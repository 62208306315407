import { useState, useEffect, useRef } from "react";
import { formatDate, formatDateToDateObject } from "../../../utils/utils.js";
import { useLocation, useNavigate } from "react-router-dom";
import UploadModal from "../../../components/modal/UploadModal.jsx";
import { ThemeProvider, Typography, createTheme } from "@mui/material";
import "./styles.css";
import {
  baseGroupLevelCols,
  baseOrgLevelCols,
  loadingColumns,
} from "./Tables/columns";
import {
  useMakeRmpPlanLiveMutation,
  useRmGroupLevelPlansQuery,
  useRmOrgLevelPlansQuery,
} from "../../../features/api/rawMaterialSlice";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../../utils/components";
import { KpiCardsRM } from "../../../components/kpi_cards_copy/kpi_cards_rm";
import {
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import "../styles.css";
import KPICard from "@/components/kpi_cards/KPICard.jsx";

import { TableBody, TableCell, TableRow } from "@/components/ui/table";
import { DataTablePagination } from "@/components/tanstack-table/data-table-pagination";
import { DataTableToolbar } from "@/components/tanstack-table/data-table-toolbar";
import { getCommonPinningStyles } from "@/components/tanstack-table/table-utils";
import { DataTable } from "@/components/tanstack-table/data-table.jsx";
import { transformStatus } from "@/components/tableUtils/tableUtils.jsx";
import { handleApiArrayResponse } from "@/utils/apiUtils.js";
import { toast } from "react-toastify";
import { useGetApprovalCommentsMutation } from "@/features/api/replPlanSlice.js";
import { useDispatch } from "react-redux";
import { setRmpGroupPlanViewDetails } from "@/features/rmpGroupPlanViewSlice.js";

const transformTableData = (arr, index) => {
  return arr.map((item) => {
    const baseObject = {
      planId: item.plan_id,
      planDate: formatDate(item.created_at),
      buCode: item.bu_code,
      // cycle: formatDate(item.created_at),
      cyclePeriod: "-",
      lastUpdatedAt: formatDate(item.last_updated_at),
      updatedBy: item.updated_by,
      jobType: item.plan_type,
      planStatus:
        item.plan_status === "Approval_In_Progress"
          ? "Approval In Progress"
          : item.plan_status,
      approvalStatus: index % 2 === 0 ? "Send" : "Revoke",
      action: null,
      approvalId: item.approval_id,
      stoStatus: item.sto_status,
    };

    if (item.approval_id !== null) {
      return {
        ...baseObject,
        approvalDetails: {
          approvalId: item.approval_id,
          permissions: {
            canSubmit: item.approval_details.permissions.canSubmit,
            canApprove: item.approval_details.permissions.canApprove,
            canRevoke: item.approval_details.permissions.canRevoke,
            canReject: item.approval_details.permissions.canReject,
          },
          approvalStatus: item.approval_details.approval_status,
          levelwiseApprovalStatus:
            item.approval_details.levelwise_approval_status.map((element) => ({
              level: element.level,
              status: element.approval_status,
            })),
        },
      };
    }
    return baseObject;
  });
};

const transformGroupData = (arr) => {
  return arr.map((item) => ({
    ...item,
    created_at: formatDateToDateObject(item.created_at),
    updated_at: formatDateToDateObject(item.updated_at),
  }));
};

const transformOrgData = (data) => {
  let transformedData = data.map(
    ({ cycle_start_date, cycle_end_date, status, cycle_id }) => {
      let statusMod = "";
      if (status === "In_progress") {
        statusMod = "In- Progress";
      }
      if (status && status.toLowerCase() === "completed") {
        statusMod = "Completed";
      }
      return {
        cycleId: cycle_id,
        // cycle: `${cycle_start_date} to ${cycle_end_date}`,
        orgCyclePeriod: `${cycle_start_date} to ${cycle_end_date}`,
        orgNextCyclePeriod: `${cycle_start_date} to ${cycle_end_date}`,
        groupLevelAggr: "Plans Approved (1/5)",
        status: statusMod,
      };
    }
  );
  return transformedData;
};

function RMPlanDashboard() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [stateColumnValues, setStateColumnValues] = useState([]);
  const [orgColumns, setOrgColumns] = useState(loadingColumns());
  const [groupColumns, setGroupColumns] = useState(loadingColumns());
  const [orgData, setOrgData] = useState(Array(10).fill({}));
  const [groupData, setGroupData] = useState(Array(10).fill({}));
  const initialLevel = state && state.planLevel ? state.planLevel : "group";
  const [level, setLevel] = useState(initialLevel);
  const [openSendModal, setOpenSendModal] = useState(false);
  const [approvalStatusValues, setApprovalStatusValues] = useState([]);
  const [selectedGroupPlan, setSelectedGroupPlan] = useState({});
  const [openMakeLiveModal, setOpenMakeLiveModal] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [orgColumnFilters, setOrgColumnFilters] = useState([]);
  const [orgSorting, setOrgSorting] = useState([]);
  const [orgColumnVisibility, setOrgColumnVisibility] = useState({});
  const [groupColumnFilters, setGroupColumnFilters] = useState([]);
  const [groupSorting, setGroupSorting] = useState([]);
  const [groupColumnVisibility, setGroupColumnVisibility] = useState({});
  const [rawMaterialType, setRawMaterialType] = useState("RM-PR");
  const [openApproveRejectModal, setOpenApproveRejectModal] = useState(false);
  const [approveRejectValue, setApproveRejectValue] = useState(null);
  const [openApprovalCommentsModal, setopenApprovalCommentsModal] =
    useState(false);
  const [approvalComments, setApprovalComments] = useState([]);
  const [loadingComments, setLoadingComments] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const savedColumns = useRef();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const {
    data: groupLevelTableData,
    refetch,
    isFetching: isLoadinggroupLevelTableData,
    isSuccess: isSuccessgroupLevelTableData,
  } = useRmGroupLevelPlansQuery(undefined, {
    skip: level === "org",
    refetchOnMountOrArgChange: true,
  });

  const {
    data: orgLevelData,
    isFetching: isLoadingOrgLevelData,
    isSuccess: isSuccessOrgLevelData,
  } = useRmOrgLevelPlansQuery(undefined, {
    skip: level === "group",
    refetchOnMountOrArgChange: true,
  });

  const [getApprovalComments, { isLoading: loadingApprovalComments, isError }] =
    useGetApprovalCommentsMutation();

  const [planLive, { isLoading: loadingMakePlanLive }] =
    useMakeRmpPlanLiveMutation();

  function groupLevelMakeLive(row) {
    const { plan_status, plan_live } = row;
    let userDetails = JSON.parse(localStorage.getItem("user"));
    const { permissions } = userDetails;
    const isPlanner = permissions.includes("RMP_SUBMIT_APPROVAL");

    const handleMakeLive = (row) => {
      setSelectedGroupPlan({ ...row });
      setOpenMakeLiveModal(true);
    };

    return (
      <div style={{ width: "100%", display: "flex" }}>
        <button
          className={
            plan_live !== true || (!isPlanner && plan_status !== "Live")
              ? plan_status !== "Live"
                ? "grp-action-live-btn"
                : "grp-action-live-btn live-btn-enable"
              : "grp-action-live-btn grp-action-live-enable"
          }
          style={{ margin: "auto", height: "22px", fontSize: "12px" }}
          disabled={!row.plan_live || !isPlanner}
          onClick={() => {
            handleMakeLive(row);
          }}
        >
          Live
        </button>
      </div>
    );
  }

  function groupLevelSendRevoke(row) {
    const { approval_id, approval_details, plan_status } = row;
    const sendRevokeEnable =
      approval_id !== null && approval_details
        ? approval_details.permissions.isPlanner === true &&
          approval_details.permissions.canSubmit === false &&
          approval_details.permissions.canApprove === false &&
          approval_details.permissions.canReject === false
          ? false
          : true
        : true;

    return (
      <div style={{ display: "flex" }}>
        {approval_id === null && (
          <div
            style={{
              border: "1px solid #d9d9d9",
              borderRadius: "19px",
              padding: "2px 4px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxSizing: "border-box",
              margin: "auto",
            }}
          >
            <span
              style={{
                fontSize: "11px",
                marginRight: "0px",
                marginBottom: "unset",
                marginTop: "unset",
                lineHeight: "unset",
                minWidth: "50px",
                textAlign: "center",
              }}
            >
              Send
            </span>
            <label
              className="send-revoke-box small-switch"
              onClick={() => {
                setSelectedGroupPlan(row);
                setOpenSendModal(true);
                setApproveRejectValue("SUBMITTED");
              }}
              disabled={true}
            >
              <input type="checkbox" className="send-revoke-input" disabled />
              <span className="send-revoke-slider send-revoke-round"></span>
            </label>
          </div>
        )}
        {approval_id !== null && approval_details && (
          <div
            style={{
              border: "1px solid #d9d9d9",
              borderRadius: "19px",
              padding: "2px 4px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxSizing: "border-box",
              margin: "auto",
              opacity:
                approval_details.permissions.canSubmit === true ||
                approval_details.permissions.canRevoke
                  ? 1
                  : 0.4,
            }}
          >
            <span
              style={{
                fontSize: "11px",
                marginRight: "0px",
                marginBottom: "unset",
                marginTop: "unset",
                lineHeight: "unset",
                minWidth: "50px",
                textAlign: "center",
              }}
            >
              {approval_id !== null && approval_details
                ? approval_details.permissions.canSubmit === true
                  ? "Send"
                  : approval_details.permissions.canRevoke === true
                  ? "Revoke"
                  : "Revoke"
                : null}
            </span>
            <label
              className="send-revoke-box small-switch"
              onClick={() => {
                if (
                  approval_details.permissions.canSubmit === true ||
                  approval_details.permissions.canRevoke === true
                ) {
                  setSelectedGroupPlan(row);
                  setOpenSendModal(true);
                  const actionValue =
                    approval_details.permissions.canSubmit === true
                      ? "SUBMITTED"
                      : approval_details.permissions.canRevoke === true
                      ? "REVOKED"
                      : "";
                  setApproveRejectValue(actionValue);
                }
              }}
              disabled={true}
            >
              <input
                type="checkbox"
                className="send-revoke-input"
                disabled
                checked={approval_details.permissions.canRevoke}
              />
              <span className="send-revoke-slider send-revoke-round"></span>
            </label>
          </div>
        )}
      </div>
    );
  }

  function groupLevelApproveReject(row) {
    const { approval_id, approval_details, plan_status } = row;
    const approveRejectDisable = () => {
      if (approval_id !== null && approval_details) {
        if (
          approval_details.permissions.canApprove === true &&
          approval_details.permissions.canReject === true
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    };
    return (
      <div style={{ display: "flex" }}>
        {approval_id !== null && approval_details && (
          <div className="approval-btns">
            <button
              className="approval-btn approve"
              onClick={() => {
                setOpenApproveRejectModal(true);
                setSelectedGroupPlan(row);
                setApproveRejectValue("APPROVED");
              }}
              style={{
                opacity: approveRejectDisable() ? 0.4 : 1,
              }}
              disabled={approveRejectDisable()}
            >
              Approve
            </button>
            <button
              className="approval-btn reject"
              onClick={() => {
                setOpenApproveRejectModal(true);
                setApproveRejectValue("REJECTED");
                setSelectedGroupPlan(row);
              }}
              style={{
                opacity: approveRejectDisable() ? 0.4 : 1,
              }}
              disabled={approveRejectDisable()}
            >
              Reject
            </button>
          </div>
        )}
      </div>
    );
  }

  const handlePlanApproval = async (comment, planId, approveRejectValue) => {
    setLoading(true);
    const formData = new FormData();
    const approvalDetails = {
      action: approveRejectValue,
      approvable_type: "RMP",
      approvables: [
        {
          id: planId,
          comment: comment,
          attachment_ids: [],
        },
      ],
    };

    formData.append("approval_details", JSON.stringify(approvalDetails));

    fetch(`${process.env.REACT_APP_SCAI_API_ENDPOINT}/v1/approval/`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
      },
      body: formData,
    })
      .then(handleApiArrayResponse)
      .then((data) => {
        const { message, approvals_info, approvable_details } = data;
        const approvableId = approvals_info[0].approvable_id;
        const indexOfObjectToUpdate = groupData.findIndex((item) => {
          if (item.plan === approvableId) {
            return true;
          }
        });
        const planToBeUpdated = groupData[indexOfObjectToUpdate];
        const updatedPlan = {
          ...planToBeUpdated,
          plan_live: approvable_details[0].plan_live,
          plan_status:
            approvable_details[0].status === "Approval_In_Progress"
              ? "Approval In Progress"
              : transformStatus(approvable_details[0].status),
          approval_id: approvals_info[0].approval_id,
          approval_details: {
            approval_id: approvals_info[0].approval_id,
            permissions: {
              canSubmit: approvals_info[0].permissions.canSubmit,
              canApprove: approvals_info[0].permissions.canApprove,
              canRevoke: approvals_info[0].permissions.canRevoke,
              canReject: approvals_info[0].permissions.canReject,
            },
            approval_status: approvals_info[0].approval_status,
            levelwise_approval_status:
              approvals_info[0].levelwise_approval_status.map((element) => ({
                level: element.level,
                approval_status: element.approval_status,
              })),
          },
        };
        const newGroupData = [...groupData];
        newGroupData[indexOfObjectToUpdate] = updatedPlan;
        setGroupData([...newGroupData]);
        const { isPlanner, isApprover } = getPlannerApproverPermissions();
        setLoading(false);
        toast.success(`${planId} is ${message}`, { autoClose: 3000 });
      })
      .catch((error) => {
        toast.error(error.message, { autoClose: 3000 });
        setLoading(false);
      });
  };

  const makePlanLive = async (planId) => {
    const requestBody = {
      plan_id: planId,
    };
    try {
      const response = await planLive({
        planId,
        requestBody: requestBody,
      });
      if (response.error) {
        throw new Error(response.error.data.error);
      } else {
        refetch();
        toast.success(response.data.success, { autoClose: 3000 });
      }
    } catch (error) {
      toast.error(error.message, { autoClose: 3000 });
    }
  };

  useEffect(() => {
    if (level === "org") {
      if (isSuccessOrgLevelData) {
        setOrgColumns(
          baseOrgLevelCols({
            stateColumnValues,
            setStateColumnValues,
            navigate,
          })
        );
        setOrgData(orgLevelData ? transformOrgData(orgLevelData) : []);
      }
    } else {
      if (isSuccessgroupLevelTableData) {
        const { isPlanner, isApprover } = getPlannerApproverPermissions();
        let cols = baseGroupLevelCols({
          approvalStatusValues,
          setOpenSendModal,
          groupLevelMakeLive,
          groupLevelSendRevoke,
          groupLevelApproveReject,
          navigate,
          isPlanner,
          isApprover,
          setopenApprovalCommentsModal,
          getComments,
          handlePlanDetails,
        });
        setGroupColumns(cols);
        savedColumns.current = cols;
        setGroupData(transformGroupData(groupLevelTableData));
      }
      // if (baseGroupLevelData) {
      //   getApprovalStatusValues(baseGroupLevelData);
      // }
    }
  }, [level, groupLevelTableData, orgLevelData]);

  useEffect(() => {
    if (loading === true) {
      setGroupColumns(loadingColumns());
    } else {
      let cols = savedColumns.current;
      setGroupColumns(cols);
    }
  }, [loading]);

  const handlePlanDetails = (row) => {
    dispatch(setRmpGroupPlanViewDetails({ ...row }));
  };

  const getComments = async (approvalId) => {
    const requestBody = {
      approvable_type: "RMP",
      approval_ids: [`${approvalId}`],
    };
    try {
      setLoadingComments(true);
      const responseApproval = await getApprovalComments({
        requestBody: requestBody,
      });

      let responseApprovalIndex =
        responseApproval.data.approval_actions.findIndex((item) => {
          if (item.approval_id === approvalId) {
            return true;
          }
        });
      const planApprovalComments =
        responseApproval.data.approval_actions[responseApprovalIndex]
          .approval_action;
      setApprovalComments([...planApprovalComments]);
      setLoadingComments(false);
    } catch (error) {
      setLoadingComments(false);
      toast.error(error.message);
    }
  };

  function getPlannerApproverPermissions() {
    let userDetails = JSON.parse(localStorage.getItem("user"));
    const { permissions } = userDetails;
    const isPlanner = permissions.includes("RMP_SUBMIT_APPROVAL");
    const isApprover = permissions.includes("RMP_APPROVE_REJECT");
    return { isPlanner, isApprover };
  }

  const orgTable = useReactTable({
    data: orgData,
    columns: orgColumns,
    state: {
      sorting: orgSorting,
      columnVisibility: orgColumnVisibility,
      columnFilters: orgColumnFilters,
    },
    initialState: {
      columnPinning: {
        right: ["actions"],
      },
      pagination: {
        pageSize: 20,
      },
    },
    defaultColumn: {
      size: 300,
      minSize: 70,
      maxSize: 1000,
    },
    enableRowSelection: false,
    onSortingChange: setOrgSorting,
    onColumnFiltersChange: setOrgColumnFilters,
    onColumnVisibilityChange: setOrgColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getRowId: (row) => row.sto_id,
    columnResizeMode: "onChange",
    enableColumnResizing: true,
    enableColumnPinning: true,
  });

  const groupTable = useReactTable({
    data: groupData,
    columns: groupColumns,
    state: {
      sorting: groupSorting,
      columnVisibility: groupColumnVisibility,
      columnFilters: groupColumnFilters,
    },
    initialState: {
      columnPinning: {
        right: ["actions"],
      },
      pagination: {
        pageSize: 20,
      },
    },
    defaultColumn: {
      size: 300,
      minSize: 70,
      maxSize: 1000,
    },
    enableRowSelection: false,
    onSortingChange: setGroupSorting,
    onColumnFiltersChange: setGroupColumnFilters,
    onColumnVisibilityChange: setGroupColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getRowId: (row) => row.sto_id,
    columnResizeMode: "onChange",
    enableColumnResizing: true,
    enableColumnPinning: true,
  });

  const getApprovalStatusValues = (data) => {
    let approvalStatusValuesTemp = {};
    data.forEach((item) => {
      approvalStatusValuesTemp[item.planId] = false;
    });
    setApprovalStatusValues(approvalStatusValuesTemp);
  };

  const LevelButtons = () => {
    return (
      <div className="level-btns-rm">
        <button
          onClick={() => setLevel("org")}
          className={
            level === "org"
              ? "level-button-rm-org active-btn-rm"
              : "level-button-rm-org"
          }
        >
          ORG
        </button>
        <button
          onClick={() => {
            refetch();
            setLevel("group");
          }}
          className={
            level === "group"
              ? "level-button-rm-group active-btn-rm"
              : "level-button-rm-group"
          }
        >
          Group
        </button>
      </div>
    );
  };

  const renderLevelButtons = () => {
    return (
      <div className="pp-level-btns">
        <button
          onClick={() => {
            localStorage.setItem("snp-pp-level", "org");
            setLevel("org");
          }}
          className={
            level === "org"
              ? "pp-level-button pp-active-btn"
              : "pp-level-button"
          }
        >
          ORG
        </button>
        <button
          onClick={() => {
            localStorage.setItem("snp-pp-level", "group");
            setLevel("group");
          }}
          className={
            level === "group"
              ? "pp-level-button pp-active-btn"
              : "pp-level-button"
          }
        >
          Group
        </button>
      </div>
    );
  };

  const ProductionPlanLevelButtons = ({ table }) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "16px",
        }}
      >
        {renderLevelButtons()}
        <DataTableToolbar table={table} />
      </div>
    );
  };

  return (
    <ThemeProvider theme={customTheme}>
      <div style={{ width: `calc(100% - 20px)`, margin: 10 }}>
        <UploadModal
          openModal={openSendModal}
          setOpenModal={setOpenSendModal}
          modalType="send"
          approvalStatusValues={approvalStatusValues}
          setApprovalStatusValues={setApprovalStatusValues}
          handlePlanApproval={handlePlanApproval}
          selectedGroupPlan={selectedGroupPlan}
          approveRejectValue={approveRejectValue}
          module="RMP"
        />
        <UploadModal
          openModal={openApproveRejectModal}
          setOpenModal={setOpenApproveRejectModal}
          modalType="approve-reject"
          approveRejectValue={approveRejectValue}
          handlePlanApproval={handlePlanApproval}
          selectedGroupPlan={selectedGroupPlan}
          module="RMP"
        />
        <UploadModal
          openModal={openApprovalCommentsModal}
          setOpenModal={setopenApprovalCommentsModal}
          modalType="approval-comments"
          approvalComments={approvalComments}
          loadingComments={loadingComments}
          module="RMP"
        />
        <UploadModal
          openModal={openMakeLiveModal}
          setOpenModal={setOpenMakeLiveModal}
          modalType="make-live"
          selectedGroupPlan={selectedGroupPlan}
          makePlanLive={makePlanLive}
          module="RMP"
        />
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            expanded={expanded === "panel1"}
            showTypePanel={true}
            fill="white"
            hoverColor="#e94f1c"
            backgroundColor="#494949"
            expandedBgColor="#e94f1b"
          >
            <Typography
              sx={{
                width: "33%",
                flexShrink: 0,
                fontSize: "12px",
                color: "white",
              }}
            >
              KPI&apos;s
            </Typography>
            <div style={{ display: "flex" }}>
              <button
                className={
                  rawMaterialType === "RM-PR"
                    ? "pp-type pp-app-type pp-active-type"
                    : "pp-type pp-app-type"
                }
                onClick={() => setRawMaterialType("RM-PR")}
              >
                RM-PR
              </button>
              {/* <button
                className={
                  productionType === "MPS"
                    ? "pp-type pp-active-type"
                    : "pp-type"
                }
                onClick={() => setProductionType("MPS")}
              >
                MPS
              </button> */}
              <button
                className={
                  rawMaterialType === "RM-PP"
                    ? "pp-type pp-dps-type pp-active-type"
                    : "pp-type pp-dps-type"
                }
                onClick={() => setRawMaterialType("RM-PP")}
              >
                RM-PP
              </button>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div
              style={{
                margin: "auto",
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              <KPICard
                value="₹125374"
                volume="25039"
                heading="Total RM Requirements"
              />
              <KPICard
                value="₹103060"
                volume="22039"
                heading="Total RM Allocation"
              />{" "}
              <KPICard
                value="₹64726"
                volume="12325"
                heading="Supplier Spending"
                topRowRightData="Count : 53"
              />
              <KPICard
                value="25"
                volume="32"
                heading="RM DOH"
                topRowRightData="Count : 168"
                metric="Avg DOH"
                metricValue="Target DOH"
              />
              <KPICard
                value="--"
                volume="--"
                heading="Deviations"
                metric="Metric"
                metricValue="Metric"
              />
            </div>
          </AccordionDetails>
        </Accordion>
        {/* <div>
          {level === "org" ? (
            <DataTable
              table={orgTable}
              hideTopToolbar={true}
              columns={orgColumns}
              data={orgData}
              canResizeColumns={true}
            />
          ) : (
            <DataTable
              table={groupTable}
              hideTopToolbar={true}
              columns={groupColumns}
              data={groupData}
              canResizeColumns={true}
            />
          )}
        </div> */}
        <div className="snp-table-container" style={{ marginTop: "-5px" }}>
          {level === "org" ? (
            <DataTable
              data={orgData}
              columns={orgColumns}
              canResizeColumns={true}
              CustomToolbar={ProductionPlanLevelButtons}
              showSelectedColumnsCount={false}
            />
          ) : (
            <DataTable
              data={groupData}
              columns={groupColumns}
              canResizeColumns={true}
              CustomToolbar={ProductionPlanLevelButtons}
              showSelectedColumnsCount={false}
            />
          )}
          {/* <DataTable
            // CustomToolbar={GroupLevelToolbar}
            data={level === "org" ? orgData : groupData}
            columns={level === "org" ? orgColumns : groupColumns}
            canResizeColumns={true}
            CustomToolbar={ProductionPlanLevelButtons}
            showSelectedColumnsCount={false}
          /> */}
        </div>
      </div>
    </ThemeProvider>
  );
}

// function DataTable({
//   table,
//   columns,
//   canResizeColumns = false,
//   hideTopToolbar,
// }) {
//   const tableContainerRef = useRef(null);
//   const totalTableWidth = table?.getTotalSize();

//   return (
//     <div>
//       {hideTopToolbar ? null : <DataTableToolbar table={table} />}
//       <div
//         className="rounded-md border new-table-container overflow-scroll max-h-[565px] lg:max-h-[520px] mb-[10px]"
//         ref={tableContainerRef}
//       >
//         <table
//           style={{
//             width: canResizeColumns ? totalTableWidth : "100%",
//           }}
//         >
//           <thead>
//             {table.getHeaderGroups().map((headerGroup) => (
//               <tr key={headerGroup.id}>
//                 {headerGroup.headers.map((header) => {
//                   const { column } = header;
//                   return (
//                     <th
//                       key={header.id}
//                       style={{
//                         position: "sticky",
//                         top: "0",
//                         zIndex: "2",
//                         width: header.getSize(),
//                         ...getCommonPinningStyles(column, "th"),
//                       }}
//                     >
//                       {header.isPlaceholder
//                         ? null
//                         : flexRender(
//                             header.column.columnDef.header,
//                             header.getContext()
//                           )}
//                       <div
//                         onMouseDown={header.getResizeHandler()}
//                         onTouchStart={header.getResizeHandler()}
//                         className={`resizer ${
//                           header.column.getIsResizing() ? "isResizing" : ""
//                         }`}
//                       />
//                     </th>
//                   );
//                 })}
//               </tr>
//             ))}
//           </thead>
//           <TableBody className="max-h-[200px]">
//             {table.getRowModel().rows?.length ? (
//               table.getRowModel().rows.map((row) => (
//                 <TableRow
//                   key={row.id}
//                   data-state={row.getIsSelected() && "selected"}
//                 >
//                   {row.getVisibleCells().map((cell) => {
//                     const { column } = cell;
//                     return (
//                       <td
//                         key={cell.id}
//                         style={{
//                           width: cell.column.getSize(),
//                           ...getCommonPinningStyles(column, "td"),
//                         }}
//                         className="font-[Inter] text-[14px] lg:text-[12px] p-0 text-ellipsis"
//                       >
//                         {flexRender(
//                           cell.column.columnDef.cell,
//                           cell.getContext()
//                         )}
//                       </td>
//                     );
//                   })}
//                 </TableRow>
//               ))
//             ) : (
//               <TableRow>
//                 <TableCell colSpan={columns.length} className="h-24">
//                   <p className="lg:pl-7 lg:text-[16px] text-[16px]">
//                     No results.
//                   </p>
//                 </TableCell>
//               </TableRow>
//             )}
//           </TableBody>
//         </table>
//       </div>
//       <DataTablePagination table={table} />
//     </div>
//   );
// }

const customTheme = createTheme({
  palette: {
    primary: {
      main: "#f5f7fa",
    },
  },
  typography: {
    fontFamily: "Inter, sans-serif",
    fontSize: 12,
    fontWeightBold: 700,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightLight: 300,
    customBoldFont: {
      fontWeight: 600,
      fontSize: 13,
    },
    customLightFont: {
      fontSize: 11,
      fontWeight: 400,
    },
    customHeading: {
      fontWeight: 300,
      fontSize: 15,
    },
  },
  components: {
    MuiDrawer: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});

export default RMPlanDashboard;
