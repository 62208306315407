import { useState, useEffect, useRef, createRef } from "react";
import MuiTable from "../../../components/muiTable/muiTable";
import { useLocation, useNavigate } from "react-router-dom";
import UploadIcon from "../../../components/customSvgIcons/upload";
import DownloadIcon from "../../../components/customSvgIcons/download";
import UploadModal from "../../../components/modal/UploadModal";
import { usePreprocessesQuery } from "../../../features/api/preprocessesSlice";
import { formatDate, formatISODateToHumanReadable } from "../../../utils/utils";
import { TableLoaderSpinner } from "../../../components/tableUtils/tableUtils";
import "./styles.css";
import { handleDownload, handleProcessType } from "./preprocessUtils";

const transformData = (arr) => {
  return arr.map((item) => ({
    process: item.process,
    processTypes: item.process_type.name,
    lastUpdatedBy: item.last_updated_by,
    lastUpdatedAt: formatISODateToHumanReadable(item.last_updated_at),
    preprocessAction: item.preprocess_action,
  }));
};

function PreprocessLanding() {
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [selectedRow, setSelectedRow] = useState();
  const [isDownloading, setIsDownloading] = useState({});
  const [isUploading, setIsUploading] = useState({});
  const [level, setLevel] = useState("preprocesses");
  const {
    data: preprocesses,
    isLoading: isLoadingPreprocesses,
    isSuccess: isSuccessPreprocesses,
  } = usePreprocessesQuery(undefined, {
    refetchOnMountOrArgChange: true,
    force: true, // This will bypass the cache
  });

  const location = useLocation();
  const path = location.pathname;
  let idArr = path.split("/");
  useEffect(() => {
    setTableData(
      preprocesses && preprocesses.results
        ? transformData(preprocesses.results)
        : []
    );
    let isDownloadingTemp = {};
    let isUploadingTemp = {};
    if (preprocesses && preprocesses.results) {
      preprocesses.results.forEach((process) => {
        isDownloadingTemp[process.process] = false;
        isUploadingTemp[process.process] = false;
      });
    }
    setIsDownloading(isDownloadingTemp);
    setIsUploading(isUploadingTemp);
  }, [preprocesses]);

  const actionsArray = [
    (row) => console.log({ row }),
    (row) => console.log({ row }),
    (row) => {
      console.log({ row });
    },
  ];
  function DynamicRefsExample(numberOfRefs, componentArray, cell, row) {
    const refsArray = useRef(
      Array.from({ length: numberOfRefs }, () => createRef())
    );
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {Array.from({ length: numberOfRefs }, (_, index) => {
          const Comp = componentArray[index];

          return (
            <div
              id={index}
              key={index}
              ref={refsArray.current[index]}
              placeholder={`Ref ${index + 1}`}
              onClick={() => actionsArray[index](row)}
              style={{
                pointerEvents: row.original.isDisabled ? "none" : "auto",
              }}
            >
              <Comp row={row.original} />
            </div>
          );
        })}
      </div>
    );
  }

  const actionButtonsArray = [
    (row1) => {
      const { row } = row1;
      return (
        <button
          className={`table-action-button upload-button process-btn ${
            row.processTypes === "RP_PREPROCESS_D" ? "disabled-btn" : ""
          }`}
          onClick={() => {
            setSelectedRow(row);
            setOpenUploadModal(true);
          }}
          disabled={
            isUploading[row.process] ||
            row.processTypes === "RP_PREPROCESS_D" ||
            !row.preprocessAction.includes("upload")
          }
        >
          {isUploading[row.process] ? (
            <TableLoaderSpinner size={12} height="2vh" />
          ) : (
            <UploadIcon />
          )}
        </button>
      );
    },
    (row1) => {
      const { row } = row1;
      let type;
      let processType;
      if (row.processTypes === "CSA") {
        const { row } = row1;
        const rowArray = row.process.split("_");
        const rowArrayLength = rowArray.length;
        type = rowArray[rowArrayLength - 1];
        processType = "classification";
      } else if (row.processTypes.toLowerCase().includes("norms")) {
        {
          type = row.process.toLowerCase();
          processType = "norms";
        }
      }
      return (
        <button
          className={`table-action-button upload-button process-btn ${
            row.processTypes === "RP_PREPROCESS_D" ? "disabled-btn" : ""
          }`}
          onClick={() =>
            handleDownload(
              row,
              type,
              processType,
              isDownloading,
              setIsDownloading,
              "preprocesses"
            )
          }
          disabled={
            isDownloading[row.process] ||
            row.processTypes === "RP_PREPROCESS_D" ||
            !row.preprocessAction.includes("download")
          }
        >
          {isDownloading[row.process] ? (
            <TableLoaderSpinner size={12} height="2vh" />
          ) : (
            <DownloadIcon />
          )}
        </button>
      );
    },
    (row1) => {
      const { row } = row1;
      let type;
      let processType;
      if (row.processTypes === "CSA") {
        const { row } = row1;
        const rowArray = row.process.split("_");
        const rowArrayLength = rowArray.length;
        type = rowArray[rowArrayLength - 1].toLowerCase();
        processType = "classification";
      } else if (row.processTypes.toLowerCase().includes("norms")) {
        {
          type = row.process.toLowerCase();
          processType = "norms";
        }
      }
      return (
        <button
          className={`table-action-button view-button-preprocess process-view-btn ${
            row.processTypes === "RP_PREPROCESS_D"
              ? "view-button-preprocess-disabled"
              : ""
          }`}
          onClick={() => {
            if (row.processTypes === "INVENTORY_PROFILING") {
              return navigate(
                `/${idArr[1]}/${idArr[2]}/${idArr[3]}/${idArr[4]}/process/profiling`,
                { state: { processType: processType, preprocessRow: row } }
              );
            }
            return navigate(
              `/${idArr[1]}/${idArr[2]}/${idArr[3]}/${idArr[4]}/process/${type}`,
              { state: { processType: processType, preprocessRow: row } }
            );
          }}
          disabled={
            row.processTypes === "RP_PREPROCESS_D" ||
            !row.preprocessAction.includes("view")
          }
        >
          View
        </button>
      );
    },
  ];

  const renderPreprocessesHeading = () => (
    <h1 className="preprocesses-heading">Preprocesses</h1>
  );

  const renderLevelButtons = () => {
    return (
      <div className="level-btns">
        <button
          onClick={() => setLevel("preprocesses")}
          className={
            level === "preprocesses"
              ? "level-button active-btn"
              : "level-button"
          }
        >
          Preprocesses
        </button>
        <button
          onClick={() => {
            setLevel("meta-data");
          }}
          className={
            level === "meta-data" ? "level-button active-btn" : "level-button"
          }
        >
          Meta Data
        </button>
      </div>
    );
  };

  return (
    tableData && (
      <>
        <div
          className="snp-table-container"
          style={{ width: `calc( 100% - 20px)`, marginLeft: "10px" }}
        >
          <UploadModal
            openModal={openUploadModal}
            setOpenModal={setOpenUploadModal}
            classType={handleProcessType(selectedRow)}
            uploadType="preprocess"
            selectedProcess={selectedRow}
            setIsUploading={setIsUploading}
            moduleType="rp"
          />
          <MuiTable
            columns={[
              {
                accessorKey: "process",
                header: "Process",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "processTypes",
                header: "Process Type",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "lastUpdatedBy",
                header: "Last Updated By",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "lastUpdatedAt",
                header: "Last Updated At",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "action",
                header: "Actions",
                enableColumnActions: false,
                enableSorting: false,
                muiTableBodyCellEditTextFieldProps: {
                  select: true, //change to select for a dropdown
                },
                Cell: ({ cell, row }) => {
                  return DynamicRefsExample(3, actionButtonsArray, cell, row);
                },
              },
            ]}
            td={tableData}
            shouldShowAddBtn={false}
            renderTopToolbarCustomActions={renderLevelButtons}
            isLoading={isLoadingPreprocesses}
          />
        </div>
      </>
    )
  );
}

export default PreprocessLanding;
