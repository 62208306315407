import React, { useState, useEffect } from "react";
import "./styles.css";
import { PowerbiSvg, ExcelSvg } from "../../assets/svg-icons";
import { useNavigate } from "react-router-dom";

export default function Reports() {
  const reportName = "Inventory Visibility";
  const dashboard = "Dashboard";
  const cards = [1, 2, 3, 4, 5, 6, 7, 8];
  let cardsArr = [
    [
      "Inventory Coverage",
      "https://app.powerbi.com/view?r=eyJrIjoiYmJjMTI2NmEtM2U4My00YTgwLThhY2QtNWI4ZWVhM2U4MzY5IiwidCI6ImQxZDkzNmVjLThiZmYtNDU2MC1hOGE5LTYwMDEwZWMwMzRkZSJ9",
    ],
    [
      "YoY & MoM Sales trend",
      "https://app.powerbi.com/view?r=eyJrIjoiYmJjMTI2NmEtM2U4My00YTgwLThhY2QtNWI4ZWVhM2U4MzY5IiwidCI6ImQxZDkzNmVjLThiZmYtNDU2MC1hOGE5LTYwMDEwZWMwMzRkZSJ9",
    ],
    [
      "Sales Order & Backorder Tracking",
      "https://app.powerbi.com/view?r=eyJrIjoiYmJjMTI2NmEtM2U4My00YTgwLThhY2QtNWI4ZWVhM2U4MzY5IiwidCI6ImQxZDkzNmVjLThiZmYtNDU2MC1hOGE5LTYwMDEwZWMwMzRkZSJ9",
    ],
    [
      "Inventory Visibility (GIT, GIH, PBNS)",
      "https://app.powerbi.com/view?r=eyJrIjoiOGRhMTQ1ZDMtNGYwOC00MGNiLTkzNDEtY2RiM2NkZTI0Nzc1IiwidCI6ImQxZDkzNmVjLThiZmYtNDU2MC1hOGE5LTYwMDEwZWMwMzRkZSJ9",
    ],
    [
      "SLOB report",
      "https://app.powerbi.com/view?r=eyJrIjoiZjAxNjViYjgtNzk4Mi00OWIwLTk2M2UtZjY3OTQwMzgwOWI1IiwidCI6ImQxZDkzNmVjLThiZmYtNDU2MC1hOGE5LTYwMDEwZWMwMzRkZSJ9",
    ],
    [
      "Production Vs Inventory Vs Sales",
      "https://app.powerbi.com/view?r=eyJrIjoiMzk2ODk4ZTMtMzY0NC00MTA2LTk5YWItN2ZiNjM3M2IzMjVhIiwidCI6ImQxZDkzNmVjLThiZmYtNDU2MC1hOGE5LTYwMDEwZWMwMzRkZSJ9",
    ],
    [
      "MTD Production",
      "https://app.powerbi.com/view?r=eyJrIjoiMjY5M2ZmYjQtMzY5OC00MzI2LTgyNGMtMGE3YzJjNzRjZmU0IiwidCI6ImQxZDkzNmVjLThiZmYtNDU2MC1hOGE5LTYwMDEwZWMwMzRkZSJ9",
    ],
    [
      "Capacity Utilisation",
      "https://app.powerbi.com/view?r=eyJrIjoiMzk2ODk4ZTMtMzY0NC00MTA2LTk5YWItN2ZiNjM3M2IzMjVhIiwidCI6ImQxZDkzNmVjLThiZmYtNDU2MC1hOGE5LTYwMDEwZWMwMzRkZSJ9",
    ],
    [
      "Weekly RP report",
      "https://app.powerbi.com/view?r=eyJrIjoiNTlmZTUxNzQtOTQ2My00ZDY5LWFhNzgtMGRkYWQ2OTI5OWMwIiwidCI6ImQxZDkzNmVjLThiZmYtNDU2MC1hOGE5LTYwMDEwZWMwMzRkZSJ9",
    ],
    [
      "Weekly PP report",
      "https://app.powerbi.com/view?r=eyJrIjoiMGU0ZDY5MjctNzk4Zi00NGM5LThiYTktN2E5MjRlMDJjY2QzIiwidCI6ImQxZDkzNmVjLThiZmYtNDU2MC1hOGE5LTYwMDEwZWMwMzRkZSJ9",
    ],
  ];
  const navigate = useNavigate();
  return (
    <div className="reports-container">
      <div className="text-powerbi">Power BI Dashboards</div>
      <div className="powerbi-dashboards">
        {cardsArr.map((item, index) => (
          <div
            className="powerbi-card"
            key={index}
            onClick={() => navigate(`/snp/reports/${index + 1}`)}
          >
            <div className="text-container">
              <div>{item[0]}</div>
              {/* <div>{dashboard}</div> */}
            </div>
            <div className="view-container">
              <PowerbiSvg />
              <button className="view-btn">View</button>
            </div>
          </div>
        ))}
      </div>
      <div className="text-excel">Excel Reports</div>
      <div className="excel-reports">
        {cards.map((n) => (
          <div className="excel-card" key={n}>
            <div className="text-container">
              <div>{`Report-${n}`}</div>
            </div>
            <div className="download-container">
              <ExcelSvg />
              <button className="download-btn">Download</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
