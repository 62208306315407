import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import "./styles.css";

export default function BarAreaLineChart_poc() {
  const getOptions = () => ({
    title: {
      text: "",
      align: "left",
    },
    plotOptions: {
      area: {
        fillOpacity: 1,
        stacking: "normal",
        lineWidth: 0,
        marker: {
          enabled: false,
        },
      },
      line: {
        marker: {
          enabled: false,
        },
      },
      series: {
        pointWidth: 20,
        groupPadding: 0.3,
      },
    },
    xAxis: {
      categories: [
        "Jet fuel",
        "Duty-free diesel",
        "Petrol",
        "Diesel",
        "Gas oil",
      ],
      lineWidth: 1,
      lineColor: "#c2c2c2",
    },
    yAxis: {
      title: {
        text: "",
      },
      lineWidth: 1,
      lineColor: "#c2c2c2",
    },
    tooltip: {
      valueSuffix: " million liters",
    },
    series: [
      {
        type: "area",
        name: "Safety stock",
        data: [34, 35, 37, 39, 42],
        color: "#D8FBE1",
        dashStyle: "line",
      },
      {
        type: "area",
        name: "Final minimum quantity",
        data: [52, 54, 57, 59, 64],
        color: "#FFEEB0",
        dashStyle: "line",
      },
      {
        type: "area",
        name: "Final maximum quantity",
        data: [102, 114, 137, 159, 164],
        color: "#F6CBD5",
        dashStyle: "line",
      },
      {
        type: "column",
        name: "Planned replenishment by due date",
        data: [59, 83, 65, 228, 184],
        color: "#1D2C90",
      },
      {
        type: "column",
        name: "On Orders",
        data: [24, 79, 72, 240, 167],
        color: "#6E91EB",
      },
      {
        type: "line",
        name: "Average",
        data: [87, 43, 62, 91, 58],
        dashStyle: "ShortDash",
        color: "#8064C0",
      },
    ],
    credits: {
      enabled: false,
    },
  });
  return <HighchartsReact highcharts={Highcharts} options={getOptions()} />;
}
