import React, { useEffect, useRef, useState } from "react";
import BorderedTreeView from "./treeView.jsx";
import TreeChart from "./treeChart.jsx";
import { data1, data2, data3, data4 } from "./data.js";
import { BreadcrumbTemp } from "@/components/job-schedular/jsConfigScreen.jsx";
import DonutChart from "./DonutChart.jsx";
import { useNavigate } from "react-router-dom";
function useResizeObserver() {
  const [componentWidth, setComponentWidth] = React.useState(null);
  const [componentHeight, setComponentHeight] = React.useState(null);
  const targetRef = React.useRef(null);

  React.useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target === targetRef.current) {
          setComponentWidth(entry.contentRect.width);
          setComponentHeight(entry.contentRect.height);
        }
      }
    });

    if (targetRef.current) {
      resizeObserver.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        resizeObserver.unobserve(targetRef.current);
      }
    };
  }, []);

  return { targetRef, componentWidth, componentHeight };
}

export function BOMExplosion() {
  const [item, setItem] = useState(0);
  const [data, setData] = useState(null);
  const [levels, setLevels] = useState(null);

  const handleItemClick = (event, itemInput) => {
    console.log("Clicked item:", itemInput);
    setItem(itemInput);
    if (itemInput == 1) {
      setLevels(8);
      setData(data1);
    }
    if (itemInput == 2) {
      setLevels(8);
      setData(data2);
    }
    if (itemInput == 3) {
      setLevels(8);
      setData(data3);
    }
    if (itemInput == 4) {
      setLevels(8);
      setData(data4);
    }
  };

  const Breadcrumb = () => {
    const navigate = useNavigate();
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          marginBottom: "10px",
        }}
        className="inv-stats-breadcrumb ml-[20px] mt-[10px]"
      >
        <div
          className="inv-mgmt-link"
          onClick={() => navigate("/snp/raw-material/requirement")}
        >
          FG, RM Stock Availability
        </div>
        <div className="flex">
          <BreadcrumbTemp />
          <BreadcrumbTemp />
        </div>
        <div className="inv-mgmt-link" onClick={() => setItem(0)}>
          BOM Explosion
        </div>
      </div>
    );
  };

  const { targetRef, componentWidth, componentHeight } = useResizeObserver();

  const snpWidth = document.getElementById("snp")?.offsetWidth;

  return (
    <>
      <Breadcrumb />
      <div className="flex h-[660px] m-[10px] p-[10px] gap-[10px] mt-0 pt-0">
        <BorderedTreeView handleItemClick={handleItemClick} />
        {item === 1 && <TreeChart data={data1} levels={levels} />}
        {item === 2 && <TreeChart data={data2} levels={levels} />}
        {item === 3 && <TreeChart data={data3} levels={levels} />}
        {item === 4 && <TreeChart data={data4} levels={levels} />}
        {item === 0 && (
          <div
            className="flex border-solid border-2 border-[#DDDDDD] rounded-[5px] w-full h-[638px]"
            ref={targetRef}
          >
            <DonutChart
              componentWidth={componentWidth}
              snpWidth={snpWidth}
              componentHeight={componentHeight}
            />
          </div>
        )}
      </div>
    </>
  );
}
