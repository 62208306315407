export const handleProcessType = (selectedRow) => {
  if (selectedRow) {
    let selectedProcess = selectedRow.process;
    let selectedProcessArray = selectedProcess.split("_");
    let selectedProcessLength = selectedProcessArray.length;
    return {
      type:
        selectedProcess === "CSA"
          ? selectedProcessArray[selectedProcessLength - 1].toLowerCase()
          : selectedProcess.split(":")[0].toLowerCase(),
      processType:
        selectedRow.processTypes === "CSA" ? "classification" : "norms",
    };
  }
  return "";
};
