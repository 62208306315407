import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentEditSto: null,
  originalSTOValues: {},
  isGroupLevelEditModeOn: false,
  editedSTOList: [],
};

const rawMaterialPlanSlice = createSlice({
  name: "rawMaterialPlanSlice",
  initialState,
  reducers: {
    setCurrentEditSto: (state, action) => {
      state.currentEditSto = action.payload;
    },
    setOriginalSTOValues: (state, action) => {
      state.originalSTOValues = action.payload;
    },
    setIsGroupLevelEditModeOn: (state, action) => {
      state.isGroupLevelEditModeOn = action.payload;
    },
    setEditedSTOList: (state, action) => {
      const { payload, type } = action.payload;
      switch (type) {
        case "API_BODY": {
          // const editableField = payload.editableField;
          const sto = payload.sto;
          const editedValue = Number(payload.editedValue);
          const reason = payload.reason;
          const comments = payload.comments;
          let tempEditedSTOList = state.editedSTOList;
          let indexToUpdate = tempEditedSTOList.findIndex(
            (obj) => obj.pro_id === sto.pro_id
          );
          if (indexToUpdate !== -1) {
            tempEditedSTOList[indexToUpdate] = {
              pro_id: sto.pro_id,
              user_pr_quantity: editedValue,
              reason_code: reason ? reason : sto.reason_code,
              comments: comments ? comments : sto.comments,
            };
            state.editedSTOList = [...tempEditedSTOList];
          } else {
            state.editedSTOList = [
              ...tempEditedSTOList,
              {
                pro_id: sto.pro_id,
                user_pr_quantity: editedValue,
                reason_code: reason ? reason : sto.reason_code,
                comments: comments ? comments : sto.comments,
              },
            ];
          }
          break;
        }
        case "FILTER_OUT/STO_ID": {
          const pro_id = payload.pro_id;
          let tempEditedSTOList = state.editedSTOList;
          state.editedSTOList = tempEditedSTOList.filter(
            (item) => item.pro_id !== pro_id
          );
          break;
        }
        case "RESET": {
          state.editedSTOList = [];
          break;
        }
      }
    },
  },
});

export const {
  setCurrentEditSto,
  setOriginalSTOValues,
  setIsGroupLevelEditModeOn,
  setEditedSTOList,
} = rawMaterialPlanSlice.actions;
export default rawMaterialPlanSlice.reducer;
