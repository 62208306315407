import { useNavigate, useLocation, Link } from "react-router-dom";
import { BreadcrumbTemp } from "../job-schedular/jsConfigScreen";
import { useRef, createRef } from "react";
import UploadModal from "../modal/UploadModal";
import { CircularIndefiniteSpinner } from "../loading/LoadingIndex";
import "./tableUtils.css";
import { useDispatch } from "react-redux";
import { handleApiArrayResponse } from "../../utils/apiUtils";

const centeredTextStyle = {
  color: "#3D445C",
  textAlign: "center",
  fontSize: "13px",
  fontFamily: "Inter",
  fontWeight: 400,
  wordWrap: "break-word",
  cursor: "pointer",
};

const horizontalLineStyle = {
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
};
export const LoaderSpinner = ({ showLoader }) => {
  console.log({ showLoader });
  return (
    <div
      // style={{  }}
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        display: !showLoader ? "none" : undefined,
        zIndex: 300,
      }}
    >
      <CircularIndefiniteSpinner />
    </div>
  );
};
export const BreadcrumbMaker = (url, ...args) => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "7px",
        display: "inline-flex",
      }}
    >
      {args.map((item, index) => {
        return (
          <>
            <div
              style={centeredTextStyle}
              onClick={
                index === 0
                  ? () => {
                      navigate(url);
                    }
                  : null
              }
              className={index !== args.length - 1 ? "bc-link" : "path-name"}
            >
              {item}
            </div>
            {index !== args.length - 1 ? (
              <div style={horizontalLineStyle}>
                <BreadcrumbTemp />
                <BreadcrumbTemp />
              </div>
            ) : null}
          </>
        );
      })}
    </div>
  );
};

export const BreadcrumbMaker2 = (arr = [{ text: "text", link: "/snp" }]) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "7px",
        display: "inline-flex",
      }}
    >
      {arr.map((item, index) => (
        <>
          <Link
            to={item.link}
            state={{ tab: `${item?.tab}`, level: `${item?.level}` }}
            style={centeredTextStyle}
            // onClick={
            //   item.link === null
            //     ? null
            //     : () => {
            //         navigate(item.link);
            //       }
            // }
            className={item.link !== null ? "bc-link" : ""}
          >
            {item.text}
          </Link>
          {index !== arr.length - 1 ? (
            <div style={horizontalLineStyle}>
              <BreadcrumbTemp />
              <BreadcrumbTemp />
            </div>
          ) : null}
        </>
      ))}
    </div>
  );
};
export const PlanStatus = ({ row, props }) => {
  const { original } = row;
  const { approvalId, planStatus } = original;
  const { setopenApprovalCommentsModal, getComments } = props;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        flexGrow: "1",
        height: 22,
        alignItems: "center",
      }}
    >
      <div className="active-cell">{planStatus}</div>
      <button
        style={{
          background: "transparent",
          padding: "0px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "50%",
          border: "none",
        }}
        className={`info-button ${approvalId === null ? "info-disable" : null}`}
        onClick={() => {
          setopenApprovalCommentsModal(true);
          getComments(approvalId);
        }}
        disabled={approvalId === null}
      >
        <svg
          width="13"
          height="13"
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          r="5"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13 6.5C13 10.0899 10.0899 13 6.5 13C2.91015 13 0 10.0899 0 6.5C0 2.91015 2.91015 0 6.5 0C10.0899 0 13 2.91015 13 6.5ZM7.3125 3.25C7.3125 3.69873 6.94873 4.0625 6.5 4.0625C6.05127 4.0625 5.6875 3.69873 5.6875 3.25C5.6875 2.80127 6.05127 2.4375 6.5 2.4375C6.94873 2.4375 7.3125 2.80127 7.3125 3.25ZM5.6875 5.6875C5.23877 5.6875 4.875 6.05127 4.875 6.5C4.875 6.94873 5.23877 7.3125 5.6875 7.3125V9.75C5.6875 10.1987 6.05127 10.5625 6.5 10.5625H7.3125C7.76123 10.5625 8.125 10.1987 8.125 9.75C8.125 9.30127 7.76123 8.9375 7.3125 8.9375V6.5C7.3125 6.05127 6.94873 5.6875 6.5 5.6875H5.6875Z"
            fill="#A3AAC2"
          />
        </svg>
      </button>
    </div>
  );
};

export const ApprovalStatus = ({ row, props }) => {
  const { original } = row;
  const { approvalDetails, approvalId } = original;
  return (
    <div
      style={{
        display: "flex",
        gap: "10px",
        justifyContent: "center",
        margin: "auto",
      }}
    >
      {" "}
      {approvalId === null && (
        <>
          {/* <div className={`status-indicator-app awaiting-status}`}></div>
              <div className={`status-indicator-app awaiting-status}`}></div> */}
        </>
      )}
      {approvalId !== null &&
        approvalDetails &&
        approvalDetails.levelwiseApprovalStatus.map((levelItem, index) => (
          <div
            className={`status-indicator-app ${
              levelItem.status !== "APPROVED"
                ? levelItem.status !== "REJECTED"
                  ? levelItem.status !== "PENDING"
                    ? "awaiting-status"
                    : "pending-status"
                  : "rejected-status"
                : "approved-status"
            }`}
            key={index}
          ></div>
        ))}
    </div>
  );
};

export const ApprovedMarker = () => (
  <div style={{ display: "flex", gap: "10px", justifyContent: "center" }}>
    <div className="status-indicator-app"></div>
    <div className="status-indicator-app"></div>
    <div className="status-indicator-app"></div>
    <div className="status-indicator-app"></div>
    <div className="status-indicator-app"></div>
  </div>
);

export const ViewPageButton = ({ row, props }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <div style={{ width: "100%", display: "flex" }}>
      <button
        className="table-action-button view-button-preprocess"
        style={{ margin: "auto" }}
        onClick={() => {
          // dispatch(setPpAppGroupPlanViewDetails({ ...row.original }));
          navigate(props.url);
        }}
      >
        View
      </button>
    </div>
  );
};
export const ActionButton = () => {
  //send or revoke
};

export function RenderMultipleButtons(
  componentArray,
  props,
  centerAlign = true,
  multipleElements = false
) {
  const numberOfRefs = componentArray.length;
  //   const refsArray = useRef(
  //     Array.from({ length: numberOfRefs }, () => createRef())
  //   );
  return (
    <div className={!centerAlign ? "action-buttons" : "action-buttons-align"}>
      {Array.from({ length: numberOfRefs }, (_, index) => {
        const Comp = componentArray[index];

        return (
          <div
            id={index}
            key={index}
            // ref={refsArray.current[index]}
            placeholder={`Ref ${index + 1}`}
            style={{
              pointerEvents: props.row.original.isDisabled ? "none" : "auto",
              display: "flex",
              width: multipleElements ? "" : "100%",
            }}
            // style={{
            //   pointerEvents: props.row.original.isDisabled ? "none" : "auto",
            // }}
          >
            <Comp row={props.row} props={props} />
          </div>
        );
      })}
    </div>
  );
}

export const RenderLevelButtons = (level, setLevel) => {
  return (
    <div className="level-btns">
      <button
        onClick={() => setLevel("org")}
        className={level === "org" ? "level-button active-btn" : "level-button"}
      >
        ORG Level
      </button>
      <button
        onClick={() => {
          console.log("clicled");
          setLevel("group");
        }}
        className={
          level === "group" ? "level-button active-btn" : "level-button"
        }
      >
        Group Level
      </button>
    </div>
  );
};

export const SendRevokeAction = ({ row, props }) => {
  return (
    <div
      style={{
        border: "1px solid #d9d9d9",
        borderRadius: "19px",
        padding: "2px 4px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // marginRight: "16px",
        boxSizing: "border-box",
        // width: "100%",
      }}
    >
      {/* <UploadModal
        openModal={props.openSendModal}
        setOpenModal={props.setOpenSendModal}
        modalType="send"
        rowId={row.planId}
        // handleGroupData={handleGroupData}
      /> */}
      <div
        style={{
          fontSize: "11px",
          marginRight: "0px",
          marginBottom: "unset",
          marginTop: "unset",
          lineHeight: "unset",
          minWidth: "50px",
          textAlign: "center",
        }}
      >
        {row.approvalStatus === "Send" ? "Send" : "Revoke"}
      </div>
      <label
        className="send-revoke-box small-switch"
        onClick={() => {
          props.setOpenSendModal(true);
          props.setPlanId(row.planId);
        }}
      >
        <input
          type="checkbox"
          className="send-revoke-input"
          // defaultChecked
          checked={row.approvalStatus === "Send"}
        />
        <span className="send-revoke-slider send-revoke-round"></span>
      </label>
    </div>
  );
};

export const renderSTOTableHeader = (
  stoValues = {
    all: 0,
    approved: 0,
    declined: 0,
    pending: 0,
  },
  selectedTab = null,
  setSelectedTab = null,
  setClearRowSelection = null,
  handleStatusTabs = null,
  ppAppGroupLevelPlanDetails,
  setppAppGroupLevelPlanDetails,
  editMode,
  setEditMode,
  submitLoading,
  approveLoading,
  setapproveLoading,
  rejectLoading,
  setRejectLoading,
  setSelectedGroupPlan,
  setOpenSendModal,
  setApproveRejectValue,
  setOpenApproveRejectModal,
  openMakeLiveModal,
  setOpenMakeLiveModal,
  loadingMakePlanLive
) => {
  const { approvalDetails, approvalId, planStatus } =
    ppAppGroupLevelPlanDetails;
  let userDetails = JSON.parse(localStorage.getItem("user"));
  const { permissions } = userDetails;
  const isPlanner = permissions.includes("STO_SUBMIT_APPROVAL");
  const isApprover = permissions.includes("STO_APPROVE_REJECT");
  const approveRejectDisable = () => {
    if (approvalId !== null && approvalDetails) {
      if (
        approvalDetails.permissions.canApprove === true &&
        approvalDetails.permissions.canReject === true
      ) {
        return false;
      } else {
        return true;
      }
    }
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        marginBottom: "6px",
        marginTop: "auto",
      }}
    >
      {!editMode && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "15px",
          }}
        >
          {" "}
          {approvalId !== null && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "15px",
                border: "1px solid #E6E6E6",
                borderRadius: "50px",
                padding: "5px 10px",
              }}
            >
              <span
                style={{
                  fontFamily: "Inter",
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "#3D445C",
                  marginBottom: "unset",
                }}
              >
                Approval Status:
              </span>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "center",
                }}
              >
                {" "}
                {approvalId === null && (
                  <>
                    {/* <div className={`status-indicator-app awaiting-status}`}></div>
              <div className={`status-indicator-app awaiting-status}`}></div> */}
                  </>
                )}
                {approvalId !== null &&
                  approvalDetails &&
                  approvalDetails.levelwiseApprovalStatus.map(
                    (levelItem, index) => (
                      <div
                        className={`status-indicator-app ${
                          levelItem.status !== "APPROVED"
                            ? levelItem.status !== "REJECTED"
                              ? levelItem.status !== "PENDING"
                                ? "awaiting-status"
                                : "pending-status"
                              : "rejected-status"
                            : "approved-status"
                        }`}
                        key={index}
                      ></div>
                    )
                  )}
              </div>
            </div>
          )}
          {isPlanner && approvalId === null && (
            <div style={{ display: "flex" }}>
              <div
                style={{
                  border: "1px solid #d9d9d9",
                  borderRadius: "19px",
                  padding: "2px 4px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxSizing: "border-box",
                  margin: "auto",
                  minWidth: "93px",
                  // opacity:
                  //   approvalDetails.permissions.canSubmit === true ||
                  //   approvalDetails.permissions.canRevoke ||
                  //   planStatus === "Planned"
                  //     ? 1
                  //     : 0.4,
                }}
                // disabled={true}
              >
                {submitLoading ? (
                  <div
                    style={{
                      paddingTop: "4px",
                      paddingBottom: "4px",
                    }}
                  >
                    <TableLoaderSpinner size={12} height="2vh" />
                  </div>
                ) : (
                  <>
                    <span
                      style={{
                        fontSize: "11px",
                        marginRight: "0px",
                        marginBottom: "unset",
                        marginTop: "unset",
                        lineHeight: "unset",
                        minWidth: "50px",
                        textAlign: "center",
                      }}
                    >
                      {/* {approvalStatusValues[row.planId] ? "Send" : "Revoke"} */}
                      {/* {approvalId !== null && approvalDetails
                  ? approvalDetails.permissions.canSubmit === true
                    ? "Send"
                    : approvalDetails.permissions.canRevoke === true
                    ? "Revoke"
                    : "Send"
                  : null} */}
                      Send
                    </span>
                    <label
                      className="send-revoke-box small-switch"
                      onClick={() => {
                        setSelectedGroupPlan({ ...ppAppGroupLevelPlanDetails });
                        setApproveRejectValue("SUBMITTED");
                        setOpenSendModal(true);

                        // setSelectedGroupPlan({ ...groupPlanDetails });
                        // setOpenSendModal(true);
                        // setApproveRejectValue("SUBMITTED");
                        // if (
                        //   approvalDetails.permissions.canSubmit === true ||
                        //   approvalDetails.permissions.canRevoke === true
                        // ) {
                        //   setSelectedGroupPlan(row);
                        //   setOpenSendModal(true);
                        //   const actionValue =
                        //     approvalDetails.permissions.canSubmit === true
                        //       ? "SUBMITTED"
                        //       : approvalDetails.permissions.canRevoke === true
                        //       ? "REVOKED"
                        //       : "";
                        //   setApproveRejectValue(actionValue);
                        // }
                      }}
                      disabled={true}
                    >
                      <input
                        type="checkbox"
                        className="send-revoke-input"
                        // defaultChecked
                        disabled
                        // checked={!approvalStatusValues[row.planId]}
                        // checked={approvalDetails.permissions.canRevoke}
                      />
                      <span className="send-revoke-slider send-revoke-round"></span>
                    </label>
                  </>
                )}
              </div>
            </div>
          )}
          {isPlanner && approvalId !== null && approvalDetails && (
            <div style={{ display: "flex" }}>
              <div
                style={{
                  border: "1px solid #d9d9d9",
                  borderRadius: "19px",
                  padding: "2px 4px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxSizing: "border-box",
                  margin: "auto",
                  minWidth: "93px",
                  opacity:
                    approvalDetails.permissions.canSubmit === true ||
                    approvalDetails.permissions.canRevoke
                      ? 1
                      : 0.4,
                }}
                // disabled={true}
              >
                {submitLoading ? (
                  <div
                    style={{
                      paddingTop: "4px",
                      paddingBottom: "4px",
                    }}
                  >
                    <TableLoaderSpinner size={12} height="2vh" />
                  </div>
                ) : (
                  <>
                    {" "}
                    <span
                      style={{
                        fontSize: "11px",
                        marginRight: "0px",
                        marginBottom: "unset",
                        marginTop: "unset",
                        lineHeight: "unset",
                        minWidth: "50px",
                        textAlign: "center",
                      }}
                    >
                      {/* {approvalStatusValues[row.planId] ? "Send" : "Revoke"} */}
                      {approvalId !== null && approvalDetails
                        ? approvalDetails.permissions.canSubmit === true
                          ? "Send"
                          : approvalDetails.permissions.canRevoke === true
                          ? "Revoke"
                          : "Revoke"
                        : null}
                    </span>
                    <label
                      className="send-revoke-box small-switch"
                      onClick={() => {
                        if (
                          approvalDetails.permissions.canSubmit === true ||
                          approvalDetails.permissions.canRevoke === true
                        ) {
                          setSelectedGroupPlan({
                            ...ppAppGroupLevelPlanDetails,
                          });
                          setOpenSendModal(true);
                          const actionValue =
                            approvalDetails.permissions.canSubmit === true
                              ? "SUBMITTED"
                              : approvalDetails.permissions.canRevoke === true
                              ? "REVOKED"
                              : "";
                          setApproveRejectValue(actionValue);
                        }
                      }}
                      disabled={true}
                    >
                      <input
                        type="checkbox"
                        className="send-revoke-input"
                        // defaultChecked
                        disabled
                        // checked={!approvalStatusValues[row.planId]}
                        checked={approvalDetails.permissions.canRevoke}
                      />
                      <span className="send-revoke-slider send-revoke-round"></span>
                    </label>
                  </>
                )}
              </div>
            </div>
          )}
          {isApprover && approvalId !== null && approvalDetails && (
            <div style={{ display: "flex" }}>
              <div className="approval-btns">
                <button
                  className="approval-btn approve"
                  onClick={() => {
                    setOpenApproveRejectModal(true);
                    setSelectedGroupPlan({ ...ppAppGroupLevelPlanDetails });
                    setApproveRejectValue("APPROVED");
                  }}
                  disabled={
                    approveRejectDisable() || approveLoading || rejectLoading
                  }
                >
                  {approveLoading ? (
                    <TableLoaderSpinner size={12} height="2vh" />
                  ) : (
                    "Approve"
                  )}
                  {/* <TableLoaderSpinner size={12} height="2vh" /> */}
                </button>
                <button
                  className="approval-btn reject"
                  onClick={() => {
                    setOpenApproveRejectModal(true);
                    setApproveRejectValue("REJECTED");
                    setSelectedGroupPlan({ ...ppAppGroupLevelPlanDetails });
                  }}
                  disabled={
                    approveRejectDisable() || approveLoading || rejectLoading
                  }
                >
                  {rejectLoading ? (
                    <TableLoaderSpinner size={12} height="2vh" />
                  ) : (
                    "Reject"
                  )}
                </button>
              </div>
            </div>
          )}
          <button
            className={
              planStatus !== "Approved" || (isApprover && planStatus !== "Live")
                ? planStatus !== "Live"
                  ? "grp-action-live-btn grp-action-live-btn-view"
                  : "grp-action-live-btn live-btn-enable grp-action-live-btn-view"
                : "grp-action-live-btn grp-action-live-enable grp-action-live-btn-view"
            }
            style={{ margin: "auto" }}
            disabled={
              planStatus !== "Approved" || (isApprover && planStatus !== "Live")
            }
            onClick={() => {
              setSelectedGroupPlan({ ...ppAppGroupLevelPlanDetails });
              setOpenMakeLiveModal(true);
            }}
          >
            {loadingMakePlanLive ? (
              <div
                style={{
                  padding: "3px 5.5px",
                }}
              >
                <TableLoaderSpinner size={12} height="2vh" />
              </div>
            ) : (
              "Live"
            )}
          </button>
        </div>
      )}
      <div style={{ display: "flex", gap: "15px" }}>
        <div
          className={`sto-card ${
            selectedTab === "all" ? "sto-card-active" : ""
          }`}
          onClick={() => {
            setClearRowSelection(true);
            setSelectedTab("all");
          }}
        >
          <span className="sto-card-heading">All</span>
          <span>{stoValues.all}</span>
        </div>
        <div
          className={`sto-card ${
            selectedTab === "accepted" ? "sto-card-active" : ""
          }`}
          onClick={() => {
            setClearRowSelection(true);
            setSelectedTab("accepted");
          }}
        >
          <span>Accepted</span>
          <span>{stoValues.approved}</span>
        </div>
        <div
          className={`sto-card ${
            selectedTab === "pending" ? "sto-card-active" : ""
          }`}
          onClick={() => {
            setClearRowSelection(true);
            setSelectedTab("pending");
          }}
        >
          <span style={{ background: "transparent" }}>Pending</span>
          <span>{stoValues.pending}</span>
        </div>
        <div
          className={`sto-card ${
            selectedTab === "rejected" ? "sto-card-active" : ""
          }`}
          onClick={() => {
            setClearRowSelection(true);
            setSelectedTab("rejected");
          }}
        >
          <span style={{ background: "unset" }}>Declined</span>
          <span>{stoValues.declined}</span>
        </div>
      </div>
    </div>
  );
};

export const NormalCell = (cell, row) => {
  return (
    <div className={row.original.isDisabled ? "disabled-cell" : "active-cell"}>
      {cell.getValue()}
    </div>
  );
};

export const TableLoaderSpinner = ({ size, height }) => (
  <div
    style={{
      width: "100%",
      height: height,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <CircularIndefiniteSpinner size={size} />
  </div>
);

TableLoaderSpinner.defaultProps = {
  size: 40,
  height: "80vh",
};

export const useUrlParams = () => {
  const location = useLocation();
  const path = location.pathname;
  let idArr = path.split("/");
  return idArr;
};

export const filterTableData = (tableData, selectedTab) => {
  let filteredData = tableData;
  if (selectedTab === "accepted") {
    filteredData = tableData.filter((row) => row.status === "accepted");
  } else if (selectedTab === "rejected") {
    filteredData = tableData.filter((row) => row.status === "rejected");
  } else if (selectedTab === "pending") {
    filteredData = tableData.filter((row) => row.status === "pending");
  } else {
    filteredData = tableData;
  }
  return filteredData;
};

export const transformStatus = (str) => {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  } else {
    return str;
  }
};

export const capitalizeString = (str) => {
  return str !== null && str !== ""
    ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
    : "";
};
