import {
    ChevronLeftIcon,
    ChevronRightIcon,
    DoubleArrowLeftIcon,
    DoubleArrowRightIcon,
  } from "@radix-ui/react-icons";
  import { useEffect, useState, useRef } from "react";
  
  import { Button } from "@/components/ui/button";
  import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
  } from "@/components/ui/select";
  
  export function ServerDataTablePagination({ table, showColorCodingLegend, planId, setTableData, paginationData, setPaginationData, setLoadingData, pageSize }) {
    // const pageSize = useRef(10);
    const handleNextPageButton = (pageNumber, size = pageSize.current) => {
      // setLoadingData(true);
      fetch(
        `${process.env.REACT_APP_SCAI_API_ENDPOINT}/v3/replenishment_plans/${planId}/${pageNumber}&page_size=${size}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
          },
        }
      ).then(response => response.json()).then(data => {
        const updatedResponseData = {
          last: data.last,
          next: data.next,
          previous: data.previous,
          page_size: data.page_size,
        };
        // setLoadingData(false);
        setPaginationData(updatedResponseData);
        setTableData(data.results);
      });
    };
  
    useEffect(() => {
      // Initial fetch to populate pagination data
      handleNextPageButton('?page=1'); // Assuming 1 is the initial page
    }, []);
    return (
      <div className="flex items-center justify-between px-2 align-center">
        <div className=" flex flex-1 text-sm text-muted-foreground lg:text-xl">
          <div>
            {table.getFilteredSelectedRowModel().rows.length} of{" "}
            {table.getFilteredRowModel().rows.length} row(s) selected
          </div>
          {showColorCodingLegend && (
            <div className="flex gap-2 items-center text-lg ml-3 font-[Inter] text-[#35445c] ">
              <div className="flex items-center gap-1">
                <span className="border border-[#d9d9d9] h-4 w-4 bg-white"></span>
                <span>System </span>
              </div>
              <div className="flex items-center gap-1">
                <span className="border border-[#d9d9d9] h-4 w-4 bg-[#E1F4F4]"></span>
                <span>Manual </span>
              </div>
              <div className="flex items-center gap-1">
                <span className="border border-[#d9d9d9] h-4 w-4 bg-[#EDEBFF]"></span>
                <span>Edited </span>
              </div>
            </div>
          )}
        </div>
        <div className="flex items-center space-x-6 lg:space-x-8">
          <div className="flex items-center space-x-2">
            <p className="text-sm font-medium lg:text-xl">Rows per page</p>
            <Select
              value={`${table.getState().pagination.pageSize}`}
              onValueChange={(value) => {
                table.setPageSize(Number(value));
                pageSize.current = Number(value);
                handleNextPageButton(`?page=${paginationData?.next?.split("=")[1] - 1}`,value);
              }}
            >
              <SelectTrigger className="h-8 w-[70px] lg:h-11">
                <SelectValue placeholder={table.getState().pagination.pageSize} />
              </SelectTrigger>
              <SelectContent side="top">
                {[10, 20, 50, 100, 250].map((pageSize) => (
                  <SelectItem key={pageSize} value={`${pageSize}`}>
                    {pageSize}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="flex w-[100px] items-center justify-center text-sm font-medium lg:text-xl">
            Page {paginationData.next === null ? paginationData?.last?.split("=")[1] : Number(paginationData?.next?.split("=")[1]) - 1} of{" "}
            {paginationData.last && Number(paginationData?.last?.split("=")[1])}
          </div>
          <div className="flex items-center space-x-2 lg:space-x-6">
            <Button
              variant="outline"
              className="h-8 w-8 p-0 lg:h-11 lg:w-11"
              onClick={() => handleNextPageButton('?page=1')}
              disabled={paginationData.previous === null}
            >
              <span className="sr-only">Go to first page</span>
              <DoubleArrowLeftIcon className="h-4 w-4 lg:h-6 lg:w-6" />
            </Button>
            <Button
              variant="outline"
              className="h-8 w-8 p-0 lg:h-11 lg:w-11"
              onClick={() => handleNextPageButton(paginationData.previous)}
              disabled={paginationData.previous === null}
            >
              <span className="sr-only">Go to previous page</span>
              <ChevronLeftIcon className="h-4 w-4 lg:h-6 lg:w-6" />
            </Button>
            <Button
              variant="outline"
              className="h-8 w-8 p-0 lg:h-11 lg:w-11"
              onClick={() => handleNextPageButton(paginationData.next)}
              disabled={paginationData.next===null}
            >
              <span className="sr-only">Go to next page</span>
              <ChevronRightIcon className="h-4 w-4 lg:h-6 lg:w-6" />
            </Button>
            <Button
              variant="outline"
              className="h-8 w-8 p-0 lg:h-11 lg:w-11"
              onClick={() => handleNextPageButton(paginationData.last)}
              disabled={paginationData.next===null}
            >
              <span className="sr-only">Go to last page</span>
              <DoubleArrowRightIcon className="h-4 w-4 lg:h-6 lg:w-6" />
            </Button>
          </div>
        </div>
      </div>
    );
  }