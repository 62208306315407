const UploadIcon = ({ color, height }) => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4306_39168)">
      <path
        d="M3.37338 4.13531L6.1249 1.71028V9.12506C6.1249 9.33234 6.29261 9.50006 6.49989 9.50006C6.70717 9.50006 6.87488 9.33234 6.87488 9.12506V1.71028L9.62639 4.15476C9.6967 4.21968 9.78576 4.25015 9.87483 4.25015C9.97809 4.25015 10.0814 4.20768 10.1554 4.12418C10.2931 3.96963 10.2784 3.73231 10.1239 3.59497L6.74893 0.595026C6.60686 0.468325 6.39292 0.468325 6.25089 0.595026L2.87596 3.59497C2.72136 3.73219 2.70683 3.96891 2.84371 4.12359C2.98058 4.27828 3.22104 4.29234 3.37338 4.13531ZM10.9998 8.00008H8.37485C8.16776 8.00008 7.99986 8.16794 7.99986 8.37508C7.99986 8.58217 8.16776 8.75007 8.37485 8.75007H10.9998C11.4139 8.75007 11.7498 9.08592 11.7498 9.50006V11C11.7498 11.4142 11.4139 11.75 10.9998 11.75H1.99997C1.58584 11.75 1.24999 11.4142 1.24999 11V9.50006C1.24999 9.08592 1.58584 8.75007 1.99997 8.75007H4.62492C4.83117 8.75007 4.99992 8.58132 4.99992 8.37508C4.99992 8.16883 4.83117 8.00008 4.62492 8.00008H1.99997C1.17147 8.00008 0.5 8.67155 0.5 9.50006V11C0.5 11.8285 1.17147 12.5 1.99997 12.5H10.9998C11.8283 12.5 12.4998 11.8285 12.4998 11V9.50006C12.4998 8.67273 11.8271 8.00008 10.9998 8.00008ZM10.8123 10.25C10.8123 9.9395 10.5604 9.68755 10.2498 9.68755C9.93928 9.68755 9.68733 9.9395 9.68733 10.25C9.68733 10.5606 9.93928 10.8125 10.2498 10.8125C10.5604 10.8125 10.8123 10.5618 10.8123 10.25Z"
        fill="#3D445C"
      />
    </g>
    <defs>
      <clipPath id="clip0_4306_39168">
        <rect
          width="12"
          height="12"
          fill="white"
          transform="translate(0.5 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default UploadIcon;
