import { useState, useEffect, useRef, createRef } from "react";
import MuiTable from "../../../components/muiTable/muiTable";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./styles.css";
import SelectInput from "../../../components/add-sto/SelectInput";
import TextInput from "../../../components/add-sto/TextInput";
import DateInput from "../../../components/add-sto/DateInput";
import { useCreateStoMutation } from "../../../features/api/replPlanSlice";
import { toast } from "react-toastify";

function RMCreateSTO() {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  let pathSplit = path.split("/");
  let len = pathSplit.length;
  const planId = pathSplit[len - 1];
  const [tableData, setTableData] = useState([]);
  const skuCodeOptions = [
    "MAGORIG1-Maggi Noodles-50g-Veg Schezwan",
    "MAGORIG2-Maggi Noodles-100g-Veg Schezwan",
    "MAGORIG3-Maggi Noodles-50g-Chicken",
    "MAGORIG4-Maggi Noodles-100g-Chicken",
  ];
  const sourceIdOptions = ["Source 1", "Source 2", "Source 3"];
  const destinationIdOptions = [
    "Destination 1",
    "Destination 2",
    "Destination 3",
  ];
  const channelIdOptions = ["Channel 1", "Channel 2", "Channel 3"];
  const [skuCode, setSkuCode] = useState("");
  const [destinationId, setDestinationId] = useState("");
  const [sourceId, setSourceId] = useState("");
  const [channelId, setChannelId] = useState("");
  const [shippingDate, setshippingDate] = useState("");
  const [replenishmentQuantity, setreplenishmentQuantity] = useState("");
  const [stoType, setstoType] = useState("");
  const [transMode, setTransMode] = useState("");
  const [stockTransfer, setstockTransfer] = useState("");
  const [status, setstatus] = useState("");
  const [addSTO, { isLoadingUpdateSTOStatus, isErrorUpdateSTOStatus }] =
    useCreateStoMutation();

  const transModeOptions = ["Air", "Water", "Road", "Railways"];

  const stockTransferOptions = ["Stock Transfer"];

  const handleSaveAddedSTO = () => {
    let addedSTO = {
      skuCode: skuCode,
      channelId: channelId,
      sourceId: sourceId,
      destinationId: destinationId,
      shippingDate: shippingDate,
      replenishmentQuantity: replenishmentQuantity,
      stoType: stoType,
      transMode: transMode,
      status: status === "" ? "pending" : status,
    };
    setTableData([...tableData, addedSTO]);
    setSkuCode("");
    setChannelId("");
    setDestinationId("");
    setSourceId("");
    setshippingDate("");
    setreplenishmentQuantity("");
    setstoType("");
    setTransMode("");
    setstatus("");
  };

  const saveAllAddedSTO = async () => {
    let requestBody = tableData.map((sto) => ({
      start_node: sto.sourceId,
      sku_code: sto.skuCode,
      planned_replenishment_quantity: sto.replenishmentQuantity,
      transport_mode_type: sto.transMode,
      channel_id: sto.channelId,
      end_node: sto.destinationId,
      date: sto.shippingDate,
    }));
    navigate(`/snp/inventory/replenishment-plan/group-plan/${planId}`);
    try {
      const response = await addSTO({
        planId,
        requestBody: requestBody,
      });
    } catch (error) {
      toast.error("STO Creation Failed");
    }
  };

  return tableData ? (
    <div style={{ padding: "8px 12px" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          alignSelf: "center",
          marginTop: "5px",
          marginBottom: "3px",
        }}
        className="process-header"
      >
        <div
          className="process-header-1"
          onClick={() =>
            navigate("/snp/inventory/replenishment-plan", {
              state: { planLevel: "group" },
            })
          }
        >
          Group Level
        </div>
        <div>
          <svg
            width="9"
            height="9"
            viewBox="0 0 9 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 1L4.5 4.5L1 8" stroke="#858585" strokeLinecap="round" />
            <path
              d="M4.5 1L8 4.5L4.5 8"
              stroke="#929292"
              strokeLinecap="round"
            />
          </svg>
        </div>
        <div
          className="process-header-2 add-sto-header"
          onClick={() =>
            navigate(`/snp/inventory/replenishment-plan/group-plan/${planId}`)
          }
        >{`Plan View : ${planId}`}</div>{" "}
        <div>
          <svg
            width="9"
            height="9"
            viewBox="0 0 9 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 1L4.5 4.5L1 8" stroke="#858585" strokeLinecap="round" />
            <path
              d="M4.5 1L8 4.5L4.5 8"
              stroke="#929292"
              strokeLinecap="round"
            />
          </svg>
        </div>
        <div className="process-header-2">Add STO</div>{" "}
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "12px",
          justifyContent: "space-between",
          paddingTop: "12px",
        }}
      >
        <SelectInput
          label="SKU Code"
          placeholderValue="Select SKU Code"
          options={skuCodeOptions}
          inputValue={skuCode}
          handleInputValue={setSkuCode}
        />
        <SelectInput
          label="Source ID"
          placeholderValue="Select Source ID"
          options={sourceIdOptions}
          inputValue={sourceId}
          handleInputValue={setSourceId}
        />
        <SelectInput
          label="Destination ID"
          placeholderValue="Select Destination ID"
          options={destinationIdOptions}
          inputValue={destinationId}
          handleInputValue={setDestinationId}
        />
        <SelectInput
          label="Channel ID"
          placeholderValue="Select Channel"
          options={channelIdOptions}
          inputValue={channelId}
          handleInputValue={setChannelId}
        />
        <DateInput
          label="Shipping Date"
          inputValue={shippingDate}
          handleInputValue={setshippingDate}
        />
        <TextInput
          placeholderValue="Enter Quantity"
          label="Replenishment Quantity"
          inputValue={replenishmentQuantity}
          handleInputValue={setreplenishmentQuantity}
        />
        <SelectInput
          label="STO Type"
          placeholderValue="Select STO Type"
          options={stockTransferOptions}
          inputValue={stockTransfer}
          handleInputValue={setstockTransfer}
        />
        <SelectInput
          label="Trans Mode"
          placeholderValue="Select Trans Mode"
          options={transModeOptions}
          inputValue={transMode}
          handleInputValue={setTransMode}
        />{" "}
      </div>
      <div className="add-sto-buttons">
        <select
          className="add-sto-select-status"
          onChange={(e) => setstatus(e.target.value)}
          value={status}
        >
          <option value="">Select Status</option>
          <option value="accepted">Accepted</option>
          <option value="rejected">Rejected</option>
          <option value="pending">Pending</option>
        </select>
        <button className="add-sto-save" onClick={handleSaveAddedSTO}>
          Save & Add Another
        </button>
      </div>
      <hr className="add-sto-line" />{" "}
      {tableData && (
        <div
          className="snp-table-container"
          // style={{ width: `calc( 100% - 20px)`, marginLeft: "10px" }}
        >
          <MuiTable
            columns={[
              {
                accessorKey: "skuCode",
                header: "SKU Code",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "sourceId",
                header: "Source ID",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "destinationId",
                header: "Destination ID",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "channelId",
                header: "Channel ID",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "shippingDate",
                header: "Shipping Date",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "stoTransfer",
                header: "Stock Transfer",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "replenishmentQuantity",
                header: "Replenishment Quantity",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "status",
                header: "Status",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "transMode",
                header: "Transport Mode",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
            ]}
            td={tableData}
            shouldShowAddBtn={false}
            // renderTopToolbarCustomActions={renderPreprocessesHeading}
          />
        </div>
      )}
      <div className="add-sto-save-cancel-buttons">
        <button
          className="add-sto-cancel-button"
          onClick={() => {
            navigate(`/snp/inventory/replenishment-plan/group-plan/${planId}`);
          }}
        >
          Cancel
        </button>
        <button className="add-sto-save-button" onClick={saveAllAddedSTO}>
          Save
        </button>
      </div>
    </div>
  ) : (
    "loading"
  );
}

export default RMCreateSTO;
