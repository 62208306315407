import React, { useState } from "react";
import "react-calendar/dist/Calendar.css";
import "./GlobalCalendar.scss";
import Calendar from "react-calendar";
import { isSameDay, addDays } from "date-fns";

function GlobalCalendar({ openCalendar, setOpenCalendar }) {
  const [value, setValue] = useState(new Date());
  const today = new Date(); // Current date
  const plannedDays = [
    new Date("2024-01-31"),
    new Date("2024-01-04"),
    new Date("2024-01-15"),
    new Date("2024-01-17"),
    new Date("2024-01-19"),
    new Date("2024-02-05"),
    new Date("2024-02-16"),
    new Date("2024-02-09"),
    new Date("2024-02-27"),
    new Date("2024-02-14"),
    new Date("2024-03-30"),
    new Date("2024-03-27"),
    new Date("2024-03-13"),
    new Date("2024-03-04"),
    new Date("2024-03-23"),
    new Date("2024-04-04"),
    new Date("2024-04-16"),
    new Date("2024-04-12"),
    new Date("2024-04-25"),
    new Date("2024-04-20"),
    new Date("2024-05-04"),
    new Date("2024-05-16"),
    new Date("2024-05-21"),
    new Date("2024-05-07"),
    new Date("2024-05-06"),
    new Date("2024-06-17"),
    new Date("2024-06-05"),
    new Date("2024-06-03"),
    new Date("2024-06-22"),
    new Date("2024-06-12"),
    new Date("2024-07-13"),
    new Date("2024-07-29"),
    new Date("2024-07-26"),
    new Date("2024-07-18"),
    new Date("2024-07-13"),
    new Date("2024-08-19"),
    new Date("2024-08-17"),
    new Date("2024-08-06"),
    new Date("2024-08-14"),
    new Date("2024-08-22"),
  ];
  const holidays = [
    new Date("2024-01-07"),
    new Date("2024-01-14"),
    new Date("2024-01-21"),
    new Date("2024-01-28"),
    new Date("2024-02-04"),
    new Date("2024-02-11"),
    new Date("2024-02-18"),
    new Date("2024-02-25"),
    new Date("2024-03-03"),
    new Date("2024-03-10"),
    new Date("2024-03-17"),
    new Date("2024-03-24"),
    new Date("2024-03-31"),
    new Date("2024-04-07"),
    new Date("2024-04-14"),
    new Date("2024-04-21"),
    new Date("2024-04-28"),
    new Date("2024-05-05"),
    new Date("2024-05-12"),
    new Date("2024-05-19"),
    new Date("2024-05-26"),
    new Date("2024-06-02"),
    new Date("2024-06-09"),
    new Date("2024-06-16"),
    new Date("2024-06-23"),
    new Date("2024-06-30"),
    new Date("2024-07-07"),
    new Date("2024-07-14"),
    new Date("2024-07-21"),
    new Date("2024-07-28"),
    new Date("2024-08-04"),
    new Date("2024-08-11"),
    new Date("2024-08-18"),
    new Date("2024-08-25"),
  ];

  const tileClassName = ({ date, view }) => {
    if (view === "month") {
      if (holidays.find((dDate) => isSameDay(dDate, date))) {
        return "holiday";
      }
      if (isSameDay(date, today)) {
        return "today";
      }
      if (plannedDays.find((dDate) => isSameDay(dDate, date))) {
        return "planned-day";
      }
    }
  };

  return (
    openCalendar && (
      <div className="global-calendar">
        <Calendar
          className="react-calendar-custom"
          tileClassName={tileClassName}
        />
      </div>
    )
  );
}

export default GlobalCalendar;
