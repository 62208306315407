import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const processData = (data) => {
  const nodeData = {};

  data.forEach(({ node, safety_stock_system, safety_stock_user }) => {
    if (!nodeData[node]) {
      nodeData[node] = {
        safety_stock_system: 0,
        safety_stock_user: 0,
        count: 0,
      };
    }
    nodeData[node].safety_stock_system += safety_stock_system;
    nodeData[node].safety_stock_user += safety_stock_user;
    nodeData[node].count += 1;
  });

  const categories = [];
  const safetyStockSystemData = [];
  const safetyStockUserData = [];

  for (const node in nodeData) {
    categories.push(node);
    safetyStockSystemData.push(
      Math.floor(nodeData[node].safety_stock_system / nodeData[node].count)
    );
    safetyStockUserData.push(
      Math.floor(nodeData[node].safety_stock_user / nodeData[node].count)
    );
  }

  return { categories, safetyStockSystemData, safetyStockUserData };
};

const ProcessViewGraph = ({ data }) => {
  const { categories, safetyStockSystemData, safetyStockUserData } =
    processData(data);

  const options = {
    chart: {
      type: "line",
      zoomType: "xy",
    },
    title: {
      text: "Safety Stock Levels",
      style: {
        fontWeight: "400",
        fontSize: "15px",
        fontFamily: "IBM Plex Sans",
        marginTop: "16px",
        marginRight: "auto",
        marginBottom: "15px",
      },
      x: -520,
    },
    xAxis: {
      categories,
      title: {
        text: "Nodes",
      },
    },
    yAxis: {
      title: {
        text: "Safety Stock",
      },
    },
    tooltip: {
      shared: true,
      crosshairs: true,
    },
    plotOptions: {
      line: {
        marker: {
          enabled: true,
          radius: 5,
        },
      },
      area: {
        fillOpacity: 0.1,
      },
    },
    series: [
      {
        name: "Safety Stock System",
        data: safetyStockSystemData,
        type: "area",
        color: "#ff8251",
        fillColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, "rgba(255, 130, 81, 0.5)"],
            [1, "rgba(255, 130, 81, 0)"],
          ],
        },
        marker: {
          enabled: true,
          radius: 5,
        },
      },
      {
        name: "Safety Stock User",
        data: safetyStockUserData,
        type: "column",
        pointWidth: 37.5,
        color: "#494949",
        marker: {
          enabled: true,
          radius: 5,
        },
      },
    ],
    exporting: {
      enabled: true,
    },
  };

  return (
    <div className="rounded-md border mt-5 p-2">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default ProcessViewGraph;
