const FilterIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.2421 0.755859H0.766991C0.122279 0.755859 -0.233724 1.4881 0.172989 1.97759L4.5034 7.18875V9.37051C4.5034 9.55376 4.59281 9.72563 4.74382 9.83074L6.62023 11.1412C6.71991 11.2105 6.8325 11.2433 6.94039 11.2433C7.23124 11.2433 7.50566 11.0138 7.50566 10.6604V7.18875L11.8355 1.97783C12.2225 1.4881 11.8871 0.755859 11.2421 0.755859ZM6.92867 6.7112L6.7551 6.91955V10.3209L5.25397 9.27242V6.91955L0.766991 1.50496H11.2421L11.2583 1.49929L6.92867 6.7112Z"
        fill="#3D445C"
      />
    </svg>
  );
};

export default FilterIcon;
