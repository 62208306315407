import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { styled } from "@mui/material/styles";
import { ChevronArrow, ChevronDown } from "../../assets/svg-icons";

export const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  // border: `1px solid ${theme.palette.divider}`,
  "&::before": {
    display: "none",
  },
  marginBottom: "10px",
  borderRadius: "10px",
}));

export const AccordionSummary = styled((props) => {
  let levelsBtn = null;
  let accordionTrigger = null;
  let modifiedProps = null;
  const childNodes = props.children;
  if (Array.isArray(childNodes)) {
    levelsBtn = childNodes[1];
    accordionTrigger = childNodes[0];
    modifiedProps = { ...props, children: [accordionTrigger] };
  } else {
    modifiedProps = props;
  }
  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom:
          props.showTypePanel && props.showTypePanel === true
            ? "1px solid #cbd5e1"
            : "",
      }}
    >
      <MuiAccordionSummary
        expandIcon={<ChevronArrow width={10} height={10} fill={props.fill} />}
        {...modifiedProps}
      />
      <span style={{ alignSelf: "end", fontSize: "12px" }}> {levelsBtn}</span>
      {childNodes && childNodes[2] ? childNodes[2] : []}
    </div>
  );
})(
  ({
    theme,
    expanded,
    hoverColor,
    backgroundColor = "white",
    expandedBgColor = "#e8f3fd",
  }) => {
    return {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: "4px",
      width: "53px",
      minHeight: "unset",
      height: "23px",
      alignSelf: "end",
      zIndex: 1,
      marginBottom: "-1px",
      backgroundColor: expanded ? "#e94f1c" : "#686867",
      flexDirection: "row",
      "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(180deg)",
      },
      "& .MuiAccordionSummary-content": {
        // marginLeft: theme.spacing(1),
      },
      padding: "0 3px 0 5px",
      "&:hover": {
        backgroundColor: hoverColor
          ? expanded
            ? "#e94f1c"
            : "#434341"
          : "none",
        cursor: "pointer",
      },
    };
  }
);

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  // padding: theme.spacing(2),
  padding: "unset",
  paddingTop: "5px",
  // borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
