import React, { useEffect, useState, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { pieChartData as data } from "./data";

// Group data by item type
const groupByItemType = (data) => {
  const groupedData = data.reduce((acc, item) => {
    const itemType = item["Item Type"];
    if (!acc[itemType]) {
      acc[itemType] = { quantity: 0, cost: 0 };
    }
    acc[itemType].quantity += item.Quantity;
    acc[itemType].cost += item["Item Cost"];
    return acc;
  }, {});

  return Object.keys(groupedData).map((itemType) => ({
    name: itemType,
    quantity: groupedData[itemType].quantity,
    cost: groupedData[itemType].cost,
  }));
};

const groupedData = groupByItemType(data);

const quantityData = groupedData.map((item) => ({
  name: item.name,
  y: item.quantity,
  color:
    item.name === "RM" ? "#FBC26A" : item.name === "FG" ? "#5FA2B9" : "#8A5863",
}));

const costData = groupedData.map((item) => ({
  name: item.name,
  y: item.cost,
  color:
    item.name === "RM" ? "#D5993A" : item.name === "FG" ? "#36778E" : "#6C3F49",
}));

const DonutChart = ({ componentWidth, snpWidth, componentHeight }) => {
  const [options, setOptions] = useState({});
  const chartRef = useRef(null);

  useEffect(() => {
    const chartOptions = {
      chart: {
        type: "pie",
      },
      title: {
        text: "",
      },
      plotOptions: {
        pie: {
          shadow: false,
          center: ["50%", "50%"],
          dataLabels: {
            enabled: false,
          },
          showInLegend: true,
        },
      },
      series: [
        {
          name: "Quantity",
          data: quantityData,
          size: "70%", // Make this series thicker
          innerSize: "40%", // Adjust the inner size for thickness
        },
        {
          name: "Cost",
          data: costData,
          size: "80%",
          innerSize: "70%", // Adjust the inner size for thickness
        },
      ],
      tooltip: {
        shared: true,
      },
      legend: {
        layout: "horizontal",
        align: "center",
        verticalAlign: "bottom",
        useHTML: false,
        labelFormatter: function () {
          const seriesName = this.series.name;
          const itemType = this.name;
          return `${seriesName} - ${itemType}`;
        },
      },
    };
    setOptions(chartOptions);
  }, []);

  useEffect(() => {
    const chart = chartRef.current.chart;
    // Function to handle resizing
    const handleResize = () => {
      chart.setSize(componentWidth, componentHeight);
    };
    handleResize();
  }, [snpWidth, componentWidth, componentHeight]);

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartRef}
      />
    </div>
  );
};

export default DonutChart;
