import { DataTable } from "@/components/tanstack-table/data-table";
import { useEffect, useState } from "react";
import { loadingColumns } from "../Production/Tables/columns";
import { generateColumns } from "./columns";
import "./styles.css";
import UploadModal from "@/components/modal/UploadModal";
import { handleDownloadApiResponse } from "@/utils/apiUtils";
import { toast } from "react-toastify";
import {
  useAllGroupsQuery,
  useOmsBaseTableQuery,
} from "@/features/api/ordersManagementSlice";
import { ordersMgmttColumnHeaders, transformData } from "./utils";
import { TableLoaderSpinner } from "@/components/tableUtils/tableUtils";

const OrdersManagement = () => {
  const [activeBtn, setActiveBtn] = useState("RP");
  const [columns, setColumns] = useState(loadingColumns());
  const [tableData, setTableData] = useState(Array(20).fill({}));
  const [openCreatePlanModal, setOpenCreatePlanModal] = useState(false);
  const [openMergePlanModal, setOpenMergePlanModal] = useState(false);
  const [addOrderModal, setAddOrderModal] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDownloadingTemplate, setIsDownloadingTemplate] = useState(false);
  const [selectedSTO, setSelectedSTO] = useState({});
  const [isUploadingSto, setIsUploadingSto] = useState(false);
  const [paginationData, setPaginationData] = useState({
    last: null,
    next: null,
    previous: null,
    page_size: 20,
    page: 1,
  });
  const [groupOptions, setGroupOptions] = useState([]);
  const [selectedGroupOption, setSelectedGroupOption] = useState(null);
  const [clearRowSelection, setClearRowSelection] = useState(false);

  const {
    data: allGroups,
    isFetching: isLoadingGroups,
    isSuccess: isSuccessGroups,
  } = useAllGroupsQuery(undefined, { refetchOnMountOrArgChange: true });

  const {
    data: omsData,
    isFetching: isLoadingTable,
    isSuccess: isSuccessOmsTableQuery,
    refetch: refetchOmsTableQuery,
  } = useOmsBaseTableQuery({
    page: paginationData.page,
    page_size: paginationData.page_size,
  });

  useEffect(() => {
    if (isSuccessGroups) {
      const rpGroups = allGroups?.results
        ?.filter((item) => item.module.name === "RP")
        ?.map((item) => ({
          id: item.id,
          value: item.name,
          label: item.name,
        }));
      setGroupOptions(rpGroups);
    }
  }, [allGroups]);

  useEffect(() => {
    if (isLoadingTable) {
      setColumns(loadingColumns());
    }
  }, [paginationData]);

  useEffect(() => {
    if (activeBtn === "RP") {
      if (isSuccessOmsTableQuery) {
        setTableData(transformData(omsData?.results ?? []));
        setColumns(generateColumns(ordersMgmttColumnHeaders));
        setPaginationData((prev) => {
          const next = omsData?.next ?? null;
          const last = omsData?.last ?? null;
          const previous = omsData?.previous ?? null;
          const page_size = omsData?.page_size ?? 20;
          return { ...prev, next, last, previous, page_size };
        });
      }
    }
  }, [omsData]);

  const renderModuleButtons = () => {
    return (
      <div style={{ background: "#686867" }} className="orders-mgmt-btns-box">
        {/* <div style={{ alignSelf: "center" }}> */}{" "}
        <UploadModal
          openModal={openCreatePlanModal}
          setOpenModal={setOpenCreatePlanModal}
          selectedSTO={Object.keys(selectedSTO)}
          refetch={refetchOmsTableQuery}
          setClearRowSelection={setClearRowSelection}
          modalType="create-plan"
        />
        <UploadModal
          openModal={openMergePlanModal}
          setOpenModal={setOpenMergePlanModal}
          selectedSTO={Object.keys(selectedSTO)}
          refetch={refetchOmsTableQuery}
          setClearRowSelection={setClearRowSelection}
          modalType="merge-plan"
        />
        <UploadModal
          openModal={addOrderModal}
          setOpenModal={setAddOrderModal}
          handleOrdersMgmtDownload={handleOrdersMgmtDownload}
          isDownloadingTemplate={isDownloadingTemplate}
          setIsDownloadingTemplate={setIsDownloadingTemplate}
          modalType="add-order"
          isLoadingGroups={isLoadingGroups}
          groupOptions={groupOptions}
          selectedGroupOption={selectedGroupOption}
          setSelectedGroupOption={setSelectedGroupOption}
          setisUploadingSto={setIsUploadingSto}
          refetchSTO={refetchOmsTableQuery}
        />
        <button
          className={`${
            activeBtn === "RP"
              ? "orders-mgmt-btn orders-mgmt-active-btn orders-mgmt-rp"
              : "orders-mgmt-btn orders-mgmt-rp"
          }`}
          onClick={() => setActiveBtn("RP")}
        >
          RP
        </button>
        <button
          className={`${
            activeBtn === "PP"
              ? "orders-mgmt-btn orders-mgmt-active-btn orders-mgmt-pp"
              : "orders-mgmt-btn orders-mgmt-pp"
          }`}
          onClick={() => setActiveBtn("PP")}
        >
          PP
        </button>
        <button
          className={`${
            activeBtn === "MRP"
              ? "orders-mgmt-btn orders-mgmt-active-btn orders-mgmt-mrp"
              : "orders-mgmt-btn orders-mgmt-mrp"
          }`}
          onClick={() => setActiveBtn("MRP")}
        >
          MRP
        </button>
      </div>
      //   </div>
    );
  };

  const handleOrdersMgmtDownload = (url, setIsDownloading) => {
    setIsDownloading(true);
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
      },
    })
      .then(handleDownloadApiResponse)
      .then((data) => {
        setIsDownloading(false);
        var a = document.createElement("a");
        a.href = window.URL.createObjectURL(data);
        a.download = `orders.csv`;
        a.click();
      })
      .catch((error) => {
        setIsDownloading(false);
        toast.error(error.message, { autoClose: 2000 });
      });
  };

  const AddIconTemp = () => (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 6.99966C14 7.29582 13.7577 7.53813 13.4615 7.53813H7.53846V13.4612C7.53846 13.7588 7.29764 14 7 14C6.70237 14 6.46154 13.7574 6.46154 13.4612V7.53813H0.538462C0.240827 7.53813 0 7.2975 0 7C0 6.70351 0.240962 6.4612 0.538462 6.4612H6.46154V0.538125C6.46154 0.24049 6.70237 0 7 0C7.29764 0 7.53846 0.240625 7.53846 0.538125V6.4612H13.4615C13.7577 6.4612 14 6.70351 14 6.99966Z"
        fill="white"
      />
    </svg>
  );

  const renderOrderMgmtActions = () => {
    return (
      <div className="flex gap-2 h-[28px]">
        <button
          className="orders-mgmt-action-btn"
          onClick={() => setOpenCreatePlanModal(true)}
          disabled={Object.keys(selectedSTO).length === 0}
        >
          Create plan
        </button>
        <button
          className="orders-mgmt-action-btn"
          onClick={() => setOpenMergePlanModal(true)}
          disabled={Object.keys(selectedSTO).length === 0}
        >
          Merge to plan
        </button>
        <button
          className="orders-mgmt-action-btn orders-mgmt-action-btn-upload"
          onClick={handleOrdersMgmtDownload}
        >
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.87338 3.63531L5.6249 1.21028V8.62506C5.6249 8.83234 5.79261 9.00006 5.99989 9.00006C6.20717 9.00006 6.37488 8.83234 6.37488 8.62506V1.21028L9.12639 3.65476C9.1967 3.71968 9.28576 3.75015 9.37483 3.75015C9.47809 3.75015 9.58135 3.70769 9.65537 3.62418C9.79306 3.46963 9.77841 3.23231 9.62387 3.09497L6.24893 0.0950256C6.10686 -0.0316752 5.89292 -0.0316752 5.75089 0.0950256L2.37596 3.09497C2.22136 3.23219 2.20683 3.46891 2.34371 3.62359C2.48058 3.77828 2.72104 3.79234 2.87338 3.63531ZM10.4998 7.50008H7.87485C7.66776 7.50008 7.49986 7.66794 7.49986 7.87508C7.49986 8.08217 7.66776 8.25007 7.87485 8.25007H10.4998C10.9139 8.25007 11.2498 8.58592 11.2498 9.00006V10.5C11.2498 10.9142 10.9139 11.25 10.4998 11.25H1.49997C1.08584 11.25 0.749986 10.9142 0.749986 10.5V9.00006C0.749986 8.58592 1.08584 8.25007 1.49997 8.25007H4.12492C4.33117 8.25007 4.49992 8.08132 4.49992 7.87508C4.49992 7.66883 4.33117 7.50008 4.12492 7.50008H1.49997C0.671472 7.50008 0 8.17155 0 9.00006V10.5C0 11.3285 0.671472 12 1.49997 12H10.4998C11.3283 12 11.9998 11.3285 11.9998 10.5V9.00006C11.9998 8.17273 11.3271 7.50008 10.4998 7.50008ZM10.3123 9.75004C10.3123 9.4395 10.0604 9.18755 9.74982 9.18755C9.43928 9.18755 9.18733 9.4395 9.18733 9.75004C9.18733 10.0606 9.43928 10.3125 9.74982 10.3125C10.0604 10.3125 10.3123 10.0618 10.3123 9.75004Z"
              fill="black"
            />
          </svg>
        </button>
        <button
          className="orders-mgmt-action-btn orders-mgmt-action-btn-upload"
          onClick={() =>
            handleOrdersMgmtDownload(
              `${process.env.REACT_APP_SCAI_API_ENDPOINT}/oms/download-csv-data/`,
              setIsDownloading
            )
          }
          //   disabled={isDownloadingSto || !downloadEnable}
        >
          {isDownloading ? (
            <TableLoaderSpinner size={12} />
          ) : (
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_17749_52768)">
                <path
                  d="M5.75156 8.88516C5.82187 8.96953 5.91094 9 6 9C6.08906 9 6.17798 8.9685 6.24891 8.90515L9.62391 5.90515C9.77845 5.76783 9.7931 5.53062 9.65541 5.37593C9.51771 5.22066 9.279 5.20638 9.12595 5.3448L6.375 7.79062V0.375C6.375 0.167719 6.20625 0 6 0C5.79375 0 5.625 0.167719 5.625 0.375V7.79062L2.87344 5.34375C2.72109 5.20547 2.48203 5.22187 2.34375 5.37656C2.20687 5.51016 2.22141 5.76797 2.37656 5.88516L5.75156 8.88516ZM10.5 7.5H9.375C9.16791 7.5 9 7.66786 9 7.875C9 8.08209 9.16791 8.25 9.375 8.25H10.5C10.9141 8.25 11.25 8.58586 11.25 9V10.5C11.25 10.9141 10.9141 11.25 10.5 11.25H1.5C1.08586 11.25 0.75 10.9141 0.75 10.5V9C0.75 8.58586 1.08586 8.25 1.5 8.25H2.625C2.83125 8.25 3 8.08125 3 7.875C3 7.66875 2.83125 7.5 2.625 7.5H1.5C0.671484 7.5 0 8.17148 0 9V10.5C0 11.3285 0.671484 12 1.5 12H10.5C11.3285 12 12 11.3285 12 10.5V9C12 8.17266 11.3273 7.5 10.5 7.5ZM10.3125 9.75C10.3125 9.43945 10.0605 9.1875 9.75 9.1875C9.43945 9.1875 9.1875 9.43945 9.1875 9.75C9.1875 10.0605 9.43945 10.3125 9.75 10.3125C10.0605 10.3125 10.3125 10.0617 10.3125 9.75Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0_17749_52768">
                  <rect width="12" height="12" fill="white" />
                </clipPath>
              </defs>
            </svg>
          )}
        </button>

        <button
          className="flex items-center bg-[#686867] h-auto rounded-[5px] px-[10px] add-btn-rm"
          onClick={() => setAddOrderModal(true)}
          // disabled={!addStoEnable}
          //   disabled={true}
        >
          {/* <img src={AddIconTemp} /> */}
          <AddIconTemp />
          {"  "}{" "}
          <span className="font-[Inter] text-[13px] text-white ml-[5px] disabled:text-[#d2d2d1]">
            Add Order
          </span>
        </button>
      </div>
    );
  };

  const renderTableHeader = ({ table }) => {
    return (
      <div className="orders-mgmt-header-box">
        {renderModuleButtons()}
        {renderOrderMgmtActions()}
      </div>
    );
  };

  return (
    <div style={{ width: `calc(100% - 20px)`, margin: 10 }}>
      <DataTable
        // CustomToolbar={GroupLevelToolbar}
        data={tableData}
        columns={columns}
        canResizeColumns={true}
        CustomToolbar={renderTableHeader}
        setSelectedSTO={setSelectedSTO}
        module="oms"
        paginationData={paginationData}
        setPaginationData={setPaginationData}
        clearRowSelection={clearRowSelection}
      />
    </div>
  );
};

export default OrdersManagement;
