import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon,
} from "@radix-ui/react-icons";

import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

export function DataTablePagination({
  table,
  showColorCodingLegend,
  showSelectedColumnsCount,
}) {
  return (
    <div className="flex items-center justify-between px-2 align-center">
      <div className=" flex flex-1 text-sm text-muted-foreground lg:text-xl">
        {showSelectedColumnsCount && (
          <div>
            {table.getFilteredSelectedRowModel().rows.length} of{" "}
            {table.getFilteredRowModel().rows.length} row(s) selected
          </div>
        )}
        {showColorCodingLegend && (
          <div className="flex gap-2 items-center text-lg ml-3 font-[Inter] text-[#35445c] ">
            <div className="flex items-center gap-1">
              <span className="border border-[#d9d9d9] h-4 w-4 bg-white"></span>
              <span>System </span>
            </div>
            <div className="flex items-center gap-1">
              <span className="border border-[#d9d9d9] h-4 w-4 bg-[#E1F4F4]"></span>
              <span>Manual </span>
            </div>
            <div className="flex items-center gap-1">
              <span className="border border-[#d9d9d9] h-4 w-4 bg-[#EDEBFF]"></span>
              <span>Edited </span>
            </div>
          </div>
        )}
      </div>
      <div className="flex items-center space-x-6 lg:space-x-8">
        <div className="flex items-center space-x-2">
          <p className="text-sm font-medium lg:text-xl">Rows per page</p>
          <Select
            value={`${table.getState().pagination.pageSize}`}
            onValueChange={(value) => {
              table.setPageSize(Number(value));
            }}
          >
            <SelectTrigger className="h-8 w-[70px] lg:h-11">
              <SelectValue placeholder={table.getState().pagination.pageSize} />
            </SelectTrigger>
            <SelectContent side="top">
              {[10, 20, 50, 100, 250].map((pageSize) => (
                <SelectItem key={pageSize} value={`${pageSize}`}>
                  {pageSize}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="flex w-[100px] items-center justify-center text-sm font-medium lg:text-xl">
          Page {table.getState().pagination.pageIndex + 1} of{" "}
          {table.getPageCount()}
        </div>
        <div className="flex items-center space-x-2 lg:space-x-6">
          <Button
            variant="outline"
            className="h-8 w-8 p-0 lg:h-11 lg:w-11"
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            <span className="sr-only">Go to first page</span>
            <DoubleArrowLeftIcon className="h-4 w-4 lg:h-6 lg:w-6" />
          </Button>
          <Button
            variant="outline"
            className="h-8 w-8 p-0 lg:h-11 lg:w-11"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            <span className="sr-only">Go to previous page</span>
            <ChevronLeftIcon className="h-4 w-4 lg:h-6 lg:w-6" />
          </Button>
          <Button
            variant="outline"
            className="h-8 w-8 p-0 lg:h-11 lg:w-11"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            <span className="sr-only">Go to next page</span>
            <ChevronRightIcon className="h-4 w-4 lg:h-6 lg:w-6" />
          </Button>
          <Button
            variant="outline"
            className="h-8 w-8 p-0 lg:h-11 lg:w-11"
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            <span className="sr-only">Go to last page</span>
            <DoubleArrowRightIcon className="h-4 w-4 lg:h-6 lg:w-6" />
          </Button>
        </div>
      </div>
    </div>
  );
}
