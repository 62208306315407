import { useState, useEffect, useRef, createRef } from "react";
import MuiTable from "../../../components/muiTable/muiTable";
import {
  formatDate,
  formatISODateToHumanReadable,
  transformData,
} from "../../../utils/utils.js";
import { useLocation, useNavigate } from "react-router-dom";
import UploadModal from "../../../components/modal/UploadModal.jsx";
import { BsInfo } from "react-icons/bs";
import KPICard from "../../../components/kpi_cards/KPICard.jsx";
import {
  TextField,
  Autocomplete,
  Checkbox,
  ThemeProvider,
  createTheme,
  Typography,
} from "@mui/material";
import "./styles.css";
import {
  useGroupLevelPlansQuery,
  useOrgLevelPlansQuery,
  useGetApprovalCommentsMutation,
  useMakePlanLiveMutation,
} from "../../../features/api/replPlanSlice";
import {
  TableLoaderSpinner,
  transformStatus,
} from "../../../components/tableUtils/tableUtils";
import { KpiCardsCopy2 } from "../../../components/kpi_cards_copy/kpicardsCopy2";
import { CheckBoxBlankIcon, checkSharpIcon } from "../../../assets/svg-icons";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setGroupLevelPlanDetails } from "../../../features/GroupPlanViewSlice";
import { handleApiArrayResponse } from "../../../utils/apiUtils";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../../utils/components";

const transformTableData = (arr, index) => {
  return arr.map((item) => {
    const baseObject = {
      planId: item.plan_id,
      planDate: formatISODateToHumanReadable(item.created_at),
      buCode: item.bu_code,
      // cycle: formatDate(item.created_at),
      cyclePeriod: "-",
      lastUpdatedAt: formatISODateToHumanReadable(item.last_updated_at),
      updatedBy: item.updated_by,
      jobType: item.plan_type,
      planStatus:
        item.plan_status === "Approval_In_Progress"
          ? "Approval In Progress"
          : item.plan_status,
      approvalStatus: index % 2 === 0 ? "Send" : "Revoke",
      action: null,
      approvalId: item.approval_id,
      stoStatus: item.sto_status,
      cycleId: item.cycle_id,
      isPlanLive: item.plan_live ? item.plan_live : false,
    };

    if (item.approval_id !== null) {
      return {
        ...baseObject,
        approvalDetails: {
          approvalId: item.approval_id,
          permissions: {
            canSubmit: item.approval_details.permissions.canSubmit,
            canApprove: item.approval_details.permissions.canApprove,
            canRevoke: item.approval_details.permissions.canRevoke,
            canReject: item.approval_details.permissions.canReject,
          },
          approvalStatus: item.approval_details.approval_status,
          levelwiseApprovalStatus:
            item.approval_details.levelwise_approval_status.map((element) => ({
              level: element.level,
              status: element.approval_status,
            })),
        },
      };
    }
    return baseObject;
  });
};

function initialLevel(state) {
  const level_local = localStorage.getItem("snp-rp-level");
  if (level_local) {
    return level_local;
  } else if (state && state.planLevel) {
    return state.planLevel;
  } else {
    return "org";
  }
}

function getLevel(state, orgLevelEnable, groupLevelEnable) {
  if (
    (orgLevelEnable === false && initialLevel(state) === "org") ||
    (initialLevel(state) === "group" && groupLevelEnable === true)
  ) {
    return "group";
  }
  return "org";
}

function ReplPlanDashboard() {
  const { state } = useLocation();
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [openApproveRejectModal, setOpenApproveRejectModal] = useState(false);
  const [approveRejectValue, setApproveRejectValue] = useState(null);
  const [stateColumnValues, setStateColumnValues] = useState([]);
  let userDetails = JSON.parse(localStorage.getItem("user"));
  const { permissions } = userDetails;
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const orgLevelEnable = permissions.includes("REPLENISHMENT_PLAN_ORG_VIEW");
  const groupLevelEnable = permissions.includes(
    "REPLENISHMENT_PLAN_GROUP_VIEW"
  );
  const [level, setLevel] = useState(
    getLevel(state, orgLevelEnable, groupLevelEnable)
  );
  const [openSendModal, setOpenSendModal] = useState(false);
  const [selectedGroupPlan, setSelectedGroupPlan] = useState({});
  const [openApprovalCommentsModal, setopenApprovalCommentsModal] =
    useState(false);
  const [approvalComments, setApprovalComments] = useState([]);
  const [loadingComments, setLoadingComments] = useState(false);
  const [loading, setloading] = useState(false);
  const [openMakeLiveModal, setOpenMakeLiveModal] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const dispatch = useDispatch();

  const {
    data: groupLevelTableData,
    refetch: refetchGroupLevelPlans,
    isFetching: isLoadinggroupLevelTableData,
    isSuccess: isSuccessgroupLevelTableData,
  } = useGroupLevelPlansQuery(undefined, {
    skip: level === "org" || !groupLevelEnable,
    refetchOnMountOrArgChange: true,
    force: true, // This will bypass the cache
  });

  const {
    data: orgLevelData,
    isFetching: isLoadingOrgLevelData,
    isSuccess: isSuccessOrgLevelData,
  } = useOrgLevelPlansQuery(undefined, {
    skip: level === "group" || !orgLevelEnable,
    refetchOnMountOrArgChange: true,
    force: true,
  });

  const [getApprovalComments, { isLoading, isError }] =
    useGetApprovalCommentsMutation();

  const [planLive, { isLoading: loadingMakePlanLive }] =
    useMakePlanLiveMutation();

  const transformOrgData = (data) => {
    let transformedData = data.map(
      ({ cycle_start_date, cycle_end_date, status, cycle_id }) => {
        let statusMod = "";
        if (status === "In_progress") {
          statusMod = "In- Progress";
        }
        if (status && status.toLowerCase() === "completed") {
          statusMod = "Completed";
        }
        const formattedCycleStartDate =
          formatISODateToHumanReadable(cycle_start_date);
        const formattedCycleEndDate =
          formatISODateToHumanReadable(cycle_end_date);
        return {
          cycleId: cycle_id,
          // cycle: `${cycle_start_date} to ${cycle_end_date}`,
          orgCyclePeriod: `${formattedCycleStartDate} to ${formattedCycleEndDate}`,
          orgNextCyclePeriod: `${cycle_start_date} to ${cycle_end_date}`,
          groupLevelAggr: "Plans Approved (1/5)",
          status: statusMod,
        };
      }
    );
    return transformedData;
  };

  useEffect(() => {
    if (level === "org") {
      setTableData(orgLevelData ? transformOrgData(orgLevelData) : []);
    } else {
      setTableData(
        groupLevelTableData ? transformTableData(groupLevelTableData) : []
      );
      // if (groupLevelTableData) {
      //   getApprovalStatusValues(groupLevelTableData);
      // }
    }
  }, [level, groupLevelTableData, orgLevelData]);

  // const getApprovalStatusValues = (data) => {
  //   let approvalStatusValuesTemp = {};
  //   data.forEach((item) => {
  //     approvalStatusValuesTemp[item.plan_id] = false;
  //   });
  //   setApprovalStatusValues(approvalStatusValuesTemp);
  // };

  const renderLevelButtons = () => {
    return (
      <div className="pp-level-btns flex">
        <button
          onClick={() => {
            localStorage.setItem("snp-rp-level", "org");
            setLevel("org");
          }}
          className={
            level === "org"
              ? "pp-level-button pp-active-btn"
              : "pp-level-button"
          }
          style={{ display: orgLevelEnable ? "block" : "none" }}
        >
          ORG
        </button>
        <button
          onClick={() => {
            localStorage.setItem("snp-rp-level", "group");
            setLevel("group");
          }}
          className={
            level === "group"
              ? "pp-level-button pp-active-btn"
              : "pp-level-button"
          }
          style={{ display: groupLevelEnable ? "block" : "none" }}
        >
          Group
        </button>
      </div>
    );
  };

  const actionsArray = [
    (row) => console.log({ row }),
    (row) => console.log({ row }),
    (row) => console.log({ row }),
  ];

  function DynamicRefsExample(
    numberOfRefs,
    componentArray,
    cell,
    row,
    centerAlign = true
  ) {
    const refsArray = useRef(
      Array.from({ length: numberOfRefs }, () => createRef())
    );
    return (
      <div className={!centerAlign ? "action-buttons" : "action-buttons-align"}>
        {Array.from({ length: numberOfRefs }, (_, index) => {
          const Comp = componentArray[index];

          return (
            <div
              id={index}
              key={index}
              ref={refsArray.current[index]}
              placeholder={`Ref ${index + 1}`}
              onClick={() => actionsArray[index](row)}
              style={{
                pointerEvents: row.original.isDisabled ? "none" : "auto",
                width: "100%",
              }}
            >
              <Comp row={row.original} />
            </div>
          );
        })}
      </div>
    );
  }

  const actionButtonsOrgArray = [
    (row1) => {
      const { row } = row1;
      const planId = row.cycleId;
      return (
        <div style={{ width: "100%", display: "flex" }}>
          <button
            className="table-action-button view-button-preprocess"
            style={{ margin: "auto" }}
            onClick={() => {
              navigate(`/snp/inventory/replenishment-plan/org-plan/${planId}`);
            }}
          >
            View
          </button>
        </div>
      );
    },
  ];

  const actionButtonsGroupArray = [
    (row1) => {
      const { row } = row1;
      return (
        <div style={{ width: "100%", display: "flex" }}>
          <button
            className="table-action-button view-button-preprocess"
            style={{ margin: "auto" }}
            onClick={() => {
              dispatch(setGroupLevelPlanDetails({ ...row }));
              navigate(
                `/snp/inventory/replenishment-plan/group-plan/${row.planId}`
              );
            }}
          >
            View
          </button>
        </div>
      );
    },
  ];

  const handleApprovalStatusIndicator = (status) => {
    if (status === "Completed") {
      return "completed";
    } else if (status === "In- Progress") {
      return "progress";
    }
    return "rejected";
  };

  const approvalStatusOrgArray = [
    (row1) => {
      const { row } = row1;

      return (
        <div className="active-cell status-col">
          <div
            className={`status-indicator ${handleApprovalStatusIndicator(
              row.status
            )}`}
          ></div>
          <span className=""> {row.status}</span>
        </div>
      );
    },
  ];

  // const planStatusGrpArray = [
  //   (row1) => {
  //     const { row } = row1;
  //     return (
  //       <div className="active-cell plan-status">
  //         <span>{row.planStatus}</span>
  //         <button
  //           style={{
  //             background: "transparent",
  //             padding: "0",
  //             display: "flex",
  //             justifyContent: "center",
  //             alignItems: "center",
  //             borderRadius: "50%",
  //             border: "none",
  //           }}
  //         >
  //           <BsInfo size={13} className="info-icon" />
  //         </button>
  //       </div>
  //     );
  //   },
  // ];

  const handlePlanApproval = async (comment, planId, approveRejectValue) => {
    setloading(true);
    const formData = new FormData();
    const approvalDetails = {
      action: approveRejectValue,
      approvable_type: "RP",
      approvables: [
        {
          id: planId,
          comment: comment,
          attachment_ids: [],
        },
      ],
    };

    formData.append("approval_details", JSON.stringify(approvalDetails));

    fetch(`${process.env.REACT_APP_SCAI_API_ENDPOINT}/v1/approval/`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
      },
      body: formData,
    })
      .then(handleApiArrayResponse)
      .then((data) => {
        const { message, approvals_info, approvable_details } = data;
        const approvableId = approvals_info[0].approvable_id;
        const indexOfObjectToUpdate = tableData.findIndex((item) => {
          if (item.planId === approvableId) {
            return true;
          }
        });
        const planToBeUpdated = tableData[indexOfObjectToUpdate];
        const updatedPlan = {
          ...planToBeUpdated,
          isPlanLive: approvable_details[0].plan_live,
          planStatus:
            approvable_details[0].status === "Approval_In_Progress"
              ? "Approval In Progress"
              : transformStatus(approvable_details[0].status),
          approvalId: approvals_info[0].approval_id,
          approvalDetails: {
            approvalId: approvals_info[0].approval_id,
            permissions: {
              canSubmit: approvals_info[0].permissions.canSubmit,
              canApprove: approvals_info[0].permissions.canApprove,
              canRevoke: approvals_info[0].permissions.canRevoke,
              canReject: approvals_info[0].permissions.canReject,
            },
            approvalStatus: approvals_info[0].approval_status,
            levelwiseApprovalStatus:
              approvals_info[0].levelwise_approval_status.map((element) => ({
                level: element.level,
                status: element.approval_status,
              })),
          },
        };
        const newTableData = [...tableData];
        newTableData[indexOfObjectToUpdate] = updatedPlan;
        setTableData([...newTableData]);
        toast.success(`${planId} is ${message}`, { autoClose: 3000 });
        setloading(false);
      })
      .catch((error) => {
        setloading(false);
        toast.error(error.message, { autoClose: 3000 });
      });
  };

  const getComments = async (buCode, approvalId) => {
    const requestBody = {
      approvable_type: "RP",
      approval_ids: [`${approvalId}`],
    };
    try {
      setLoadingComments(true);
      const responseApproval = await getApprovalComments({
        requestBody: requestBody,
      });

      let responseApprovalIndex =
        responseApproval.data.approval_actions.findIndex((item) => {
          if (item.approval_id === approvalId) {
            return true;
          }
        });
      const planApprovalComments =
        responseApproval.data.approval_actions[responseApprovalIndex]
          .approval_action;
      setApprovalComments([...planApprovalComments]);
      setLoadingComments(false);
    } catch (error) {
      setLoadingComments(false);
      toast.error(error.message);
    }
  };

  const planStatusGrpArray = [
    (row1) => {
      const { row } = row1;
      const { approvalId } = row;
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            flexGrow: "1",
            height: 22,
            alignItems: "center",
          }}
        >
          <div className="active-cell">{row.planStatus}</div>
          <button
            style={{
              background: "transparent",
              padding: "0px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              border: "none",
            }}
            className={`info-button ${
              approvalId === null ? "info-disable" : null
            }`}
            onClick={() => {
              setopenApprovalCommentsModal(true);
              getComments(row.buCode, row.approvalId);
            }}
            disabled={approvalId === null}
          >
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              r="5"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13 6.5C13 10.0899 10.0899 13 6.5 13C2.91015 13 0 10.0899 0 6.5C0 2.91015 2.91015 0 6.5 0C10.0899 0 13 2.91015 13 6.5ZM7.3125 3.25C7.3125 3.69873 6.94873 4.0625 6.5 4.0625C6.05127 4.0625 5.6875 3.69873 5.6875 3.25C5.6875 2.80127 6.05127 2.4375 6.5 2.4375C6.94873 2.4375 7.3125 2.80127 7.3125 3.25ZM5.6875 5.6875C5.23877 5.6875 4.875 6.05127 4.875 6.5C4.875 6.94873 5.23877 7.3125 5.6875 7.3125V9.75C5.6875 10.1987 6.05127 10.5625 6.5 10.5625H7.3125C7.76123 10.5625 8.125 10.1987 8.125 9.75C8.125 9.30127 7.76123 8.9375 7.3125 8.9375V6.5C7.3125 6.05127 6.94873 5.6875 6.5 5.6875H5.6875Z"
                fill="#A3AAC2"
              />
            </svg>
          </button>
        </div>
      );
    },
  ];

  const approvalStatusGrpArray = [
    (row1) => {
      const { row } = row1;
      const { approvalDetails, approvalId } = row;
      return (
        <div
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: "center",
          }}
        >
          {" "}
          {approvalId === null && (
            <>
              {/* <div className={`status-indicator-app awaiting-status}`}></div>
              <div className={`status-indicator-app awaiting-status}`}></div> */}
            </>
          )}
          {approvalId !== null &&
            approvalDetails &&
            approvalDetails.levelwiseApprovalStatus.length !== 0 &&
            approvalDetails.levelwiseApprovalStatus.map((levelItem, index) => (
              <div
                className={`status-indicator-app ${
                  levelItem.status !== "APPROVED"
                    ? levelItem.status !== "REJECTED"
                      ? levelItem.status !== "PENDING"
                        ? "awaiting-status"
                        : "pending-status"
                      : "rejected-status"
                    : "approved-status"
                }`}
                key={index}
              ></div>
            ))}
        </div>
      );
    },
  ];

  const actionsGrpSendRevokeArray = [
    (row1) => {
      const { row } = row1;
      const { approvalId, approvalDetails, planStatus } = row;
      const sendRevokeEnable =
        approvalId !== null && approvalDetails
          ? approvalDetails.permissions.isPlanner === true &&
            approvalDetails.permissions.canSubmit === false &&
            approvalDetails.permissions.canApprove === false &&
            approvalDetails.permissions.canReject === false
            ? false
            : true
          : true;

      const approveRejectDisable = () => {
        if (approvalId !== null && approvalDetails) {
          if (
            approvalDetails.permissions.canApprove === true &&
            approvalDetails.permissions.canReject === true
          ) {
            return false;
          } else {
            return true;
          }
        }
      };
      let userDetails = JSON.parse(localStorage.getItem("user"));
      const { permissions } = userDetails;
      const isPlanner = permissions.includes("STO_SUBMIT_APPROVAL");
      const isApprover = permissions.includes("STO_APPROVE_REJECT");

      return (
        <div style={{ display: "flex" }}>
          {isPlanner && approvalId === null && (
            <div
              style={{
                border: "1px solid #d9d9d9",
                borderRadius: "19px",
                padding: "2px 4px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxSizing: "border-box",
                margin: "auto",
                // opacity:
                //   approvalDetails.permissions.canSubmit === true ||
                //   approvalDetails.permissions.canRevoke ||
                //   planStatus === "Planned"
                //     ? 1
                //     : 0.4,
              }}
              // disabled={true}
            >
              <span
                style={{
                  fontSize: "11px",
                  marginRight: "0px",
                  marginBottom: "unset",
                  marginTop: "unset",
                  lineHeight: "unset",
                  minWidth: "50px",
                  textAlign: "center",
                }}
              >
                {/* {approvalStatusValues[row.planId] ? "Send" : "Revoke"} */}
                {/* {approvalId !== null && approvalDetails
                  ? approvalDetails.permissions.canSubmit === true
                    ? "Send"
                    : approvalDetails.permissions.canRevoke === true
                    ? "Revoke"
                    : "Send"
                  : null} */}
                Send
              </span>
              <label
                className="send-revoke-box small-switch"
                onClick={() => {
                  setSelectedGroupPlan(row);
                  setOpenSendModal(true);
                  setApproveRejectValue("SUBMITTED");
                }}
                disabled={true}
              >
                <input
                  type="checkbox"
                  className="send-revoke-input"
                  // defaultChecked
                  disabled
                  // checked={!approvalStatusValues[row.planId]}
                  // checked={approvalDetails.permissions.canRevoke}
                />
                <span className="send-revoke-slider send-revoke-round"></span>
              </label>
            </div>
          )}
          {isPlanner && approvalId !== null && approvalDetails && (
            <div
              style={{
                border: "1px solid #d9d9d9",
                borderRadius: "19px",
                padding: "2px 4px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxSizing: "border-box",
                margin: "auto",
                opacity:
                  approvalDetails.permissions.canSubmit === true ||
                  approvalDetails.permissions.canRevoke
                    ? 1
                    : 0.4,
              }}
              // disabled={true}
            >
              <span
                style={{
                  fontSize: "11px",
                  marginRight: "0px",
                  marginBottom: "unset",
                  marginTop: "unset",
                  lineHeight: "unset",
                  minWidth: "50px",
                  textAlign: "center",
                }}
              >
                {/* {approvalStatusValues[row.planId] ? "Send" : "Revoke"} */}
                {approvalId !== null && approvalDetails
                  ? approvalDetails.permissions.canSubmit === true
                    ? "Send"
                    : approvalDetails.permissions.canRevoke === true
                    ? "Revoke"
                    : "Revoke"
                  : null}
              </span>
              <label
                className="send-revoke-box small-switch"
                onClick={() => {
                  if (
                    approvalDetails.permissions.canSubmit === true ||
                    approvalDetails.permissions.canRevoke === true
                  ) {
                    setSelectedGroupPlan(row);
                    setOpenSendModal(true);
                    const actionValue =
                      approvalDetails.permissions.canSubmit === true
                        ? "SUBMITTED"
                        : approvalDetails.permissions.canRevoke === true
                        ? "REVOKED"
                        : "";
                    setApproveRejectValue(actionValue);
                  }
                }}
                disabled={true}
              >
                <input
                  type="checkbox"
                  className="send-revoke-input"
                  // defaultChecked
                  disabled
                  // checked={!approvalStatusValues[row.planId]}
                  checked={approvalDetails.permissions.canRevoke}
                />
                <span className="send-revoke-slider send-revoke-round"></span>
              </label>
            </div>
          )}
          {isApprover && approvalId !== null && approvalDetails && (
            <div className="approval-btns">
              <button
                className="approval-btn approve"
                onClick={() => {
                  setOpenApproveRejectModal(true);
                  setSelectedGroupPlan(row);
                  setApproveRejectValue("APPROVED");
                }}
                style={{
                  opacity: approveRejectDisable() ? 0.4 : 1,
                }}
                // disabled={approveRejectDisable}
                disabled={approveRejectDisable()}
              >
                Approve
              </button>
              <button
                className="approval-btn reject"
                onClick={() => {
                  setOpenApproveRejectModal(true);
                  setApproveRejectValue("REJECTED");
                  setSelectedGroupPlan(row);
                }}
                style={{
                  opacity: approveRejectDisable() ? 0.4 : 1,
                }}
                disabled={approveRejectDisable()}
              >
                Reject
              </button>
            </div>
          )}{" "}
          {/* {isApprover && approvalId === null && (
            <div className="approval-btns">
              <button
                className="approval-btn approve"
                style={{
                  opacity: "0.4",
                }}
                disabled={true}
              >
                Approve
              </button>
              <button
                className="approval-btn reject"
                style={{
                  opacity: "0.4 ",
                }}
                disabled={true}
              >
                Reject
              </button>
            </div>
          )} */}
        </div>
      );
    },
  ];

  const handleMakeLive = (row) => {
    setSelectedGroupPlan({ ...row });
    setOpenMakeLiveModal(true);
  };

  const makePlanLive = async (planId) => {
    const requestBody = {
      plan_id: planId,
    };
    try {
      const response = await planLive({
        planId,
        requestBody: requestBody,
      });
      if (response.error) {
        throw new Error(response.error.data.error);
      } else {
        refetchGroupLevelPlans();
        toast.success(response.data.success, { autoClose: 3000 });
      }
    } catch (error) {
      toast.error(error.message, { autoClose: 3000 });
    }
  };

  const actionsGrpLiveArray = [
    (row1) => {
      const { row } = row1;
      const { planStatus, isPlanLive } = row;
      let userDetails = JSON.parse(localStorage.getItem("user"));
      const { permissions } = userDetails;
      const isPlanner = permissions.includes("STO_SUBMIT_APPROVAL");
      const isApprover = permissions.includes("STO_APPROVE_REJECT");
      return (
        <div style={{ width: "100%", display: "flex" }}>
          <button
            className={
              // planStatus !== "Approved" || (isApprover && planStatus !== "Live")
              isPlanLive !== true || (!isPlanner && planStatus !== "Live")
                ? planStatus !== "Live"
                  ? "grp-action-live-btn"
                  : "grp-action-live-btn live-btn-enable"
                : "grp-action-live-btn grp-action-live-enable"
            }
            style={{ margin: "auto" }}
            // disabled={
            //   planStatus !== "Approved" || (isApprover && planStatus !== "Live")
            // }
            disabled={!row.isPlanLive || !isPlanner}
            onClick={() => {
              handleMakeLive(row);
            }}
          >
            Live
          </button>
        </div>
      );
    },
  ];

  const getActionColumnName = () => {
    let userDetails = JSON.parse(localStorage.getItem("user"));
    const { permissions } = userDetails;
    const isPlanner = permissions.includes("STO_SUBMIT_APPROVAL");
    const isApprover = permissions.includes("STO_APPROVE_REJECT");
    if (isPlanner) {
      return "Action - Send / Revoke";
    } else if (isApprover) {
      return "Action - Approve / Reject";
    } else {
      return "Action";
    }
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return tableData ? (
    <ThemeProvider theme={customTheme}>
      <div style={{ width: `calc(100% - 20px)`, margin: 10 }}>
        <UploadModal
          openModal={openSendModal}
          setOpenModal={setOpenSendModal}
          modalType="send"
          // approvalStatusValues={approvalStatusValues}
          // setApprovalStatusValues={setApprovalStatusValues}
          selectedGroupPlan={selectedGroupPlan}
          approveRejectValue={approveRejectValue}
          handlePlanApproval={handlePlanApproval}
        />
        <UploadModal
          openModal={openApproveRejectModal}
          setOpenModal={setOpenApproveRejectModal}
          modalType="approve-reject"
          approveRejectValue={approveRejectValue}
          handlePlanApproval={handlePlanApproval}
          selectedGroupPlan={selectedGroupPlan}
          // approvalStatusValues={approvalStatusValues}
          // setApprovalStatusValues={setApprovalStatusValues}
          // selectedGroupPlan={selectedGroupPlan}
        />
        <UploadModal
          openModal={openApprovalCommentsModal}
          setOpenModal={setopenApprovalCommentsModal}
          modalType="approval-comments"
          approvalComments={approvalComments}
          loadingComments={loadingComments}
        />
        <UploadModal
          openModal={openMakeLiveModal}
          setOpenModal={setOpenMakeLiveModal}
          modalType="make-live"
          selectedGroupPlan={selectedGroupPlan}
          makePlanLive={makePlanLive}
        />
        {/* <div
        style={{
          width: "100%",
          margin: "auto",
          display: "flex",
          justifyContent: "space-between",
          gap: "10px",
          paddingTop: "12px",
          paddingBottom: "12px",
        }}
      >
        <KPICard
          value="$10536"
          volume="25039"
          heading="Total Replenishment Requirements"
        />
        <KPICard
          value="$10536"
          volume="22039"
          heading="Total Replenishment Allocation"
        />{" "}
        <KPICard value="$320976" volume="12356" heading="STO" />
        <KPICard value="$231456" volume="178000" heading="Lost Sales" />
        <KPICard value="$231456" volume="178000" heading="Deviations" />
      </div> */}
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            expanded={expanded === "panel1"}
            fill="white"
            hoverColor="#e94f1c"
            backgroundColor="#494949"
            expandedBgColor="#e94f1b"
          >
            <Typography
              sx={{
                width: "33%",
                flexShrink: 0,
                fontSize: "12px",
                color: "white",
              }}
            >
              KPI&apos;s
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <KpiCardsCopy2 />
          </AccordionDetails>
        </Accordion>
        <div
          className="snp-table-container"
          style={{
            marginTop: "-5px",
            display: orgLevelEnable || groupLevelEnable ? "block" : "none",
          }}
          // style={{ width: `calc( 100% - 20px)`, margin: 10 }}
        >
          <MuiTable
            columns={
              level === "org"
                ? [
                    {
                      accessorKey: "cycleId",
                      header: "RP ID",
                      enableColumnOrdering: false,
                      enableEditing: false,
                      enableSorting: true,
                      size: 140,
                      Cell: ({ cell, row, column }) => {
                        return (
                          <div
                            className={
                              row.original.isDisabled
                                ? "disabled-cell"
                                : "active-cell"
                            }
                          >
                            {cell.getValue()}
                          </div>
                        );
                      },
                    },
                    {
                      accessorKey: "orgCyclePeriod",
                      header: "Cycle Period",
                      enableColumnOrdering: false,
                      enableEditing: false,
                      enableSorting: true,
                      size: 210,
                      Cell: ({ cell, row, column }) => {
                        return (
                          <div
                            className={
                              row.original.isDisabled
                                ? "disabled-cell"
                                : "active-cell"
                            }
                          >
                            {cell.getValue()}
                          </div>
                        );
                      },
                    },
                    {
                      accessorKey: "status",
                      header: "Status",
                      enableColumnActions: true,
                      muiTableBodyCellEditTextFieldProps: {
                        select: true, //change to select for a dropdown
                      },
                      size: 210,
                      Cell: ({ cell, row }) => {
                        const centerAlign = false;
                        return DynamicRefsExample(
                          1,
                          approvalStatusOrgArray,
                          cell,
                          row,
                          centerAlign
                        );
                      },
                      Filter: ({ header }) => (
                        <Autocomplete
                          onChange={(e, newValue) => {
                            setStateColumnValues(newValue);
                            header.column.setFilterValue(newValue);
                          }}
                          sx={{
                            width: 190,
                            "& .MuiAutocomplete-clearIndicator": {
                              display: "none",
                            },
                          }}
                          multiple
                          size="small"
                          id="checkboxes-tags-demo"
                          options={[
                            "Rejected",
                            "Completed",
                            "In- Progress",
                          ].sort((a, b) => {
                            let ai = stateColumnValues.indexOf(a);
                            ai =
                              ai === -1
                                ? stateColumnValues.length +
                                  [
                                    "Rejected",
                                    "Completed",
                                    "In- Progress",
                                  ].indexOf(a)
                                : ai;
                            let bi = stateColumnValues.indexOf(b);
                            bi =
                              bi === -1
                                ? stateColumnValues.length +
                                  [
                                    "Rejected",
                                    "Completed",
                                    "In- Progress",
                                  ].indexOf(b)
                                : bi;
                            return ai - bi;
                          })}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option}
                          renderTags={() => null}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={CheckBoxBlankIcon}
                                checkedIcon={checkSharpIcon}
                                style={{
                                  width: "20%",
                                  margin: "0",
                                  padding: "0",
                                }}
                                checked={selected}
                              />
                              {option}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField {...params} label="" placeholder="" />
                          )}
                        />
                      ),
                      filterFn: "arrIncludesSome",
                    },
                    {
                      accessorKey: "groupLevelAggr",
                      header: "Group Level Aggregation",
                      enableColumnOrdering: false,
                      enableEditing: false,
                      enableSorting: true,
                      size: 210,
                      Cell: ({ cell, row, column }) => {
                        return (
                          <div
                            className={
                              row.original.isDisabled
                                ? "disabled-cell"
                                : "active-cell"
                            }
                          >
                            {cell.getValue()}
                          </div>
                        );
                      },
                    },
                    {
                      accessorKey: "action",
                      header: "View Plan",
                      enableColumnActions: false,
                      enableSorting: false,
                      muiTableBodyCellEditTextFieldProps: {
                        select: true, //change to select for a dropdown
                      },
                      size: 210,
                      Cell: ({ cell, row }) => {
                        return DynamicRefsExample(
                          1,
                          actionButtonsOrgArray,
                          cell,
                          row
                        );
                      },
                    },
                  ]
                : (() => {
                    let groupColumns = [
                      {
                        accessorKey: "planId",
                        header: "Plan ID",
                        enableColumnOrdering: false,
                        enableEditing: false,
                        enableSorting: true,
                        size: 140,
                        Cell: ({ cell, row, column }) => {
                          return (
                            <div
                              className={
                                row.original.isDisabled
                                  ? "disabled-cell"
                                  : "active-cell"
                              }
                            >
                              {cell.getValue()}
                            </div>
                          );
                        },
                      },
                      {
                        accessorKey: "cycleId",
                        header: "Cycle ID",
                        enableColumnOrdering: false,
                        enableEditing: false,
                        enableSorting: true,
                        size: 140,
                        Cell: ({ cell, row, column }) => {
                          return (
                            <div
                              className={
                                row.original.isDisabled
                                  ? "disabled-cell"
                                  : "active-cell"
                              }
                            >
                              {cell.getValue()}
                            </div>
                          );
                        },
                      },
                      {
                        accessorKey: "planDate",
                        header: "Plan Date",
                        enableColumnOrdering: false,
                        enableEditing: false,
                        enableSorting: true,
                        size: 140,
                        Cell: ({ cell, row, column }) => {
                          return (
                            <div
                              className={
                                row.original.isDisabled
                                  ? "disabled-cell"
                                  : "active-cell"
                              }
                            >
                              {cell.getValue()}
                            </div>
                          );
                        },
                      },
                      {
                        accessorKey: "buCode",
                        header: "Group Code",
                        enableColumnOrdering: false,
                        enableEditing: false,
                        enableSorting: true,
                        size: 140,
                        Cell: ({ cell, row, column }) => {
                          return (
                            <div
                              className={
                                row.original.isDisabled
                                  ? "disabled-cell"
                                  : "active-cell"
                              }
                            >
                              {cell.getValue()}
                            </div>
                          );
                        },
                      },
                      {
                        accessorKey: "lastUpdatedAt",
                        header: "Last Updated",
                        enableColumnOrdering: false,
                        enableEditing: false,
                        enableSorting: true,
                        size: 140,
                        Cell: ({ cell, row, column }) => {
                          return (
                            <div
                              className={
                                row.original.isDisabled
                                  ? "disabled-cell"
                                  : "active-cell"
                              }
                            >
                              {cell.getValue()}
                            </div>
                          );
                        },
                      },
                      {
                        accessorKey: "updatedBy",
                        header: "Updated By",
                        enableColumnOrdering: false,
                        enableEditing: false,
                        enableSorting: true,
                        size: 140,
                        Cell: ({ cell, row, column }) => {
                          return (
                            <div
                              className={
                                row.original.isDisabled
                                  ? "disabled-cell"
                                  : "active-cell"
                              }
                            >
                              {cell.getValue()}
                            </div>
                          );
                        },
                      },

                      {
                        accessorKey: "jobType",
                        header: "Job Type",
                        enableColumnOrdering: false,
                        enableEditing: false,
                        enableSorting: true,
                        enableColumnActions: true,
                        size: 140,
                        Cell: ({ cell, row, column }) => {
                          return (
                            <div
                              className={
                                row.original.isDisabled
                                  ? "disabled-cell"
                                  : "active-cell"
                              }
                            >
                              {cell.getValue()}
                            </div>
                          );
                        },
                      },

                      {
                        accessorKey: "planStatus",
                        header: "Plan Status",
                        enableColumnActions: true,
                        enableSorting: true,
                        muiTableBodyCellEditTextFieldProps: {
                          select: true, //change to select for a dropdown
                        },
                        Cell: ({ cell, row }) => {
                          return DynamicRefsExample(
                            1,
                            planStatusGrpArray,
                            cell,
                            row
                          );
                        },
                      },
                      {
                        accessorKey: "approvalStatus",
                        header: "Approval Status",
                        enableColumnActions: true,
                        enableSorting: false,
                        muiTableBodyCellEditTextFieldProps: {
                          select: true, //change to select for a dropdown
                        },
                        Cell: ({ cell, row }) => {
                          return DynamicRefsExample(
                            1,
                            approvalStatusGrpArray,
                            cell,
                            row
                          );
                        },
                      },
                      // {
                      //   accessorKey: "action1",
                      //   header: getActionColumnName(),
                      //   enableColumnActions: true,
                      //   enableSorting: false,
                      //   muiTableBodyCellEditTextFieldProps: {
                      //     select: true, //change to select for a dropdown
                      //   },
                      //   size: 160,
                      //   Cell: ({ cell, row }) => {
                      //     const centerAlign = true;
                      //     return DynamicRefsExample(
                      //       1,
                      //       actionsGrpSendRevokeArray,
                      //       cell,
                      //       row,
                      //       centerAlign
                      //     );
                      //   },
                      // },
                      {
                        accessorKey: "action2",
                        header: "Action - Make Live",
                        enableColumnActions: true,
                        enableSorting: false,
                        muiTableBodyCellEditTextFieldProps: {
                          select: true, //change to select for a dropdown
                        },
                        size: 160,
                        Cell: ({ cell, row }) => {
                          const centerAlign = true;
                          return DynamicRefsExample(
                            1,
                            actionsGrpLiveArray,
                            cell,
                            row,
                            centerAlign
                          );
                        },
                      },

                      {
                        accessorKey: "action",
                        header: "View Plan",
                        enableColumnActions: false,
                        enableSorting: false,
                        muiTableBodyCellEditTextFieldProps: {
                          select: true, //change to select for a dropdown
                        },
                        Cell: ({ cell, row }) => {
                          return DynamicRefsExample(
                            1,
                            actionButtonsGroupArray,
                            cell,
                            row
                          );
                        },
                      },
                    ];
                    let userDetails = JSON.parse(localStorage.getItem("user"));
                    const { permissions } = userDetails;
                    const isPlanner = permissions.includes(
                      "STO_SUBMIT_APPROVAL"
                    );
                    const isApprover =
                      permissions.includes("STO_APPROVE_REJECT");

                    if (isPlanner || isApprover) {
                      groupColumns.splice(11, 0, {
                        accessorKey: "action1",
                        header: getActionColumnName(),
                        enableColumnActions: true,
                        enableSorting: false,
                        muiTableBodyCellEditTextFieldProps: {
                          select: true, //change to select for a dropdown
                        },
                        size: 160,
                        Cell: ({ cell, row }) => {
                          const centerAlign = true;
                          return DynamicRefsExample(
                            1,
                            actionsGrpSendRevokeArray,
                            cell,
                            row,
                            centerAlign
                          );
                        },
                      });
                    }
                    return groupColumns;
                  })()
            }
            td={tableData}
            createPath="/js-create"
            level={level}
            setLevel={setLevel}
            renderTopToolbarCustomActions={renderLevelButtons}
            // renderBottomToolbarCustomActions={renderLeftTableFooterGroupLevel}
            shouldShowAddBtn={false}
            isLoading={
              level === "org"
                ? isLoadingOrgLevelData
                : isLoadinggroupLevelTableData || loading || loadingMakePlanLive
            }
            //   viewPath="/process-view"
          />
        </div>
      </div>
    </ThemeProvider>
  ) : (
    <TableLoaderSpinner />
  );
}

const customTheme = createTheme({
  palette: {
    primary: {
      main: "#f5f7fa",
    },
  },
  typography: {
    fontFamily: "Inter, sans-serif",
    fontSize: 12,
    fontWeightBold: 700,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightLight: 300,
    customBoldFont: {
      fontWeight: 600,
      fontSize: 13,
    },
    customLightFont: {
      fontSize: 11,
      fontWeight: 400,
    },
    customHeading: {
      fontWeight: 300,
      fontSize: 15,
    },
  },
  components: {
    MuiDrawer: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});

export default ReplPlanDashboard;
