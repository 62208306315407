import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  areAllDataFetched: false,
  data: {},
};

const inventoryProfileSlice = createSlice({
  name: "inventoryProfileSlice",
  initialState,
  reducers: {
    setDemandAndInvStatsData: (state, action) => {
      state.data = action.payload;
      state.areAllDataFetched = true;
    },
  },
});

export const { setDemandAndInvStatsData } = inventoryProfileSlice.actions;
export default inventoryProfileSlice.reducer;
