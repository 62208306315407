import { useState, useEffect } from "react";
import { useStockLevelQuery } from "../../../../features/api/inventorySlice";
import MuiTable from "../../../../components/muiTable/muiTable";
import { salesOrderDetailsCols, stockLevelCols } from "./columns";
import { salesOrderDetailsData, stockLevelData } from "./data";

const transformData = (arr) => {
  return arr.map((item) => ({
    process: item.process,
    processTypes: item.process_type.name,
    lastUpdatedBy: item.last_updated_by,
    // lastUpdatedAt: formatDate(item.last_updated_at),
    preprocessAction: item.preprocess_action,
  }));
};

const muiTableContainerProps = { sx: { maxHeight: "350px" } };

function SalesOrderDetails() {
  const [tableData, setTableData] = useState([]);
  const { data, isLoading, isSuccess } = useStockLevelQuery(undefined, {
    refetchOnMountOrArgChange: true,
    force: true, // This will bypass the cache
  });

  useEffect(() => {
    // setTableData(data ? data : []);
    setTableData(salesOrderDetailsData);
  }, [data]);

  return (
    <div
      className="snp-table-container mini-table"
      // style={{
      //   width: "100%",
      //   margin: "auto",
      //   overflow: "hidden",
      //   // overflowX: "overlay",
      // }}
      style={{ overflow: "hidden" }}
    >
      <MuiTable
        columns={salesOrderDetailsCols}
        td={tableData}
        shouldShowAddBtn={false}
        enableTopToolbar={false}
        enableBottomToolbar={false}
        muiTableContainerProps={muiTableContainerProps}
        isLoading={isLoading}
      />
    </div>
  );
}

export default SalesOrderDetails;
