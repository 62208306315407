import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useState, useEffect, useRef } from "react";

function DemandOverview({ componentWidth, snpWidth }) {
  const [options, setOptions] = useState({});
  const chartRef = useRef(null);

  Highcharts.setOptions({
    lang: {
      decimalPoint: ".",
      thousandsSep: ",",
    },
  });

  useEffect(() => {
    const chartOptions = {
      chart: {
        type: "area",
        height: 260,
      },
      title: {
        text: "",
      },
      legend: {
        itemStyle: {
          fontFamily: "Inter",
        },
        padding: 0.5,
      },
      xAxis: {
        lineWidth: 1,
        lineColor: "#c2c2c2",
        categories: [
          "Jan 24",
          "Feb 24",
          "Mar 24",
          "Apr 24",
          "May 24",
          "Jun 24",
          "Jul 24",
          "Aug 24",
          "Sep 24",
          "Oct 24",
          "Nov 24",
          "Dec 24",
        ],
        labels: {
          style: {
            fontSize: "10px",
            fontFamily: "Inter",
          },
        },
        accessibility: {
          description: "Months of the year",
        },
        plotLines: [],
      },
      yAxis: {
        lineWidth: 1,
        lineColor: "#c2c2c2",
        gridLineWidth: 0,
        labels: {
          x: -8,
          style: {
            fontSize: "10px",
            fontFamily: "Inter",
          },
        },
        title: {
          text: "",
        },
      },
      tooltip: {
        crosshairs: true,
        shared: true,
      },
      plotOptions: {
        series: {
          marker: {
            symbol: "circle",
            fillColor: "#FFFFFF",
            lineColor: "#494949",
            lineWidth: 2,
          },
          fillColor: {
            linearGradient: [0, 0, 0, 300],
            stops: [
              [0, "#FFB494"],
              [1, "#ffffff"],
            ],
          },
          states: {
            hover: {
              lineWidthPlus: 0,
            },
          },
        },
        area: {
          fillOpacity: 0.1,
        },
      },
      credits: {
        enabled: false,
      },
      colors: ["#494949", "#FF8251"],
      series: [
        {
          lineWidth: 1,
          zIndex: 1000,
          type: "line",
          fillColor: {
            linearGradient: [0, 0, 0, 300],
            stops: [
              [0, "#ffffff"],
              [1, "#ffffff"],
            ],
          },
          marker: {
            symbol: "circle",
            lineColor: "#000000",
            lineWidth: 1,
          },
          name: "Actual Sales",
          data: [
            1000000, 2000000, 3000000, 2500000, 4000000, 3500000, 1500000,
            400000, 500000, 800000, 1800000, 2000000,
          ].map((value, index, array) => ({
            y: value,
            marker: {
              fillColor: index === array.length - 1 ? "#000000" : "#FFFFFF",
              lineColor: "#494949",
              radius: 4
            },
          })),
          tooltip: {
            valuePrefix: "₹",
          },
        },
        {
          lineWidth: 1,
          fillColor: {
            linearGradient: [0, 0, 0, 190],
            stops: [
              [0, "#FFB494"],
              [1, "#ffffff"],
            ],
          },
          marker: {
            symbol: "circle",
            lineColor: "#EF3A3A",
            lineWidth: 1,
          },
          name: "Demand",
          data: [
            1300000, 2500000, 3500000, 2000000, 3500000, 3900000, 1300000,
            500000, 1000000, 1500000, 1800000, 2500000,
          ].map((value, index, array) => ({
            y: value,
            marker: {
              fillColor: index === array.length - 1 ? "#EF3A3A" : "#FFFFFF",
              lineColor: "#EF3A3A",
              radius: 4
            },
          })),
          tooltip: {
            valuePrefix: "₹",
          },
        },
      ],
    };

    // Dynamically add plotLines for each category point
    chartOptions.xAxis.categories.forEach((category, index) => {
      chartOptions.xAxis.plotLines.push({
        color: "#CBCBCB",
        dashStyle: "Dash",
        value: index,
        width: 1,
        zIndex: 3,
      });
    });
    setOptions(chartOptions);
  }, []);

  useEffect(() => {
    const chart = chartRef.current.chart;
    // Function to handle resizing
    const handleResize = () => {
      // Calculate the new width based on the container's width
      // const newWidth = chartRef.current.container.offsetWidth;
      chart.setSize(componentWidth);
    };
    handleResize();
  }, [snpWidth]);

  return (
    <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
  );
}

export default DemandOverview;
