import { useState, useEffect, useRef, createRef } from "react";
import MuiTable from "../../components/muiTable/muiTable";
import { useLocation, useNavigate } from "react-router-dom";
import "../Inventory/Dashboard/styles.css";
import SelectInput from "../../components/add-sto/SelectInput";
import TextInput from "../../components/add-sto/TextInput";
import DateInput from "../../components/add-sto/DateInput";
import { useCreateStoMutation } from "../../features/api/replPlanSlice";
import {
  useChannelsListQuery,
  useSkuCodeListQuery,
  useSourceDestinationListQuery,
} from "../../features/api/createStoSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteIcon from "../../components/customSvgIcons/delete";
import { usePpAppGroupPlanCreatePWOMutation } from "../../features/api/prodPlanSlice";

function CreatePWO() {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  let pathSplit = path.split("/");
  let len = pathSplit.length;
  const id = pathSplit[len - 1];
  const [tableData, setTableData] = useState([]);
  const [skuCodeOptions, setSkuCodeOptions] = useState([]);
  const [sourceIdOptions, setSourceIdOptions] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const reasonOptions = [
    "Urgent priority customer order for high quantity",
    "Prebuild stocking required for estimated order",
    "EBO Outlet requirement",
  ];
  const weeklySplitOptions = [
    "W1-25%; W2-25%; W3-25%; W4-25%",
    "W1-33%; W2-33%; W3-34%; W4-0%",
    "W1-10%; W2-30%; W3-30%; W4-30%",
    "W1-20%; W2-20%; W3-20%; W4-40%",
    "Follow Demand Trend (Auto)",
  ];
  const [skuCode, setSkuCode] = useState("");
  const [productionPlantCode, setProductionPlantCode] = useState("");
  const [timePeriod, setTimePeriod] = useState("");
  const [productionQuantity, setProductionQuantity] = useState("");
  const [weeklySplit, setWeeklySplit] = useState("");
  const [week1Split, setweek1Split] = useState("");
  const [week2Split, setweek2Split] = useState("");
  const [week3Split, setweek3Split] = useState("");
  const [week4Split, setweek4Split] = useState("");

  const [status, setStatus] = useState("");
  const [reason, setReason] = useState("");
  const [comments, setComments] = useState("");
  const [errors, setErrors] = useState({
    skuCode: "",
    productionPlantCode: "",
    timePeriod: "",
    productionQuantity: "",
    week1Split: "",
    week2Split: "",
    week3Split: "",
    week4Split: "",
    reason: "",
    comments: "",
  });

  const {
    data: channelList,
    isLoading: isLoadingChannelList,
    isSuccess: isSuccessChannelList,
  } = useChannelsListQuery(undefined, {
    refetchOnMountOrArgChange: true,
    force: true, // This will bypass the cache
  });

  const {
    data: skuCodeList,
    isLoading: isLoadingSkuCodeList,
    isSuccess: isSuccessSkuCodeList,
  } = useSkuCodeListQuery(undefined, {
    refetchOnMountOrArgChange: true,
    force: true, // This will bypass the cache
  });

  const {
    data: sourceDestinationList,
    isLoading: isLoadingSourceDestinationList,
    isSuccess: isSuccessSourceDestinationList,
  } = useSourceDestinationListQuery(undefined, {
    refetchOnMountOrArgChange: true,
    force: true, // This will bypass the cache
  });

  const [addPWO, { isLoading: isLoadingCreatePWOStatus }] =
    usePpAppGroupPlanCreatePWOMutation();

  // const transModeOptions = ["air", "water", "road", "railways"];

  const stoTypeOptions = ["Stock Transfer"];

  useEffect(() => {
    let channelIdOptionsTemp = [];
    let skuCodeOptionsTemp = [];
    let sourceIdOptionsTemp = [];
    if (skuCodeList && skuCodeList.results) {
      skuCodeList.results.forEach((item) =>
        skuCodeOptionsTemp.push(`${item.sku_code} - ${item.sku_name}`)
      );
    }

    if (sourceDestinationList) {
      sourceDestinationList.forEach((item) => {
        if (!sourceIdOptionsTemp.includes(item.from_node_code)) {
          sourceIdOptionsTemp.push(item.from_node_code);
        }
      });
    }
    setSkuCodeOptions(skuCodeOptionsTemp);
    setSourceIdOptions(sourceIdOptionsTemp);
  }, [channelList, skuCodeList, sourceDestinationList]);

  const validateUserInputs = () => {
    const errorsTemp = { ...errors };
    if (skuCode === "") {
      errorsTemp["skuCode"] = "SKU Code cannot be empty";
    } else if (errorsTemp["skuCode"]) {
      errorsTemp["skuCode"] = errors["skuCode"];
    } else {
      errorsTemp["skuCode"] = "";
    }

    if (productionPlantCode === "") {
      errorsTemp["productionPlantCode"] =
        "Production Plant Code cannot be empty";
    } else if (errorsTemp["productionPlantCode"]) {
      errorsTemp["productionPlantCode"] = errors["productionPlantCode"];
    } else {
      errorsTemp["productionPlantCode"] = "";
    }

    if (timePeriod === "") {
      errorsTemp["timePeriod"] = "Time Period cannot be empty";
    } else if (errorsTemp["timePeriod"]) {
      errorsTemp["timePeriod"] = errors["timePeriod"];
    } else {
      errorsTemp["timePeriod"] = "";
    }

    if (productionQuantity === "") {
      errorsTemp["productionQuantity"] = "Production Quantity cannot be empty";
    } else if (errorsTemp["productionQuantity"]) {
      errorsTemp["productionQuantity"] = errors["productionQuantity"];
    } else {
      errorsTemp["productionQuantity"] = "";
    }

    if (week1Split === "") {
      errorsTemp["week1Split"] = "Week-1(M1) Split  cannot be empty";
    } else if (errorsTemp["week1Split"]) {
      errorsTemp["week1Split"] = errors["week1Split"];
    } else {
      errorsTemp["week1Split"] = "";
    }

    if (week2Split === "") {
      errorsTemp["week2Split"] = "Week-2(M1) Split  cannot be empty";
    } else if (errorsTemp["week2Split"]) {
      errorsTemp["week2Split"] = errors["week2Split"];
    } else {
      errorsTemp["week2Split"] = "";
    }

    if (week3Split === "") {
      errorsTemp["week3Split"] = "Week-3(M1) Split  cannot be empty";
    } else if (errorsTemp["week3Split"]) {
      errorsTemp["week3Split"] = errors["week3Split"];
    } else {
      errorsTemp["week3Split"] = "";
    }

    if (week4Split === "") {
      errorsTemp["week4Split"] = "Week-4(M1) Split  cannot be empty";
    } else if (errorsTemp["week4Split"]) {
      errorsTemp["week4Split"] = errors["week4Split"];
    } else {
      errorsTemp["week4Split"] = "";
    }

    if (reason === "") {
      errorsTemp["reason"] = "Reason cannot be empty";
    } else if (errorsTemp["reason"]) {
      errorsTemp["reason"] = errors["reason"];
    } else {
      errorsTemp["reason"] = "";
    }

    if (comments === "") {
      errorsTemp["comments"] = "Comments cannot be empty";
    } else if (errorsTemp["comments"]) {
      errorsTemp["comments"] = errors["comments"];
    } else {
      errorsTemp["comments"] = "";
    }

    setErrors(errorsTemp);
    return (
      errorsTemp["skuCode"] === "" &&
      errorsTemp["productionPlantCode"] === "" &&
      errorsTemp["week1Split"] === "" &&
      errorsTemp["week2Split"] === "" &&
      errorsTemp["week3Split"] === "" &&
      errorsTemp["week4Split"] === "" &&
      errorsTemp["timePeriod"] === "" &&
      errorsTemp["reason"] === "" &&
      errorsTemp["comments"] === "" &&
      errorsTemp["productionQuantity"] === ""
    );
  };

  const handleSaveAddedPWO = () => {
    const isValid = validateUserInputs();
    const splitSum =
      parseFloat(week1Split) +
      parseFloat(week2Split) +
      parseFloat(week3Split) +
      parseFloat(week4Split);
    if (isValid && splitSum === 100) {
      let addedSTO = {
        id: generateUniqueId(),
        skuCode: skuCode,
        productionPlantCode: productionPlantCode,
        timePeriod: timePeriod,
        productionQuantity: productionQuantity,
        week1Split,
        week2Split,
        week3Split,
        week4Split,
        status: status === "" ? "pending" : status,
        reason: reason,
        comments: comments,
      };
      setTableData([...tableData, addedSTO]);
      setSkuCode("");
      setProductionPlantCode("");
      setTimePeriod("");
      setProductionQuantity("");
      setweek1Split("");
      setweek2Split("");
      setweek3Split("");
      setweek4Split("");
      setStatus("");
      setReason("");
      setComments("");
    }
    if (isValid && splitSum !== 100) {
      toast.error(
        "The sum of week1, week2, week3, and week4 splits must be equal to 100.",
        { autoClose: 3000 }
      );
    }
  };
  const saveAllAddedPWO = async () => {
    setIsSaving(true);
    let requestBody = tableData.map((pwo) => ({
      sku_code: pwo.skuCode.split(" - ")[0],
      production_plant_code: pwo.productionPlantCode,
      total_allocation_user: parseFloat(pwo.productionQuantity),
      week_1: parseFloat(pwo.week1Split),
      week_2: parseFloat(pwo.week2Split),
      week_3: parseFloat(pwo.week3Split),
      week_4: parseFloat(pwo.week4Split),
      reason_code: pwo.reason,
      comments: pwo.comments,
      status: pwo.status,
    }));
    try {
      const response = await addPWO({
        id,
        requestBody: requestBody,
      });
      toast.success(response.data.message);
      setIsSaving(false);
      navigate(`/snp/production/production-plan/app-group-level-view/${id}`);
    } catch (error) {
      setIsSaving(false);
      toast.error("PWO Creation Failed");
    }
  };

  const handleDeletePWO = (id) => {
    let filterData = tableData.filter((item) => item.id !== id);
    setTableData(filterData);
  };

  const generateUniqueId = () => Symbol("uniqueId");

  const actionAddStoArray = [
    (row1) => {
      const { row } = row1;
      return (
        <div>
          <button
            className="group-plan-action-button remove"
            style={{}}
            onClick={() => handleDeletePWO(row.id)}
          >
            <DeleteIcon color="#3D445C" height={12} />
          </button>
        </div>
      );
    },
  ];

  function DynamicRefsExample(
    numberOfRefs,
    componentArray,
    cell,
    row,
    centerAlign,
    multipleElements = false
  ) {
    const refsArray = useRef(
      Array.from({ length: numberOfRefs }, () => createRef())
    );
    return (
      <div className={!centerAlign ? "action-buttons" : "action-buttons-align"}>
        {Array.from({ length: numberOfRefs }, (_, index) => {
          const Comp = componentArray[index];

          return (
            <div
              id={index}
              key={index}
              ref={refsArray.current[index]}
              placeholder={`Ref ${index + 1}`}
              //   onClick={() => actionsArray[index](row)}
              style={{
                pointerEvents: row.original.isDisabled ? "none" : "auto",
                display: "flex",
                width: multipleElements ? "" : "100%",
              }}
            >
              <Comp row={row.original} />
            </div>
          );
        })}
      </div>
    );
  }

  return tableData ? (
    <div style={{ padding: "8px 12px 18px 12px" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          alignSelf: "center",
          marginTop: "5px",
          marginBottom: "3px",
        }}
        className="process-header"
      >
        <div
          className="process-header-1"
          onClick={() =>
            navigate("`/snp/production/production-plan`", {
              state: { planLevel: "group" },
            })
          }
        >
          Group Level
        </div>
        <div>
          <svg
            width="9"
            height="9"
            viewBox="0 0 9 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 1L4.5 4.5L1 8" stroke="#858585" strokeLinecap="round" />
            <path
              d="M4.5 1L8 4.5L4.5 8"
              stroke="#929292"
              strokeLinecap="round"
            />
          </svg>
        </div>
        <div
          className="process-header-2 add-sto-header"
          onClick={() =>
            navigate(
              `/snp/production/production-plan/app-group-level-view/${id}`
            )
          }
        >{`Plan View : ${id}`}</div>{" "}
        <div>
          <svg
            width="9"
            height="9"
            viewBox="0 0 9 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 1L4.5 4.5L1 8" stroke="#858585" strokeLinecap="round" />
            <path
              d="M4.5 1L8 4.5L4.5 8"
              stroke="#929292"
              strokeLinecap="round"
            />
          </svg>
        </div>
        <div className="process-header-2">Add PWO</div>{" "}
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "12px",
          // justifyContent: "space-between",
          paddingTop: "12px",
        }}
      >
        <SelectInput
          label="SKU Code"
          placeholderValue="Select SKU Code"
          options={skuCodeOptions}
          inputValue={skuCode}
          handleInputValue={setSkuCode}
          required={true}
          errorMsg={errors.skuCode}
          setErrors={setErrors}
          name="skuCode"
        />
        <SelectInput
          label="Production Plant Code"
          placeholderValue="Select Production Plant Code"
          options={sourceIdOptions}
          inputValue={productionPlantCode}
          handleInputValue={setProductionPlantCode}
          required={true}
          errorMsg={errors.productionPlantCode}
          setErrors={setErrors}
          name="productionPlantCode"
        />
        <DateInput
          label="Time Period"
          inputValue={timePeriod}
          handleInputValue={setTimePeriod}
          required={true}
          errorMsg={errors.timePeriod}
          setErrors={setErrors}
          name="timePeriod"
        />
        <TextInput
          placeholderValue="Enter Quantity"
          label="Production Quantity"
          inputValue={productionQuantity}
          handleInputValue={setProductionQuantity}
          inputType="number"
          required={true}
          errorMsg={errors.productionQuantity}
          name="productionQuantity"
          setErrors={setErrors}
        />
        <TextInput
          placeholderValue="Enter Week 1 Split"
          label="Week-1(M1)"
          inputValue={week1Split}
          handleInputValue={setweek1Split}
          inputType="number"
          required={true}
          errorMsg={errors.week1Split}
          name="week1Split"
          setErrors={setErrors}
        />
        <TextInput
          placeholderValue="Enter Week 2 Split"
          label="Week-2(M1)"
          inputValue={week2Split}
          handleInputValue={setweek2Split}
          inputType="number"
          required={true}
          errorMsg={errors.week2Split}
          name="week2Split"
          setErrors={setErrors}
        />
        <TextInput
          placeholderValue="Enter Week 3 Split"
          label="Week-3(M1)"
          inputValue={week3Split}
          handleInputValue={setweek3Split}
          inputType="number"
          required={true}
          errorMsg={errors.week3Split}
          name="week3Split"
          setErrors={setErrors}
        />
        <TextInput
          placeholderValue="Enter Week 4 Split"
          label="Week-4(M1)"
          inputValue={week4Split}
          handleInputValue={setweek4Split}
          inputType="number"
          required={true}
          errorMsg={errors.week4Split}
          name="week4Split"
          setErrors={setErrors}
        />

        <SelectInput
          placeholderValue="Enter Reason"
          label="Reason"
          inputValue={reason}
          handleInputValue={setReason}
          options={reasonOptions}
          required={true}
          errorMsg={errors.reason}
          setErrors={setErrors}
          name="reason"
        />
        <TextInput
          placeholderValue="Enter Comments"
          label="Comments"
          inputValue={comments}
          handleInputValue={setComments}
          inputType="text"
          required={true}
          errorMsg={errors.comments}
          name="comments"
          setErrors={setErrors}
        />
      </div>
      <div className="add-sto-buttons">
        <select
          className="add-sto-select-status"
          onChange={(e) => setStatus(e.target.value)}
          value={status}
        >
          <option value="">Select Status</option>
          <option value="accepted">Accepted</option>
          <option value="rejected">Rejected</option>
          <option value="pending">Pending</option>
        </select>
        <button className="add-sto-save" onClick={handleSaveAddedPWO}>
          Save & Add Another
        </button>
      </div>
      <hr className="add-sto-hor-line" />{" "}
      {tableData && (
        <div
          className="snp-table-container"
          // style={{ width: `calc( 100% - 20px)`, marginLeft: "10px" }}
        >
          <MuiTable
            columns={[
              {
                accessorKey: "skuCode",
                header: "SKU Code",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "productionPlantCode",
                header: "Production Plant Code",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "timePeriod",
                header: "Time Period",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "productionQuantity",
                header: "Production Quantity",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "week1Split",
                header: "Week-1(M1)",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "week2Split",
                header: "Week-2(M1)",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "week3Split",
                header: "Week-3(M1)",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "week4Split",
                header: "Week-4(M1)",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "status",
                header: "Status",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "reason",
                header: "Reason",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "comments",
                header: "Comments",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "action",
                header: "Action",
                enableColumnActions: false,
                enableSorting: false,
                muiTableBodyCellEditTextFieldProps: {
                  select: true, //change to select for a dropdown
                },
                size: 100,
                Cell: ({ cell, row }) => {
                  const centerAlign = true;
                  const multipleElements = true;
                  return DynamicRefsExample(
                    1,
                    actionAddStoArray,
                    cell,
                    row,
                    centerAlign,
                    multipleElements
                  );
                },
              },
            ]}
            td={tableData}
            shouldShowAddBtn={false}
            showToolbarInternalActions={false}
            showPagination={false}
            // renderTopToolbarCustomActions={renderPreprocessesHeading}
          />
        </div>
      )}
      <div className="add-sto-save-cancel-buttons">
        <button
          className="add-sto-cancel-button"
          onClick={() => {
            navigate(
              `/snp/production/production-plan/app-group-level-view/${id}`
            );
          }}
        >
          Cancel
        </button>
        <button
          className={
            tableData.length === 0
              ? "add-sto-save-button add-sto-save-button-disabled"
              : "add-sto-save-button"
          }
          onClick={saveAllAddedPWO}
          disabled={tableData.length === 0}
        >
          {isSaving ? "Saving.." : "Save"}
        </button>
      </div>
    </div>
  ) : (
    "loading"
  );
}

export default CreatePWO;
