import "./ApprovalComment.scss";
import { capitalizeString } from "../tableUtils/tableUtils";

const ApprovalComment = ({ comment }) => {
  const generateAvatar = (firstName, lastName) => {
    const firstNameInitial =
      firstName !== null && firstName !== "" ? firstName[0].toUpperCase() : "";
    const lastNameInitial =
      lastName !== null && lastName !== "" ? lastName[0].toUpperCase() : "";

    return `${firstNameInitial}${lastNameInitial}`;
  };

  const timeAgo = (timestamp) => {
    const currentDate = new Date();
    const inputDate = new Date(timestamp);

    const timeDifference = currentDate - inputDate;
    const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));

    if (hoursDifference < 1) {
      return "Just now";
    } else if (hoursDifference < 24) {
      return `${hoursDifference} ${
        hoursDifference === 1 ? "hour" : "hours"
      } ago`;
    } else {
      const daysDifference = Math.floor(hoursDifference / 24);
      return `${daysDifference} ${daysDifference === 1 ? "day" : "days"} ago`;
    }
  };

  const getApprovalStatusValues = (status) => {
    if (status === "APPROVED") {
      return `approval-comment-action approval-status-approved`;
    } else if (status === "REJECTED") {
      return `approval-comment-action approval-status-rejected`;
    } else {
      return `approval-comment-action approval-status-submit`;
    }
  };

  return (
    <div className="approval-comment-container">
      <div className="approval-comment-avatar">
        {generateAvatar(comment.actor.first_name, comment.actor.last_name)}
      </div>
      <div className="approval-comment">
        <div className="approval-comment-header">
          <h1 className="approval-comment-username ">{`${capitalizeString(
            comment.actor.first_name
          )} ${capitalizeString(comment.actor.last_name)}`}</h1>{" "}
          <span>{timeAgo(comment.createdOn)}</span>
        </div>
        <div
          style={{
            display: "flex",
            gap: "5px",
            marginTop: "3px",
            marginBottom: "6px",
          }}
        >
          <span className="approval-comment-level">{`Level ${comment.level}`}</span>
          <span className={getApprovalStatusValues(comment.action)}>
            {capitalizeString(comment.action)}
          </span>
        </div>
        <p className="approval-comment-text">
          {comment.comment !== "" ? comment.comment : "No comment."}
        </p>
      </div>
    </div>
  );
};

export default ApprovalComment;
