/* eslint-disable no-unused-vars */
import { useState, useEffect, useRef, createRef } from "react";
import MuiTable from "../components/muiTable/muiTable";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "../components/customSvgIcons/edit";
import DeleteIcon from "../components/customSvgIcons/delete";
import { useAllGroupRulesQuery } from "../features/api/schedularSlice";
import { TextField, Autocomplete, Checkbox } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckSharpIcon from "@mui/icons-material/CheckSharp";
import { useNavigate, useLocation } from "react-router-dom";
import {
  setConfigurationMode,
  setConfigurationProps,
  setConfigTableData,
} from "../features/ConfigurationsSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { CircularIndefiniteSpinner } from "../components/loading/LoadingIndex";
import UploadModal from "../components/modal/UploadModal";
import { handleApiArrayResponse } from "../utils/apiUtils";

function sortObjectKeysAlphabetically(obj) {
  if (!obj) {
    return {};
  }
  const sortedKeys = Object.keys(obj).sort();
  const sortedObject = {};

  for (const key of sortedKeys) {
    sortedObject[key] = obj[key];
  }

  return sortedObject;
}
const transformTableData = (arr) => {
  console.log({ arr });
  return arr.map((item) => ({
    id: item.id,
    name: item.rule_set_name,
    jobType: item.group.module.name,
    jobTypeId: item.group.module.id,
    categoryType: item.group.group_type,
    jobCategory: item.group.name,
    jobCategoryId: item.group.id,
    configuration: JSON.stringify(item.rule_set_value),
    job_scheduler_id: item.job_scheduler_id,
    lastStatus: null,
    createdAt: null,
    createdBy: null,
    lastEditedBy: null,
    lastEditedOn: null,
    groupRulesName: item.rule_set_name,
    rule_set_value: sortObjectKeysAlphabetically(item.rule_set_value),
    //  groupRulesId: item.group_rule.id,
  }));
};

//navigate(routeName?.CATEGORY);
function ConfigLanding() {
  const dispatch = useDispatch();

  dispatch(setConfigurationMode("create"));

  const {
    data: allGroupRules,
    isFetching: isLoadingAllGroupRules,
    isSuccess: isSuccessAllGroupRules,
  } = useAllGroupRulesQuery(undefined, {
    refetchOnMountOrArgChange: true,
    force: true, // This will bypass the cache
  });

  //   const {
  //     data: jobType1,
  //     isLoading: isLoadingJobType,
  //     isSuccess: isSuccessJobType,
  //   } = useJobTypeQuery(undefined, {
  //     refetchOnMountOrArgChange: true,
  //     force: true, // This will bypass the cache
  //   });

  const mode = useSelector((state) => state.jobScheduler.jobSchedulerMode);
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [stateColumnValues, setStateColumnValues] = useState([]);
  const [level, setLevel] = useState("org");
  const [openDeleteConfigModal, setOpenDeleteConfigModal] = useState(false);
  const [selectedConfig, setSelectedConfig] = useState({});

  const baseURL = process.env.REACT_APP_SCAI_API_ENDPOINT;
  const location = useLocation();
  const path = location.pathname;
  let idArr = path.split("/");
  const icon = (
    <CheckBoxOutlineBlankIcon fontSize="small" sx={{ display: "none" }} />
  );
  const checkSharpIcon = <CheckSharpIcon fontSize="small" />;
  useEffect(() => {
    dispatch(setConfigurationMode("create"));
    allGroupRules
      ? console.log({
          tt: transformTableData(allGroupRules.data),
        })
      : null;
    setTableData(allGroupRules ? transformTableData(allGroupRules.data) : []);
    dispatch(
      allGroupRules
        ? setConfigTableData(transformTableData(allGroupRules.data))
        : setConfigTableData([])
    );
  }, [allGroupRules]);
  // const deleteQuery = async (id) => {
  //   const myHeaders = new Headers();
  //   myHeaders.append(
  //     "Authorization",
  //     `Bearer ${JSON.parse(localStorage.getItem("token"))}`
  //   );

  //   const requestOptions = {
  //     method: "DELETE",
  //     headers: myHeaders,
  //     redirect: "follow",
  //   };

  //   try {
  //     const response = await fetch(
  //       `${baseURL}/rnc/group_rules/${id}`,
  //       requestOptions
  //     );
  //     console.log({ response: response.data });
  //     if (response.ok) {
  //       const rules = await response.json();
  //       setTableData((td) => td.filter((item) => item.id !== id));
  //       toast.success("Deletion successful!");
  //       navigate(`/${idArr[1]}/${idArr[2]}/${idArr[3]}/config`);
  //     } else {
  //       // toast.error("Error fetching rules");
  //       throw new Error();
  //     }
  //   } catch (error) {
  //     toast.error("Encountered error in deletion111111");
  //     console.log("Error fetching rules", error);
  //     return [];
  //   }
  // };
  const deleteQuery = async (id) => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${JSON.parse(localStorage.getItem("token"))}`
    );

    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${baseURL}/rnc/group_rules/${id}/`,
        requestOptions
      );
      const rules = await handleApiArrayResponse(response);
      setTableData((td) => td.filter((item) => item.id !== id));
      toast.success("Deletion successful!");
      navigate(`/${idArr[1]}/${idArr[2]}/${idArr[3]}/config`);
      // if (response.ok) {
      //   // const rules = await response.json();
      //   setTableData((td) => td.filter((item) => item.id !== id));
      //   toast.success("Deletion successful!");
      //   navigate(`/${idArr[1]}/${idArr[2]}/${idArr[3]}/config`);
      // } else {
      //   // Handle the case where the response status code is not OK (e.g., 400)
      //   const errorResponseData = await response.json();

      //   // Assuming the error response contains an array of error messages
      //   if (Array.isArray(errorResponseData)) {
      //     const errorMessage = errorResponseData;
      //     toast.error(`Error in deletion: ${errorMessage}`, {
      //       autoClose: 4000,
      //     });
      //   } else {
      //     // If the error response does not have an "errors" array, display a generic error message
      //     toast.error("Error in deletion");
      //   }
      //   console.log("Error response data:", errorResponseData);
      // }
    } catch (error) {
      if (error.message) {
        toast.error(error.message, { autoClose: 3000 });
      } else {
        toast.error("Encountered error in deletion");
      }
      // toast.error("Encountered error in deletion");
      console.error("Error fetching rules", error);
    }
  };

  const actionsArray = [
    (row) => {
      let {
        id,
        jobType,
        jobCategory,
        jobCategoryId,

        groupRulesName,

        jobTypeId,
        rule_set_value,
        rule_set_name,
      } = row.original;
      let jobTypeSelected = { value: jobTypeId, label: jobType };
      let jobCategorySelected = { label: jobCategory, value: jobCategoryId };
      dispatch(setConfigurationMode("edit"));
      console.log({ rule_set_name: row.original });
      console.log({ rule_set_value });
      console.log({
        dispatch: {
          name: groupRulesName,
          jobTypeSelected,
          jobCategorySelected,
          selectedRules: Object.values(
            // sortObjectKeysAlphabetically(rule_set_value)
            rule_set_value
          ),
          allRules: rule_set_value,
        },
      });
      dispatch(
        setConfigurationProps({
          name: groupRulesName,
          jobTypeSelected,
          jobCategorySelected,
          selectedRules: Object.values(
            // sortObjectKeysAlphabetically(rule_set_value)
            rule_set_value
          ),
          allRules: rule_set_value,
        })
      );
      console.log({
        aaaa: {
          name: groupRulesName,
          jobTypeSelected,
          jobCategorySelected,
          selectedRules: Object.values(
            sortObjectKeysAlphabetically(rule_set_value)
          ),
          allRules: rule_set_value,
        },
      });
      navigate(`/${idArr[1]}/${idArr[2]}/${idArr[3]}/config-edit/${id}`, {
        state: { isJobSchedulerConfig: false },
      });
    },
    (row) => {
      setOpenDeleteConfigModal(true);
      setSelectedConfig(row.original);
      // deleteQuery(row.original.id);
    },
  ];
  function DynamicRefsExample(numberOfRefs, componentArray, cell, row) {
    const refsArray = useRef(
      Array.from({ length: numberOfRefs }, () => createRef())
    );
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        {Array.from({ length: numberOfRefs }, (_, index) => {
          const Comp = componentArray[index];

          return (
            <div
              id={index}
              key={index}
              ref={refsArray.current[index]}
              placeholder={`Ref ${index + 1}`}
              onClick={() => actionsArray[index](row)}
              style={{
                pointerEvents: row.original.isDisabled ? "none" : "auto",
              }}
            >
              <Comp />
            </div>
          );
        })}
      </div>
    );
  }

  const actionButtonsArray = [
    () => (
      <button className="table-action-button edit-button">
        <EditIcon color="#3D445C" height={12} />
      </button>
    ),
    () => (
      <button className="table-action-button delete-button">
        <DeleteIcon color="#3D445C" height={12} />
      </button>
    ),
  ];

  const renderLevelButtons = () => {
    return (
      <div className="header-btns">
        <button
          onClick={() => setLevel("org")}
          className={
            level === "org"
              ? "header-level-button header-active-btn"
              : "header-level-button"
          }
        >
          User
        </button>
        <button
          onClick={() => {
            console.log("clicled");
            setLevel("group");
          }}
          className={
            level === "group"
              ? "header-level-button header-active-btn"
              : "header-level-button"
          }
        >
          System
        </button>
      </div>
    );
  };

  return (
    <>
      <div
        className="snp-table-container"
        style={{ width: `calc( 100% - 20px)`, marginLeft: "10px" }}
      >
        <UploadModal
          openModal={openDeleteConfigModal}
          setOpenModal={setOpenDeleteConfigModal}
          handleDelete={deleteQuery}
          selectedConfig={selectedConfig}
          modalType="config-delete"
        />
        <MuiTable
          columns={[
            {
              accessorKey: "name",
              header: "Configuration Name",
              enableColumnOrdering: false,
              enableEditing: false,
              enableSorting: true,
              size: 180,
              Cell: ({ cell, row, column }) => {
                return (
                  <div
                    className={
                      row.original.isDisabled ? "disabled-cell" : "active-cell"
                    }
                  >
                    {cell.getValue()}
                  </div>
                );
              },
            },
            {
              accessorKey: "jobType",
              header: "Job Type",
              enableColumnOrdering: false,
              enableEditing: false,
              enableSorting: true,
              size: 100,
              Cell: ({ cell, row, column }) => {
                return (
                  <div
                    className={
                      row.original.isDisabled ? "disabled-cell" : "active-cell"
                    }
                  >
                    {cell.getValue()}
                  </div>
                );
              },
              Filter: ({ header }) => (
                <Autocomplete
                  onChange={(e, newValue) => {
                    setStateColumnValues(newValue);
                    header.column.setFilterValue(newValue);
                  }}
                  sx={{
                    "& .MuiAutocomplete-clearIndicator": {
                      display: "none",
                    },
                  }}
                  multiple
                  size="small"
                  id="checkboxes-tags-demo"
                  options={["RP", "PP"].sort((a, b) => {
                    let ai = stateColumnValues.indexOf(a);
                    ai =
                      ai === -1
                        ? stateColumnValues.length + ["RP", "PP"].indexOf(a)
                        : ai;
                    let bi = stateColumnValues.indexOf(b);
                    bi =
                      bi === -1
                        ? stateColumnValues.length + ["RP", "PP"].indexOf(b)
                        : bi;
                    return ai - bi;
                  })}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option}
                  renderTags={() => null}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkSharpIcon}
                        style={{ width: "20%", margin: "0", padding: "0" }}
                        checked={selected}
                      />
                      {option}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label="" placeholder="" />
                  )}
                />
              ),
              filterFn: "arrIncludesSome",
            },

            {
              accessorKey: "categoryType",
              header: "Category Type",
              enableColumnOrdering: false,
              enableEditing: false,
              enableSorting: true,
              size: 140,
              Cell: ({ cell, row, column }) => {
                return (
                  <div
                    className={
                      row.original.isDisabled ? "disabled-cell" : "active-cell"
                    }
                  >
                    {cell.getValue()}
                  </div>
                );
              },
            },
            {
              accessorKey: "createdAt",
              header: "Created At",
              enableColumnOrdering: false,
              enableEditing: false,
              enableSorting: true,
              size: 140,
              Cell: ({ cell, row, column }) => {
                return (
                  <div
                    className={
                      row.original.isDisabled ? "disabled-cell" : "active-cell"
                    }
                  >
                    {cell.getValue()}
                  </div>
                );
              },
            },
            {
              accessorKey: "createdBy",
              header: "Created By",
              enableColumnOrdering: false,
              enableEditing: false,
              enableSorting: true,
              size: 140,
              Cell: ({ cell, row, column }) => {
                return (
                  <div
                    className={
                      row.original.isDisabled ? "disabled-cell" : "active-cell"
                    }
                  >
                    {cell.getValue()}
                  </div>
                );
              },
            },
            {
              accessorKey: "lastEditedBy",
              header: "Last Edited by",
              enableColumnOrdering: false,
              enableEditing: false,
              enableSorting: true,
              size: 140,
              Cell: ({ cell, row, column }) => {
                return (
                  <div
                    className={
                      row.original.isDisabled ? "disabled-cell" : "active-cell"
                    }
                  >
                    {cell.getValue()}
                  </div>
                );
              },
            },
            {
              accessorKey: "lastEditedOn",
              header: "Last Edited at",
              enableColumnOrdering: false,
              enableEditing: false,
              enableSorting: true,
              size: 140,
              Cell: ({ cell, row, column }) => {
                return (
                  <div
                    className={
                      row.original.isDisabled ? "disabled-cell" : "active-cell"
                    }
                  >
                    {cell.getValue()}
                  </div>
                );
              },
            },
            {
              accessorKey: "action",
              header: "Actions",
              enableSorting: false,
              muiTableBodyCellEditTextFieldProps: {
                select: true, //change to select for a dropdown
              },
              Cell: ({ cell, row }) => {
                return DynamicRefsExample(2, actionButtonsArray, cell, row);
              },
            },
          ]}
          isLoading={isLoadingAllGroupRules}
          td={tableData}
          createPath="/snp/inventory/management/config-create"
          renderTopToolbarCustomActions={renderLevelButtons}
        />
      </div>
    </>
  );
}

export default ConfigLanding;
