import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isFilterOpen: false, // Your initial value here
  filterRules: [],
  filterRules2: [],
  filterType: "filter",
  allAppliedFilters: {},
};

const filterSlice = createSlice({
  name: "filterSlice",
  initialState,
  reducers: {
    setIsFilterOpen: (state, action) => {
      state.isFilterOpen = action.payload;
    },
    setFilterRules: (state, action) => {
      state.filterRules = action.payload;
    },
    setFilterRules2: (state, action) => {
      state.filterRules2 = action.payload;
    },
    setFilterType: (state, action) => {
      state.filterType = action.payload;
    },
    setAllAppliedFilters: (state, action) => {
      state.allAppliedFilters = action.payload;
    },
  },
});

export const {
  setIsFilterOpen,
  setFilterRules,
  setFilterRules2,
  setFilterType,
  setAllAppliedFilters,
} = filterSlice.actions;
export default filterSlice.reducer;
