import { createApi } from "@reduxjs/toolkit/query/react";
import { customBaseQuery } from "../../utils/apiUtils";

export const ordersManagementSlice = createApi({
  reducerPath: "ordersManagement",
  baseQuery: customBaseQuery({
    baseUrl: `${process.env.REACT_APP_SCAI_API_ENDPOINT}/`,
  }),
  tagTypes: [],
  endpoints: (builder) => ({
    groupCodes: builder.query({
      query: () => ({
        url: "rnc/groups/",
        method: "GET",
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
        },
      }),
    }),
    validateCreatePlan: builder.mutation({
      query: ({ requestBody }) => ({
        url: `/oms/validate-create-plan/`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
          "Content-Type": "application/json",
        },
        body: requestBody,
      }),
    }),
    createPlan: builder.mutation({
      query: ({ requestBody }) => ({
        url: `oms/create-plan/`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
          "Content-Type": "application/json",
        },
        body: requestBody,
      }),
    }),
    validateMergePlan: builder.mutation({
      query: ({ requestBody }) => ({
        url: `/oms/validate-merge-plan/`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
          "Content-Type": "application/json",
        },
        body: requestBody,
      }),
    }),
    mergePlan: builder.mutation({
      query: ({ requestBody }) => ({
        url: `oms/merge-to-plan/`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
          "Content-Type": "application/json",
        },
        body: requestBody,
      }),
    }),
    plansData: builder.query({
      query: ({ planDate, groupId }) => ({
        url: `/oms/get-dropdown/?group=${groupId}&plan_date=${planDate}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
        },
      }),
    }),
    omsBaseTable: builder.query({
      query: ({ page, page_size }) => {
        return {
          url: "/oms/",
          method: "GET",
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("token")
            )}`,
          },
          params: {
            page,
            page_size,
          },
        };
      },
    }),
    downloadCsvTemplate: builder.query({
      query: ({ group }) => {
        return {
          url: "/oms/download-csv-template/",
          method: "GET",
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("token")
            )}`,
          },
          params: {
            group,
          },
        };
      },
    }),
    allGroups: builder.query({
      query: () => {
        return {
          url: "/rnc/groups/",
          method: "GET",
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("token")
            )}`,
          },
        };
      },
    }),
  }),
});

export const {
  useGroupCodesQuery,
  useValidateCreatePlanMutation,
  useCreatePlanMutation,
  useValidateMergePlanMutation,
  useMergePlanMutation,
  usePlansDataQuery,
  useAllGroupsQuery,
  useOmsBaseTableQuery,
  useLazyDownloadCsvTemplateQuery,
} = ordersManagementSlice;
