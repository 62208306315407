import { useState, useEffect } from "react";
import { useStockLevelQuery } from "../../../../features/api/inventorySlice";
import MuiTable from "../../../../components/muiTable/muiTable";
import { loadingColumns, newStockLevelCols, stockLevelCols } from "./columns";
import { stockLevelData } from "./data";
import { DataTable } from "@/components/tanstack-table/data-table";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Cross2Icon } from "@radix-ui/react-icons";
import { DataTableViewOptions } from "@/components/tanstack-table/data-table-view-options";
import { Skeleton } from "@/components/ui/skeleton";
import { CircularIndefiniteSpinner } from "@/components/loading/LoadingIndex";

const transformData = (arr) => {
  return arr.map((item) => ({
    classification_xyz: item.xyz,
    classification_abc: item.abc,
    safety_stock: item.ss,
    // lastUpdatedAt: formatDate(item.last_updated_at),
    msl: item.msl,
    node_id: item.node_id,
    node_name: item.node_name,
    sku_id: item.sku_id,
    sku_description: item.sku_description,
    channel_id: item.channel_name,
  }));
};

const muiTableContainerProps = { sx: { maxHeight: "350px" } };

function StockLevelTable() {
  const [tableData, setTableData] = useState(Array(10).fill({}));
  const [newColumns, setNewColumns] = useState(loadingColumns());
  const [isLoadingStockLevel, setIsLoadingStockLevel] = useState(true);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_SCAI_API_ENDPOINT}/masters/stock_level_table/`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
        },
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        console.log(data);
        setTableData(transformData(data));
        setIsLoadingStockLevel(false);
      });
  }, []);

  return (
    <div
      className="snp-table-container mini-table px-2 pb-4"
      // style={{
      //   width: "100%",
      //   margin: "auto",
      //   overflow: "hidden",
      //   // overflowX: "overlay",
      // }}
      // style={{ overflow: "hidden" }}
    >
      {/* <MuiTable
        columns={stockLevelCols}
        td={tableData}
        shouldShowAddBtn={false}
        enableTopToolbar={false}
        enableBottomToolbar={false}
        muiTableContainerProps={muiTableContainerProps}
        isLoading={isLoading}
      /> */}
      {/* {isLoadingStockLevel ? (
        <div className="space-y-2 relative">
          <div className="absolute top-1/2 left-1/2 z-10 flex items-center -translate-x-1/2 -translate-y-1/2">
            <span className="text-xl">Stock Level Table Loading </span>
            <CircularIndefiniteSpinner size={"18px"} />
          </div>
          {[1, 2, 3].map(() => (
            <>
              <Skeleton className="h-5 w-full" />
              <Skeleton className="h-5 w-full" />
              <Skeleton className="h-5 w-full" />
              <Skeleton className="h-5 w-full" />
              <Skeleton className="h-5 w-full" />
            </>
          ))}
        </div>
      ) : ( */}
      <DataTable
        CustomToolbar={StockLevelTableToolbar}
        data={tableData}
        columns={newColumns}
        canResizeColumns={true}
        showSelectedColumnsCount={false}
      />
      {/* )} */}
    </div>
  );
}

export function StockLevelTableToolbar({ table }) {
  const isFiltered = table.getState().columnFilters.length > 0;

  return (
    <div className="flex items-center justify-between space-x-2">
      <span style={{ fontFamily: "IBM Plex Sans" }}>Stock Level Table</span>
      <div className="flex items-center space-x-20">
        <div className="flex items-center space-x-2">
          <Input
            placeholder="Filter tasks..."
            value={table.getColumn("title")?.getFilterValue() ?? ""}
            onChange={(event) =>
              table.getColumn("title")?.setFilterValue(event.target.value)
            }
            className="h-8 w-[150px] lg:w-[250px] box-shadow-custom"
          />
          {/*
        {table.getColumn("status") && (
          <DataTableFacetedFilter
            column={table.getColumn("status")}
            title="Status"
            options={statuses}
          />
        )}
        {table.getColumn("priority") && (
          <DataTableFacetedFilter
            column={table.getColumn("priority")}
            title="Priority"
            options={priorities}
          />
        )}
        */}
          {isFiltered && (
            <Button
              variant="ghost"
              onClick={() => table.resetColumnFilters()}
              className="h-8 px-2 lg:px-3"
            >
              Reset
              <Cross2Icon className="ml-2 h-4 w-4" />
            </Button>
          )}
        </div>
        <DataTableViewOptions table={table} />
      </div>
    </div>
  );
}

export default StockLevelTable;
