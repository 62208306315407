import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useState, useEffect } from "react";

function InventoryStatistics() {
  const [options, setOptions] = useState({});

  useEffect(() => {
    const chartOptions = {
      chart: {
        type: "spline",
      },
      title: {
        text: "",
      },
      xAxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        accessibility: {
          description: "Months of the year",
        },
      },
      tooltip: {
        crosshairs: true,
        shared: true,
      },
      plotOptions: {
        area: {
          fillOpacity: 0.1,
        },
      },
      credits: {
        enabled: false,
      },
      colors: ["#70D880"],
      series: [
        {
          marker: {
            symbol: "square",
          },
          data: [
            5.2,
            5.7,
            8.7,
            13.9,
            18.2,
            21.4,
            25.0,
            {
              y: 26.4,
              marker: {
                symbol:
                  "url(https://www.highcharts.com/samples/graphics/sun.png)",
              },
              accessibility: {
                description:
                  "Sunny symbol, this is the warmest point in the chart.",
              },
            },
            22.8,
            17.5,
            12.1,
            7.6,
          ],
        },
      ],
    };
    setOptions(chartOptions);
  }, []);

  return <HighchartsReact highcharts={Highcharts} options={options} />;
}

export default InventoryStatistics;
