import { useState, useEffect, useRef, createRef } from "react";
import MuiTable from "../../../components/muiTable/muiTable";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import "./styles.css";
import SelectInput from "../../../components/add-sto/SelectInput";
import TextInput from "../../../components/add-sto/TextInput";
import DateInput from "../../../components/add-sto/DateInput";
import {
  useCreateStoMutation,
  useGetReasonsQuery,
} from "../../../features/api/replPlanSlice";
import {
  useChannelsListQuery,
  useSkuCodeListQuery,
  useSourceDestinationListQuery,
} from "../../../features/api/createStoSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteIcon from "../../../components/customSvgIcons/delete";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import { CircularIndefiniteSpinner } from "../../../components/loading/LoadingIndex";

function CreateSTO() {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  let pathSplit = path.split("/");
  let len = pathSplit.length;
  const planId = pathSplit[len - 1];
  const [tableData, setTableData] = useState([]);
  const [channelIdOptions, setChannelIdOptions] = useState([]);
  const [skuCodeOptions, setSkuCodeOptions] = useState([]);
  const [sourceIdOptions, setSourceIdOptions] = useState([]);
  const [destinationIdOptions, setDestinationIdOptions] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [skuCode, setSkuCode] = useState("");
  const [destinationId, setDestinationId] = useState("");
  const [sourceId, setSourceId] = useState("");
  const [channelId, setChannelId] = useState("");
  //Highlight next day in calendar
  const today = new Date();
  const tomorrow = new Date(today);
  if (today.getDay() === 6) {
    // If today is Saturday, add 2 days - because shipping cannot be done on Sunday
    tomorrow.setDate(today.getDate() + 2);
  } else {
    // For all other days, add 1 day
    tomorrow.setDate(today.getDate() + 1);
  }
  const formattedDate = tomorrow.toISOString().split("T")[0];
  const [shippingDate, setShippingDate] = useState(formattedDate);
  const [replenishmentQuantity, setReplenishmentQuantity] = useState("");
  const [stoType, setStoType] = useState("");
  const [transMode, setTransMode] = useState("");
  //const [status, setStatus] = useState("");
  const [reason, setReason] = useState("");
  const [comments, setComments] = useState("");
  const [errors, setErrors] = useState({
    skuCode: "",
    sourceId: "",
    destinationId: "",
    channelId: "",
    shippingDate: "",
    replenishmentQuantity: "",
    transMode: "",
    reason: "",
    //comments: "",
    stoType: "",
  });
  const [isLoadingOverlay, setIsLoadingOverlay] = useState(true);

  const {
    data: channelList,
    isLoading: isLoadingChannelList,
    isSuccess: isSuccessChannelList,
  } = useChannelsListQuery(undefined, {
    refetchOnMountOrArgChange: true,
    force: true, // This will bypass the cache
  });

  const {
    data: skuCodeList,
    isLoading: isLoadingSkuCodeList,
    isSuccess: isSuccessSkuCodeList,
  } = useSkuCodeListQuery(undefined, {
    refetchOnMountOrArgChange: true,
    force: true, // This will bypass the cache
  });

  const {
    data: sourceDestinationList,
    isLoading: isLoadingSourceDestinationList,
    isSuccess: isSuccessSourceDestinationList,
  } = useSourceDestinationListQuery(undefined, {
    refetchOnMountOrArgChange: true,
    force: true, // This will bypass the cache
  });

  const {
    data: reasonOptions,
    isLoading: isLoadingReasonOptions,
    isSuccess: isSuccessReasonOptions,
  } = useGetReasonsQuery(planId, {
    refetchOnMountOrArgChange: true,
    force: true, // This will bypass the cache
  });

  const [addSTO, { isLoadingUpdateSTOStatus, isErrorUpdateSTOStatus }] =
    useCreateStoMutation();

  const transModeOptions = ["road"];

  const stoTypeOptions = ["Stock Transfer"];

  useEffect(() => {
    let channelIdOptionsTemp = [];
    let skuCodeOptionsTemp = [];
    let sourceIdOptionsTemp = [];
    let destinationIdOptionsTemp = [];
    if (channelList && channelList.results) {
      channelList.results.forEach((item) =>
        channelIdOptionsTemp.push(item.channel_name)
      );
    }
    if (skuCodeList && skuCodeList.results) {
      skuCodeList.results.forEach((item) =>
        skuCodeOptionsTemp.push(`${item.sku_code} - ${item.sku_name}`)
      );
    }

    if (sourceDestinationList) {
      sourceDestinationList.forEach((item) => {
        if (!sourceIdOptionsTemp.includes(item.from_node_code)) {
          sourceIdOptionsTemp.push(item.from_node_code);
        }
        if (!destinationIdOptionsTemp.includes(item.to_node_code)) {
          destinationIdOptionsTemp.push(item.to_node_code);
        }
      });
    }
    setChannelIdOptions(channelIdOptionsTemp);
    setSkuCodeOptions(skuCodeOptionsTemp);
    setSourceIdOptions(sourceIdOptionsTemp);
    setDestinationIdOptions(destinationIdOptionsTemp);
  }, [channelList, skuCodeList, sourceDestinationList]);

  useEffect(() => {
    const isLoading = !(
      isSuccessChannelList &&
      isSuccessReasonOptions &&
      isSuccessSkuCodeList &&
      isSuccessSourceDestinationList
    );
    setIsLoadingOverlay(isLoading);
  }, [
    isSuccessChannelList,
    isSuccessReasonOptions,
    isSuccessSkuCodeList,
    isSuccessSourceDestinationList,
  ]);

  const validateUserInputs = () => {
    const errorsTemp = { ...errors };
    if (skuCode === "") {
      errorsTemp["skuCode"] = "SKU Code cannot be empty";
    } else if (errorsTemp["skuCode"]) {
      errorsTemp["skuCode"] = errors["skuCode"];
    } else {
      errorsTemp["skuCode"] = "";
    }

    if (sourceId === "") {
      errorsTemp["sourceId"] = "Source cannot be empty";
    } else if (errorsTemp["sourceId"]) {
      errorsTemp["sourceId"] = errors["sourceId"];
    } else {
      errorsTemp["sourceId"] = "";
    }

    if (destinationId === "") {
      errorsTemp["destinationId"] = "Destination cannot be empty";
    } else if (errorsTemp["destinationId"]) {
      errorsTemp["destinationId"] = errors["destinationId"];
    } else {
      errorsTemp["destinationId"] = "";
    }

    if (channelId === "") {
      errorsTemp["channelId"] = "Channel cannot be empty";
    } else if (errorsTemp["channelId"]) {
      errorsTemp["channelId"] = errors["channelId"];
    } else {
      errorsTemp["channelId"] = "";
    }

    if (shippingDate === "") {
      errorsTemp["shippingDate"] = "Shipping Date cannot be empty";
    } else if (errorsTemp["shippingDate"]) {
      errorsTemp["shippingDate"] = errors["shippingDate"];
    } else {
      errorsTemp["shippingDate"] = "";
    }

    if (replenishmentQuantity === "") {
      errorsTemp["replenishmentQuantity"] =
        "Replenishment Quantity cannot be empty";
    } else if (errorsTemp["replenishmentQuantity"]) {
      errorsTemp["replenishmentQuantity"] = errors["replenishmentQuantity"];
    } else {
      errorsTemp["replenishmentQuantity"] = "";
    }

    if (transMode === "") {
      errorsTemp["transMode"] = "Transport Mode cannot be empty";
    } else if (errorsTemp["transMode"]) {
      errorsTemp["transMode"] = errors["transMode"];
    } else {
      errorsTemp["transMode"] = "";
    }

    if (reason === "") {
      errorsTemp["reason"] = "Reason cannot be empty";
    } else if (errorsTemp["reason"]) {
      errorsTemp["reason"] = errors["reason"];
    } else {
      errorsTemp["reason"] = "";
    }

    // if (comments === "") {
    //   errorsTemp["comments"] = "Comments cannot be empty";
    // } else
    if (errorsTemp["comments"]) {
      errorsTemp["comments"] = errors["comments"];
    } else {
      errorsTemp["comments"] = "";
    }

    if (errorsTemp["stoType"]) {
      errorsTemp["stoType"] = errors["stoType"];
    } else {
      errorsTemp["stoType"] = "";
    }
    setErrors(errorsTemp);
    return (
      errorsTemp["skuCode"] === "" &&
      errorsTemp["sourceId"] === "" &&
      errorsTemp["destinationId"] === "" &&
      errorsTemp["channelId"] === "" &&
      errorsTemp["transMode"] === "" &&
      errorsTemp["shippingDate"] === "" &&
      errorsTemp["reason"] === "" &&
      // errorsTemp["comments"] === "" &&
      errorsTemp["replenishmentQuantity"] === "" &&
      errorsTemp["stoType"] === ""
    );
  };

  const handleSaveAddedSTO = () => {
    const isValid = validateUserInputs();
    if (isValid) {
      let addedSTO = {
        id: generateUniqueId(),
        skuCode: skuCode,
        channelId: channelId,
        sourceId: sourceId,
        destinationId: destinationId,
        shippingDate: shippingDate,
        replenishmentQuantity: replenishmentQuantity,
        stoType: stoType,
        transMode: transMode,
        //status: status === "" ? "pending" : status,
        reason: reason,
        comments: comments,
      };
      setTableData([...tableData, addedSTO]);
      setSkuCode("");
      setChannelId(channelIdOptions?.length === 1 ? channelIdOptions[0] : "");
      setDestinationId(
        destinationIdOptions?.length === 1 ? destinationIdOptions[0] : ""
      );
      setSourceId(sourceIdOptions?.length === 1 ? sourceIdOptions[0] : "");
      setShippingDate(formattedDate);
      setReplenishmentQuantity("");
      setStoType("");
      setTransMode(transModeOptions?.length === 1 ? transModeOptions[0] : "");
      //setStatus("");
      setReason(reasonOptions?.length === 1 ? reasonOptions[0] : "");
      setComments("");
    }
  };

  // useEffect(() => {
  //   setShippingDate(formattedDate);
  // }, []);

  const saveAllAddedSTO = async () => {
    setIsSaving(true);
    let requestBody = tableData.map((sto) => ({
      start_node: sto.sourceId,
      sku_code: sto.skuCode.split(" - ")[0],
      planned_replenishment_qty: sto.replenishmentQuantity,
      transport_mode_type: sto.transMode,
      channel_id: sto.channelId,
      end_node: sto.destinationId,
      date: sto.shippingDate,
      //status: sto.status,
      reason_code: sto.reason,
      comments: sto.comments,
    }));
    try {
      const response = await addSTO({
        planId,
        requestBody: requestBody,
      });
      toast.success(response.data.message);
      setIsSaving(false);
      navigate(`/snp/inventory/replenishment-plan/group-plan/${planId}`);
    } catch (error) {
      setIsSaving(false);
      toast.error("STO Creation Failed");
    }
  };

  const handleDeleteSto = (id) => {
    let filterData = tableData.filter((item) => item.id !== id);
    setTableData(filterData);
  };

  const generateUniqueId = () => Symbol("uniqueId");

  const actionAddStoArray = [
    (row1) => {
      const { row } = row1;
      return (
        <div>
          <button
            className="group-plan-action-button remove"
            style={{}}
            onClick={() => handleDeleteSto(row.id)}
          >
            <DeleteIcon color="#3D445C" height={12} />
          </button>
        </div>
      );
    },
  ];

  function DynamicRefsExample(
    numberOfRefs,
    componentArray,
    cell,
    row,
    centerAlign,
    multipleElements = false
  ) {
    const refsArray = useRef(
      Array.from({ length: numberOfRefs }, () => createRef())
    );
    return (
      <div className={!centerAlign ? "action-buttons" : "action-buttons-align"}>
        {Array.from({ length: numberOfRefs }, (_, index) => {
          const Comp = componentArray[index];

          return (
            <div
              id={index}
              key={index}
              ref={refsArray.current[index]}
              placeholder={`Ref ${index + 1}`}
              //   onClick={() => actionsArray[index](row)}
              style={{
                pointerEvents: row.original.isDisabled ? "none" : "auto",
                display: "flex",
                width: multipleElements ? "" : "100%",
              }}
            >
              <Comp row={row.original} />
            </div>
          );
        })}
      </div>
    );
  }

  return tableData ? (
    <div style={{ padding: "8px 12px 18px 12px" }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoadingOverlay}
      >
        <CircularIndefiniteSpinner />
      </Backdrop>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          alignSelf: "center",
          marginTop: "5px",
          marginBottom: "3px",
        }}
        className="process-header"
      >
        <div
          className="process-header-1"
          onClick={() =>
            navigate("/snp/inventory/replenishment-plan", {
              state: { planLevel: "group" },
            })
          }
        >
          Group Level
        </div>
        <div>
          <svg
            width="9"
            height="9"
            viewBox="0 0 9 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 1L4.5 4.5L1 8" stroke="#858585" strokeLinecap="round" />
            <path
              d="M4.5 1L8 4.5L4.5 8"
              stroke="#929292"
              strokeLinecap="round"
            />
          </svg>
        </div>
        <div
          className="process-header-2 add-sto-header"
          onClick={() =>
            navigate(`/snp/inventory/replenishment-plan/group-plan/${planId}`)
          }
        >{`Plan View : ${planId}`}</div>{" "}
        <div>
          <svg
            width="9"
            height="9"
            viewBox="0 0 9 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 1L4.5 4.5L1 8" stroke="#858585" strokeLinecap="round" />
            <path
              d="M4.5 1L8 4.5L4.5 8"
              stroke="#929292"
              strokeLinecap="round"
            />
          </svg>
        </div>
        <div className="process-header-2">Add STO</div>{" "}
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "12px",
          // justifyContent: "space-between",
          paddingTop: "12px",
        }}
      >
        <SelectInput
          label="SKU Code"
          placeholderValue="Select SKU Code"
          options={skuCodeOptions}
          inputValue={skuCode}
          handleInputValue={setSkuCode}
          required={true}
          errorMsg={errors.skuCode}
          setErrors={setErrors}
          name="skuCode"
        />
        <SelectInput
          label="Source ID"
          placeholderValue="Select Source ID"
          options={sourceIdOptions}
          //If only one option is present, it will be autopopulated
          inputValue={
            sourceIdOptions?.length === 1 ? sourceIdOptions[0] : sourceId
          }
          handleInputValue={setSourceId}
          required={true}
          errorMsg={errors.sourceId}
          setErrors={setErrors}
          name="sourceId"
        />
        <SelectInput
          label="Destination ID"
          placeholderValue="Select Destination ID"
          options={destinationIdOptions}
          //If only one option is present, it will be autopopulated
          inputValue={
            destinationIdOptions?.length === 1
              ? destinationIdOptions[0]
              : destinationId
          }
          handleInputValue={setDestinationId}
          required={true}
          errorMsg={errors.destinationId}
          setErrors={setErrors}
          name="destinationId"
        />
        <SelectInput
          label="Channel ID"
          placeholderValue="Select Channel"
          options={channelIdOptions}
          //If only one option is present, it will be autopopulated
          inputValue={
            channelIdOptions?.length === 1 ? channelIdOptions[0] : channelId
          }
          handleInputValue={setChannelId}
          required={true}
          setErrors={setErrors}
          errorMsg={errors.channelId}
          name="channelId"
        />
        <DateInput
          label="Shipping Date"
          placeholder="dd/mm/yyyy"
          inputValue={shippingDate}
          handleInputValue={setShippingDate}
          required={true}
          errorMsg={errors.shippingDate}
          setErrors={setErrors}
          name="shippingDate"
        />
        <TextInput
          placeholderValue="Enter Quantity"
          label="Replenishment Quantity"
          inputValue={replenishmentQuantity}
          handleInputValue={setReplenishmentQuantity}
          inputType="number"
          required={true}
          errorMsg={errors.replenishmentQuantity}
          name="replenishmentQuantity"
          setErrors={setErrors}
        />
        <SelectInput
          label="STO Type"
          placeholderValue="Select STO Type"
          options={stoTypeOptions}
          inputValue={stoType}
          handleInputValue={setStoType}
          errorMsg={errors.stoType}
          setErrors={setErrors}
          name="stoType"
        />
        <SelectInput
          label="Transport Mode"
          placeholderValue="Select Transport Mode"
          options={transModeOptions}
          //If only one option is present, it will be autopopulated
          inputValue={
            transModeOptions?.length === 1 ? transModeOptions[0] : transMode
          }
          handleInputValue={setTransMode}
          required={true}
          errorMsg={errors.transMode}
          setErrors={setErrors}
          name="transMode"
        />{" "}
        <SelectInput
          placeholderValue="Enter Reason"
          label="Reason"
          options={reasonOptions}
          //If only one option is present, it will be autopopulated
          inputValue={reasonOptions?.length === 1 ? reasonOptions[0] : reason}
          handleInputValue={setReason}
          required={true}
          errorMsg={errors.reason}
          setErrors={setErrors}
          name="reason"
        />
        <TextInput
          placeholderValue="Enter Comments"
          label="Comments"
          inputValue={comments}
          handleInputValue={setComments}
          inputType="text"
          required={false}
          errorMsg={errors.comments}
          name="comments"
          setErrors={setErrors}
        />
      </div>
      <div className="add-sto-buttons">
        {/* <select
          className="add-sto-select-status"
          onChange={(e) => setStatus(e.target.value)}
          value={status}
        >
          <option value="">Select Status</option>
          <option value="accepted">Accepted</option>
          <option value="rejected">Rejected</option>
          <option value="pending">Pending</option>
        </select> */}
        <button className="add-sto-save" onClick={handleSaveAddedSTO}>
          Save & Add Another
        </button>
      </div>
      <hr className="add-sto-hor-line" />{" "}
      {tableData && (
        <div
          className="snp-table-container"
          // style={{ width: `calc( 100% - 20px)`, marginLeft: "10px" }}
        >
          <MuiTable
            columns={[
              {
                accessorKey: "skuCode",
                header: "SKU Code",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "sourceId",
                header: "Source ID",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "destinationId",
                header: "Destination ID",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "channelId",
                header: "Channel ID",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "shippingDate",
                header: "Shipping Date",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "stoType",
                header: "Stock Type",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "replenishmentQuantity",
                header: "Replenishment Quantity",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              // {
              //   accessorKey: "status",
              //   header: "Status",
              //   enableColumnOrdering: false,
              //   enableEditing: false,
              //   enableSorting: true,
              //   Cell: ({ cell, row, column }) => {
              //     return (
              //       <div
              //         className={
              //           row.original.isDisabled
              //             ? "disabled-cell"
              //             : "active-cell"
              //         }
              //       >
              //         {cell.getValue()}
              //       </div>
              //     );
              //   },
              // },
              {
                accessorKey: "transMode",
                header: "Transport Mode",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "reason",
                header: "Reason",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "comments",
                header: "Comments",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "action",
                header: "Action",
                enableColumnActions: false,
                enableSorting: false,
                muiTableBodyCellEditTextFieldProps: {
                  select: true, //change to select for a dropdown
                },
                size: 100,
                Cell: ({ cell, row }) => {
                  const centerAlign = true;
                  const multipleElements = true;
                  return DynamicRefsExample(
                    1,
                    actionAddStoArray,
                    cell,
                    row,
                    centerAlign,
                    multipleElements
                  );
                },
              },
            ]}
            td={tableData}
            shouldShowAddBtn={false}
            showToolbarInternalActions={false}
            showPagination={false}
            // renderTopToolbarCustomActions={renderPreprocessesHeading}
          />
        </div>
      )}
      <div className="add-sto-save-cancel-buttons">
        <button
          className="add-sto-cancel-button"
          onClick={() => {
            navigate(`/snp/inventory/replenishment-plan/group-plan/${planId}`);
          }}
        >
          Cancel
        </button>
        <button
          className={
            tableData.length === 0
              ? "add-sto-save-button add-sto-save-button-disabled"
              : "add-sto-save-button"
          }
          onClick={saveAllAddedSTO}
          disabled={tableData.length === 0}
        >
          {isSaving ? "Saving.." : "Save"}
        </button>
      </div>
    </div>
  ) : (
    "loading"
  );
}

export default CreateSTO;
