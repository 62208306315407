import { useState, useEffect } from "react";
import MuiTable from "../../components/muiTable/muiTable";
import "../../components/muiTable/muiTable.scss";
// import { salesOrderDetailsCols } from "./columns";
// import { salesOrderDetailsData } from "./data";
const rawDetailsCols = [
  {
    accessorKey: "plant",
    header: "Plant",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    size: 120,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "item_id",
    header: "Item ID",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    size: 120,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "item_name",
    header: "Item Name",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    size: 120,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "reqd_qty",
    header: "Required Quantity",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    size: 120,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "dos",
    header: "DOS",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    size: 120,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "units_in_hand",
    header: "Units In Hand",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    size: 120,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
];
const rawDetailsData = [
  {
    plant: "Plant A",
    item_id: "RM - 1201",
    item_name: "PVC-PAllet-W",
    reqd_qty: "12312",
    dos: "3",
    units_in_hand: "5368",
  },
  {
    plant: "Plant B",
    item_id: "RM - 1231",
    item_name: "PVC-PAllet-W",
    reqd_qty: "12312",
    dos: "3",
    units_in_hand: "5368",
  },
  {
    plant: "Plant C",
    item_id: "RM - 1211",
    item_name: "PVC-PAllet-W",
    reqd_qty: "12312",
    dos: "3",
    units_in_hand: "5368",
  },
  {
    plant: "Plant A",
    item_id: "RM - 1871",
    item_name: "PVC-PAllet-W",
    reqd_qty: "12312",
    dos: "3",
    units_in_hand: "5368",
  },
  {
    plant: "Plant A",
    item_id: "RM - 1921",
    item_name: "PVC-PAllet-Y",
    reqd_qty: "12312",
    dos: "3",
    units_in_hand: "5368",
  },
  {
    plant: "Plant A",
    item_id: "RM - 1921",
    item_name: "PVC-PAllet-Y",
    reqd_qty: "12312",
    dos: "3",
    units_in_hand: "5368",
  },
  {
    plant: "Plant A",
    item_id: "RM - 1921",
    item_name: "PVC-PAllet-Y",
    reqd_qty: "12312",
    dos: "3",
    units_in_hand: "5368",
  },
  {
    plant: "Plant A",
    item_id: "RM - 1921",
    item_name: "PVC-PAllet-Y",
    reqd_qty: "12312",
    dos: "3",
    units_in_hand: "5368",
  },
  {
    plant: "Plant A",
    item_id: "RM - 1921",
    item_name: "PVC-PAllet-Y",
    reqd_qty: "12312",
    dos: "3",
    units_in_hand: "5368",
  },
];

const finishedDetailsData = [
  {
    plant: "Plant A",
    item_id: "FG - 1201",
    item_name: "PVC-PAllet-W",
    reqd_qty: "12312",
    dos: "3",
    units_in_hand: "5368",
  },
  {
    plant: "Plant B",
    item_id: "FG - 1231",
    item_name: "PVC-PAllet-W",
    reqd_qty: "12312",
    dos: "3",
    units_in_hand: "5368",
  },
  {
    plant: "Plant C",
    item_id: "FG - 1211",
    item_name: "PVC-PAllet-W",
    reqd_qty: "12312",
    dos: "3",
    units_in_hand: "5368",
  },
  {
    plant: "Plant A",
    item_id: "FG - 1871",
    item_name: "PVC-PAllet-W",
    reqd_qty: "12312",
    dos: "3",
    units_in_hand: "5368",
  },
  {
    plant: "Plant A",
    item_id: "FG - 1921",
    item_name: "PVC-PAllet-Y",
    reqd_qty: "12312",
    dos: "3",
    units_in_hand: "5368",
  },
  {
    plant: "Plant A",
    item_id: "FG - 1921",
    item_name: "PVC-PAllet-Y",
    reqd_qty: "12312",
    dos: "3",
    units_in_hand: "5368",
  },
  {
    plant: "Plant A",
    item_id: "FG - 1921",
    item_name: "PVC-PAllet-Y",
    reqd_qty: "12312",
    dos: "3",
    units_in_hand: "5368",
  },
  {
    plant: "Plant A",
    item_id: "FG - 1921",
    item_name: "PVC-PAllet-Y",
    reqd_qty: "12312",
    dos: "3",
    units_in_hand: "5368",
  },
  {
    plant: "Plant A",
    item_id: "RM - 1921",
    item_name: "PVC-PAllet-Y",
    reqd_qty: "12312",
    dos: "3",
    units_in_hand: "5368",
  },
];
const muiTableContainerProps = { sx: { maxHeight: "350px" } };

function StockLevelDetails() {
  const [tableData, setTableData] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);

  const [type, setType] = useState("raw");
  //   const { data, isLoading, isSuccess } = useStockLevelQuery(undefined, {
  //     refetchOnMountOrArgChange: true,
  //     force: true, // This will bypass the cache
  //   });
  useEffect(() => {
    // setTableData(data ? data : []);
    type === "raw"
      ? setTableData(rawDetailsData)
      : setTableData(finishedDetailsData);
  }, [type]);
  const cycleIdParam = "";
  return (
    <div
      className="snp-table-container mini-table"
      style={{
        overflow: "hidden",
        width: `calc( 100% - 20px)`,
        marginLeft: "10px",
      }}
    >
      <div className="level-btns" style={{ marginBottom: "10px" }}>
        <button
          onClick={() => setType("raw")}
          className={
            type === "raw" ? "level-button active-btn" : "level-button"
          }
        >
          Raw Material
        </button>
        <button
          onClick={() => {
            setType("finished");
          }}
          className={
            type === "finished" ? "level-button active-btn" : "level-button"
          }
        >
          Finished Goods
        </button>
      </div>
      <MuiTable
        columns={rawDetailsCols}
        td={tableData}
        shouldShowAddBtn={false}
        enableTopToolbar={false}
        enableBottomToolbar={false}
        muiTableContainerProps={muiTableContainerProps}
        // isLoading={isLoading}
      />
    </div>
  );
}

export default StockLevelDetails;
