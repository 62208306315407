/* eslint-disable no-unused-vars */
import { useState, useEffect, useRef, createRef } from "react";
import MuiTable from "../components/muiTable/muiTable";
import { useDispatch, useSelector } from "react-redux";
// import { useJobHistoryQuery } from "../features/api/schedularSlice";
import { useNavigate, useLocation } from "react-router-dom";
import { TableLoaderSpinner } from "../components/tableUtils/tableUtils";
import { TextField, Autocomplete, Checkbox } from "@mui/material";
import {
  NormalCell,
  BreadcrumbMaker,
} from "../components/tableUtils/tableUtils";
import "../components/tableUtils/tableUtils.css";
import { BreadcrumbTemp } from "../components/job-schedular/jsConfigScreen";
import { useJobsHistoryQuery } from "../features/api/schedularSlice";
import { formatISODateToHumanReadable } from "../utils/utils";

const transformTableData = (arr) => {
  return arr.map((item) => ({
    ...item,
    group_config: item?.group_config?.name,
    group_rule: item?.group_rule?.rule_set_name,
    datetime: formatISODateToHumanReadable(item.datetime),
  }));
};

function JobSchedulerHistory() {
  const baseURL = process.env.REACT_APP_SCAI_API_ENDPOINT;
  const location = useLocation();
  const path = location.pathname;
  let idArr = path.split("/");
  const navigate = useNavigate();
  let editId = Number(idArr[idArr.length - 1]);
  const [isLoading, setIsLoaading] = useState(true);
  const mode = useSelector((state) => state.jobScheduler.jobSchedulerMode);
  const [tableData, setTableData] = useState([]);
  const [stateColumnValues, setStateColumnValues] = useState([]);
  const {
    data: jobHistoryData,
    isFetching: isFetchingJobHistory,
    isError: isErrorJobHistory,
  } = useJobsHistoryQuery({
    group_schedule: editId,
  });

  useEffect(() => {
    if (jobHistoryData) {
      setTableData(transformTableData(jobHistoryData) || []);
    }
  }, [jobHistoryData]);

  const centeredTextStyle = {
    textAlign: "center",
    color: "#3D445C",
    fontSize: "13px",
    fontFamily: "Inter",
    fontWeight: 400,
    wordWrap: "break-word",
  };

  const horizontalLineStyle = {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  };

  const renderJobSchedulerHistoryHeader = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          alignSelf: "center",
        }}
        className="process-header"
      >
        <div
          className="process-header-1"
          onClick={() =>
            navigate(`/${idArr[1]}/${idArr[2]}/${idArr[3]}/job-scheduler`)
          }
        >
          Job Scheduler
        </div>
        <div className="flex">
          <BreadcrumbTemp />
          <BreadcrumbTemp />
        </div>
        <div className="process-header-2">History</div>
        <div className="flex">
          <BreadcrumbTemp />
          <BreadcrumbTemp />
        </div>
        <div className="process-header-2">{editId}</div>
      </div>
    );
  };

  return (
    <>
      <div
        className="snp-table-container"
        style={{ width: `calc( 100% - 20px)`, marginLeft: "10px" }}
      >
        <MuiTable
          columns={[
            {
              accessorKey: "group_config",
              header: "Group",
              enableColumnOrdering: false,
              enableEditing: false,
              enableSorting: true,
              size: 140,
              Cell: ({ cell, row }) => {
                return NormalCell(cell, row);
              },
            },
            {
              accessorKey: "group_rule",
              header: "Configuration",
              enableColumnOrdering: false,
              enableEditing: false,
              enableSorting: true,
              size: 140,
              Cell: ({ cell, row }) => {
                return NormalCell(cell, row);
              },
            },
            {
              accessorKey: "user_email",
              header: "User Email",
              enableColumnOrdering: false,
              enableEditing: false,
              enableSorting: true,
              size: 140,
              Cell: ({ cell, row }) => {
                return NormalCell(cell, row);
              },
            },
            {
              accessorKey: "plan_id",
              header: "Plan ID",
              enableColumnOrdering: false,
              enableEditing: false,
              enableSorting: true,
              size: 140,
              Cell: ({ cell, row }) => {
                return NormalCell(cell, row);
              },
            },
            {
              accessorKey: "datetime",
              header: "Date Time",
              enableColumnOrdering: false,
              enableEditing: false,
              enableSorting: true,
              size: 140,
              Cell: ({ cell, row }) => {
                return NormalCell(cell, row);
              },
            },
            {
              accessorKey: "job_run_type",
              header: "Job Type",
              enableColumnOrdering: false,
              enableColumnActions: true,
              enableEditing: false,
              enableSorting: true,

              size: 140,
              Cell: ({ cell, row }) => {
                return NormalCell(cell, row);
              },
            },
          ]}
          isLoading={isFetchingJobHistory}
          td={tableData}
          renderTopToolbarCustomActions={renderJobSchedulerHistoryHeader}
          createPath="/snp/inventory/management/job-scheduler-create"
        />
      </div>
    </>
  );
}

export default JobSchedulerHistory;
