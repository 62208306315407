import { useState, useEffect, useRef, createRef, Fragment } from "react";
import MuiTable from "../../components/muiTable/muiTable";
import { useNavigate, useLocation } from "react-router-dom";
import KPICard from "../../components/kpi_cards/KPICard";
import UploadModal from "../../components/modal/UploadModal";
import {
  BreadcrumbMaker2,
  NormalCell,
  TableLoaderSpinner,
  transformStatus,
} from "../../components/tableUtils/tableUtils";
import "./ProductionPlan.scss";
import { usePpAppOrgPlanViewQuery } from "../../features/api/prodPlanSlice";
import { formatDateToDateMonYear } from "../../utils/utils";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../utils/components";
import { Typography } from "@mui/material";

const transformTableData = (arr) => {
  return arr.map((item) => ({
    wpoId: item.wpo_id,
    skuCode: item.sku_code,
    skuName: item.product,
    productionPlantName: item.production_plant_name,
    productionPlantCode: item.production_plant_code,
    lotSize: item.lot_size,
    capacity: item.capacity,
    timePeriod: item.time_period,
    totalAllocationSystem: item.total_allocation_system,
    totalAllocationUser: item.total_allocation_user,
    week1m1: item.week_1_m1,
    week2m1: item.week_2_m1,
    week3m1: item.week_3_m1,
    week4m1: item.week_4_m1,
    prebuildQty: item.prebuild_qty,
    shortSupplyQty: item.short_supply_qty,
    surplusQty: item.surplus_qty,
    preBuildAllocation: item.pre_build_allocation,
    reasonCode: item.reason_code === null ? "-" : item.reason_code,
    createdBy: item.created_by,
    createdAt: formatDateToDateMonYear(item.created_at),
    status: transformStatus(item.status),
    comments: item.comments === null ? "-" : item.comments,
    updatedDate: item.updated_at,
    updatedBy: "-",
    version: item.version,
  }));
};

function PlanViewAPPOrg() {
  const location = useLocation();
  const path = location.pathname;
  let idArr = path.split("/");
  let cycleId = idArr[idArr.length - 1];
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [level, setLevel] = useState("org");
  const [openSendModal, setOpenSendModal] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const {
    data: ppAppOrgPlanViewData,
    isLoading: isLoadingPpAppOrgPlanViewData,
    isSuccess: isSuccessPpAppOrgPlanViewData,
  } = usePpAppOrgPlanViewQuery(cycleId, {
    refetchOnMountOrArgChange: true,
    force: true, // This will bypass the cache
  });

  useEffect(() => {
    if (ppAppOrgPlanViewData && ppAppOrgPlanViewData.results) {
      setTableData(
        ppAppOrgPlanViewData && ppAppOrgPlanViewData.results
          ? transformTableData(ppAppOrgPlanViewData.results)
          : []
      );
    }
  }, [ppAppOrgPlanViewData]);

  const renderLeftTableFooterGroupLevel = () => {
    return (
      <div className="table-left-bottom">
        <div className="table-left-bottom-footer">
          <p>Ongoing Cycle : 16-06-23 12:00 AM -11:59 PM</p>
          <p>Upcoming Cycle : 16-06-23 12:00 AM -11:59 PM</p>
        </div>
      </div>
    );
  };

  return tableData ? (
    <div style={{ width: `calc( 100% - 20px)`, margin: 10 }}>
      <div style={{ marginBottom: "10px" }}>
        {BreadcrumbMaker2([
          {
            text: "APP",
            link: "/snp/production/production-plan",
            level: "org",
            tab: "APP",
          },
          {
            text: "ORG Level",
            link: "/snp/production/production-plan",
            level: "org",
            tab: "APP",
          },
          { text: `Plan View: ${cycleId.replaceAll("%20", " ")}`, link: null },
        ])}
      </div>
      <Fragment>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            expanded={expanded === "panel1"}
            fill="white"
            hoverColor="#e94f1c"
            backgroundColor="#494949"
            expandedBgColor="#e94f1b"
          >
            <Typography
              sx={{
                width: "33%",
                flexShrink: 0,
                fontSize: "12px",
                color: "white",
              }}
            >
              KPI&apos;s
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div
              style={{
                margin: "auto",
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              <KPICard
                value="$10536"
                volume="25039"
                heading="Total Production Requirements"
              />
              <KPICard
                value="$10536"
                volume="22039"
                heading="Total Production Allocation"
              />{" "}
              <KPICard
                value="$320976"
                volume="12356"
                heading="Production Work Order"
              />
              <KPICard
                value="$231456"
                volume="178000"
                heading="Capacity Utilization"
              />
              <KPICard value="$231456" volume="178000" heading="Deviations" />
            </div>
          </AccordionDetails>
        </Accordion>

        <div className="snp-table-container" style={{ marginTop: "-5px" }}>
          <MuiTable
            columns={[
              {
                accessorKey: "wpoId",
                header: "WO - No",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                size: 180,
                Cell: ({ cell, row }) => {
                  return NormalCell(cell, row);
                },
              },
              {
                accessorKey: "skuCode",
                header: "SKU Code",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                size: 140,
                Cell: ({ cell, row }) => {
                  return NormalCell(cell, row);
                },
              },
              {
                accessorKey: "skuName",
                header: "SKU Name",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                size: 210,
                Cell: ({ cell, row }) => {
                  return NormalCell(cell, row);
                },
              },
              {
                accessorKey: "productionPlantCode",
                header: "Production Plant Code",
                enableColumnActions: true,
                enableSorting: true,
                muiTableBodyCellEditTextFieldProps: {
                  select: true, //change to select for a dropdown
                },
                size: 210,
                Cell: ({ cell, row }) => {
                  return NormalCell(cell, row);
                },
              },
              {
                accessorKey: "productionPlantName",
                header: "Production Plant Name",
                enableColumnOrdering: false,
                enableColumnActions: true,
                enableEditing: false,
                enableSorting: true,
                size: 210,
                Cell: ({ cell, row }) => {
                  return NormalCell(cell, row);
                },
              },
              // {
              //   accessorKey: "netSalesRate",
              //   header: "Net Sales Rate",
              //   enableColumnOrdering: false,
              //   enableColumnActions: true,
              //   enableEditing: false,
              //   enableSorting: true,
              //   size: 210,
              //   Cell: ({ cell, row }) => {
              //     return NormalCell(cell, row);
              //   },
              // },
              // {
              //   accessorKey: "itemCost",
              //   header: "Item Cost",
              //   enableColumnActions: true,
              //   enableSorting: true,
              //   muiTableBodyCellEditTextFieldProps: {
              //     select: true, //change to select for a dropdown
              //   },
              //   size: 210,
              //   Cell: ({ cell, row }) => {
              //     return NormalCell(cell, row);
              //   },
              // },
              {
                accessorKey: "lotSize",
                header: "Lot Size",
                enableColumnActions: true,
                enableSorting: true,
                muiTableBodyCellEditTextFieldProps: {
                  select: true, //change to select for a dropdown
                },
                size: 210,
                Cell: ({ cell, row }) => {
                  return NormalCell(cell, row);
                },
              },
              {
                accessorKey: "capacity",
                header: "Capacity",
                enableColumnActions: true,
                enableSorting: true,
                muiTableBodyCellEditTextFieldProps: {
                  select: true, //change to select for a dropdown
                },
                size: 210,
                Cell: ({ cell, row }) => {
                  return NormalCell(cell, row);
                },
              },
              {
                accessorKey: "week1m1",
                header: "Week-1(M1)",
                enableColumnActions: true,
                muiTableBodyCellEditTextFieldProps: {
                  select: true, //change to select for a dropdown
                },
                size: 210,
                Cell: ({ cell, row }) => {
                  return NormalCell(cell, row);
                },
              },
              {
                accessorKey: "week2m1",
                header: "Week-2(M1)",
                enableColumnActions: true,
                muiTableBodyCellEditTextFieldProps: {
                  select: true, //change to select for a dropdown
                },
                size: 210,
                Cell: ({ cell, row }) => {
                  return NormalCell(cell, row);
                },
              },
              {
                accessorKey: "week3m1",
                header: "Week-3(M1)",
                enableColumnActions: true,
                muiTableBodyCellEditTextFieldProps: {
                  select: true, //change to select for a dropdown
                },
                size: 210,
                Cell: ({ cell, row }) => {
                  return NormalCell(cell, row);
                },
              },
              {
                accessorKey: "week4m1",
                header: "Week-4(M1)",
                enableColumnActions: true,
                muiTableBodyCellEditTextFieldProps: {
                  select: true, //change to select for a dropdown
                },
                size: 210,
                Cell: ({ cell, row }) => {
                  return NormalCell(cell, row);
                },
              },
              // {
              //   accessorKey: "totalAllocationM1",
              //   header: "Total Allocation M1(January)",
              //   enableColumnOrdering: false,
              //   enableEditing: false,
              //   enableSorting: true,
              //   size: 210,
              //   Cell: ({ cell, row }) => {
              //     return NormalCell(cell, row);
              //   },
              // },
              // {
              //   accessorKey: "totalAllocationM2",
              //   header: "Total Allocation M2(February)",
              //   enableColumnOrdering: false,
              //   enableEditing: false,
              //   enableSorting: true,
              //   size: 210,
              //   Cell: ({ cell, row }) => {
              //     return NormalCell(cell, row);
              //   },
              // },
              // {
              //   accessorKey: "totalAllocationM3",
              //   header: "Total Allocation M3(March)",
              //   enableColumnOrdering: false,
              //   enableEditing: false,
              //   enableSorting: true,
              //   size: 210,
              //   Cell: ({ cell, row }) => {
              //     return NormalCell(cell, row);
              //   },
              // },
              // {
              //   accessorKey: "totalAllocationM4",
              //   header: "Total Allocation M4(April)",
              //   enableColumnOrdering: false,
              //   enableEditing: false,
              //   enableSorting: true,
              //   size: 210,
              //   Cell: ({ cell, row }) => {
              //     return NormalCell(cell, row);
              //   },
              // },
              // {
              //   accessorKey: "totalAllocationM5",
              //   header: "Total Allocation M5(May)",
              //   enableColumnOrdering: false,
              //   enableEditing: false,
              //   enableSorting: true,
              //   size: 210,
              //   Cell: ({ cell, row }) => {
              //     return NormalCell(cell, row);
              //   },
              // },
              // {
              //   accessorKey: "totalAllocationM6",
              //   header: "Total Allocation M6(June)",
              //   enableColumnOrdering: false,
              //   enableEditing: false,
              //   enableSorting: true,
              //   size: 210,
              //   Cell: ({ cell, row }) => {
              //     return NormalCell(cell, row);
              //   },
              // },
              // {
              //   accessorKey: "totalAllocationM7",
              //   header: "Total Allocation M7(July)",
              //   enableColumnOrdering: false,
              //   enableEditing: false,
              //   enableSorting: true,
              //   size: 210,
              //   Cell: ({ cell, row }) => {
              //     return NormalCell(cell, row);
              //   },
              // },
              // {
              //   accessorKey: "totalAllocationM8",
              //   header: "Total Allocation M8(August)",
              //   enableColumnOrdering: false,
              //   enableEditing: false,
              //   enableSorting: true,
              //   size: 210,
              //   Cell: ({ cell, row }) => {
              //     return NormalCell(cell, row);
              //   },
              // },
              // {
              //   accessorKey: "totalAllocationM9",
              //   header: "Total Allocation M9(September)",
              //   enableColumnOrdering: false,
              //   enableEditing: false,
              //   enableSorting: true,
              //   size: 210,
              //   Cell: ({ cell, row }) => {
              //     return NormalCell(cell, row);
              //   },
              // },
              // {
              //   accessorKey: "totalAllocationM10",
              //   header: "Total Allocation M10(October)",
              //   enableColumnOrdering: false,
              //   enableEditing: false,
              //   enableSorting: true,
              //   size: 210,
              //   Cell: ({ cell, row }) => {
              //     return NormalCell(cell, row);
              //   },
              // },
              // {
              //   accessorKey: "totalAllocationM11",
              //   header: "Total Allocation M11(November)",
              //   enableColumnOrdering: false,
              //   enableEditing: false,
              //   enableSorting: true,
              //   size: 210,
              //   Cell: ({ cell, row }) => {
              //     return NormalCell(cell, row);
              //   },
              // },
              // {
              //   accessorKey: "totalAllocationM12",
              //   header: "Total Allocation M12(December)",
              //   enableColumnOrdering: false,
              //   enableEditing: false,
              //   enableSorting: true,
              //   size: 210,
              //   Cell: ({ cell, row }) => {
              //     return NormalCell(cell, row);
              //   },
              // },
              {
                accessorKey: "totalAllocationSystem",
                header: "Total Allocation System",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                size: 210,
                Cell: ({ cell, row }) => {
                  return NormalCell(cell, row);
                },
              },
              {
                accessorKey: "totalAllocationUser",
                header: "Total Allocation User",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                size: 210,
                Cell: ({ cell, row }) => {
                  return NormalCell(cell, row);
                },
              },
              // {
              //   accessorKey: "prebuildQty",
              //   header: "Pre Build Quantity",
              //   enableColumnOrdering: false,
              //   enableEditing: false,
              //   enableSorting: true,
              //   size: 210,
              //   Cell: ({ cell, row }) => {
              //     return NormalCell(cell, row);
              //   },
              // },
              {
                accessorKey: "preBuildAllocation",
                header: "Pre Build Allocation",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                size: 210,
                Cell: ({ cell, row }) => {
                  return NormalCell(cell, row);
                },
              },
              {
                accessorKey: "shortSupplyQty",
                header: "Short Supply Quantity",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                size: 210,
                Cell: ({ cell, row }) => {
                  return NormalCell(cell, row);
                },
              },
              {
                accessorKey: "reasonCode",
                header: "Reason Code",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                size: 210,
                Cell: ({ cell, row }) => {
                  return NormalCell(cell, row);
                },
              },
              {
                accessorKey: "comments",
                header: "Comments",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                size: 210,
                Cell: ({ cell, row }) => {
                  return NormalCell(cell, row);
                },
              },
              // {
              //   accessorKey: "shortSupplyM1",
              //   header: "Short Supply M1",
              //   enableColumnOrdering: false,
              //   enableEditing: false,
              //   enableSorting: true,
              //   size: 210,
              //   Cell: ({ cell, row }) => {
              //     return NormalCell(cell, row);
              //   },
              // },
              // {
              //   accessorKey: "shortSupplyM2",
              //   header: "Short Supply M2",
              //   enableColumnOrdering: false,
              //   enableEditing: false,
              //   enableSorting: true,
              //   size: 210,
              //   Cell: ({ cell, row }) => {
              //     return NormalCell(cell, row);
              //   },
              // },
              // {
              //   accessorKey: "shortSupplyM3",
              //   header: "Short Supply M3",
              //   enableColumnOrdering: false,
              //   enableEditing: false,
              //   enableSorting: true,
              //   size: 210,
              //   Cell: ({ cell, row }) => {
              //     return NormalCell(cell, row);
              //   },
              // },
              // {
              //   accessorKey: "shortSupplyM4",
              //   header: "Short Supply M4",
              //   enableColumnOrdering: false,
              //   enableEditing: false,
              //   enableSorting: true,
              //   size: 210,
              //   Cell: ({ cell, row }) => {
              //     return NormalCell(cell, row);
              //   },
              // },
              // {
              //   accessorKey: "shortSupplyM5",
              //   header: "Short Supply M5",
              //   enableColumnOrdering: false,
              //   enableEditing: false,
              //   enableSorting: true,
              //   size: 210,
              //   Cell: ({ cell, row }) => {
              //     return NormalCell(cell, row);
              //   },
              // },
              // {
              //   accessorKey: "shortSupplyM6",
              //   header: "Short Supply M6",
              //   enableColumnOrdering: false,
              //   enableEditing: false,
              //   enableSorting: true,
              //   size: 210,
              //   Cell: ({ cell, row }) => {
              //     return NormalCell(cell, row);
              //   },
              // },
              // {
              //   accessorKey: "shortSupplyM7",
              //   header: "Short Supply M7",
              //   enableColumnOrdering: false,
              //   enableEditing: false,
              //   enableSorting: true,
              //   size: 210,
              //   Cell: ({ cell, row }) => {
              //     return NormalCell(cell, row);
              //   },
              // },
              // {
              //   accessorKey: "shortSupplyM8",
              //   header: "Short Supply M8",
              //   enableColumnOrdering: false,
              //   enableEditing: false,
              //   enableSorting: true,
              //   size: 210,
              //   Cell: ({ cell, row }) => {
              //     return NormalCell(cell, row);
              //   },
              // },
              // {
              //   accessorKey: "shortSupplyM9",
              //   header: "Short Supply M9",
              //   enableColumnOrdering: false,
              //   enableEditing: false,
              //   enableSorting: true,
              //   size: 210,
              //   Cell: ({ cell, row }) => {
              //     return NormalCell(cell, row);
              //   },
              // },
              // {
              //   accessorKey: "shortSupplyM10",
              //   header: "Short Supply M10",
              //   enableColumnOrdering: false,
              //   enableEditing: false,
              //   enableSorting: true,
              //   size: 210,
              //   Cell: ({ cell, row }) => {
              //     return NormalCell(cell, row);
              //   },
              // },
              // {
              //   accessorKey: "shortSupplyM11",
              //   header: "Short Supply M11",
              //   enableColumnOrdering: false,
              //   enableEditing: false,
              //   enableSorting: true,
              //   size: 210,
              //   Cell: ({ cell, row }) => {
              //     return NormalCell(cell, row);
              //   },
              // },
              // {
              //   accessorKey: "shortSupplyM12",
              //   header: "Short Supply M12",
              //   enableColumnOrdering: false,
              //   enableEditing: false,
              //   enableSorting: true,
              //   size: 210,
              //   Cell: ({ cell, row }) => {
              //     return NormalCell(cell, row);
              //   },
              // },
              {
                accessorKey: "version",
                header: "Version",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                size: 210,
                Cell: ({ cell, row }) => {
                  return NormalCell(cell, row);
                },
              },
              {
                accessorKey: "status",
                header: "Status",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                size: 210,
                Cell: ({ cell, row }) => {
                  return NormalCell(cell, row);
                },
              },
              {
                accessorKey: "updatedBy",
                header: "Updated By",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                size: 210,
                Cell: ({ cell, row }) => {
                  return NormalCell(cell, row);
                },
              },
              {
                accessorKey: "updatedDate",
                header: "Updated Date",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                size: 210,
                Cell: ({ cell, row }) => {
                  return NormalCell(cell, row);
                },
              },
              {
                accessorKey: "createdBy",
                header: "Created By",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                size: 210,
                Cell: ({ cell, row }) => {
                  return NormalCell(cell, row);
                },
              },
              {
                accessorKey: "createdAt",
                header: "Created Date",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                size: 210,
                Cell: ({ cell, row }) => {
                  return NormalCell(cell, row);
                },
              },
            ]}
            td={tableData}
            level={level}
            setLevel={setLevel}
            renderBottomToolbarCustomActions={renderLeftTableFooterGroupLevel}
            shouldShowAddBtn={false}
            enableRowSelection={false}
            isLoading={isLoadingPpAppOrgPlanViewData}
            //   viewPath="/process-view"
          />
        </div>
      </Fragment>
    </div>
  ) : (
    <TableLoaderSpinner />
  );
}

export default PlanViewAPPOrg;
