import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  jobSchedulerMode: "create", // Your initial value here
  jobSchedulerProps: null,
};

const jobSchedulerSlice = createSlice({
  name: "jobSchedulerSlice",
  initialState,
  reducers: {
    setJobSchedulerMode: (state, action) => {
      state.jobSchedulerMode = action.payload;
    },
    setJobSchedulerProps: (state, action) => {
      state.jobSchedulerProps = action.payload;
    },
  },
});

export const { setJobSchedulerMode, setJobSchedulerProps } =
  jobSchedulerSlice.actions;
export default jobSchedulerSlice.reducer;
