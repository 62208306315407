import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  groupLevelPlanDetails: {},
};

const groupPlanViewSlice = createSlice({
  name: "groupPlanViewSlice",
  initialState,
  reducers: {
    setGroupLevelPlanDetails: (state, action) => {
      state.groupLevelPlanDetails = action.payload;
    },
  },
});

export const { setGroupLevelPlanDetails } = groupPlanViewSlice.actions;
export default groupPlanViewSlice.reducer;
