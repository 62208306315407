import { useRef, createRef } from "react";
import { DataTableColumnHeader } from "@/components/tanstack-table/data-table-column-header";
import { Skeleton } from "@/components/ui/skeleton";

export const baseOrgLevelCols = ({ navigate }) => {
  let columns = [
    {
      accessorKey: "cycleId",
      header: ({ column, header }) => (
        <div className="py-[10px] lg:py-[8px]">
          <DataTableColumnHeader
            column={column}
            title={"MRP ID"}
            header={header}
          />
        </div>
      ),
      enableHiding: true,
      enableSorting: true,
      size: 300,
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
      cell: ({ row, cell }) => {
        const widthValue = cell.column.getSize();
        return (
          <div className="flex items-center justify-center py-1 lg:py-2">
            <div
              className="antialiased truncate text-center"
              style={{ width: widthValue, minWidth: "150px" }}
            >
              {row.getValue("cycleId")}
            </div>
          </div>
        );
      },
    },
    {
      accessorKey: "orgCyclePeriod",
      header: ({ column, header }) => (
        <DataTableColumnHeader
          column={column}
          title={"Cycle Period"}
          header={header}
        />
      ),
      enableHiding: true,
      enableSorting: true,
      size: 350,
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
      cell: ({ row, cell }) => {
        const widthValue = cell.column.getSize();
        return (
          <div className="flex items-center justify-center">
            <div
              className="antialiased truncate text-center"
              style={{ width: widthValue, minWidth: "150px" }}
            >
              {row.getValue("orgCyclePeriod")}
            </div>
          </div>
        );
      },
    },
    {
      accessorKey: "orgNextCyclePeriod",
      header: ({ column, header }) => (
        <DataTableColumnHeader
          column={column}
          title={"Next Cycle Period"}
          header={header}
        />
      ),
      enableHiding: true,
      enableSorting: true,
      size: 350,
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
      cell: ({ row, cell }) => {
        const widthValue = cell.column.getSize();
        return (
          <div className="flex items-center justify-center">
            <div
              className="antialiased truncate text-center"
              style={{ width: widthValue, minWidth: "150px" }}
            >
              {row.getValue("orgNextCyclePeriod")}
            </div>
          </div>
        );
      },
    },
    {
      accessorKey: "groupLevelAggr",
      header: ({ column, header }) => (
        <DataTableColumnHeader
          column={column}
          title={"Group Level Aggregation"}
          header={header}
        />
      ),
      enableHiding: true,
      enableSorting: true,
      size: 250,
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
      cell: ({ row, cell }) => {
        const widthValue = cell.column.getSize();
        return (
          <div className="flex items-center justify-center">
            <div
              className="antialiased truncate text-center"
              style={{ width: widthValue, minWidth: "150px" }}
            >
              {row.getValue("groupLevelAggr")}
            </div>
          </div>
        );
      },
    },
    {
      accessorKey: "status",
      header: ({ column, header }) => (
        <DataTableColumnHeader
          column={column}
          title={"Status"}
          header={header}
        />
      ),
      enableHiding: true,
      enableSorting: true,
      size: 200,
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
      cell: ({ row, cell }) => {
        const widthValue = cell.column.getSize();
        return (
          <div className="flex items-center justify-center">
            <div
              className="antialiased truncate text-center"
              style={{ width: widthValue, minWidth: "150px" }}
            >
              {row.getValue("status")}
            </div>
          </div>
        );
      },
    },
    {
      accessorKey: "actions",
      header: () => (
        <div className="font-semibold font-sans lg:text-[1.4rem] antialiased !px-[6px] lg:!px-[4px]">
          View Plan
        </div>
      ),
      enableSorting: false,
      enableHiding: false,
      size: 140,
      cell: ({ cell, row }) => {
        return <>{actionColumn(row.original, navigate)}</>;
      },
    },
  ];
  return columns;
};

function transformRenderedCellvalue(input) {
  const options = {
    day: "numeric",
    month: "short",
    year: "numeric",
  };
  if (input instanceof Date) {
    return input.toLocaleDateString("en-GB", options);
  }
  return input;
}

export const baseGroupLevelCols = ({
  approvalStatusValues,
  setOpenSendModal,
  groupLevelMakeLive,
  groupLevelSendRevoke,
  groupLevelApproveReject,
  navigate,
  isPlanner,
  isApprover,
  setopenApprovalCommentsModal,
  getComments,
  handlePlanDetails,
}) => {
  const columnMeta = [
    { key: "plan", title: "Plan ID", size: 250 },
    { key: "cycle_id", title: "Cycle ID", size: 250 },
    { key: "group_code", title: "Group Code", size: 250 },
    { key: "created_at", title: "Created At", size: 250 },

    { key: "updated_at", title: "Updated At", size: 250 },
    { key: "updated_by", title: "Updated By", size: 250 },
    { key: "plan_type", title: "Plan Type", size: 250 },
    { key: "plan_status", title: "Plan Status", size: 250 },
    { key: "approval_status", title: "Approval Status", size: 250 },
    ...(isApprover
      ? [
          {
            key: "action_approve_reject",
            title: "Action - Approve / Reject",
            size: 250,
          },
        ]
      : []),
    ...(isPlanner
      ? [
          {
            key: "action_send_revoke",
            title: "Action - Send / Revoke",
            size: 250,
          },
        ]
      : []),
    { key: "action_make_live", title: "Action - Make Live", size: 250 },
    // { key: "cycle", title: "Cycle", size: 250 },
    // { key: "group", title: "Group", size: 250 },
    // { key: "version", title: "Version", size: 250 },
    // { key: "created_by", title: "Created By", size: 250 },
  ];
  let columns = columnMeta.map(({ key, title, size }) => {
    return {
      accessorKey: key,
      header: ({ column, header }) => (
        <div className="py-[10px] lg:py-[8px]">
          <DataTableColumnHeader
            column={column}
            title={title}
            header={header}
          />
        </div>
      ),
      enableHiding: true,
      enableSorting: true,
      size: size,
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
      cell: ({ row, cell }) => {
        const widthValue = cell.column.getSize();
        if (key === "plan_status") {
          return (
            <div className="flex items-center">
              <div
                className="antialiased truncate text-center py-[8px] lg:py-[6px]"
                style={{ width: widthValue, minWidth: "150px" }}
              >
                {groupLevelPlanStatus(
                  row.original,
                  setopenApprovalCommentsModal,
                  getComments
                )}
              </div>
            </div>
          );
        } else if (key === "approval_status") {
          return (
            <div className="flex items-center">
              <div
                className="antialiased truncate text-center py-[8px] lg:py-[6px]"
                style={{ width: widthValue, minWidth: "150px" }}
              >
                {groupLevelApprovalStatus(row.original)}
              </div>
            </div>
          );
        } else if (key === "action_make_live") {
          return (
            <div className="flex items-center">
              <div
                className="antialiased truncate text-center py-[8px] lg:py-[6px]"
                style={{ width: widthValue, minWidth: "150px" }}
              >
                {groupLevelMakeLive(row.original)}
              </div>
            </div>
          );
        } else if (key === "action_send_revoke") {
          return (
            <div className="flex items-center">
              <div
                className="antialiased truncate text-center py-[8px] lg:py-[6px]"
                style={{ width: widthValue, minWidth: "150px" }}
              >
                {groupLevelSendRevoke(row.original)}
              </div>
            </div>
          );
        } else if (key === "action_approve_reject") {
          return (
            <div className="flex items-center">
              <div
                className="antialiased truncate text-center py-[8px] lg:py-[6px]"
                style={{ width: widthValue, minWidth: "150px" }}
              >
                {groupLevelApproveReject(row.original)}
              </div>
            </div>
          );
        } else {
          return (
            <div className="flex items-center justify-center">
              <div
                className="antialiased truncate text-center py-[8px] lg:py-[6px]"
                style={{ width: widthValue, minWidth: "150px" }}
              >
                {transformRenderedCellvalue(row.getValue(key))}
              </div>
            </div>
          );
        }
      },
    };
  });
  columns.push({
    accessorKey: "actions",
    header: () => (
      <div className="font-semibold font-sans lg:text-[1.4rem] antialiased px-[6px] lg:!px-[4px]">
        View Plan
      </div>
    ),
    enableSorting: false,
    enableHiding: false,
    size: 120,
    cell: ({ cell, row }) => {
      return (
        <>{actionColumnGroup(row.original, navigate, handlePlanDetails)}</>
      );
    },
  });
  return columns;
};

//{ allocationArray } as prop
export const detailGroupCols = ({ allocationArray }) => {
  let columns = [
    {
      accessorKey: "rmpo_code",
      header: "RMPR Code/ID",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "rm_code",
      header: "RM Code",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "rm_name",
      header: "RM Name",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "src_supplier_code",
      header: "Source Code/ID",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "src_supplier_name",
      header: "Source Name",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "dest_node_code",
      header: "Destination Code/ID",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "dest_name",
      header: "Destination Name",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "rmpo_req_qty",
      header: "RMPR Required Quantity",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 140,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "rmpo_req_del_date",
      header: "RMPR Required Delivery Date",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "rmpo_planned_quantity_system",
      header: "RMPR Allocated Quantity-System",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "rmpo_planned_quantity_user",
      header: "RMPR Allocated Quantity-User",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 220,
      Cell: ({ cell, row }) => {
        const centerAlign = false;
        return DynamicRefsExample(1, allocationArray, cell, row, centerAlign);
      },
    },
    {
      accessorKey: "rmpo_planned_delivery_date",
      header: "RMPR Planned Delivery Date",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "rmpo_value",
      header: "RMPR Value",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "planned_rmpo_creation_date",
      header: "RMPR Creation Date",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "lead_time_in_days",
      header: "Lead Time(Days)",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "net_sales_rate",
      header: "Net Sales Rate",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "item_cost",
      header: "Item Cost",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "safety_stock",
      header: "Safety Stock",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "maximum_stock_level",
      header: "Maximum Stock Level",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "approval_status",
      header: "Approval Status",
      enableColumnOrdering: false,
      enableEditing: false,
      enableColumnActions: true,
      enableSorting: false,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "approved_by",
      header: "Approved By",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "approved_date",
      header: "Approved Date",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "version",
      header: "Version",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "short_supply",
      header: "Short Supply",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "reason_code",
      header: "Reason code",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "user_remarks",
      header: "Comments",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "total_shipping_cost",
      header: "Shipping Cost",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "shelf_life",
      header: "Shelf life",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "opening_stock",
      header: "Opening Stock",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "open_order",
      header: "Open Order",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "pr_generation_rate",
      header: "PR Generation Rate",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "net_req",
      header: "Net Requirement",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "action",
      header: "Action",
      enableColumnActions: false,
      enableSorting: false,
      muiTableBodyCellEditTextFieldProps: {
        select: true, //change to select for a dropdown
      },
      size: 210,
      Cell: ({ cell, row }) => {
        const centerAlign = true;
        const multipleElements = true;
        return DynamicRefsExample(
          2,
          actionGroupPlanArray,
          cell,
          row,
          centerAlign,
          multipleElements
        );
      },
    },
  ];
  return columns;
};

export const detailOrgCols = () => {
  let columns = [
    {
      accessorKey: "rmpo_code",
      header: "RMPR Code/ID",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "rm_code",
      header: "RM Code",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "rm_name",
      header: "RM Name",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "src_supplier_code",
      header: "Source Code/ID",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "src_supplier_name",
      header: "Source Name",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "dest_node_code",
      header: "Destination Code/ID",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "dest_name",
      header: "Destination Name",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "rmpo_req_qty",
      header: "RMPR Required Quantity",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 140,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "rmpo_req_del_date",
      header: "RMPR Required Delivery Date",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "rmpo_planned_quantity_system",
      header: "RMPR Allocated Quantity-System",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "rmpo_planned_quantity_user",
      header: "RMPR Allocated Quantity-User",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 220,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "rmpo_planned_delivery_date",
      header: "RMPR Planned Delivery Date",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "rmpo_value",
      header: "RMPR Value",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "planned_rmpo_creation_date",
      header: "RMPR Creation Date",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "lead_time_in_days",
      header: "Lead Time(Days)",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "net_sales_rate",
      header: "Net Sales Rate",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "item_cost",
      header: "Item Cost",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "safety_stock",
      header: "Safety Stock",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "maximum_stock_level",
      header: "Maximum Stock Level",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "approval_status",
      header: "Approval Status",
      enableColumnOrdering: false,
      enableEditing: false,
      enableColumnActions: true,
      enableSorting: false,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "approved_by",
      header: "Approved By",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "approved_date",
      header: "Approved Date",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "version",
      header: "Version",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "short_supply",
      header: "Short Supply",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "reason_code",
      header: "Reason code",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "user_remarks",
      header: "Comments",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "total_shipping_cost",
      header: "Shipping Cost",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "shelf_life",
      header: "Shelf life",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "opening_stock",
      header: "Opening Stock",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "open_order",
      header: "Open Order",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "pr_generation_rate",
      header: "PR Generation Rate",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "net_req",
      header: "Net Requirement",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      size: 180,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "action",
      header: "Action",
      enableColumnActions: false,
      enableSorting: false,
      muiTableBodyCellEditTextFieldProps: {
        select: true, //change to select for a dropdown
      },
      size: 210,
      Cell: ({ cell, row }) => {
        const centerAlign = true;
        const multipleElements = true;
        return DynamicRefsExample(
          2,
          actionGroupPlanArray,
          cell,
          row,
          centerAlign,
          multipleElements
        );
      },
    },
  ];
  return columns;
};

const actionGroupPlanArray = [
  (row1) => {
    const { row } = row1;
    return (
      <div>
        <button
          className="group-plan-action-button remove"
          style={{}}
          // onClick={() => handleSingleSTOApproval(row.stoId, false)}
        >
          <svg
            width="13"
            height="12"
            viewBox="0 0 13 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.3228 11.8228C12.0884 12.0573 11.709 12.0573 11.4743 11.8228L6.4999 6.84728L1.52435 11.8228C1.28989 12.0573 0.910558 12.0573 0.675841 11.8228C0.441386 11.5884 0.441386 11.209 0.675841 10.9743L5.65252 5.9999L0.676591 1.02435C0.442136 0.789893 0.442136 0.410558 0.676591 0.175841C0.911046 -0.0586137 1.29038 -0.0586137 1.5251 0.175841L6.4999 5.15252L11.4755 0.176966C11.7099 -0.0574889 12.0892 -0.0574889 12.324 0.176966C12.5584 0.411421 12.5584 0.790755 12.324 1.02547L7.34728 5.9999L12.3228 10.9755C12.5591 11.2079 12.5591 11.5904 12.3228 11.8228Z"
              fill="#3D445C"
            />
          </svg>
        </button>
      </div>
    );
  },
  (row1) => {
    const { row } = row1;
    return (
      <div>
        <button
          className="group-plan-action-button save"
          //   onClick={() => handleSingleSTOApproval(row.stoId, true)}
        >
          <svg
            width="13"
            height="9"
            viewBox="0 0 13 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.339 1.17228C12.5682 1.42715 12.5506 1.82248 12.2996 2.05525L5.05608 8.774C4.8066 9.0054 4.42008 8.98925 4.19016 8.73781L0.66447 4.88218C0.433187 4.62926 0.447578 4.2338 0.696613 3.9989C0.945648 3.76401 1.33502 3.77862 1.5663 4.03155L4.67671 7.43303L11.4696 1.13226C11.7206 0.899479 12.1098 0.917397 12.339 1.17228Z"
              fill="#3D445C"
            />
          </svg>
        </button>
      </div>
    );
  },
];

function groupLevelApprovalStatus(row) {
  const approval_id = row?.approval_id;
  const approval_details = row?.approval_details;
  return (
    <div
      style={{
        display: "flex",
        gap: "10px",
        justifyContent: "center",
      }}
    >
      {" "}
      {approval_id === null && <></>}
      {approval_id !== null &&
        approval_details &&
        approval_details.levelwise_approval_status.length !== 0 &&
        approval_details.levelwise_approval_status.map((levelItem, index) => (
          <div
            className={`status-indicator-app ${
              levelItem.approval_status !== "APPROVED"
                ? levelItem.approval_status !== "REJECTED"
                  ? levelItem.approval_status !== "PENDING"
                    ? "awaiting-status"
                    : " pending-status"
                  : "rejected-status"
                : "approved-status"
            }`}
            key={index}
          ></div>
        ))}
    </div>
  );
}

function groupLevelPlanStatus(row, setopenApprovalCommentsModal, getComments) {
  return (
    <div className="flex justify-between px-5">
      <div className="text-left">{row.plan_status}</div>
      <button
        style={{
          background: "transparent",
          padding: "0px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "50%",
          border: "none",
        }}
        className={`info-button ${
          row.approval_id === null ? "info-disable" : null
        }`}
        onClick={() => {
          setopenApprovalCommentsModal(true);
          getComments(row.approval_id);
        }}
        disabled={row.approval_id === null}
      >
        <svg
          width="13"
          height="13"
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          r="5"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13 6.5C13 10.0899 10.0899 13 6.5 13C2.91015 13 0 10.0899 0 6.5C0 2.91015 2.91015 0 6.5 0C10.0899 0 13 2.91015 13 6.5ZM7.3125 3.25C7.3125 3.69873 6.94873 4.0625 6.5 4.0625C6.05127 4.0625 5.6875 3.69873 5.6875 3.25C5.6875 2.80127 6.05127 2.4375 6.5 2.4375C6.94873 2.4375 7.3125 2.80127 7.3125 3.25ZM5.6875 5.6875C5.23877 5.6875 4.875 6.05127 4.875 6.5C4.875 6.94873 5.23877 7.3125 5.6875 7.3125V9.75C5.6875 10.1987 6.05127 10.5625 6.5 10.5625H7.3125C7.76123 10.5625 8.125 10.1987 8.125 9.75C8.125 9.30127 7.76123 8.9375 7.3125 8.9375V6.5C7.3125 6.05127 6.94873 5.6875 6.5 5.6875H5.6875Z"
            fill="#A3AAC2"
          />
        </svg>
      </button>
    </div>
  );
}

const handleApprovalStatusIndicator = (status) => {
  if (status === "Completed") {
    return "completed";
  } else if (status === "In- Progress") {
    return "progress";
  }
  return "rejected";
};

const actionButtonsGroupArray = (navigate) => {
  return [
    (row1) => {
      const { row } = row1;
      return (
        <div style={{ width: "100%", display: "flex" }}>
          <button
            className="table-action-button view-button-preprocess-rm"
            style={{ margin: "auto" }}
            onClick={() => {
              navigate(`/snp/raw-material/plan/group-plan/${row.planId}`);
            }}
          >
            View
          </button>
        </div>
      );
    },
  ];
};

function DynamicRefsExample(
  numberOfRefs,
  componentArray,
  cell,
  row,
  centerAlign = true
) {
  const refsArray = useRef(
    Array.from({ length: numberOfRefs }, () => createRef())
  );
  return (
    <div className={!centerAlign ? "action-buttons" : "action-buttons-align"}>
      {Array.from({ length: numberOfRefs }, (_, index) => {
        const Comp = componentArray[index];

        return (
          <div
            id={index}
            key={index}
            ref={refsArray.current[index]}
            placeholder={`Ref ${index + 1}`}
            onClick={() => actionsArray[index](row)}
            style={{
              pointerEvents: row.original.isDisabled ? "none" : "auto",
              width: "100%",
            }}
          >
            <Comp row={row.original} />
          </div>
        );
      })}
    </div>
  );
}

const actionsArray = [
  (row) => console.log({ row }),
  (row) => console.log({ row }),
  (row) => console.log({ row }),
];

const actionColumnGroup = (row, navigate, handlePlanDetails) => {
  const planId = row.plan;
  return (
    <div style={{ width: "100%", display: "flex" }}>
      <button
        className="table-action-button view-button-preprocess-rm"
        style={{ margin: "auto" }}
        onClick={() => {
          handlePlanDetails({ ...row });
          navigate(`/snp/raw-material/plan/group-plan/${planId}`);
        }}
      >
        View
      </button>
    </div>
  );
};

const actionColumn = (row, navigate) => {
  const planId = row.cycleId;
  return (
    <div style={{ width: "100%", display: "flex" }}>
      <button
        className="table-action-button view-button-preprocess-rm"
        style={{ margin: "auto" }}
        onClick={() => {
          navigate(`/snp/raw-material/plan/org-plan/${planId}`);
        }}
      >
        View
      </button>
    </div>
  );
};

export function loadingColumns() {
  const columns = Array(10)
    .fill({})
    .map((item, index) => {
      return {
        accessorKey: `column_${index}`,
        header: ({ column, header }) => (
          <div className="py-[20px] lg:py-[15px]">
            <DataTableColumnHeader column={column} title={""} header={header} />
          </div>
        ),
        cell: ({ row }) => (
          <div className="flex items-center justify-center h-full py-[8px] lg:py-[6px]">
            <Skeleton className="h-5 w-1/2" />
          </div>
        ),
      };
    });
  return columns;
}
