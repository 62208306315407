import { useState, useEffect } from "react";
import Select from "react-select";
import "./BasicUploader.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UploadCloudIcon from "../../assets/uploadCloudIcon.svg";
import { forwardRef, useImperativeHandle, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import { handleApiResponse } from "../../utils/apiUtils";

// eslint-disable-next-line react/display-name
const BasicUploader = forwardRef((props, ref) => {
  const { preprocessValues, uploadType } = props;
  const [file, setFile] = useState();
  const [chunkCount, setChunkCount] = useState(0);
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [fileNameUsedInServer, setFileNameUsedInServer] = useState("");
  const [sha256Checksum, setSha256Checksum] = useState(null);
  const location = useLocation();
  const path = location.pathname;
  let pathSplit = path.split("/");
  let len = pathSplit.length;
  const planId = pathSplit[len - 1];
  let snpURL = process.env.REACT_APP_SCAI_API_ENDPOINT;
  const handleChange = (option) => {
    if (uploadType === "add-new-order") {
      props.setSelectedGroupOption(option);
    } else {
      setSelectedOption(option);
    }
  };
  const options = [
    { value: "Plan export edits", label: "Plan export edits" },
    { value: "Manual STO creation", label: "Manual STO creation" },
  ];
  useEffect(() => {
    fetchFileNames();
    if (file) {
      console.log(
        "count",
        Math.ceil(file?.size / (1024 * 1024 * 10)),
        "filesize",
        file?.size
      );
      setChunkCount(Math.ceil(file?.size / (1024 * 1024 * 10)));
    }
  }, [file]);

  function b2h(buffer) {
    return Array.prototype.map
      .call(new Uint8Array(buffer), (x) => ("00" + x.toString(16)).slice(-2))
      .join("");
  }

  useImperativeHandle(ref, () => ({
    handleUploadClick() {
      if (!file) {
        toast.error("Please select a file to upload");
        return;
      }
      if (
        selectedOption === null &&
        (uploadType === "sto" || uploadType === "RMPR")
      ) {
        toast.error("Please select an option to upload");
        return;
      }
      if (
        uploadType === "sto" ||
        uploadType === "pwo" ||
        uploadType === "RMPR" ||
        uploadType === "add-new-order"
      ) {
        props.setIsUploadingSto(true);
      }
      if (uploadType === "preprocess") {
        props.setIsUploading((prev) => ({
          ...prev,
          [props.selectedProcess.process]: true,
        }));
      }
      if (uploadType === "process") {
        props.setIsUploading(true);
      }
      props.setOpenModal(false);

      if (file?.size > 1024 * 1024 * 10) uploadChunks();
      else {
        uploadMonolithFile();
      }
    },
  }));

  const handleFileChange = (e) => {
    e.preventDefault();
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
      if (fileExtension === "csv") {
        setFile(selectedFile);
      } else {
        toast.warn("Please select a valid Excel file (csv)");
      }

      const reader = new FileReader();
      reader.readAsArrayBuffer(e.target.files[0]);
      reader.onloadend = async function (entry) {
        const FILE_HASH = b2h(
          await crypto.subtle.digest("SHA-256", entry.target.result)
        );
        console.log({ FILE_HASH });
        // output: the sha256 digest hex encoded of the file
        setSha256Checksum(FILE_HASH);
      };
    }
  };

  async function makeApiCallsInLoop(totalCHunks, CHUNK_SIZE) {
    // required when file is not there
    let fileName;
    if (selectedOption === null) {
      const fileUploadPermission = await permissionStartUpload(
        file.name,
        Math.ceil(file?.size / (1024 * 1024 * 10))
      );
      fileName = fileUploadPermission.filename;
    } else {
      fileName = selectedOption.value;
    }
    const getChunksUploadedCount = await checkFileChunkUploadCount(fileName);
    const blocksUploaded = getChunksUploadedCount.block_count;
    for (let i = blocksUploaded; i < totalCHunks; i++) {
      let success = true;
      try {
        const start = i * CHUNK_SIZE;
        const end = Math.min(start + CHUNK_SIZE, file?.size);
        const chunk = file.slice(start, end);

        const formData = new FormData();
        formData.append("file", chunk);
        const response = await startUpload(chunk, Number(i + 1), fileName);
        // add condition tp make this call only if startUpload is successful
        if (i === totalCHunks - 1) {
          const endResp = await endFileUpload(fileName);
        }
      } catch (error) {
        console.error(error);
        success &&
          toast.success(
            "upload failed midway, please select the file from dropdown and try again"
          );
        success = false;
        break;
        // handle the error from the API call here
      }
    }
  }

  const uploadChunks = () => {
    const CHUNK_SIZE = 1024 * 1024 * 10; // 10MB
    const totalChunks = Math.ceil(file?.size / CHUNK_SIZE);
    makeApiCallsInLoop(totalChunks, CHUNK_SIZE);
  };

  const uploadMonolithFile = () => {
    if (!file) return;
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${JSON.parse(localStorage.getItem("token"))}`
    );
    var formdata = new FormData();
    formdata.append("file", file);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    toast.info("File started uploading", { autoClose: false });
    return fetch(`${snpURL}/upload/file/`, requestOptions)
      .then(handleApiResponse)
      .then((uploadedResult) => {
        fetch(getUploadURL(uploadedResult), requestOptions)
          .then(handleApiResponse)
          .then((result) => {
            if (
              uploadType === "sto" ||
              uploadType === "pwo" ||
              uploadType === "RMPR" ||
              uploadType === "add-new-order"
            ) {
              props.refetchSTO();
              props.setIsUploadingSto(false);
              toast.dismiss();
              toast.success(`File uploaded successfully & ${result.message}`, {
                autoClose: 10000,
              });
            } else if (uploadType === "preprocess") {
              props.setIsUploading((prev) => ({
                ...prev,
                [props.selectedProcess.process]: false,
              }));
              toast.dismiss();
              toast.success("File uploaded successfully", { autoClose: 10000 });
            } else if (uploadType === "process") {
              props.refetch();
              props.setIsUploading(false);
              toast.dismiss();
              toast.success("File uploaded successfully", {
                autoClose: 10000,
              });
            } else {
              toast.dismiss();
              toast.success("File uploaded successfully", { autoClose: 10000 });
            }
          })
          .catch((error) => {
            if (
              uploadType === "sto" ||
              uploadType === "pwo" ||
              uploadType === "RMPR" ||
              uploadType === "add-new-order"
            ) {
              props.setIsUploadingSto(false);
              toast.dismiss();
              toast.error(error.message, { autoClose: 10000 });
            } else if (
              uploadType === "preprocess" ||
              uploadType === "process"
            ) {
              uploadType === "preprocess"
                ? props.setIsUploading((prev) => ({
                    ...prev,
                    [props.selectedProcess.process]: false,
                  }))
                : props.setIsUploading(false);
              if (error.message) {
                toast.dismiss();
                toast.error(error.message, { autoClose: 10000 });
              } else {
                toast.dismiss();
                toast.error("File upload failed", { autoClose: 10000 });
              }
            } else {
              toast.dismiss();
              toast.error("File upload failed", { autoClose: 10000 });
            }
          });
      })
      .catch((error) => {
        if (
          uploadType === "sto" ||
          uploadType === "pwo" ||
          uploadType === "RMPR" ||
          uploadType === "add-new-order"
        ) {
          props.setIsUploadingSto(false);
        } else if (uploadType === "preprocess" || uploadType === "process") {
          uploadType === "preprocess"
            ? props.setIsUploading((prev) => ({
                ...prev,
                [props.selectedProcess.process]: false,
              }))
            : props.setIsUploading(false);
        }
        if (error.message) {
          toast.dismiss();
          toast.error(error.message, { autoClose: 3000 });
        } else {
          toast.dismiss();
          toast.error("File upload failed ");
        }
      });
  };

  function getUploadURL(uploadedResult) {
    const rmprId = props.rmprId;
    let url;

    if (uploadType === "preprocess" || uploadType === "process") {
      if (props.selectedProcess.processTypes === "INVENTORY_PROFILING") {
        url = `${snpURL}/preprocess/upload_profiling/?filename=${uploadedResult.filename}`;
      } else if (preprocessValues.processType === "classification") {
        url = `${snpURL}/preprocess/upload_${preprocessValues.processType}/?type=${preprocessValues.type}&filename=${uploadedResult.filename}`;
      } else {
        url = `${snpURL}/preprocess/v2/upload_${
          preprocessValues.processType
        }/${getValue(props.selectedProcess, props.moduleType)}/?norms_type=${
          preprocessValues.type
        }&module_type=${props.moduleType}&filename=${uploadedResult.filename}`;
      }
    } else if (uploadType === "sto") {
      if (selectedOption.value === "Plan export edits") {
        url = `${snpURL}/v1/csv_upload/${planId}/?filename=${uploadedResult.filename}`;
      } else {
        url = `${snpURL}/v1/replenishment_plans/manual_sto/upload/${planId}/?filename=${uploadedResult.filename}`;
      }
    } else if (uploadType === "RMPR") {
      if (selectedOption.value === "Plan export edits") {
        url = `${snpURL}/v1/rm_plans/${rmprId}/?filename=${uploadedResult.filename}`;
      } else {
        url = `${snpURL}/v1/rm_plans/${rmprId}/?filename=${uploadedResult.filename}&template=true`;
      }
    } else if (uploadType === "add-new-order") {
      const groupId = props.selectedGroupOption?.id;
      url = `${snpURL}/oms/add-data/?filename=${uploadedResult.filename}&group=${groupId}`;
    } else {
      url = `${snpURL}/v1/app_csv_upload/${planId}/?filename=${uploadedResult.filename}`;
    }

    return url;
  }

  const checkFileChunkUploadCount = async (filename) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${JSON.parse(localStorage.getItem("token"))}`
    );
    var formdata = new FormData();
    formdata.append("filename", filename);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    return fetch(`${snpURL}/upload/chunks/count/`, requestOptions)
      .then(handleApiResponse)
      .then((result) => {
        console.log({
          result,
        });
        toast.dismiss();
        toast.success("count call done");
        return result;
      })
      .catch((error) => {
        if (error.message) {
          toast.dismiss();
          toast.error(error.message, { autoClose: 3000 });
        }
      });
  };

  const fetchFileNames = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${JSON.parse(localStorage.getItem("token"))}`
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${snpURL}/upload/chunks/list/`, requestOptions)
      .then(handleApiResponse)
      .then((result) => {
        setSelectOptions(
          result.filenames.map((item) => ({ value: item, label: item }))
        );
        return result.filenames.map((item) => item);
      })
      .catch((error) => {
        if (error.message) {
          toast.dismiss();
          toast.error(error.message, { autoClose: 10000 });
        }
      });
  };

  const getValue = (row, moduleType) => {
    if (moduleType === "rmp") {
      const process = row.process.toLowerCase().split(":");
      const processSpec = process[1].trim() === "rm" ? "rm" : "sku";
      return processSpec;
    }
    return "sku";
  };

  function getEndFileUploadURL(result) {
    const rmprId = props.rmprId;
    let url;

    if (uploadType === "preprocess" || uploadType === "process") {
      if (props.selectedProcess.processTypes === "INVENTORY_PROFILING") {
        // If the process type is INVENTORY_PROFILING
        url = `${snpURL}/preprocess/upload_profiling/?filename=${result.filename}`;
      } else if (preprocessValues.processType === "classification") {
        // If the process type is classification
        url = `${snpURL}/preprocess/upload_${preprocessValues.processType}/?type=${preprocessValues.type}&filename=${result.filename}`;
      } else {
        // For other preprocess or process types
        url = `${snpURL}/preprocess/v2/upload_${
          preprocessValues.processType
        }/${getValue(props.selectedProcess, props.moduleType)}/?norms_type=${
          preprocessValues.type
        }&module_type=${props.moduleType}&filename=${result.filename}`;
      }
    } else if (uploadType === "sto") {
      // If the upload type is sto
      url = `${snpURL}/v1/csv_upload/${planId}/?filename=${result.filename}`;
    } else if (uploadType === "RMPR") {
      if (selectedOption.value === "Plan export edits") {
        url = `${snpURL}/v1/rm_plans/${rmprId}?filename=${result.filename}`;
      } else {
        url = `${snpURL}/v1/rm_plans/${rmprId}?filename=${result.filename}&template=true`;
      }
    } else if (uploadType === "add-new-order") {
      const groupId = props.selectedGroupOption?.id;
      url = `${snpURL}/oms/add-data/?filename=${result.filename}&group=${groupId}`;
    } else {
      // For other upload types
      url = `${snpURL}/v1/app_csv_upload/${planId}/?filename=${result.filename}`;
    }

    return url;
  }

  const endFileUpload = async (filename) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${JSON.parse(localStorage.getItem("token"))}`
    );
    var formdata = new FormData();
    formdata.append("filename", filename);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    if (uploadType === "sto" || uploadType === "pwo" || uploadType === "RMPR") {
      props.setLoadingData(true);
    }
    return fetch(`${snpURL}/upload/chunks/end/`, requestOptions)
      .then(handleApiResponse)
      .then((result) => {
        fetch(getEndFileUploadURL(result), requestOptions)
          .then(handleApiResponse)
          .then((result) => {
            if (
              uploadType === "sto" ||
              uploadType === "pwo" ||
              uploadType === "RMPR" ||
              uploadType === "add-new-order"
            ) {
              props.refetchSTO();
              props.setIsUploadingSto(false);
              toast.success("File uploaded successfully", { autoClose: 10000 });
            } else if (uploadType === "preprocess") {
              props.setIsUploading((prev) => ({
                ...prev,
                [props.selectedProcess.process]: false,
              }));
              toast.success("File uploaded successfully", { autoClose: 10000 });
            } else if (uploadType === "process") {
              props.refetch();
              props.setIsUploading(false);
              toast.success("File uploaded successfully", { autoClose: 10000 });
            } else {
              toast.success("File uploaded successfully", { autoClose: 10000 });
            }

            console.log({ result });
          })
          .catch((error) => {
            if (
              uploadType === "sto" ||
              uploadType === "pwo" ||
              uploadType === "RMPR" ||
              uploadType === "add-new-order"
            ) {
              props.setIsUploadingSto(false);
              toast.dismiss();
              toast.error(error.message, { autoClose: 10000 });
            } else if (
              uploadType === "preprocess" ||
              uploadType === "process"
            ) {
              uploadType === "preprocess"
                ? props.setIsUploading((prev) => ({
                    ...prev,
                    [props.selectedProcess.process]: false,
                  }))
                : props.setIsUploading(false);
              if (error.message) {
                toast.dismiss();
                toast.error(error.message, { autoClose: 10000 });
              } else {
                toast.dismiss();
                toast.error("File upload failed", { autoClose: 10000 });
              }
            } else {
              toast.dismiss();
              toast.error("File upload failed", { autoClose: 10000 });
            }
          });
      })
      .catch((error) => {
        if (
          uploadType === "sto" ||
          uploadType === "pwo" ||
          uploadType === "RMPR" ||
          uploadType === "add-new-order"
        ) {
          props.setIsUploadingSto(false);
        } else if (uploadType === "preprocess") {
          props.setIsUploading((prev) => ({
            ...prev,
            [props.selectedProcess.process]: false,
          }));
        } else if (uploadType === "process") {
          props.setIsUploading(false);
        }
        if (error.message) {
          toast.dismiss();
          toast.error(error.message, { autoClose: 10000 });
        } else {
          toast.dismiss();
          toast.error("File upload failed", { autoClose: 10000 });
        }
      });
  };
  const permissionStartUpload = async (filename, count) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${JSON.parse(localStorage.getItem("token"))}`
    );
    var formdata = new FormData();
    formdata.append("filename", filename);
    formdata.append("file_hash", sha256Checksum || null);
    formdata.append("block_count", count);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    return fetch(`${snpURL}/upload/chunks/start/`, requestOptions)
      .then(handleApiResponse)
      .then((result) => {
        console.log({ result });
        toast.success("start call done");
        setSelectedOption({ label: result.filename, value: result.filename });
        return result;
      })
      .catch((error) => {
        if (error.message) {
          toast.dismiss();
          toast.error(error.message, { autoClose: 3000 });
        } else {
          toast.dismiss();
          toast.error(error);
        }
      });
  };

  const startUpload = (chunk, chunk_index, filename) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${JSON.parse(localStorage.getItem("token"))}`
    );

    var formdata = new FormData();
    formdata.append("file", chunk);
    formdata.append("filename", selectedOption?.value ?? filename);
    formdata.append("chunk_index", chunk_index);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    // earlier it was await
    return fetch(`${snpURL}/upload/chunks/data/`, requestOptions)
      .then(handleApiResponse)
      .then((result) => console.log(result))
      .catch((error) => {
        checkFileChunkUploadCount(selectedOption.value);
        if (error.message) {
          toast.dismiss();
          toast.error(error.message, { autoClose: 10000 });
        } else {
          toast.dismiss();
          toast.error(
            "chunked upload failed midway, select the same file from dropdown and try reuploading it"
          );
        }

        console.log("error", error);
      });
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setFile(e.dataTransfer.files[0]);
  };

  return (
    <>
      <Select
        options={
          uploadType === "sto" || uploadType === "RMPR"
            ? options
            : uploadType === "add-new-order"
            ? props.groupOptions
            : selectOptions
        }
        placeholder={
          uploadType === "add-new-order" ? "Select Group" : "Select..."
        }
        value={
          uploadType === "add-new-order"
            ? props.selectedGroupOption
            : selectedOption
        }
        onChange={handleChange}
        isLoading={uploadType === "add-new-order" && props.isLoadingGroups}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            width: "98%",
            margin: "auto",
            border: "1px solid #dbdbdb",
            fontFamily: "Inter",
            fontSize: "13px",
          }),

          menu: (provided) => ({
            ...provided,
            width: "98%",
            margin: "auto",
            marginLeft: "5px",
            marginTop: "2px",
            fontFamily: "Inter",
            fontSize: "13px",
          }),
        }}
      />
      <div
        className="drop-area"
        onDragOver={(e) => e.preventDefault()}
        onDrop={handleDrop}
      >
        {/* <img src={UploadCloudIcon} /> */}
        <svg
          width="62"
          height="46"
          viewBox="0 0 62 46"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.81569 33.6527C-12.7948 11.4771 26.7552 -14.6399 42.9096 15.9122C55.1643 13.4482 67.9763 27.7384 53.4931 38.0865"
            stroke="#C9C9C9"
            strokeWidth="3"
            strokeLinecap="round"
          />
          <path
            d="M29.1562 44C29.1563 44.8284 29.8278 45.5 30.6563 45.5C31.4847 45.5 32.1563 44.8284 32.1562 44L29.1562 44ZM31.7169 20.272C31.1311 19.6862 30.1814 19.6862 29.5956 20.272L20.0496 29.818C19.4639 30.4038 19.4639 31.3535 20.0496 31.9393C20.6354 32.5251 21.5852 32.5251 22.171 31.9393L30.6562 23.454L39.1415 31.9393C39.7273 32.5251 40.6771 32.5251 41.2629 31.9393C41.8486 31.3535 41.8486 30.4038 41.2629 29.818L31.7169 20.272ZM32.1562 44L32.1562 21.3327L29.1562 21.3327L29.1562 44L32.1562 44Z"
            fill="#C9C9C9"
          />
        </svg>

        <div className="drag-drop">
          {file ? <p>File name: {file.name}</p> : <p>Drag and Drop or </p>}
          {!file ? (
            <div>
              <label className="file-input-label" htmlFor="file-input">
                browse
              </label>
              <input
                id="file-input"
                className="file-input"
                type="file"
                accept=".csv"
                onChange={handleFileChange}
              />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
});

export default BasicUploader;
