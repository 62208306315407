const DeleteIcon = ({ color, height }) => (
  <svg
    width={height}
    height={height}
    viewBox="0 0 128 128"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="action-icon-hover"
  >
    <path
      d="M108.2 32C106.129 32 104.4 33.581 104.215 35.645L97 116.35C96.85 118.4 95.1 120 93.025 120H34.975C32.9075 120 31.1575 118.4 31 116.35L23.79 35.65C23.605 33.575 21.8775 32 19.8075 32C17.4575 32 15.6125 34.025 15.8225 36.35L23.0325 117.05C23.595 123.25 28.75 128 34.975 128H93.05C99.27 128 104.425 123.25 104.988 117.062L112.198 36.3625C112.375 34.025 110.55 32 108.2 32ZM116 16H92L83.5925 4.8125C81.35 1.78125 77.775 0 74 0H54C50.225 0 46.65 1.78125 44.4 4.8125L36 16H12C9.8 16 8 17.8 8 20C8 22.2 9.8 24 12 24H116C118.2 24 120 22.2 120 20C120 17.8 118.2 16 116 16ZM46 16L50.8125 9.595C51.575 8.595 52.75 8 54 8H74C75.25 8 76.4375 8.59375 77.1875 9.59375L82 16H46Z"
      fill={color}
    />
  </svg>
);
export default DeleteIcon;
