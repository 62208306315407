import * as React from "react";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { TableBody, TableCell, TableRow } from "@/components/ui/table";
import "./table.css";

export function DataSubrowTable({ columns, data }) {
  const table = useReactTable({
    data,
    columns,
    defaultColumn: {
      size: 100,
      minSize: 70,
      maxSize: 1000,
    },
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.wpoId,
    debugTable: true,
  });

  const tableContainerRef = React.useRef(null);
  return (
    <div className="space-y-4 data-subrow-table">
      <div
        className="rounded-md border overflow-auto box-shadow-custom new-table-container"
        ref={tableContainerRef}
      >
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} style={{ margin: "20px" }}>
                {headerGroup.headers.map((header) => {
                  const { column } = header;
                  return (
                    <th
                      key={header.id}
                      style={{
                        width: header.getSize(),
                      }}
                      className="lg:p-2"
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                      <div
                        onMouseDown={header.getResizeHandler()}
                        onTouchStart={header.getResizeHandler()}
                        className={`resizer ${
                          header.column.getIsResizing() ? "isResizing" : ""
                        }`}
                      />
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <>
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                  >
                    {row.getVisibleCells().map((cell) => {
                      const { column } = cell;
                      return (
                        <td
                          key={cell.id}
                          style={{
                            width: cell.column.getSize(),
                          }}
                          className="font-[Inter] text-sm lg:text-[12px] lg:!py-4 py-2 text-ellipsis"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </TableRow>
                </>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-14">
                  <span className="lg:pl-7 sm:text-[12px] text-[12px]">
                    No results.
                  </span>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </table>
      </div>
    </div>
  );
}
