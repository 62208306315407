import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  createTheme,
  Skeleton,
  ThemeProvider,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import InventoryStatistics from "./Charts/InventoryStatistics";
import KpiCards from "../../../components/kpi_cards/kpiCardsMain";
import DemandOverview from "./Charts/DemandOverview";
import { KpiCardsCopy } from "../../../components/kpi_cards_copy/kpiCardsMain";
import {
  useDemandOverviewQuery,
  useInventoryStatisticsQuery,
} from "../../../features/api/inventorySlice";
import { CircularIndefiniteSpinner } from "../../../components/loading/LoadingIndex";
import Performance from "./Charts/Performance";
import { PerformanceIndicator } from "../../../components/progressbar/performance";
import RevenueOutlook from "./Charts/RevenueOutlook";
import { useDispatch } from "react-redux";
import { setDemandAndInvStatsData } from "../../../features/InventoryProfileSlice";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../../utils/components";
import { useNavigate } from "react-router-dom";

const StyledCard2 = styled("div")(({ theme }) => ({
  ...theme.typography.body1,
  borderRadius: "7px",
  border: "1px solid #E6E6E6",
  // padding: theme.spacing(1),
  display: "flex",
  flexDirection: "column",
  position: "relative",
  //   flexWrap: "wrap",
}));

const StyledButton = styled("button")(() => ({
  display: "inline-flex",
  justifyContent: "space-between",
  alignItems: "center",
  background: "#fff",
  marginTop: "10px",
  marginRight: "10px",
  paddingTop: "2px",
  paddingBottom: "2px",
  paddingLeft: "5px",
  paddingRight: "5px",
  fontSize: "12px",
  fontWeight: "500",
  borderRadius: "30px",
  transition: "all 0.5s ease",
  "&:hover": {
    "& svg": {
      visibility: "visible",
      opacity: 1,
    },
    background: "#ebebeb",
  },
  "& svg": {
    visibility: "hidden",
    opacity: "0",
    transition: "visibility 0s, opacity 0.5s linear",
  },
}));

const HeadingDiv = styled("div")(({ theme }) => ({
  ...theme.typography.customHeading,
  fontFamily: "IBM Plex sans",
  fontWeight: "400",
  paddingLeft: "20px",
  marginTop: "10px",
  background: "#fff",
}));

const ViewMore = styled("div")(() => ({
  color: "#3D445C",
  fontFamily: "IBM Plex Sans",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: "500",
  marginRight: "2px",
}));

const StyledOverlay = styled("div")(() => ({
  borderRadius: "7px",
  position: "absolute",
  display: "block",
  width: "100%",
  height: "100%",
  top: "0",
  left: "0",
  right: "0",
  bottom: "0",
  backgroundImage: "linear-gradient(rgba(0,0,0,0.21), rgba(0,0,0,0.62))",
  zIndex: "100",
}));

const StyledComingSoon = styled("div")(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  fontSize: "30px",
  color: "white",
  transform: "translate(-50%,-50%)",
  fontFamily: "IBM Plex Sans",
  // -ms-transform: translate(-50%,-50%),
}));

function useResizeObserver() {
  const [componentWidth, setComponentWidth] = React.useState(null);
  const [componentHeight, setComponentHeight] = React.useState(null);
  const targetRef = React.useRef(null);

  React.useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target === targetRef.current) {
          setComponentWidth(entry.contentRect.width);
          setComponentHeight(entry.contentRect.height);
        }
      }
    });

    if (targetRef.current) {
      resizeObserver.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        resizeObserver.unobserve(targetRef.current);
      }
    };
  }, []);

  return { targetRef, componentWidth, componentHeight };
}

const InventoryDashBoard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { targetRef, componentWidth, componentHeight } = useResizeObserver();

  const {
    data: demandOverviewData,
    isLoading: isLoadingDemandOverview,
    isSuccess: isSuccessDemandOverview,
  } = useDemandOverviewQuery(undefined, {
    refetchOnMountOrArgChange: true,
    force: true, // This will bypass the cache
  });

  const {
    data: invStatsData,
    isLoading: isLoadingInvStats,
    isSuccess: isSuccessInvStats,
  } = useInventoryStatisticsQuery(undefined, {
    refetchOnMountOrArgChange: true,
    force: true, // This will bypass the cache
  });

  if (isSuccessDemandOverview && isSuccessInvStats) {
    dispatch(setDemandAndInvStatsData({ demandOverviewData, invStatsData }));
  }

  const snpWidth = document.getElementById("snp")?.offsetWidth;

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <ThemeProvider theme={customTheme}>
      <Box
        sx={{
          boxSizing: "border-box",
          display: "flex",
          height: "100vh",
          maxHeight: "100vh",
          width: "100%",
          flexDirection: "column",
          padding: "10px",
        }}
      >
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            expanded={expanded === "panel1"}
            fill="white"
            hoverColor="#e94f1c"
            backgroundColor="#494949"
            expandedBgColor="#e94f1b"
          >
            <Typography
              sx={{
                width: "33%",
                flexShrink: 0,
                fontSize: "12px",
                color: "white",
              }}
            >
              KPI&apos;s
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <KpiCardsCopy />
          </AccordionDetails>
        </Accordion>
        <Box
          sx={{
            display: "grid",
            flexGrow: "1",
            gridTemplateRows: "repeat(2, 300px)",
            gridTemplateColumns: "repeat(1, 1fr)",
            gap: "10px",
            paddingBottom: "10px",
          }}
        >
          {" "}
          <StyledCard2>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                // height: "10%",
              }}
            >
              <HeadingDiv>Inventory Statistics</HeadingDiv>
              <StyledButton onClick={() => navigate("inventory-stats")}>
                <ViewMore>View more data</ViewMore>
                <ArrowForwardIcon sx={{ color: "grey" }} />
              </StyledButton>
            </Box>
            <Box
              sx={{
                display: "grid",
                // height: "90%",
                width: "100%",
                overflow: "hidden",
              }}
            >
              {isLoadingInvStats && (
                <Skeleton
                  variant="rounded"
                  width={componentWidth}
                  height={0.8 * componentHeight}
                  animation="wave"
                />
              )}
              {isSuccessInvStats && (
                <InventoryStatistics
                  componentWidth={componentWidth}
                  snpWidth={snpWidth}
                  data={invStatsData}
                />
              )}
            </Box>
          </StyledCard2>
          <StyledCard2 ref={targetRef}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",

                // height: "10%",
              }}
            >
              <HeadingDiv>Demand Overview</HeadingDiv>
              {/* <StyledButton onClick={() => navigate("demand-overview")}>
                <ViewMore>View more data</ViewMore>
                <ArrowForwardIcon sx={{ color: "grey" }} />
              </StyledButton> */}
            </Box>
            <Box
              sx={{
                display: "grid",
                // height: "90%",
                width: "100%",
              }}
            >
              {isLoadingDemandOverview && (
                <Skeleton
                  variant="rounded"
                  width={componentWidth}
                  height={0.8 * componentHeight}
                  animation="wave"
                />
              )}
              {isSuccessDemandOverview && (
                <DemandOverview
                  componentWidth={componentWidth}
                  snpWidth={snpWidth}
                  data={demandOverviewData}
                />
              )}
            </Box>
          </StyledCard2>
          {/* <StyledCard2>
            <StyledOverlay>
              <StyledComingSoon>Coming soon...</StyledComingSoon>
            </StyledOverlay>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                // height: "10%",
              }}
            >
              <HeadingDiv>Revenue Outlook</HeadingDiv>
            </Box>
            <Box
              sx={{
                display: "grid",
                // height: "90%",
                width: "100%",
                overflow: "hidden",
              }}
            >
              <RevenueOutlook
                componentWidth={componentWidth}
                snpWidth={snpWidth}
              />
            </Box>
          </StyledCard2> */}
          {/* <StyledCard2>
            <StyledOverlay>
              <StyledComingSoon>Coming soon...</StyledComingSoon>
            </StyledOverlay>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                // height: "10%",
              }}
            >
              <HeadingDiv>Performance</HeadingDiv>
            
            </Box>
            <Box
              sx={{
                display: "grid",
                // height: "90%",
                width: "100%",
                marginTop: "10px",
              }}
            >
              <PerformanceIndicator
                title={"Inventory Turnover Ratio"}
                percentage={92.4}
                text={"Actual (14.7)/Target (15.9)"}
              />
              <PerformanceIndicator
                title={"Service Level"}
                percentage={89.7}
                text={"Target: 96%"}
              />
              <PerformanceIndicator
                title={"Fill Rate"}
                percentage={83}
                text={"Target: 90%"}
              />
              <PerformanceIndicator
                title={"OTIF"}
                percentage={91.2}
                text={"Actual (10408)/Target (11438)"}
              />
              <PerformanceIndicator
                title={"Stock Out Rate"}
                percentage={30}
                text={"Target: 2%"}
              />
            </Box>
          </StyledCard2> */}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

const customTheme = createTheme({
  palette: {
    primary: {
      main: "#f5f7fa",
    },
  },
  typography: {
    fontFamily: "Inter, sans-serif",
    fontSize: 12,
    fontWeightBold: 700,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightLight: 300,
    customBoldFont: {
      fontWeight: 600,
      fontSize: 13,
    },
    customLightFont: {
      fontSize: 11,
      fontWeight: 400,
    },
    customHeading: {
      fontWeight: 300,
      fontSize: 15,
    },
  },
  components: {
    MuiDrawer: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});

export default InventoryDashBoard;
