import "./FilterModal.scss";
import "react-datepicker/dist/react-datepicker.css";
import {
  setFilterRules,
  setIsFilterOpen,
  setAllAppliedFilters,
} from "../../features/FiltersSlice";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import Toggle from "./toggleButton";
import { useLocation } from "react-router-dom";
import ModifiedSelect from "./modifiedSelect";
const networkDesc = [
  { type: "	Total Nodes		", value: "	3000	" },
  { type: "	Total Connections		", value: "	4123	" },
  { type: "	Total Nodes Visible		", value: "	975	" },
  { type: "	Total Connections Visible		", value: "	1345	" },
  { type: "	Maximum lead time		", value: "	10 days	" },
  { type: "	Minimum lead time		", value: "	2 days	" },
  { type: "	Average lead time		", value: "	6 days	" },
  { type: "	Maximum order quantity		", value: "	2000 SKUs	" },
  { type: "	Minimum order quantity		", value: "	100 SKUs	" },
  { type: "	Average Order Qty		", value: "	6 days	" },
  { type: "	Modes of Transport		", value: "	Road, Air	" },
  { type: "	Total Sales order quantity		", value: "	5000 SKUs	" },
  { type: "	Total order value		", value: "	₹10000	" },
  { type: "	Total PO Qty		", value: "	5000 SKUs	" },
  { type: "	Total PO Value		", value: "	₹10000	" },
];

const NodeDetails = ({
  isOpen,
  setIsFilterOpen,
  nodeType,
  details = networkDesc,
}) => {
  const dispatch = useDispatch();
  const handleDisplay = isOpen ? "node-details toggle-bar" : "node-details";
  const handleClose = () => {
    dispatch(setIsFilterOpen(false));
  };
  return (
    <div
      id="network-sidebar"
      // className={handleDisplay}
      // style={{ padding: "12px" }}
    >
      {
        <>
          {" "}
          <div className="node-details-header ">
            {/* {nodeType === "Network" && } */}

            {/* {nodeType === "Node" && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    background: "#2F6DF6",
                    width: "43px",
                    height: "38px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "3px",
                  }}
                >
                  <svg
                    width="23"
                    height="18"
                    viewBox="0 0 23 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.9174 3.85714C21.7243 3.85714 21.5263 3.90427 21.3424 4.00825L15.0938 7.71429V4.85759C15.0938 4.29308 14.574 3.8929 14.012 3.8929C13.8189 3.8929 13.621 3.94003 13.437 4.04397L7.1875 7.71429V1.28571C7.1875 0.575759 6.54377 0 5.75 0H1.4375C0.64373 0 0 0.575759 0 1.28571V16.7143C0 17.4242 0.64373 18 1.4375 18H21.5625C22.3563 18 23 17.4242 23 16.7143V4.85759C23 4.25893 22.4789 3.85714 21.9174 3.85714ZM21.5625 16.7504H1.4375V1.32188H5.75V10.1451L13.6562 5.45625V10.1451L21.5625 5.45625V16.7504ZM7.1875 11.6076H4.3125C3.91521 11.6076 3.59375 11.8951 3.59375 12.2504V14.8219C3.59375 15.1754 3.91539 15.4647 4.3125 15.4647H7.1875C7.58479 15.4647 7.90625 15.1772 7.90625 14.8219V12.2504C7.90625 11.8969 7.58281 11.6076 7.1875 11.6076ZM6.46875 14.179H5.03125V12.8933H6.46875V14.179ZM12.9375 11.6076H10.0625C9.66521 11.6076 9.34375 11.8951 9.34375 12.2504V14.8219C9.34375 15.1754 9.66719 15.4647 10.0625 15.4647H12.9375C13.3348 15.4647 13.6562 15.1772 13.6562 14.8219V12.2504C13.6562 11.8969 13.3328 11.6076 12.9375 11.6076ZM12.2188 14.179H10.7812V12.8933H12.2188V14.179ZM15.0938 12.2504V14.8219C15.0938 15.1772 15.4152 15.4647 15.8125 15.4647H18.6875C19.0848 15.4647 19.4062 15.1772 19.4062 14.8219V12.2504C19.4062 11.8951 19.0848 11.6076 18.6875 11.6076H15.8125C15.4172 11.6076 15.0938 11.8969 15.0938 12.2504ZM16.5312 12.8933H17.9688V14.179H16.5312V12.8933Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <h1>WH-N231</h1>
              </div>
            )} */}
            <h1>Network Details</h1>
            <button className="close-btn" onClick={handleClose}>
              <svg
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.85236 9.85334C9.65699 10.0487 9.34087 10.0487 9.14528 9.85334L4.99992 5.70705L0.853623 9.85334C0.658244 10.0487 0.342132 10.0487 0.146534 9.85334C-0.0488447 9.65796 -0.0488447 9.34185 0.146534 9.14625L4.29377 5.00089L0.147159 0.854599C-0.0482198 0.659221 -0.0482198 0.343108 0.147159 0.147511C0.342538 -0.0478682 0.65865 -0.0478682 0.854248 0.147511L4.99992 4.29474L9.14621 0.148448C9.34159 -0.0469308 9.6577 -0.0469308 9.8533 0.148448C10.0487 0.343827 10.0487 0.659939 9.8533 0.855537L5.70607 5.00089L9.85236 9.14719C10.0492 9.34091 10.0492 9.65962 9.85236 9.85334Z"
                  fill="#3D445C"
                />
              </svg>
            </button>
          </div>
          <hr className="node-details-line" />
          <table className="table">
            <tbody style={{ width: "100%" }}>
              {details.map((detail, ind) => (
                <tr
                  key={ind}
                  onMouseEnter={() => {
                    console.log("hovered");
                  }}
                >
                  <td>{detail.type}</td>
                  <td>{detail.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      }
    </div>
  );
};
const FilterModalCommon = ({ dynamicFields, dynamicFields2 }) => {
  const isModalOpen = useSelector((state) => state.filters.isFilterOpen);
  const modalMode = useSelector((state) => state.filters.filterType);
  const appliedFilters = useSelector(
    (state) => state.filters.allAppliedFilters
  );
  console.log({ modalMode });
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname;
  let idArr = path.split("/");
  let pageId = idArr[idArr.length - 1];
  console.log({ idArr });
  console.log({ dynamicFields });
  const handleDisplay = isModalOpen ? "node-details toggle-bar" : "hide-modal";

  console.log({ isModalOpen });
  const handleClose = () => {
    dispatch(setIsFilterOpen(false));
  };

  return (
    <div style={{ padding: "18px 24px" }} className={handleDisplay}>
      {modalMode === "filter" ? (
        <div className="filter-content" style={{ marginBottom: "200px" }}>
          <div className="filter-header">
            <h1>Filters</h1>
            <div className="filter-right-header">
              <button
                className="clear-all-btn"
                onClick={() => {
                  dispatch(setIsFilterOpen(false));
                }}
              >
                Clear all
              </button>
              <button className="clear-all-btn">Apply</button>
              <button className="close-btn" onClick={handleClose}>
                <svg
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.85236 9.85334C9.65699 10.0487 9.34087 10.0487 9.14528 9.85334L4.99992 5.70705L0.853623 9.85334C0.658244 10.0487 0.342132 10.0487 0.146534 9.85334C-0.0488447 9.65796 -0.0488447 9.34185 0.146534 9.14625L4.29377 5.00089L0.147159 0.854599C-0.0482198 0.659221 -0.0482198 0.343108 0.147159 0.147511C0.342538 -0.0478682 0.65865 -0.0478682 0.854248 0.147511L4.99992 4.29474L9.14621 0.148448C9.34159 -0.0469308 9.6577 -0.0469308 9.8533 0.148448C10.0487 0.343827 10.0487 0.659939 9.8533 0.855537L5.70607 5.00089L9.85236 9.14719C10.0492 9.34091 10.0492 9.65962 9.85236 9.85334Z"
                    fill="#3D445C"
                  />
                </svg>
              </button>
            </div>
          </div>
          <hr
            style={{
              borderTop: "1px solid #EBEBEB",
              boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.15)",
              marginBottom: "10px",
            }}
          />
          <h3 className="filter-heading">PRODUCT</h3>
          {/* <fieldset className="filter-item ">
          <legend className="filter-legend">BU</legend>{" "}
          <select className="filter-item-select">
            <option>All</option>
            <option>Option 2</option>
            <option>Option 3</option>
          </select>
        </fieldset> */}
          <ModifiedSelect
            label="BU"
            options={[
              { value: "BU1", label: "BU1" },
              { value: "BU2", label: "BU2" },
            ]}
            isMulti={true}
            setAllAppliedFilters={setAllAppliedFilters}
            key="BU"
          />
          {/* <fieldset className="filter-item ">
          <legend className="filter-legend">Brand</legend>{" "}
          <select className="filter-item-select">
            <option>All</option>
            <option>Option 2</option>
            <option>Option 3</option>
          </select>
        </fieldset>{" "}
        <fieldset className="filter-item ">
          <legend className="filter-legend">Division</legend>{" "}
          <select className="filter-item-select">
            <option>All</option>
            <option>Option 2</option>
            <option>Option 3</option>
          </select>
        </fieldset>{" "}
        <fieldset className="filter-item ">
          <legend className="filter-legend">Category</legend>{" "}
          <select className="filter-item-select">
            <option>All</option>
            <option>Option 2</option>
            <option>Option 3</option>
          </select>
        </fieldset>{" "} */}
          {dynamicFields.length > 0 ? (
            <>
              {/* {JSON.stringify(ruleLabels)} */}
              {dynamicFields.map((item, index) => {
                console.log({ item });
                return item.rule_type === "toggle" ? (
                  <div
                    style={{
                      display: "flex",
                      marginTop: "6px",
                      marginRight: "30px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: 180,
                        borderColor: "#E4E4E4",
                        marginTop: 18,
                        marginLeft: -8,
                      }}
                    >
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={true}
                          onClick={(e) => {
                            let modifiedFilters = {
                              ...appliedFilters,
                              [item.rule_id]: e.target.value,
                            };
                            setAllAppliedFilters(modifiedFilters);
                            console.log("set state here");
                          }}
                        />
                        <span className="slider round" />
                      </label>
                      <div className="schedular-subheader">
                        {item.rule_label}
                      </div>
                    </div>
                  </div>
                ) : item.rule_type === "dropdown" ? (
                  <div>
                    <ModifiedSelect
                      label={item.rule_label}
                      options={item.rule_option.map((item) => ({
                        value: item,
                        label: item,
                      }))}
                      isMulti={true}
                      setAllAppliedFilters={setAllAppliedFilters}
                      key={item.rule_id}
                    />
                  </div>
                ) : null;
              })}
            </>
          ) : null}
          <ModifiedSelect
            label={"SKU"}
            options={[
              {
                value: "sku1",
                label: "sku1",
              },
              {
                value: "sku2",
                label: "sku2",
              },
            ]}
            isMulti={true}
            setAllAppliedFilters={setAllAppliedFilters}
          />
          <hr
            style={{
              borderTop: "1px solid #C4C4C4",
              marginTop: "5px",
              marginBottom: "8px",
            }}
          />
          <h3 className="filter-heading">LOCATION</h3>
          {dynamicFields2.length > 0 ? (
            <>
              <ModifiedSelect
                label="Nodes"
                options={[
                  { label: "Select All", value: "all" },
                  { label: "Node 1", value: "Node 1" },
                  { label: "Node 2", value: "Node 2" },
                ]}
                isMulti={true}
                setAllAppliedFilters={setAllAppliedFilters}
              />
              {dynamicFields2.map((item, index) => {
                return item.rule_type === "toggle" ? (
                  <div
                    style={{
                      display: "flex",
                      // marginTop: "6px",
                      marginRight: "30px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: 180,
                        borderColor: "#E4E4E4",
                        marginTop: 18,
                        marginLeft: -8,
                      }}
                    >
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={true}
                          onClick={(e) => console.log("set state here")}
                        />
                        <span className="slider round" />
                      </label>
                      <div className="schedular-subheader">
                        {item.rule_label}
                      </div>
                    </div>
                  </div>
                ) : item.rule_type === "dropdown" ? (
                  <div>
                    <ModifiedSelect
                      label={item.rule_label}
                      options={[]}
                      isMulti={true}
                      setAllAppliedFilters={setAllAppliedFilters}
                    />
                  </div>
                ) : null;
              })}
            </>
          ) : null}
          <hr
            style={{
              borderTop: "1px solid #C4C4C4",
              marginTop: "5px",
              marginBottom: "8px",
            }}
          />
          <hr
            style={{
              borderTop: "1px solid #C4C4C4",
              marginTop: "5px",
              marginBottom: "8px",
            }}
          />{" "}
          {pageId === "network" ? null : <Toggle />}
          <ModifiedSelect
            label={"Channel"}
            options={[
              {
                value: "sku1",
                label: "sku1",
              },
              {
                value: "sku2",
                label: "sku2",
              },
            ]}
            isMulti={true}
            setAllAppliedFilters={setAllAppliedFilters}
          />{" "}
          {pageId === "network" ? (
            <ModifiedSelect
              label="Mode of Transport"
              options={[{ value: "air", label: "air" }]}
              isMulti={true}
              setAllAppliedFilters={setAllAppliedFilters}
            />
          ) : null}
        </div>
      ) : (
        <NodeDetails isOpen={isModalOpen} setIsFilterOpen={setIsFilterOpen} />
      )}
    </div>
  );
};

export default FilterModalCommon;
