import "./legend.scss";

function Legend({ legendData }) {
  return (
    <>
      <div className="legend-container">
        {legendData.map((item, index) => (
          <div key={index} className="legend-item">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
            >
              <circle cx="6" cy="6" r="5" stroke={item.color} strokeWidth="2" />
            </svg>
            <span className="legend-label">{item.label}</span>
          </div>
        ))}
      </div>
    </>
  );
}

export default Legend;
