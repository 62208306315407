const getDisplayNameProcessType = (type, processType, userType) => {
  if (processType === "classification") {
    return `Classification - ${userType}`;
  } else if (processType === "norms") {
    let processPath = "";
    if (type === "msl") {
      processPath = `Max Stock Level - ${userType}`;
    } else if (type === "misl") {
      processPath = `Min Stock Level - ${userType}`;
    } else if (type === "dos") {
      processPath = `Days of Stock (DOS) - ${userType}`;
    } else if (type === "ss") {
      processPath = `Safety Stock - ${userType}`;
    }
    return processPath;
  }
  return "";
};

export function getColumns({ skipViewQuery, type, processType }) {
  let columns = [
    {
      accessorKey: "skuName",
      header: "SKU Name",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "skuCode",
      header: "SKU Code",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "channelName",
      header: "Channel Name",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "nodeName",
      header: "Node Name",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "nodeCode",
      header: "Node Code",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
  ];
  let profilingCols = [
    ...columns,
    {
      accessorKey: "inventoryLevel",
      header: "Inventory Level",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "lostSales",
      header: "Lost Sales",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "inventoryPosition",
      header: "Inventory Position",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "date",
      header: "Date",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
  ];
  let viewCols = [
    ...columns,
    {
      accessorKey: "classification",
      header: getDisplayNameProcessType(type, processType, "System"),
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
    {
      accessorKey: "classification1",
      header: getDisplayNameProcessType(type, processType, "User"),
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: true,
      Cell: ({ cell, row, column }) => {
        return (
          <div
            className={
              row.original.isDisabled ? "disabled-cell" : "active-cell"
            }
          >
            {cell.getValue()}
          </div>
        );
      },
    },
  ];
  if (skipViewQuery) {
    return profilingCols;
  }
  return viewCols;
}
