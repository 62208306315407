import {
  ArrowDownIcon,
  ArrowUpIcon,
  CaretSortIcon,
  EyeNoneIcon,
} from "@radix-ui/react-icons";

import { Filter } from "lucide-react";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { DataTableFacetedFilter } from "./data-table-faceted-filter";
import { forwardRef } from "react";

export function DataTableColumnHeader({
  column,
  title,
  className,
  options,
  header,
}) {
  if (!column.getCanSort()) {
    return <div className={cn(className)}>{title}</div>;
  }

  // console.log(header.getSize());

  return (
    <div
      className={cn("flex items-center justify-center", className)}
      // style={{ width: `${header.getSize()}` }}
    >
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            size="sm"
            className="ml-3 h-full data-[state=open]:bg-[#b0b0b0] px-1 lg:px-1 hover:bg-[#b0b0b0]"
          >
            <span className="font-semibold font-sans lg:text-[1.4rem] antialiased text-[15px]">
              {title}
            </span>
            {title === "" ? null : column.getIsSorted() === "desc" ? (
              <ArrowDownIcon className="ml-2 h-4 w-4 lg:h-6 lg:w-6 lg:ml-3" />
            ) : column.getIsSorted() === "asc" ? (
              <ArrowUpIcon className="ml-2 h-4 w-4 lg:h-6 lg:w-6 lg:ml-3" />
            ) : (
              <CaretSortIcon className="ml-2 h-4 w-4 lg:h-6 lg:w-6 lg:ml-3" />
            )}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="start" className="lg:h-auto lg:w-[120px]">
          <DropdownMenuItem
            onClick={() => column.toggleSorting(false)}
            className="lg:text-xl justify-start lg:ml-3"
          >
            <ArrowUpIcon className="mr-2 h-3.5 w-3.5  lg:mr-3 lg:h-5 lg:w-5" />
            Asc
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => column.toggleSorting(true)}
            className="lg:text-xl justify-start lg:ml-3"
          >
            <ArrowDownIcon className="mr-2 h-3.5 w-3.5  lg:mr-3 lg:h-5 lg:w-5" />
            Desc
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            onClick={() => column.toggleVisibility(false)}
            className="lg:text-xl justify-start lg:ml-3"
          >
            <EyeNoneIcon className="mr-2 h-3.5 w-3.5  lg:mr-3 lg:h-5 lg:w-5" />
            Hide
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      {title !== "" && (
        <DataTableFacetedFilter
          column={column}
          title={title}
          options={options}
        />
      )}
    </div>
  );
}