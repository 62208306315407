export const getCommonPinningStyles = (column, tableElement) => {
  const isPinned = column.getIsPinned();
  const isLastLeftPinnedColumn =
    isPinned === "left" && column.getIsLastColumn("left");
  const isFirstRightPinnedColumn =
    isPinned === "right" && column.getIsFirstColumn("right");

  const getBoxShadow = () => {
    if (isLastLeftPinnedColumn && tableElement === "th") {
      return "-4px 0 4px -4px rgba(0,0,0,0.3) inset, inset 0px -1px 0px 0px rgba(0,0,0,0.1)";
    }
    if (isFirstRightPinnedColumn && tableElement === "th") {
      return "4px 0 4px -4px rgba(0,0,0,0.3) inset, inset -1px -1px 0px 0px rgba(0,0,0,0.1)";
    }
    if (isLastLeftPinnedColumn && tableElement === "td") {
      return "-4px 0 4px -4px rgba(0,0,0,0.3) inset";
    }
    if (isFirstRightPinnedColumn && tableElement === "td") {
      return "4px 0 4px -4px rgba(0,0,0,0.3) inset";
    }
  };
  const getZIndex = () => {
    if (tableElement === "th") {
      return isPinned ? 10 : 5;
    }
    if (tableElement === "td") {
      return isPinned ? 8 : 0;
    }
  };

  return {
    boxShadow: getBoxShadow(),
    left: isPinned === "left" ? `${column.getStart("left")}px` : undefined,
    right: isPinned === "right" ? `${column.getAfter("right")}px` : undefined,
    opacity: isPinned ? 1 : 1,
    position: "sticky",
    width: column.getSize(),
    zIndex: getZIndex(),
  };
};

export function transformRenderedCellvalue(input) {
  const options = {
    day: "numeric",
    month: "short",
    year: "numeric",
  };
  if (input instanceof Date) {
    return input.toLocaleDateString("en-GB", options);
  }
  return input;
}

export function removeDuplicateKeys(obj) {
  const result = {};
  const seenKeys = new Set();
  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      const baseKey = key.split(".")[0];
      if (!seenKeys.has(baseKey)) {
        result[key] = obj[key];
        seenKeys.add(baseKey);
      }
    }
  }
  return result;
}
