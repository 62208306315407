import { Box } from "@mui/material";
import CustomStepper from "../stepper/CustomStepper";
import { useEffect, useState } from "react";
import {
  useCreatePlanMutation,
  useValidateCreatePlanMutation,
} from "@/features/api/ordersManagementSlice";
import { handleApiResponse } from "@/utils/apiUtils";
import { toast } from "react-toastify";
import { useJobCategoryQuery } from "@/features/api/schedularSlice";
import { TableLoaderSpinner } from "../tableUtils/tableUtils";

const CreatePlan = ({
  step,
  setStep,
  openModal,
  setOpenModal,
  selectedSTO,
  refetch,
  setClearRowSelection,
}) => {
  const [groupCode, setGroupCode] = useState("Select Group");
  const [planDate, setPlanDate] = useState("");
  const [groupCodesList, setgroupCodesList] = useState([]);
  const [generatedData, setgeneratedData] = useState({
    validOrders: 0,
    invalidOrders: 0,
    totalOrders: 0,
    planId: "",
    orders: [],
  });
  const [skip, setSkip] = useState(true);

  const {
    data: groupCodesData,
    error: errorGroupData,
    isLoading: loadingGroupData,
  } = useJobCategoryQuery(undefined, {
    skip: skip,
    refetchOnMountOrArgChange: true,
    force: true, // This will bypass the cache
  });

  const [validateCreatePlan, { isLoading: loadingValidateCreatePlan }] =
    useValidateCreatePlanMutation();

  const [createPlan, { isLoading: loadingCreatePlan }] =
    useCreatePlanMutation();

  useEffect(() => {
    if (
      groupCodesData &&
      groupCodesData.results &&
      groupCodesData.results.length > 0
    ) {
      let tempGroupCodes = [];
      groupCodesData.results.forEach((group) => {
        if (group?.module?.name === "RP") {
          let temp = [group.id, group.name];
          tempGroupCodes.push(temp);
          console.log({ tempGroupCodes });
        }
      });
      setgroupCodesList([...tempGroupCodes]);
      setSkip(true);
    }
  }, [groupCodesData]);

  const handleGroupCode = (e) => {
    let splitGroupCode = e.target.value.split(",");
    setGroupCode([...splitGroupCode]);
  };

  const handlePlanDate = (e) => {
    setPlanDate(e.target.value);
  };

  const handleValidatePlan = async () => {
    const requestBody = {
      orders: selectedSTO,
      group: groupCode[0],
    };
    try {
      const response = await validateCreatePlan({
        requestBody: requestBody,
      });
      if (response.error) {
        if (
          response.error.originalStatus &&
          response.error.originalStatus === 500
        ) {
          throw new Error("Something went wrong.!");
        } else {
          throw new Error(response.error.data.message);
        }
      } else {
        let ordersData = {
          validOrders: response.data.data.valid_orders,
          invalidOrders: response.data.data.invalid_orders,
          totalOrders: response.data.data.total_orders,
          planId: response.data.data.new_plan_id,
          orders: response.data.data.orders,
        };
        setgeneratedData({ ...ordersData });
        // toast.success(response.data.success, { autoClose: 3000 });
      }
    } catch (error) {
      toast.error(error.message, { autoClose: 3000 });
    }
    setStep(2);
  };

  const handleCreatePlan = async () => {
    const requestBody = {
      orders: generatedData.orders,
      new_plan_id: generatedData.planId,
      group: groupCode[0],
      plan_date: planDate,
    };
    try {
      const response = await createPlan({
        requestBody: requestBody,
      });
      if (response.error) {
        if (
          response.error.originalStatus &&
          response.error.originalStatus === 500
        ) {
          throw new Error("Something went wrong.!");
        } else {
          throw new Error(response.error.data.message);
        }
      } else {
        toast.success(response.data.message, { autoClose: 3000 });
        setGroupCode("");
        setPlanDate("");
        setClearRowSelection(true);
        refetch();
        setOpenModal(false);
      }
    } catch (error) {
      toast.error(error.message, { autoClose: 3000 });
    }
  };

  return (
    <>
      {" "}
      {step === 1 && (
        <div className="upload-modal upload-send-modal orders-mgmt-modal h-auto">
          <div className="orders-mgmt-modal-header">
            <h1 className="orders-mgmt-modal-heading">Create Plan</h1>
            <hr className="orders-mgmt-modal-line"></hr>
            <div className="orders-mgmt-stepper">
              <Box sx={{ width: "100%" }}>
                <CustomStepper step={step} />
              </Box>
            </div>
          </div>
          <div className="orders-mgmt-modal-body">
            <h1 className="orders-mgmt-orders-selected">{`${selectedSTO.length} Orders Selected`}</h1>
            <div>
              <select
                className="orders-mgmt-select-box"
                style={{ border: " 1px solid #b3b3b3" }}
                onFocus={() => setSkip(false)}
                onChange={handleGroupCode}
                value={groupCode}
              >
                {loadingGroupData ? (
                  <option>loading...</option>
                ) : (
                  <>
                    <option disabled selected hidden value="Select Group">
                      Select Group
                    </option>
                    {groupCodesList.map((item, index) => (
                      <option key={index} value={item}>
                        {item[1]}
                      </option>
                    ))}
                  </>
                )}
              </select>
              <input
                type="date"
                style={{ border: " 1px solid #b3b3b3" }}
                className="orders-mgmt-select-box "
                placeholder="Select Plan Date"
                value={planDate}
                onChange={handlePlanDate}
              />
            </div>
          </div>
          <div>
            <div className="orders-mgmt-modal-footer">
              <button
                onClick={handleValidatePlan}
                style={{ minWidth: "66.5px" }}
                className="create-plan-next-btn"
                disabled={planDate === "" && groupCode === "Select Group"}
              >
                {loadingValidateCreatePlan ? (
                  <div
                    style={{
                      paddingTop: "2.5px",
                      paddingBottom: "2.5px",
                    }}
                  >
                    <TableLoaderSpinner size={12} height="2vh" />
                  </div>
                ) : (
                  "Next"
                )}
              </button>
            </div>
          </div>
        </div>
      )}
      {step === 2 && (
        <div className="upload-modal orders-mgmt-modal h-auto">
          <div className="orders-mgmt-modal-header">
            <h1 className="orders-mgmt-modal-heading">Create Plan</h1>
            <hr className="orders-mgmt-modal-line"></hr>
            <div className="orders-mgmt-stepper">
              <CustomStepper step={step} />
            </div>
          </div>
          <div className="orders-mgmt-modal-body">
            <h1 className="orders-mgmt-orders-selected">{`${generatedData.totalOrders} Orders Selected`}</h1>
            <div>
              <select
                className="orders-mgmt-select-box"
                disabled
                value={groupCode[1]}
              >
                <option disabled selected>
                  {groupCode[1]}
                </option>
              </select>
              <input
                type="date"
                style={{ border: " 1px solid #b3b3b3" }}
                className="orders-mgmt-select-box "
                value={planDate}
                disabled
              />
            </div>
            <div className="create-plan-step2-summary">
              <div className="create-plan-step2-header">
                <p>Summary</p>
                <p>21-04-2023, 12:@6</p>
              </div>
              <div className="create-plan-step2-order-tabs">
                <div className="create-plan-order-values">
                  <button>{generatedData.totalOrders}</button>
                  <button>{generatedData.validOrders}</button>
                  <button>{generatedData.invalidOrders}</button>
                </div>
                <button className="create-plan-step2-download">
                  {" "}
                  <svg
                    width="11"
                    height="11"
                    viewBox="0 0 11 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 1.5V0.5L0 0.5L0 1.5L4.95 1.5L4.95 8.585L1.925 5.835L1.144 6.54L5.5 10.5L9.856 6.54L9.075 5.835L6.05 8.585L6.05 1.5L11 1.5Z"
                      fill="white"
                    />
                  </svg>
                </button>
              </div>
              <p className="create-plan-success-data-heading">Success</p>
              <span className="create-plan-success-data">
                {`-${generatedData.validOrders} Orders Successfully accepted`}
              </span>
              <p className="create-plan-success-data-heading">Conflicts</p>
              <div className="flex flex-col">
                {" "}
                <span className="create-plan-conficts-data">
                  {`-${generatedData.invalidOrders} Orders are not completed with mandatory fields`}
                </span>
                {/* <span className="create-plan-conficts-data">
                  -2 Orders have a conflict with Group Code
                </span>
                <span className="create-plan-conficts-data">
                  -8 Orders have a conflict with Plan Date
                </span> */}
              </div>
            </div>
          </div>
          <div>
            <div className="orders-mgmt-modal-footer create-plan-footer">
              <button
                onClick={() => setStep(1)}
                className="create-plan-prev-btn"
              >
                Previous
              </button>
              <button
                className="create-plan-next-btn"
                onClick={() => setStep(3)}
              >
                Skips Errrors & Continue
              </button>
            </div>
          </div>
        </div>
      )}
      {step === 3 && (
        <div className="upload-modal orders-mgmt-modal create-plan-modal-3">
          <div className="orders-mgmt-modal-header">
            <h1 className="orders-mgmt-modal-heading">Create Plan</h1>
            <hr className="orders-mgmt-modal-line"></hr>
            <div className="orders-mgmt-stepper">
              <CustomStepper step={step} />
            </div>
          </div>
          <div className="orders-mgmt-modal-body create-plan-body">
            <p className="create-plan-step3-text">
              The Plan will be created with the <br />
              <span className="create-plan-step3-plan-text">
                {`${generatedData.planId}.`}
              </span>
              <button>
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.34385 9.43732C6.15479 9.43732 6.00011 9.59201 6.00011 9.78107V10.1248C6.00011 10.5044 5.69224 10.8123 5.31262 10.8123H1.87519C1.49557 10.8123 1.1877 10.5044 1.1877 10.1248V4.62492C1.1877 4.2453 1.49557 3.93743 1.87519 3.93743H4.28139C4.47045 3.93743 4.62513 3.78275 4.62513 3.59369C4.62513 3.40463 4.47045 3.24995 4.28139 3.24995H1.87497C1.11552 3.24995 0.5 3.86546 0.5 4.62492L0.500211 10.1248C0.500215 10.8832 1.11573 11.4998 1.87519 11.4998H5.31262C6.07208 11.4998 6.68759 10.8843 6.68759 10.1248V9.78107C6.68759 9.59201 6.53291 9.43732 6.34385 9.43732ZM11.2981 2.36115L9.63842 0.701519C9.51059 0.572422 9.33442 0.5 9.1518 0.5H6.68759C5.92921 0.5 5.31262 1.11552 5.31262 1.87497V7.37487C5.31262 8.13432 5.92814 8.74984 6.68759 8.74984H10.125C10.8845 8.74984 11.5 8.13432 11.5 7.37487V2.8482C11.5 2.66558 11.427 2.49006 11.2981 2.36115ZM9.43754 1.47215L10.5268 2.56246H9.43754V1.47215ZM10.8125 7.37487C10.8125 7.75449 10.5046 8.06235 10.125 8.06235H6.68759C6.30797 8.06235 6.00011 7.75449 6.00011 7.37487V1.87497C6.00011 1.49535 6.30797 1.18749 6.68759 1.18749H8.75005L8.75011 2.56246C8.75011 2.94208 9.05797 3.24995 9.4376 3.24995H10.8125V7.37487Z"
                    fill="#7A7A7A"
                  />
                </svg>
              </button>
              <br />
              Are you sure you want to proceed..?
            </p>
          </div>
          <div>
            <div className="orders-mgmt-modal-footer create-plan-footer">
              <button
                onClick={() => setStep(2)}
                className="create-plan-prev-btn"
              >
                Previous
              </button>
              <div className="create-plan-step3-btns-box">
                <button
                  className="create-plan-prev-btn"
                  style={{ marginRight: "8px" }}
                  onClick={() => {
                    setGroupCode("");
                    setPlanDate("");
                    setOpenModal(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  className="create-plan-next-btn"
                  style={{ minWidth: "85px" }}
                  onClick={handleCreatePlan}
                >
                  {loadingCreatePlan ? (
                    <div
                      style={{
                        paddingTop: "2.5px",
                        paddingBottom: "2.5px",
                      }}
                    >
                      <TableLoaderSpinner size={12} height="2vh" />
                    </div>
                  ) : (
                    "Confirm"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CreatePlan;
