const ExpandIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 128 128"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M41.1429 0H4.57143C2.04457 0 0 2.04571 0 4.57143V41.1429C0 43.6571 2.04457 45.7143 4.57143 45.7143C7.09829 45.7143 9.14286 43.6571 9.14286 41.1429V9.14286H41.1429C43.6571 9.14286 45.7143 7.09714 45.7143 4.57143C45.7143 2.04571 43.6571 0 41.1429 0ZM41.1429 118.857H9.14286V86.8571C9.14286 84.3429 7.09714 82.2857 4.57143 82.2857C2.04571 82.2857 0 84.3429 0 86.8571V123.429C0 125.943 2.04457 128 4.57143 128H41.1429C43.6571 128 45.7143 125.943 45.7143 123.429C45.7143 120.914 43.6571 118.857 41.1429 118.857ZM123.429 82.2857C120.902 82.2857 118.857 84.3303 118.857 86.8571V118.857H86.8571C84.3303 118.857 82.2857 120.902 82.2857 123.429C82.2857 125.955 84.3303 128 86.8571 128H123.429C125.955 128 128 125.955 128 123.429V86.8571C128 84.3429 125.943 82.2857 123.429 82.2857ZM123.429 0H86.8571C84.3429 0 82.2857 2.04571 82.2857 4.57143C82.2857 7.09714 84.3429 9.14286 86.8571 9.14286H118.857V41.1429C118.857 43.6571 120.914 45.7143 123.429 45.7143C125.943 45.7143 128 43.6571 128 41.1429V4.57143C128 2.04571 125.943 0 123.429 0Z"
      fill="#3D445C"
    />
  </svg>
);

export const ExpandIconClose = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 128 128"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M41.1429 82.2857H4.57143C2.04457 82.2857 0 84.3429 0 86.8571C0 89.3714 2.04457 91.4286 4.57143 91.4286H36.5714V123.429C36.5714 125.943 38.6286 128 41.1429 128C43.6571 128 45.7143 125.943 45.7143 123.429V86.8571C45.7143 84.3429 43.6571 82.2857 41.1429 82.2857ZM86.8571 45.7143H123.429C125.943 45.7143 128 43.6571 128 41.1429C128 38.6286 125.943 36.5714 123.429 36.5714H91.4286V4.57143C91.4286 2.04571 89.3714 0 86.8571 0C84.3429 0 82.2857 2.04571 82.2857 4.57143V41.1429C82.2857 43.6571 84.3429 45.7143 86.8571 45.7143ZM123.429 82.2857H86.8571C84.3303 82.2857 82.2857 84.3303 82.2857 86.8571V123.429C82.2857 125.955 84.3303 128 86.8571 128C89.384 128 91.4286 125.955 91.4286 123.429V91.4286H123.429C125.955 91.4286 128 89.384 128 86.8571C128 84.3303 125.943 82.2857 123.429 82.2857ZM41.1429 0C38.6286 0 36.5714 2.04571 36.5714 4.57143V36.5714H4.57143C2.04457 36.5714 0 38.6286 0 41.1429C0 43.6571 2.04457 45.7143 4.57143 45.7143H41.1429C43.6571 45.7143 45.7143 43.6571 45.7143 41.1429V4.57143C45.7143 2.04571 43.6571 0 41.1429 0Z"
      fill="#3D445C"
    />
  </svg>
);

export default ExpandIcon;
