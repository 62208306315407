import { useSelector } from "react-redux";

const PlantAllocation = ({
  row,
  columnKey,
  widthValue,
  transformRenderedCellvalue,
}) => {
  const editedSTOList = useSelector(
    (state) => state.productionPlan.editedSTOList
  );
  const isWPOEdited = editedSTOList[row.original.wpo_id] ? true : false;

  const getEditedValuesSum = () => {
    return editedSTOList[row.original.wpo_id].reduce(
      (sum, item) => sum + item.edited_qty,
      0
    );
  };

  return (
    <div
      className="antialiased truncate text-center"
      style={{ width: widthValue, minWidth: "150px" }}
    >
      {isWPOEdited
        ? getEditedValuesSum()
        : transformRenderedCellvalue(row.getValue(columnKey))}
    </div>
  );
};

export default PlantAllocation;
