import "./InputStyles.css";

const TextInput = ({
  label,
  inputValue,
  placeholderValue,
  handleInputValue,
  inputType,
  required = false,
  errorMsg = null,
  name = null,
  setErrors = null,
}) => {
  const handleTextInput = (e) => {
    if (inputType === "number") {
      let val = e.target.value;
      let numericValue = Math.round(val);
      if (numericValue < 0 || isNaN(numericValue)) {
        val = "";
      }
      if (
        name === "week1Split" ||
        name === "week2Split" ||
        name === "week3Split" ||
        name === "week4Split"
      ) {
        if (numericValue === 0) {
          setErrors((prev) => ({
            ...prev,
            [name]: `${label} must be greater than 0`,
          }));
        } else if (isNaN(numericValue)) {
          setErrors((prev) => ({
            ...prev,
            [name]: `${label} cannot be empty`,
          }));
        } else if (numericValue > 100) {
          setErrors((prev) => ({
            ...prev,
            [name]: `${label} must be less than or equal to 100`,
          }));
        } else {
          setErrors((prev) => ({
            ...prev,
            [name]: "",
          }));
        }
        handleInputValue(numericValue);
      } else {
        if (numericValue > 99999999999.9999) {
          setErrors((prev) => ({
            ...prev,
            [name]: `Enter up to 11 digits before and 3 digits after the decimal`,
          }));
        } else if (isNaN(numericValue)) {
          setErrors((prev) => ({
            ...prev,
            [name]: `${label} cannot be empty`,
          }));
        } else if (numericValue === 0) {
          setErrors((prev) => ({
            ...prev,
            [name]: `${label} must be greater than 0`,
          }));
        } else {
          setErrors((prev) => ({
            ...prev,
            [name]: "",
          }));
        }
        handleInputValue(numericValue);
      }
    } else {
      let textValue = e.target.value;
      if (textValue.length > 500) {
        setErrors((prev) => ({
          ...prev,
          [name]: ` Comments is limited to a maximum of 500 characters`,
        }));
      } 
      // else if (textValue === "") {
      //   setErrors((prev) => ({
      //     ...prev,
      //     [name]: ` Comments cannot be empty`,
      //   }));
      // }
       else {
        setErrors((prev) => ({
          ...prev,
          [name]: "",
        }));
      }
      handleInputValue(textValue);
    }
  };
  return (
    <div className="add-sto-input-box">
      <label className="add-sto-input-label">
        {label}
        {required && <span className="required-asterisk">*</span>}
      </label>
      <input
        type={inputType}
        className="add-sto-select-box add-sto-input-text"
        placeholder={placeholderValue}
        value={inputValue}
        onChange={(e) => handleTextInput(e)}
        min={inputType === "number" ? 0 : null}
      />
      <div className="error">{errorMsg}</div>
    </div>
  );
};

export default TextInput;
