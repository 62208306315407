import { useState, useEffect, useRef, createRef } from "react";
import MuiTable from "../../../components/muiTable/muiTable";
import { useLocation, useNavigate } from "react-router-dom";
import { ThemeProvider, Typography, createTheme } from "@mui/material";
import "./styles.css";
import { KpiCardsCopy2 } from "../../../components/kpi_cards_copy/kpicardsCopy2";
import { detailOrgGroupData } from "./Tables/data";
import { detailOrgCols } from "./Tables/columns";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../../utils/components";

function RMOrgLevelPlanView() {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  let pathSplit = path.split("/");
  let len = pathSplit.length;
  const cycleIdParam = pathSplit[len - 1];

  const [tableData, setTableData] = useState([]);
  const [stateColumnValues, setStateColumnValues] = useState([]);
  const [expanded, setExpanded] = useState(false);

  const reasonArray = [
    (row1) => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            // border: "1px solid red",
            width: "100%",
            flexGrow: "1",
          }}
          // className="active-cell"
        >
          <p className="active-cell">Ider used to...</p>
          <button
            style={{
              background: "transparent",
              padding: "0",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              border: "none",
            }}
            className="info-button"
          >
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              r="5"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13 6.5C13 10.0899 10.0899 13 6.5 13C2.91015 13 0 10.0899 0 6.5C0 2.91015 2.91015 0 6.5 0C10.0899 0 13 2.91015 13 6.5ZM7.3125 3.25C7.3125 3.69873 6.94873 4.0625 6.5 4.0625C6.05127 4.0625 5.6875 3.69873 5.6875 3.25C5.6875 2.80127 6.05127 2.4375 6.5 2.4375C6.94873 2.4375 7.3125 2.80127 7.3125 3.25ZM5.6875 5.6875C5.23877 5.6875 4.875 6.05127 4.875 6.5C4.875 6.94873 5.23877 7.3125 5.6875 7.3125V9.75C5.6875 10.1987 6.05127 10.5625 6.5 10.5625H7.3125C7.76123 10.5625 8.125 10.1987 8.125 9.75C8.125 9.30127 7.76123 8.9375 7.3125 8.9375V6.5C7.3125 6.05127 6.94873 5.6875 6.5 5.6875H5.6875Z"
                fill="#A3AAC2"
              />
            </svg>
          </button>
        </div>
      );
    },
  ];

  function DynamicRefsExample(
    numberOfRefs,
    componentArray,
    cell,
    row,
    centerAlign
  ) {
    const refsArray = useRef(
      Array.from({ length: numberOfRefs }, () => createRef())
    );
    return (
      <div className={!centerAlign ? "action-buttons" : "action-buttons-align"}>
        {Array.from({ length: numberOfRefs }, (_, index) => {
          const Comp = componentArray[index];

          return (
            <div
              id={index}
              key={index}
              ref={refsArray.current[index]}
              placeholder={`Ref ${index + 1}`}
              //   onClick={() => actionsArray[index](row)}
              style={{
                pointerEvents: row.original.isDisabled ? "none" : "auto",
                width: "100%",
              }}
            >
              <Comp row={row.original} />
            </div>
          );
        })}
      </div>
    );
  }

  useEffect(() => {
    setTableData(detailOrgGroupData ? detailOrgGroupData : []);
  }, [detailOrgGroupData]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <ThemeProvider theme={customTheme}>
      <div style={{ width: `calc(100% - 20px)`, margin: 10 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            alignSelf: "center",
            marginTop: "12px",
            marginBottom: "12px",
          }}
          className="process-header"
        >
          <div
            className="process-header-1"
            onClick={() => navigate("/snp/raw-material/plan")}
          >
            ORG Level
          </div>
          <div>
            <svg
              width="9"
              height="9"
              viewBox="0 0 9 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L4.5 4.5L1 8"
                stroke="#858585"
                strokeLinecap="round"
              />
              <path
                d="M4.5 1L8 4.5L4.5 8"
                stroke="#929292"
                strokeLinecap="round"
              />
            </svg>
          </div>
          <div className="process-header-2">{`Plan View : ${cycleIdParam}`}</div>
        </div>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            expanded={expanded === "panel1"}
            fill="white"
            hoverColor="#e94f1c"
            backgroundColor="#494949"
            expandedBgColor="#e94f1b"
          >
            <Typography
              sx={{
                width: "33%",
                flexShrink: 0,
                fontSize: "12px",
                color: "white",
              }}
            >
              KPI&apos;s
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <KpiCardsCopy2 />
          </AccordionDetails>
        </Accordion>
        <div
          className="snp-table-container"
          // style={{ width: `calc( 100% - 20px)`, margin: 10 }}
        >
          <MuiTable
            columns={detailOrgCols()}
            td={tableData}
            createPath="/js-create"
            shouldShowAddBtn={false}
            showDownloadIcon={true}
          />
        </div>
      </div>
    </ThemeProvider>
  );
}

const customTheme = createTheme({
  palette: {
    primary: {
      main: "#f5f7fa",
    },
  },
  typography: {
    fontFamily: "Inter, sans-serif",
    fontSize: 12,
    fontWeightBold: 700,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightLight: 300,
    customBoldFont: {
      fontWeight: 600,
      fontSize: 13,
    },
    customLightFont: {
      fontSize: 11,
      fontWeight: 400,
    },
    customHeading: {
      fontWeight: 300,
      fontSize: 15,
    },
  },
  components: {
    MuiDrawer: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});

export default RMOrgLevelPlanView;
