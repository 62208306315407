import React from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

export default function KpiCards() {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.fontWeightLight,
    marginBottom: "10px",
    boxShadow: "none",
    color: theme.palette.text.secondary,
    fontSize: "12px",
    fontWeight: "400",
    background: "transparent",
  }));

  const StyledCard1 = styled(Grid)(({ theme }) => ({
    ...theme.typography.body1,
    border: "1px solid #FFAB8A",
    boxShadow: "0px 4px 4px 0px rgba(174, 174, 174, 0.25)",
    borderRadius: "6px",
    padding: theme.spacing(1),
    background: " #F6F6F6"
  }));

  const BoldDiv = styled("div")(({ theme }) => ({
    ...theme.typography.customBoldFont,
  }));

  const LightDiv = styled("div")(({ theme }) => ({
    ...theme.typography.customLightFont,
  }));

  return (
    <Grid container sx={{ columnGap: "15px", marginBottom: "10px" }}>
      <StyledCard1 item xs>
        <Item>Inventory In Hand</Item>
        <Box display={"flex"} sx={{ justifyContent: "space-between" }}>
          <div>
            <BoldDiv>28479</BoldDiv>
            <LightDiv>Volume</LightDiv>
          </div>
          <div>
            <BoldDiv>₹233035</BoldDiv>
            <LightDiv>Value</LightDiv>
          </div>
        </Box>
      </StyledCard1>
      <StyledCard1 item xs>
        <Item>Inventory In Transit</Item>
        <Box display={"flex"} sx={{ justifyContent: "space-between" }}>
          <div>
            <BoldDiv>25000</BoldDiv>
            <LightDiv>Volume</LightDiv>
          </div>
          <div>
            <BoldDiv>₹103067</BoldDiv>
            <LightDiv>Value</LightDiv>
          </div>
        </Box>
      </StyledCard1>
      <StyledCard1 item xs>
        <Item>Days of Supply</Item>
        <Box display={"flex"} sx={{ justifyContent: "space-between" }}>
          <div>
            <BoldDiv>42 days</BoldDiv>
            <LightDiv>Target</LightDiv>
          </div>
          <div>
            <BoldDiv>35 days</BoldDiv>
            <LightDiv>Actual</LightDiv>
          </div>
        </Box>
      </StyledCard1>
      <StyledCard1 item xs>
        <Item>Turnover Ratio</Item>
        <Box display={"flex"} sx={{ justifyContent: "space-between" }}>
          <div>
            <BoldDiv>13.4</BoldDiv>
            <LightDiv>TR</LightDiv>
          </div>
          <div>
            <BoldDiv>14.5</BoldDiv>
            <LightDiv>Target</LightDiv>
          </div>
        </Box>
      </StyledCard1>
      <StyledCard1 item xs>
        <Item>Metric</Item>
        <Box display={"flex"} sx={{ justifyContent: "space-between" }}>
          <div>
            <BoldDiv>--</BoldDiv>
            <LightDiv>Volume</LightDiv>
          </div>
          <div>
            <BoldDiv>--</BoldDiv>
            <LightDiv>Value</LightDiv>
          </div>
        </Box>
      </StyledCard1>
    </Grid>
  );
}
