import "./FilterModal.scss";
import "react-datepicker/dist/react-datepicker.css";

const FilterModal = ({ setIsOpen }) => {
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div style={{ padding: "18px 24px" }}>
      <div className="filter-content">
        <div className="filter-header">
          <h1>Filters</h1>
          <div className="filter-right-header">
            <button
              className="clear-all-btn"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              Clear all
            </button>
            <button className="clear-all-btn">Apply</button>
            <button className="close-btn" onClick={handleClose}>
              <svg
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.85236 9.85334C9.65699 10.0487 9.34087 10.0487 9.14528 9.85334L4.99992 5.70705L0.853623 9.85334C0.658244 10.0487 0.342132 10.0487 0.146534 9.85334C-0.0488447 9.65796 -0.0488447 9.34185 0.146534 9.14625L4.29377 5.00089L0.147159 0.854599C-0.0482198 0.659221 -0.0482198 0.343108 0.147159 0.147511C0.342538 -0.0478682 0.65865 -0.0478682 0.854248 0.147511L4.99992 4.29474L9.14621 0.148448C9.34159 -0.0469308 9.6577 -0.0469308 9.8533 0.148448C10.0487 0.343827 10.0487 0.659939 9.8533 0.855537L5.70607 5.00089L9.85236 9.14719C10.0492 9.34091 10.0492 9.65962 9.85236 9.85334Z"
                  fill="#3D445C"
                />
              </svg>
            </button>
          </div>
        </div>
        <hr
          style={{
            borderTop: "1px solid #EBEBEB",
            boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.15)",
            marginBottom: "10px",
          }}
        />
        <h3 className="filter-heading">PRODUCT</h3>
        <fieldset className="filter-item ">
          <legend className="filter-legend">BU</legend>{" "}
          <select className="filter-item-select">
            <option>All</option>
            <option>Option 2</option>
            <option>Option 3</option>
          </select>
        </fieldset>
        <fieldset className="filter-item ">
          <legend className="filter-legend">Brand</legend>{" "}
          <select className="filter-item-select">
            <option>All</option>
            <option>Option 2</option>
            <option>Option 3</option>
          </select>
        </fieldset>{" "}
        <fieldset className="filter-item ">
          <legend className="filter-legend">Division</legend>{" "}
          <select className="filter-item-select">
            <option>All</option>
            <option>Option 2</option>
            <option>Option 3</option>
          </select>
        </fieldset>{" "}
        <fieldset className="filter-item ">
          <legend className="filter-legend">Category</legend>{" "}
          <select className="filter-item-select">
            <option>All</option>
            <option>Option 2</option>
            <option>Option 3</option>
          </select>
        </fieldset>{" "}
        <fieldset className="filter-item ">
          <legend className="filter-legend">SKU</legend>{" "}
          <select className="filter-item-select">
            <option>All</option>
            <option>Option 2</option>
            <option>Option 3</option>
          </select>
        </fieldset>
        <hr
          style={{
            borderTop: "1px solid #C4C4C4",
            marginTop: "5px",
            marginBottom: "8px",
          }}
        />
        <h3 className="filter-heading">LOCATION</h3>
        <fieldset className="filter-item ">
          <legend className="filter-legend">Region</legend>{" "}
          <select className="filter-item-select">
            <option>All</option>
            <option>Option 2</option>
            <option>Option 3</option>
          </select>
        </fieldset>
        <fieldset className="filter-item ">
          <legend className="filter-legend">State</legend>{" "}
          <select className="filter-item-select">
            <option>All</option>
            <option>Option 2</option>
            <option>Option 3</option>
          </select>
        </fieldset>{" "}
        <fieldset className="filter-item ">
          <legend className="filter-legend">Location Cluster</legend>{" "}
          <select className="filter-item-select">
            <option>All</option>
            <option>Option 2</option>
            <option>Option 3</option>
          </select>
        </fieldset>{" "}
        <fieldset className="filter-item ">
          <legend className="filter-legend">Nodes</legend>{" "}
          <select className="filter-item-select">
            <option>All</option>
            <option>Option 2</option>
            <option>Option 3</option>
          </select>
        </fieldset>{" "}
        <hr
          style={{
            borderTop: "1px solid #C4C4C4",
            marginTop: "5px",
            marginBottom: "8px",
          }}
        />
        <hr
          style={{
            borderTop: "1px solid #C4C4C4",
            marginTop: "5px",
            marginBottom: "8px",
          }}
        />{" "}
        <fieldset className="filter-item ">
          <legend className="filter-legend">Channel</legend>{" "}
          <select className="filter-item-select">
            <option>All</option>
            <option>Option 2</option>
            <option>Option 3</option>
          </select>
        </fieldset>{" "}
        <fieldset className="filter-item ">
          <legend className="filter-legend">Mode of Transport</legend>{" "}
          <select className="filter-item-select">
            <option>All</option>
            <option>Option 2</option>
            <option>Option 3</option>
          </select>
        </fieldset>
      </div>
    </div>
  );
};

export default FilterModal;
