const DownloadIcon = () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.25156 9.38516C6.32187 9.46953 6.41094 9.5 6.5 9.5C6.58906 9.5 6.67798 9.4685 6.74891 9.40515L10.1239 6.40515C10.2785 6.26783 10.2931 6.03062 10.1554 5.87593C10.0177 5.72066 9.779 5.70638 9.62595 5.8448L6.875 8.29062V0.875C6.875 0.667719 6.70625 0.5 6.5 0.5C6.29375 0.5 6.125 0.667719 6.125 0.875V8.29062L3.37344 5.84375C3.22109 5.70547 2.98203 5.72187 2.84375 5.87656C2.70688 6.01016 2.72141 6.26797 2.87656 6.38516L6.25156 9.38516ZM11 8H9.875C9.66791 8 9.5 8.16786 9.5 8.375C9.5 8.58209 9.66791 8.75 9.875 8.75H11C11.4141 8.75 11.75 9.08586 11.75 9.5V11C11.75 11.4141 11.4141 11.75 11 11.75H2C1.58586 11.75 1.25 11.4141 1.25 11V9.5C1.25 9.08586 1.58586 8.75 2 8.75H3.125C3.33125 8.75 3.5 8.58125 3.5 8.375C3.5 8.16875 3.33125 8 3.125 8H2C1.17148 8 0.5 8.67148 0.5 9.5V11C0.5 11.8285 1.17148 12.5 2 12.5H11C11.8285 12.5 12.5 11.8285 12.5 11V9.5C12.5 8.67266 11.8273 8 11 8ZM10.8125 10.25C10.8125 9.93945 10.5605 9.6875 10.25 9.6875C9.93945 9.6875 9.6875 9.93945 9.6875 10.25C9.6875 10.5605 9.93945 10.8125 10.25 10.8125C10.5605 10.8125 10.8125 10.5617 10.8125 10.25Z"
      fill="#3D445C"
    />
  </svg>
);

export default DownloadIcon;
