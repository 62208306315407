import React, { useState } from "react";
import { CheckIcon, PlusCircledIcon } from "@radix-ui/react-icons";
import { cn } from "@/lib/utils";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Separator } from "@/components/ui/separator";
import { Filter } from "lucide-react";
import { transformRenderedCellvalue } from "@/components/tanstack-table/table-utils";

export function ServerDataTableFacetedFilter({
  column,
  title,
  options,
  filterOptionsData,
  updateFilteringData,
}) {
  // const facets = column?.getFacetedUniqueValues();
  // options = Array.from(facets).map(([value, count]) => ({
  //   label: value,
  //   value,
  //   count,
  // }));
  const [selectedValues, setSelectedValues] = useState(new Set([]));
  const [filters, setFilters] = useState(new Map());

  const handleFilterSelect = (columnId, value) => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters };
      const columnFilters = new Set(newFilters[columnId] || []);

      if (columnFilters.has(value)) {
        columnFilters.delete(value);
      } else {
        columnFilters.add(value);
      }

      newFilters[columnId] = columnFilters;
      return newFilters;
    });
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          size="sm"
          className="h-full px-0 p-[4px] lg:!px-0 lg:p-[2px]  bg-[#D1D1D1] !border-none hover:bg-[#b0b0b0]"
        >
          <Filter className="h-3.5 w-3.5 lg:h-6 lg:w-6" />
          {selectedValues?.size > 0 && (
            <>
              <Separator orientation="vertical" className="mx-2 h-4" />
              <Badge
                variant="secondary"
                className="rounded-sm px-1 font-normal lg:hidden !bg-[#f1f5f9] lg:rounded-lg lg:px-2 lg:text-lg"
              >
                {selectedValues.size}
              </Badge>
              <div className="hidden space-x-1 lg:flex">
                {selectedValues.size > 2 ? (
                  <Badge
                    variant="secondary"
                    className="rounded-sm px-1 font-normal !bg-[#f1f5f9] lg:text-lg lg:rounded-lg lg:px-2"
                  >
                    {selectedValues.size} selected
                  </Badge>
                ) : (
                  [...new Set(filterOptionsData)]
                    .filter((option) => selectedValues.has(option))
                    .map((option) => (
                      <Badge
                        variant="secondary"
                        key={option}
                        className="rounded-sm px-1 font-normal !bg-[#f1f5f9] lg:text-lg lg:rounded-lg lg:px-2"
                      >
                        {option}
                      </Badge>
                    ))
                )}
              </div>
            </>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-0" align="start">
        <Command>
          <CommandInput placeholder={title} />
          <CommandList>
            <CommandEmpty>No results found.</CommandEmpty>
            <CommandGroup>
              {[...new Set(filterOptionsData)].map((option) => {
                const isSelected = selectedValues.has(option);
                return (
                  <CommandItem
                    key={option}
                    onSelect={() => {
                      handleFilterSelect(column.id, option);
                      setSelectedValues((prev) => {
                        const newSelectedValues = new Set(prev);
                        if (isSelected) {
                          newSelectedValues.delete(option);
                        } else {
                          newSelectedValues.add(option);
                        }
                        return newSelectedValues;
                      });
                      // const filterValues = Array.from(newSelectedValues);
                      // column?.setFilterValue(
                      //   filterValues.length ? filterValues : undefined
                      // );
                    }}
                  >
                    <div
                      className={cn(
                        "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border !border-slate-500 lg:mr-4 lg:h-6 lg:w-6 lg:rounded-md cursor-pointer",
                        isSelected
                          ? "!bg-primary !text-primary-foreground"
                          : "opacity-50 [&_svg]:invisible"
                      )}
                    >
                      <CheckIcon className={cn("h-4 w-4 lg:h-6 lg:w-6")} />
                    </div>
                    {/* {option.icon && (
                      <option.icon className="mr-2 h-4 w-4 text-muted-foreground" />
                    )} */}
                    <span className="mr-2 lg:text-xl lg:mr-4">
                      {transformRenderedCellvalue(option)}
                    </span>
                    {/* {option?.count && (
                      <span className="ml-auto flex h-4 w-4 items-center justify-center font-mono text-xs lg:h-6 lg:w-6 lg:text-lg text-slate-600">
                        {option?.count}
                      </span>
                    )} */}
                  </CommandItem>
                );
              })}
            </CommandGroup>
            {selectedValues.size > 0 && (
              <>
                <CommandSeparator />
                <CommandGroup>
                  <div className="flex items-center justify-around">
                    <CommandItem
                      onSelect={() => {
                        updateFilteringData(selectedValues);
                      }}
                      className="justify-center text-center"
                    >
                      Apply filters
                    </CommandItem>
                    <CommandItem
                      onSelect={() => {
                        setSelectedValues(new Set());
                        updateFilteringData(new Set());
                        column?.setFilterValue(undefined);
                      }}
                      className="justify-center text-center"
                    >
                      Clear filters
                    </CommandItem>
                  </div>
                </CommandGroup>
              </>
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
