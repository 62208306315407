import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  formatDateToDateObject,
  twoDecimalNumberFormat,
} from "../../../utils/utils";
import "./styles.css";
import {
  useReplGroupPlanQuery,
  useUpdateSTOMutation,
  useAcceptStoStatusMutation,
  useRejectStoStatusMutation,
  useMakePlanLiveMutation,
  useOrderFilterQuery,
} from "../../../features/api/replPlanSlice";
import UploadModal from "../../../components/modal/UploadModal";
import AllocationQuantityItem from "./AllocationQuantityItem";
import {
  TableLoaderSpinner,
  transformStatus,
} from "../../../components/tableUtils/tableUtils";
import { PlanViewKpiCards } from "../../../components/kpi_cards_copy/planViewKpiCards";
import { ThemeProvider, Typography, createTheme } from "@mui/material";

import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setGroupLevelPlanDetails } from "../../../features/GroupPlanViewSlice";
import {
  handleApiResponse,
  handleApiArrayResponse,
  handleDownloadApiResponse,
} from "../../../utils/apiUtils";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../../utils/components";
import { loadingColumns } from "./Tables/columns";
import {
  setEditedSTOList,
  setIsGroupLevelEditModeOn,
  setOriginalSTOValues,
} from "../../../features/replenishmentPlanSlice";
import { Checkbox } from "@/components/ui/checkbox";
import { DataTableColumnHeader } from "@/components/tanstack-table/data-table-column-header";
import { DataTable } from "@/components/tanstack-table/data-table";
import { DataTableToolbar } from "@/components/tanstack-table/data-table-toolbar";
import Tooltip from "@/components/tooltip/Tooltip";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { ServerDataTableColumnHeader } from "./Tables/ServerSideTableDataHandling/server-data-table-column-header";

const transformTableData = (arr) => {
  return arr.map((item) => ({
    ...item,
    sto_id: item.sto_id,
    unit_cost: item.unit_cost,
    total_cost: item.total_cost,
    product: item.product,
    stock_type: item.stock_type,
    channel_name: item.channel_name,
    location_start: item.location_start,
    source_name: item.source_name,
    location_end: item.location_end,
    destination_name: item.destination_name,
    planned_replenishment_qty: twoDecimalNumberFormat(
      item.planned_replenishment_qty
    ),
    edited_replenishment_qty: twoDecimalNumberFormat(
      item.edited_replenishment_qty
    ),
    planned_replenishment_qty_suom: twoDecimalNumberFormat(
      item.planned_replenishment_qty_suom
    ),
    edited_replenishment_qty_suom: twoDecimalNumberFormat(
      item.edited_replenishment_qty_suom
    ),
    short_supply: item.short_supply,
    status: item.status,
    approved_by: item.approved_by,
    approved_date: item.approved_date,
    created_date: formatDateToDateObject(item.created_date),
    created_by: item.created_by,
    version: item.version,
    reason_code: item.reason_code,
    comments: item.comments,
    date: formatDateToDateObject(item.date),
    sku_code: item.sku_code ?? "",
    demand_forecast: twoDecimalNumberFormat(item.demand_forecast),
    safety_stock: twoDecimalNumberFormat(item.safety_stock),
    max_stock: twoDecimalNumberFormat(item.max_stock),
    dos: twoDecimalNumberFormat(item.dos),
    projected_sales: twoDecimalNumberFormat(item.projected_sales),
    lost_sales: twoDecimalNumberFormat(item.lost_sales),
    current_inv_dos: item.current_inv_dos,
    lead_time: twoDecimalNumberFormat(item.lead_time),
    planned_dispatch_date: formatDateToDateObject(item.planned_dispatch_date),
    required_delivery_date: formatDateToDateObject(item.required_delivery_date),
    is_manually_created: item.is_manually_created,
    sku_hierarchy_1: item.sku_hierarchy_1,
    sku_hierarchy_2: item.sku_hierarchy_2,
    sku_hierarchy_3: item.sku_hierarchy_3,
    end_node_hierarchy_2: item.end_node_hierarchy_2,
    end_node_hierarchy_1: item.end_node_hierarchy_1,
    start_node_hierarchy_2: item.start_node_hierarchy_2,
    start_node_hierarchy_1: item.start_node_hierarchy_1,
    current_dos: item.current_dos,
    required_replenishment_qty: item.required_replenishment_qty,
    transport_mode_type: item.transport_mode_type,
    variable_cost: item.variable_cost,
    fixed_cost: item.fixed_cost,
    total_cost_system: item.total_cost_system,
    updated_at: formatDateToDateObject(item.updated_at),
    updated_by: item.updated_by,
  }));
};

const transformKpiData = (item) => {
  let arr = {};
  arr["totalOrderQuantitySystem"] = twoDecimalNumberFormat(
    item.total_order_quantity_system
  );
  arr["totalOrderQuantityUser"] = twoDecimalNumberFormat(
    item.total_order_quantity_user
  );
  arr["totalOrderValueSystem"] = twoDecimalNumberFormat(
    item.total_order_value_system
  );
  arr["totalOrderValueUser"] = twoDecimalNumberFormat(
    item.total_order_value_user
  );
  arr["orderCountTotal"] = item.order_count_total;
  arr["orderCountSystem"] = item.order_count_system;
  arr["orderCountManual"] = item.order_count_manual;
  arr["orderCountEdited"] = item.order_count_edited;
  arr["orderCountAccepted"] = item.order_count_accepted;
  arr["orderCountDeclined"] = item.order_count_declined;
  arr["orderCountPending"] = item.order_count_pending;
  arr["skuCount"] = item.sku_count;
  arr["sourceCount"] = item.source_count;
  arr["destinationCount"] = item.destination_count;
  return arr;
};

function getInitialVisibleColumns() {
  let localVisibleColumns = localStorage.getItem("snp-rp-group-cols");
  if (localVisibleColumns) {
    localVisibleColumns = JSON.parse(localVisibleColumns);
  } else {
    return {};
  }

  if (localVisibleColumns && Object.keys(localVisibleColumns).length !== 0) {
    return localVisibleColumns;
  } else {
    return {};
  }
}

function transformRenderedCellvalue(input) {
  const options = {
    day: "numeric",
    month: "short",
    year: "numeric",
  };
  if (input instanceof Date) {
    return input.toLocaleDateString("en-GB", options);
  }
  return input;
}

function GroupLevelPlanView() {
  const firstEditField = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  let pathSplit = path.split("/");
  let len = pathSplit.length;
  const planId = pathSplit[len - 1];
  const [tableData, setTableData] = useState(Array(10).fill({}));
  const [stoStatusValues, setStoStatusvalues] = useState({
    all: 0,
    approved: 0,
    declined: 0,
    pending: 0,
  });
  const [openApproveRejectModal, setOpenApproveRejectModal] = useState(false);
  const [approveRejectValue, setApproveRejectValue] = useState(null);
  const [openSendModal, setOpenSendModal] = useState(false);
  const [selectedGroupPlan, setSelectedGroupPlan] = useState({});
  const groupLevelPlanDetails = useSelector(
    (state) => state.groupLevelPlan.groupLevelPlanDetails
  );
  const [groupPlanDetails, setgroupPlanDetails] = useState(
    groupLevelPlanDetails
  );
  const [selectedSTO, setSelectedSTO] = useState({});
  const [loadingData, setLoadingData] = useState(false);
  const [selectedTab, setSelectedTab] = useState("all");
  const [openStoUploadModal, setopenStoUploadModal] = useState(false);
  const [isUploadingSto, setisUploadingSto] = useState(false);
  const [isDownloadingSto, setisDownloadingSto] = useState(false);
  const [clearRowSelection, setClearRowSelection] = useState(false);
  const [approveLoading, setapproveLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [kpiData, setKpiData] = useState({});
  const [columnVisibility, setColumnVisibility] = useState(
    getInitialVisibleColumns()
  );
  const [paginationData, setPaginationData] = useState({
    last: null,
    next: null,
    previous: null,
    page_size: null,
  });
  const [filterOptionsData, setFilterOptionsData] = useState([]);
  const orderColumns = useRef([]);

  let userDetails = JSON.parse(localStorage.getItem("user"));
  const { permissions } = userDetails;
  const isApprover = permissions.includes("STO_APPROVE_REJECT");
  const isEditPermission = permissions.includes("STO_EDIT");
  const acceptPermission = permissions.includes("STO_ACCEPT");
  const rejectPermission = permissions.includes("STO_REJECT");
  const uploadPermission = permissions.includes("STO_UPLOAD");
  const downloadPermission = permissions.includes("STO_VIEW");
  const addStoPermission = permissions.includes("STO_CREATE");
  const stoActionEnable =
    groupPlanDetails.approvalId === null ||
    (groupPlanDetails.approvalId !== null &&
      groupPlanDetails.approvalDetails &&
      groupPlanDetails.approvalDetails.permissions.canSubmit === true &&
      groupPlanDetails.approvalDetails.permissions.canRevoke !== true);
  const editEnable = isEditPermission && stoActionEnable;
  const acceptEnable = acceptPermission && stoActionEnable;
  const rejectEnable = rejectPermission && stoActionEnable;
  const uploadEnable = uploadPermission && stoActionEnable;
  const downloadEnable = downloadPermission;
  const addStoEnable = addStoPermission && stoActionEnable;
  const [openMakeLiveModal, setOpenMakeLiveModal] = useState(false);
  const [columns, setColumns] = useState(loadingColumns());
  const [resetFilterButton, setResetFilterButton] = useState(false);
  const selectedColumns = useRef({});
  const isServerSideDataHandling = true;
  const savedColumns = useRef([]);
  const pageSize = useRef(10);
  const dispatch = useDispatch();
  const isGroupLevelEditModeOn = useSelector(
    (state) => state.replenishmentPlan.isGroupLevelEditModeOn
  );
  const editedSTOList = useSelector(
    (state) => state.replenishmentPlan.editedSTOList
  );
  let editableFieldRef = useRef(null);

  const {
    data: replGroupPlanData,
    isFetching: isLoadingReplPlanViewData,
    isSuccess: isSuccessReplPlanViewData,
  } = useReplGroupPlanQuery(planId, {
    refetchOnMountOrArgChange: true,
    force: true, // This will bypass the cache
  });

  const { data: orderFilterData } = useOrderFilterQuery(planId, {
    refetchOnMountOrArgChange: true,
    force: true, // This will bypass the cache
  });

  useEffect(() => {
    const fetchFilterData = orderFilterData?.filter_columns.map(
      (columnName) => {
        return fetch(
          `${process.env.REACT_APP_SCAI_API_ENDPOINT}/v1/replenishment_plans/filter/${planId}/?column=${columnName}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${JSON.parse(
                localStorage.getItem("token")
              )}`,
            },
          }
        ).then((response) => {
          if (!response.ok) {
            throw new Error(`Failed to fetch data for column: ${columnName}`);
          }
          return response.json();
        });
      }
    );
    Promise.all(fetchFilterData)
      .then((responses) => {
        const responseData = responses.map((response, index) => ({
          [orderFilterData?.filter_columns[index]]: response.filterset,
        }));
        setFilterOptionsData(responseData);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  let filterQueryArr=[];
  const handleSortingFiltering = (columnName, values, order) => {
    // setLoadingData(true);
    
    const updatedColumns = {
      ...selectedColumns.current,
      [columnName]: values,
    };

    Object.keys(updatedColumns).length > 0
      ? setResetFilterButton(true)
      : setResetFilterButton(false);

    selectedColumns.current = updatedColumns;

    filterQueryArr = Object.entries(selectedColumns.current).map(
      ([column, columnFilterData]) => {
        return `${column}=${Array.from(columnFilterData).join(",")}`;
      }
    );

    const filterQuery = filterQueryArr.join("&");
    if (order) {
      const newOrdering = orderColumns.current.filter(
        (item) => !item.includes(columnName)
      );
      const orderValue = order === "desc" ? `-${columnName}` : columnName;
      newOrdering.push(orderValue);
      orderColumns.current = newOrdering;
    }

    let orderQuery = "";

    if (orderColumns.current.length > 0) {
      orderQuery = `ordering=` + orderColumns.current.join(",");
    }

    const baseUrl = `${process.env.REACT_APP_SCAI_API_ENDPOINT}/v3/replenishment_plans/${planId}/?${filterQuery}`;
    const orderParam = orderQuery ? `&${orderQuery}` : "";
    const fullUrl = `${baseUrl}${orderParam}&page_size=${pageSize.current}`;

    fetch(fullUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
      },
    })
      .then(handleApiResponse)
      .then((data) => {
        const updatedResponseData = {
          last: data.last,
          next: data.next,
          previous: data.previous,
          page_size: data.page_size,
        };
        setPaginationData(updatedResponseData);
        setColumns(savedColumns.current);
        setTableData(
          data && data.results ? transformTableData(data.results) : []
        );
        if (data && data.results) {
          getSTOStatusValues(data.results);
        }
        // setLoadingData(false);
      });
  };

  const handleResetButton = () => {
    fetch(
      `${process.env.REACT_APP_SCAI_API_ENDPOINT}/v3/replenishment_plans/${planId}/?page_size=${pageSize.current}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
        },
      }
    )
      .then(handleApiResponse)
      .then((data) => {
        const updatedResponseData = {
          last: data.last,
          next: data.next,
          previous: data.previous,
          page_size: data.page_size,
        };
        setPaginationData(updatedResponseData);
        setTableData(
          data && data.results ? transformTableData(data.results) : []
        );
      });
  };

  const [updateSTO, { isLoading: isLoadingUpdateSTO, isError }] =
    useUpdateSTOMutation();
  const [
    acceptSTOstatus,
    { isLoadingAcceptSTOStatus, isErrorAcceptSTOStatus },
  ] = useAcceptStoStatusMutation();
  const [
    rejectSTOstatus,
    { isLoadingRejectSTOStatus, isErrorRejectSTOStatus },
  ] = useRejectStoStatusMutation();

  const [planLive, { isLoading: loadingMakePlanLive }] =
    useMakePlanLiveMutation();

  const actionColumn = (row) => {
    return (
      <div className="accept-reject-buttons">
        <div
          className={
            row.version !== "V1"
              ? "sto-edited"
              : row.is_manually_created === true
              ? "sto-manually-created"
              : "sto-system"
          }
        >
          <Tooltip content="Decline">
            <button
              className="group-plan-action-button remove"
              disabled={rejectEnable ? row.status === "rejected" : true}
              onClick={() => handleSingleSTOApproval(row.sto_id, false)}
            >
              <svg
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.3228 11.8228C12.0884 12.0573 11.709 12.0573 11.4743 11.8228L6.4999 6.84728L1.52435 11.8228C1.28989 12.0573 0.910558 12.0573 0.675841 11.8228C0.441386 11.5884 0.441386 11.209 0.675841 10.9743L5.65252 5.9999L0.676591 1.02435C0.442136 0.789893 0.442136 0.410558 0.676591 0.175841C0.911046 -0.0586137 1.29038 -0.0586137 1.5251 0.175841L6.4999 5.15252L11.4755 0.176966C11.7099 -0.0574889 12.0892 -0.0574889 12.324 0.176966C12.5584 0.411421 12.5584 0.790755 12.324 1.02547L7.34728 5.9999L12.3228 10.9755C12.5591 11.2079 12.5591 11.5904 12.3228 11.8228Z"
                  fill="#3D445C"
                />
              </svg>
            </button>
          </Tooltip>
        </div>
        <div>
          <Tooltip content="Accept">
            <button
              className="group-plan-action-button save"
              disabled={acceptEnable ? row.status === "accepted" : true}
              onClick={() => handleSingleSTOApproval(row.sto_id, true)}
            >
              <svg
                width="13"
                height="9"
                viewBox="0 0 13 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.339 1.17228C12.5682 1.42715 12.5506 1.82248 12.2996 2.05525L5.05608 8.774C4.8066 9.0054 4.42008 8.98925 4.19016 8.73781L0.66447 4.88218C0.433187 4.62926 0.447578 4.2338 0.696613 3.9989C0.945648 3.76401 1.33502 3.77862 1.5663 4.03155L4.67671 7.43303L11.4696 1.13226C11.7206 0.899479 12.1098 0.917397 12.339 1.17228Z"
                  fill="#3D445C"
                />
              </svg>
            </button>
          </Tooltip>
        </div>{" "}
      </div>
    );
  };

  useEffect(() => {
    // Check if the column is editable
    const isColumnEditable = (columnName) => {
      return replGroupPlanData.editable_fields.includes(columnName);
    };
    function prepareColumns() {
      const allowedColumns = Object.keys(replGroupPlanData?.results?.[0]);
      const columnHeaderMap = replGroupPlanData.column_mapping;
      let cols = [];
      let visibleColumns = {};
      cols.push({
        id: "select",
        header: ({ table, cell }) => {
          const widthValue = cell?.column?.getSize();
          return (
            <div
              className="flex justify-center items-center py-[10px] lg:py-[8px]"
              style={{ width: widthValue }}
            >
              <Checkbox
                checked={
                  table.getIsAllPageRowsSelected() ||
                  (table.getIsSomePageRowsSelected() && "indeterminate")
                }
                onCheckedChange={(value) =>
                  table.toggleAllPageRowsSelected(!!value)
                }
                aria-label="Select all"
                // className="translate-y-[2px]"
              />
            </div>
          );
        },
        cell: ({ row, cell }) => {
          const widthValue = cell?.column?.getSize();
          return (
            <div
              className="flex justify-center items-center h-full py-[8px] lg:py-[6px]"
              style={{ width: widthValue }}
            >
              <Checkbox
                checked={row.getIsSelected()}
                onCheckedChange={(value) => row.toggleSelected(!!value)}
                aria-label="Select row"
                // className="translate-y-[2px]"
              />
            </div>
          );
        },
        enableSorting: false,
        enableHiding: false,
        enableResizing: false,
        size: 40,
      });
      allowedColumns.forEach((columnKey) => {
        visibleColumns[columnKey] = true;
        let columnMetaData = null;
        if (isColumnEditable(columnKey)) {
          editableFieldRef.current = columnKey;
          columnMetaData = {
            accessorKey: columnKey,
            header: ({ column, header }) => (
              <div ref={firstEditField}>
                <ServerDataTableColumnHeader
                  column={column}
                  title={columnHeaderMap[columnKey] ?? columnKey}
                  header={header}
                  orderingColumns={orderFilterData?.ordering_columns.includes(
                    columnKey
                  )}
                  filteringColumns={orderFilterData?.filter_columns.includes(
                    columnKey
                  )}
                  filterOptionsData={filterOptionsData}
                  handleSortingFiltering={handleSortingFiltering}
                />
              </div>
            ),
            cell: ({ row, cell }) => (
              <AllocationQuantityItem
                editableField={editableFieldRef.current}
                stoValues={row.original}
                getEditSTOApiBody={getEditSTOApiBody}
                planId={planId}
              />
            ),
            size: 250,
            filterFn: (row, id, value) => {
              return value.includes(row.getValue(id));
            },
            enableSorting: true,
            enableHiding: true,
          };
        } else {
          columnMetaData = {
            accessorKey: columnKey,
            header: ({ column, header }) => (
              <ServerDataTableColumnHeader
                column={column}
                title={columnHeaderMap[columnKey] ?? columnKey}
                header={header}
                orderingColumns={orderFilterData?.ordering_columns.includes(
                  columnKey
                )}
                filteringColumns={orderFilterData?.filter_columns.includes(
                  columnKey
                )}
                filterOptionsData={filterOptionsData}
                handleSortingFiltering={handleSortingFiltering}
              />
            ),
            cell: ({ row, cell }) => {
              const widthValue = cell.column.getSize();
              return (
                <div className="flex items-center justify-center">
                  <div
                    className="antialiased truncate text-center"
                    style={{ width: widthValue, minWidth: "150px" }}
                  >
                    {transformRenderedCellvalue(row.getValue(columnKey))}
                  </div>
                </div>
              );
            },
            size: 250,
            filterFn: (row, id, value) => {
              return value.includes(row.getValue(id));
            },
            enableSorting: true,
            enableHiding: true,
          };
        }

        cols.push(columnMetaData);
      });
      cols.push({
        id: "actions",
        accessorKey: "Action",
        header: () => (
          <div className="flex justify-center">
            <span className="font-semibold font-sans lg:text-[1.4rem] antialiased px-[6px] lg:px-[4px]">
              Action
            </span>
          </div>
        ),
        cell: ({ cell, row }) => {
          return <>{actionColumn(row.original)}</>;
        },
        enableHiding: false,
        size: 140,
      });
      if (cols.length > 0) {
        savedColumns.current = cols;
        setColumns(cols);
      }
      if (columnVisibility && Object.keys(columnVisibility).length === 0) {
        setColumnVisibility(visibleColumns);
      }
    }
    if (
      replGroupPlanData &&
      replGroupPlanData.column_mapping &&
      replGroupPlanData.results.length > 0
    ) {
      prepareColumns();
      setTableData(transformTableData(replGroupPlanData.results));
    } else if (isSuccessReplPlanViewData) {
      setTableData([]);
    }
  }, [replGroupPlanData, filterOptionsData]);

  useEffect(() => {
    if (replGroupPlanData && replGroupPlanData.aggregated_data) {
      setKpiData(
        replGroupPlanData && replGroupPlanData.aggregated_data
          ? transformKpiData(replGroupPlanData.aggregated_data)
          : {}
      );
    }
    if (replGroupPlanData && replGroupPlanData.results) {
      getSTOStatusValues(replGroupPlanData.results);
      setSTOValues(replGroupPlanData.results);
    }
    setLoadingData(false);
  }, [replGroupPlanData]);

  useEffect(() => {
    if (loadingData === true || isLoadingReplPlanViewData === true) {
      setColumns(loadingColumns());
    } else {
      if (savedColumns.current.length !== 0) {
        const cols = savedColumns.current;
        setColumns([...cols]);
      }
    }
  }, [loadingData]);

  const handleSTOUpload = () => {
    setopenStoUploadModal(true);
  };

  const setSTOValues = (data) => {
    let editValuesTemp = {};
    data.forEach((item) => {
      editValuesTemp[item.sto_id] = item;
    });
    dispatch(setOriginalSTOValues(editValuesTemp));
  };

  const getSTOStatusValues = (data) => {
    let approvedCount = 0;
    let pendingCount = 0;
    let declinedCount = 0;
    data.forEach((plan) => {
      if (plan.status === "accepted") {
        approvedCount += 1;
      } else if (plan.status === "pending") {
        pendingCount += 1;
      } else {
        declinedCount += 1;
      }
    });

    let stoStatusValuesCalculated = {
      all: approvedCount + pendingCount + declinedCount,
      approved: approvedCount,
      declined: declinedCount,
      pending: pendingCount,
    };
    setStoStatusvalues({ ...stoStatusValues, ...stoStatusValuesCalculated });
  };

  const filterTableData = (tableData) => {
    let filteredData = tableData;
    if (selectedTab === "accepted") {
      filteredData = tableData.filter((row) => row.status === "accepted");
    } else if (selectedTab === "rejected") {
      filteredData = tableData.filter((row) => row.status === "rejected");
    } else if (selectedTab === "pending") {
      filteredData = tableData.filter((row) => row.status === "pending");
    } else {
      filteredData = tableData;
    }
    return filteredData;
  };

  const handleSingleSTOApproval = (stoId, approvalStatus) => {
    let stoSingleApprovalStatus = { [stoId]: approvalStatus };
    handleSTOApprovalStatus(null, false, stoSingleApprovalStatus);
  };

  const handlePlanApproval = async (comment, planId, approveRejectValue) => {
    if (approveRejectValue === "APPROVED") {
      setapproveLoading(true);
    } else if (approveRejectValue === "REJECTED") {
      setRejectLoading(true);
    } else {
      setSubmitLoading(true);
    }
    const formData = new FormData();
    const approvalDetails = {
      action: approveRejectValue,
      approvable_type: "RP",
      approvables: [
        {
          id: planId,
          comment: comment,
          attachment_ids: [],
        },
      ],
    };
    formData.append("approval_details", JSON.stringify(approvalDetails));

    fetch(`${process.env.REACT_APP_SCAI_API_ENDPOINT}/v1/approval/`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
      },
      body: formData,
    })
      .then(handleApiArrayResponse)
      .then((data) => {
        const { message, approvals_info, approvable_details } = data;
        const approvableId = approvals_info[0].approvable_id;
        // const indexOfObjectToUpdate = tableData.findIndex((item) => {
        //   if (item.planId === approvableId) {
        //     return true;
        //   }
        // });
        // const planToBeUpdated = tableData[indexOfObjectToUpdate];
        const updatedPlan = {
          ...groupPlanDetails,
          isPlanLive: approvable_details[0].plan_live,
          planStatus:
            approvable_details[0].status === "APPROVAL_IN_PROGRESS"
              ? "Approval In Progress"
              : transformStatus(approvable_details[0].status),
          approvalId: approvals_info[0].approval_id,
          approvalDetails: {
            approvalId: approvals_info[0].approval_id,
            permissions: {
              canSubmit: approvals_info[0].permissions.canSubmit,
              canApprove: approvals_info[0].permissions.canApprove,
              canRevoke: approvals_info[0].permissions.canRevoke,
              canReject: approvals_info[0].permissions.canReject,
            },
            approvalStatus: approvals_info[0].approval_status,
            levelwiseApprovalStatus:
              approvals_info[0].levelwise_approval_status.map((element) => ({
                level: element.level,
                status: element.approval_status,
              })),
          },
        };
        // const newTableData = [...tableData];
        // newTableData[indexOfObjectToUpdate] = updatedPlan;
        // setTableData([...newTableData]);
        setgroupPlanDetails({ ...updatedPlan });
        dispatch(setGroupLevelPlanDetails({ ...updatedPlan }));
        toast.success(`${planId} is ${message}`, { autoClose: 3000 });
        if (approveRejectValue === "APPROVED") {
          setapproveLoading(false);
        } else if (approveRejectValue === "REJECTED") {
          setRejectLoading(false);
        } else {
          setSubmitLoading(false);
        }
      })
      .catch((error) => {
        if (approveRejectValue === "APPROVED") {
          setapproveLoading(false);
        } else if (approveRejectValue === "REJECTED") {
          setRejectLoading(false);
        } else {
          setSubmitLoading(false);
        }
        toast.error(error.message, { autoClose: 4000 });
      });
  };

  const renderSTOTableHeader = () => {
    const { approvalDetails, approvalId, planStatus, isPlanLive } =
      groupPlanDetails;
    const approveRejectDisable = () => {
      if (approvalId !== null && approvalDetails) {
        if (
          approvalDetails.permissions.canApprove === true &&
          approvalDetails.permissions.canReject === true
        ) {
          return false;
        } else {
          return true;
        }
      }
    };
    let userDetails = JSON.parse(localStorage.getItem("user"));
    const { permissions } = userDetails;
    const isPlanner = permissions.includes("STO_SUBMIT_APPROVAL");
    const isApprover = permissions.includes("STO_APPROVE_REJECT");
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          marginBottom: "6px",
          marginTop: "auto",
        }}
      >
        {!isGroupLevelEditModeOn && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "15px",
            }}
          >
            {" "}
            {approvalId !== null &&
              approvalDetails &&
              approvalDetails.levelwiseApprovalStatus.length !== 0 && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "15px",
                    border: "1px solid #E6E6E6",
                    borderRadius: "50px",
                    padding: "5px 10px",
                  }}
                >
                  <span
                    style={{
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#3D445C",
                      marginBottom: "unset",
                    }}
                  >
                    Approval Status:
                  </span>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    {approvalId === null && (
                      <>
                        {/* <div className={`status-indicator-app awaiting-status}`}></div>
              <div className={`status-indicator-app awaiting-status}`}></div> */}
                      </>
                    )}
                    {approvalId !== null &&
                      approvalDetails &&
                      approvalDetails.levelwiseApprovalStatus.length !== 0 &&
                      approvalDetails.levelwiseApprovalStatus.map(
                        (levelItem, index) => (
                          <div
                            className={`status-indicator-app ${
                              levelItem.status !== "APPROVED"
                                ? levelItem.status !== "REJECTED"
                                  ? levelItem.status !== "PENDING"
                                    ? "awaiting-status"
                                    : "pending-status"
                                  : "rejected-status"
                                : "approved-status"
                            }`}
                            key={index}
                          ></div>
                        )
                      )}
                  </div>
                </div>
              )}
            {isPlanner && approvalId === null && (
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    border: "1px solid #d9d9d9",
                    borderRadius: "19px",
                    padding: "2px 4px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    boxSizing: "border-box",
                    margin: "auto",
                    minWidth: "93px",
                    // opacity:
                    //   approvalDetails.permissions.canSubmit === true ||
                    //   approvalDetails.permissions.canRevoke ||
                    //   planStatus === "Planned"
                    //     ? 1
                    //     : 0.4,
                  }}
                  className=""
                  // disabled={true}
                >
                  {submitLoading ? (
                    <div
                      style={{
                        paddingTop: "4px",
                        paddingBottom: "4px",
                      }}
                    >
                      <TableLoaderSpinner size={12} height="2vh" />
                    </div>
                  ) : (
                    <>
                      <span
                        style={{
                          fontSize: "11px",
                          marginRight: "0px",
                          marginBottom: "unset",
                          marginTop: "unset",
                          lineHeight: "unset",
                          minWidth: "50px",
                          textAlign: "center",
                        }}
                      >
                        {/* {approvalStatusValues[row.planId] ? "Send" : "Revoke"} */}
                        {/* {approvalId !== null && approvalDetails
                  ? approvalDetails.permissions.canSubmit === true
                    ? "Send"
                    : approvalDetails.permissions.canRevoke === true
                    ? "Revoke"
                    : "Send"
                  : null} */}
                        Send
                      </span>
                      <label
                        className="send-revoke-box small-switch"
                        onClick={() => {
                          setSelectedGroupPlan({ ...groupPlanDetails });
                          setOpenSendModal(true);
                          setApproveRejectValue("SUBMITTED");
                          // setSelectedGroupPlan({ ...groupPlanDetails });
                          // setOpenSendModal(true);
                          // setApproveRejectValue("SUBMITTED");
                          // if (
                          //   approvalDetails.permissions.canSubmit === true ||
                          //   approvalDetails.permissions.canRevoke === true
                          // ) {
                          //   setSelectedGroupPlan(row);
                          //   setOpenSendModal(true);
                          //   const actionValue =
                          //     approvalDetails.permissions.canSubmit === true
                          //       ? "SUBMITTED"
                          //       : approvalDetails.permissions.canRevoke === true
                          //       ? "REVOKED"
                          //       : "";
                          //   setApproveRejectValue(actionValue);
                          // }
                        }}
                        disabled={true}
                      >
                        <input
                          type="checkbox"
                          className="send-revoke-input"
                          // defaultChecked
                          disabled
                          // checked={!approvalStatusValues[row.planId]}
                          // checked={approvalDetails.permissions.canRevoke}
                        />
                        <span className="send-revoke-slider send-revoke-round"></span>
                      </label>
                    </>
                  )}
                </div>
              </div>
            )}
            {isPlanner && approvalId !== null && approvalDetails && (
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    border: "1px solid #d9d9d9",
                    borderRadius: "19px",
                    padding: "2px 4px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    boxSizing: "border-box",
                    margin: "auto",
                    minWidth: "93px",
                    opacity:
                      approvalDetails.permissions.canSubmit === true ||
                      approvalDetails.permissions.canRevoke
                        ? 1
                        : 0.4,
                  }}
                  // disabled={true}
                >
                  {submitLoading ? (
                    <div
                      style={{
                        paddingTop: "4px",
                        paddingBottom: "4px",
                      }}
                    >
                      <TableLoaderSpinner size={12} height="2vh" />
                    </div>
                  ) : (
                    <>
                      {" "}
                      <span
                        style={{
                          fontSize: "11px",
                          marginRight: "0px",
                          marginBottom: "unset",
                          marginTop: "unset",
                          lineHeight: "unset",
                          minWidth: "50px",
                          textAlign: "center",
                        }}
                      >
                        {/* {approvalStatusValues[row.planId] ? "Send" : "Revoke"} */}
                        {approvalId !== null && approvalDetails
                          ? approvalDetails.permissions.canSubmit === true
                            ? "Send"
                            : approvalDetails.permissions.canRevoke === true
                            ? "Revoke"
                            : "Revoke"
                          : null}
                      </span>
                      <label
                        className="send-revoke-box small-switch"
                        onClick={() => {
                          if (
                            approvalDetails.permissions.canSubmit === true ||
                            approvalDetails.permissions.canRevoke === true
                          ) {
                            setSelectedGroupPlan({ ...groupPlanDetails });
                            setOpenSendModal(true);
                            const actionValue =
                              approvalDetails.permissions.canSubmit === true
                                ? "SUBMITTED"
                                : approvalDetails.permissions.canRevoke === true
                                ? "REVOKED"
                                : "";
                            setApproveRejectValue(actionValue);
                          }
                        }}
                        disabled={true}
                      >
                        <input
                          type="checkbox"
                          className="send-revoke-input"
                          // defaultChecked
                          disabled
                          // checked={!approvalStatusValues[row.planId]}
                          checked={approvalDetails.permissions.canRevoke}
                        />
                        <span className="send-revoke-slider send-revoke-round"></span>
                      </label>
                    </>
                  )}
                </div>
              </div>
            )}
            {isApprover && approvalId !== null && approvalDetails && (
              <div style={{ display: "flex" }}>
                <div className="approval-btns">
                  <button
                    className="approval-btn approve"
                    onClick={() => {
                      setOpenApproveRejectModal(true);
                      setSelectedGroupPlan({ ...groupPlanDetails });
                      setApproveRejectValue("APPROVED");
                    }}
                    disabled={
                      approveRejectDisable() || approveLoading || rejectLoading
                    }
                  >
                    {approveLoading ? (
                      <TableLoaderSpinner size={12} height="2vh" />
                    ) : (
                      "Approve"
                    )}
                    {/* <TableLoaderSpinner size={12} height="2vh" /> */}
                  </button>
                  <button
                    className="approval-btn reject"
                    onClick={() => {
                      setOpenApproveRejectModal(true);
                      setApproveRejectValue("REJECTED");
                      setSelectedGroupPlan({ ...groupPlanDetails });
                    }}
                    disabled={
                      approveRejectDisable() || approveLoading || rejectLoading
                    }
                  >
                    {rejectLoading ? (
                      <TableLoaderSpinner size={12} height="2vh" />
                    ) : (
                      "Reject"
                    )}
                  </button>
                </div>
              </div>
            )}
            <button
              className={
                // planStatus !== "Approved" ||
                // (isApprover && planStatus !== "Live")
                isPlanLive !== true || (!isPlanner && planStatus !== "Live")
                  ? planStatus !== "Live"
                    ? "grp-action-live-btn grp-action-live-btn-view "
                    : "grp-action-live-btn live-btn-enable grp-action-live-btn-view "
                  : "grp-action-live-btn grp-action-live-enable grp-action-live-btn-view "
              }
              style={{ margin: "auto", height: "23px", fontSize: "12px" }}
              // disabled={
              //   planStatus !== "Approved" ||
              //   (isApprover && planStatus !== "Live")
              // }
              disabled={!isPlanLive || !isPlanner}
              onClick={() => {
                handleMakeLive({ ...groupPlanDetails });
              }}
            >
              {loadingMakePlanLive ? (
                <div
                  style={{
                    padding: "3px 5.5px",
                  }}
                >
                  <TableLoaderSpinner size={12} height="2vh" />
                </div>
              ) : (
                "Live"
              )}
            </button>
          </div>
        )}
        <div style={{ display: "flex", gap: "15px" }}>
          <div
            className={` sto-card ${
              selectedTab === "all" ? "sto-card-active" : ""
            }`}
            onClick={() => {
              setClearRowSelection(true);
              setSelectedTab("all");
            }}
          >
            <span className="sto-card-heading">All</span>
            <span>{stoStatusValues.all}</span>
          </div>
          <div
            className={` sto-card ${
              selectedTab === "accepted" ? "sto-card-active" : ""
            }`}
            onClick={() => {
              setClearRowSelection(true);
              setSelectedTab("accepted");
            }}
          >
            <span>Accepted</span>
            <span>{stoStatusValues.approved}</span>
          </div>
          <div
            className={` sto-card ${
              selectedTab === "pending" ? "sto-card-active" : ""
            }`}
            onClick={() => {
              setClearRowSelection(true);
              setSelectedTab("pending");
            }}
          >
            <span style={{ background: "transparent" }}>Pending</span>
            <span>{stoStatusValues.pending}</span>
          </div>
          <div
            className={` sto-card ${
              selectedTab === "rejected" ? "sto-card-active" : ""
            }`}
            onClick={() => {
              setClearRowSelection(true);
              setSelectedTab("rejected");
            }}
          >
            <span style={{ background: "unset" }}>Declined</span>
            <span>{stoStatusValues.declined}</span>
          </div>
        </div>
      </div>
    );
  };

  const AddIconTemp = () => (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 6.99966C14 7.29582 13.7577 7.53813 13.4615 7.53813H7.53846V13.4612C7.53846 13.7588 7.29764 14 7 14C6.70237 14 6.46154 13.7574 6.46154 13.4612V7.53813H0.538462C0.240827 7.53813 0 7.2975 0 7C0 6.70351 0.240962 6.4612 0.538462 6.4612H6.46154V0.538125C6.46154 0.24049 6.70237 0 7 0C7.29764 0 7.53846 0.240625 7.53846 0.538125V6.4612H13.4615C13.7577 6.4612 14 6.70351 14 6.99966Z"
        fill="white"
      />
    </svg>
  );

  const handleSTODownload = () => {
    setisDownloadingSto(true);
    fetch(
      `${process.env.REACT_APP_SCAI_API_ENDPOINT}/v2/replenishment_plans/${planId}/?action=download`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
        },
      }
    )
      .then(handleDownloadApiResponse)
      .then((data) => {
        setisDownloadingSto(false);
        var a = document.createElement("a");
        a.href = window.URL.createObjectURL(data);
        a.download = `STO_${planId}.csv`;
        a.click();
      })
      .catch((error) => {
        setisDownloadingSto(false);
        toast.error(error.message, { autoClose: 2000 });
      });
  };

  const handleManualSTOTemplate = () => {
    setisDownloadingSto(true);
    fetch(
      `${process.env.REACT_APP_SCAI_API_ENDPOINT}/v1/replenishment_plans/template/download/`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
        },
      }
    )
      .then(handleDownloadApiResponse)
      .then((data) => {
        setisDownloadingSto(false);
        var a = document.createElement("a");
        a.href = window.URL.createObjectURL(data);
        a.download = `STO_${planId}.csv`;
        a.click();
      })
      .catch((error) => {
        setisDownloadingSto(false);
        toast.error(error.message, { autoClose: 2000 });
      });
  };

  const handleMakeLive = (row) => {
    setSelectedGroupPlan({ ...row });
    setOpenMakeLiveModal(true);
  };

  const makePlanLive = async (planId) => {
    const requestBody = {
      plan_id: planId,
    };
    try {
      const response = await planLive({
        planId,
        requestBody: requestBody,
      });
      if (response.error) {
        throw new Error(response.error.data.error);
      } else {
        const updatedGroupPlanDetails = { ...groupPlanDetails };
        updatedGroupPlanDetails["planStatus"] = "Live";
        setgroupPlanDetails({ ...updatedGroupPlanDetails });
        dispatch(setGroupLevelPlanDetails({ ...updatedGroupPlanDetails }));
        toast.success(response.data.success, { autoClose: 3000 });
      }
    } catch (error) {
      toast.error(error.message, { autoClose: 3000 });
    }
  };

  const refetchSTO = () => {
    setLoadingData(true);
    fetch(
      `${process.env.REACT_APP_SCAI_API_ENDPOINT}/v2/replenishment_plans/${planId}/`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
        },
      }
    )
      .then(handleApiResponse)
      .then((updatedData) => {
        setLoadingData(false);
        setTableData(
          updatedData && updatedData.results
            ? transformTableData(updatedData.results)
            : []
        );
        if (updatedData && updatedData.results) {
          getSTOStatusValues(updatedData.results);
        }
      })
      .catch((error) => {
        setLoadingData(false);
        if (error.message) {
          toast.error(error.message);
        }
      });
  };

  const handleAllEditSTOSave = async () => {
    setLoadingData(true);
    try {
      const response = await updateSTO({
        planId,
        requestBody: editedSTOList,
      });
      if (response.error) {
        throw new Error(response.error.data.error);
      } else {
        setLoadingData(false);
        toast.success(response.data.message, { autoClose: 3000 });
      }
    } catch (error) {
      toast.error(error.message, { autoClose: 3000 });
      setLoadingData(false);
    } finally {
      dispatch(setIsGroupLevelEditModeOn(false));
      dispatch(setEditedSTOList({ type: "RESET" }));
    }
  };

  const transformStatusValues = (selectedSTO) => {
    const selectedSTORequestBody = {};
    Object.keys(selectedSTO).forEach((key) => {
      selectedSTORequestBody[key] = selectedSTO[key] ? "accepted" : "rejected";
    });
    return selectedSTORequestBody;
  };

  const acceptSelectedSTO = (selectedSTO) => {
    const selectedSTORequestBody = {};
    Object.keys(selectedSTO).forEach((key) => {
      selectedSTORequestBody[key] = "accepted";
    });
    return selectedSTORequestBody;
  };

  const rejectSelectedSTO = (selectedSTO) => {
    const selectedSTORequestBody = {};
    Object.keys(selectedSTO).forEach((key) => {
      selectedSTORequestBody[key] = "rejected";
    });
    return selectedSTORequestBody;
  };

  const handleSTOApprovalStatus = async (
    updateStatus,
    multiEnable,
    stoSingleSelected
  ) => {
    let requestBody;
    if (multiEnable) {
      requestBody = {
        plan_id: planId,
        status_updates: updateStatus
          ? acceptSelectedSTO(selectedSTO)
          : rejectSelectedSTO(selectedSTO),
      };
    } else {
      requestBody = {
        plan_id: planId,
        status_updates: transformStatusValues(stoSingleSelected),
      };
    }
    if (Object.keys(requestBody.status_updates).length !== 0) {
      setLoadingData(true);
      let statusUpdates = requestBody.status_updates;
      try {
        let response;
        if (
          Object.values(statusUpdates).every((value) => value === "accepted")
        ) {
          response = await acceptSTOstatus(requestBody);
        } else {
          response = await rejectSTOstatus(requestBody);
        }
        if (response.error) {
          throw new Error(response.error.data.error);
        } else {
          toast.success(response.data.message, { autoClose: 3000 });
          setClearRowSelection(true);
          // setLoadingData(false);
        }
      } catch (error) {
        toast.error(error.message, { autoClose: 3000 });
        setLoadingData(false);
      }
    }
  };

  const handleEditField = () => {
    firstEditField.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "center",
    });
    dispatch(setIsGroupLevelEditModeOn(true));
  };

  const renderSTORightHeader = () => {
    return isGroupLevelEditModeOn ? (
      <div
        style={{
          marginLeft: "auto",
          height: "30px",
          display: "flex",
          alignItems: "center",
          marginBottom: "10px",
          justifyContent: "flex-end",
        }}
      >
        <button
          className="sto-view-cancel-button "
          onClick={() => {
            dispatch(setEditedSTOList({ type: "RESET" }));
            dispatch(setIsGroupLevelEditModeOn(false));
          }}
        >
          Cancel
        </button>
        <button
          className="sto-view-save-button "
          disabled={
            isLoadingUpdateSTO ||
            (editedSTOList != null ? editedSTOList.length === 0 : true)
          }
          onClick={handleAllEditSTOSave}
        >
          Save
        </button>
      </div>
    ) : (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          height: "30px",
          gap: "30px",
          paddingBottom: "15px",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "10px",
            height: "100%",
            alignItems: "center",
          }}
        >
          {" "}
          <Tooltip content="Upload" placement="top">
            <button
              className="download-button sto-upload "
              onClick={handleSTOUpload}
              disabled={isUploadingSto || !uploadEnable}
            >
              {isUploadingSto ? (
                <TableLoaderSpinner size={12} />
              ) : (
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.87338 3.63531L5.6249 1.21028V8.62506C5.6249 8.83234 5.79261 9.00006 5.99989 9.00006C6.20717 9.00006 6.37488 8.83234 6.37488 8.62506V1.21028L9.12639 3.65476C9.1967 3.71968 9.28576 3.75015 9.37483 3.75015C9.47809 3.75015 9.58135 3.70768 9.65537 3.62418C9.79306 3.46963 9.77841 3.23231 9.62387 3.09497L6.24893 0.0950256C6.10686 -0.0316752 5.89292 -0.0316752 5.75089 0.0950256L2.37596 3.09497C2.22136 3.23219 2.20683 3.46891 2.34371 3.62359C2.48058 3.77828 2.72104 3.79234 2.87338 3.63531ZM10.4998 7.50008H7.87485C7.66776 7.50008 7.49986 7.66794 7.49986 7.87508C7.49986 8.08217 7.66776 8.25007 7.87485 8.25007H10.4998C10.9139 8.25007 11.2498 8.58592 11.2498 9.00006V10.5C11.2498 10.9142 10.9139 11.25 10.4998 11.25H1.49997C1.08584 11.25 0.749986 10.9142 0.749986 10.5V9.00006C0.749986 8.58592 1.08584 8.25007 1.49997 8.25007H4.12492C4.33117 8.25007 4.49992 8.08132 4.49992 7.87508C4.49992 7.66883 4.33117 7.50008 4.12492 7.50008H1.49997C0.671472 7.50008 0 8.17155 0 9.00006V10.5C0 11.3285 0.671472 12 1.49997 12H10.4998C11.3283 12 11.9998 11.3285 11.9998 10.5V9.00006C11.9998 8.17273 11.3271 7.50008 10.4998 7.50008ZM10.3123 9.75004C10.3123 9.4395 10.0604 9.18755 9.74982 9.18755C9.43928 9.18755 9.18733 9.4395 9.18733 9.75004C9.18733 10.0606 9.43928 10.3125 9.74982 10.3125C10.0604 10.3125 10.3123 10.0618 10.3123 9.75004Z"
                    fill="black"
                  />
                </svg>
              )}
            </button>
          </Tooltip>
          <Tooltip content="Download">
            <Popover>
              <PopoverTrigger>
                <button
                  className="download-button sto-upload"
                  disabled={isDownloadingSto || !downloadEnable}
                >
                  {isDownloadingSto ? (
                    <TableLoaderSpinner size={12} />
                  ) : (
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="red"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.75156 8.88516C5.82187 8.96953 5.91094 9 6 9C6.08906 9 6.17798 8.9685 6.24891 8.90515L9.62391 5.90515C9.77845 5.76783 9.7931 5.53062 9.65541 5.37593C9.51771 5.22066 9.279 5.20638 9.12595 5.3448L6.375 7.79062V0.375C6.375 0.167719 6.20625 0 6 0C5.79375 0 5.625 0.167719 5.625 0.375V7.79062L2.87344 5.34375C2.72109 5.20547 2.48203 5.22187 2.34375 5.37656C2.20688 5.51016 2.22141 5.76797 2.37656 5.88516L5.75156 8.88516ZM10.5 7.5H9.375C9.16791 7.5 9 7.66786 9 7.875C9 8.08209 9.16791 8.25 9.375 8.25H10.5C10.9141 8.25 11.25 8.58586 11.25 9V10.5C11.25 10.9141 10.9141 11.25 10.5 11.25H1.5C1.08586 11.25 0.75 10.9141 0.75 10.5V9C0.75 8.58586 1.08586 8.25 1.5 8.25H2.625C2.83125 8.25 3 8.08125 3 7.875C3 7.66875 2.83125 7.5 2.625 7.5H1.5C0.671484 7.5 0 8.17148 0 9V10.5C0 11.3285 0.671484 12 1.5 12H10.5C11.3285 12 12 11.3285 12 10.5V9C12 8.17266 11.3273 7.5 10.5 7.5ZM10.3125 9.75C10.3125 9.43945 10.0605 9.1875 9.75 9.1875C9.43945 9.1875 9.1875 9.43945 9.1875 9.75C9.1875 10.0605 9.43945 10.3125 9.75 10.3125C10.0605 10.3125 10.3125 10.0617 10.3125 9.75Z"
                        fill="black"
                      />
                    </svg>
                  )}
                </button>
              </PopoverTrigger>
              <PopoverContent>
                <div
                  className="flex justify-between content-center cursor-pointer border-b-2 px-4 py-3"
                  onClick={handleSTODownload}
                >
                  <p>Plan Export</p>
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="red"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.75156 8.88516C5.82187 8.96953 5.91094 9 6 9C6.08906 9 6.17798 8.9685 6.24891 8.90515L9.62391 5.90515C9.77845 5.76783 9.7931 5.53062 9.65541 5.37593C9.51771 5.22066 9.279 5.20638 9.12595 5.3448L6.375 7.79062V0.375C6.375 0.167719 6.20625 0 6 0C5.79375 0 5.625 0.167719 5.625 0.375V7.79062L2.87344 5.34375C2.72109 5.20547 2.48203 5.22187 2.34375 5.37656C2.20688 5.51016 2.22141 5.76797 2.37656 5.88516L5.75156 8.88516ZM10.5 7.5H9.375C9.16791 7.5 9 7.66786 9 7.875C9 8.08209 9.16791 8.25 9.375 8.25H10.5C10.9141 8.25 11.25 8.58586 11.25 9V10.5C11.25 10.9141 10.9141 11.25 10.5 11.25H1.5C1.08586 11.25 0.75 10.9141 0.75 10.5V9C0.75 8.58586 1.08586 8.25 1.5 8.25H2.625C2.83125 8.25 3 8.08125 3 7.875C3 7.66875 2.83125 7.5 2.625 7.5H1.5C0.671484 7.5 0 8.17148 0 9V10.5C0 11.3285 0.671484 12 1.5 12H10.5C11.3285 12 12 11.3285 12 10.5V9C12 8.17266 11.3273 7.5 10.5 7.5ZM10.3125 9.75C10.3125 9.43945 10.0605 9.1875 9.75 9.1875C9.43945 9.1875 9.1875 9.43945 9.1875 9.75C9.1875 10.0605 9.43945 10.3125 9.75 10.3125C10.0605 10.3125 10.3125 10.0617 10.3125 9.75Z"
                      fill="black"
                    />
                  </svg>
                </div>
                <div
                  className="flex justify-between content-center cursor-pointer px-4 py-3"
                  onClick={handleManualSTOTemplate}
                >
                  <p>Manual STO Template</p>
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="red"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.75156 8.88516C5.82187 8.96953 5.91094 9 6 9C6.08906 9 6.17798 8.9685 6.24891 8.90515L9.62391 5.90515C9.77845 5.76783 9.7931 5.53062 9.65541 5.37593C9.51771 5.22066 9.279 5.20638 9.12595 5.3448L6.375 7.79062V0.375C6.375 0.167719 6.20625 0 6 0C5.79375 0 5.625 0.167719 5.625 0.375V7.79062L2.87344 5.34375C2.72109 5.20547 2.48203 5.22187 2.34375 5.37656C2.20688 5.51016 2.22141 5.76797 2.37656 5.88516L5.75156 8.88516ZM10.5 7.5H9.375C9.16791 7.5 9 7.66786 9 7.875C9 8.08209 9.16791 8.25 9.375 8.25H10.5C10.9141 8.25 11.25 8.58586 11.25 9V10.5C11.25 10.9141 10.9141 11.25 10.5 11.25H1.5C1.08586 11.25 0.75 10.9141 0.75 10.5V9C0.75 8.58586 1.08586 8.25 1.5 8.25H2.625C2.83125 8.25 3 8.08125 3 7.875C3 7.66875 2.83125 7.5 2.625 7.5H1.5C0.671484 7.5 0 8.17148 0 9V10.5C0 11.3285 0.671484 12 1.5 12H10.5C11.3285 12 12 11.3285 12 10.5V9C12 8.17266 11.3273 7.5 10.5 7.5ZM10.3125 9.75C10.3125 9.43945 10.0605 9.1875 9.75 9.1875C9.43945 9.1875 9.1875 9.43945 9.1875 9.75C9.1875 10.0605 9.43945 10.3125 9.75 10.3125C10.0605 10.3125 10.3125 10.0617 10.3125 9.75Z"
                      fill="black"
                    />
                  </svg>
                </div>
              </PopoverContent>
            </Popover>
          </Tooltip>
        </div>
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            height: "100%",
          }}
        >
          {" "}
          <Tooltip content="Reject" placement="top">
            <button
              className="download-button sto-multi-reject "
              onClick={() => handleSTOApprovalStatus(false, true)}
              disabled={Object.keys(selectedSTO).length === 0 || !rejectEnable}
            >
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.8228 11.8228C11.5884 12.0573 11.209 12.0573 10.9743 11.8228L5.9999 6.84728L1.02435 11.8228C0.789893 12.0573 0.410558 12.0573 0.175841 11.8228C-0.0586137 11.5884 -0.0586137 11.209 0.175841 10.9743L5.15252 5.9999L0.176591 1.02435C-0.0578638 0.789893 -0.0578638 0.410558 0.176591 0.175841C0.411046 -0.0586137 0.79038 -0.0586137 1.0251 0.175841L5.9999 5.15252L10.9755 0.176966C11.2099 -0.0574889 11.5892 -0.0574889 11.824 0.176966C12.0584 0.411421 12.0584 0.790755 11.824 1.02547L6.84728 5.9999L11.8228 10.9755C12.0591 11.2079 12.0591 11.5904 11.8228 11.8228Z"
                  fill="#3D445C"
                />
              </svg>
            </button>
          </Tooltip>
          <Tooltip content="Accept" placement="top">
            <button
              className="download-button sto-multi-accept "
              onClick={() => handleSTOApprovalStatus(true, true)}
              disabled={Object.keys(selectedSTO).length === 0 || !acceptEnable}
            >
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11.839 2.17228C12.0682 2.42715 12.0506 2.82248 11.7996 3.05525L4.55608 9.774C4.3066 10.0054 3.92008 9.98925 3.69016 9.73781L0.16447 5.88218C-0.0668127 5.62926 -0.0524215 5.2338 0.196613 4.9989C0.445648 4.76401 0.835022 4.77862 1.0663 5.03155L4.17671 8.43303L10.9696 2.13226C11.2206 1.89948 11.6098 1.9174 11.839 2.17228Z"
                  fill="#3D445C"
                />
              </svg>
            </button>
          </Tooltip>
          <Tooltip content="Edit">
            <button
              className="download-button sto-edit "
              onClick={handleEditField}
              disabled={!editEnable}
            >
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.5591 1.31856L10.68 0.439443C10.3872 0.146528 10.0029 0 9.62086 0C9.23696 0 8.85306 0.146481 8.5601 0.439443L1.05722 7.94279C0.856408 8.14355 0.716677 8.39724 0.654569 8.67426L0.00747342 11.5523C-0.0398693 11.7677 0.144299 11.9997 0.373793 11.9997C0.398332 11.9997 0.423386 11.9973 0.448745 11.9922C0.448745 11.9922 2.43175 11.5715 3.32681 11.3611C3.59727 11.2974 3.83937 11.1613 4.03578 10.965L11.5637 3.43703C12.1474 2.85228 12.145 1.90449 11.5591 1.31856ZM3.5038 10.4341C3.40492 10.533 3.28696 10.5991 3.15319 10.6306C2.57476 10.7668 1.52103 10.9951 0.869017 11.135L1.38557 8.83792C1.41679 8.69922 1.48642 8.57285 1.58699 8.47231L7.38998 2.66931L9.32963 4.60895L3.5038 10.4341ZM11.0318 2.90619L9.85921 4.0785L7.91956 2.13886L9.08977 0.968651C9.2318 0.828029 9.4193 0.749983 9.62086 0.749983C9.82241 0.749983 10.0094 0.828029 10.1512 0.969705L11.0304 1.84883C11.3224 2.14144 11.3224 2.61557 11.0318 2.90619Z"
                  fill="#3D445C"
                />
              </svg>
            </button>{" "}
          </Tooltip>
          <button
            className="create-new-button bg-[#e55c2e]"
            onClick={() =>
              navigate(
                `/snp/inventory/replenishment-plan/group-plan/${planId}/add-sto`
              )
            }
            disabled={!addStoEnable}
          >
            {/* <img src={AddIconTemp} /> */}
            <AddIconTemp />
            {"  "} <span>Add</span>
          </button>
        </div>
      </div>
    );
  };

  const getEditSTOApiBody = (sto, editedValue, reason, comments) => {
    const editableField = editableFieldRef.current;
    dispatch(
      setEditedSTOList({
        type: "API_BODY",
        payload: { sto, editedValue, reason, comments, editableField },
      })
    );
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const renderLeftTableFooterGroupLevel = () => {
    return (
      <div className="stos-legend-container ">
        <div className="stos-legend-item">
          <span className="stos-legend-item-color stos-legend-item-system"></span>
          <span>System </span>
        </div>
        <div className="stos-legend-item">
          <span className="stos-legend-item-color stos-legend-item-manual"></span>
          <span>Manual </span>
        </div>
        <div className="stos-legend-item">
          <span className="stos-legend-item-color stos-legend-item-edited"></span>
          <span>Edited </span>
        </div>
      </div>
    );
  };

  const GroupLevelToolbar = ({ table }) => {
    return (
      <div className="flex justify-between items-center">
        {renderSTOTableHeader()}
        <div>
          {renderSTORightHeader()}
          <DataTableToolbar
            table={table}
            resetFilterButton={resetFilterButton}
            setResetFilterButton={setResetFilterButton}
            handleResetButton={handleResetButton}
          />
        </div>
      </div>
    );
  };

  return tableData ? (
    <ThemeProvider theme={customTheme}>
      <div style={{ width: `calc(100% - 20px)`, margin: 10 }}>
        <UploadModal
          openModal={openStoUploadModal}
          setOpenModal={setopenStoUploadModal}
          uploadType="sto"
          isUploadingSto={isUploadingSto}
          setisUploadingSto={setisUploadingSto}
          refetchSTO={refetchSTO}
          setLoadingData={setLoadingData}
        />
        <UploadModal
          openModal={openSendModal}
          setOpenModal={setOpenSendModal}
          modalType="send"
          // approvalStatusValues={approvalStatusValues}
          // setApprovalStatusValues={setApprovalStatusValues}
          selectedGroupPlan={selectedGroupPlan}
          approveRejectValue={approveRejectValue}
          handlePlanApproval={handlePlanApproval}
        />
        <UploadModal
          openModal={openApproveRejectModal}
          setOpenModal={setOpenApproveRejectModal}
          modalType="approve-reject"
          approveRejectValue={approveRejectValue}
          handlePlanApproval={handlePlanApproval}
          selectedGroupPlan={selectedGroupPlan}
          // approvalStatusValues={approvalStatusValues}
          // setApprovalStatusValues={setApprovalStatusValues}
          // selectedGroupPlan={selectedGroupPlan}
        />
        <UploadModal
          openModal={openMakeLiveModal}
          setOpenModal={setOpenMakeLiveModal}
          modalType="make-live"
          selectedGroupPlan={selectedGroupPlan}
          makePlanLive={makePlanLive}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            alignSelf: "center",
            marginTop: "12px",
            marginBottom: "12px",
          }}
          className="process-header"
        >
          <div
            className="process-header-1"
            onClick={() =>
              navigate("/snp/inventory/replenishment-plan", {
                state: { planLevel: "group" },
              })
            }
          >
            Group Level
          </div>
          <div>
            <svg
              width="9"
              height="9"
              viewBox="0 0 9 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L4.5 4.5L1 8"
                stroke="#858585"
                strokeLinecap="round"
              />
              <path
                d="M4.5 1L8 4.5L4.5 8"
                stroke="#929292"
                strokeLinecap="round"
              />
            </svg>
          </div>
          <div className="process-header-2">{`Plan View : ${planId}`}</div>
        </div>

        {/* <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            expanded={expanded === "panel1"}
            fill="white"
            hoverColor="#e94f1c"
            backgroundColor="#494949"
            expandedBgColor="#e94f1b"
          >
            <Typography
              sx={{
                width: "33%",
                flexShrink: 0,
                fontSize: "12px",
                color: "white",
              }}
            >
              KPI&apos;s
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <PlanViewKpiCards
              kpiData={kpiData}
              loading={isLoadingReplPlanViewData}
            />
          </AccordionDetails>
        </Accordion> */}
        <div>
          <DataTable
            CustomToolbar={GroupLevelToolbar}
            data={filterTableData(tableData)}
            columns={columns}
            canResizeColumns={true}
            setSelectedSTO={setSelectedSTO}
            clearRowSelection={clearRowSelection}
            setClearRowSelection={setClearRowSelection}
            columnVisibility={columnVisibility}
            setColumnVisibility={setColumnVisibility}
            showColorCodingLegend={true}
            planId={planId}
            setTableData={setTableData}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
            isServerSideDataHandling={isServerSideDataHandling}
            setLoadingData={setLoadingData}
            pageSize={pageSize}
          />
        </div>
      </div>
    </ThemeProvider>
  ) : (
    <TableLoaderSpinner />
  );
}

const customTheme = createTheme({
  palette: {
    // primary: {
    //   main: "#f5f7fa",
    // },
  },
  typography: {
    fontFamily: "Inter, sans-serif",
    fontSize: 12,
    fontWeightBold: 700,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightLight: 300,
    customBoldFont: {
      fontWeight: 600,
      fontSize: 13,
    },
    customLightFont: {
      fontSize: 11,
      fontWeight: 400,
    },
    customHeading: {
      fontWeight: 300,
      fontSize: 15,
    },
  },
  components: {
    MuiDrawer: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});
export default GroupLevelPlanView;
