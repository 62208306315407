import "./styles.css";

export function PerformanceIndicator({ title, percentage, text }) {
  return (
    <div style={{ marginBottom: "10px", borderRadius: '5px' }}>
      <div
        style={{ marginLeft: "30px", fontFamily: "Inter", fontSize: "13px" }}
      >
        {title}
      </div>
      <div className="w3-round-xxlarge flex" style={{background: '#DEDEDE', border: "1px solid #FFC9B3", borderRadius: '5px', margin: "0px 28px", position: "relative"}}>
        <div
          className="w3-container w3-round-xxlarge"
          style={{ width: `${percentage}%`, fontSize: "12px", background: '#494949', borderRadius: '5px', color: '#ffffff' }}
        >
          {percentage}%
        </div>
        <div
          style={{
            color: "#ffffff",
            paddingRight: "10px",
            position: "absolute",
            right: "5px",
            fontSize: "13px",
          }}
        >
          {text}
        </div>
      </div>
    </div>
  );
}
