import { useState, useEffect, useRef, createRef } from "react";
import MuiTable from "../components/muiTable/muiTable";
import { data } from "../components/muiTable/data";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "../components/customSvgIcons/edit";
import DeleteIcon from "../components/customSvgIcons/delete";
import ConfigIcon from "../components/customSvgIcons/configuration";
import SettingIcon from "../components/customSvgIcons/settings";
import { TextField, Autocomplete, Checkbox } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  setJobSchedulerMode,
  setJobSchedulerProps,
} from "../features/JobSchedulerSlice";
import { setConfigurationMode } from "../features/ConfigurationsSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  NormalCell,
  RenderMultipleButtons,
} from "../components/tableUtils/tableUtils";
import UploadModal from "../components/modal/UploadModal";
import { CheckBoxBlankIcon, checkSharpIcon } from "../assets/svg-icons";
import {
  formatDateToDateMonYear,
  formatISODateToHumanReadable,
} from "../utils/utils";
import { handleApiArrayResponse } from "../utils/apiUtils";

const transformTableData = (arr) => {
  return arr.map((item) => ({
    id: item.id,
    name: item.name,
    jobType: item.group_rule.group.module.name,
    jobTypeId: item.group_rule.group.module.id,
    categoryType: item.group_rule.group.group_type,
    jobCategory: item.group_rule.group.name,
    jobCategoryId: item.group_rule.group.id,
    configuration: item.group_rule?.rule_set_name ?? "",
    cron: item.cron,
    cron_str: item.cron_str,
    period: item.start_date + " to " + item.end_date,
    startDate: formatDateToDateMonYear(item.start_date),
    endDate: formatDateToDateMonYear(item.end_date),
    active: item.active,
    job_scheduler_id: item.job_scheduler_id,
    lastStatus: item.last_status,
    createdAt: formatISODateToHumanReadable(item.created_on),
    createdBy: item.created_by,
    lastEditedBy: item.last_updated_by,
    lastEditedOn: formatISODateToHumanReadable(item.last_updated_on),
    groupRulesName: item.group_rule.rule_set_name,
    groupRulesId: item.group_rule.id,
    isAdhocJob: item?.is_adhoc,
  }));
};

function JobSchedulerLanding() {
  const dispatch = useDispatch();
  const path = location.pathname;
  let idArr = path.split("/");
  dispatch(setJobSchedulerMode("create"));

  const [isLoadingAllGroupSchedules, setisLoadingAllGroupSchedules] =
    useState(true);

  useEffect(() => {
    async function fetchAllGroupSchedules() {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${JSON.parse(localStorage.getItem("token"))}`
      );

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      setisLoadingAllGroupSchedules(true);

      try {
        const response = await fetch(
          `${baseURL}/rnc/group_schedules/`,
          requestOptions
        );
        const result = await handleApiArrayResponse(response);
        setTableData(transformTableData(result.data));
        // if (response.ok) {
        //   const result = await response.json();
        //   setTableData(transformTableData(result.data));
        // } else {
        //   toast.error("Error fetching rules");
        // }
      } catch (error) {
        if (error.message) {
          toast.error(error.message, { autoClose: 3000 });
        } else {
          toast.error("Error fetching rules");
        }
      }
      setisLoadingAllGroupSchedules(false);
    }
    fetchAllGroupSchedules();
  }, []);

  const navigate = useNavigate();
  const baseURL = process.env.REACT_APP_SCAI_API_ENDPOINT;

  const [tableData, setTableData] = useState([]);
  const [stateColumnValues, setStateColumnValues] = useState([]);
  const [level, setLevel] = useState("org");
  const [openDeleteJobModal, setOpenDeleteJobModal] = useState(false);
  const [selectedJob, setSelectedJob] = useState({});

  const deleteQuery = async (id) => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${JSON.parse(localStorage.getItem("token"))}`
    );

    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${baseURL}/rnc/group_schedules/${id}/`,
        requestOptions
      );
      const result = await handleApiArrayResponse(response);
      navigate("/snp/inventory/management/job-scheduler");
      // if (response.ok) {
      //   navigate("/snp/inventory/management/job-scheduler");
      // } else {
      //   toast.error("Error fetching rules");
      //   return [];
      // }
    } catch (error) {
      if (error.message) {
        toast.error(error.message, { autoClose: 3000 });
      } else {
        toast.error("Error fetching rules");
      }

      return [];
    }
  };

  const handleDelete = async (id) => {
    try {
      let resp = await deleteQuery(id);
      if (resp.length !== 0) {
        toast.success("Deletion was successful");
        setTableData((td) => td.filter((item) => item.id !== id));
      }
    } catch (error) {
      console.error("Error deleting group schedule:", error);
      toast.error("Error in deleting");
      // Handle the error.
    }
  };

  const editSchedule = (row) => {
    let {
      id,
      name,
      jobType,
      jobCategory,
      jobCategoryId,
      cron,
      period,
      active,
      groupRulesName,
      groupRulesId,
      jobTypeId,
      isAdhocJob,
    } = row.original;
    let dateArray = null;
    let startDate = null;
    let endDate = null;
    if (!isAdhocJob) {
      dateArray = period.replaceAll(" ", "").split("to");
      startDate = dateArray[0];
      endDate = dateArray[1];
    }
    let jobTypeSelected = { value: jobTypeId, label: jobType };
    let jobCategorySelected = { label: jobCategory, value: jobCategoryId };
    let jobConfigurationSelected = {
      label: groupRulesName,
      value: groupRulesId,
    };
    dispatch(setJobSchedulerMode("edit"));
    dispatch(
      setJobSchedulerProps({
        name,
        jobTypeSelected,
        jobCategorySelected,
        jobConfigurationSelected,
        startDate,
        endDate,
        cron,
        active,
        id,
        isAdhocJob,
      })
    );
    navigate(`/${idArr[1]}/${idArr[2]}/${idArr[3]}/job-scheduler-edit/${id}`);
  };
  const editConfiguration = (row) => {
    let { id, groupRulesId, jobCategoryId } = row.original;
    dispatch(setConfigurationMode("edit"));
    navigate(
      `/${idArr[1]}/${idArr[2]}/${idArr[3]}/config-edit/${row.original.groupRulesId}`,
      {
        state: {
          isJobSchedulerConfig: true,
          group_schedule: id,
          group_rule: groupRulesId,
          group_config: jobCategoryId,
        },
      }
    );
  };
  const jsHistoryHandler = (row) => {
    navigate(
      `/${idArr[1]}/${idArr[2]}/${idArr[3]}/job-scheduler-history/${row.original.id}`
    );
  };
  const deleteSchedule = (row) => {
    console.log({ row });
    setOpenDeleteJobModal(true);
    setSelectedJob(row.original);
    // handleDelete(row.original.id);
  };

  const actionButtonsArray = [
    ({ row, props }) => (
      <button
        className="table-action-button  js-action-btn edit-button"
        onClick={() => editSchedule(row)}
      >
        <EditIcon color="#3D445C" height={12} />
      </button>
    ),
    ({ row }) => (
      <button
        className="table-action-button   js-action-btn setting-button"
        onClick={() => editConfiguration(row)}
      >
        <SettingIcon color="#3D445C" height={12} />
      </button>
    ),
    ({ row }) => (
      <button
        className="table-action-button   js-action-btn config-button"
        onClick={() => jsHistoryHandler(row)}
      >
        <ConfigIcon color="#3D445C" height={12} />
      </button>
    ),
    ({ row }) => (
      <button
        className="table-action-button  js-action-btn delete-button"
        onClick={() => deleteSchedule(row)}
      >
        <DeleteIcon color="#3D445C" height={12} />
      </button>
    ),
  ];

  const renderLevelButtons = () => {
    return (
      <div className="header-btns">
        <button
          onClick={() => setLevel("org")}
          className={
            level === "org"
              ? "header-level-button header-active-btn"
              : "header-level-button"
          }
        >
          User
        </button>
        <button
          onClick={() => {
            console.log("clicled");
            setLevel("group");
          }}
          className={
            level === "group"
              ? "header-level-button header-active-btn"
              : "header-level-button"
          }
        >
          System
        </button>
      </div>
    );
  };

  const jobStatusArray = [
    (row1) => {
      const { row } = row1;
      return (
        <div className="active-cell status-col">
          <div className={`status-indicator `}></div>
          <span className=""> {row.original.lastStatus}</span>
        </div>
      );
    },
  ];

  return (
    <>
      <div
        className="snp-table-container"
        style={{ width: `calc( 100% - 20px)`, marginLeft: "10px" }}
      >
        <UploadModal
          openModal={openDeleteJobModal}
          setOpenModal={setOpenDeleteJobModal}
          handleDelete={handleDelete}
          selectedJob={selectedJob}
          modalType="job-delete"
        />
        <MuiTable
          columns={[
            {
              accessorKey: "name",
              header: "Job Name",
              enableColumnOrdering: false,
              enableEditing: false,
              enableSorting: true,
              size: 140,
              Cell: ({ cell, row }) => {
                return NormalCell(cell, row);
              },
            },
            {
              accessorKey: "jobType",
              header: "Job Type",
              enableColumnOrdering: false,
              enableEditing: false,
              enableSorting: true,
              size: 180,
              Cell: ({ cell, row }) => {
                return NormalCell(cell, row);
              },
              Filter: ({ header }) => (
                <Autocomplete
                  onChange={(e, newValue) => {
                    setStateColumnValues(newValue);
                    header.column.setFilterValue(newValue);
                  }}
                  sx={{
                    width: 160,
                    "& .MuiAutocomplete-clearIndicator": {
                      display: "none",
                    },
                  }}
                  multiple
                  size="small"
                  id="checkboxes-tags-demo"
                  options={["RP", "PP"].sort((a, b) => {
                    let ai = stateColumnValues.indexOf(a);
                    ai =
                      ai === -1
                        ? stateColumnValues.length + ["RP", "PP"].indexOf(a)
                        : ai;
                    let bi = stateColumnValues.indexOf(b);
                    bi =
                      bi === -1
                        ? stateColumnValues.length + ["RP", "PP"].indexOf(b)
                        : bi;
                    return ai - bi;
                  })}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option}
                  renderTags={() => null}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={CheckBoxBlankIcon}
                        checkedIcon={checkSharpIcon}
                        style={{ width: "20%", margin: "0", padding: "0" }}
                        checked={selected}
                      />
                      {option}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label="" placeholder="" />
                  )}
                />
              ),
              filterFn: "arrIncludesSome",
            },

            {
              accessorKey: "categoryType",
              header: "Group Type",
              enableColumnOrdering: false,
              enableEditing: false,
              enableSorting: true,
              size: 150,
              Cell: ({ cell, row }) => {
                return NormalCell(cell, row);
              },
            },
            {
              accessorKey: "jobCategory",
              header: "Group",
              enableColumnOrdering: false,
              enableEditing: false,
              enableSorting: true,
              size: 140,
              Cell: ({ cell, row }) => {
                return NormalCell(cell, row);
              },
            },
            {
              accessorKey: "configuration",
              header: "Configuration",
              enableColumnOrdering: false,
              enableEditing: false,
              enableSorting: true,
              size: 160,
              Cell: ({ cell, row }) => {
                return NormalCell(cell, row);
              },
            },
            {
              accessorKey: "cron_str",
              header: "Schedule",
              enableColumnOrdering: false,
              enableEditing: false,
              enableSorting: true,
              size: 140,
              Cell: ({ cell, row }) => {
                return NormalCell(cell, row);
              },
            },
            {
              accessorKey: "startDate",
              header: "Start Date",
              enableColumnOrdering: false,
              enableEditing: false,
              enableSorting: true,
              size: 140,
              Cell: ({ cell, row }) => {
                return NormalCell(cell, row);
              },
            },
            {
              accessorKey: "endDate",
              header: "End Date",
              enableColumnOrdering: false,
              enableEditing: false,
              enableSorting: true,
              size: 140,
              Cell: ({ cell, row }) => {
                return NormalCell(cell, row);
              },
            },
            {
              accessorKey: "active",
              header: "Active Status",
              enableColumnOrdering: false,
              enableEditing: false,
              enableSorting: true,
              size: 140,
              Cell: ({ cell, row }) => {
                return NormalCell(cell, row);
              },
            },
            {
              accessorKey: "lastStatus",
              header: "Last Status",
              enableColumnActions: true,
              muiTableBodyCellEditTextFieldProps: {
                select: true, //change to select for a dropdown
              },
              size: 210,
              Cell: ({ cell, row }) => {
                const centerAlign = false;
                return RenderMultipleButtons(
                  jobStatusArray,
                  { row },
                  centerAlign
                );
              },
              Filter: ({ header }) => (
                <Autocomplete
                  onChange={(e, newValue) => {
                    setStateColumnValues(newValue);
                    header.column.setFilterValue(newValue);
                  }}
                  sx={{
                    width: 190,
                    "& .MuiAutocomplete-clearIndicator": {
                      display: "none",
                    },
                  }}
                  multiple
                  size="small"
                  id="checkboxes-tags-demo"
                  options={["Success", "Running", "Failed"].sort((a, b) => {
                    let ai = stateColumnValues.indexOf(a);
                    ai =
                      ai === -1
                        ? stateColumnValues.length +
                          ["Success", "Running", "Failed"].indexOf(a)
                        : ai;
                    let bi = stateColumnValues.indexOf(b);
                    bi =
                      bi === -1
                        ? stateColumnValues.length +
                          ["Success", "Running", "Failed"].indexOf(b)
                        : bi;
                    return ai - bi;
                  })}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option}
                  renderTags={() => null}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={CheckBoxBlankIcon}
                        checkedIcon={checkSharpIcon}
                        style={{
                          width: "20%",
                          margin: "0",
                          padding: "0",
                        }}
                        checked={selected}
                      />
                      {option}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label="" placeholder="" />
                  )}
                />
              ),
              filterFn: "arrIncludesSome",
            },
            {
              accessorKey: "createdAt",
              header: "Created At",
              enableColumnOrdering: false,
              enableEditing: false,
              enableSorting: true,
              size: 140,
              Cell: ({ cell, row }) => {
                return NormalCell(cell, row);
              },
            },
            {
              accessorKey: "createdBy",
              header: "Created By",
              enableColumnOrdering: false,
              enableEditing: false,
              enableSorting: true,
              size: 140,
              Cell: ({ cell, row }) => {
                return NormalCell(cell, row);
              },
            },
            {
              accessorKey: "lastEditedBy",
              header: "Last Edited By",
              enableColumnOrdering: false,
              enableEditing: false,
              enableSorting: true,
              size: 140,
              Cell: ({ cell, row }) => {
                return NormalCell(cell, row);
              },
            },
            {
              accessorKey: "lastEditedOn",
              header: "Last Edited On",
              enableColumnOrdering: false,
              enableEditing: false,
              enableSorting: true,
              size: 140,
              Cell: ({ cell, row }) => {
                return NormalCell(cell, row);
              },
            },
            {
              accessorKey: "action",
              header: "Actions",
              enableColumnActions: false,
              enableSorting: false,
              size: 240,
              muiTableBodyCellEditTextFieldProps: {
                select: true, //change to select for a dropdown
              },
              Cell: ({ cell, row }) => {
                const centerAlign = true;
                const multipleElements = true;
                return RenderMultipleButtons(
                  actionButtonsArray,
                  { row },
                  centerAlign,
                  multipleElements
                );
              },
            },
          ]}
          isLoading={isLoadingAllGroupSchedules}
          td={tableData}
          createPath={`/${idArr[1]}/${idArr[2]}/${idArr[3]}/job-scheduler-create`}
          renderTopToolbarCustomActions={renderLevelButtons}
        />
      </div>
    </>
  );
}

export default JobSchedulerLanding;
