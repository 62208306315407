import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  createTheme,
  Skeleton,
  ThemeProvider,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import InventoryStatistics from "../RawMaterial/Dashboard/Charts/InventoryStatistics";
import DemandOverview from "../RawMaterial/Dashboard/Charts/DemandOverview";
import { KpiCardPP } from "../../components/kpi_cards_copy/kpi_card_pp";
import {
  useDemandOverviewQuery,
  useInventoryStatisticsQuery,
} from "../../features/api/inventorySlice";
import { PerformanceIndicator } from "../../components/progressbar/performance";
import RevenueOutlook3 from "./RevenueOutlook2";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../utils/components";
import { useNavigate } from "react-router-dom";

const StyledCard2 = styled("div")(({ theme }) => ({
  ...theme.typography.body1,
  borderRadius: "7px",
  border: "1px solid #E6E6E6",
  // padding: theme.spacing(1),
  display: "flex",
  flexDirection: "column",
  //   flexWrap: "wrap",
}));

const StyledButton = styled("button")(() => ({
  display: "inline-flex",
  justifyContent: "space-between",
  alignItems: "center",
  background: "#F6F8F9",
  marginTop: "10px",
  marginRight: "10px",
  paddingTop: "2px",
  paddingBottom: "2px",
  paddingLeft: "5px",
  paddingRight: "5px",
  fontSize: "12px",
  fontWeight: "700",
  borderRadius: "3px",
  transition: "all 0.5s ease",
  "&:hover": {
    "& svg": {
      visibility: "visible",
      opacity: 1,
    },
    background: "#FF8251",
  },
  "& svg": {
    visibility: "visible",
    opacity: "1",
    color: "#000000",
    transition: "visibility 0s, opacity 0.5s linear",
    width: "18px",
    height: "18px",
  },
}));

const HeadingDiv = styled("div")(({ theme }) => ({
  ...theme.typography.customHeading,
  fontFamily: "IBM Plex sans",
  fontWeight: "400",
  paddingLeft: "20px",
  marginTop: "10px",
  background: "#fff",
}));

const ViewMore = styled("div")(() => ({
  color: "#3D445C",
  fontFamily: "IBM Plex Sans",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: "500",
  marginRight: "2px",
}));

function useResizeObserver() {
  const [componentWidth, setComponentWidth] = React.useState(null);
  const [componentHeight, setComponentHeight] = React.useState(null);
  const targetRef = React.useRef(null);

  React.useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target === targetRef.current) {
          setComponentWidth(entry.contentRect.width);
          setComponentHeight(entry.contentRect.height);
        }
      }
    });

    if (targetRef.current) {
      resizeObserver.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        resizeObserver.unobserve(targetRef.current);
      }
    };
  }, []);

  return { targetRef, componentWidth, componentHeight };
}

const InventoryDashBoard = () => {
  const navigate = useNavigate();
  const { targetRef, componentWidth, componentHeight } = useResizeObserver();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const {
    data: demandOverviewData,
    isLoading: isLoadingDemandOverview,
    isSuccess: isSuccessDemandOverview,
  } = useDemandOverviewQuery(undefined, {
    refetchOnMountOrArgChange: true,
    force: true, // This will bypass the cache
  });

  const {
    data: invStatsData,
    isLoading: isLoadingInvStats,
    isSuccess: isSuccessInvStats,
  } = useInventoryStatisticsQuery(undefined, {
    refetchOnMountOrArgChange: true,
    force: true, // This will bypass the cache
  });
  console.log({ invStatsData });
  const snpWidth = document.getElementById("snp")?.offsetWidth;

  return (
    <ThemeProvider theme={customTheme}>
      <Box
        sx={{
          boxSizing: "border-box",
          display: "flex",
          height: "100vh",
          maxHeight: "100vh",
          width: "100%",
          flexDirection: "column",
          padding: "10px",
        }}
      >
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            expanded={expanded === "panel1"}
            className="box-shadow-small"
            fill="white"
            hoverColor="#e94f1c"
            backgroundColor="#494949"
            expandedBgColor="#e94f1b"
          >
            <Typography
              sx={{
                width: "33%",
                flexShrink: 0,
                fontSize: "12px",
                color: "white",
              }}
            >
              KPI&apos;s
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <KpiCardPP />
          </AccordionDetails>
        </Accordion>
        <Box
          sx={{
            display: "grid",
            flexGrow: "1",
            gridTemplateRows: "repeat(2, 300px)",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "10px",
            paddingBottom: "10px",
          }}
        >
          <StyledCard2 ref={targetRef}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                // height: "10%",
              }}
            >
              <HeadingDiv>Demand Overview</HeadingDiv>
              <StyledButton onClick={() => navigate("demand-overview")}>
                {/* <ViewMore>View more data</ViewMore> */}
                <ArrowForwardIcon
                  sx={{ color: "grey", visibility: "visible" }}
                />
              </StyledButton>
            </Box>
            <Box
              sx={{
                display: "grid",
                // height: "90%",
                width: "100%",
              }}
            >
              {/* {isLoadingDemandOverview && (
                <Skeleton
                  variant="rounded"
                  width={componentWidth}
                  height={0.8 * componentHeight}
                  animation="wave"
                />
              )} */}
              {/* {isSuccessDemandOverview && ( */}
              <DemandOverview
                componentWidth={componentWidth}
                snpWidth={snpWidth}
                data={demandOverviewData}
              />
              {/* )} */}
            </Box>
          </StyledCard2>
          <StyledCard2>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                // height: "10%",
              }}
            >
              <HeadingDiv>FG Inventory Statistics</HeadingDiv>
              <StyledButton onClick={() => navigate("inventory-stats")}>
                {/* <ViewMore>View more data</ViewMore> */}
                <ArrowForwardIcon
                  sx={{ color: "grey", visibility: "visible" }}
                />
              </StyledButton>
            </Box>
            <Box
              sx={{
                display: "grid",
                // height: "90%",
                width: "100%",
                overflow: "hidden",
              }}
            >
              {/* {invStatsData ? ( */}
              <InventoryStatistics
                componentWidth={componentWidth}
                snpWidth={snpWidth}
                data={invStatsData}
              />
              {/* ) : null} */}
            </Box>
          </StyledCard2>
          <StyledCard2>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                // height: "10%",
              }}
            >
              <HeadingDiv>Current Plan</HeadingDiv>
              {/* <StyledButton onClick={() => navigate("inventory-stats")}>
                <ViewMore>View more data</ViewMore>
                <ArrowForwardIcon sx={{ color: "grey" }} />
              </StyledButton> */}
            </Box>
            <Box
              sx={{
                display: "grid",
                // height: "90%",
                width: "100%",
                overflow: "hidden",
              }}
            >
              <RevenueOutlook3
                componentWidth={componentWidth}
                snpWidth={snpWidth}
              />
            </Box>
          </StyledCard2>
          <StyledCard2>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                // height: "10%",
              }}
            >
              <HeadingDiv>Performance</HeadingDiv>
              {/* <StyledButton onClick={() => navigate("inventory-stats")}>
                <ViewMore>View more data</ViewMore>
                <ArrowForwardIcon sx={{ color: "grey" }} />
              </StyledButton> */}
            </Box>
            <Box
              sx={{
                display: "grid",
                // height: "90%",
                width: "100%",
                marginTop: "10px",
              }}
            >
              <PerformanceIndicator
                title={"Inventory Turnover Ratio"}
                percentage={92.4}
                text={"Actual (14.7)/Target (15.9)"}
              />
              <PerformanceIndicator
                title={"Service Level"}
                percentage={89.7}
                text={"Target: 96%"}
              />
              <PerformanceIndicator
                title={"Fill Rate"}
                percentage={83}
                text={"Target: 90%"}
              />
              <PerformanceIndicator
                title={"OTIF"}
                percentage={91.2}
                text={"Actual (10408)/Target (11438)"}
              />
              <PerformanceIndicator
                title={"Stock Out Rate"}
                percentage={30}
                text={"Target: 2%"}
              />
            </Box>
          </StyledCard2>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

const customTheme = createTheme({
  palette: {
    primary: {
      main: "#f5f7fa",
    },
  },
  typography: {
    fontFamily: "Inter, sans-serif",
    fontSize: 12,
    fontWeightBold: 700,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightLight: 300,
    customBoldFont: {
      fontWeight: 600,
      fontSize: 13,
    },
    customLightFont: {
      fontSize: 11,
      fontWeight: 400,
    },
    customHeading: {
      fontWeight: 300,
      fontSize: 15,
    },
  },
  components: {
    MuiDrawer: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});

export default InventoryDashBoard;
