import { useState, useEffect } from "react";
import MuiTable from "../../../components/muiTable/muiTable";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useViewProfilingQuery,
  useViewQuery,
} from "../../../features/api/preprocessesSlice";
import "./styles.css";
import { BreadcrumbTemp } from "../../../components/job-schedular/jsConfigScreen";
import { getColumns } from "./columns";
import {
  formatISODateToHumanReadable,
  twoDecimalNumberFormat,
} from "../../../utils/utils";
import { handleDownload, handleProcessType } from "./preprocessUtils";
import UploadModal from "../../../components/modal/UploadModal";

const getProcessTypeValues = (item, type, processType) => {
  if (processType === "classification") {
    return item[`${type}_class`];
  } else if (processType === "norms") {
    if (type === "msl") return item[`max_stock`];
    else if (type === "misl") {
      return twoDecimalNumberFormat(item[`min_stock`]);
    } else if (type === "ss") {
      return twoDecimalNumberFormat(item[`safety_stock`]);
    } else if (type === "dos") {
      return item[`days_of_stock`];
    }
  }
};

const transformTableData = (arr, type, processType) => {
  return arr.map((item) => ({
    skuName:
      processType === "classification"
        ? item.product.sku_name
        : item.sku.sku_name,
    skuCode:
      processType === "classification"
        ? item.product.sku_code
        : item.sku.sku_code,
    channelName: item.channel.channel_name,
    nodeName:
      processType === "classification" ? item.location.node : item.node.node,
    nodeCode:
      processType === "classification"
        ? item.location.node_code
        : item.node.node_code,
    classification: getProcessTypeValues(item, type, processType),
  }));
};

const transformProfilingTableData = (arr) => {
  return arr.map((item) => ({
    skuName: item.product.sku_name,
    skuCode: item.product.sku_code,
    channelName: item.channel.channel_name,
    nodeName: item.location.node,
    nodeCode: item.location.node_code,
    inventoryLevel: item.inventory_level,
    lostSales: twoDecimalNumberFormat(item.lost_sales),
    inventoryPosition: twoDecimalNumberFormat(item.inventory_position),
    date: formatISODateToHumanReadable(item.date),
  }));
};

function ProcessView(props) {
  const location = useLocation();
  const path = location.pathname;
  let pathSplit = path.split("/");
  let len = pathSplit.length;
  const type = pathSplit[len - 1];
  const navigate = useNavigate();
  const { state } = useLocation();
  const { processType, preprocessRow } = state;

  let idArr = path.split("/");
  let skipViewQuery = false;
  if (idArr[6] === "profiling") {
    skipViewQuery = true;
  }
  const columns = getColumns({ skipViewQuery, type, processType });
  const {
    data: processData,
    isFetching: isLoadingProcessView,
    isSuccess: isSuccessProcessView,
    refetch: refetchProcessData,
  } = useViewQuery(
    { type, processType },
    {
      skip: skipViewQuery,
      refetchOnMountOrArgChange: true,
      force: true, // This will bypass the cache
    }
  );

  const {
    data: profilingData,
    isFetching: isLoadingProfiling,
    refetch: refetchProfilingData,
  } = useViewProfilingQuery(
    {},
    {
      skip: !skipViewQuery,
      refetchOnMountOrArgChange: true,
      force: true, // This will bypass the cache,
    }
  );
  const [tableData, setTableData] = useState([]);
  const [isDownloading, setIsDownloading] = useState();
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    if (processData) {
      setTableData(
        processData
          ? transformTableData(processData.data, type, processType)
          : []
      );
    }
    if (profilingData) {
      setTableData(
        profilingData ? transformProfilingTableData(profilingData.data) : []
      );
    }
  }, [processData, profilingData]);

  const getProcessName = (type, processType, skipViewQuery) => {
    if (skipViewQuery) {
      return "Inventory Profiling";
    }
    if (processType === "classification") {
      return `CSE_${type.toUpperCase()}`;
    } else if (processType === "norms") {
      let processPath = "";
      if (type === "msl") {
        processPath = "Max Stock Level";
      } else if (type === "misl") {
        processPath = "Min Stock Level";
      } else if (type === "dos") {
        processPath = "Days of Stock";
      } else if (type === "ss") {
        processPath = "Safety Stock";
      }
      return `Inventory Norm - ${processPath} `;
    }
  };

  const renderProcessHeader = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          alignSelf: "center",
        }}
        className="process-header"
      >
        <div
          className="process-header-1"
          onClick={() =>
            navigate(`/${idArr[2]}/${idArr[3]}/${idArr[4]}/preprocesses`)
          }
        >
          Preprocesses
        </div>
        <div className="flex">
          <BreadcrumbTemp />
          <BreadcrumbTemp />
        </div>
        <div className="process-header-2">
          {getProcessName(type, processType, skipViewQuery)}
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        className="snp-table-container"
        style={{ width: `calc( 100% - 20px)`, marginLeft: "10px" }}
      >
        <UploadModal
          openModal={openUploadModal}
          setOpenModal={setOpenUploadModal}
          classType={handleProcessType(preprocessRow)}
          uploadType="process"
          selectedProcess={preprocessRow}
          setIsUploading={setIsUploading}
          refetch={
            idArr[6] === "profiling" ? refetchProfilingData : refetchProcessData
          }
          moduleType="rp"
        />
        <MuiTable
          columns={columns}
          isLoading={skipViewQuery ? isLoadingProfiling : isLoadingProcessView}
          td={tableData}
          renderTopToolbarCustomActions={renderProcessHeader}
          shouldShowAddBtn={false}
          showDownloadIcon={true}
          showUploadIcon={true}
          handleDownload={() =>
            handleDownload(
              { process: type, processTypes: processType },
              type,
              processType,
              isDownloading,
              setIsDownloading,
              "process"
            )
          }
          handleUpload={() => setOpenUploadModal(true)}
          isUploading={isUploading}
          isDownloading={isDownloading}
          enableUpload={preprocessRow.preprocessAction.includes("upload")}
          enableDownload={preprocessRow.preprocessAction.includes("download")}
        />
      </div>
    </>
  );
}

export default ProcessView;
