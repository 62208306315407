import { useNavigate } from "react-router-dom";
import { BreadcrumbTemp } from "../../../../components/job-schedular/jsConfigScreen";

export const Breadcrumb = ({
  isInvHealth,
  isInvProfile,
  isStockLevelTable,
}) => {
  const navigate = useNavigate();
  let breadCrumbTitle = "";
  if (isInvHealth) {
    breadCrumbTitle = "Inventory Health & Classification";
  }
  if (isInvProfile) {
    breadCrumbTitle = "Inventory Profile";
  }
  if (isStockLevelTable) {
    breadCrumbTitle = "Stock Level Table";
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "10px",
        marginBottom: "10px",
        marginLeft: "10px",
        marginTop: "10px",
      }}
      className="inv-stats-breadcrumb"
    >
      <div
        className="inv-mgmt-link"
        onClick={() => navigate("/snp/inventory/management")}
      >
        Inventory Management
      </div>
      <div className="flex">
        <BreadcrumbTemp />
        <BreadcrumbTemp />
      </div>
      <div
        className="inv-mgmt-link"
        onClick={() => navigate("/snp/inventory/management/inventory-stats")}
      >
        Inventory Statistics
      </div>
      <div className="flex">
        <BreadcrumbTemp />
        <BreadcrumbTemp />
      </div>
      <div className="inv-stats-text">{breadCrumbTitle}</div>
    </div>
  );
};
