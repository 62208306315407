import { useState, useEffect, useRef, createRef, Fragment } from "react";
import MuiTable from "../../components/muiTable/muiTable";
import { useNavigate, useLocation } from "react-router-dom";
import KPICard from "../../components/kpi_cards/KPICard";
import UploadModal from "../../components/modal/UploadModal";
import {
  PlanStatus,
  RenderMultipleButtons,
  transformStatus,
} from "../../components/tableUtils/tableUtils";
import {
  ApprovalStatus,
  ViewPageButton,
  SendRevokeAction,
  NormalCell,
  TableLoaderSpinner,
} from "../../components/tableUtils/tableUtils";
import "./ProductionPlan.scss";
import {
  useMakePpPlanLiveMutation,
  usePpAppGroupPlansQuery,
  usePpAppOrgPlansQuery,
} from "../../features/api/prodPlanSlice";
import { formatDate } from "../../utils/utils";
import { useGetApprovalCommentsMutation } from "../../features/api/replPlanSlice";
import { toast } from "react-toastify";
import { handleApiArrayResponse } from "../../utils/apiUtils";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../utils/components";
import { Typography } from "@mui/material";
import { DataTable } from "@/components/tanstack-table/data-table";
import { DataTableColumnHeader } from "@/components/tanstack-table/data-table-column-header";
import { ppGroupData, ppOrgData } from "./Tables/data";
import { loadingColumns, ppGroupColumns, ppOrgColumns } from "./Tables/columns";
import { DataTableToolbar } from "@/components/tanstack-table/data-table-toolbar";
import "./Tables/styles.css";
import { setPpGroupPlanViewDetails } from "@/features/ppGroupPlanViewSlice";
import { useDispatch } from "react-redux";

const transformTableData = (arr) => {
  return arr.map((item) => {
    const baseObject = {
      planId: item.plan_id,
      cycleId: item.cycle_id,
      planType: item.plan_type,
      mps: "-",
      dps: "-",
      createdAt: formatDate(item.created_at),
      lastUpdatedAt: formatDate(item.last_updated_at),
      updatedBy: item.updated_by,
      jobType: item.adhoc === "False" ? "Scheduled" : "-",
      runTime: "-",
      buCode: item.bu_code,
      runtime: item.run_time,
      cycle: item.cycle,
      planStatus:
        item.app_status === "Approval_In_Progress"
          ? "Approval In Progress"
          : item.plan_status,
      approvalId: item.approval_id,
      isPlanLive: item.plan_live ? item.plan_live : false,
    };
    if (item.approval_id !== null) {
      return {
        ...baseObject,
        approvalDetails: {
          approvalId: item.approval_id,
          permissions: {
            canSubmit: item.approval_details.permissions.canSubmit,
            canApprove: item.approval_details.permissions.canApprove,
            canRevoke: item.approval_details.permissions.canRevoke,
            canReject: item.approval_details.permissions.canReject,
          },
          approvalStatus: item.approval_details.approval_status,
          levelwiseApprovalStatus:
            item.approval_details.levelwise_approval_status.map((element) => ({
              level: element.level,
              status: element.approval_status,
            })),
        },
      };
    }
    return baseObject;
  });
};

const transformAppOrgLevelData = (arr) => {
  return arr.map((item) => ({
    cycleId: item.cycle_id,
    cyclePeriod: `${item.cycle_start_date} to ${item.cycle_end_date}`,
    status: item.status === "In_progress" ? "In- Progress" : item.status,
    mps: "2 Plans",
  }));
};

function initialLevel(state) {
  const level_local = localStorage.getItem("snp-pp-level");
  if (level_local) {
    return level_local;
  } else if (state && state.planLevel) {
    return state.planLevel;
  } else {
    return "org";
  }
}

function getLevel(state, orgLevelEnable, groupLevelEnable) {
  if (
    (orgLevelEnable === false && initialLevel(state) === "org") ||
    (initialLevel(state) === "group" && groupLevelEnable === true)
  ) {
    return "group";
  }
  return "org";
}

function ProductionPlanning() {
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = useLocation();

  const tabState = location.state?.tab || "APP";
  const levelState = location.state?.level || "org";

  const [tableData, setTableData] = useState(Array(10).fill({}));
  const [ppOrgTableData, setPpOrgTableData] = useState(Array(10).fill({}));
  const [ppGroupTableData, setPpGroupTableData] = useState(Array(10).fill({}));
  const [ppOrgLevelColumns, setPpOrgLevelColumns] = useState(loadingColumns());
  const [ppGroupLevelColumns, setPpGroupLevelColumns] = useState(
    loadingColumns()
  );
  const [isLoading, setIsLoading] = useState(true);

  const [openSendModal, setOpenSendModal] = useState(false);
  const [selectedButton, setSelectedButton] = useState(tabState);
  const [planId, setPlanId] = useState("");
  const [approvalStatusValues, setApprovalStatusValues] = useState([]);
  const [selectedApp, setSelectedApp] = useState({});
  const [openApprovalCommentsModal, setopenApprovalCommentsModal] =
    useState(false);
  const [approvalComments, setApprovalComments] = useState([]);
  const [loadingComments, setLoadingComments] = useState(false);
  const [openApproveRejectModal, setOpenApproveRejectModal] = useState(false);
  const [approveRejectValue, setApproveRejectValue] = useState(null);
  const [selectedGroupPlan, setSelectedGroupPlan] = useState({});
  const [loading, setloading] = useState(false);
  const [openMakeLiveModal, setOpenMakeLiveModal] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [productionType, setProductionType] = useState("APP");
  let userDetails = JSON.parse(localStorage.getItem("user"));
  const { permissions } = userDetails;
  const orgLevelEnable = permissions.includes("PP_ORG_VIEW");
  const groupLevelEnable = permissions.includes("PP_GROUP_VIEW");
  const [level, setLevel] = useState(
    getLevel(state, orgLevelEnable, groupLevelEnable)
  );
  const dispatch = useDispatch();
  const isPlanner = permissions.includes("PP_SUBMIT_APPROVAL");
  const isApprover = permissions.includes("PP_APPROVE_REJECT");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const savedColumns = useRef();

  const {
    data: ppAppGroupPlansData,
    refetch: refetchppAppGroupPlansData,
    isLoading: isLoadingPpAppGroupPlansData,
    isSuccess: isSuccessPpAppGroupPlansData,
  } = usePpAppGroupPlansQuery(undefined, {
    skip: level === "org",
    refetchOnMountOrArgChange: true,
    force: true, // This will bypass the cache
  });

  const {
    data: ppAppOrgPlansData,
    isLoading: isLoadingPpAppOrgPlansData,
    isSuccess: isSuccessPpAppOrgPlansData,
  } = usePpAppOrgPlansQuery(undefined, {
    skip: level === "group",
    refetchOnMountOrArgChange: true,
    force: true,
  });

  const [getApprovalComments, { isLoading: loadingApprovalComments, isError }] =
    useGetApprovalCommentsMutation();

  const [planLive, { isLoading: loadingMakePlanLive }] =
    useMakePpPlanLiveMutation();

  const ppOrgColumnHeaders = [
    ["cycleId", "Cycle ID"],
    ["cyclePeriod", "Cycle Period"],
  ];

  const ppGroupColumnHeaders = [
    ["planId", "Plan ID"],
    ["cycleId", "Cycle ID"],
    // ["dps", "DPS"],
    ["buCode", "Group Code"],
    // ["runTime", "Run Time"],
    ["createdAt", "Created At"],
    ["lastUpdatedAt", "Updated At"],
    ["updatedBy", "Updated By"],
    ["planType", "Plan Type"],
  ];

  useEffect(() => {
    if (level === "org" && selectedButton == "APP") {
      ppAppOrgPlansData
        ? setPpOrgTableData(transformAppOrgLevelData(ppAppOrgPlansData))
        : [];
      isLoadingPpAppOrgPlansData === true
        ? setPpOrgLevelColumns(loadingColumns())
        : setPpOrgLevelColumns(ppOrgColumns(ppOrgColumnHeaders));
    } else if (level === "group" && selectedButton == "APP") {
      ppAppGroupPlansData
        ? setPpGroupTableData(transformTableData(ppAppGroupPlansData))
        : [];
      if (isLoadingPpAppGroupPlansData === true) {
        setPpGroupLevelColumns(loadingColumns());
      } else {
        let cols = ppGroupColumns(
          ppGroupColumnHeaders,
          setopenApprovalCommentsModal,
          getComments,
          handleMakeLive,
          setSelectedGroupPlan,
          setOpenSendModal,
          setApproveRejectValue,
          setOpenApproveRejectModal,
          handlePlanDetails,
          isPlanner,
          isApprover
        );
        setPpGroupLevelColumns(cols);
        savedColumns.current = cols;
      }
    }

    setIsLoading(false);
  }, [level, selectedButton, ppAppOrgPlansData, ppAppGroupPlansData]);

  useEffect(() => {
    if (loading === true) {
      setPpGroupLevelColumns(loadingColumns());
    } else {
      let cols = savedColumns.current;
      setPpGroupLevelColumns(cols);
    }
  }, [loading]);

  const handlePlanDetails = (row) => {
    dispatch(setPpGroupPlanViewDetails({ ...row }));
  };

  const getApprovalStatusValues = (data) => {
    let approvalStatusValuesTemp = {};
    data.forEach((item) => {
      approvalStatusValuesTemp[item.app] = false;
    });
    setApprovalStatusValues(approvalStatusValuesTemp);
  };

  const renderLevelButtons = () => {
    return (
      <div className="pp-level-btns">
        <button
          onClick={() => {
            localStorage.setItem("snp-pp-level", "org");
            setLevel("org");
          }}
          className={
            level === "org"
              ? "pp-level-button pp-active-btn"
              : "pp-level-button"
          }
        >
          ORG
        </button>
        <button
          onClick={() => {
            localStorage.setItem("snp-pp-level", "group");
            setLevel("group");
          }}
          className={
            level === "group"
              ? "pp-level-button pp-active-btn"
              : "pp-level-button"
          }
        >
          Group
        </button>
      </div>
    );
  };

  const actionButtonsArray = [ViewPageButton];
  const approvalStatusOrgArray = [ApprovalStatus];
  const planStatusGrpArray = [PlanStatus];
  const columnOrder = [
    "mpsPlanId",
    "mpsAppPlanID",
    "cycle",
    "status",
    "groupLevelAggregation",
  ];
  const approvalStatusGrpArray = [ApprovalStatus];

  const renderLeftTableFooterGroupLevel = () => {
    return (
      <div className="table-left-bottom">
        <div className="table-left-bottom-footer">
          <p>Ongoing Cycle : 16-06-23 12:00 AM -11:59 PM</p>
          <p>Upcoming Cycle : 16-06-23 12:00 AM -11:59 PM</p>
        </div>
      </div>
    );
  };

  const handleMakeLive = (row) => {
    setSelectedGroupPlan({ ...row });
    setOpenMakeLiveModal(true);
  };

  const makePlanLive = async (planId) => {
    const requestBody = {
      plan_id: planId,
    };
    try {
      const response = await planLive({
        planId,
        requestBody: requestBody,
      });
      if (response.error) {
        throw new Error(response.error.data.error);
      } else {
        refetchppAppGroupPlansData();
        toast.success(response.data.success, { autoClose: 3000 });
      }
    } catch (error) {
      toast.error(error.message, { autoClose: 3000 });
    }
  };

  const handlePlanApproval = async (comment, planId, approveRejectValue) => {
    setloading(true);
    const formData = new FormData();
    const approvalDetails = {
      action: approveRejectValue,
      approvable_type: "PP",
      approvables: [
        {
          id: planId,
          comment: comment,
          attachment_ids: [],
        },
      ],
    };

    formData.append("approval_details", JSON.stringify(approvalDetails));

    fetch(`${process.env.REACT_APP_SCAI_API_ENDPOINT}/v1/approval/`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
      },
      body: formData,
    })
      .then(handleApiArrayResponse)
      .then((data) => {
        const { message, approvals_info, approvable_details } = data;
        const approvableId = approvals_info[0].approvable_id;
        const indexOfObjectToUpdate = ppGroupTableData.findIndex((item) => {
          if (item.planId === approvableId) {
            return true;
          }
        });
        const planToBeUpdated = ppGroupTableData[indexOfObjectToUpdate];
        const updatedPlan = {
          ...planToBeUpdated,
          planStatus:
            approvable_details[0].status === "Approval_In_Progress"
              ? "Approval In Progress"
              : transformStatus(approvable_details[0].status),
          approvalId: approvals_info[0].approval_id,
          approvalDetails: {
            approvalId: approvals_info[0].approval_id,
            permissions: {
              canSubmit: approvals_info[0].permissions.canSubmit,
              canApprove: approvals_info[0].permissions.canApprove,
              canRevoke: approvals_info[0].permissions.canRevoke,
              canReject: approvals_info[0].permissions.canReject,
            },
            approvalStatus: approvals_info[0].approval_status,
            levelwiseApprovalStatus:
              approvals_info[0].levelwise_approval_status.map((element) => ({
                level: element.level,
                status: element.approval_status,
              })),
          },
        };
        const newTableData = [...ppGroupTableData];
        newTableData[indexOfObjectToUpdate] = updatedPlan;
        setPpGroupTableData([...newTableData]);
        toast.success(`${planId} is ${message}`, { autoClose: 3000 });
        setloading(false);
      })
      .catch((error) => {
        setloading(false);
        toast.error(error.message, { autoClose: 3000 });
      });
  };

  const getComments = async (approvalId) => {
    const requestBody = {
      approvable_type: "PP",
      approval_ids: [`${approvalId}`],
    };
    try {
      setLoadingComments(true);
      const responseApproval = await getApprovalComments({
        requestBody: requestBody,
      });

      let responseApprovalIndex =
        responseApproval.data.approval_actions.findIndex((item) => {
          if (item.approval_id === approvalId) {
            return true;
          }
        });
      const planApprovalComments =
        responseApproval.data.approval_actions[responseApprovalIndex]
          .approval_action;
      setApprovalComments([...planApprovalComments]);
      setLoadingComments(false);
    } catch (error) {
      setLoadingComments(false);
      toast.error(error.message);
    }
  };

  const ProductionPlanLevelButtons = ({ table }) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "16px",
        }}
      >
        {renderLevelButtons()}
        <DataTableToolbar table={table} />
      </div>
    );
  };

  return (
    <div style={{ width: `calc( 100% - 20px)`, margin: 10 }}>
      <UploadModal
        openModal={openSendModal}
        setOpenModal={setOpenSendModal}
        modalType="send"
        // rowId={planId}
        // handleGroupData={handleGroupData}
        approvalStatusValues={approvalStatusValues}
        setApprovalStatusValues={setApprovalStatusValues}
        selectedGroupPlan={selectedGroupPlan}
        sendModalType="ppAppGroup"
        handlePlanApproval={handlePlanApproval}
        approveRejectValue={approveRejectValue}
      />
      <UploadModal
        openModal={openApproveRejectModal}
        setOpenModal={setOpenApproveRejectModal}
        modalType="approve-reject"
        sendModalType="ppAppGroup"
        approveRejectValue={approveRejectValue}
        handlePlanApproval={handlePlanApproval}
        selectedGroupPlan={selectedGroupPlan}
      />
      <UploadModal
        openModal={openApprovalCommentsModal}
        setOpenModal={setopenApprovalCommentsModal}
        modalType="approval-comments"
        approvalComments={approvalComments}
        loadingComments={loadingComments}
      />
      <UploadModal
        openModal={openMakeLiveModal}
        setOpenModal={setOpenMakeLiveModal}
        modalType="make-live"
        moduleType="APP"
        selectedGroupPlan={selectedGroupPlan}
        makePlanLive={makePlanLive}
      />
      <Fragment>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            expanded={expanded === "panel1"}
            showTypePanel={true}
            fill="white"
            hoverColor="#e94f1c"
            backgroundColor="#494949"
            expandedBgColor="#e94f1b"
          >
            <Typography
              sx={{
                width: "33%",
                flexShrink: 0,
                fontSize: "12px",
                color: "white",
              }}
            >
              KPI&apos;s
            </Typography>
            <div style={{ display: "flex" }}>
              <button
                className={
                  productionType === "APP"
                    ? "pp-type pp-app-type pp-active-type"
                    : "pp-type pp-app-type"
                }
                onClick={() => setProductionType("APP")}
              >
                APP
              </button>
              <button
                className={
                  productionType === "MPS"
                    ? "pp-type pp-active-type"
                    : "pp-type"
                }
                onClick={() => setProductionType("MPS")}
              >
                MPS
              </button>
              <button
                className={
                  productionType === "DPS"
                    ? "pp-type pp-dps-type pp-active-type disabled:text-[#d2d2d1]"
                    : "pp-type pp-dps-type"
                }
                onClick={() => setProductionType("DPS")}
                disabled={true}
              >
                DPS
              </button>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div
              style={{
                margin: "auto",
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              <KPICard
                value="₹10536"
                volume="25039"
                heading="Total Production Requirements"
              />
              <KPICard
                value="₹10536"
                volume="22039"
                heading="Total Production Allocation"
              />{" "}
              <KPICard
                value="₹320976"
                volume="12356"
                heading="Production Work Order"
                topRowRightData="Count : 535"
              />
              <KPICard
                value="95%"
                volume="92%"
                heading="Capacity Utilization"
                topRowRightData="Count : 535"
                metric="Target"
                metricValue="Actual"
              />
              <KPICard
                value="--"
                volume="--"
                heading="Deviations"
                metric="Metric"
                metricValue="Metric"
              />
            </div>
          </AccordionDetails>
        </Accordion>
        <div className="snp-table-container" style={{ marginTop: "-5px" }}>
          <DataTable
            // CustomToolbar={GroupLevelToolbar}
            data={level === "org" ? ppOrgTableData : ppGroupTableData}
            columns={level === "org" ? ppOrgLevelColumns : ppGroupLevelColumns}
            canResizeColumns={true}
            CustomToolbar={ProductionPlanLevelButtons}
            showSelectedColumnsCount={false}
          />
        </div>
      </Fragment>
    </div>
  );
}

export default ProductionPlanning;
