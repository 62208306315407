import * as React from "react";
import { styled } from "@mui/material/styles";
import { createTheme, Skeleton, ThemeProvider } from "@mui/material";
import Box from "@mui/material/Box";
import KpiCards from "../../../components/kpi_cards/kpiCardsMain";
import { ExpandSvg } from "../../../assets/expand";
import HeatMap from "./Charts/heatMap";
import AreaChart_poc from "./Charts/AreaChart";
import BarAreaLineChart_poc from "./Charts/BarAreaLineChart";
import { useInventoryHealthQuery } from "../../../features/api/inventorySlice";
import StockLevelTable from "./Tables/StockLevelTable";
import { BreadcrumbTemp } from "../../../components/job-schedular/jsConfigScreen";
import "./styles.css";
import ReplenishmentRqmt from "./Charts/ReplenishmentRqmt";
import Overview from "./Charts/Overview";
import SalesOrderDetails from "./Tables/SalesOrderDetails";
import { useNavigate } from "react-router-dom";

const StyledCard2 = styled("div")(({ theme }) => ({
  ...theme.typography.body1,
  borderRadius: "7px",
  // paddingBottom: "2px",
  border: "1px solid #E6E6E6",
  // overflowX: "overlay",
  // overflowY: "hidden",
  // padding: theme.spacing(1),
  display: "flex",
  flexDirection: "column",
  //   flexWrap: "wrap",
}));

const StyledButton = styled("button")(() => ({
  background: "#fff",
  padding: "5px",
  // paddingTop: "10px",
  transition: "all 0.5s ease",
  "&:hover": {
    background: "#ebebeb",
  },
  margin: "5px",
}));

const HeadingDiv = styled("div")(({ theme }) => ({
  ...theme.typography.customHeading,
  fontFamily: "Nunito Sans",
  marginLeft: "10px",
  marginTop: "10px",
  marginBottom: "10px",

  //   paddingLeft: "20px",
  //   paddingTop: "10px",
}));

const Breadcrumb = () => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "10px",
        marginBottom: "10px",
      }}
      className="inv-stats-breadcrumb"
    >
      <div
        className="inv-mgmt-link"
        onClick={() => navigate("/snp/inventory/management")}
      >
        Inventory Management
      </div>
      <div className="flex">
        <BreadcrumbTemp />
        <BreadcrumbTemp />
      </div>
      <div className="inv-stats-text">Demand Overview</div>
    </div>
  );
};

function useResizeObserver() {
  const [componentWidth, setComponentWidth] = React.useState(null);
  const [componentHeight, setComponentHeight] = React.useState(null);
  const targetRef = React.useRef(null);

  React.useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target === targetRef.current) {
          setComponentWidth(entry.contentRect.width);
          setComponentHeight(entry.contentRect.height);
        }
      }
    });

    if (targetRef.current) {
      resizeObserver.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        resizeObserver.unobserve(targetRef.current);
      }
    };
  }, []);

  return { targetRef, componentWidth, componentHeight };
}

const DemandOverviewDashboard = () => {
  const navigate = useNavigate();
  const { targetRef, componentWidth, componentHeight } = useResizeObserver();

  const {
    data: inventoryHealthData,
    isLoading: isLoadingInventoryHealth,
    isSuccess: isSuccessInventoryHealth,
  } = useInventoryHealthQuery(undefined, {
    refetchOnMountOrArgChange: true,
    force: true, // This will bypass the cache
  });

  const snpWidth = document.getElementById("snp")?.offsetWidth;

  return (
    <ThemeProvider theme={customTheme}>
      <Box
        sx={{
          boxSizing: "border-box",
          display: "flex",
          height: "100vh",
          maxHeight: "100vh",
          width: "100%",
          flexDirection: "column",
          padding: "10px",
        }}
      >
        <Breadcrumb />
        {/* <KpiCards /> */}
        <Box
          sx={{
            display: "grid",
            flexGrow: "1",
            gridTemplateRows: "repeat(2, 350px)",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "10px",
            paddingBottom: "10px",
          }}
        >
          {/* <StyledCard2 ref={targetRef}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                // height: "15%",
                // paddingLeft: "10px",
              }}
            >
              <HeadingDiv>Overview</HeadingDiv>
              <StyledButton>
                <ExpandSvg />
              </StyledButton>
            </div>
            <Box
              sx={{
                display: "grid",
                // height: "100%",
                width: "100%",
                overflowX: "overlay",
                overflowY: "hidden",
              }}
            >
              <Overview componentWidth={componentWidth} snpWidth={snpWidth} />
            </Box>
          </StyledCard2>
          <StyledCard2>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                // height: "15%",
                // padding: "10px",
              }}
            >
              <HeadingDiv>Replenishment Requirement</HeadingDiv>
              <StyledButton>
                <ExpandSvg />
              </StyledButton>
            </div>
            <Box
              style={{
                display: "grid",
                // height: "85%",
                width: "100%",
                overflow: "hidden",
              }}
            >
              <ReplenishmentRqmt
                componentWidth={componentWidth}
                snpWidth={snpWidth}
              />
            </Box>
          </StyledCard2> 
           <StyledCard2 sx={{ gridColumn: "span 2" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                // height: "15%",
                // padding: "10px",
              }}
            >
              <HeadingDiv>Sales Order Details</HeadingDiv>
              <StyledButton>
                <ExpandSvg />
              </StyledButton>
            </div>
            <Box
              sx={{
                display: "grid",
                // height: "85%",
                width: "100%",
                overflow: "hidden",
              }}
            >
              <SalesOrderDetails />
            </Box>
          </StyledCard2> */}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

const customTheme = createTheme({
  palette: {
    primary: {
      main: "#f5f7fa",
    },
  },
  typography: {
    fontFamily: "Inter, sans-serif",
    fontSize: 12,
    fontWeightBold: 700,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightLight: 300,
    customBoldFont: {
      fontWeight: 600,
      fontSize: 13,
    },
    customLightFont: {
      fontSize: 11,
      fontWeight: 400,
    },
    customHeading: {
      fontWeight: 300,
      fontSize: 15,
    },
  },
  components: {
    MuiDrawer: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});

export default DemandOverviewDashboard;
