import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  configurationMode: "create", // Your initial value here
  configurationProps: {},
  configTabledata: [],
};

const configurationSlice = createSlice({
  name: "configurationSlice",
  initialState,
  reducers: {
    setConfigurationMode: (state, action) => {
      state.configurationMode = action.payload;
    },
    setConfigurationProps: (state, action) => {
      state.configurationProps = action.payload;
    },
    setConfigTableData: (state, action) => {
      state.configTabledata = action.payload;
    },
  },
});

export const {
  setConfigurationMode,
  setConfigurationProps,
  setConfigTableData,
} = configurationSlice.actions;
export default configurationSlice.reducer;
