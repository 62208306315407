import * as React from "react";
import { useEffect } from "react";
import {
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

import { DataTablePagination } from "./data-table-pagination";
import { ServerDataTablePagination } from "@/pages/Inventory/Dashboard/Tables/ServerSideTableDataHandling/server-data-table-pagination";
import { DataTableToolbar } from "./data-table-toolbar";
import { getCommonPinningStyles } from "./table-utils";
// import { useVirtualizer } from "@tanstack/react-virtual";

import "./table.css";
import { DataSubrowTable } from "./data-subrow-table";
import { OmsServerDataTablePagination } from "@/pages/OrdersManagement/utils";

export function DataTable({
  columns,
  data,
  canResizeColumns = false,
  CustomToolbar,
  setSelectedSTO = null,
  setClearRowSelection = null,
  clearRowSelection = null,
  columnVisibility,
  setColumnVisibility,
  showColorCodingLegend = false,
  subRowColumns = null,
  module = "rp",
  resetExpanded = false,
  planId,
  setTableData,
  setPaginationData,
  paginationData,
  isServerSideDataHandling,
  setLoadingData,
  pageSize,
}) {
  const [rowSelection, setRowSelection] = React.useState({});
  // const [columnVisibility, setColumnVisibility] = React.useState({});
  const [columnFilters, setColumnFilters] = React.useState([]);
  const [sorting, setSorting] = React.useState([]);

  useEffect(() => {
    if (setSelectedSTO) {
      setSelectedSTO(rowSelection);
    }
  }, [rowSelection]);

  useEffect(() => {
    if (clearRowSelection === true) {
      setRowSelection({});
      setTimeout(() => {
        setClearRowSelection(false);
      }, [10]);
    }
  }, [clearRowSelection]);

  const getId = (row, module) => {
    let id = "";
    switch (module) {
      case "pp":
        id = `${row.wpo_id}`;
        break;
      case "rmp":
        id = `${row.pro_id}`;
        break;
      case "oms":
        id = `${row.stoId}`;
        break;
      default:
        id = `${row.sto_id}`;
    }
    return id;
  };

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageSize: 10,
      },
      columnPinning: {
        left: ["expander", "select"],
        right: ["actions"],
      },
    },
    defaultColumn: {
      size: 300,
      minSize: 40,
      maxSize: 1000,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    getSubRows: (row) => row.subRows,
    onColumnFiltersChange: setColumnFilters,
    autoResetPageIndex: false,
    onColumnVisibilityChange: (updater) => {
      if (setColumnVisibility) {
        setColumnVisibility((old) => {
          const newColumnVisibilityValue =
            updater instanceof Function ? updater(old) : updater;
          localStorage.setItem(
            "snp-rp-group-cols",
            JSON.stringify(newColumnVisibilityValue)
          );
          return newColumnVisibilityValue;
        });
      }
    },
    getCoreRowModel: getCoreRowModel(),
    manualFiltering: isServerSideDataHandling ? true : false,
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getRowId: (row) => getId(row, module),
    getRowCanExpand: (row) => {
      if (module === "pp") {
        return row.original.total_allocation !== 0;
      }
    },
    columnResizeMode: "onChange",
    enableColumnResizing: true,
    enableColumnPinning: true,
  });

  const visibleColumns = table.getVisibleLeafColumns();
  const tableContainerRef = React.useRef(null);
  const totalTableWidth = table.getTotalSize();

  useEffect(() => {
    if (resetExpanded === true) {
      table.resetExpanded(true);
    }
  }, [resetExpanded]);
  return (
    <div>
      {CustomToolbar ? (
        <CustomToolbar table={table} />
      ) : (
        <DataTableToolbar table={table} />
      )}
      <div
        className="rounded-md border new-table-container overflow-scroll max-h-[565px] lg:max-h-[415px] mt-[8px] mb-[10px]"
        ref={tableContainerRef}
        style={module === "oms" ? { maxHeight: "650px" } : {}}
      >
        <table
          style={{
            width: canResizeColumns ? totalTableWidth : "100%",
            // borderTop: "solid 1px #424242",
            // borderLeft: "solid 1px #424242",
          }}
        >
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {/* {virtualPaddingLeft ? (
                  //fake empty column to the left for virtualization scroll padding
                  <th style={{ display: "flex", width: virtualPaddingLeft }} />
                ) : null} */}

                {headerGroup.headers.map((header) => {
                  // const header = headerGroup.headers[vc.index];
                  const { column } = header;
                  return (
                    <th
                      key={header.id}
                      style={{
                        position: "sticky",
                        top: "0",
                        zIndex: "2",
                        width: header.getSize(),
                        ...getCommonPinningStyles(column, "th"),
                      }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                      <div
                        onMouseDown={header.getResizeHandler()}
                        onTouchStart={header.getResizeHandler()}
                        className={`resizer ${
                          header.column.getIsResizing() ? "isResizing" : ""
                        }`}
                      />
                    </th>
                  );
                })}
                {/* {virtualPaddingRight ? (
                  //fake empty column to the right for virtualization scroll padding
                  <th style={{ display: "flex", width: virtualPaddingRight }} />
                ) : null} */}
              </tr>
            ))}
          </thead>
          <TableBody className="max-h-[200px]">
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <>
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                  >
                    {row.getVisibleCells().map((cell) => {
                      const { column } = cell;
                      return (
                        <td
                          key={cell.id}
                          style={{
                            width: cell.column.getSize(),
                            ...getCommonPinningStyles(column, "td"),
                          }}
                          className="font-[Inter] text-[14px] lg:text-[12px] p-0 text-ellipsis"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </TableRow>
                  {row.getIsExpanded() && (
                    <TableRow>
                      <TableCell colSpan={columns.length}>
                        <DataSubrowTable
                          data={row.original.subRows}
                          columns={subRowColumns}
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24">
                  <p className="lg:pl-7 lg:text-[16px] text-[16px]">
                    No results.
                  </p>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </table>
      </div>
      {isServerSideDataHandling ? (
        <ServerDataTablePagination
          table={table}
          showColorCodingLegend={showColorCodingLegend}
          planId={planId}
          setTableData={setTableData}
          setPaginationData={setPaginationData}
          paginationData={paginationData}
          setLoadingData={setLoadingData}
          pageSize={pageSize}
        />
      ) : module === "oms" ? (
        <OmsServerDataTablePagination
          table={table}
          setPaginationData={setPaginationData}
          paginationData={paginationData}
        />
      ) : (
        <DataTablePagination
          table={table}
          showColorCodingLegend={showColorCodingLegend}
        />
      )}
    </div>
  );
}
