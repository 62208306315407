import { DataTableColumnHeader } from "@/components/tanstack-table/data-table-column-header";
import { Checkbox } from "@/components/ui/checkbox";

export const generateColumns = (arr) => {
  let cols = [];
  cols.push({
    id: "select",
    header: ({ table, cell }) => {
      const widthValue = cell?.column?.getSize();
      return (
        <div
          className="flex justify-center items-center py-[10px] lg:py-[8px]"
          style={{ width: widthValue }}
        >
          <Checkbox
            checked={
              table.getIsAllPageRowsSelected() ||
              (table.getIsSomePageRowsSelected() && "indeterminate")
            }
            onCheckedChange={(value) =>
              table.toggleAllPageRowsSelected(!!value)
            }
            aria-label="Select all"
            // className="translate-y-[2px]"
          />
        </div>
      );
    },
    cell: ({ row, cell }) => {
      const widthValue = cell?.column?.getSize();
      return (
        <div
          className="flex justify-center items-center h-full py-[8px] lg:py-[6px]"
          style={{ width: widthValue }}
        >
          <Checkbox
            checked={row.getIsSelected()}
            onCheckedChange={(value) => row.toggleSelected(!!value)}
            aria-label="Select row"
            // className="translate-y-[2px]"
          />
        </div>
      );
    },
    enableSorting: false,
    enableHiding: false,
    enableResizing: false,
    size: 40,
  });
  arr.map((item, index) => {
    cols.push({
      accessorKey: item[0],
      header: ({ column, header }) => (
        <div>
          <DataTableColumnHeader
            column={column}
            title={item[1]}
            header={header}
          />{" "}
        </div>
      ),
      cell: ({ row, cell }) => {
        const widthValue = cell.column.getSize();
        return (
          <div className="flex items-center justify-center">
            <div
              className="antialiased truncate text-center"
              style={{ width: widthValue, minWidth: "150px" }}
            >
              {row.getValue(item[0])}
            </div>
          </div>
        );
      },
      size: 270,
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
      enableSorting: true,
      enableHiding: true,
    });
  });
  return cols;
};
