import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentEditSto: null,
  originalSTOValues: {},
  isGroupLevelEditModeOn: false,
  editedSTOList: [],
};

const replenishmentPlanSlice = createSlice({
  name: "replenishmentPlanSlice",
  initialState,
  reducers: {
    setCurrentEditSto: (state, action) => {
      state.currentEditSto = action.payload;
    },
    setOriginalSTOValues: (state, action) => {
      state.originalSTOValues = action.payload;
    },
    setIsGroupLevelEditModeOn: (state, action) => {
      state.isGroupLevelEditModeOn = action.payload;
    },
    setEditedSTOList: (state, action) => {
      const { payload, type } = action.payload;
      switch (type) {
        case "API_BODY": {
          const editableField = payload.editableField;
          const sto = payload.sto;
          const editedValue = Number(payload.editedValue);
          const reason = payload.reason;
          const comments = payload.comments;
          let tempEditedSTOList = state.editedSTOList;
          let indexToUpdate = tempEditedSTOList.findIndex(
            (obj) => obj.sto_id === sto.sto_id
          );
          if (indexToUpdate !== -1) {
            tempEditedSTOList[indexToUpdate] = {
              sto_id: sto.sto_id,
              sku_code: sto.sku_code,
              channel_id: sto.channel_name,
              start_node: sto.location_start,
              end_node: sto.location_end,
              ...(editableField === "edited_replenishment_qty_suom"
                ? {
                    planned_qty_suom: sto.planned_replenishment_qty_suom,
                    edited_qty_suom: editedValue,
                  }
                : {
                    planned_qty: sto.planned_replenishment_qty,
                    edited_qty: editedValue,
                  }),
              reason_code: reason ? reason : sto.reason_code,
              comments: comments ? comments : sto.comments,
            };
            state.editedSTOList = [...tempEditedSTOList];
          } else {
            state.editedSTOList = [
              ...tempEditedSTOList,
              {
                sto_id: sto.sto_id,
                sku_code: sto.sku_code,
                channel_id: sto.channel_name,
                start_node: sto.location_start,
                end_node: sto.location_end,
                ...(editableField === "edited_replenishment_qty_suom"
                  ? {
                      planned_qty_suom: sto.planned_replenishment_qty_suom,
                      edited_qty_suom: editedValue,
                    }
                  : {
                      planned_qty: sto.planned_replenishment_qty,
                      edited_qty: editedValue,
                    }),
                reason_code: reason ? reason : sto.reason_code,
                comments: comments ? comments : sto.comments,
              },
            ];
          }
          break;
        }
        case "FILTER_OUT/STO_ID": {
          const sto_id = payload.sto_id;
          let tempEditedSTOList = state.editedSTOList;
          state.editedSTOList = tempEditedSTOList.filter(
            (item) => item.sto_id !== sto_id
          );
          break;
        }
        case "RESET": {
          state.editedSTOList = [];
          break;
        }
      }
    },
  },
});

export const {
  setCurrentEditSto,
  setOriginalSTOValues,
  setIsGroupLevelEditModeOn,
  setEditedSTOList,
} = replenishmentPlanSlice.actions;
export default replenishmentPlanSlice.reducer;
