import { useState, useEffect, useRef, useMemo } from "react";
import Graph from "react-vis-network-graph";
import { v4 as uuidv4 } from "uuid";
import { useGetNetworkDataQuery } from "../../features/api/apiSlice";
import Legend from "./legend";
import "./NetworkGraph.scss";
import NodeDetails from "../modal/NodeDetails";
import AutoSuggest from "./AutoSuggest";
import { TableLoaderSpinner } from "../tableUtils/tableUtils";
import { setFilterType, setIsFilterOpen } from "../../features/FiltersSlice";
import { useDispatch } from "react-redux";

export default function NodalGraph2() {
  const {
    data: posts,
    isLoading,
    isSuccess,
  } = useGetNetworkDataQuery(undefined, {
    refetchOnMountOrArgChange: true,
    force: true, // This will bypass the cache
  });
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [nodeType, setNodeType] = useState("");
  const [parentNodeMap, setParentNodeMap] = useState({});
  const [parentNodeMap2, setParentNodeMap2] = useState({});
  const [parentNodeMapCurrentState, setParentNodeMapCurrentState] = useState(
    {}
  );
  const [childNodeMap, setChildNodeMap] = useState({});
  const [transformedData, setTransformedData] = useState({});
  const [showExpandData, setShowExpandData] = useState({});
  const [details, setDetails] = useState([]);
  let networkRef = useRef(null);
  const position = useRef({ x: -1750, y: 20, scale: 1.2 });
  const [isVisible, setIsVisible] = useState(false);
  const [contextPosition, setContextPosition] = useState({ x: 0, y: 0 });
  const [selectedNodeID, setSelectedNodeID] = useState("");
  const [isFocusFilterActive, setIsFocusFilterActive] = useState(false);
  // const getColor = (level) => {
  //   let borderColor = "";
  //   switch (level) {
  //     case 1:
  //       borderColor = "#9B88ED";
  //       break;
  //     case 2:
  //       borderColor = "#04BFDA";
  //       break;
  //     case 3:
  //       borderColor = "#FFC382";
  //       break;
  //     case 4:
  //       borderColor = "#FFABE3";
  //       break;
  //   }
  //   return { borderColor };
  // };

  const nodeDesc = [
    { type: "	Node ID		", value: "	WH-N231	" },
    { type: "	Node Name		", value: "	Lucknow WH - Sharmaji nagar	" },
    { type: "	Node Level		", value: "	L4	" },
    { type: "	Location		", value: "	Sharmaji nagar, Lucknow	" },
    { type: "	Total Connections		", value: "	232	" },
    { type: "	Storage Capacity		", value: "	12000 Sq.ft.	" },
    { type: "	Production Capacity		", value: "	30000 SKU units	" },
    { type: "	Source Node		", value: "	Yes; 21 Destinations	" },
    { type: "	Destination Node		", value: "	Yes; 3 Sources	" },
    { type: "	Replenishment Frequency - IN		", value: "	One time - Daily	" },
    { type: "	Replenishment Frequency - OUT		", value: "	Two times - Weekly	" },
    { type: "	Max Lead Time		", value: "	12 days	" },
    { type: "	Min Lead Time		", value: "	2 days	" },
    { type: "	Mode of Transport		", value: "	Road, Air	" },
    { type: "	Maximum order quantity		", value: "	2000 SKUs	" },
  ];
  const handleNetwork = () => {
    networkRef.moveTo({
      position: { x: position.current.x, y: position.current.y },
      scale: position.current.scale,
      animation: {
        duration: 0,
        easingFunction: "easeInOutQuad",
      },
    });
  };
  // makes a dictionary of levels and the node ids belonging to that level, e.g.,{1: ["a","b"],2: ["c","d"],...}
  const levelMap = (nodes) => {
    if (!nodes) return {};
    else {
      const obj = {};

      for (let item of nodes) {
        if (!obj[item.level]) {
          obj[item.level] = [item.id];
        } else {
          obj[item.level].push(item.id);
        }
      }

      return obj;
    }
  };

  // generates label value, level value and color for dynamic number of levels
  const legendData = Object.keys(levelMap(transformedData.nodes)).map(
    (item) => {
      console.log({
        item,
        interpolated: interpolateColor("#f5bf42", "#376db8", item / 10),
        keys: Object.keys(levelMap(transformedData.nodes)),
      });
      return {
        color: interpolateColor("#f5bf42", "#376db8", item / 10),
        label: `Level ${item}`,
        level: item,
      };
    }
  );

  function getColorForLevel(level, colorData) {
    const levelData = colorData.find((data) => data.level == level);
    colorData.forEach((data) =>
      console.log({ seeData: data.level, seeLevelData: levelData })
    );
    return levelData ? levelData.color : null;
  }

  // generates URL of dynamically generated SVGs
  function getUrl(
    nodeID,
    nodeName,
    level,
    nodeData,
    showExpandData,
    isExternal,
    nodeType,
    parentNodeMap,
    childNodeMapObject
  ) {
    const cacheKey = `${nodeID}_${nodeName}_${level}_${nodeData[nodeID]}_${showExpandData[nodeID]}_${isExternal}`;
    const cachedUrl = localStorage.getItem(cacheKey);
    // if (cachedUrl) {
    //   console.log("catch");
    //   return cachedUrl;
    // }

    // console.log({ legendData, nodes: transformedData, level });
    // const borderColor = legendData.find((item) => {
    //   console.log({
    //     item,
    //     itemLevel: item.level,
    //     item_level_real: level,
    //     legendData,
    //   });
    //   return (item) => Number(item.level) === level;
    // })?.color;
    const borderColor = getColorForLevel(level, legendData);
    console.log({ borderColor, level });
    const pnm = parentNodeMap ? parentNodeMap : parentNodeMap2;
    const cnm = childNodeMapObject ? childNodeMapObject : childNodeMap;
    console.log({ pnm, cnm });
    const input = pnm[nodeID] !== undefined ? pnm[nodeID].length : 0;
    const output = cnm[nodeID] !== undefined ? cnm[nodeID].length : 0;

    let svg =
      '<svg xmlns="http://www.w3.org/2000/svg" width="150" height="80">' +
      '<rect x="100%" y="100%" />' +
      ' <foreignObject x="0" y="0" width="100%" height="100%">' +
      `<div  xmlns="http://www.w3.org/1999/xhtml" style="margin: 0px;padding:0px 0px 0px 0px;height: 100%;width:100%;font-size: 12px;font-family:Arial;background-color:#FFFFFF;box-sizing: border-box;border-left-width:3px;border-left-style:solid;border-left-color:${borderColor};border-top-left-radius:10px;border-bottom-left-radius: 10px;border-top-right-radius:10px;border-bottom-right-radius:10px;display: flex; flex-direction: column">` +
      ` <div  style= " background:#FFFAFA;border-top-left-radius:8px;border-top-right-radius:10px;display: flex;flex-grow: 1;justify-content: space-between;align-items: center;padding-top: 0px;padding-bottom: 0px;">` +
      `<div style=" fontSize: 6px; padding-left: 10px">` +
      `<p style=" text-align: start;font-size: 10px;padding: 0;padding-right:3px;margin: 0;color: #000;font-family: 'IBM Plex Sans', sans-serif;font-style: normal;font-weight: 600;line-height: normal">${nodeName}</p>` +
      ` <p style="text-align: start;padding: 0; margin: 0;color: #A0A0A0;font-family: 'IBM Plex Sans', sans-serif;font-size: 12px;font-style: normal;font-weight: 500;line-height: normal">${nodeType}</p>` +
      "  </div>" +
      ` </div>` +
      `  <div style="background-color:#EDF1F2;padding:8px;padding-bottom: 10px;height: 25%;width: 90%;border-bottom-left-radius: 8px;border-bottom-right-radius: 10px;display: flex;flex-direction: column;color: #777;font-family: Inter;font-size: 8px;font-style: normal;font-weight: 500;line-height: normal;text-align:start" >` +
      ` <div style="display: flex;margin-bottom: 5px;width: 80%;justify-content: space-between">` +
      ` <svg
            width="19"
            height="8"
            viewBox="0 0 19 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >` +
      ` <path
              d="M1 3.70312C0.792893 3.70312 0.625 3.87102 0.625 4.07812C0.625 4.28523 0.792893 4.45312 1 4.45312L1 3.70312ZM9.26517 4.34329C9.41161 4.19684 9.41161 3.95941 9.26517 3.81296L6.87868 1.42648C6.73223 1.28003 6.4948 1.28003 6.34835 1.42648C6.2019 1.57292 6.2019 1.81036 6.34835 1.95681L8.46967 4.07813L6.34835 6.19945C6.2019 6.34589 6.2019 6.58333 6.34835 6.72978C6.4948 6.87622 6.73223 6.87622 6.87868 6.72978L9.26517 4.34329ZM1 4.45312L9 4.45313L9 3.70313L1 3.70312L1 4.45312Z"
              fill="#029F00"
            />` +
      `<rect
              x="14.5352"
              y="1.0733"
              width="4.25"
              height="4.25"
              transform="rotate(45 14.5352 1.0733)"
              stroke="#029F00"
              stroke-width="0.75"
            />` +
      ` </svg>` +
      ` <span style="text-align:start;font-family: 'Inter', sans-serif;">Input</span>` +
      ` <span style="font-family: 'Inter', sans-serif;" >${input}</span>` +
      " </div>" +
      ` <div style="display: flex;width: 80%;justify-content: space-between">` +
      ' <svg width="17" height="8" viewBox="0 0 17 8" fill="none" xmlns="http://www.w3.org/2000/svg">' +
      ' <rect x="3.53516" y="1.0733" width="4.25" height="4.25" transform="rotate(45 3.53516 1.0733)" stroke="#FF5555" stroke-width="0.75"/>' +
      '<path d="M8.07129 3.70312C7.86418 3.70312 7.69629 3.87102 7.69629 4.07812C7.69629 4.28523 7.86418 4.45312 8.07129 4.45312L8.07129 3.70312ZM16.3365 4.34329C16.4829 4.19684 16.4829 3.95941 16.3365 3.81296L13.95 1.42648C13.8035 1.28003 13.5661 1.28003 13.4196 1.42648C13.2732 1.57292 13.2732 1.81036 13.4196 1.95681L15.541 4.07813L13.4196 6.19945C13.2732 6.34589 13.2732 6.58333 13.4196 6.72978C13.5661 6.87622 13.8035 6.87622 13.95 6.72978L16.3365 4.34329ZM8.07129 4.45312L16.0713 4.45313L16.0713 3.70313L8.07129 3.70312L8.07129 4.45312Z" fill="#FF5555"/>' +
      " </svg>" +
      ` <span style="text-align:start;font-family: 'Inter', sans-serif">Out</span>` +
      `<span style="font-family: 'Inter', sans-serif;">${output}</span>` +
      " </div>" +
      "  </div>" +
      "  </div>" +
      " </foreignObject>" +
      "  </svg>";

    if (showExpandData[nodeID]) {
      if (nodeData[nodeID]) {
        svg = svg.replace(
          "</foreignObject>",
          "</foreignObject>" +
            ` <circle
                  cx="142.5"
                  cy="42"
                  r="6.5"
                  fill="white"
                  stroke="#D8D8D8"
                  stroke-width="2"
                />`
        );
      } else {
        svg = svg.replace(
          "</foreignObject>",
          "</foreignObject>" +
            `<svg x="120" y="34" width="30" height="15" viewBox="0 0 30 15" xmlns="http://www.w3.org/2000/svg" id="svg-container">` +
            `   <path d="M10,0 h20 a2.5,2.5 0 0 1 2.5,2.5 v10 a2.5,2.5 0 0 1 -2.5,2.5 h-20 a2.5,2.5 0 0 1 -2.5,-2.5 v-10 a2.5,2.5 0 0 1 2.5,-2.5" fill="#007bff" />` +
            ` <rect x="15" y="0" width="15" height="15" rx="0" ry="0" fill="#007bff" />` +
            `  <text x="20" y="7.5" font-family="Arial" font-size="8" fill="white" text-anchor="middle" dominant-baseline="middle" id="dynamic-number">${output}</text>` +
            ` </svg>`
        );
      }
    }

    const url = "data:image/svg+xml;charset=utf-8," + encodeURIComponent(svg);
    localStorage.setItem(cacheKey, url);

    return url;
  }

  // transforming API data into consumable format
  function transformData(data) {
    if (!data) return {};
    let uniqueStages = [...new Set(data.stages.map((item) => item.sequence))];
    const nodes = [];
    let edges = [];
    let startXcValue = -2000;
    let posDy = uniqueStages.reduce((acc, item, index) => {
      acc[index + 1] = { xc: startXcValue, yc: -100 };
      startXcValue += 170;
      return acc;
    }, {});

    data.nodeDetails.forEach((nodeDetail) => {
      const { nodeID, nodeName, nodeType } = nodeDetail;
      const stage = data.stages.find(
        (stage) => stage.nodeType === nodeType
      )?.sequence;
      const level = stage;
      nodes.push({
        id: nodeID,
        nodeName,
        level,
        isExternal: nodeType === "OEM" ? true : false,
        x: posDy[stage].xc,
        y: posDy[stage].yc,
        nodeType,
      });
      posDy[stage].yc = posDy[stage].yc + 150;
    });

    data.nodalConnections.forEach((connection) => {
      const { startNodeId, endNodeId } = connection;
      const startIndex = data.nodeDetails.findIndex(
        (node) => node.nodeID === startNodeId
      );
      let from = data.nodeDetails[startIndex].nodeID;
      const endIndex = data.nodeDetails.findIndex(
        (node) => node.nodeID === endNodeId
      );
      let to = data.nodeDetails[endIndex].nodeID;
      edges.push({ from, to });
    });

    const parentNodeMapTemp2 = {};

    edges.forEach((edge) => {
      const { from, to } = edge;

      if (!parentNodeMapTemp2[to]) {
        parentNodeMapTemp2[to] = [];
      }

      parentNodeMapTemp2[to].push(from);
    });

    setParentNodeMap2(parentNodeMapTemp2);

    let childNodeMapTemp = {};
    edges.forEach((edge) => {
      const { from, to } = edge;
      if (!childNodeMapTemp[from]) {
        childNodeMapTemp[from] = [];
      }

      childNodeMapTemp[from].push(to);
    });
    setChildNodeMap({ ...childNodeMapTemp });

    return {
      nodes,
      edges,
      parentNodeMap: parentNodeMapTemp2,
      childNodeMap: childNodeMapTemp,
    };
  }

  function isDirectDescendant(
    tempEdges,
    nodeId, // node we want to check if there is a path from ancesterNode to nodeId
    ancestorNode,
    visited = new Set()
  ) {
    if (visited.has(ancestorNode)) {
      // If the ancestor node has already been visited, return false to break the recursion
      return false;
    }

    visited.add(ancestorNode);

    const descendants = tempEdges.filter((edge) => edge.from === ancestorNode);

    for (const descendant of descendants) {
      if (descendant.to === nodeId) {
        return true;
      } else {
        const isNestedDescendant = isDirectDescendant(
          tempEdges,
          nodeId,
          descendant.to,
          visited
        );
        if (isNestedDescendant) {
          return true;
        }
      }
    }

    return false;
  }

  function hasAlternativePath(
    tempEdges,
    nodeId,
    ancestorNode,
    visited = new Set()
  ) {
    visited.add(ancestorNode);

    const descendants = tempEdges.filter((edge) => edge.from === ancestorNode);

    for (const descendant of descendants) {
      if (descendant.to === nodeId) {
        return false;
      } else if (!visited.has(descendant.to)) {
        const hasAlternative = hasAlternativePath(
          tempEdges,
          nodeId,
          descendant.to,
          visited
        );
        if (!hasAlternative) {
          return false;
        }
      }
    }

    return true;
  }

  const generateNodeSequenceMap = (nodeData1) => {
    if (!nodeData1) return {};
    const nodeData = nodeData1;
    const nodeDetails = nodeData.nodeDetails;
    const nodalConnections = nodeData.nodalConnections;
    const stages = nodeData1.stages;
    const stagesMap = stages.reduce((acc, item) => {
      acc[item.nodeType] = item.sequence;
      return acc;
    }, {});
    console.log({ stagesMap });
    const allNodes = nodeData1.nodeDetails;

    const nodeSequenceMap2 = allNodes.reduce((acc, item) => {
      acc[item.nodeID] = stagesMap[item.nodeType];
      return acc;
    }, {});
    console.log({ nodeSequenceMap2 });
    // --------
    // Create an empty dictionary to store nodeId to sequence mapping
    const nodeSequenceMap = {};

    // Iterate over each node and determine its sequence
    for (const node of nodeDetails) {
      const nodeId = node.nodeID;

      // Find the incoming connections for the current node
      const incomingConnections = nodalConnections.filter(
        (connection) => connection.endNodeId === nodeId
      );

      // If there are no incoming connections, assign sequence 1
      if (incomingConnections.length === 0) {
        nodeSequenceMap[nodeId] = 1;
      } else {
        let maxSequence = 0;

        // Find the maximum sequence from the incoming connections
        for (const connection of incomingConnections) {
          const startNodeId = connection.startNodeId;
          const sequence = nodeSequenceMap[startNodeId];

          if (sequence > maxSequence) {
            maxSequence = sequence;
          }
        }

        // Assign the next sequence number
        nodeSequenceMap[nodeId] = maxSequence + 1;
      }
    }

    return nodeSequenceMap2;
  };

  const nodeLevelMap = generateNodeSequenceMap(posts);

  useEffect(() => {
    document.addEventListener("contextmenu", (e) => e.preventDefault());
    if (isSuccess) {
      const transformedDataTemp = transformData(posts);
      let showExpandDataTemp = {};
      let edgesFrom = new Set();
      transformedDataTemp.edges.forEach((edge) => edgesFrom.add(edge.from));
      transformedDataTemp.nodes.map((node) => {
        if (edgesFrom.has(node.id)) {
          showExpandDataTemp[node.id] = true;
        } else {
          showExpandDataTemp[node.id] = false;
        }
      });
      setShowExpandData(showExpandDataTemp);
      let edges = transformedDataTemp.edges;
      let nodes = transformedDataTemp.nodes;
      let tempData = {};

      for (let i = 0; i < nodes.length; i++) {
        if (nodes[i].level == 1) {
          tempData[nodes[i].id] = true;
        } else {
          tempData[nodes[i].id] = false;
        }
      }
      nodes = nodes.map((node) => ({
        ...node,
        image: getUrl(
          node.id,
          node.nodeName,
          node.level,
          tempData,
          showExpandDataTemp,
          node.isExternal,
          node.nodeType,
          transformedDataTemp.parentNodeMap,
          transformedDataTemp.childNodeMap
        ),
      }));
      console.log({ transformedDataTemp });
      setTransformedData({ ...transformedDataTemp, nodes });

      const levelMapValues = levelMap(nodes);
      let temp = edges.filter((edge) => levelMapValues[1].includes(edge.from));
      const connectedNodes = new Set();
      temp.forEach((edge) => {
        connectedNodes.add(edge.from);
        connectedNodes.add(edge.to);
      });
      let visibleTempNodes = nodes.filter((node) =>
        connectedNodes.has(node.id)
      );

      const parentNodeMapTemp = {};

      temp.forEach((edge) => {
        const { from, to } = edge;

        if (!parentNodeMapTemp[to]) {
          parentNodeMapTemp[to] = [];
        }

        parentNodeMapTemp[to].push(from);
      });

      setNodeData(tempData);
      setTempEdges(temp);
      setVisibleNodes(visibleTempNodes);
      setParentNodeMap(parentNodeMapTemp);
    }
  }, [isSuccess]);
  const [nodeData, setNodeData] = useState({});

  const [tempEdges, setTempEdges] = useState([]);

  const [visibleNodes, setVisibleNodes] = useState([]);

  useEffect(() => {
    // let toggle = function (event) {
    //   const nodeDetails = document.querySelector("#network-sidebar");
    //   const filterButton = document.querySelector("#filter-button");
    //   const detailsButton = document.querySelector("#network-details-btn");
    //   const viewDetails = document.querySelector("#view-details");
    //   console.log({ nodeDetails });
    //   // Check if the clicked element is not the sidebar or content area
    //   if (
    //     isVisible &&
    //     !nodeDetails.contains(event.target) &&
    //     !filterButton.contains(event.target) &&
    //     !detailsButton.contains(event.target) &&
    //     !viewDetails?.contains(event.target)
    //   ) {
    //     setIsOpen(false);
    //   }
    //   // if (
    //   //   !nodeDetails?.contains(event.target) &&
    //   //   !filterButton.contains(event.target) &&
    //   //   !detailsButton.contains(event.target)
    //   // ) {
    //   //   setIsOpen(false);
    //   // }
    // };
    // document.addEventListener("click", toggle);
    if (isSuccess) {
      const connectedNodes = new Set();
      tempEdges.forEach((edge) => {
        connectedNodes.add(edge.from);
        connectedNodes.add(edge.to);
      });
      const levelOneNodes = transformedData.nodes?.filter(
        (item) => item.level === 1
      );
      const visibleNodes = transformedData.nodes?.filter((node) =>
        connectedNodes.has(node.id)
      );

      setVisibleNodes(
        transformedData.nodes
          ? [
              ...new Set([
                ...(isFocusFilterActive
                  ? [
                      transformedData.nodes.find(
                        (item) => item.id === selectedNodeID
                      ),
                    ]
                  : levelOneNodes),
                ...visibleNodes,
              ]),
            ].map((node) => ({
              ...node,
              image: getUrl(
                node.id,
                node.nodeName,
                node.level,
                nodeData,
                showExpandData,
                node.isExternal,
                node.nodeType
              ),
            }))
          : []
      );
    }
    // return () => document.removeEventListener("click", toggle);
  }, [tempEdges]);

  const options = {
    nodes: {
      shape: "image",
    },
    edges: {
      smooth: {
        type: "cubicBezier",
        forceDirection: "horizontal",
        roundness: 0.4,
      },
      color: {
        color: "#b3b3b3",
        hover: "#b380ff",
      },
    },

    height: "100%",
    width: "100%",
    interaction: {
      dragNodes: false,
      hover: true,
      tooltipDelay: 10,
      navigationButtons: true,
      keyboard: { enabled: false },
    },
    physics: {
      enabled: false,
      hierarchicalRepulsion: {
        nodeDistance: 170,
      },
      stabilization: true,
    },
  };

  const getChildrenNodes = (nodeId) => {
    const childrenNodes = transformedData.edges
      .filter((edge) => edge.from === nodeId)
      .map((edge) => edge.to);
    return childrenNodes;
  };

  const getChildrenNodesForVisibleGraph = (nodeId) => {
    const childrenNodes = tempEdges
      .filter((edge) => edge.from === nodeId)
      .map((edge) => edge.to);
    return childrenNodes;
  };

  const getDescendantNodes = (nodeId, visited = new Set()) => {
    const descendants = [];
    const edgesToBeRemoved = [];
    visited.add(nodeId);
    const children = transformedData.edges
      .filter((edge) => edge.from === nodeId)
      .map((edge) => edge.to);
    console.log({ transformedData, children, nodeId });
    const clickedNodeLevel = transformedData.nodes.find(
      (item) => item.id === nodeId
    ).level;
    children.forEach((childId) => {
      if (visited.has(childId)) {
        return;
      }
      console.log({
        parentNodeMap,
        parentNodeMap2,
        cid: childId,
        childId: parentNodeMap[childId] && parentNodeMap[childId].length === 1,
        pcId2: parentNodeMap2[childId],
      });
      if (parentNodeMap2[childId] && parentNodeMap2[childId].length === 1) {
        descendants.push(childId);
        descendants.push(...getDescendantNodes(childId, visited)[0]);
        edgesToBeRemoved.push(...getDescendantNodes(childId, visited)[1]);
        console.log({ descendants, edgesToBeRemoved });
      } else if (parentNodeMap2[childId].length === 1) {
        console.log({ doned: getDescendantNodes(childId), childId });
      } else {
        edgesToBeRemoved.push({ from: nodeId, to: childId });
      }
    });

    const isLeafNode = children.length === 0;

    if (isLeafNode) {
      edgesToBeRemoved.push(
        ...transformedData.edges.filter((edge) => edge.to === nodeId)
      );
    }

    return [descendants, edgesToBeRemoved];
  };
  const handleInitialData = () => {
    let temp = [];
    let visibleTempNodes = [];
    if (isSuccess) {
      const levelMapValues = levelMap(transformedData.nodes);
      temp = transformedData.edges.filter((edge) =>
        levelMapValues[1].includes(edge.from)
      );
      const connectedNodes = new Set();
      temp.forEach((edge) => {
        connectedNodes.add(edge.from);
        connectedNodes.add(edge.to);
      });
      let showExpandData = {};
      visibleTempNodes = transformedData.nodes.filter((node) =>
        connectedNodes.has(node.id)
      );
      visibleTempNodes = visibleTempNodes.map((node) => ({
        ...node,
        image: getUrl(
          node.id,
          node.nodeName,
          node.level,
          nodeData,
          showExpandData,
          node.isExternal,
          node.nodeType
        ),
      }));
    }

    return [temp, visibleTempNodes];
  };

  const handleExpandCollapse = () => {
    let tempData = {};

    for (let i = 0; i < transformedData.nodes.length; i++) {
      if (transformedData.nodes[i].level == 1) {
        tempData[transformedData.nodes[i].id] = true;
      } else {
        tempData[transformedData.nodes[i].id] = false;
      }
    }
    setNodeData(tempData);
  };

  const handleDefault = () => {
    setIsVisible(false);
    setIsFocusFilterActive(false);
    let [temp, visibleTempNodes] = handleInitialData();
    setTempEdges(temp);
    setVisibleNodes(visibleTempNodes);
    handleExpandCollapse();
    position.current = { x: -1750, y: 20, scale: 1.2 };
    networkRef.moveTo({
      position: { x: position.current.x, y: position.current.y },
      scale: position.current.scale,
      animation: {
        duration: 0,
        easingFunction: "easeInOutQuad",
      },
    });
  };

  function interpolateColor(color1, color2, value) {
    value = Math.min(1, Math.max(0, value)); // Ensure value is between 0 and 1

    const r1 = parseInt(color1.slice(1, 3), 16); // Red component of color1
    const g1 = parseInt(color1.slice(3, 5), 16); // Green component of color1
    const b1 = parseInt(color1.slice(5, 7), 16); // Blue component of color1

    const r2 = parseInt(color2.slice(1, 3), 16); // Red component of color2
    const g2 = parseInt(color2.slice(3, 5), 16); // Green component of color2
    const b2 = parseInt(color2.slice(5, 7), 16); // Blue component of color2

    const r = Math.round(r1 + (r2 - r1) * value);
    const g = Math.round(g1 + (g2 - g1) * value);
    const b = Math.round(b1 + (b2 - b1) * value);

    return `#${r.toString(16).padStart(2, "0")}${g
      .toString(16)
      .padStart(2, "0")}${b.toString(16).padStart(2, "0")}`;
  }

  // const interpolatedColor = interpolateColor(color1, color2, value);
  console.log({ transformedData, lmtd: levelMap(transformedData.nodes) });

  const events = {
    click: function (event) {
      // this conditional runs on an expansion click
      setIsVisible(false);
      var { nodes, pointer } = event;

      let nodeId = null;

      if (nodes.length === 1) {
        nodeId = nodes[0];
      }
      const childrenNodesForNodeId = getChildrenNodes(nodeId);

      let { x, y } = networkRef.getViewPosition();
      const { scale } = networkRef.body.view;
      position.current = { x, y, scale };
      if (childrenNodesForNodeId.length === 0) return;
      if (nodes.length > 1) {
        return;
      }
      if (nodes.length === 1) {
        nodeId = nodes[0];
      }
      console.log({ childrenNodesForNodeId, nodes, nodeData, nodeId });
      if (!nodeData[nodeId]) {
        let currEdges = transformedData.edges.filter(
          (edge) => edge.from == nodeId
        );
        let a = [...tempEdges, ...currEdges];
        // adds the edges emerging from the node clicked to tempEdges,
        // then tempEdges is used to extract the nodes that should be rendered to the screen useing the from and to object
        setTempEdges([...a]);

        // this state variable updates whether the nodes are in expanded or collapsed state
        setNodeData((prev) => ({ ...prev, [nodeId]: true }));

        const parentNodeMapCurrent = {};

        [...a].forEach((edge) => {
          const { from, to } = edge;

          if (!parentNodeMapCurrent[to]) {
            parentNodeMapCurrent[to] = [];
          }

          parentNodeMapCurrent[to].push(from);
        });
        // whatever is visible on the screen, it makes the parent mapping for it
        setParentNodeMapCurrentState(parentNodeMapCurrent);
        console.log({ parentNodeMapCurrent });
      } else {
        const descendantNodesForNodeId = getDescendantNodes(nodeId);
        const repeatedNodes = new Set(descendantNodesForNodeId[0]);
        // let repeatedEdges = descendantNodesForNodeId[1];
        console.log({ descendantNodesForNodeId });
        // running a filter on all edges to determine which edges to keep and which to remove from tempEdges state variable
        let filteredEdges = tempEdges.filter((item) => {
          const hasAlternativePathItemTo = hasAlternativePath(
            tempEdges,
            item.to,
            nodeId
          );
          const hasAlternativePathItemFrom = hasAlternativePath(
            tempEdges,
            item.from,
            nodeId
          );
          const isDirectDescendantItemTo = isDirectDescendant(
            tempEdges,
            item.to,
            nodeId
          );
          // console.log({
          //   item,
          //   itemFrom: item.from,
          //   itemTo: item.to,
          //   parentNodeMap,
          //   parentNodeMap2,
          //   parentNodeMap2ItemFrom: parentNodeMap2[item.from],
          //   nodeId,
          //   hasAlternativePathItemTo,
          //   hasAlternativePathItemFrom,
          //   isDirectDescendantItemTo,
          //   f1: parentNodeMapCurrentState[item.from],
          //   f2:
          //     parentNodeMap2[item.from] &&
          //     parentNodeMap2[item.from].every(
          //       (node) => nodeLevelMap[nodeId] > nodeLevelMap[node]
          //     ),
          // });
          if (item.from === nodeId && !hasAlternativePathItemTo) {
            return false;
          }
          // eges cases:  when there is some cyclicity involved, it might get a little tricky to collapse some node that is in the middle if a cycle
          // edge case: if one node has connections that arise from some node other than the one that is clicked,
          // then, that other connection is to be kept
          if (
            nodeLevelMap[item.from] < nodeLevelMap[nodeId] &&
            nodeLevelMap[item.to] <= nodeLevelMap[nodeId]
          ) {
            return true;
          } else if (
            item.from !== nodeId &&
            parentNodeMapCurrentState[item.from] &&
            parentNodeMapCurrentState[item.from].length === 1
          ) {
            return false;
          } else if (
            nodeLevelMap[nodeId] > nodeLevelMap[item.to] &&
            item.from === nodeId
          ) {
            return true;
          } else if (
            item.from !== nodeId &&
            parentNodeMap[item.from] &&
            parentNodeMap[item.from].length >= 1
          ) {
            if (
              !hasAlternativePathItemFrom &&
              parentNodeMap[item.from].length === 1 &&
              !parentNodeMap[item.from].includes(nodeId)
            )
              return false;
            return true;
          } else if (item.from !== nodeId && !hasAlternativePathItemFrom) {
            return false;
          } else if (
            !hasAlternativePathItemTo &&
            parentNodeMap[item.to].length > 1
          ) {
            return false;
          } else if (
            hasAlternativePathItemTo &&
            parentNodeMap[item.to].length > 1
          ) {
            return true;
          } else if (
            parentNodeMap[item.to].length > 1 &&
            getChildrenNodesForVisibleGraph(item.to).length === 0
          ) {
            if (hasAlternativePathItemFrom) return true;
            else return false;
          } else if (
            parentNodeMap[item.to].length === 1 &&
            isDirectDescendantItemTo
          ) {
            return false;
          } else if (
            isDirectDescendantItemTo &&
            parentNodeMap[item.to].length > 1
          ) {
            return true;
          } else if (
            parentNodeMap[item.to].length === 1 &&
            item.from !== nodeId
          ) {
            return true;
          } else if (
            repeatedNodes.has(item.from) ||
            repeatedNodes.has(item.to)
          ) {
            return false;
          }

          const commonParents = childrenNodesForNodeId.reduce(
            (common, childId) =>
              common.filter((parentId) =>
                parentNodeMap[childId].includes(parentId)
              ),
            [...parentNodeMap[item.to]] || []
          );

          const childNodeIds = descendantNodesForNodeId[0];
          const hasChildNodesWithCommonParents = childNodeIds.some((childId) =>
            commonParents.includes(childId)
          );

          return (
            !commonParents.includes(item.from) &&
            !hasChildNodesWithCommonParents
          );
        });
        console.log("filtered edges", filteredEdges);

        setTempEdges(filteredEdges);

        const tempNodeData = descendantNodesForNodeId[0].reduce(
          (acc, nodeId) => {
            acc[nodeId] = false;
            return acc;
          },
          {}
        );
        setNodeData((prev) => ({ ...prev, ...tempNodeData, [nodeId]: false }));
      }
    },
    // handles right click
    oncontext: function (event) {
      setIsVisible(false);
      let { x, y } = networkRef.getViewPosition();
      const { scale } = networkRef.body.view;
      position.current = { x, y, scale };
      const { pointer } = event;
      const { DOM } = pointer;
      // this is done to keep the scale right
      x = DOM.x;
      y = DOM.y;

      const nodeId = networkRef.getNodeAt(event.pointer.DOM);
      if (nodeId) {
        setSelectedNodeID(nodeId);
        setContextPosition({ x, y });
        setIsVisible(true);
      }
    },
  };

  // opens details menu
  const handleDetails = () => {
    dispatch(setFilterType("details"));
    dispatch(setIsFilterOpen(true));
  };

  const handleNodeDetails = () => {
    setNodeType("Node");
    setDetails(nodeDesc);
    setIsVisible(false);
    setTimeout(() => setIsOpen(true));
  };

  // const handleFilters = () => {
  //   setIsOpen(true);
  //   setNodeType("Filters");
  // };

  function getAllEdgesFormingTree(edges, nodeId) {
    console.log({ funcEdges: edges });

    const collectedEdges = [];

    const descendants = new Set();

    function traverseAndCollectEdges(currentNodeId) {
      descendants.add(currentNodeId);

      const outgoingEdges = edges.filter((edge) => edge.from === currentNodeId);

      collectedEdges.push(...outgoingEdges);

      for (const outgoingEdge of outgoingEdges) {
        if (!descendants.has(outgoingEdge.to)) {
          traverseAndCollectEdges(outgoingEdge.to);
        }
      }
    }

    traverseAndCollectEdges(nodeId);

    return collectedEdges;
  }

  const handleFocus = (e, nodeId) => {
    let nodeToFocus = transformedData.nodes.find((node) => node.id === nodeId);
    console.log({ nodeToFocus });
    setIsVisible(false);
    focusOnNode(nodeToFocus);
    e.stopPropagation();
  };

  const focusOnNode = (nodeFocused) => {
    setIsFocusFilterActive(true);
    let { x, y } = nodeFocused;
    position.current = childNodeMap[nodeFocused.id]
      ? { x, y, scale: 1.4 }
      : { x, y, scale: 2 };
    const allEdgesConnectedToDescendants = getAllEdgesFormingTree(
      transformedData.edges,
      nodeFocused.id
    );

    let nodeDataTemp = nodeData;
    allEdgesConnectedToDescendants.forEach((edge) => {
      nodeDataTemp[edge.from] = true;
      nodeDataTemp[edge.to] = true;
    });
    setNodeData({ ...nodeDataTemp });
    setTempEdges(allEdgesConnectedToDescendants);
    networkRef.moveTo({
      position: { x: position.current.x, y: position.current.y },
      scale: position.current.scale,
      animation: {
        duration: 0,
        easingFunction: "easeInOutQuad",
      },
    });
  };
  const handleSearch = (event) => {
    if (event.key === "Enter") {
      searchToFocus(event.target.value);
    }
  };

  const searchToFocus = (value) => {
    let nodeToFocus = transformedData.nodes.filter((node) =>
      node.nodeName.includes(value)
    );
    setSelectedNodeID(nodeToFocus[0].id);
    focusOnNode(nodeToFocus[0]);
  };

  return (
    <div className="network-vis">
      {isVisible && (
        <div
          style={{
            left: `${contextPosition.x}px`,
            top: `${contextPosition.y + 70}px`,
          }}
          className="node-tooltip"
        >
          <button onClick={(e) => handleFocus(e, selectedNodeID)}>Focus</button>
          <button
            id="view-details"
            onClick={(e) => handleNodeDetails(selectedNodeID)}
          >
            View details
          </button>
        </div>
      )}
      <NodeDetails
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        nodeType={nodeType}
        details={details}
      />

      <div className="network-vis-navbar">
        <Legend legendData={legendData} />
        <div className="network-vis-navbar-right">
          <AutoSuggest
            suggestData={transformedData ? transformedData.nodes : []}
            searchToFocus={searchToFocus}
          />
          <button
            id="network-details-btn"
            className="network-details-btn "
            onClick={handleDetails}
          >
            <svg
              width="12"
              height="12"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.99935 2.66667C8.7356 2.66667 9.33268 2.06958 9.33268 1.33333C9.33268 0.597083 8.7356 0 7.99935 0C7.2631 0 6.66602 0.597083 6.66602 1.33333C6.66602 2.06958 7.2631 2.66667 7.99935 2.66667ZM7.99935 6.66667C7.2631 6.66667 6.66602 7.2625 6.66602 8C6.66602 8.7375 7.2631 9.33333 7.99935 9.33333C8.7356 9.33333 9.33268 8.73625 9.33268 8C9.33268 7.26375 8.7356 6.66667 7.99935 6.66667ZM7.99935 13.3333C7.2631 13.3333 6.66602 13.9304 6.66602 14.6667C6.66602 15.4029 7.2631 16 7.99935 16C8.7356 16 9.33268 15.4029 9.33268 14.6667C9.33268 13.9304 8.7356 13.3333 7.99935 13.3333Z"
                fill="#3D445C"
              />
            </svg>

            <span className="tooltiptext">Show network details</span>
          </button>
        </div>
      </div>
      {isSuccess && !isLoading ? (
        <div className="network-graph">
          <Graph
            key={uuidv4()}
            graph={{ nodes: visibleNodes, edges: tempEdges }}
            options={options}
            events={events}
            ref={networkRef}
            getNetwork={(network) => {
              networkRef = network;
              handleNetwork();
            }}
          />
          <button className="default-btn" onClick={handleDefault}>
            D
          </button>
        </div>
      ) : (
        <TableLoaderSpinner />
      )}
    </div>
  );
}
