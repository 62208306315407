export function hasChildren(item) {
  const { items: children } = item;

  if (children === undefined) {
    return false;
  }

  if (children.constructor !== Array) {
    return false;
  }

  if (children.length === 0) {
    return false;
  }

  return true;
}

export function formatDate(date) {
  const inputDate = new Date(date);
  if (isNaN(inputDate.getTime())) {
    return "Invalid Date";
  }

  const day = inputDate.getDate();
  const month = inputDate.getMonth() + 1;
  const year = inputDate.getFullYear();
  const hours = inputDate.getHours();
  const minutes = inputDate.getMinutes();

  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedHours = hours < 10 ? `0${hours}` : hours;

  const formattedDate = `${formattedDay}-${formattedMonth}-${year} ${formattedHours}:${formattedMinutes}`;

  return formattedDate;
}

function snakeToCamel(obj) {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => snakeToCamel(item));
  }

  const camelObj = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const camelKey = key.replace(/_([a-z])/g, function (match, letter) {
        return letter.toUpperCase();
      });
      camelObj[camelKey] = snakeToCamel(obj[key]);
    }
  }
  return camelObj;
}

export function transformData(array) {
  if (!Array.isArray(array)) {
    return [];
  }
  return array.map((obj) => snakeToCamel(obj));
}

export function formatDateToDateMonYear(inputDate) {
  if (inputDate == null) {
    return "";
  }
  if (isValidISODate(inputDate)) {
    const options = { day: "numeric", month: "short", year: "numeric" };
    const date = new Date(inputDate);
    return date.toLocaleDateString("en-GB", options);
  }
  return "Invalid ISO date !";
}

function isValidISODate(dateString) {
  const date = new Date(dateString);
  // Check if the date object is a valid date and the input string is not 'Invalid Date'
  return !isNaN(date) && date.toString() !== "Invalid Date";
}

export function formatISODateToHumanReadable(isoDateTime) {
  if (isoDateTime == null) {
    return "";
  }
  if (isValidISODate(isoDateTime)) {
    const parsedDate = new Date(isoDateTime);
    // Format the date to a human-readable string
    const options = {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: "true",
    };
    let formattedDate = parsedDate.toLocaleString("en-GB", options);
    if (parsedDate.getHours() === 12) {
      formattedDate = formattedDate.replace(/00:/g, "12:");
    }
    return formattedDate;
  }
  return "Invalid ISO date !";
}

export function twoDecimalNumberFormat(number) {
  let formattedString = new Intl.NumberFormat("en-US", {
    style: "decimal",
    maximumFractionDigits: 3,
  }).format(number);
  const parsedNumber = parseFloat(formattedString.replace(/,/g, ""));
  return parsedNumber;
}

export function formatDateToDateObject(inputDate) {
  if (inputDate == null) {
    return "";
  }
  if (isValidISODate(inputDate)) {
    const date = new Date(inputDate);
    return date;
  }
  return "Invalid ISO date !";
}
