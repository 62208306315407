import { useEffect, useState } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import FilterIcon from "./FilterIcon";
import { setIsFilterOpen, setFilterType } from "./../../features/FiltersSlice";
import FilterModalCommon from "../modal/FilterModalCommon";
import { useDispatch, useSelector } from "react-redux";
import CalendarIcon from "../calendar/CalendarIcon";
import GlobalCalendar from "../calendar/GlobalCalendar";

const dummyFilterRules = [
  {
    default: "B1",
    rule_id: "input_data",
    rule_type: "dropdown",
    rule_label: "Brand",
    rule_option: ["B1", "B2", "B3"],
    is_mandatory: true,
  },
  {
    default: "D1",
    rule_id: "demand_forecast",
    rule_type: "dropdown",
    rule_label: "Division",
    rule_option: ["D1", "D2", "D3"],
    is_mandatory: true,
  },
  // {
  //   default: "SKU1",
  //   rule_id: "include_sales_order",
  //   rule_type: "dropdown",
  //   rule_label: "SKU",
  //   rule_option: ["SKU1", "SKU2", "SKU3"],
  //   is_mandatory: true,
  // },
];
const dummyFilterRules2 = [
  {
    default: "FWH1",
    rule_id: "rp_rule",
    rule_type: "dropdown",
    rule_label: "FWH",
    rule_option: ["FWH1", "FWH2", "FWH3"],
    is_mandatory: true,
  },
  {
    default: "CDC1",
    rule_id: "input_data",
    rule_type: "dropdown",
    rule_label: "CDC",
    rule_option: ["CDC1", "CDC2", "CDC3"],
    is_mandatory: true,
  },
  {
    default: "RDC1",
    rule_id: "input_data",
    rule_type: "dropdown",
    rule_label: "RDC",
    rule_option: ["RDC1", "RDC2", "RDC3"],
    is_mandatory: true,
  },
];

const StyledOverlay = styled("div")(() => ({
  borderRadius: "7px",
  position: "absolute",
  display: "block",
  width: "100%",
  height: "108vh",
  top: "0",
  left: "0",
  right: "0",
  bottom: "0",
  backgroundImage: "linear-gradient(rgba(0,0,0,0.31), rgba(0,0,0,0.82))",
  zIndex: "100",
}));

const StyledComingSoon = styled("div")(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  fontSize: "30px",
  color: "white",
  transform: "translate(-50%,-50%)",
  fontFamily: "IBM Plex Sans",
  // -ms-transform: translate(-50%,-50%),
}));

const StyledStayTuned = styled("div")(() => ({
  position: "absolute",
  top: "60%",
  left: "50%",
  fontSize: "25px",
  color: "white",
  transform: "translate(-50%,-50%)",
  fontFamily: "IBM Plex Sans",
  // -ms-transform: translate(-50%,-50%),
}));

export default function TabLayout() {
  const location = useLocation();
  const dispatch = useDispatch();
  const path = location.pathname;
  let idArr = path.split("/");
  console.log({ path });
  let pageIdentifier = Number(idArr[idArr.length - 1]);
  const isModalOpen = useSelector((state) => state.filters.isFilterOpen);
  const [value, setValue] = useState(processPath(path));
  const [dynamicFields, setDynamicFields] = useState(dummyFilterRules);
  const [dynamicFields2, setDynamicFields2] = useState(dummyFilterRules2);
  const [openCalendar, setOpenCalendar] = useState(false);

  const navigate = useNavigate();
  const userDetails = JSON.parse(localStorage.getItem("user"));
  const { permissions } = userDetails;
  const isJSPermissionAvailable = permissions.includes("JS_VIEW");
  const isConfigPermissionAvailable =
    permissions.includes("CONFIGURATION_VIEW");
  const isPreprocessPermissionAvailable =
    permissions.includes("PREPROCESS_VIEW");
  const isNetworkPermissionAvailable = permissions.includes(
    "NETWORK_VISUALISATION_VIEW"
  );

  useEffect(() => {
    setValue(processPath(path));
    if (isModalOpen) dispatch(setIsFilterOpen(false));
  }, [path]);

  function getFirstPartOfString(inputString) {
    if (inputString === "") {
      return "";
    } else {
      // Split the input string by '/'
      const parts = inputString.split("/");

      // Return the first part (index 0) of the split string
      return parts[1];
    }
  }

  function processPath(path) {
    let newPath = "";
    if (path.includes("replenishment-plan")) {
      newPath = path.replace("/snp/inventory/replenishment-plan", "");
    }
    if (path.includes("management")) {
      newPath = path.replace("/snp/inventory/management", "");
    }
    if (path.includes("production-plan")) {
      newPath = path.replace("/snp/production/production-plan", "");
    }
    if (path.includes("raw-material/requirement")) {
      newPath = path.replace("/snp/raw-material/requirement", "");
    }
    if (path.includes("production/requirement")) {
      newPath = path.replace("/snp/production/requirement", "");
    }
    if (path.includes("raw-material/plan")) {
      newPath = path.replace("/snp/raw-material/plan", "");
    }

    newPath = getFirstPartOfString(newPath);
    let tabValue = "one";
    switch (newPath) {
      case "":
        tabValue = "one";
        break;
      case "config":
        tabValue = "two";
        break;
      case "config-create":
        tabValue = "two";
        break;
      case "config-edit":
        tabValue = "two";
        break;
      case "job-scheduler":
        tabValue = "four";
        break;
      case "job-scheduler-history":
        tabValue = "four";
        break;
      case "job-scheduler-edit":
        tabValue = "four";
        break;
      case "job-scheduler-create":
        tabValue = "four";
        break;

      case "preprocesses":
        tabValue = "three";
        break;
      case "network":
        tabValue = "five";
        break;
      default:
        tabValue = "one";
        break;
    }
    return tabValue;
  }

  const TabStyled = styled(Tab)(() => ({
    fontSize: "14px",
    height: "40px",
  }));
  const viewFilters =
    path === "/snp/inventory/management" ||
    idArr[idArr.length - 1] === "network" ||
    path === "/snp/production/requirement" ||
    path === "/snp/raw-material/requirement";

  let customNavigate = useNavigate();

  return (
    <>
      <FilterModalCommon
        dynamicFields={dynamicFields}
        dynamicFields2={dynamicFields2}
      />
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "right",
          position: "relative",
          top: "10px",
          height: "0px",
          zIndex: "20",
        }}
      >
        {/* <div style={{ width: "50px" }}>Icon 2</div> */}
      </div>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            background: "#f6f6f8",
          }}
        >
          {/* {path === "/snp/raw-material/requirement" && (
            <StyledOverlay>
              <StyledComingSoon>Coming soon...</StyledComingSoon>
              <StyledStayTuned>
                We are working hard to bring you something amazing. Stay tuned!
              </StyledStayTuned>
            </StyledOverlay>
          )} */}
          <Tabs
            value={value}
            // onChange={handleChange}
            textColor="primary"
            indicatorColor="primary"
            aria-label="secondary tabs example"
            sx={{
              "& .MuiButtonBase-root": {
                textTransform: "capitalize",
                minHeight: "40px",
                lineHeight: "40px",
              },
              "& .MuiTab-root": {
                textTransform: "capitalize",
              },
              "& .MuiTabs-indicator": {
                height: "3px",
                borderRadius: "5px",
                backgroundColor: "#e94f1b",
              },
              "& .MuiButtonBase-root.Mui-selected": {
                color: "#1c1c1c",
              },
              minHeight: "40px",
              height: "40px",
              background: "#f6f6f8",
            }}
          >
            <TabStyled
              value="one"
              label="Dashboard"
              onClick={() => navigate("")}
            />
            {isPreprocessPermissionAvailable && (
              <TabStyled
                value="three"
                label="Preprocesses"
                onClick={() => navigate("preprocesses")}
              />
            )}
            {isConfigPermissionAvailable && (
              <TabStyled
                value="two"
                label="Configuration"
                onClick={() => navigate("config")}
              />
            )}

            {isJSPermissionAvailable && (
              <TabStyled
                value="four"
                label="Job Scheduler"
                onClick={() => navigate("job-scheduler")}
              />
            )}
            {isNetworkPermissionAvailable && (
              <TabStyled
                value="five"
                label="Network Visualisation"
                onClick={() => navigate("network")}
              />
            )}
          </Tabs>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              paddingRight: "10px",
            }}
          >
            <GlobalCalendar
              openCalendar={openCalendar}
              setOpenCalendar={setOpenCalendar}
            />
            {viewFilters && (
              <div
                style={{
                  display: "inline-flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "8px",
                  border: "1px solid #DBDBDB",
                  borderRadius: "6px",
                  background: "white",
                }}
                onClick={() => {
                  dispatch(setIsFilterOpen(!isModalOpen));
                  dispatch(setFilterType("filter"));
                }}
              >
                <FilterIcon />
              </div>
            )}
            <div
              style={{
                width: "30px",
              }}
              onClick={() => setOpenCalendar(!openCalendar)}
            >
              <CalendarIcon />
            </div>
          </Box>
        </Box>
        <Outlet />
      </Box>
    </>
  );
}
