import { Cross2Icon } from "@radix-ui/react-icons";

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { DataTableViewOptions } from "./data-table-view-options";

// import { priorities, statuses } from "../data/data";
// import { DataTableFacetedFilter } from "./data-table-faceted-filter";

export function DataTableToolbar({ table, resetFilterButton, setResetFilterButton, handleResetButton }) {
  const isFiltered = resetFilterButton;

  return (
    <div className="flex items-center justify-end">
      <div className="flex items-center mr-3">
        {isFiltered && (
          <Button
            variant="ghost"
            onClick={() => {table.resetColumnFilters();
              handleResetButton();
              setResetFilterButton(false);
            }}
            className="h-8 px-2 lg:px-3 lg:h-[30px] mr-3"
          >
            <span className="font-[Inter] text-[13px]">Reset</span>
            <Cross2Icon className="ml-2 h-[15px] w-[15px] lg:ml-4" />
          </Button>
        )}
        <Input
          placeholder="Search here..."
          // value={table.getColumn("title")?.getFilterValue() ?? ""}
          // onChange={(event) =>
          //   table.getColumn("title")?.setFilterValue(event.target.value)
          // }
          className="h-[30px] w-[250px] lg:h-[30px] lg:w-[250px] "
        />
        {/*
        {table.getColumn("status") && (
          <DataTableFacetedFilter
            column={table.getColumn("status")}
            title="Status"
            options={statuses}
          />
        )}
        {table.getColumn("priority") && (
          <DataTableFacetedFilter
            column={table.getColumn("priority")}
            title="Priority"
            options={priorities}
          />
        )}
        */}
      </div>
      <DataTableViewOptions table={table} />
    </div>
  );
}
