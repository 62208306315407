import { useSelector } from "react-redux";
import { parseISO, isBefore, isAfter, isEqual } from "date-fns";
import "./InputStyles.css";

const DateInput = ({
  label,
  inputValue,
  handleInputValue,
  required = false,
  errorMsg = null,
  name = null,
  setErrors = null,
}) => {
  const mode = useSelector((state) => state.jobScheduler.jobSchedulerMode);
  const getInputValue = (inputDate) => {
    if (inputDate && typeof inputDate === "object") {
      let calcDate = new Date(inputDate.toISOString());
      const year = calcDate.getFullYear();
      const month = String(calcDate.getMonth() + 1).padStart(2, "0");
      const day = String(calcDate.getDate()).padStart(2, "0");
      // const hours = String(calcDate.getUTCHours()).padStart(2, "0");
      // const minutes = String(calcDate.getUTCMinutes()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    }
    return inputDate;
  };

  const handleInitialInputValue = (inputDate) => {
    if (mode === "create" && name === "scheduler-start-date") {
      return getInputValue(inputDate);
    } else if (
      mode === "edit" &&
      (name === "scheduler-start-date" || name === "scheduler-end-date")
    ) {
      return getInputValue(inputDate);
    }
    return inputDate;
  };

  const handleDateInput = (e) => {
    if (name !== "scheduler-start-date" && name !== "scheduler-end-date") {
      let userDate = parseISO(e.target.value);
      let currentDate = parseISO(new Date().toISOString().split("T")[0]);
      if (isAfter(currentDate, userDate)) {
        setErrors((prev) => ({
          ...prev,
          [`${name}`]: `${label} cannot be before Current Date`,
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          [`${name}`]: "",
        }));
      }
    }
    handleInputValue(e.target.value);
  };

  return (
    <div
      className={
        name === "scheduler-start-date" || name === "scheduler-end-date"
          ? "add-sto-input-box job-scheduler-date"
          : "add-sto-input-box "
      }
    >
      <label className="add-sto-input-label">
        {" "}
        {label} {required && <span className="required-asterisk">*</span>}
      </label>
      <input
        type="date"
        className={
          name === "scheduler-start-date" || name === "scheduler-end-date"
            ? "add-sto-select-box add-sto-input-text js-date-input"
            : "add-sto-select-box add-sto-input-text"
        }
        value={
          // name === "scheduler-start-date"
          //   ? handle
          //   : inputValue
          handleInitialInputValue(inputValue)
        }
        onChange={(e) => handleDateInput(e)}
        min={new Date().toISOString().split("T")[0]}
        max="9999-12-31"
      />
      {name !== "scheduler-start-date" && name !== "scheduler-end-date" && (
        <div className="error">{errorMsg}</div>
      )}
    </div>
  );
};

export default DateInput;
