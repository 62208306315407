export const baseOrgLevelData = [
  {
    mrp_id: "MRPCYC1010202134537",
    cycle: "Oct - Dec 2023",
    status: "In-Progress",
    groupLevelAggr: "Plans Approved (1/5)",
    action: "View",
  },
  {
    mrp_id: "MRPCYC8127648189540",
    cycle: "Sep - Nov 2023",
    status: "Completed",
    groupLevelAggr: "Plans Approved (5/5)",
    action: "View",
  },
  {
    mrp_id: "MRPCYC2727557416103",
    cycle: "Aug - Oct 2023",
    status: "Completed",
    groupLevelAggr: "Plans Approved (5/5)",
    action: "View",
  },
  {
    mrp_id: "MRPCYC1547624278660",
    cycle: "Jul - Sep 2023",
    status: "Completed",
    groupLevelAggr: "Plans Approved (5/5)",
    action: "View",
  },
  {
    mrp_id: "MRPCYC9288359703957",
    cycle: "Jun - Aug 2023",
    status: "Completed",
    groupLevelAggr: "Plans Approved (5/5)",
    action: "View",
  },
  {
    mrp_id: "MRPCYC9582913219653",
    cycle: "May - Jul 2023",
    status: "Completed",
    groupLevelAggr: "Plans Approved (5/5)",
    action: "View",
  },
  {
    mrp_id: "MRPCYC9470243004180",
    cycle: "Apr - Jun 2023",
    status: "Completed",
    groupLevelAggr: "Plans Approved (5/5)",
    action: "View",
  },
  {
    mrp_id: "MRPCYC2750337378748",
    cycle: "Mar - May 2023",
    status: "Completed",
    groupLevelAggr: "Plans Approved (5/5)",
    action: "View",
  },
  {
    mrp_id: "MRPCYC1781131271130",
    cycle: "Feb - Apr 2023",
    status: "Completed",
    groupLevelAggr: "Plans Approved (5/5)",
    action: "View",
  },
  {
    mrp_id: "MRPCYC1651018178652",
    cycle: "Jan - Mar 2023",
    status: "Completed",
    groupLevelAggr: "Plans Approved (5/5)",
    action: "View",
  },
  {
    mrp_id: "MRPCYC1010202132437",
    cycle: "Dec - Feb 2023",
    status: "Completed",
    groupLevelAggr: "Plans Approved (5/5)",
    action: "View",
  },
  {
    mrp_id: "MRPCYC8127648189827",
    cycle: "Nov - Jan 2023",
    status: "Completed",
    groupLevelAggr: "Plans Approved (5/5)",
    action: "View",
  },
];
export const baseGroupLevelData = [
  {
    cycle: "Oct - Dec 2023",
    planId: "PID13102023053002168643",
    planDate: "15-10-2023 5:30",
    group_code: "Group 1",
    last_updated: "15-10-2023 5:30",
    updated_by: "System",
    job_type: "adhoc",
    run_time: "60 Min",
    plan_status: "Planned",
    approval_status: "",
    action1: "Send/Revoke",
    action2: "Live",
    action: "View",
  },
  {
    cycle: "Oct - Dec 2023",
    planId: "PID13102023053002166775",
    planDate: "13-10-2023 3:21",
    group_code: "Group 1",
    last_updated: "13-10-2023 3:21",
    updated_by: "System",
    job_type: "adhoc",
    run_time: "60 Min",
    plan_status: "Planned",
    approval_status: "",
    action1: "Send/Revoke",
    action2: "Live",
    action: "View",
  },
  {
    cycle: "Oct - Dec 2023",
    planId: "PID13102023053002169683",
    planDate: "10-12-2023 03:22",
    group_code: "Group 1",
    last_updated: "10-12-2023 03:22",
    updated_by: "System",
    job_type: "Scheduled",
    run_time: "72 Min",
    plan_status: "Planned",
    approval_status: "",
    action1: "Send/Revoke",
    action2: "Live",
    action: "View",
  },
  {
    cycle: "Oct - Dec 2023",
    planId: "PID13102023053002167922",
    planDate: "10-12-2023 03:20",
    group_code: "Group 2",
    last_updated: "10-12-2023 03:20",
    updated_by: "System",
    job_type: "adhoc",
    run_time: "90 Min",
    plan_status: "Planned",
    approval_status: "",
    action1: "Send/Revoke",
    action2: "Live",
    action: "View",
  },
  {
    cycle: "Oct - Dec 2023",
    planId: "PID13102023053002167038",
    planDate: "10-11-2023 03:21",
    group_code: "Group 2",
    last_updated: "10-11-2023 03:21",
    updated_by: "System",
    job_type: "adhoc",
    run_time: "90 Min",
    plan_status: "Planned",
    approval_status: "",
    action1: "Send/Revoke",
    action2: "Live",
    action: "View",
  },
  {
    cycle: "Oct - Dec 2023",
    planId: "PID13102023053002169496",
    planDate: "10-11-2023 03:00",
    group_code: "Group 2",
    last_updated: "10-11-2023 03:00",
    updated_by: "System",
    job_type: "Scheduled",
    run_time: "86 Min",
    plan_status: "Planned",
    approval_status: "",
    action1: "Send/Revoke",
    action2: "Live",
    action: "View",
  },
  {
    cycle: "Oct - Dec 2023",
    planId: "PID13102023053002166655",
    planDate: "10-10-2023 03:23",
    group_code: "Group 2",
    last_updated: "10-10-2023 03:23",
    updated_by: "System",
    job_type: "adhoc",
    run_time: "66 Min",
    plan_status: "Planned",
    approval_status: "",
    action1: "Send/Revoke",
    action2: "Live",
    action: "View",
  },
  {
    cycle: "Sep - Nov 2023",
    planId: "PID13102023053002168336",
    planDate: "09-10-2023 03:23",
    group_code: "Group 1",
    last_updated: "09-10-2023 03:23",
    updated_by: "System",
    job_type: "adhoc",
    run_time: "67 Min",
    plan_status: "Planned",
    approval_status: "",
    action1: "Send/Revoke",
    action2: "Live",
    action: "View",
  },
  {
    cycle: "Sep - Nov 2023",
    planId: "PID13102023053002167126",
    planDate: "09-08-2023 03:23",
    group_code: "Group 1",
    last_updated: "09-08-2023 03:23",
    updated_by: "System",
    job_type: "adhoc",
    run_time: "68 Min",
    plan_status: "Planned",
    approval_status: "",
    action1: "Send/Revoke",
    action2: "Live",
    action: "View",
  },
  {
    cycle: "Sep - Nov 2023",
    planId: "PID13102023053002169463",
    planDate: "09-07-2023 03:00",
    group_code: "Group 1",
    last_updated: "09-07-2023 03:00",
    updated_by: "System",
    job_type: "Scheduled",
    run_time: "69 Min",
    plan_status: "Planned",
    approval_status: "",
    action1: "Send/Revoke",
    action2: "Live",
    action: "View",
  },
  {
    cycle: "Sep - Nov 2023",
    planId: "PID13102023053002167319",
    planDate: "09-07-2023 01:00",
    group_code: "Group 2",
    last_updated: "09-07-2023 01:00",
    updated_by: "System",
    job_type: "Scheduled",
    run_time: "70 Min",
    plan_status: "Planned",
    approval_status: "",
    action1: "Send/Revoke",
    action2: "Live",
    action: "View",
  },
  {
    cycle: "Sep - Nov 2023",
    planId: "PID13102023053002167729",
    planDate: "09-07-2023 00:00",
    group_code: "Group 2",
    last_updated: "09-07-2023 00:00",
    updated_by: "System",
    job_type: "Scheduled",
    run_time: "81 Min",
    plan_status: "Planned",
    approval_status: "",
    action1: "Send/Revoke",
    action2: "Live",
    action: "View",
  },
  {
    cycle: "Sep - Nov 2023",
    planId: "PID13102023053002168773",
    planDate: "09-06-2023 03:00",
    group_code: "Group 2",
    last_updated: "09-06-2023 03:00",
    updated_by: "System",
    job_type: "adhoc",
    run_time: "72 Min",
    plan_status: "Planned",
    approval_status: "",
    action1: "Send/Revoke",
    action2: "Live",
    action: "View",
  },
  {
    cycle: "Sep - Nov 2023",
    planId: "PID13102023053002167637",
    planDate: "09-06-2023 01:00",
    group_code: "Group 2",
    last_updated: "09-06-2023 01:00",
    updated_by: "System",
    job_type: "Scheduled",
    run_time: "73 Min",
    plan_status: "Planned",
    approval_status: "",
    action1: "Send/Revoke",
    action2: "Live",
    action: "View",
  },
  {
    cycle: "Sep - Nov 2023",
    planId: "PID13102023053002165628",
    planDate: "09-06-2023 00:00",
    group_code: "Group 1",
    last_updated: "09-06-2023 00:00",
    updated_by: "System",
    job_type: "Scheduled",
    run_time: "74 Min",
    plan_status: "Planned",
    approval_status: "",
    action1: "Send/Revoke",
    action2: "Live",
    action: "View",
  },
  {
    cycle: "Sep - Nov 2023",
    planId: "PID13102023053002166110",
    planDate: "09-05-2023 03:00",
    group_code: "Group 1",
    last_updated: "09-05-2023 03:00",
    updated_by: "System",
    job_type: "adhoc",
    run_time: "75 Min",
    plan_status: "Planned",
    approval_status: "",
    action1: "Send/Revoke",
    action2: "Live",
    action: "View",
  },
  {
    cycle: "Sep - Nov 2023",
    planId: "PID13102023053002167703",
    planDate: "09-05-2023 02:00",
    group_code: "Group 1",
    last_updated: "09-05-2023 02:00",
    updated_by: "System",
    job_type: "Scheduled",
    run_time: "100 Min",
    plan_status: "Planned",
    approval_status: "",
    action1: "Send/Revoke",
    action2: "Live",
    action: "View",
  },
  {
    cycle: "Sep - Nov 2023",
    planId: "PID13102023053002167787",
    planDate: "09-05-2023 01:00",
    group_code: "Group 2",
    last_updated: "09-05-2023 01:00",
    updated_by: "System",
    job_type: "adhoc",
    run_time: "77 Min",
    plan_status: "Planned",
    approval_status: "",
    action1: "Send/Revoke",
    action2: "Live",
    action: "View",
  },
  {
    cycle: "Aug - Oct 2023",
    planId: "PID13102023053002166298",
    planDate: "08-10-2023 03:00",
    group_code: "Group 2",
    last_updated: "08-10-2023 03:00",
    updated_by: "System",
    job_type: "Scheduled",
    run_time: "78 Min",
    plan_status: "Planned",
    approval_status: "",
    action1: "Send/Revoke",
    action2: "Live",
    action: "View",
  },
];

export const detailOrgGroupData = [
  {
    rmpo_code: "P-MRP-1001",
    rm_code: "RM-14392",
    rm_name: "Bolt 6mm Bronze glazing",
    batch_code: "Batch-28F-12801",
    batch_manf_date: "23-04-2023",
    batch_exp_date: "23-04-2024",
    src_supplier_code: "VEN-BLR-02",
    src_supplier_name: "GSK Fasteners, Bengaluru",
    dest_node_code: "WH-U15",
    mode_of_transport: "Normal - Road",
    rmpo_req_qty: 500,
    rmpo_req_del_date: "23-09-2023",
    rmpo_planned_quantity_system: 500,
    rmpo_planned_quantity_user: 500,
    rmpo_planned_delivery_date: "23-09-2023",
    rmpo_value: "$5,000",
    planned_rmpo_creation_date: "18-09-2023",
    planned_rmpo_packing_date: "19-09-2023",
    planned_rmpo_loading_date: "20-09-2023",
    planned_rmpo_shipping_date: "21-09-2023",
    planned_rmpo_unloading_date: "22-09-2023",
    planned_rmpo_completion_date: "23-09-2023",
    lead_time_in_days: 6,
    net_sales_rate: 90,
    item_cost: "$5",
    safety_stock: 50,
    maximum_stock_level: 600,
    approval_status: "Pending",
    approved_by: "vishal sharma",
    approved_date: "18-09-2023",
    version: 1.1,
    short_supply: 0,
    reason_code: "-",
    user_remarks: "-",
    fixed_shipping_cost: "$100",
    variable_shipping_cost: "$20",
    total_shipping_cost: "$120",
  },
  {
    rmpo_code: "P-MRP-1002",
    rm_code: "RM-32060",
    rm_name: "Nut 8mm Bronze glazing",
    batch_code: "Batch-28F-12802",
    batch_manf_date: "23-04-2023",
    batch_exp_date: "23-04-2024",
    src_supplier_code: "VEN-BLR-02",
    src_supplier_name: "GSK Fasteners, Bengaluru",
    dest_node_code: "WH-U16",
    mode_of_transport: "Normal - Road",
    rmpo_req_qty: 1000,
    rmpo_req_del_date: "23-09-2023",
    rmpo_planned_quantity_system: 1000,
    rmpo_planned_quantity_user: 1000,
    rmpo_planned_delivery_date: "23-09-2023",
    rmpo_value: "$10,000",
    planned_rmpo_creation_date: "18-09-2023",
    planned_rmpo_packing_date: "19-09-2023",
    planned_rmpo_loading_date: "20-09-2023",
    planned_rmpo_shipping_date: "21-09-2023",
    planned_rmpo_unloading_date: "22-09-2023",
    planned_rmpo_completion_date: "23-09-2023",
    lead_time_in_days: 6,
    net_sales_rate: 90,
    item_cost: "$10",
    safety_stock: 100,
    maximum_stock_level: 1100,
    approval_status: "Accepted",
    approved_by: "vishal sharma",
    approved_date: "18-09-2023",
    version: 1.1,
    short_supply: 0,
    reason_code: "-",
    user_remarks: "-",
    fixed_shipping_cost: "$100",
    variable_shipping_cost: "$29",
    total_shipping_cost: "$120",
  },
  {
    rmpo_code: "P-MRP-1003",
    rm_code: "RM-33831",
    rm_name: "Washer 10mm Bronze glazing",
    batch_code: "Batch-28F-12803",
    batch_manf_date: "23-04-2023",
    batch_exp_date: "23-04-2024",
    src_supplier_code: "VEN-BLR-02",
    src_supplier_name: "GSK Fasteners, Bengaluru",
    dest_node_code: "WH-U17",
    mode_of_transport: "Normal - Road",
    rmpo_req_qty: 1500,
    rmpo_req_del_date: "23-09-2023",
    rmpo_planned_quantity_system: 1500,
    rmpo_planned_quantity_user: 1500,
    rmpo_planned_delivery_date: "23-09-2023",
    rmpo_value: "$15,000",
    planned_rmpo_creation_date: "18-09-2023",
    planned_rmpo_packing_date: "19-09-2023",
    planned_rmpo_loading_date: "20-09-2023",
    planned_rmpo_shipping_date: "21-09-2023",
    planned_rmpo_unloading_date: "22-09-2023",
    planned_rmpo_completion_date: "23-09-2023",
    lead_time_in_days: 6,
    net_sales_rate: 90,
    item_cost: "$15",
    safety_stock: 150,
    maximum_stock_level: 1600,
    approval_status: "Accepted",
    approved_by: "vishal sharma",
    approved_date: "18-09-2023",
    version: 1.1,
    short_supply: 0,
    reason_code: "-",
    user_remarks: "-",
    fixed_shipping_cost: "$100",
    variable_shipping_cost: "$38",
    total_shipping_cost: "$120",
  },
  {
    rmpo_code: "P-MRP-1004",
    rm_code: "RM-18413",
    rm_name: "Bolt 10mm Bronze glazing",
    batch_code: "Batch-28F-12804",
    batch_manf_date: "23-04-2023",
    batch_exp_date: "23-04-2024",
    src_supplier_code: "VEN-BLR-02",
    src_supplier_name: "GSK Fasteners, Bengaluru",
    dest_node_code: "WH-U15",
    mode_of_transport: "Normal - Road",
    rmpo_req_qty: 500,
    rmpo_req_del_date: "23-09-2023",
    rmpo_planned_quantity_system: 500,
    rmpo_planned_quantity_user: 500,
    rmpo_planned_delivery_date: "23-09-2023",
    rmpo_value: "$5,000",
    planned_rmpo_creation_date: "18-09-2023",
    planned_rmpo_packing_date: "19-09-2023",
    planned_rmpo_loading_date: "20-09-2023",
    planned_rmpo_shipping_date: "21-09-2023",
    planned_rmpo_unloading_date: "22-09-2023",
    planned_rmpo_completion_date: "23-09-2023",
    lead_time_in_days: 6,
    net_sales_rate: 90,
    item_cost: "$5",
    safety_stock: 50,
    maximum_stock_level: 600,
    approval_status: "Accepted",
    approved_by: "vishal sharma",
    approved_date: "18-09-2023",
    version: 1.1,
    short_supply: 0,
    reason_code: "-",
    user_remarks: "-",
    fixed_shipping_cost: "$100",
    variable_shipping_cost: "$47",
    total_shipping_cost: "$120",
  },
  {
    rmpo_code: "P-MRP-1005",
    rm_code: "RM-28113",
    rm_name: "Nut 12mm Bronze glazing",
    batch_code: "Batch-28F-12805",
    batch_manf_date: "23-04-2023",
    batch_exp_date: "23-04-2024",
    src_supplier_code: "VEN-BLR-02",
    src_supplier_name: "GSK Fasteners, Bengaluru",
    dest_node_code: "WH-U16",
    mode_of_transport: "Normal - Road",
    rmpo_req_qty: 1000,
    rmpo_req_del_date: "23-09-2023",
    rmpo_planned_quantity_system: 1000,
    rmpo_planned_quantity_user: 1000,
    rmpo_planned_delivery_date: "23-09-2023",
    rmpo_value: "$10,000",
    planned_rmpo_creation_date: "18-09-2023",
    planned_rmpo_packing_date: "19-09-2023",
    planned_rmpo_loading_date: "20-09-2023",
    planned_rmpo_shipping_date: "21-09-2023",
    planned_rmpo_unloading_date: "22-09-2023",
    planned_rmpo_completion_date: "23-09-2023",
    lead_time_in_days: 6,
    net_sales_rate: 90,
    item_cost: "$10",
    safety_stock: 100,
    maximum_stock_level: 1100,
    approval_status: "Pending",
    approved_by: "vishal sharma",
    approved_date: "18-09-2023",
    version: 1.1,
    short_supply: 0,
    reason_code: "-",
    user_remarks: "-",
    fixed_shipping_cost: "$100",
    variable_shipping_cost: "$56",
    total_shipping_cost: "$120",
  },
  {
    rmpo_code: "P-MRP-1006",
    rm_code: "RM-20840",
    rm_name: "Washer 14mm Bronze glazing",
    batch_code: "Batch-28F-12806",
    batch_manf_date: "23-04-2023",
    batch_exp_date: "23-04-2024",
    src_supplier_code: "VEN-BLR-02",
    src_supplier_name: "GSK Fasteners, Bengaluru",
    dest_node_code: "WH-U17",
    mode_of_transport: "Normal - Road",
    rmpo_req_qty: 1500,
    rmpo_req_del_date: "23-09-2023",
    rmpo_planned_quantity_system: 1500,
    rmpo_planned_quantity_user: 1500,
    rmpo_planned_delivery_date: "23-09-2023",
    rmpo_value: "$15,000",
    planned_rmpo_creation_date: "18-09-2023",
    planned_rmpo_packing_date: "19-09-2023",
    planned_rmpo_loading_date: "20-09-2023",
    planned_rmpo_shipping_date: "21-09-2023",
    planned_rmpo_unloading_date: "22-09-2023",
    planned_rmpo_completion_date: "23-09-2023",
    lead_time_in_days: 6,
    net_sales_rate: 90,
    item_cost: "$15",
    safety_stock: 150,
    maximum_stock_level: 1600,
    approval_status: "Pending",
    approved_by: "vishal sharma",
    approved_date: "18-09-2023",
    version: 1.1,
    short_supply: 0,
    reason_code: "-",
    user_remarks: "-",
    fixed_shipping_cost: "$100",
    variable_shipping_cost: "$65",
    total_shipping_cost: "$120",
  },
  {
    rmpo_code: "P-MRP-1007",
    rm_code: "RM-20583",
    rm_name: "Bolt 8mm Bronze glazing",
    batch_code: "Batch-28F-12807",
    batch_manf_date: "23-04-2023",
    batch_exp_date: "23-04-2024",
    src_supplier_code: "VEN-PUN-02",
    src_supplier_name: "Sundaram Fasteners, Pune",
    dest_node_code: "WH-U15",
    mode_of_transport: "Normal - Road",
    rmpo_req_qty: 500,
    rmpo_req_del_date: "23-09-2023",
    rmpo_planned_quantity_system: 500,
    rmpo_planned_quantity_user: 500,
    rmpo_planned_delivery_date: "23-09-2023",
    rmpo_value: "$5,000",
    planned_rmpo_creation_date: "18-09-2023",
    planned_rmpo_packing_date: "19-09-2023",
    planned_rmpo_loading_date: "20-09-2023",
    planned_rmpo_shipping_date: "21-09-2023",
    planned_rmpo_unloading_date: "22-09-2023",
    planned_rmpo_completion_date: "23-09-2023",
    lead_time_in_days: 6,
    net_sales_rate: 90,
    item_cost: "$5",
    safety_stock: 50,
    maximum_stock_level: 600,
    approval_status: "Pending",
    approved_by: "vishal sharma",
    approved_date: "18-09-2023",
    version: 1.1,
    short_supply: 0,
    reason_code: "-",
    user_remarks: "-",
    fixed_shipping_cost: "$100",
    variable_shipping_cost: "$74",
    total_shipping_cost: "$120",
  },
  {
    rmpo_code: "P-MRP-1008",
    rm_code: "RM-34632",
    rm_name: "Nut 10mm Bronze glazing",
    batch_code: "Batch-28F-12808",
    batch_manf_date: "23-04-2023",
    batch_exp_date: "23-04-2024",
    src_supplier_code: "VEN-PUN-02",
    src_supplier_name: "Sundaram Fasteners, Pune",
    dest_node_code: "WH-U16",
    mode_of_transport: "Normal - Road",
    rmpo_req_qty: 1000,
    rmpo_req_del_date: "23-09-2023",
    rmpo_planned_quantity_system: 1000,
    rmpo_planned_quantity_user: 1000,
    rmpo_planned_delivery_date: "23-09-2023",
    rmpo_value: "$10,000",
    planned_rmpo_creation_date: "18-09-2023",
    planned_rmpo_packing_date: "19-09-2023",
    planned_rmpo_loading_date: "20-09-2023",
    planned_rmpo_shipping_date: "21-09-2023",
    planned_rmpo_unloading_date: "22-09-2023",
    planned_rmpo_completion_date: "23-09-2023",
    lead_time_in_days: 6,
    net_sales_rate: 90,
    item_cost: "$10",
    safety_stock: 100,
    maximum_stock_level: 1100,
    approval_status: "Pending",
    approved_by: "vishal sharma",
    approved_date: "18-09-2023",
    version: 1.1,
    short_supply: 0,
    reason_code: "-",
    user_remarks: "-",
    fixed_shipping_cost: "$100",
    variable_shipping_cost: "$83",
    total_shipping_cost: "$120",
  },
  {
    rmpo_code: "P-MRP-1009",
    rm_code: "RM-41311",
    rm_name: "Washer 12mm Bronze glazing",
    batch_code: "Batch-28F-12809",
    batch_manf_date: "23-04-2023",
    batch_exp_date: "23-04-2024",
    src_supplier_code: "VEN-PUN-02",
    src_supplier_name: "Sundaram Fasteners, Pune",
    dest_node_code: "WH-U17",
    mode_of_transport: "Normal - Road",
    rmpo_req_qty: 1500,
    rmpo_req_del_date: "23-09-2023",
    rmpo_planned_quantity_system: 1500,
    rmpo_planned_quantity_user: 1500,
    rmpo_planned_delivery_date: "23-09-2023",
    rmpo_value: "$15,000",
    planned_rmpo_creation_date: "18-09-2023",
    planned_rmpo_packing_date: "19-09-2023",
    planned_rmpo_loading_date: "20-09-2023",
    planned_rmpo_shipping_date: "21-09-2023",
    planned_rmpo_unloading_date: "22-09-2023",
    planned_rmpo_completion_date: "23-09-2023",
    lead_time_in_days: 6,
    net_sales_rate: 90,
    item_cost: "$15",
    safety_stock: 150,
    maximum_stock_level: 1600,
    approval_status: "Pending",
    approved_by: "vishal sharma",
    approved_date: "18-09-2023",
    version: 1.1,
    short_supply: 0,
    reason_code: "-",
    user_remarks: "-",
    fixed_shipping_cost: "$100",
    variable_shipping_cost: "$92",
    total_shipping_cost: "$120",
  },
  {
    rmpo_code: "P-MRP-1010",
    rm_code: "RM-38326",
    rm_name: "Bolt 12mm Bronze glazing",
    batch_code: "Batch-28F-12810",
    batch_manf_date: "23-04-2023",
    batch_exp_date: "23-04-2024",
    src_supplier_code: "VEN-PUN-02",
    src_supplier_name: "Sundaram Fasteners, Pune",
    dest_node_code: "WH-U15",
    mode_of_transport: "Normal - Road",
    rmpo_req_qty: 500,
    rmpo_req_del_date: "23-09-2023",
    rmpo_planned_quantity_system: 500,
    rmpo_planned_quantity_user: 500,
    rmpo_planned_delivery_date: "23-09-2023",
    rmpo_value: "$5,000",
    planned_rmpo_creation_date: "18-09-2023",
    planned_rmpo_packing_date: "19-09-2023",
    planned_rmpo_loading_date: "20-09-2023",
    planned_rmpo_shipping_date: "21-09-2023",
    planned_rmpo_unloading_date: "22-09-2023",
    planned_rmpo_completion_date: "23-09-2023",
    lead_time_in_days: 6,
    net_sales_rate: 90,
    item_cost: "$5",
    safety_stock: 50,
    maximum_stock_level: 600,
    approval_status: "Accepted",
    approved_by: "vishal sharma",
    approved_date: "18-09-2023",
    version: 1.1,
    short_supply: 0,
    reason_code: "-",
    user_remarks: "-",
    fixed_shipping_cost: "$100",
    variable_shipping_cost: "$101",
    total_shipping_cost: "$120",
  },
  {
    rmpo_code: "P-MRP-1011",
    rm_code: "RM-27420",
    rm_name: "Nut 14mm Bronze glazing",
    batch_code: "Batch-28F-12811",
    batch_manf_date: "23-04-2023",
    batch_exp_date: "23-04-2024",
    src_supplier_code: "VEN-PUN-02",
    src_supplier_name: "Sundaram Fasteners, Pune",
    dest_node_code: "WH-U16",
    mode_of_transport: "Normal - Road",
    rmpo_req_qty: 1000,
    rmpo_req_del_date: "23-09-2023",
    rmpo_planned_quantity_system: 1000,
    rmpo_planned_quantity_user: 1000,
    rmpo_planned_delivery_date: "23-09-2023",
    rmpo_value: "$10,000",
    planned_rmpo_creation_date: "18-09-2023",
    planned_rmpo_packing_date: "19-09-2023",
    planned_rmpo_loading_date: "20-09-2023",
    planned_rmpo_shipping_date: "21-09-2023",
    planned_rmpo_unloading_date: "22-09-2023",
    planned_rmpo_completion_date: "23-09-2023",
    lead_time_in_days: 6,
    net_sales_rate: 90,
    item_cost: "$10",
    safety_stock: 100,
    maximum_stock_level: 1100,
    approval_status: "Accepted",
    approved_by: "vishal sharma",
    approved_date: "18-09-2023",
    version: 1.1,
    short_supply: 0,
    reason_code: "-",
    user_remarks: "-",
    fixed_shipping_cost: "$100",
    variable_shipping_cost: "$110",
    total_shipping_cost: "$120",
  },
  {
    rmpo_code: "P-MRP-1012",
    rm_code: "RM-35692",
    rm_name: "Washer 16mm Bronze glazing",
    batch_code: "Batch-28F-12812",
    batch_manf_date: "23-04-2023",
    batch_exp_date: "23-04-2024",
    src_supplier_code: "VEN-PUN-02",
    src_supplier_name: "Sundaram Fasteners, Pune",
    dest_node_code: "WH-U17",
    mode_of_transport: "Normal - Road",
    rmpo_req_qty: 1500,
    rmpo_req_del_date: "23-09-2023",
    rmpo_planned_quantity_system: 1500,
    rmpo_planned_quantity_user: 1500,
    rmpo_planned_delivery_date: "23-09-2023",
    rmpo_value: "$15,000",
    planned_rmpo_creation_date: "18-09-2023",
    planned_rmpo_packing_date: "19-09-2023",
    planned_rmpo_loading_date: "20-09-2023",
    planned_rmpo_shipping_date: "21-09-2023",
    planned_rmpo_unloading_date: "22-09-2023",
    planned_rmpo_completion_date: "23-09-2023",
    lead_time_in_days: 6,
    net_sales_rate: 90,
    item_cost: "$15",
    safety_stock: 150,
    maximum_stock_level: 1600,
    approval_status: "Pending",
    approved_by: "vishal sharma",
    approved_date: "18-09-2023",
    version: 1.1,
    short_supply: 0,
    reason_code: "-",
    user_remarks: "-",
    fixed_shipping_cost: "$100",
    variable_shipping_cost: "$119",
    total_shipping_cost: "$120",
  },
  {
    rmpo_code: "P-MRP-1013",
    rm_code: "RM-12926",
    rm_name: "Bolt 6mm  Stainless Steel",
    batch_code: "Batch-28F-12813",
    batch_manf_date: "23-04-2023",
    batch_exp_date: "23-04-2024",
    src_supplier_code: "VEN-PUN-02",
    src_supplier_name: "Sundaram Fasteners, Pune",
    dest_node_code: "WH-U15",
    mode_of_transport: "Normal - Road",
    rmpo_req_qty: 500,
    rmpo_req_del_date: "23-09-2023",
    rmpo_planned_quantity_system: 500,
    rmpo_planned_quantity_user: 500,
    rmpo_planned_delivery_date: "23-09-2023",
    rmpo_value: "$5,000",
    planned_rmpo_creation_date: "18-09-2023",
    planned_rmpo_packing_date: "19-09-2023",
    planned_rmpo_loading_date: "20-09-2023",
    planned_rmpo_shipping_date: "21-09-2023",
    planned_rmpo_unloading_date: "22-09-2023",
    planned_rmpo_completion_date: "23-09-2023",
    lead_time_in_days: 6,
    net_sales_rate: 90,
    item_cost: "$5",
    safety_stock: 50,
    maximum_stock_level: 600,
    approval_status: "Pending",
    approved_by: "vishal sharma",
    approved_date: "18-09-2023",
    version: 1.1,
    short_supply: 0,
    reason_code: "-",
    user_remarks: "-",
    fixed_shipping_cost: "$100",
    variable_shipping_cost: "$128",
    total_shipping_cost: "$120",
  },
  {
    rmpo_code: "P-MRP-1014",
    rm_code: "RM-37221",
    rm_name: "Nut 8mm Stainless Steel",
    batch_code: "Batch-28F-12814",
    batch_manf_date: "23-04-2023",
    batch_exp_date: "23-04-2024",
    src_supplier_code: "VEN-PUN-02",
    src_supplier_name: "Sundaram Fasteners, Pune",
    dest_node_code: "WH-U16",
    mode_of_transport: "Normal - Road",
    rmpo_req_qty: 1000,
    rmpo_req_del_date: "23-09-2023",
    rmpo_planned_quantity_system: 1000,
    rmpo_planned_quantity_user: 1000,
    rmpo_planned_delivery_date: "23-09-2023",
    rmpo_value: "$10,000",
    planned_rmpo_creation_date: "18-09-2023",
    planned_rmpo_packing_date: "19-09-2023",
    planned_rmpo_loading_date: "20-09-2023",
    planned_rmpo_shipping_date: "21-09-2023",
    planned_rmpo_unloading_date: "22-09-2023",
    planned_rmpo_completion_date: "23-09-2023",
    lead_time_in_days: 6,
    net_sales_rate: 90,
    item_cost: "$10",
    safety_stock: 100,
    maximum_stock_level: 1100,
    approval_status: "Pending",
    approved_by: "vishal sharma",
    approved_date: "18-09-2023",
    version: 1.1,
    short_supply: 0,
    reason_code: "-",
    user_remarks: "-",
    fixed_shipping_cost: "$100",
    variable_shipping_cost: "$137",
    total_shipping_cost: "$120",
  },
  {
    rmpo_code: "P-MRP-1015",
    rm_code: "RM-37222",
    rm_name: "Washer 10mm Stainless Steel",
    batch_code: "Batch-28F-12815",
    batch_manf_date: "23-04-2023",
    batch_exp_date: "23-04-2024",
    src_supplier_code: "VEN-PUN-02",
    src_supplier_name: "Sundaram Fasteners, Pune",
    dest_node_code: "WH-U15",
    mode_of_transport: "Normal - Road",
    rmpo_req_qty: 500,
    rmpo_req_del_date: "23-09-2023",
    rmpo_planned_quantity_system: 500,
    rmpo_planned_quantity_user: 500,
    rmpo_planned_delivery_date: "23-09-2023",
    rmpo_value: "$5,000",
    planned_rmpo_creation_date: "18-09-2023",
    planned_rmpo_packing_date: "19-09-2023",
    planned_rmpo_loading_date: "20-09-2023",
    planned_rmpo_shipping_date: "21-09-2023",
    planned_rmpo_unloading_date: "22-09-2023",
    planned_rmpo_completion_date: "23-09-2023",
    lead_time_in_days: 6,
    net_sales_rate: 90,
    item_cost: "$5",
    safety_stock: 50,
    maximum_stock_level: 600,
    approval_status: "Accepted",
    approved_by: "vishal sharma",
    approved_date: "18-09-2023",
    version: 1.1,
    short_supply: 0,
    reason_code: "-",
    user_remarks: "-",
    fixed_shipping_cost: "$100",
    variable_shipping_cost: "$146",
    total_shipping_cost: "$120",
  },
  {
    rmpo_code: "P-MRP-1016",
    rm_code: "RM-38674",
    rm_name: "Bolt 10mm Stainless Steel",
    batch_code: "Batch-28F-12816",
    batch_manf_date: "23-04-2023",
    batch_exp_date: "23-04-2024",
    src_supplier_code: "VEN-AMD-02",
    src_supplier_name: "Shri Pistons & Rings, Ahemdabad",
    dest_node_code: "WH-U16",
    mode_of_transport: "Normal - Road",
    rmpo_req_qty: 1000,
    rmpo_req_del_date: "23-09-2023",
    rmpo_planned_quantity_system: 1000,
    rmpo_planned_quantity_user: 1000,
    rmpo_planned_delivery_date: "23-09-2023",
    rmpo_value: "$10,000",
    planned_rmpo_creation_date: "18-09-2023",
    planned_rmpo_packing_date: "19-09-2023",
    planned_rmpo_loading_date: "20-09-2023",
    planned_rmpo_shipping_date: "21-09-2023",
    planned_rmpo_unloading_date: "22-09-2023",
    planned_rmpo_completion_date: "23-09-2023",
    lead_time_in_days: 6,
    net_sales_rate: 90,
    item_cost: "$10",
    safety_stock: 100,
    maximum_stock_level: 1100,
    approval_status: "Accepted",
    approved_by: "vishal sharma",
    approved_date: "18-09-2023",
    version: 1.1,
    short_supply: 0,
    reason_code: "-",
    user_remarks: "-",
    fixed_shipping_cost: "$100",
    variable_shipping_cost: "$155",
    total_shipping_cost: "$120",
  },
  {
    rmpo_code: "P-MRP-1017",
    rm_code: "RM-37224",
    rm_name: "Nut 12mm Stainless Steel",
    batch_code: "Batch-28F-12817",
    batch_manf_date: "23-04-2023",
    batch_exp_date: "23-04-2024",
    src_supplier_code: "VEN-AMD-02",
    src_supplier_name: "Shri Pistons & Rings, Ahemdabad",
    dest_node_code: "WH-U17",
    mode_of_transport: "Normal - Road",
    rmpo_req_qty: 1500,
    rmpo_req_del_date: "23-09-2023",
    rmpo_planned_quantity_system: 1500,
    rmpo_planned_quantity_user: 1500,
    rmpo_planned_delivery_date: "23-09-2023",
    rmpo_value: "$15,000",
    planned_rmpo_creation_date: "18-09-2023",
    planned_rmpo_packing_date: "19-09-2023",
    planned_rmpo_loading_date: "20-09-2023",
    planned_rmpo_shipping_date: "21-09-2023",
    planned_rmpo_unloading_date: "22-09-2023",
    planned_rmpo_completion_date: "23-09-2023",
    lead_time_in_days: 6,
    net_sales_rate: 90,
    item_cost: "$15",
    safety_stock: 150,
    maximum_stock_level: 1600,
    approval_status: "Pending",
    approved_by: "vishal sharma",
    approved_date: "18-09-2023",
    version: 1.1,
    short_supply: 0,
    reason_code: "-",
    user_remarks: "-",
    fixed_shipping_cost: "$100",
    variable_shipping_cost: "$164",
    total_shipping_cost: "$120",
  },
  {
    rmpo_code: "P-MRP-1018",
    rm_code: "RM-36535",
    rm_name: "Washer 14mm Stainless Steel",
    batch_code: "Batch-28F-12818",
    batch_manf_date: "23-04-2023",
    batch_exp_date: "23-04-2024",
    src_supplier_code: "VEN-AMD-02",
    src_supplier_name: "Shri Pistons & Rings, Ahemdabad",
    dest_node_code: "WH-U15",
    mode_of_transport: "Normal - Road",
    rmpo_req_qty: 500,
    rmpo_req_del_date: "23-09-2023",
    rmpo_planned_quantity_system: 500,
    rmpo_planned_quantity_user: 500,
    rmpo_planned_delivery_date: "23-09-2023",
    rmpo_value: "$5,000",
    planned_rmpo_creation_date: "18-09-2023",
    planned_rmpo_packing_date: "19-09-2023",
    planned_rmpo_loading_date: "20-09-2023",
    planned_rmpo_shipping_date: "21-09-2023",
    planned_rmpo_unloading_date: "22-09-2023",
    planned_rmpo_completion_date: "23-09-2023",
    lead_time_in_days: 6,
    net_sales_rate: 90,
    item_cost: "$5",
    safety_stock: 50,
    maximum_stock_level: 600,
    approval_status: "Accepted",
    approved_by: "vishal sharma",
    approved_date: "18-09-2023",
    version: 1.1,
    short_supply: 0,
    reason_code: "-",
    user_remarks: "-",
    fixed_shipping_cost: "$100",
    variable_shipping_cost: "$173",
    total_shipping_cost: "$120",
  },
  {
    rmpo_code: "P-MRP-1019",
    rm_code: "RM-45748",
    rm_name: "Bolt 8mm Stainless Steel",
    batch_code: "Batch-28F-12819",
    batch_manf_date: "23-04-2023",
    batch_exp_date: "23-04-2024",
    src_supplier_code: "VEN-AMD-02",
    src_supplier_name: "Shri Pistons & Rings, Ahemdabad",
    dest_node_code: "WH-U16",
    mode_of_transport: "Normal - Road",
    rmpo_req_qty: 1000,
    rmpo_req_del_date: "23-09-2023",
    rmpo_planned_quantity_system: 1000,
    rmpo_planned_quantity_user: 1000,
    rmpo_planned_delivery_date: "23-09-2023",
    rmpo_value: "$10,000",
    planned_rmpo_creation_date: "18-09-2023",
    planned_rmpo_packing_date: "19-09-2023",
    planned_rmpo_loading_date: "20-09-2023",
    planned_rmpo_shipping_date: "21-09-2023",
    planned_rmpo_unloading_date: "22-09-2023",
    planned_rmpo_completion_date: "23-09-2023",
    lead_time_in_days: 6,
    net_sales_rate: 90,
    item_cost: "$10",
    safety_stock: 100,
    maximum_stock_level: 1100,
    approval_status: "Accepted",
    approved_by: "vishal sharma",
    approved_date: "18-09-2023",
    version: 1.1,
    short_supply: 0,
    reason_code: "-",
    user_remarks: "-",
    fixed_shipping_cost: "$100",
    variable_shipping_cost: "$182",
    total_shipping_cost: "$120",
  },
  {
    rmpo_code: "P-MRP-1020",
    rm_code: "RM-37227",
    rm_name: "Nut 10mmStainless Steel",
    batch_code: "Batch-28F-12820",
    batch_manf_date: "23-04-2023",
    batch_exp_date: "23-04-2024",
    src_supplier_code: "VEN-AMD-02",
    src_supplier_name: "Shri Pistons & Rings, Ahemdabad",
    dest_node_code: "WH-U17",
    mode_of_transport: "Normal - Road",
    rmpo_req_qty: 1500,
    rmpo_req_del_date: "23-09-2023",
    rmpo_planned_quantity_system: 1500,
    rmpo_planned_quantity_user: 1500,
    rmpo_planned_delivery_date: "23-09-2023",
    rmpo_value: "$15,000",
    planned_rmpo_creation_date: "18-09-2023",
    planned_rmpo_packing_date: "19-09-2023",
    planned_rmpo_loading_date: "20-09-2023",
    planned_rmpo_shipping_date: "21-09-2023",
    planned_rmpo_unloading_date: "22-09-2023",
    planned_rmpo_completion_date: "23-09-2023",
    lead_time_in_days: 6,
    net_sales_rate: 90,
    item_cost: "$15",
    safety_stock: 150,
    maximum_stock_level: 1600,
    approval_status: "Pending",
    approved_by: "vishal sharma",
    approved_date: "18-09-2023",
    version: 1.1,
    short_supply: 0,
    reason_code: "-",
    user_remarks: "-",
    fixed_shipping_cost: "$100",
    variable_shipping_cost: "$191",
    total_shipping_cost: "$120",
  },
  {
    rmpo_code: "P-MRP-1021",
    rm_code: "RM-57488",
    rm_name: "Washer 12mm Stainless Steel",
    batch_code: "Batch-28F-12821",
    batch_manf_date: "23-04-2023",
    batch_exp_date: "23-04-2024",
    src_supplier_code: "VEN-AMD-02",
    src_supplier_name: "Shri Pistons & Rings, Ahemdabad",
    dest_node_code: "WH-U18",
    mode_of_transport: "Normal - Road",
    rmpo_req_qty: 500,
    rmpo_req_del_date: "23-09-2023",
    rmpo_planned_quantity_system: 500,
    rmpo_planned_quantity_user: 500,
    rmpo_planned_delivery_date: "23-09-2023",
    rmpo_value: "$5,000",
    planned_rmpo_creation_date: "18-09-2023",
    planned_rmpo_packing_date: "19-09-2023",
    planned_rmpo_loading_date: "20-09-2023",
    planned_rmpo_shipping_date: "21-09-2023",
    planned_rmpo_unloading_date: "22-09-2023",
    planned_rmpo_completion_date: "23-09-2023",
    lead_time_in_days: 6,
    net_sales_rate: 90,
    item_cost: "$5",
    safety_stock: 50,
    maximum_stock_level: 600,
    approval_status: "Accepted",
    approved_by: "vishal sharma",
    approved_date: "18-09-2023",
    version: 1.1,
    short_supply: 0,
    reason_code: "-",
    user_remarks: "-",
    fixed_shipping_cost: "$100",
    variable_shipping_cost: "$200",
    total_shipping_cost: "$120",
  },
  {
    rmpo_code: "P-MRP-1022",
    rm_code: "RM-45645",
    rm_name: "Bolt 12mm Stainless Steel",
    batch_code: "Batch-28F-12822",
    batch_manf_date: "23-04-2023",
    batch_exp_date: "23-04-2024",
    src_supplier_code: "VEN-AMD-02",
    src_supplier_name: "Shri Pistons & Rings, Ahemdabad",
    dest_node_code: "WH-U15",
    mode_of_transport: "Normal - Road",
    rmpo_req_qty: 1000,
    rmpo_req_del_date: "23-09-2023",
    rmpo_planned_quantity_system: 1000,
    rmpo_planned_quantity_user: 1000,
    rmpo_planned_delivery_date: "23-09-2023",
    rmpo_value: "$10,000",
    planned_rmpo_creation_date: "18-09-2023",
    planned_rmpo_packing_date: "19-09-2023",
    planned_rmpo_loading_date: "20-09-2023",
    planned_rmpo_shipping_date: "21-09-2023",
    planned_rmpo_unloading_date: "22-09-2023",
    planned_rmpo_completion_date: "23-09-2023",
    lead_time_in_days: 6,
    net_sales_rate: 90,
    item_cost: "$10",
    safety_stock: 100,
    maximum_stock_level: 1100,
    approval_status: "Accepted",
    approved_by: "vishal sharma",
    approved_date: "18-09-2023",
    version: 1.1,
    short_supply: 0,
    reason_code: "-",
    user_remarks: "-",
    fixed_shipping_cost: "$100",
    variable_shipping_cost: "$209",
    total_shipping_cost: "$120",
  },
  {
    rmpo_code: "P-MRP-1023",
    rm_code: "RM-37230",
    rm_name: "Nut 14mm Stainless Steel",
    batch_code: "Batch-28F-12823",
    batch_manf_date: "23-04-2023",
    batch_exp_date: "23-04-2024",
    src_supplier_code: "VEN-AMD-02",
    src_supplier_name: "Shri Pistons & Rings, Ahemdabad",
    dest_node_code: "WH-U16",
    mode_of_transport: "Normal - Road",
    rmpo_req_qty: 1500,
    rmpo_req_del_date: "23-09-2023",
    rmpo_planned_quantity_system: 1500,
    rmpo_planned_quantity_user: 1500,
    rmpo_planned_delivery_date: "23-09-2023",
    rmpo_value: "$15,000",
    planned_rmpo_creation_date: "18-09-2023",
    planned_rmpo_packing_date: "19-09-2023",
    planned_rmpo_loading_date: "20-09-2023",
    planned_rmpo_shipping_date: "21-09-2023",
    planned_rmpo_unloading_date: "22-09-2023",
    planned_rmpo_completion_date: "23-09-2023",
    lead_time_in_days: 6,
    net_sales_rate: 90,
    item_cost: "$15",
    safety_stock: 150,
    maximum_stock_level: 1600,
    approval_status: "Pending",
    approved_by: "vishal sharma",
    approved_date: "18-09-2023",
    version: 1.1,
    short_supply: 0,
    reason_code: "-",
    user_remarks: "-",
    fixed_shipping_cost: "$100",
    variable_shipping_cost: "$218",
    total_shipping_cost: "$120",
  },
  {
    rmpo_code: "P-MRP-1024",
    rm_code: "RM-37231",
    rm_name: "Washer 16mm Stainless Steel",
    batch_code: "Batch-28F-12824",
    batch_manf_date: "23-04-2023",
    batch_exp_date: "23-04-2024",
    src_supplier_code: "VEN-AMD-02",
    src_supplier_name: "Shri Pistons & Rings, Ahemdabad",
    dest_node_code: "WH-U17",
    mode_of_transport: "Normal - Road",
    rmpo_req_qty: 500,
    rmpo_req_del_date: "23-09-2023",
    rmpo_planned_quantity_system: 500,
    rmpo_planned_quantity_user: 500,
    rmpo_planned_delivery_date: "23-09-2023",
    rmpo_value: "$5,000",
    planned_rmpo_creation_date: "18-09-2023",
    planned_rmpo_packing_date: "19-09-2023",
    planned_rmpo_loading_date: "20-09-2023",
    planned_rmpo_shipping_date: "21-09-2023",
    planned_rmpo_unloading_date: "22-09-2023",
    planned_rmpo_completion_date: "23-09-2023",
    lead_time_in_days: 6,
    net_sales_rate: 90,
    item_cost: "$5",
    safety_stock: 50,
    maximum_stock_level: 600,
    approval_status: "Accepted",
    approved_by: "vishal sharma",
    approved_date: "18-09-2023",
    version: 1.1,
    short_supply: 0,
    reason_code: "-",
    user_remarks: "-",
    fixed_shipping_cost: "$100",
    variable_shipping_cost: "$227",
    total_shipping_cost: "$120",
  },
  {
    rmpo_code: "P-MRP-1025",
    rm_code: "RM-45647",
    rm_name: "Bolt 2mm  Stainless Steel",
    batch_code: "Batch-28F-12825",
    batch_manf_date: "23-04-2023",
    batch_exp_date: "23-04-2024",
    src_supplier_code: "VEN-AMD-02",
    src_supplier_name: "Shri Pistons & Rings, Ahemdabad",
    dest_node_code: "WH-U15",
    mode_of_transport: "Normal - Road",
    rmpo_req_qty: 1000,
    rmpo_req_del_date: "23-09-2023",
    rmpo_planned_quantity_system: 1000,
    rmpo_planned_quantity_user: 1000,
    rmpo_planned_delivery_date: "23-09-2023",
    rmpo_value: "$10,000",
    planned_rmpo_creation_date: "18-09-2023",
    planned_rmpo_packing_date: "19-09-2023",
    planned_rmpo_loading_date: "20-09-2023",
    planned_rmpo_shipping_date: "21-09-2023",
    planned_rmpo_unloading_date: "22-09-2023",
    planned_rmpo_completion_date: "23-09-2023",
    lead_time_in_days: 6,
    net_sales_rate: 90,
    item_cost: "$10",
    safety_stock: 100,
    maximum_stock_level: 1100,
    approval_status: "Accepted",
    approved_by: "vishal sharma",
    approved_date: "18-09-2023",
    version: 1.1,
    short_supply: 0,
    reason_code: "-",
    user_remarks: "-",
    fixed_shipping_cost: "$100",
    variable_shipping_cost: "$236",
    total_shipping_cost: "$120",
  },
  {
    rmpo_code: "P-MRP-1026",
    rm_code: "RM-23456",
    rm_name: "Nut 4mm Stainless Steel",
    batch_code: "Batch-28F-12826",
    batch_manf_date: "23-04-2023",
    batch_exp_date: "23-04-2024",
    src_supplier_code: "VEN-AMD-02",
    src_supplier_name: "Shri Pistons & Rings, Ahemdabad",
    dest_node_code: "WH-U16",
    mode_of_transport: "Normal - Road",
    rmpo_req_qty: 1500,
    rmpo_req_del_date: "23-09-2023",
    rmpo_planned_quantity_system: 1500,
    rmpo_planned_quantity_user: 1500,
    rmpo_planned_delivery_date: "23-09-2023",
    rmpo_value: "$15,000",
    planned_rmpo_creation_date: "18-09-2023",
    planned_rmpo_packing_date: "19-09-2023",
    planned_rmpo_loading_date: "20-09-2023",
    planned_rmpo_shipping_date: "21-09-2023",
    planned_rmpo_unloading_date: "22-09-2023",
    planned_rmpo_completion_date: "23-09-2023",
    lead_time_in_days: 6,
    net_sales_rate: 90,
    item_cost: "$15",
    safety_stock: 150,
    maximum_stock_level: 1600,
    approval_status: "Pending",
    approved_by: "vishal sharma",
    approved_date: "18-09-2023",
    version: 1.1,
    short_supply: 0,
    reason_code: "-",
    user_remarks: "-",
    fixed_shipping_cost: "$100",
    variable_shipping_cost: "$245",
    total_shipping_cost: "$120",
  },
  {
    rmpo_code: "P-MRP-1027",
    rm_code: "RM-96785",
    rm_name: "Washer 6mm Stainless Steel",
    batch_code: "Batch-28F-12827",
    batch_manf_date: "23-04-2023",
    batch_exp_date: "23-04-2024",
    src_supplier_code: "VEN-AMD-02",
    src_supplier_name: "Shri Pistons & Rings, Ahemdabad",
    dest_node_code: "WH-U17",
    mode_of_transport: "Normal - Road",
    rmpo_req_qty: 500,
    rmpo_req_del_date: "23-09-2023",
    rmpo_planned_quantity_system: 500,
    rmpo_planned_quantity_user: 500,
    rmpo_planned_delivery_date: "23-09-2023",
    rmpo_value: "$5,000",
    planned_rmpo_creation_date: "18-09-2023",
    planned_rmpo_packing_date: "19-09-2023",
    planned_rmpo_loading_date: "20-09-2023",
    planned_rmpo_shipping_date: "21-09-2023",
    planned_rmpo_unloading_date: "22-09-2023",
    planned_rmpo_completion_date: "23-09-2023",
    lead_time_in_days: 6,
    net_sales_rate: 90,
    item_cost: "$5",
    safety_stock: 50,
    maximum_stock_level: 600,
    approval_status: "Accepted",
    approved_by: "vishal sharma",
    approved_date: "18-09-2023",
    version: 1.1,
    short_supply: 0,
    reason_code: "-",
    user_remarks: "-",
    fixed_shipping_cost: "$100",
    variable_shipping_cost: "$254",
    total_shipping_cost: "$120",
  },
  {
    rmpo_code: "P-MRP-1028",
    rm_code: "RM-37235",
    rm_name: "Bolt 4mm Stainless Steel",
    batch_code: "Batch-28F-12828",
    batch_manf_date: "23-04-2023",
    batch_exp_date: "23-04-2024",
    src_supplier_code: "VEN-AMD-02",
    src_supplier_name: "Shri Pistons & Rings, Ahemdabad",
    dest_node_code: "WH-U15",
    mode_of_transport: "Normal - Road",
    rmpo_req_qty: 1000,
    rmpo_req_del_date: "23-09-2023",
    rmpo_planned_quantity_system: 1000,
    rmpo_planned_quantity_user: 1000,
    rmpo_planned_delivery_date: "23-09-2023",
    rmpo_value: "$10,000",
    planned_rmpo_creation_date: "18-09-2023",
    planned_rmpo_packing_date: "19-09-2023",
    planned_rmpo_loading_date: "20-09-2023",
    planned_rmpo_shipping_date: "21-09-2023",
    planned_rmpo_unloading_date: "22-09-2023",
    planned_rmpo_completion_date: "23-09-2023",
    lead_time_in_days: 6,
    net_sales_rate: 90,
    item_cost: "$10",
    safety_stock: 100,
    maximum_stock_level: 1100,
    approval_status: "Accepted",
    approved_by: "vishal sharma",
    approved_date: "18-09-2023",
    version: 1.1,
    short_supply: 0,
    reason_code: "-",
    user_remarks: "-",
    fixed_shipping_cost: "$100",
    variable_shipping_cost: "$263",
    total_shipping_cost: "$120",
  },
  {
    rmpo_code: "P-MRP-1029",
    rm_code: "RM-37236",
    rm_name: "Nut 6mm Stainless Steel",
    batch_code: "Batch-28F-12829",
    batch_manf_date: "23-04-2023",
    batch_exp_date: "23-04-2024",
    src_supplier_code: "VEN-AMD-02",
    src_supplier_name: "Shri Pistons & Rings, Ahemdabad",
    dest_node_code: "WH-U16",
    mode_of_transport: "Normal - Road",
    rmpo_req_qty: 1500,
    rmpo_req_del_date: "23-09-2023",
    rmpo_planned_quantity_system: 1500,
    rmpo_planned_quantity_user: 1500,
    rmpo_planned_delivery_date: "23-09-2023",
    rmpo_value: "$15,000",
    planned_rmpo_creation_date: "18-09-2023",
    planned_rmpo_packing_date: "19-09-2023",
    planned_rmpo_loading_date: "20-09-2023",
    planned_rmpo_shipping_date: "21-09-2023",
    planned_rmpo_unloading_date: "22-09-2023",
    planned_rmpo_completion_date: "23-09-2023",
    lead_time_in_days: 6,
    net_sales_rate: 90,
    item_cost: "$15",
    safety_stock: 150,
    maximum_stock_level: 1600,
    approval_status: "Pending",
    approved_by: "vishal sharma",
    approved_date: "18-09-2023",
    version: 1.1,
    short_supply: 0,
    reason_code: "-",
    user_remarks: "-",
    fixed_shipping_cost: "$100",
    variable_shipping_cost: "$272",
    total_shipping_cost: "$120",
  },
  {
    rmpo_code: "P-MRP-1030",
    rm_code: "RM-45645",
    rm_name: "Washer 8mm Stainless Steel",
    batch_code: "Batch-28F-12830",
    batch_manf_date: "23-04-2023",
    batch_exp_date: "23-04-2024",
    src_supplier_code: "VEN-AMD-02",
    src_supplier_name: "Shri Pistons & Rings, Ahemdabad",
    dest_node_code: "WH-U17",
    mode_of_transport: "Normal - Road",
    rmpo_req_qty: 500,
    rmpo_req_del_date: "23-09-2023",
    rmpo_planned_quantity_system: 500,
    rmpo_planned_quantity_user: 500,
    rmpo_planned_delivery_date: "23-09-2023",
    rmpo_value: "$5,000",
    planned_rmpo_creation_date: "18-09-2023",
    planned_rmpo_packing_date: "19-09-2023",
    planned_rmpo_loading_date: "20-09-2023",
    planned_rmpo_shipping_date: "21-09-2023",
    planned_rmpo_unloading_date: "22-09-2023",
    planned_rmpo_completion_date: "23-09-2023",
    lead_time_in_days: 6,
    net_sales_rate: 90,
    item_cost: "$5",
    safety_stock: 50,
    maximum_stock_level: 600,
    approval_status: "Accepted",
    approved_by: "vishal sharma",
    approved_date: "18-09-2023",
    version: 1.1,
    short_supply: 0,
    reason_code: "-",
    user_remarks: "-",
    fixed_shipping_cost: "$100",
    variable_shipping_cost: "$281",
    total_shipping_cost: "$120",
  },
  {
    rmpo_code: "",
    rm_code: "",
    rm_name: "",
    batch_code: "",
    batch_manf_date: "",
    batch_exp_date: "",
    src_supplier_code: "",
    src_supplier_name: "",
    dest_node_code: "",
    mode_of_transport: "Normal - Road",
    rmpo_req_qty: 1000,
    rmpo_req_del_date: "23-09-2023",
    rmpo_planned_quantity_system: 1000,
    rmpo_planned_quantity_user: 1000,
    rmpo_planned_delivery_date: "23-09-2023",
    rmpo_value: "$10,000",
    planned_rmpo_creation_date: "18-09-2023",
    planned_rmpo_packing_date: "19-09-2023",
    planned_rmpo_loading_date: "20-09-2023",
    planned_rmpo_shipping_date: "21-09-2023",
    planned_rmpo_unloading_date: "22-09-2023",
    planned_rmpo_completion_date: "23-09-2023",
    lead_time_in_days: 6,
    net_sales_rate: 90,
    item_cost: "$10",
    safety_stock: 100,
    maximum_stock_level: 1100,
    approval_status: "Accepted",
    approved_by: "vishal sharma",
    approved_date: "18-09-2023",
    version: 1.1,
    short_supply: 0,
    reason_code: "-",
    user_remarks: "-",
    fixed_shipping_cost: "$100",
    variable_shipping_cost: "$290",
    total_shipping_cost: "$120",
  },
];
