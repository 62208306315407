import "./UploadModal.scss";
import BasicUploader from "../uploader/BasicUploader.js";
import { useRef, useState } from "react";
import ApprovalComment from "../comments/ApprovalComment";
import { Skeleton } from "@mui/material";
import CreatePlan from "../orders-mgmt/CreatePlan";
import MergePlan from "../orders-mgmt/MergePlan";
import AddOrder from "../orders-mgmt/AddOrder";

const SkeletonComment = () => {
  return (
    <div className="comments-container">
      <div className="comment-user">
        <Skeleton variant="circular" width={40} height={40} />
        <div className="comment-name-level">
          <Skeleton variant="text" width={100} />
          <Skeleton variant="text" width={100} />
        </div>
      </div>
      <div className="comment-text skeleton-comment-text">
        <Skeleton variant="rounded" width={"98%"} height={10} />
      </div>
    </div>
  );
};

const UploadModal = ({
  rmprId,
  openModal,
  setOpenModal,
  classType,
  modalType,
  activeSTO,
  getEditSTOApiBody,
  fieldsRequired,
  uploadType,
  setisUploadingSto,
  selectedGroupPlan = null,
  refetchSTO,
  setLoadingData,
  selectedProcess = null,
  setIsUploading = null,
  handleDelete = null,
  selectedJob = null,
  selectedConfig = null,
  sendModalType = null,
  approveRejectValue = null,
  approvalComments = null,
  loadingComments = null,
  handlePlanApproval = null,
  makePlanLive = null,
  moduleType = null,
  editedQuantity,
  resetInput,
  refetch = null,
  module = null,
  handleOrdersMgmtDownload = null,
  isDownloadingTemplate = null,
  setIsDownloadingTemplate,
  groupCodesList = null,
  selectedSTO,
  isLoadingGroups,
  groupOptions,
  selectedGroupOption,
  setSelectedGroupOption,
  setClearRowSelection,
}) => {
  const uploaderRef = useRef(null);
  const handleUpload = () => {
    uploaderRef.current.handleUploadClick();
  };

  const [reason, setReason] = useState("");
  const [comments, setComments] = useState("");
  const [reasonError, setReasonError] = useState(false);
  const [commentsError, setCommentsError] = useState(false);
  const [approvalRejectComments, setApprovalRejectComments] = useState("");

  const breachLevelCalculated =
    fieldsRequired && fieldsRequired.breachLevel
      ? fieldsRequired.breachLevel.split("")
      : null;

  const handleEditSTOSubmit = () => {
    setReasonError(false);
    setCommentsError(false);
    if (
      (reason === "" || reason === "Select Reason Code") &&
      fieldsRequired.reasonRequired
    ) {
      setReasonError(true);
    }
    if (comments === "" && fieldsRequired.commentsRequired) {
      setCommentsError(true);
    }
    if (
      !(
        (reason === "" || reason === "Select Reason Code") &&
        fieldsRequired.reasonRequired
      ) &&
      !(comments === "" && fieldsRequired.commentsRequired)
    ) {
      getEditSTOApiBody(activeSTO, editedQuantity, reason, comments);
      setReason("");
      setComments("");
      setOpenModal(false);
    }
  };

  const handleApprovalRejectComments = () => {
    handlePlanApproval(
      approvalRejectComments,
      module === "RMP" ? selectedGroupPlan.plan : selectedGroupPlan.planId,
      approveRejectValue
    );
    setApprovalRejectComments("");
    setOpenModal(false);
  };

  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };
  const [step, setStep] = useState(1);

  if (modalType === "send" && openModal) {
    const sendRevokeValue = approveRejectValue === "REVOKED";
    return (
      <div className="upload-modal-container">
        <div className="upload-modal upload-send-modal">
          <h1 className="send-modal-header">Confirmation</h1>
          <div className="send-modal-body">
            <p>{`Are you sure you want to ${
              sendRevokeValue ? "Revoke from" : "Send for"
            }  approval?`}</p>
            <textarea
              onChange={(e) => setApprovalRejectComments(e.target.value)}
              placeholder="Add Comments"
              className="approval-comments"
              rows="3"
              style={{ padding: "14px", boxSizing: "border-box" }}
            ></textarea>{" "}
            {/* {commentsError && (
              <span className="comments-error-msg">
                Comments cannot be empty
              </span>
            )} */}
          </div>
          <div className="send-modal-footer">
            <button
              className="send-cancel-button"
              onClick={() => {
                setOpenModal(false);
              }}
            >
              Cancel
            </button>
            <button
              className="send-yes-button"
              onClick={() => {
                let planId =
                  sendModalType !== null
                    ? selectedGroupPlan.appId
                    : selectedGroupPlan.planId;
                // setApprovalStatusValues((prev) => ({
                //   ...prev,
                //   [planId]: !prev[planId],
                // }));
                handleApprovalRejectComments();
              }}
              // disabled={}
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    );
  } else if (modalType === "approve-reject" && openModal) {
    return (
      <div className="upload-modal-container">
        <div className="upload-modal upload-send-modal">
          <h1 className="send-modal-header">Confirmation</h1>
          <div className="send-modal-body">
            <p>{`Are you sure you want to ${
              approveRejectValue === "APPROVED" ? "Approve" : "Reject"
            }  ?`}</p>
            <textarea
              onChange={(e) => setApprovalRejectComments(e.target.value)}
              placeholder="Add Comments"
              className="approval-comments"
              rows="3"
            ></textarea>
          </div>
          <div className="send-modal-footer approval-footer">
            <button
              className="send-cancel-button"
              onClick={() => {
                setOpenModal(false);
              }}
            >
              Cancel
            </button>
            <button
              className="send-yes-button"
              onClick={() => {
                handleApprovalRejectComments();
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    );
  } else if (modalType === "make-live" && openModal) {
    return (
      <div className="upload-modal-container">
        <div className="upload-modal upload-send-modal">
          <h1 className="send-modal-header">Confirmation</h1>
          <div className="send-modal-body">
            <p>Are you sure you want to make plan live?</p>
            <textarea
              onChange={(e) => setApprovalRejectComments(e.target.value)}
              placeholder="Add Comments"
              className="approval-comments"
              rows="3"
            ></textarea>
          </div>
          <div className="send-modal-footer approval-footer">
            <button
              className="send-cancel-button"
              onClick={() => {
                setOpenModal(false);
              }}
            >
              Cancel
            </button>
            <button
              className="send-yes-button"
              onClick={() => {
                setOpenModal(false);
                makePlanLive(
                  module === "RMP"
                    ? selectedGroupPlan.plan
                    : selectedGroupPlan.planId
                );
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    );
  } else if (modalType === "reasonForEditQuantity" && openModal) {
    return (
      <div className="upload-modal-container">
        <div className="upload-modal upload-send-modal edit-reason-modal">
          <div className="edit-reason-modal-header">
            <span>
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.0007 15.4689C10.4314 15.4689 10.0082 15.9304 10.0082 16.5001C10.0082 17.0693 10.4701 17.5312 11.0007 17.5312C11.57 17.5312 11.9931 17.0693 11.9931 16.5001C12.0318 15.9286 11.5721 15.4689 11.0007 15.4689ZM11.0007 13.7504C11.3806 13.7504 11.6494 13.443 11.6494 13.063V6.87627C11.6494 6.4963 11.342 6.18885 11.0007 6.18885C10.6593 6.18885 10.3133 6.49819 10.3133 6.87627V13.063C10.3133 13.4411 10.6226 13.7504 11.0007 13.7504ZM21.6685 17.0715L13.0844 2.55329C12.6505 1.81819 11.8685 1.37824 11.0007 1.37695C10.1328 1.37695 9.35519 1.81604 8.91696 2.55243L0.328585 17.0741C-0.103197 17.8031 -0.109642 18.6783 0.310463 19.4156C0.7449 20.1734 1.52941 20.6245 2.41231 20.6245H19.5934C20.4745 20.6245 21.2582 20.1721 21.6904 19.4143C22.111 18.6783 22.1024 17.8019 21.6685 17.0715ZM20.4569 18.6955C20.3108 19.0564 19.9714 19.2497 19.5547 19.2497H2.41231C2.02968 19.2497 1.69138 19.0571 1.50664 18.7342C1.33144 18.4267 1.33414 18.0768 1.51335 17.7735L10.1028 3.25273C10.2875 2.9391 10.6226 2.75178 11.0007 2.75178C11 2.75178 11.0007 2.75178 11.0007 2.75178C11.3773 2.75245 11.7122 2.93906 11.8969 3.25187L20.4853 17.7735C20.6288 18.0768 20.6717 18.4248 20.4569 18.6955Z"
                  fill="#B45309"
                />
              </svg>
            </span>
            <h1 style={{}}>Alert</h1>
          </div>
          <div className="edit-reason-modal-body">
            <p>
              This Edited Quantity is Breaching the tolerance level: “Level{" "}
              {`${
                breachLevelCalculated[1]
              } - ${fieldsRequired.percentageBreached.toFixed(2)}%"`}
              <br /> Please add a Reason Code/ Comment to go ahead with this
              change.
            </p>
            <p className="edit-reason-order-quantity">
              {`Order Quantity: ${activeSTO.planned_replenishment_qty}`}
            </p>
            <p className="edit-reason-edited-quantity">
              {`Edited Quantity: ${editedQuantity}`}
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "10px",
                width: "100%",
              }}
            >
              <label htmlFor="edit-reason" className="edit-reason-label">
                Reason code
                {fieldsRequired.reasonRequired && (
                  <span className="required-asterisk">*</span>
                )}
              </label>
              <select
                id="edit-reason"
                name="edit-reason"
                className="edit-select-container"
                onChange={handleReasonChange}
                value={reason}
              >
                <option value="" selected>
                  Select Reason Code
                </option>
                {/* <option value="reason1">
                  Urgent priority customer order for high quantity
                </option>
                <option value="reason2">
                  Prebuild stocking required for estimated order
                </option>
                <option value="reason3">EBO Outlet requirement</option> */}
                {fieldsRequired.reasonOptions.map((reason, index) => (
                  <option key={index} value={reason}>
                    {reason}
                  </option>
                ))}
              </select>
              {reasonError && (
                <span className="required-error-msg">Reason required</span>
              )}
            </div>

            <div
              style={{
                marginTop: "8px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {" "}
              <label htmlFor="comments-textarea" className="edit-reason-label">
                Comments{" "}
                {fieldsRequired.commentsRequired && (
                  <span className="required-asterisk">*</span>
                )}
              </label>
              <textarea
                id="comments-textarea"
                style={{ width: "100%", marginTop: "10px" }}
                className="edit-reason-textarea"
                placeholder="Add comment"
                rows={3}
                onChange={(e) => setComments(e.target.value)}
              ></textarea>
              {commentsError && (
                <span className="required-error-msg">Comments required</span>
              )}
            </div>
          </div>
          <div className="send-modal-footer">
            <button
              className="send-cancel-button"
              onClick={() => {
                resetInput();
                setReason("");
                setComments("");
                setOpenModal(false);
              }}
            >
              Cancel
            </button>
            <button className="send-yes-button" onClick={handleEditSTOSubmit}>
              Yes
            </button>
          </div>
        </div>
      </div>
    );
  } else if (modalType === "job-delete" && openModal) {
    return (
      <div className="upload-modal-container">
        <div className="upload-modal job-delete-modal">
          <h1 className="send-modal-header">Confirmation</h1>
          <div className="job-delete-body">
            <p>
              Are you sure you want to delete &quot;{selectedJob.name}&quot;
              Job?
            </p>
          </div>
          <div className="send-modal-footer ">
            <button
              className="upload-cancel-button"
              onClick={() => setOpenModal(false)}
            >
              Cancel
            </button>
            <button
              className="upload-yes-button"
              onClick={() => {
                handleDelete(selectedJob.id);
                setOpenModal(false);
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    );
  } else if (modalType === "config-delete" && openModal) {
    return (
      <div className="upload-modal-container">
        <div className="upload-modal job-delete-modal">
          <h1 className="send-modal-header">Confirmation</h1>
          <div className="job-delete-body">
            <p>
              Are you sure you want to delete &quot;{selectedConfig.name}&quot;
              Configuration?
            </p>
          </div>
          <div className="send-modal-footer ">
            <button
              className="upload-cancel-button"
              onClick={() => setOpenModal(false)}
            >
              Cancel
            </button>
            <button
              className="upload-yes-button"
              onClick={() => {
                handleDelete(selectedConfig.id);
                setOpenModal(false);
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    );
  } else if (modalType === "approval-comments" && openModal) {
    return (
      <div className="upload-modal-container">
        <div className="upload-modal upload-send-modal edit-reason-modal approval-comments-modal">
          <h1 className="send-modal-header">Comments</h1>
          {/* <button onClick={() => setOpenModal(false)}>X</button> */}
          {loadingComments ? (
            <div
              style={{
                padding: "0px 16px 12px 16px",
                width: "100%",
                maxHeight: "400px",
                overflowY: "scroll",
                marginBottom: "12px",
              }}
            >
              <SkeletonComment />
              <SkeletonComment />
              <SkeletonComment />
            </div>
          ) : (
            <div
              style={{
                padding: "0px 16px 12px 16px",
                width: "100%",
                maxHeight: "400px",
                overflowY: "scroll",
                marginBottom: "12px",
              }}
            >
              {approvalComments &&
                approvalComments.map((comment, index) => (
                  <ApprovalComment key={index} comment={comment} />
                ))}
            </div>
          )}
          <div
            style={{
              backgroundColor: "white",
              marginTop: "auto",
              padding: "2px 30px 18px 30px",
              borderBottomLeftRadius: "6px",
              borderBottomRightRadius: "6px",
              width: "100%",
              display: "flex",
            }}
          >
            <button
              style={{ marginLeft: "auto" }}
              className="approval-comments-close-btn"
              onClick={() => setOpenModal(false)}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    );
  } else if (modalType === "create-plan" && openModal) {
    return (
      <div className="upload-modal-container">
        <CreatePlan
          step={step}
          setStep={setStep}
          openModal={openModal}
          setOpenModal={setOpenModal}
          groupCodesList={groupCodesList}
          selectedSTO={selectedSTO}
          refetch={refetch}
          setClearRowSelection={setClearRowSelection}
        />
      </div>
    );
  } else if (modalType === "merge-plan" && openModal) {
    return (
      <div className="upload-modal-container">
        <MergePlan
          step={step}
          setStep={setStep}
          openModal={openModal}
          setOpenModal={setOpenModal}
          selectedSTO={selectedSTO}
          refetch={refetch}
          setClearRowSelection={setClearRowSelection}
        />
      </div>
    );
  } else if (modalType === "add-order" && openModal) {
    return (
      <div className="upload-modal-container">
        <AddOrder
          openModal={openModal}
          setOpenModal={setOpenModal}
          uploaderRef={uploaderRef}
          classType={classType}
          setisUploadingSto={setisUploadingSto}
          refetchSTO={refetchSTO}
          setLoadingData={setLoadingData}
          selectedProcess={selectedProcess}
          setIsUploading={setIsUploading}
          refetch={refetch}
          moduleType={moduleType}
          handleOrdersMgmtDownload={handleOrdersMgmtDownload}
          isDownloadingTemplate={isDownloadingTemplate}
          setIsDownloadingTemplate={setIsDownloadingTemplate}
          isLoadingGroups={isLoadingGroups}
          groupOptions={groupOptions}
          selectedGroupOption={selectedGroupOption}
          setSelectedGroupOption={setSelectedGroupOption}
        />
      </div>
    );
  }
  return (
    openModal && (
      <div className="upload-modal-container">
        <div className="upload-modal">
          <h1 className="upload-modal-header">Upload CSV File</h1>
          <BasicUploader
            ref={uploaderRef}
            preprocessValues={classType}
            uploadType={uploadType}
            setIsUploadingSto={setisUploadingSto}
            refetchSTO={refetchSTO}
            setLoadingData={setLoadingData}
            selectedProcess={selectedProcess}
            setIsUploading={setIsUploading}
            setOpenModal={setOpenModal}
            refetch={refetch}
            moduleType={moduleType}
            rmprId={rmprId}
          />
          <div className="upload-modal-footer">
            <button
              className="upload-cancel-button"
              onClick={() => setOpenModal(false)}
            >
              Cancel
            </button>
            <button
              className="upload-yes-button"
              onClick={() => {
                handleUpload();
                // setOpenModal(false);
              }}
            >
              Upload
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default UploadModal;
