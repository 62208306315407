import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ppGroupPlanViewDetails: {},
};

const ppGroupPlanViewSlice = createSlice({
  name: "ppGroupPlanViewSlice",
  initialState,
  reducers: {
    setPpGroupPlanViewDetails: (state, action) => {
      state.ppGroupPlanViewDetails = action.payload;
    },
  },
});

export const { setPpGroupPlanViewDetails } = ppGroupPlanViewSlice.actions;
export default ppGroupPlanViewSlice.reducer;
