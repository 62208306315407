import "./styles.css";
import { Breadcrumb } from "./common";

const pbiUrl = process.env.REACT_APP_PBI_ENBED_URL;

export const InventoryHealthClassification = () => {
  return (
    <div>
      <Breadcrumb isInvHealth={true} />
      <iframe
        className="iframe-pbi"
        title="Report Section"
        src={`${pbiUrl}/view?r=eyJrIjoiMmY1ZDYyNzItMjIzNi00NzJhLTg1OTktNzkzYjIzNzI1ZDFlIiwidCI6ImQxZDkzNmVjLThiZmYtNDU2MC1hOGE5LTYwMDEwZWMwMzRkZSJ9`}
        frameBorder="0"
        allowFullScreen="true"
      ></iframe>
    </div>
  );
};
