import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

// const apiIpPort = "https://scai-dev-iam.3sc.ai";
// const apiBaseUrl = "/api/v1";
const apiIpPort = process.env.REACT_APP_IAM_API_ENDPOINT;
const apiBaseUrl = process.env.REACT_APP_IAM_API_BASEURL;
const loginUser = async (email, password) => {
  const body = {
    email,
    password,
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post(
      `${apiIpPort}${apiBaseUrl}/users/login/`,
      body,
      config
    );
    localStorage.setItem("user", JSON.stringify(res.data.data));
    localStorage.setItem("token", JSON.stringify(res.data.data.access));
    localStorage.setItem("refresh", JSON.stringify(res.data.data.refresh));
    window.location.replace("/snp/inventory/management");
    return res;
  } catch (error) {
    console.error(error);
  }
};
export const UserSlice = createSlice({
  name: "user",
  initialState: {
    isAuthenticated: false,
    user: null,
  },
  reducers: {
    login: async (state, data) => {
      console.log({ redux: data });
      const response = await loginUser(
        data.payload.email,
        data.payload.password
      );
      state.isAuthenticated = true;
      console.log("LOGIN", state);
    },
    logout: (state) => {
      state.user = null;
      state.isAuthenticated = false;
    },
  },
});

export const { login, logout } = UserSlice.actions;
export const selectUser = (state) => state.user.isAuthenticated;
export default UserSlice.reducer;
