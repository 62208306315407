import { useState } from "react";
import Autosuggest from "react-autosuggest";
import AutoSuggestStyles from "./AutoSuggest.scss";

const AutoSuggest = (props) => {
  const { suggestData, searchToFocus } = props;
  let listSuggestions = suggestData
    ? suggestData.map((node) => node.nodeName)
    : [];
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : listSuggestions.filter((item) =>
          item.toLowerCase().includes(inputValue)
        );
  };

  const onChange = (event, { newValue }) => {
    setValue(newValue);
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    let p = getSuggestions(value);
    setSuggestions(p);
  };

  const getSuggestionValue = (suggestion) => suggestion;

  const renderSuggestion = (suggestion) => <div>{suggestion}</div>;

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onKeyPress = (event) => {
    if (event.key === "Enter") {
      searchToFocus(value);
      setValue("");
    }
  };

  const inputProps = {
    placeholder: "Search..",
    value,
    onChange,
    onKeyPress: onKeyPress,
    spellCheck: false,
  };

  const onSuggestionSelected = (e, { suggestion }) => {
    searchToFocus(suggestion);
  };

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
      AutoSuggestStyles={AutoSuggestStyles}
      onSuggestionSelected={onSuggestionSelected}
    />
  );
};

export default AutoSuggest;
