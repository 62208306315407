import { useEffect, useState } from "react";
import { useIsMount } from "./utils";
function PercentageSplit({
  distributionChangeHandler,
  values,
  dynamicComponentIndex,
  setSelectedRules,
}) {
  // useEffect(() => {
  //   setSelectedRules((rules) => {
  //     let tempRules = [...rules];
  //     tempRules[dynamicComponentIndex] = [25, 25, 25, 25];
  //   });
  // }, []);
  console.log({ values });
  const [localVals, setLocaVals] = useState(values);
  useEffect(() => {
    distributionChangeHandler(1, dynamicComponentIndex, localVals);
  }, [localVals]);
  return (
    <div>
      <form>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "70%",
          }}
        >
          {values.map((value, index) => (
            <div
              key={index}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <label>Week {index + 1}</label>
              <input
                type="number"
                min="0"
                max="100"
                value={value}
                onChange={(e) =>
                  // distributionChangeHandler(
                  //   index,
                  //   dynamicComponentIndex,
                  //   values
                  // )
                  setLocaVals((vals) => {
                    console.log({ vals });
                    let tempVals = [...vals];
                    tempVals[index] = Number(e.target.value);
                    return tempVals;
                  })
                }
                style={{
                  height: 40,
                  width: 150,
                }}
              />
            </div>
          ))}
        </div>
      </form>
    </div>
  );
}

export default PercentageSplit;
