import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useRef, useState } from "react";

export default function ReplenishmentRqmt({ componentWidth, snpWidth }) {
  const [options, setOptions] = useState({});
  const chartRef = useRef(null);

  useEffect(() => {
    const chartOptions = {
      chart: {
        type: "column",
        height: 300,
      },
      title: {
        text: "",
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        labels: {
          style: {
            fontSize: "10px",
            fontFamily: "Inter",
          },
        },
        plotLines: [
          {
            color: "#FFDFC1", // Color value
            dashStyle: "Solid", // Style of the plot line. Default to solid
            value: 8, // Value of where the line will appear
            width: 2, // Width of the line
          },
        ],
        plotBands: [
          {
            color: "#f9f9f9", // Color value
            from: 0, // Start of the plot band
            to: 8, // End of the plot band
          },
        ],
      },
      yAxis: [
        {
          lineWidth: 1,
          lineColor: "#c2c2c2",
          title: {
            text: "Value (₹ Mn)",
          },
          labels: {
            x: -8,
          },
        },
        {
          title: {
            text: "",
          },
        },
      ],
      legend: {
        shadow: false,
        itemStyle: {
          fontFamily: "Inter",
        },
        padding: 0.5,
      },
      tooltip: {
        shared: true,
      },
      plotOptions: {
        column: {
          grouping: false,
          shadow: false,
          borderWidth: 0,
        },
      },
      series: [
        {
          name: "Production Requirement",
          color: "#7FD39D",
          data: [183.6, 178.8, 198.5, 150, 90, 110, 170, 60, 40, 70, 140, 200],
          tooltip: {
            valuePrefix: "₹",
            valueSuffix: " M",
          },
          pointPadding: 0.2,
          //   pointPlacement: 0.2,
          //   yAxis: 1,
        },
        {
          name: "Production Planned",
          color: "#283F91",
          data: [203.6, 198.8, 208.5, 150, 190, 220, 80, 100, 130, 60, 90, 150],
          tooltip: {
            valuePrefix: "₹",
            valueSuffix: " M",
          },
          pointPadding: 0.4,
          //   pointPlacement: 0.2,
          //   yAxis: 1,
        },
      ],
    };
    setOptions(chartOptions);
  }, []);

  useEffect(() => {
    const chart = chartRef.current.chart;
    // Function to handle resizing
    const handleResize = () => {
      // Calculate the new width based on the container's width
      // const newWidth = chartRef.current.container.offsetWidth;
      chart.setSize(componentWidth);
    };
    handleResize();
  }, [snpWidth]);

  return (
    <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
  );
}
