import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Import the appropriate storage type
import userSlice from "../features/UserSlice";
import { apiSlice } from "../features/api/apiSlice";
import { loginSlice } from "../features/api/loginSlice";
import { schedulerSlice } from "../features/api/schedularSlice";
import jobSchedulerSlice from "../features/JobSchedulerSlice";
import configurationsSlice from "../features/ConfigurationsSlice";
import { preprocessesSlice } from "../features/api/preprocessesSlice";
import { inventorySlice } from "../features/api/inventorySlice";
import { replPlanSlice } from "../features/api/replPlanSlice";
import { ordersManagementSlice } from "@/features/api/ordersManagementSlice";
import { prodPlanSlice } from "../features/api/prodPlanSlice";
import filterSlice from "../features/FiltersSlice";
import { createStoSlice } from "../features/api/createStoSlice";
import InventoryProfileSlice from "../features/InventoryProfileSlice";
import groupPlanViewSlice from "../features/GroupPlanViewSlice";
import ppGroupPlanViewSlice from "../features/ppGroupPlanViewSlice";
import replenishmentPlanSlice from "../features/replenishmentPlanSlice";
import productionPlanSlice from "@/features/productionPlanSlice";
import { rawMaterialSlice } from "@/features/api/rawMaterialSlice";
import rawMaterialPlanSlice from "@/features/rawMaterialPlanSlice";
import rmpGroupPlanViewSlice from "@/features/rmpGroupPlanViewSlice";

const rootReducer = combineReducers({
  user: userSlice,
  jobScheduler: jobSchedulerSlice,
  configuration: configurationsSlice,
  filters: filterSlice,
  inventoryProfile: InventoryProfileSlice,
  groupLevelPlan: groupPlanViewSlice,
  ppGroupPlan: ppGroupPlanViewSlice,
  rmpGroupPlan: rmpGroupPlanViewSlice,
  replenishmentPlan: replenishmentPlanSlice,
  productionPlan: productionPlanSlice,
  rawMaterialPlan: rawMaterialPlanSlice,
  [apiSlice.reducerPath]: apiSlice.reducer,

  [loginSlice.reducerPath]: loginSlice.reducer,

  [schedulerSlice.reducerPath]: schedulerSlice.reducer,
  [preprocessesSlice.reducerPath]: preprocessesSlice.reducer,
  [inventorySlice.reducerPath]: inventorySlice.reducer,
  [replPlanSlice.reducerPath]: replPlanSlice.reducer,
  [createStoSlice.reducerPath]: createStoSlice.reducer,
  [prodPlanSlice.reducerPath]: prodPlanSlice.reducer,
  [rawMaterialSlice.reducerPath]: rawMaterialSlice.reducer,
  [ordersManagementSlice.reducerPath]: ordersManagementSlice.reducer,
});

const persistConfig = {
  key: "root", // Key to identify the storage
  storage, // Storage type (localStorage, sessionStorage, AsyncStorage)
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
      .concat(apiSlice.middleware)
      .concat(loginSlice.middleware)
      .concat(schedulerSlice.middleware)
      .concat(preprocessesSlice.middleware)
      .concat(inventorySlice.middleware)
      .concat(replPlanSlice.middleware)
      .concat(createStoSlice.middleware)
      .concat(prodPlanSlice.middleware)
      .concat(rawMaterialSlice.middleware)
      .concat(ordersManagementSlice.middleware),
});

const persistor = persistStore(store);

export { store, persistor };
