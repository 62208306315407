import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

export default function KpiCardsReqm() {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.fontWeightLight,
    marginBottom: "10px",
    boxShadow: "none",
    color: theme.palette.text.secondary,
    fontSize: "12px",
    fontWeight: "400",
    background: "transparent"
  }));

  const StyledCard1 = styled(Grid)(({ theme }) => ({ 
    ...theme.typography.body1,
    border: "1px solid #FFAB8A",
    boxShadow: "0px 4px 4px 0px rgba(174, 174, 174, 0.25)",
    borderRadius: "6px",
    padding: theme.spacing(1),
    background: " #F6F6F6",
  }));

  const BoldDiv = styled("div")(({ theme }) => ({
    ...theme.typography.customBoldFont,
  }));

  const LightDiv = styled("div")(({ theme }) => ({
    ...theme.typography.customLightFont,
  }));

  return (
    <Grid container sx={{ columnGap: "15px", marginBottom: "10px" }}>
      <StyledCard1 item xs>
        <Item>Total Demand</Item>
        <Box display={"flex"} sx={{ justifyContent: "space-between" }}>
          <div>
            <BoldDiv>28479</BoldDiv>
            <LightDiv>Volume</LightDiv>
          </div>
          <div>
            <BoldDiv>₹2330352</BoldDiv>
            <LightDiv>Value</LightDiv>
          </div>
        </Box>
      </StyledCard1>
      <StyledCard1 item xs>
        <Item>Total Production Requirement</Item>
        <Box display={"flex"} sx={{ justifyContent: "space-between" }}>
          <div>
            <BoldDiv>25000</BoldDiv>
            <LightDiv>Volume</LightDiv>
          </div>
          <div>
            <BoldDiv>₹1030672</BoldDiv>
            <LightDiv>Value</LightDiv>
          </div>
        </Box>
      </StyledCard1>
      <StyledCard1 item xs>
        <Item>Available to promise</Item>
        <Box display={"flex"} sx={{ justifyContent: "space-between" }}>
          <div>
            <BoldDiv>42234</BoldDiv>
            <LightDiv>Volume</LightDiv>
          </div>
          <div>
            <BoldDiv>₹3523422</BoldDiv>
            <LightDiv>Value</LightDiv>
          </div>
        </Box>
      </StyledCard1>
      <StyledCard1 item xs>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Item>Sales Orders </Item>
          <Item>Count: 535 </Item>
        </div>

        <Box display={"flex"} sx={{ justifyContent: "space-between" }}>
          <div>
            <BoldDiv>23423</BoldDiv>
            <LightDiv>Volume</LightDiv>
          </div>
          <div>
            <BoldDiv>₹14234223</BoldDiv>
            <LightDiv>Value</LightDiv>
          </div>
        </Box>
      </StyledCard1>
      <StyledCard1 item xs>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Item>Production Backlog</Item>
          <Item>Count: 65</Item>
        </div>

        <Box display={"flex"} sx={{ justifyContent: "space-between" }}>
          <div>
            <BoldDiv>12312</BoldDiv>
            <LightDiv>Volume</LightDiv>
          </div>
          <div>
            <BoldDiv>₹2342333</BoldDiv>
            <LightDiv>Value</LightDiv>
          </div>
        </Box>
      </StyledCard1>
    </Grid>
  );
}
