import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useRef, useState } from "react";

export default function RevenueOutlook({ componentWidth, snpWidth }) {
  const [options, setOptions] = useState({});
  const chartRef = useRef(null);

  Highcharts.setOptions({
    lang: {
      decimalPoint: ".",
      thousandsSep: ",",
    },
  });

  useEffect(() => {
    const chartOptions = {
      chart: {
        type: "bar",
        height: 260,
      },
      title: {
        text: "",
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: [
          "Sup-RM01",
          "Sup-RM02",
          "Sup-RM03",
          "Sup-RM04",
          "Sup-RM05",
          "Sup-RM06"
        ],
        labels: {
          style: {
            fontSize: "10px",
            fontFamily: "Inter",
          },
        },
        // plotLines: [
        //   {
        //     color: "#FFDFC1", // Color value
        //     dashStyle: "Solid", // Style of the plot line. Default to solid
        //     value: 8, // Value of where the line will appear
        //     width: 2, // Width of the line
        //   },
        // ],
        // plotBands: [
        //   {
        //     color: "#f9f9f9", // Color value
        //     from: 0, // Start of the plot band
        //     to: 8, // End of the plot band
        //   },
        // ],
        plotLines: [],
      },
      yAxis: [
        {
          lineWidth: 1,
          lineColor: "#c2c2c2",
          gridLineWidth: 0,
          title: {
            text: "",
          },
          labels: {
            x: -8,
          },
        },
        {
          title: {
            text: "",
          },
        },
      ],
      legend: {
        shadow: false,
        itemStyle: {
          fontFamily: "Inter",
        },
        padding: 0.5,
      },
      tooltip: {
        shared: true,
      },
      plotOptions: {
        bar: {
          borderRadius: "2px",
        },
      },
      series: [
        {
          name: "Quantity",
          color: "#FF8251",
          data: [39000, 35000, 20000, 17000, 15000, 12000],
          tooltip: {
            valuePrefix: "₹",
            valueSuffix: "",
          },
          // pointPadding: 0.2,
          //   pointPlacement: 0.2,
          //   yAxis: 1,
        },
        {
          name: "Cost",
          color: "#494949",
          data: [34000, 31000, 18000, 13000, 11000, 9000],
          tooltip: {
            valuePrefix: "₹",
            valueSuffix: "",
          },
          // pointPadding: 0.4,
          //   pointPlacement: 0.2,
          //   yAxis: 1,
        },
      ],
    };

    // Add plotLines for each category in xAxis
    chartOptions.xAxis.categories.forEach((category, index) => {
      chartOptions.xAxis.plotLines.push({
        color: "#CBCBCB", // Color value
        dashStyle: "Dash", // Style of the plot line (dash)
        value: index + 0.21, // Position the line between bars
        width: 1, // Width of the line
      });
    });
    setOptions(chartOptions);
  }, []);

  useEffect(() => {
    const chart = chartRef.current.chart;
    // Function to handle resizing
    const handleResize = () => {
      // Calculate the new width based on the container's width
      // const newWidth = chartRef.current.container.offsetWidth;
      chart.setSize(componentWidth);
    };
    handleResize();
  }, [snpWidth]);

  return (
    <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
  );
}
