import { useEffect, useRef } from "react";
import { toast } from "react-toastify";
export function transformObjectKeys(obj) {
  const { configs, modules } = obj.data;
  console.log({ configs, modules });
  const transformedConfigs = configs.reduce((acc, item) => {
    const newItem = {
      ...item,
      value: item["id"],
      label: item["name"],
      module: {
        ...item["module"],
        value: item["module"].id,
        label: item["module"].name,
      },
    };
    acc.push(newItem);
    return acc;
  }, []);
  const transformedModules = modules.reduce((acc, item) => {
    const newItem = { ...item, value: item.id, label: item.name };
    acc.push(newItem);
    return acc;
  }, []);
  return { configs: transformedConfigs, modules: transformedModules };
}

export const useIsMount = () => {
  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
};

export const formatWord = (word) => {
  console.log({ word });
  let arr = word.split("_");
  return arr.map((item) => item.toUppercase()).join(" ");
};

export const handleRunAdhocJob = async ({
  runAdhocJob,
  urlState,
  setShowLoader,
  navigate,
  jobCategory,
}) => {
  let requestBody = {
    is_adhoc: true,
    group_schedule: urlState.group_schedule,
    group_rule: urlState.group_rule,
    group_config: jobCategory.value ?? urlState.group_config,
  };

  try {
    setShowLoader(true);
    const response = await runAdhocJob(requestBody).unwrap();
    if (response.err) {
      toast.error(`${response?.err?.data}`);
    } else {
      toast.success("Adhoc job triggered successfully");
      navigate("/snp/inventory/management/job-scheduler");
    }
    setShowLoader(false);
  } catch (err) {
    toast.error("Adhoc job trigger failed !");
    setShowLoader(false);
    navigate("/snp/inventory/management/job-scheduler");
  }
};
