const KPICard = ({
  value,
  volume,
  heading,
  metric = "Volume",
  metricValue = "Value",
  topRowRightData = "",
}) => {
  return (
    <div
      style={{
        background: "#F6F6F6",
        borderRadius: "6px",
        border: "1px solid #FFAB8A",
        boxShadow: "0px 2px 6px -2px #AEAEAE",
        padding: "8px 10px",
        width: "40%",
        height: 82,
      }}
    >
      <div
        style={{ display: "flex", justifyContent: "space-between", height: 13 }}
      >
        <h1
          style={{
            fontWeight: "500",
            fontSize: "12px",
            color: "#525B7A",
            fontFamily: "Nunito Sans",
            display: "inline-block",
          }}
        >
          {heading}
        </h1>

        <h1
          style={{
            fontWeight: "500",
            fontSize: "12px",
            color: "#525B7A",
            fontFamily: "Nunito Sans",
            display: "inline-block",
          }}
        >
          {topRowRightData}
        </h1>
      </div>

      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          marginTop: 11,
        }}
      >
        <div>
          <p
            style={{
              fontWeight: "600",
              fontSize: "13px",
              color: "#3D445C",
              marginBottom: "unset",
              margintop: "unset",
            }}
          >
            {volume}
          </p>
          <p
            style={{
              fontWeight: "400",
              fontSize: "11px",
              color: "#525B7A",
              marginBottom: "unset",
              margintop: "unset",
            }}
          >
            {metric}
          </p>
        </div>
        <div>
          <p
            style={{
              fontWeight: "600",
              fontSize: "13px",
              color: "#3D445C",
              marginBottom: "unset",
            }}
          >
            {value}
          </p>
          <p
            style={{
              fontWeight: "400",
              fontSize: "11px",
              color: "#525B7A",
              textAlign: "end",
              marginBottom: "unset",
            }}
          >
            {metricValue}
          </p>
        </div>
      </div>
    </div>
  );
};

export default KPICard;
