export const stockLevelData = [
  {
    sku_id: "FG-MRK-1001",
    sku_description: "Black Marker",
    node_id: "Node-1",
    node_name: "WH-Calcutta",
    channel_id: "Channel 1",
    classification_abc: "A",
    classification_xyz: "X",
    stock_in_hand: 1000,
    stock_in_transit: 497,
    stock_in_pbns: 0,
    health: "In-stock",
    open_sales_orders: 128,
    safety_stock: 150,
    msl: 5779,
    dos: 30,
    rop: 120,
    sku_hierarchy_1_category: "-",
    sku_hierarchy_2_division: "-",
    sku_hierarchy_3_brand: "-",
    node_hierarchy_1: "-",
    node_hierarchy_2: "-",
    node_hierarchy_3: "-",
  },
  {
    sku_id: "FG-MRK-1616",
    sku_description: "Pencil - 2HB",
    node_id: "Node-2",
    node_name: "WH-Calcutta",
    channel_id: "Channel 2",
    classification_abc: "B",
    classification_xyz: "Y",
    stock_in_hand: 100,
    stock_in_transit: 35,
    stock_in_pbns: 0,
    health: "Low",
    open_sales_orders: 112,
    safety_stock: 116,
    msl: 5591,
    dos: 30,
    rop: 106,
    sku_hierarchy_1_category: "-",
    sku_hierarchy_2_division: "-",
    sku_hierarchy_3_brand: "-",
    node_hierarchy_1: "-",
    node_hierarchy_2: "-",
    node_hierarchy_3: "-",
  },
  {
    sku_id: "FG-MRK-2010",
    sku_description: "Blue Marker",
    node_id: "Node-3",
    node_name: "WH-Calcutta",
    channel_id: "Channel 1",
    classification_abc: "C",
    classification_xyz: "Z",
    stock_in_hand: 100,
    stock_in_transit: 68,
    stock_in_pbns: 0,
    health: "Over-stock",
    open_sales_orders: 134,
    safety_stock: 150,
    msl: 5171,
    dos: 30,
    rop: 110,
    sku_hierarchy_1_category: "-",
    sku_hierarchy_2_division: "-",
    sku_hierarchy_3_brand: "-",
    node_hierarchy_1: "-",
    node_hierarchy_2: "-",
    node_hierarchy_3: "-",
  },
  {
    sku_id: "FG-MRK-4097",
    sku_description: "Green Marker",
    node_id: "Node-4",
    node_name: "WH-Calcutta",
    channel_id: "Channel 2",
    classification_abc: "B",
    classification_xyz: "X",
    stock_in_hand: 1000,
    stock_in_transit: 63,
    stock_in_pbns: 0,
    health: "In-stock",
    open_sales_orders: 124,
    safety_stock: 123,
    msl: 5997,
    dos: 30,
    rop: 113,
    sku_hierarchy_1_category: "-",
    sku_hierarchy_2_division: "-",
    sku_hierarchy_3_brand: "-",
    node_hierarchy_1: "-",
    node_hierarchy_2: "-",
    node_hierarchy_3: "-",
  },
  {
    sku_id: "FG-MRK-1496",
    sku_description: "Ball pt. pen - Black",
    node_id: "Node-5",
    node_name: "WH-Calcutta",
    channel_id: "Channel 1",
    classification_abc: "B",
    classification_xyz: "X",
    stock_in_hand: 1000,
    stock_in_transit: 81,
    stock_in_pbns: 0,
    health: "Low",
    open_sales_orders: 165,
    safety_stock: 146,
    msl: 5478,
    dos: 30,
    rop: 103,
    sku_hierarchy_1_category: "-",
    sku_hierarchy_2_division: "-",
    sku_hierarchy_3_brand: "-",
    node_hierarchy_1: "-",
    node_hierarchy_2: "-",
    node_hierarchy_3: "-",
  },
  {
    sku_id: "FG-MRK-3210",
    sku_description: "Ball pt. pen - Blue",
    node_id: "Node-6",
    node_name: "WH-Calcutta",
    channel_id: "Channel 2",
    classification_abc: "C",
    classification_xyz: "X",
    stock_in_hand: 100,
    stock_in_transit: 30,
    stock_in_pbns: 0,
    health: "Over-stock",
    open_sales_orders: 134,
    safety_stock: 135,
    msl: 5287,
    dos: 30,
    rop: 144,
    sku_hierarchy_1_category: "-",
    sku_hierarchy_2_division: "-",
    sku_hierarchy_3_brand: "-",
    node_hierarchy_1: "-",
    node_hierarchy_2: "-",
    node_hierarchy_3: "-",
  },
  {
    sku_id: "FG-MRK-3694",
    sku_description: "Pencil - 2HB",
    node_id: "Node-7",
    node_name: "WH-Calcutta",
    channel_id: "Channel 1",
    classification_abc: "C",
    classification_xyz: "Z",
    stock_in_hand: 50,
    stock_in_transit: 39,
    stock_in_pbns: 0,
    health: "In-stock",
    open_sales_orders: 190,
    safety_stock: 120,
    msl: 5424,
    dos: 30,
    rop: 146,
    sku_hierarchy_1_category: "-",
    sku_hierarchy_2_division: "-",
    sku_hierarchy_3_brand: "-",
    node_hierarchy_1: "-",
    node_hierarchy_2: "-",
    node_hierarchy_3: "-",
  },
  {
    sku_id: "FG-MRK-4332",
    sku_description: "Pencil - 1HB",
    node_id: "Node-8",
    node_name: "WH-Calcutta",
    channel_id: "Channel 2",
    classification_abc: "C",
    classification_xyz: "Z",
    stock_in_hand: 1000,
    stock_in_transit: 968,
    stock_in_pbns: 0,
    health: "Low",
    open_sales_orders: 198,
    safety_stock: 120,
    msl: 5978,
    dos: 30,
    rop: 113,
    sku_hierarchy_1_category: "-",
    sku_hierarchy_2_division: "-",
    sku_hierarchy_3_brand: "-",
    node_hierarchy_1: "-",
    node_hierarchy_2: "-",
    node_hierarchy_3: "-",
  },
  {
    sku_id: "FG-MRK-2114",
    sku_description: "Ball pt. pen - Red",
    node_id: "Node-9",
    node_name: "WH-Calcutta",
    channel_id: "Channel 1",
    classification_abc: "A",
    classification_xyz: "Z",
    stock_in_hand: 500,
    stock_in_transit: 438,
    stock_in_pbns: 0,
    health: "Over-stock",
    open_sales_orders: 124,
    safety_stock: 124,
    msl: 5137,
    dos: 30,
    rop: 116,
    sku_hierarchy_1_category: "-",
    sku_hierarchy_2_division: "-",
    sku_hierarchy_3_brand: "-",
    node_hierarchy_1: "-",
    node_hierarchy_2: "-",
    node_hierarchy_3: "-",
  },
  {
    sku_id: "FG-MRK-1565",
    sku_description: "Red Marker",
    node_id: "Node-10",
    node_name: "WH-Calcutta",
    channel_id: "Channel 2",
    classification_abc: "A",
    classification_xyz: "Y",
    stock_in_hand: 100,
    stock_in_transit: 57,
    stock_in_pbns: 0,
    health: "In-stock",
    open_sales_orders: 104,
    safety_stock: 120,
    msl: 5465,
    dos: 30,
    rop: 109,
    sku_hierarchy_1_category: "-",
    sku_hierarchy_2_division: "-",
    sku_hierarchy_3_brand: "-",
    node_hierarchy_1: "-",
    node_hierarchy_2: "-",
    node_hierarchy_3: "-",
  },
  {
    sku_id: "FG-MRK-2012",
    sku_description: "Black Marker",
    node_id: "Node-11",
    node_name: "WH-Calcutta",
    channel_id: "Channel 1",
    classification_abc: "A",
    classification_xyz: "Y",
    stock_in_hand: 1000,
    stock_in_transit: 609,
    stock_in_pbns: 0,
    health: "Out-of-stock",
    open_sales_orders: 192,
    safety_stock: 105,
    msl: 5844,
    dos: 30,
    rop: 141,
    sku_hierarchy_1_category: "-",
    sku_hierarchy_2_division: "-",
    sku_hierarchy_3_brand: "-",
    node_hierarchy_1: "-",
    node_hierarchy_2: "-",
    node_hierarchy_3: "-",
  },
  {
    sku_id: "FG-MRK-1616",
    sku_description: "Pencil - 2HB",
    node_id: "Node-12",
    node_name: "WH-Calcutta",
    channel_id: "Channel 2",
    classification_abc: "A",
    classification_xyz: "Y",
    stock_in_hand: 500,
    stock_in_transit: 188,
    stock_in_pbns: 0,
    health: "Over-stock",
    open_sales_orders: 131,
    safety_stock: 127,
    msl: 5220,
    dos: 30,
    rop: 110,
    sku_hierarchy_1_category: "-",
    sku_hierarchy_2_division: "-",
    sku_hierarchy_3_brand: "-",
    node_hierarchy_1: "-",
    node_hierarchy_2: "-",
    node_hierarchy_3: "-",
  },
  {
    sku_id: "FG-MRK-2010",
    sku_description: "Blue Marker",
    node_id: "Node-13",
    node_name: "WH-Calcutta",
    channel_id: "Channel 1",
    classification_abc: "A",
    classification_xyz: "X",
    stock_in_hand: 538,
    stock_in_transit: 234,
    stock_in_pbns: 0,
    health: "Over-stock",
    open_sales_orders: 192,
    safety_stock: 113,
    msl: 5662,
    dos: 30,
    rop: 112,
    sku_hierarchy_1_category: "-",
    sku_hierarchy_2_division: "-",
    sku_hierarchy_3_brand: "-",
    node_hierarchy_1: "-",
    node_hierarchy_2: "-",
    node_hierarchy_3: "-",
  },
  {
    sku_id: "FG-MRK-4097",
    sku_description: "Green Marker",
    node_id: "Node-14",
    node_name: "WH-Calcutta",
    channel_id: "Channel 2",
    classification_abc: "A",
    classification_xyz: "X",
    stock_in_hand: 538,
    stock_in_transit: 419,
    stock_in_pbns: 0,
    health: "Over-stock",
    open_sales_orders: 130,
    safety_stock: 107,
    msl: 5159,
    dos: 30,
    rop: 150,
    sku_hierarchy_1_category: "-",
    sku_hierarchy_2_division: "-",
    sku_hierarchy_3_brand: "-",
    node_hierarchy_1: "-",
    node_hierarchy_2: "-",
    node_hierarchy_3: "-",
  },
  {
    sku_id: "FG-MRK-1496",
    sku_description: "Ball pt. pen - Black",
    node_id: "Node-15",
    node_name: "WH-Calcutta",
    channel_id: "Channel 1",
    classification_abc: "A",
    classification_xyz: "X",
    stock_in_hand: 538,
    stock_in_transit: 441,
    stock_in_pbns: 0,
    health: "Over-stock",
    open_sales_orders: 132,
    safety_stock: 127,
    msl: 5844,
    dos: 30,
    rop: 147,
    sku_hierarchy_1_category: "-",
    sku_hierarchy_2_division: "-",
    sku_hierarchy_3_brand: "-",
    node_hierarchy_1: "-",
    node_hierarchy_2: "-",
    node_hierarchy_3: "-",
  },
];

export const salesOrderDetailsData = [
  {
    sales_order: "SO-001117",
    invoice_id: "IN-2575",
    order_date: "04-11-2022",
    sku_code: "SKU-1",
    sku_description: "Max Fresh",
    channel_id: "Channel 1",
    node_id: "Node-1",
    node_name: "WH-Calcutta",
    customer_id: "CDC-1",
    customer_name: "CDC-Mumbai",
    total_order_quantity: 2497,
    delivered_order_quantity: 1000,
    in_transit_order_quantity: 1000,
    pending_order_quantity: 497,
    net_sales_value: "₹10,000",
    lead_time_days: 13,
    req_delivery_date: "04-11-2022",
    plan_delivery_date: "04-11-2022",
    so_type: "NB",
    invoice_amt: 8260,
    order_status: "Planned",
    promo_id: "Promo 1",
    sku_hierarchy_1_category: "-",
    sku_hierarchy_2_division: "-",
    sku_hierarchy_3_brand: "-",
    node_hierarchy_1: "-",
    node_hierarchy_2: "-",
    node_hierarchy_3: "-",
  },
  {
    sales_order: "SO-001611",
    invoice_id: "IN-3080",
    order_date: "07-11-2022",
    sku_code: "SKU-2",
    sku_description: "Max Fresh",
    channel_id: "Channel 2",
    node_id: "Node-2",
    node_name: "WH-Calcutta",
    customer_id: "CDC-2",
    customer_name: "CDC-Surat",
    total_order_quantity: 635,
    delivered_order_quantity: 500,
    in_transit_order_quantity: 100,
    pending_order_quantity: 35,
    net_sales_value: "₹10,000",
    lead_time_days: 6,
    req_delivery_date: "07-11-2022",
    plan_delivery_date: "07-11-2022",
    so_type: "Transfer",
    invoice_amt: 8586,
    order_status: "Backorder",
    promo_id: "Promo 2",
    sku_hierarchy_1_category: "-",
    sku_hierarchy_2_division: "-",
    sku_hierarchy_3_brand: "-",
    node_hierarchy_1: "-",
    node_hierarchy_2: "-",
    node_hierarchy_3: "-",
  },
  {
    sales_order: "SO-001084",
    invoice_id: "IN-2164",
    order_date: "08-11-2022",
    sku_code: "SKU-3",
    sku_description: "Max Fresh",
    channel_id: "Channel 1",
    node_id: "Node-3",
    node_name: "WH-Calcutta",
    customer_id: "CDC-3",
    customer_name: "CDC-Pune",
    total_order_quantity: 468,
    delivered_order_quantity: 300,
    in_transit_order_quantity: 100,
    pending_order_quantity: 68,
    net_sales_value: "₹10,000",
    lead_time_days: 4,
    req_delivery_date: "08-11-2022",
    plan_delivery_date: "08-11-2022",
    so_type: "Prod WO",
    invoice_amt: 9668,
    order_status: "Planned",
    promo_id: "Promo 3",
    sku_hierarchy_1_category: "-",
    sku_hierarchy_2_division: "-",
    sku_hierarchy_3_brand: "-",
    node_hierarchy_1: "-",
    node_hierarchy_2: "-",
    node_hierarchy_3: "-",
  },
  {
    sales_order: "SO-002137",
    invoice_id: "IN-2823",
    order_date: "09-11-2022",
    sku_code: "SKU-4",
    sku_description: "Max Fresh",
    channel_id: "Channel 2",
    node_id: "Node-4",
    node_name: "WH-Calcutta",
    customer_id: "CDC-4",
    customer_name: "CDC-Nagpur",
    total_order_quantity: 2063,
    delivered_order_quantity: 1000,
    in_transit_order_quantity: 1000,
    pending_order_quantity: 63,
    net_sales_value: "₹10,000",
    lead_time_days: 12,
    req_delivery_date: "09-11-2022",
    plan_delivery_date: "09-11-2022",
    so_type: "Transfer",
    invoice_amt: 5297,
    order_status: "Planned",
    promo_id: "Promo 4",
    sku_hierarchy_1_category: "-",
    sku_hierarchy_2_division: "-",
    sku_hierarchy_3_brand: "-",
    node_hierarchy_1: "-",
    node_hierarchy_2: "-",
    node_hierarchy_3: "-",
  },
  {
    sales_order: "SO-002142",
    invoice_id: "IN-3039",
    order_date: "08-11-2022",
    sku_code: "SKU-5",
    sku_description: "Max Fresh",
    channel_id: "Channel 1",
    node_id: "Node-5",
    node_name: "WH-Calcutta",
    customer_id: "CDC-5",
    customer_name: "CDC-Benglore",
    total_order_quantity: 2081,
    delivered_order_quantity: 1000,
    in_transit_order_quantity: 1000,
    pending_order_quantity: 81,
    net_sales_value: "₹10,000",
    lead_time_days: 7,
    req_delivery_date: "08-11-2022",
    plan_delivery_date: "08-11-2022",
    so_type: "Prod WO",
    invoice_amt: 7829,
    order_status: "Backorder",
    promo_id: "Promo 5",
    sku_hierarchy_1_category: "-",
    sku_hierarchy_2_division: "-",
    sku_hierarchy_3_brand: "-",
    node_hierarchy_1: "-",
    node_hierarchy_2: "-",
    node_hierarchy_3: "-",
  },
  {
    sales_order: "SO-001686",
    invoice_id: "IN-2362",
    order_date: "09-11-2022",
    sku_code: "SKU-6",
    sku_description: "Max Fresh",
    channel_id: "Channel 2",
    node_id: "Node-6",
    node_name: "WH-Calcutta",
    customer_id: "CDC-1",
    customer_name: "CDC-Mumbai",
    total_order_quantity: 630,
    delivered_order_quantity: 500,
    in_transit_order_quantity: 100,
    pending_order_quantity: 30,
    net_sales_value: "₹10,000",
    lead_time_days: 7,
    req_delivery_date: "09-11-2022",
    plan_delivery_date: "09-11-2022",
    so_type: "Transfer",
    invoice_amt: 9723,
    order_status: "Planned",
    promo_id: "Promo 6",
    sku_hierarchy_1_category: "-",
    sku_hierarchy_2_division: "-",
    sku_hierarchy_3_brand: "-",
    node_hierarchy_1: "-",
    node_hierarchy_2: "-",
    node_hierarchy_3: "-",
  },
  {
    sales_order: "SO-001060",
    invoice_id: "IN-3098",
    order_date: "10-11-2022",
    sku_code: "SKU-7",
    sku_description: "Max Fresh",
    channel_id: "Channel 1",
    node_id: "Node-7",
    node_name: "WH-Calcutta",
    customer_id: "CDC-1",
    customer_name: "CDC-Mumbai",
    total_order_quantity: 139,
    delivered_order_quantity: 50,
    in_transit_order_quantity: 50,
    pending_order_quantity: 39,
    net_sales_value: "₹10,000",
    lead_time_days: 4,
    req_delivery_date: "10-11-2022",
    plan_delivery_date: "10-11-2022",
    so_type: "Transfer",
    invoice_amt: 9014,
    order_status: "Planned",
    promo_id: "Promo 7",
    sku_hierarchy_1_category: "-",
    sku_hierarchy_2_division: "-",
    sku_hierarchy_3_brand: "-",
    node_hierarchy_1: "-",
    node_hierarchy_2: "-",
    node_hierarchy_3: "-",
  },
  {
    sales_order: "SO-001514",
    invoice_id: "IN-2545",
    order_date: "04-11-2022",
    sku_code: "SKU-8",
    sku_description: "Max Fresh",
    channel_id: "Channel 2",
    node_id: "Node-8",
    node_name: "WH-Calcutta",
    customer_id: "CDC-1",
    customer_name: "CDC-Mumbai",
    total_order_quantity: 2968,
    delivered_order_quantity: 1000,
    in_transit_order_quantity: 1000,
    pending_order_quantity: 968,
    net_sales_value: "₹10,000",
    lead_time_days: 13,
    req_delivery_date: "04-11-2022",
    plan_delivery_date: "04-11-2022",
    so_type: "Prod WO",
    invoice_amt: 5287,
    order_status: "Backorder",
    promo_id: "Promo 8",
    sku_hierarchy_1_category: "-",
    sku_hierarchy_2_division: "-",
    sku_hierarchy_3_brand: "-",
    node_hierarchy_1: "-",
    node_hierarchy_2: "-",
    node_hierarchy_3: "-",
  },
  {
    sales_order: "SO-001355",
    invoice_id: "IN-2648",
    order_date: "07-11-2022",
    sku_code: "SKU-9",
    sku_description: "Max Fresh",
    channel_id: "Channel 1",
    node_id: "Node-9",
    node_name: "WH-Calcutta",
    customer_id: "CDC-1",
    customer_name: "CDC-Mumbai",
    total_order_quantity: 1438,
    delivered_order_quantity: 500,
    in_transit_order_quantity: 500,
    pending_order_quantity: 438,
    net_sales_value: "₹10,000",
    lead_time_days: 2,
    req_delivery_date: "07-11-2022",
    plan_delivery_date: "07-11-2022",
    so_type: "Transfer",
    invoice_amt: 7697,
    order_status: "Planned",
    promo_id: "Promo 9",
    sku_hierarchy_1_category: "-",
    sku_hierarchy_2_division: "-",
    sku_hierarchy_3_brand: "-",
    node_hierarchy_1: "-",
    node_hierarchy_2: "-",
    node_hierarchy_3: "-",
  },
  {
    sales_order: "SO-001604",
    invoice_id: "IN-2785",
    order_date: "08-11-2022",
    sku_code: "SKU-10",
    sku_description: "Max Fresh",
    channel_id: "Channel 2",
    node_id: "Node-10",
    node_name: "WH-Calcutta",
    customer_id: "CDC-1",
    customer_name: "CDC-Mumbai",
    total_order_quantity: 857,
    delivered_order_quantity: 700,
    in_transit_order_quantity: 100,
    pending_order_quantity: 57,
    net_sales_value: "₹10,000",
    lead_time_days: 11,
    req_delivery_date: "08-11-2022",
    plan_delivery_date: "08-11-2022",
    so_type: "NB",
    invoice_amt: 6318,
    order_status: "Backorder",
    promo_id: "Promo 10",
    sku_hierarchy_1_category: "-",
    sku_hierarchy_2_division: "-",
    sku_hierarchy_3_brand: "-",
    node_hierarchy_1: "-",
    node_hierarchy_2: "-",
    node_hierarchy_3: "-",
  },
  {
    sales_order: "SO-001147",
    invoice_id: "IN-2216",
    order_date: "09-11-2022",
    sku_code: "SKU-11",
    sku_description: "Max Fresh",
    channel_id: "Channel 1",
    node_id: "Node-11",
    node_name: "WH-Calcutta",
    customer_id: "CDC-1",
    customer_name: "CDC-Mumbai",
    total_order_quantity: 2609,
    delivered_order_quantity: 1000,
    in_transit_order_quantity: 1000,
    pending_order_quantity: 609,
    net_sales_value: "₹10,000",
    lead_time_days: 4,
    req_delivery_date: "09-11-2022",
    plan_delivery_date: "09-11-2022",
    so_type: "Transfer",
    invoice_amt: 6560,
    order_status: "Planned",
    promo_id: "Promo 11",
    sku_hierarchy_1_category: "-",
    sku_hierarchy_2_division: "-",
    sku_hierarchy_3_brand: "-",
    node_hierarchy_1: "-",
    node_hierarchy_2: "-",
    node_hierarchy_3: "-",
  },
  {
    sales_order: "SO-001136",
    invoice_id: "IN-2956",
    order_date: "08-11-2022",
    sku_code: "SKU-12",
    sku_description: "Max Fresh",
    channel_id: "Channel 2",
    node_id: "Node-12",
    node_name: "WH-Calcutta",
    customer_id: "CDC-1",
    customer_name: "CDC-Mumbai",
    total_order_quantity: 1188,
    delivered_order_quantity: 500,
    in_transit_order_quantity: 500,
    pending_order_quantity: 188,
    net_sales_value: "₹10,000",
    lead_time_days: 3,
    req_delivery_date: "08-11-2022",
    plan_delivery_date: "08-11-2022",
    so_type: "Prod WO",
    invoice_amt: 5579,
    order_status: "Planned",
    promo_id: "Promo 12",
    sku_hierarchy_1_category: "-",
    sku_hierarchy_2_division: "-",
    sku_hierarchy_3_brand: "-",
    node_hierarchy_1: "-",
    node_hierarchy_2: "-",
    node_hierarchy_3: "-",
  },
];
