import { useMemo, useRef } from "react";
import {
  MRT_ShowHideColumnsButton,
  MRT_ToggleFiltersButton,
  MaterialReactTable,
  MRT_GlobalFilterTextField,
} from "material-react-table";
import { Box, Tooltip } from "@mui/material";
import "./muiTable.scss";
import AppBarSearch from "./Search";
import { FindIcon, SlidersSvg, SortAscendingSvg } from "../../assets/svg-icons";
import AddSvg from "../../assets/addIcon.svg";
import { useNavigate } from "react-router-dom";
import ExpandIcon, { ExpandIconClose } from "./Expand";
import ShowHideFiltersIcon, { ShowHideFiltersIconClose } from "./RowAdjust";
import { useState } from "react";
import { useEffect } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import UploadIcon from "../customSvgIcons/upload";
import { TableLoaderSpinner } from "../tableUtils/tableUtils";
import DownloadIcon from "../customSvgIcons/download";

const AddIconTemp = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 6.99966C14 7.29582 13.7577 7.53813 13.4615 7.53813H7.53846V13.4612C7.53846 13.7588 7.29764 14 7 14C6.70237 14 6.46154 13.7574 6.46154 13.4612V7.53813H0.538462C0.240827 7.53813 0 7.2975 0 7C0 6.70351 0.240962 6.4612 0.538462 6.4612H6.46154V0.538125C6.46154 0.24049 6.70237 0 7 0C7.29764 0 7.53846 0.240625 7.53846 0.538125V6.4612H13.4615C13.7577 6.4612 14 6.70351 14 6.99966Z"
      fill="white"
    />
  </svg>
);

const MuiTable = ({
  columns,
  td,
  createPath,
  shouldShowAddBtn = true,
  renderTopToolbarCustomActions,
  level,
  setLevel,
  renderBottomToolbarCustomActions,
  enableTopToolbar = true,
  enableBottomToolbar = true,
  enableRowSelection,
  showDownloadIcon = false,
  showUploadIcon = false,
  repPlanSTOView = false,
  renderSTORightHeader,
  muiTableContainerProps = {},
  setSelectedSTO,
  isLoading = false,
  columnOrder,
  setClearRowSelection = null,
  clearRowSelection = null,
  showPagination = true,
  showToolbarInternalActions = true,
  rowId = null,
  enablePinning = true,
  handleDownload = null,
  handleUpload = null,
  isUploading = false,
  isDownloading = false,
  enableUpload = false,
  enableDownload = false,
}) => {
  const tableInstanceRef = useRef(null);
  const navigate = useNavigate();
  const customIcons = {
    ViewColumnIcon: (props) => <SlidersSvg {...props} />,
    CloseIcon: () => "",
    FullscreenIcon: () => <ExpandIcon />,
    FilterListIcon: () => <ShowHideFiltersIcon />,
    FilterListOffIcon: () => <ShowHideFiltersIconClose />,
    FullscreenExitIcon: () => <ExpandIconClose />,
    MoreVertIcon: () => <MoreVertIcon fontSize="large" />,
    SearchIcon: () => <FindIcon />,
    ArrowDownwardIcon: (props) => <SortAscendingSvg {...props} />,
  };
  const [rowSelection, setRowSelection] = useState({});
  const [muiTableKey, setMuiTableKey] = useState(0);
  const [isTableReset, setIsTableReset] = useState(false);
  const qs = document.querySelector(
    "div.snp-table-container > div > div.MuiDialog-container.MuiDialog-scrollPaper > div > div"
  );
  if (qs) {
    qs.style.width = 300;
    qs.style.height = 300;
  }
  const onIsFullScreenChange = (e) => {
    const qs = document.querySelector(
      "div.snp-table-container > div > div.MuiDialog-container.MuiDialog-scrollPaper > div > div"
    );
    const sidebar = document.querySelector(
      "#root > div > div.main-container.MuiBox-root.css-k008qs > div > div"
    );
    const topbar = document.querySelector(
      "#root > div > div.main-container.MuiBox-root > header"
    );
    if (qs) {
      qs.style.width = 300;
      qs.style.height = 300;
    }
  };
  useEffect(() => {
    if (setSelectedSTO) {
      setSelectedSTO(rowSelection);
    }
  }, [rowSelection]);

  useEffect(() => {
    if (clearRowSelection === true) {
      setRowSelection({});
      setTimeout(() => {
        setClearRowSelection(false);
      }, [10]);
    }
  }, [clearRowSelection]);

  const globalTheme = useTheme(); //(optional) if you already have a theme defined in your app root, you can import here

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          secondary: {
            light: "#6f7ca8",
            main: "#566082",
            dark: "#3d445c",
            darker: "#1b1e28",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "10px", //override to make tooltip font size larger
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  return (
    <ThemeProvider theme={tableTheme}>
      <MaterialReactTable
        key={muiTableKey}
        enablePinning={enablePinning}
        enableTopToolbar={enableTopToolbar}
        enableBottomToolbar={enableBottomToolbar}
        enablePagination={showPagination}
        enableToolbarInternalActions={showToolbarInternalActions}
        muiTableHeadCellColumnActionsButtonProps={{
          size: "large",
          sx: {
            color: "#000",
            padding: "2",
          },
        }}
        muiTableHeadCellFilterTextFieldProps={{
          sx: {
            width: "90%",
            outline: "1px solid #efefef",
            pr: "0",
            "& .MuiInputAdornment-root": {
              display: "none",
            },
          },
          variant: "outlined",
          placeholder: "",
          size: "small",
        }}
        icons={customIcons}
        // enableGlobalFilter={true}
        tableInstanceRef={tableInstanceRef}
        renderToolbarInternalActions={({ table }) => {
          if (repPlanSTOView === true) {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                  // border: "1px solid green",
                  marginBottom: "6px",
                }}
              >
                {renderSTORightHeader()}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5px",
                    justifyContent: "flex-end",
                    height: "30px",
                  }}
                >
                  {/* <AppBarSearch /> */}
                  <MRT_GlobalFilterTextField table={table} />
                  <MRT_ShowHideColumnsButton
                    table={table}
                    sx={{
                      padding: "10px",
                      paddingLeft: "12px",
                      paddingTop: "12px",
                    }}
                  />
                  <Tooltip title="Reset Applied Filters" arrow>
                    <button
                      className="table-filter-reset"
                      style={{ background: "white", padding: "0" }}
                      onClick={() => {
                        setIsTableReset(true);
                        setMuiTableKey(muiTableKey + 1);
                        setTimeout(() => {
                          setIsTableReset(false);
                        }, 1000);
                      }}
                    >
                      <RestartAltIcon
                        fontSize="large"
                        sx={{ color: "#51586d" }}
                      />
                    </button>
                  </Tooltip>
                  <MRT_ToggleFiltersButton table={table} />
                  {shouldShowAddBtn && (
                    <button
                      className="create-new-button"
                      onClick={() => navigate(createPath)}
                    >
                      {/* <img src={AddIconTemp} /> */}
                      <AddIconTemp />
                      {"  "} <span>Add</span>
                    </button>
                  )}
                </Box>
              </div>
            );
          }
          return (
            <Box sx={{ display: "flex", alignItems: "center", gap: "0.5px" }}>
              {/* <AppBarSearch /> */}
              {showUploadIcon && (
                <button
                  className={`download-button process-download-btn sto-upload process-btn`}
                  style={{ marginRight: "9px" }}
                  onClick={handleUpload}
                  disabled={isUploading || !enableUpload}
                >
                  {isUploading ? (
                    <TableLoaderSpinner size={12} height="2vh" />
                  ) : (
                    <UploadIcon />
                  )}
                </button>
              )}
              {showDownloadIcon && (
                <button
                  className={`download-button process-download-btn sto-upload process-btn`}
                  style={{ marginRight: "9px" }}
                  onClick={handleDownload}
                  disabled={isDownloading || !enableDownload}
                >
                  {isDownloading ? (
                    <TableLoaderSpinner size={12} height="2vh" />
                  ) : (
                    <DownloadIcon />
                  )}
                </button>
              )}
              <MRT_GlobalFilterTextField table={table} />
              <MRT_ShowHideColumnsButton
                table={table}
                sx={{
                  padding: "10px",
                  paddingLeft: "12px",
                  paddingTop: "12px",
                }}
              />
              <Tooltip title="Reset Applied Filters" arrow>
                <button
                  className="table-filter-reset"
                  style={{ background: "white", padding: "0" }}
                  onClick={() => {
                    setIsTableReset(true);
                    setMuiTableKey(muiTableKey + 1);
                    setTimeout(() => {
                      setIsTableReset(false);
                    }, 1000);
                  }}
                >
                  <RestartAltIcon fontSize="large" sx={{ color: "#51586d" }} />
                </button>
              </Tooltip>
              <MRT_ToggleFiltersButton table={table} />

              {shouldShowAddBtn && (
                <button
                  className="create-new-button"
                  onClick={() => navigate(createPath)}
                >
                  {/* <img src={AddIconTemp} /> */}
                  <AddIconTemp />
                  {"  "} <span>Add</span>
                </button>
              )}
            </Box>
          );
        }}
        muiSearchTextFieldProps={{
          placeholder: "Search here",
          // sx: { minWidth: "15rem" },
          variant: "outlined",
        }}
        displayColumnDefOptions={{
          "mrt-row-actions": {
            muiTableHeadCellProps: {
              align: "center",
            },
            size: 120,
          },
        }}
        muiLinearProgressProps={({ isTopToolbar }) => ({
          color: "secondary",
          sx: {
            display: isTopToolbar ? "block" : "none", //hide bottom progress bar
          },
        })}
        muiTablePaginationProps={{
          rowsPerPageOptions: [10, 25, 50, 100, 250],
          showFirstButton: true,
          showLastButton: true,
          labelRowsPerPage: "Showing",
        }}
        enableRowSelection={enableRowSelection}
        columns={columns}
        data={td}
        editingMode="modal" //default
        initialState={{
          columnPinning: { left: ["id"], right: ["action"] },
          hoveredRow: null,
          density: "compact",
          showGlobalFilter: true,
          columnOrder,
          pagination: { pageSize: 25 },
        }}
        autoResetPageIndex={false}
        renderTopToolbarCustomActions={renderTopToolbarCustomActions}
        renderBottomToolbarCustomActions={renderBottomToolbarCustomActions}
        enableColumnResizing={true}
        enableStickyHeader
        muiTableContainerProps={muiTableContainerProps}
        state={{
          rowSelection,
          showSkeletons: isLoading,
          showProgressBars: isTableReset,
        }}
        onRowSelectionChange={setRowSelection}
        getRowId={(row) => (rowId !== null ? row.wpoId : row.sto_id)}
        selectAllMode="all"
        positionToolbarAlertBanner="none"
        onIsFullScreenChange={onIsFullScreenChange}
        renderColumnActionsMenuItems={({ internalColumnMenuItems }) => {
          internalColumnMenuItems[0].props.sx.fontSize = "12px";
          return [
            ...internalColumnMenuItems, //optionally include the internal menu items above or below your custom menu items
          ];
        }}
      />
    </ThemeProvider>
  );
};

export default MuiTable;
