import { useState, useEffect, useRef, createRef, Fragment } from "react";
import MuiTable from "../../components/muiTable/muiTable";
// import { transformData } from "../../../utils/utils.js";
import { useNavigate, useLocation } from "react-router-dom";
import KPICard from "../../components/kpi_cards/KPICard";
import {
  BreadcrumbMaker2,
  TableLoaderSpinner,
} from "../../components/tableUtils/tableUtils";
// import { BsInfo } from "react-icons/bs";
// import KPICard from "../../../components/kpi_cards/KPICard.jsx";

import "./ProductionPlan.scss";
import { planOrgDetailCols } from "./Tables/columns";
import { planOrgGroupDetailData } from "./Tables/data";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../utils/components";
import { Typography } from "@mui/material";

function PlanViewMPSOrg() {
  const location = useLocation();
  const path = location.pathname;
  let idArr = path.split("/");
  let editId = idArr[idArr.length - 1];
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [level, setLevel] = useState("org");
  const [openSendModal, setOpenSendModal] = useState(false);
  const [selectedButton, setSelectedButton] = useState("APP");
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const actionsArray = [
    (row) => console.log({ row }),
    (row) => console.log({ row }),
    (row) => console.log({ row }),
  ];
  const dataOrg = planOrgGroupDetailData;
  useEffect(() => {
    setTimeout(() => {
      setTableData(dataOrg);
      setIsLoading(false);
    }, 1000);
  }, []);
  const renderLeftTableFooterGroupLevel = () => {
    return (
      <div className="table-left-bottom">
        <div className="table-left-bottom-footer">
          <p>Ongoing Cycle : 16-06-23 12:00 AM -11:59 PM</p>
          <p>Upcoming Cycle : 16-06-23 12:00 AM -11:59 PM</p>
        </div>
      </div>
    );
  };

  return (
    <div style={{ width: `calc( 100% - 20px)`, margin: 10 }}>
      <div style={{ marginBottom: "10px" }}>
        {BreadcrumbMaker2([
          {
            text: "MPS",
            link: "/snp/production/production-plan",
            level: "org",
            tab: "MPS",
          },
          {
            text: "ORG Level",
            link: "/snp/production/production-plan",
            level: "org",
            tab: "MPS",
          },
          { text: `Plan View: ${editId.replaceAll("%20", " ")}`, link: null },
        ])}
      </div>
      <Fragment>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            expanded={expanded === "panel1"}
          >
            <Typography
              sx={{
                width: "33%",
                flexShrink: 0,
                fontSize: "14px",
                color: "black",
              }}
            >
              KPI&apos;s
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div
              style={{
                margin: "auto",
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              <KPICard
                value="$10536"
                volume="25039"
                heading="Total Production Requirements"
              />
              <KPICard
                value="$10536"
                volume="22039"
                heading="Total Production Allocation"
              />{" "}
              <KPICard
                value="$320976"
                volume="12356"
                heading="Production Work Order"
              />
              <KPICard
                value="$231456"
                volume="178000"
                heading="Capacity Utilization"
              />
              <KPICard value="$231456" volume="178000" heading="Deviations" />
            </div>
          </AccordionDetails>
        </Accordion>
        <div className="snp-table-container" style={{ marginTop: "-5px" }}>
          <MuiTable
            columns={planOrgDetailCols}
            td={tableData}
            createPath="/js-create"
            level={level}
            setLevel={setLevel}
            renderBottomToolbarCustomActions={renderLeftTableFooterGroupLevel}
            shouldShowAddBtn={false}
            isLoading={isLoading}
            //   viewPath="/process-view"
          />
        </div>
      </Fragment>
    </div>
  );
}

export default PlanViewMPSOrg;
