const EditIcon = ({ color, height }) => (
  <svg
    width={height}
    height={height}
    viewBox="0 0 128 128"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="action-icon-hover"
  >
    <path
      d="M123.297 14.0666L113.92 4.68935C110.797 1.56492 106.697 0.00195312 102.622 0.00195312C98.5275 0.00195312 94.4326 1.56442 91.3077 4.68935L11.277 84.7251C9.13502 86.8665 7.64455 89.5725 6.98206 92.5274L0.0797164 123.227C-0.425272 125.524 1.53918 127.999 3.98713 127.999C4.24887 127.999 4.51612 127.973 4.78661 127.919C4.78661 127.919 25.9386 123.432 35.4859 121.187C38.3709 120.507 40.9533 119.056 43.0483 116.962L123.346 36.6636C129.572 30.4263 129.547 20.3165 123.297 14.0666ZM37.3739 111.299C36.3192 112.354 35.0609 113.059 33.634 113.395C27.4641 114.848 16.2244 117.283 9.26952 118.775L14.7794 94.2731C15.1124 92.7937 15.8551 91.4457 16.9278 90.3732L78.8265 28.4746L99.516 49.1641L37.3739 111.299ZM117.672 31.0013L105.165 43.506L84.4754 22.8165L96.9576 10.3342C98.4725 8.83426 100.473 8.00178 102.622 8.00178C104.772 8.00178 106.767 8.83426 108.28 10.3455L117.657 19.7228C120.772 22.844 120.772 27.9013 117.672 31.0013Z"
      fill={color}
    />
  </svg>
);
export default EditIcon;
