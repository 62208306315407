export const data1 = {
  id: "root",
  label: "Electric Bike - Model E1",
  children: [
    {
      id: "c1",
      label: "Frame Assembly",
      children: [
        {
          id: "c1-1",
          label: "Frame",
          children: [
            {
              id: "c1-1-1",
              label: "Main Frame",
              children: [
                {
                  id: "c1-1-1-1",
                  label: "Top Tube",
                  children: [
                    {
                      id: "c1-1-1-1-1",
                      label: "Tube Material",
                      children: [
                        {
                          id: "c1-1-1-1-1-1",
                          label: "Aluminum Alloy",
                          children: [
                            {
                              id: "c1-1-1-1-1-1-1",
                              label: "Alloy Grade",
                              quantity: 1,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  id: "c1-1-1-2",
                  label: "Down Tube",
                  quantity: 1,
                },
                {
                  id: "c1-1-1-3",
                  label: "Seat Tube",
                  quantity: 1,
                },
                {
                  id: "c1-1-1-4",
                  label: "Head Tube",
                  quantity: 1,
                },
              ],
            },
            {
              id: "c1-1-2",
              label: "Battery Mount",
              children: [
                {
                  id: "c1-1-2-1",
                  label: "Mount Bracket",
                  quantity: 1,
                },
                {
                  id: "c1-1-2-2",
                  label: "Locking Mechanism",
                  quantity: 1,
                },
              ],
            },
            {
              id: "c1-1-3",
              label: "Motor Mount",
              children: [
                {
                  id: "c1-1-3-1",
                  label: "Mounting Plate",
                  quantity: 1,
                },
                {
                  id: "c1-1-3-2",
                  label: "Bolts",
                  quantity: 4,
                },
              ],
            },
          ],
        },
        {
          id: "c1-2",
          label: "Fork",
          children: [
            {
              id: "c1-2-1",
              label: "Fork Stanchions",
              children: [
                {
                  id: "c1-2-1-1",
                  label: "Stanchion Tube",
                  children: [
                    {
                      id: "c1-2-1-1-1",
                      label: "Tube Material",
                      children: [
                        {
                          id: "c1-2-1-1-1-1",
                          label: "Aluminum Alloy",
                          children: [
                            {
                              id: "c1-2-1-1-1-1-1",
                              label: "Alloy Grade",
                              quantity: 2,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  id: "c1-2-1-2",
                  label: "Stanchion Bushings",
                  quantity: 4,
                },
              ],
            },
            {
              id: "c1-2-2",
              label: "Fork Crown",
              quantity: 1,
            },
          ],
        },
      ],
    },
    {
      id: "c2",
      label: "Electrical Components",
      children: [
        {
          id: "c2-1",
          label: "Electric Motor",
          children: [
            {
              id: "c2-1-1",
              label: "Motor Body",
              quantity: 1,
            },
            {
              id: "c2-1-2",
              label: "Rotor",
              quantity: 1,
            },
            {
              id: "c2-1-3",
              label: "Stator",
              quantity: 1,
            },
            {
              id: "c2-1-4",
              label: "Bearings",
              children: [
                {
                  id: "c2-1-4-1",
                  label: "Front Bearing",
                  children: [
                    {
                      id: "c2-1-4-1-1",
                      label: "Bearing Type",
                      children: [
                        {
                          id: "c2-1-4-1-1-1",
                          label: "Sealed Cartridge",
                          children: [
                            {
                              id: "c2-1-4-1-1-1-1",
                              label: "Bearing Size",
                              quantity: 1,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  id: "c2-1-4-2",
                  label: "Rear Bearing",
                  quantity: 1,
                },
              ],
            },
          ],
        },
        {
          id: "c2-2",
          label: "Battery Pack",
          children: [
            {
              id: "c2-2-1",
              label: "Battery Cells",
              children: [
                {
                  id: "c2-2-1-1",
                  label: "Cell Type",
                  children: [
                    {
                      id: "c2-2-1-1-1",
                      label: "Lithium-ion",
                      children: [
                        {
                          id: "c2-2-1-1-1-1",
                          label: "Cell Chemistry",
                          children: [
                            {
                              id: "c2-2-1-1-1-1-1",
                              label: "NMC",
                              quantity: 40,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              id: "c2-2-2",
              label: "Battery Management System",
              quantity: 1,
            },
          ],
        },
        {
          id: "c2-3",
          label: "Controller",
          quantity: 1,
        },
        {
          id: "c2-4",
          label: "Display",
          quantity: 1,
        },
        {
          id: "c2-5",
          label: "Wiring Harness",
          quantity: 1,
        },
      ],
    },
  ],
};

export const data2 = {
  id: "root",
  label: "Mountain Bike - Model XYZ",
  children: [
    {
      id: "c1",
      label: "Frame Assembly",
      children: [
        {
          id: "c1-1",
          label: "Frame",
          children: [
            {
              id: "c1-1-1",
              label: "Frame Body",
              children: [
                {
                  id: "c1-1-1-1",
                  label: "Frame Tube",
                  children: [
                    {
                      id: "c1-1-1-1-1",
                      label: "Tube Material",
                      children: [
                        {
                          id: "c1-1-1-1-1-1",
                          label: "Aluminum Alloy",
                          children: [
                            {
                              id: "c1-1-1-1-1-1-1",
                              label: "Alloy Grade",
                              quantity: 1,
                            },
                          ],
                        },
                      ],
                    },
                    {
                      id: "c1-1-1-1-2",
                      label: "Tube Butting",
                      quantity: 1,
                    },
                  ],
                },
                {
                  id: "c1-1-1-2",
                  label: "Head Tube",
                  quantity: 1,
                },
                {
                  id: "c1-1-1-3",
                  label: "Bottom Bracket Shell",
                  quantity: 1,
                },
              ],
            },
            {
              id: "c1-1-2",
              label: "Frame Decals",
              quantity: 1,
            },
          ],
        },
        {
          id: "c1-2",
          label: "Suspension Fork",
          children: [
            {
              id: "c1-2-1",
              label: "Fork Stanchions",
              children: [
                {
                  id: "c1-2-1-1",
                  label: "Stanchion Tube",
                  quantity: 2,
                },
                {
                  id: "c1-2-1-2",
                  label: "Stanchion Bushings",
                  quantity: 4,
                },
              ],
            },
            {
              id: "c1-2-2",
              label: "Fork Crown",
              children: [
                {
                  id: "c1-2-2-1",
                  label: "Crown Body",
                  quantity: 1,
                },
                {
                  id: "c1-2-2-2",
                  label: "Crown Steerer Tube",
                  children: [
                    {
                      id: "c1-2-2-2-1",
                      label: "Steerer Material",
                      children: [
                        {
                          id: "c1-2-2-2-1-1",
                          label: "Carbon Fiber",
                          children: [
                            {
                              id: "c1-2-2-2-1-1-1",
                              label: "Carbon Fiber Type",
                              quantity: 1,
                            },
                          ],
                        },
                      ],
                    },
                    {
                      id: "c1-2-2-2-2",
                      label: "Steerer Bearings",
                      children: [
                        {
                          id: "c1-2-2-2-2-1",
                          label: "Bearing Type",
                          quantity: 1,
                        },
                        {
                          id: "c1-2-2-2-2-2",
                          label: "Bearing Seals",
                          quantity: 2,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              id: "c1-2-3",
              label: "Fork Seals",
              quantity: 2,
            },
          ],
        },
      ],
    },
  ],
};

export const data3 = {
  id: "root",
  label: "Electric Car - Model EC1",
  children: [
    {
      id: "c1",
      label: "Powertrain",
      children: [
        {
          id: "c1-1",
          label: "Electric Motor",
          children: [
            {
              id: "c1-1-1",
              label: "Stator",
              children: [
                {
                  id: "c1-1-1-1",
                  label: "Stator Core",
                  children: [
                    {
                      id: "c1-1-1-1-1",
                      label: "Laminations",
                      children: [
                        {
                          id: "c1-1-1-1-1-1",
                          label: "Silicon Steel",
                          children: [
                            {
                              id: "c1-1-1-1-1-1-1",
                              label: "Steel Grade",
                              quantity: 1,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  id: "c1-1-1-2",
                  label: "Windings",
                  quantity: 1,
                },
              ],
            },
            {
              id: "c1-1-2",
              label: "Rotor",
              children: [
                {
                  id: "c1-1-2-1",
                  label: "Rotor Core",
                  quantity: 1,
                },
                {
                  id: "c1-1-2-2",
                  label: "Permanent Magnets",
                  quantity: 8,
                },
              ],
            },
            {
              id: "c1-1-3",
              label: "Bearings",
              quantity: 2,
            },
          ],
        },
        {
          id: "c1-2",
          label: "Battery Pack",
          children: [
            {
              id: "c1-2-1",
              label: "Battery Modules",
              children: [
                {
                  id: "c1-2-1-1",
                  label: "Battery Cells",
                  children: [
                    {
                      id: "c1-2-1-1-1",
                      label: "Cell Type",
                      children: [
                        {
                          id: "c1-2-1-1-1-1",
                          label: "Lithium-ion",
                          children: [
                            {
                              id: "c1-2-1-1-1-1-1",
                              label: "Cell Chemistry",
                              quantity: 7000,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  id: "c1-2-1-2",
                  label: "Module Casing",
                  quantity: 16,
                },
              ],
            },
            {
              id: "c1-2-2",
              label: "Battery Management System",
              quantity: 1,
            },
            {
              id: "c1-2-3",
              label: "Cooling System",
              quantity: 1,
            },
          ],
        },
        {
          id: "c1-3",
          label: "Power Electronics",
          children: [
            {
              id: "c1-3-1",
              label: "Inverter",
              quantity: 1,
            },
            {
              id: "c1-3-2",
              label: "DC-DC Converter",
              quantity: 1,
            },
          ],
        },
      ],
    },
    {
      id: "c2",
      label: "Chassis and Body",
      children: [
        {
          id: "c2-1",
          label: "Frame",
          children: [
            {
              id: "c2-1-1",
              label: "Unibody Structure",
              children: [
                {
                  id: "c2-1-1-1",
                  label: "Frame Material",
                  children: [
                    {
                      id: "c2-1-1-1-1",
                      label: "Aluminum Alloy",
                      children: [
                        {
                          id: "c2-1-1-1-1-1",
                          label: "Alloy Type",
                          children: [
                            {
                              id: "c2-1-1-1-1-1-1",
                              label: "Alloy Grade",
                              quantity: 1,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  id: "c2-1-1-2",
                  label: "Structural Reinforcements",
                  quantity: 1,
                },
              ],
            },
            {
              id: "c2-1-2",
              label: "Crumple Zones",
              quantity: 1,
            },
          ],
        },
        {
          id: "c2-2",
          label: "Body Panels",
          children: [
            {
              id: "c2-2-1",
              label: "Hood",
              quantity: 1,
            },
            {
              id: "c2-2-2",
              label: "Doors",
              quantity: 4,
            },
            {
              id: "c2-2-3",
              label: "Roof",
              quantity: 1,
            },
          ],
        },
      ],
    },
    {
      id: "c3",
      label: "Suspension and Steering",
      children: [
        {
          id: "c3-1",
          label: "Front Suspension",
          children: [
            {
              id: "c3-1-1",
              label: "Struts",
              children: [
                {
                  id: "c3-1-1-1",
                  label: "Shock Absorber",
                  children: [
                    {
                      id: "c3-1-1-1-1",
                      label: "Damper",
                      children: [
                        {
                          id: "c3-1-1-1-1-1",
                          label: "Fluid Type",
                          children: [
                            {
                              id: "c3-1-1-1-1-1-1",
                              label: "Hydraulic Oil",
                              quantity: 2,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  id: "c3-1-1-2",
                  label: "Spring",
                  quantity: 2,
                },
              ],
            },
            {
              id: "c3-1-2",
              label: "Control Arms",
              quantity: 2,
            },
          ],
        },
        {
          id: "c3-2",
          label: "Rear Suspension",
          children: [
            {
              id: "c3-2-1",
              label: "Multi-link System",
              quantity: 1,
            },
          ],
        },
        {
          id: "c3-3",
          label: "Steering System",
          children: [
            {
              id: "c3-3-1",
              label: "Steering Rack",
              quantity: 1,
            },
            {
              id: "c3-3-2",
              label: "Power Steering Unit",
              quantity: 1,
            },
          ],
        },
      ],
    },
  ],
};

export const data4 = {
  id: "root",
  label: "Three-Wheeler Rickshaw - Model R1",
  children: [
    {
      id: "c1",
      label: "Chassis and Body",
      children: [
        {
          id: "c1-1",
          label: "Frame",
          children: [
            {
              id: "c1-1-1",
              label: "Main Frame",
              children: [
                {
                  id: "c1-1-1-1",
                  label: "Front Section",
                  children: [
                    {
                      id: "c1-1-1-1-1",
                      label: "Steering Column Mount",
                      children: [
                        {
                          id: "c1-1-1-1-1-1",
                          label: "Bearing Housing",
                          children: [
                            {
                              id: "c1-1-1-1-1-1-1",
                              label: "Bearing Type",
                              quantity: 2,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  id: "c1-1-1-2",
                  label: "Rear Section",
                  quantity: 1,
                },
                {
                  id: "c1-1-1-3",
                  label: "Engine Mount",
                  quantity: 1,
                },
              ],
            },
            {
              id: "c1-1-2",
              label: "Passenger Cabin Frame",
              children: [
                {
                  id: "c1-1-2-1",
                  label: "Roof Support",
                  quantity: 4,
                },
                {
                  id: "c1-1-2-2",
                  label: "Seat Frame",
                  quantity: 1,
                },
              ],
            },
          ],
        },
        {
          id: "c1-2",
          label: "Body Panels",
          children: [
            {
              id: "c1-2-1",
              label: "Front Cowl",
              quantity: 1,
            },
            {
              id: "c1-2-2",
              label: "Side Panels",
              quantity: 2,
            },
            {
              id: "c1-2-3",
              label: "Roof",
              quantity: 1,
            },
          ],
        },
      ],
    },
    {
      id: "c2",
      label: "Powertrain",
      children: [
        {
          id: "c2-1",
          label: "Engine",
          children: [
            {
              id: "c2-1-1",
              label: "Engine Block",
              children: [
                {
                  id: "c2-1-1-1",
                  label: "Cylinder",
                  children: [
                    {
                      id: "c2-1-1-1-1",
                      label: "Piston",
                      children: [
                        {
                          id: "c2-1-1-1-1-1",
                          label: "Piston Rings",
                          children: [
                            {
                              id: "c2-1-1-1-1-1-1",
                              label: "Ring Type",
                              quantity: 3,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  id: "c2-1-1-2",
                  label: "Crankshaft",
                  quantity: 1,
                },
                {
                  id: "c2-1-1-3",
                  label: "Camshaft",
                  quantity: 1,
                },
              ],
            },
            {
              id: "c2-1-2",
              label: "Fuel System",
              children: [
                {
                  id: "c2-1-2-1",
                  label: "Carburetor",
                  quantity: 1,
                },
                {
                  id: "c2-1-2-2",
                  label: "Fuel Tank",
                  quantity: 1,
                },
              ],
            },
          ],
        },
        {
          id: "c2-2",
          label: "Transmission",
          children: [
            {
              id: "c2-2-1",
              label: "Gearbox",
              children: [
                {
                  id: "c2-2-1-1",
                  label: "Gears",
                  children: [
                    {
                      id: "c2-2-1-1-1",
                      label: "First Gear",
                      children: [
                        {
                          id: "c2-2-1-1-1-1",
                          label: "Gear Material",
                          children: [
                            {
                              id: "c2-2-1-1-1-1-1",
                              label: "Steel Grade",
                              quantity: 1,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  id: "c2-2-1-2",
                  label: "Shifting Mechanism",
                  quantity: 1,
                },
              ],
            },
            {
              id: "c2-2-2",
              label: "Clutch",
              quantity: 1,
            },
          ],
        },
      ],
    },
    {
      id: "c3",
      label: "Suspension and Steering",
      children: [
        {
          id: "c3-1",
          label: "Front Suspension",
          children: [
            {
              id: "c3-1-1",
              label: "Fork",
              children: [
                {
                  id: "c3-1-1-1",
                  label: "Fork Tubes",
                  children: [
                    {
                      id: "c3-1-1-1-1",
                      label: "Tube Material",
                      children: [
                        {
                          id: "c3-1-1-1-1-1",
                          label: "Steel Type",
                          children: [
                            {
                              id: "c3-1-1-1-1-1-1",
                              label: "Steel Grade",
                              quantity: 2,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  id: "c3-1-1-2",
                  label: "Fork Crown",
                  quantity: 1,
                },
              ],
            },
            {
              id: "c3-1-2",
              label: "Shock Absorber",
              quantity: 1,
            },
          ],
        },
        {
          id: "c3-2",
          label: "Rear Suspension",
          children: [
            {
              id: "c3-2-1",
              label: "Leaf Springs",
              quantity: 2,
            },
            {
              id: "c3-2-2",
              label: "Shock Absorbers",
              quantity: 2,
            },
          ],
        },
        {
          id: "c3-3",
          label: "Steering System",
          children: [
            {
              id: "c3-3-1",
              label: "Handlebar",
              quantity: 1,
            },
            {
              id: "c3-3-2",
              label: "Steering Column",
              quantity: 1,
            },
          ],
        },
      ],
    },
  ],
};

export const pieChartData = [
  {
    FG: "Motorbike",
    "Item Description": "Motorbike",
    "Item Type": "FG",
    Quantity: 1,
    "Item Cost": 93882,
    "Item unit cost": 93882,
  },
  {
    FG: "Motorbike",
    "Item Description": "Frame Assembly",
    "Item Type": "SFG",
    Quantity: 1,
    "Item Cost": 3,
    "Item unit cost": 3,
  },
  {
    FG: "Motorbike",
    "Item Description": "Frame",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 1140,
    "Item unit cost": 1140,
  },
  {
    FG: "Motorbike",
    "Item Description": "Handlebars",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 887,
    "Item unit cost": 887,
  },
  {
    FG: "Motorbike",
    "Item Description": "Forks",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 196,
    "Item unit cost": 196,
  },
  {
    FG: "Motorbike",
    "Item Description": "Swingarm",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 1678,
    "Item unit cost": 1678,
  },
  {
    FG: "Motorbike",
    "Item Description": "Wheels (Front and Rear)",
    "Item Type": "RM",
    Quantity: 2,
    "Item Cost": 4590,
    "Item unit cost": 2295,
  },
  {
    FG: "Motorbike",
    "Item Description": "Tires (Front and Rear)",
    "Item Type": "RM",
    Quantity: 2,
    "Item Cost": 900,
    "Item unit cost": 450,
  },
  {
    FG: "Motorbike",
    "Item Description": "Brakes (Front and Rear)",
    "Item Type": "RM",
    Quantity: 2,
    "Item Cost": 4038,
    "Item unit cost": 2019,
  },
  {
    FG: "Motorbike",
    "Item Description": "Suspension (Front and Rear)",
    "Item Type": "RM",
    Quantity: 2,
    "Item Cost": 3250,
    "Item unit cost": 1625,
  },
  {
    FG: "Motorbike",
    "Item Description": "Engine Assembly",
    "Item Type": "SFG",
    Quantity: 1,
    "Item Cost": 3,
    "Item unit cost": 3,
  },
  {
    FG: "Motorbike",
    "Item Description": "Engine block",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 1194,
    "Item unit cost": 1194,
  },
  {
    FG: "Motorbike",
    "Item Description": "Cylinder head",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 621,
    "Item unit cost": 621,
  },
  {
    FG: "Motorbike",
    "Item Description": "Pistons",
    "Item Type": "RM",
    Quantity: 4,
    "Item Cost": 6924,
    "Item unit cost": 1731,
  },
  {
    FG: "Motorbike",
    "Item Description": "Crankshaft",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 1279,
    "Item unit cost": 1279,
  },
  {
    FG: "Motorbike",
    "Item Description": "Camshaft",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 929,
    "Item unit cost": 929,
  },
  {
    FG: "Motorbike",
    "Item Description": "Carburetor/Fuel Injection System",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 2498,
    "Item unit cost": 2498,
  },
  {
    FG: "Motorbike",
    "Item Description": "Exhaust System",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 1324,
    "Item unit cost": 1324,
  },
  {
    FG: "Motorbike",
    "Item Description": "Cooling System (Radiator, Hoses, Fans)",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 1672,
    "Item unit cost": 1672,
  },
  {
    FG: "Motorbike",
    "Item Description": "Transmission",
    "Item Type": "SFG",
    Quantity: 1,
    "Item Cost": 9,
    "Item unit cost": 9,
  },
  {
    FG: "Motorbike",
    "Item Description": "Transmission Gearbox",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 2196,
    "Item unit cost": 2196,
  },
  {
    FG: "Motorbike",
    "Item Description": "Clutch Assembly",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 686,
    "Item unit cost": 686,
  },
  {
    FG: "Motorbike",
    "Item Description": "Gear Shift Mechanism",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 2491,
    "Item unit cost": 2491,
  },
  {
    FG: "Motorbike",
    "Item Description": "Drive Chain or Belt",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 781,
    "Item unit cost": 781,
  },
  {
    FG: "Motorbike",
    "Item Description": "Electrical System",
    "Item Type": "SFG",
    Quantity: 1,
    "Item Cost": 1,
    "Item unit cost": 1,
  },
  {
    FG: "Motorbike",
    "Item Description": "Battery",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 2063,
    "Item unit cost": 2063,
  },
  {
    FG: "Motorbike",
    "Item Description": "Ignition System",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 1507,
    "Item unit cost": 1507,
  },
  {
    FG: "Motorbike",
    "Item Description": "Wiring Harness",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 1446,
    "Item unit cost": 1446,
  },
  {
    FG: "Motorbike",
    "Item Description": "Lights (Headlights, Tail lights, etc.)",
    "Item Type": "RM",
    Quantity: 4,
    "Item Cost": 2284,
    "Item unit cost": 571,
  },
  {
    FG: "Motorbike",
    "Item Description": "Instrument Cluster",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 2021,
    "Item unit cost": 2021,
  },
  {
    FG: "Motorbike",
    "Item Description": "Switches and Controls",
    "Item Type": "RM",
    Quantity: 10,
    "Item Cost": 13880,
    "Item unit cost": 1388,
  },
  {
    FG: "Motorbike",
    "Item Description": "Fuel System",
    "Item Type": "SFG",
    Quantity: 1,
    "Item Cost": 4,
    "Item unit cost": 4,
  },
  {
    FG: "Motorbike",
    "Item Description": "Fuel Tank",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 1744,
    "Item unit cost": 1744,
  },
  {
    FG: "Motorbike",
    "Item Description": "Fuel Pump",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 1017,
    "Item unit cost": 1017,
  },
  {
    FG: "Motorbike",
    "Item Description": "Fuel Lines",
    "Item Type": "RM",
    Quantity: 2,
    "Item Cost": 1664,
    "Item unit cost": 832,
  },
  {
    FG: "Motorbike",
    "Item Description": "Braking System",
    "Item Type": "SFG",
    Quantity: 1,
    "Item Cost": 9,
    "Item unit cost": 9,
  },
  {
    FG: "Motorbike",
    "Item Description": "Brake Calipers",
    "Item Type": "RM",
    Quantity: 2,
    "Item Cost": 402,
    "Item unit cost": 201,
  },
  {
    FG: "Motorbike",
    "Item Description": "Brake Master Cylinders",
    "Item Type": "RM",
    Quantity: 2,
    "Item Cost": 2350,
    "Item unit cost": 1175,
  },
  {
    FG: "Motorbike",
    "Item Description": "Brake Lines",
    "Item Type": "RM",
    Quantity: 4,
    "Item Cost": 8692,
    "Item unit cost": 2173,
  },
  {
    FG: "Motorbike",
    "Item Description": "Suspension System",
    "Item Type": "SFG",
    Quantity: 1,
    "Item Cost": 1,
    "Item unit cost": 1,
  },
  {
    FG: "Motorbike",
    "Item Description": "Front Forks",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 598,
    "Item unit cost": 598,
  },
  {
    FG: "Motorbike",
    "Item Description": "Rear Shock Absorbers",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 270,
    "Item unit cost": 270,
  },
  {
    FG: "Motorbike",
    "Item Description": "Controls",
    "Item Type": "SFG",
    Quantity: 1,
    "Item Cost": 9,
    "Item unit cost": 9,
  },
  {
    FG: "Motorbike",
    "Item Description": "Handlebar Grips",
    "Item Type": "RM",
    Quantity: 2,
    "Item Cost": 3314,
    "Item unit cost": 1657,
  },
  {
    FG: "Motorbike",
    "Item Description": "Throttle Assembly",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 804,
    "Item unit cost": 804,
  },
  {
    FG: "Motorbike",
    "Item Description": "Brake Levers",
    "Item Type": "RM",
    Quantity: 2,
    "Item Cost": 1390,
    "Item unit cost": 695,
  },
  {
    FG: "Motorbike",
    "Item Description": "Bodywork",
    "Item Type": "SFG",
    Quantity: 1,
    "Item Cost": 9,
    "Item unit cost": 9,
  },
  {
    FG: "Motorbike",
    "Item Description": "Fairings (if applicable)",
    "Item Type": "RM",
    Quantity: 2,
    "Item Cost": 4282,
    "Item unit cost": 2141,
  },
  {
    FG: "Motorbike",
    "Item Description": "Fenders",
    "Item Type": "RM",
    Quantity: 2,
    "Item Cost": 3166,
    "Item unit cost": 1583,
  },
  {
    FG: "Motorbike",
    "Item Description": "Seat",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 1466,
    "Item unit cost": 1466,
  },
  {
    FG: "Motorbike",
    "Item Description": "Mirrors",
    "Item Type": "RM",
    Quantity: 2,
    "Item Cost": 200,
    "Item unit cost": 100,
  },
  {
    FG: "Electric Bike",
    "Item Description": "Electric Bike",
    "Item Type": "FG",
    Quantity: 1,
    "Item Cost": 104263,
    "Item unit cost": 104263,
  },
  {
    FG: "Electric Bike",
    "Item Description": "Frame",
    "Item Type": "SFG",
    Quantity: 1,
    "Item Cost": 9,
    "Item unit cost": 9,
  },
  {
    FG: "Electric Bike",
    "Item Description": "Frame Body",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 722,
    "Item unit cost": 722,
  },
  {
    FG: "Electric Bike",
    "Item Description": "Seat",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 1544,
    "Item unit cost": 1544,
  },
  {
    FG: "Electric Bike",
    "Item Description": "Fork",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 2277,
    "Item unit cost": 2277,
  },
  {
    FG: "Electric Bike",
    "Item Description": "Handlebar",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 1382,
    "Item unit cost": 1382,
  },
  {
    FG: "Electric Bike",
    "Item Description": "Wheel Set",
    "Item Type": "SFG",
    Quantity: 2,
    "Item Cost": 8,
    "Item unit cost": 4,
  },
  {
    FG: "Electric Bike",
    "Item Description": "Front Wheel",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 1517,
    "Item unit cost": 1517,
  },
  {
    FG: "Electric Bike",
    "Item Description": "Rear Wheel",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 998,
    "Item unit cost": 998,
  },
  {
    FG: "Electric Bike",
    "Item Description": "Drive System",
    "Item Type": "SFG",
    Quantity: 1,
    "Item Cost": 9,
    "Item unit cost": 9,
  },
  {
    FG: "Electric Bike",
    "Item Description": "Motor",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 792,
    "Item unit cost": 792,
  },
  {
    FG: "Electric Bike",
    "Item Description": "Battery",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 489,
    "Item unit cost": 489,
  },
  {
    FG: "Electric Bike",
    "Item Description": "Chain",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 939,
    "Item unit cost": 939,
  },
  {
    FG: "Electric Bike",
    "Item Description": "Pedals",
    "Item Type": "RM",
    Quantity: 2,
    "Item Cost": 2904,
    "Item unit cost": 1452,
  },
  {
    FG: "Electric Bike",
    "Item Description": "Electrical System",
    "Item Type": "SFG",
    Quantity: 1,
    "Item Cost": 6,
    "Item unit cost": 6,
  },
  {
    FG: "Electric Bike",
    "Item Description": "Wiring Harness",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 2290,
    "Item unit cost": 2290,
  },
  {
    FG: "Electric Bike",
    "Item Description": "Controller",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 944,
    "Item unit cost": 944,
  },
  {
    FG: "Electric Bike",
    "Item Description": "Display",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 149,
    "Item unit cost": 149,
  },
  {
    FG: "Electric Bike",
    "Item Description": "Throttle",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 171,
    "Item unit cost": 171,
  },
  {
    FG: "Electric Bike",
    "Item Description": "Braking System",
    "Item Type": "SFG",
    Quantity: 1,
    "Item Cost": 4,
    "Item unit cost": 4,
  },
  {
    FG: "Electric Bike",
    "Item Description": "Front Brake",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 844,
    "Item unit cost": 844,
  },
  {
    FG: "Electric Bike",
    "Item Description": "Rear Brake",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 463,
    "Item unit cost": 463,
  },
  {
    FG: "Electric Car",
    "Item Description": "Electric Car",
    "Item Type": "FG",
    Quantity: 1,
    "Item Cost": 302987,
    "Item unit cost": 302987,
  },
  {
    FG: "Electric car",
    "Item Description": "Chassis",
    "Item Type": "SFG",
    Quantity: 1,
    "Item Cost": 8,
    "Item unit cost": 8,
  },
  {
    FG: "Electric car",
    "Item Description": "Frame",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 1903,
    "Item unit cost": 1903,
  },
  {
    FG: "Electric car",
    "Item Description": "Suspension System",
    "Item Type": "SFG",
    Quantity: 1,
    "Item Cost": 2,
    "Item unit cost": 2,
  },
  {
    FG: "Electric car",
    "Item Description": "Front Suspension",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 1328,
    "Item unit cost": 1328,
  },
  {
    FG: "Electric car",
    "Item Description": "Rear Suspension",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 289,
    "Item unit cost": 289,
  },
  {
    FG: "Electric car",
    "Item Description": "Steering System",
    "Item Type": "SFG",
    Quantity: 1,
    "Item Cost": 9,
    "Item unit cost": 9,
  },
  {
    FG: "Electric car",
    "Item Description": "Steering Wheel",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 664,
    "Item unit cost": 664,
  },
  {
    FG: "Electric car",
    "Item Description": "Steering Column",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 1852,
    "Item unit cost": 1852,
  },
  {
    FG: "Electric car",
    "Item Description": "Body",
    "Item Type": "SFG",
    Quantity: 1,
    "Item Cost": 4,
    "Item unit cost": 4,
  },
  {
    FG: "Electric car",
    "Item Description": "Body Shell",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 1737,
    "Item unit cost": 1737,
  },
  {
    FG: "Electric car",
    "Item Description": "Doors",
    "Item Type": "RM",
    Quantity: 4,
    "Item Cost": 7760,
    "Item unit cost": 1940,
  },
  {
    FG: "Electric car",
    "Item Description": "Windows",
    "Item Type": "RM",
    Quantity: 6,
    "Item Cost": 14802,
    "Item unit cost": 2467,
  },
  {
    FG: "Electric car",
    "Item Description": "Powertrain",
    "Item Type": "SFG",
    Quantity: 1,
    "Item Cost": 8,
    "Item unit cost": 8,
  },
  {
    FG: "Electric car",
    "Item Description": "Electric Motor",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 1598,
    "Item unit cost": 1598,
  },
  {
    FG: "Electric car",
    "Item Description": "Battery Pack",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 1749,
    "Item unit cost": 1749,
  },
  {
    FG: "Electric car",
    "Item Description": "Transmission",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 815,
    "Item unit cost": 815,
  },
  {
    FG: "Electric car",
    "Item Description": "Drive Shafts",
    "Item Type": "RM",
    Quantity: 2,
    "Item Cost": 4376,
    "Item unit cost": 2188,
  },
  {
    FG: "Electric car",
    "Item Description": "Electrical System",
    "Item Type": "SFG",
    Quantity: 1,
    "Item Cost": 9,
    "Item unit cost": 9,
  },
  {
    FG: "Electric car",
    "Item Description": "Wiring Harness",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 2057,
    "Item unit cost": 2057,
  },
  {
    FG: "Electric car",
    "Item Description": "Control Unit",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 670,
    "Item unit cost": 670,
  },
  {
    FG: "Electric car",
    "Item Description": "Display Panel",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 1259,
    "Item unit cost": 1259,
  },
  {
    FG: "Electric car",
    "Item Description": "Infotainment System",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 1832,
    "Item unit cost": 1832,
  },
  {
    FG: "Electric car",
    "Item Description": "Interior",
    "Item Type": "SFG",
    Quantity: 1,
    "Item Cost": 9,
    "Item unit cost": 9,
  },
  {
    FG: "Electric car",
    "Item Description": "Seats",
    "Item Type": "RM",
    Quantity: 5,
    "Item Cost": 4480,
    "Item unit cost": 896,
  },
  {
    FG: "Electric car",
    "Item Description": "Dashboard",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 843,
    "Item unit cost": 843,
  },
  {
    FG: "Electric car",
    "Item Description": "Console",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 1437,
    "Item unit cost": 1437,
  },
  {
    FG: "Electric car",
    "Item Description": "Climate Control System",
    "Item Type": "SFG",
    Quantity: 1,
    "Item Cost": 9,
    "Item unit cost": 9,
  },
  {
    FG: "Electric car",
    "Item Description": "Air Conditioning Unit",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 1230,
    "Item unit cost": 1230,
  },
  {
    FG: "Electric car",
    "Item Description": "Heater",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 870,
    "Item unit cost": 870,
  },
  {
    FG: "Electric car",
    "Item Description": "Braking System",
    "Item Type": "SFG",
    Quantity: 1,
    "Item Cost": 7,
    "Item unit cost": 7,
  },
  {
    FG: "Electric car",
    "Item Description": "Front Brakes",
    "Item Type": "RM",
    Quantity: 2,
    "Item Cost": 2936,
    "Item unit cost": 1468,
  },
  {
    FG: "Electric car",
    "Item Description": "Rear Brakes",
    "Item Type": "RM",
    Quantity: 2,
    "Item Cost": 2242,
    "Item unit cost": 1121,
  },
  {
    FG: "Diesel car",
    "Item Description": "Diesel Car",
    "Item Type": "FG",
    Quantity: 1,
    "Item Cost": 229485,
    "Item unit cost": 229485,
  },
  {
    FG: "Diesel car",
    "Item Description": "Chassis",
    "Item Type": "SFG",
    Quantity: 1,
    "Item Cost": 6,
    "Item unit cost": 6,
  },
  {
    FG: "Diesel car",
    "Item Description": "Frame",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 699,
    "Item unit cost": 699,
  },
  {
    FG: "Diesel car",
    "Item Description": "Suspension System",
    "Item Type": "SFG",
    Quantity: 1,
    "Item Cost": 2,
    "Item unit cost": 2,
  },
  {
    FG: "Diesel car",
    "Item Description": "Front Suspension",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 1558,
    "Item unit cost": 1558,
  },
  {
    FG: "Diesel car",
    "Item Description": "Rear Suspension",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 1794,
    "Item unit cost": 1794,
  },
  {
    FG: "Diesel car",
    "Item Description": "Steering System",
    "Item Type": "SFG",
    Quantity: 1,
    "Item Cost": 3,
    "Item unit cost": 3,
  },
  {
    FG: "Diesel car",
    "Item Description": "Steering Wheel",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 696,
    "Item unit cost": 696,
  },
  {
    FG: "Diesel car",
    "Item Description": "Steering Column",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 2031,
    "Item unit cost": 2031,
  },
  {
    FG: "Diesel car",
    "Item Description": "Body",
    "Item Type": "SFG",
    Quantity: 1,
    "Item Cost": 7,
    "Item unit cost": 7,
  },
  {
    FG: "Diesel car",
    "Item Description": "Body Shell",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 1079,
    "Item unit cost": 1079,
  },
  {
    FG: "Diesel car",
    "Item Description": "Doors",
    "Item Type": "RM",
    Quantity: 4,
    "Item Cost": 9360,
    "Item unit cost": 2340,
  },
  {
    FG: "Diesel car",
    "Item Description": "Windows",
    "Item Type": "RM",
    Quantity: 6,
    "Item Cost": 4752,
    "Item unit cost": 792,
  },
  {
    FG: "Diesel car",
    "Item Description": "Powertrain",
    "Item Type": "SFG",
    Quantity: 1,
    "Item Cost": 7,
    "Item unit cost": 7,
  },
  {
    FG: "Diesel car",
    "Item Description": "Diesel Engine",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 1582,
    "Item unit cost": 1582,
  },
  {
    FG: "Diesel car",
    "Item Description": "Fuel Tank",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 1373,
    "Item unit cost": 1373,
  },
  {
    FG: "Diesel car",
    "Item Description": "Transmission",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 360,
    "Item unit cost": 360,
  },
  {
    FG: "Diesel car",
    "Item Description": "Drive Shafts",
    "Item Type": "RM",
    Quantity: 2,
    "Item Cost": 2496,
    "Item unit cost": 1248,
  },
  {
    FG: "Diesel car",
    "Item Description": "Electrical System",
    "Item Type": "SFG",
    Quantity: 1,
    "Item Cost": 3,
    "Item unit cost": 3,
  },
  {
    FG: "Diesel car",
    "Item Description": "Wiring Harness",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 496,
    "Item unit cost": 496,
  },
  {
    FG: "Diesel car",
    "Item Description": "Control Unit",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 1234,
    "Item unit cost": 1234,
  },
  {
    FG: "Diesel car",
    "Item Description": "Display Panel",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 141,
    "Item unit cost": 141,
  },
  {
    FG: "Diesel car",
    "Item Description": "Infotainment System",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 103,
    "Item unit cost": 103,
  },
  {
    FG: "Diesel car",
    "Item Description": "Interior",
    "Item Type": "SFG",
    Quantity: 1,
    "Item Cost": 1,
    "Item unit cost": 1,
  },
  {
    FG: "Diesel car",
    "Item Description": "Seats",
    "Item Type": "RM",
    Quantity: 5,
    "Item Cost": 4185,
    "Item unit cost": 837,
  },
  {
    FG: "Diesel car",
    "Item Description": "Dashboard",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 1680,
    "Item unit cost": 1680,
  },
  {
    FG: "Diesel car",
    "Item Description": "Console",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 1593,
    "Item unit cost": 1593,
  },
  {
    FG: "Diesel car",
    "Item Description": "Climate Control System",
    "Item Type": "SFG",
    Quantity: 1,
    "Item Cost": 5,
    "Item unit cost": 5,
  },
  {
    FG: "Diesel car",
    "Item Description": "Air Conditioning Unit",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 782,
    "Item unit cost": 782,
  },
  {
    FG: "Diesel car",
    "Item Description": "Heater",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 2205,
    "Item unit cost": 2205,
  },
  {
    FG: "Diesel car",
    "Item Description": "Braking System",
    "Item Type": "SFG",
    Quantity: 1,
    "Item Cost": 4,
    "Item unit cost": 4,
  },
  {
    FG: "Diesel car",
    "Item Description": "Front Brakes",
    "Item Type": "RM",
    Quantity: 2,
    "Item Cost": 3524,
    "Item unit cost": 1762,
  },
  {
    FG: "Diesel car",
    "Item Description": "Rear Brakes",
    "Item Type": "RM",
    Quantity: 2,
    "Item Cost": 1488,
    "Item unit cost": 744,
  },
  {
    FG: "Mountain Bike",
    "Item Description": "Mountain Bike",
    "Item Type": "FG",
    Quantity: 1,
    "Item Cost": 78924,
    "Item unit cost": 78924,
  },
  {
    FG: "Mountain Bike",
    "Item Description": "Frame",
    "Item Type": "SFG",
    Quantity: 1,
    "Item Cost": 2334,
    "Item unit cost": 2334,
  },
  {
    FG: "Mountain Bike",
    "Item Description": "Frame Body",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 571,
    "Item unit cost": 571,
  },
  {
    FG: "Mountain Bike",
    "Item Description": "Seat",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 493,
    "Item unit cost": 493,
  },
  {
    FG: "Mountain Bike",
    "Item Description": "Fork",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 2294,
    "Item unit cost": 2294,
  },
  {
    FG: "Mountain Bike",
    "Item Description": "Handlebar",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 382,
    "Item unit cost": 382,
  },
  {
    FG: "Mountain Bike",
    "Item Description": "Wheel Set",
    "Item Type": "SFG",
    Quantity: 2,
    "Item Cost": 20,
    "Item unit cost": 10,
  },
  {
    FG: "Mountain Bike",
    "Item Description": "Front Wheel",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 2308,
    "Item unit cost": 2308,
  },
  {
    FG: "Mountain Bike",
    "Item Description": "Rear Wheel",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 427,
    "Item unit cost": 427,
  },
  {
    FG: "Mountain Bike",
    "Item Description": "Drive System",
    "Item Type": "SFG",
    Quantity: 1,
    "Item Cost": 9,
    "Item unit cost": 9,
  },
  {
    FG: "Mountain Bike",
    "Item Description": "Chain",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 1821,
    "Item unit cost": 1821,
  },
  {
    FG: "Mountain Bike",
    "Item Description": "Pedals",
    "Item Type": "RM",
    Quantity: 2,
    "Item Cost": 1910,
    "Item unit cost": 955,
  },
  {
    FG: "Mountain Bike",
    "Item Description": "Crankset",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 2291,
    "Item unit cost": 2291,
  },
  {
    FG: "Mountain Bike",
    "Item Description": "Derailleur",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 2304,
    "Item unit cost": 2304,
  },
  {
    FG: "Mountain Bike",
    "Item Description": "Braking System",
    "Item Type": "SFG",
    Quantity: 1,
    "Item Cost": 5,
    "Item unit cost": 5,
  },
  {
    FG: "Mountain Bike",
    "Item Description": "Front Brake",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 287,
    "Item unit cost": 287,
  },
  {
    FG: "Mountain Bike",
    "Item Description": "Rear Brake",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 234,
    "Item unit cost": 234,
  },
  {
    FG: "Mountain Bike",
    "Item Description": "Suspension System",
    "Item Type": "SFG",
    Quantity: 1,
    "Item Cost": 5,
    "Item unit cost": 5,
  },
  {
    FG: "Mountain Bike",
    "Item Description": "Front Suspension",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 1582,
    "Item unit cost": 1582,
  },
  {
    FG: "Mountain Bike",
    "Item Description": "Rear Suspension",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 728,
    "Item unit cost": 728,
  },
  {
    FG: "Mountain Bike",
    "Item Description": "Gear System",
    "Item Type": "SFG",
    Quantity: 1,
    "Item Cost": 6,
    "Item unit cost": 6,
  },
  {
    FG: "Mountain Bike",
    "Item Description": "Gear Shifters",
    "Item Type": "RM",
    Quantity: 2,
    "Item Cost": 2520,
    "Item unit cost": 1260,
  },
  {
    FG: "Mountain Bike",
    "Item Description": "Cassette",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 2299,
    "Item unit cost": 2299,
  },
  {
    FG: "Mountain Bike",
    "Item Description": "Accessories",
    "Item Type": "SFG",
    Quantity: 1,
    "Item Cost": 2,
    "Item unit cost": 2,
  },
  {
    FG: "Mountain Bike",
    "Item Description": "Bottle Holder",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 303,
    "Item unit cost": 303,
  },
  {
    FG: "Mountain Bike",
    "Item Description": "Kickstand",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 1279,
    "Item unit cost": 1279,
  },
  {
    FG: "Mountain Bike",
    "Item Description": "Bell",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 2446,
    "Item unit cost": 2446,
  },
  {
    FG: "3-Wheeler Rickshaw",
    "Item Description": "3-Wheeler Rickshaw",
    "Item Type": "FG",
    Quantity: 1,
    "Item Cost": 99872,
    "Item unit cost": 99872,
  },
  {
    FG: "3-Wheeler Rickshaw",
    "Item Description": "Chassis",
    "Item Type": "SFG",
    Quantity: 1,
    "Item Cost": 8,
    "Item unit cost": 8,
  },
  {
    FG: "3-Wheeler Rickshaw",
    "Item Description": "Frame",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 1281,
    "Item unit cost": 1281,
  },
  {
    FG: "3-Wheeler Rickshaw",
    "Item Description": "Suspension System",
    "Item Type": "SFG",
    Quantity: 1,
    "Item Cost": 9,
    "Item unit cost": 9,
  },
  {
    FG: "3-Wheeler Rickshaw",
    "Item Description": "Front Suspension",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 1280,
    "Item unit cost": 1280,
  },
  {
    FG: "3-Wheeler Rickshaw",
    "Item Description": "Rear Suspension",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 1699,
    "Item unit cost": 1699,
  },
  {
    FG: "3-Wheeler Rickshaw",
    "Item Description": "Steering System",
    "Item Type": "SFG",
    Quantity: 1,
    "Item Cost": 1,
    "Item unit cost": 1,
  },
  {
    FG: "3-Wheeler Rickshaw",
    "Item Description": "Handlebar",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 985,
    "Item unit cost": 985,
  },
  {
    FG: "3-Wheeler Rickshaw",
    "Item Description": "Steering Column",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 2367,
    "Item unit cost": 2367,
  },
  {
    FG: "3-Wheeler Rickshaw",
    "Item Description": "Body",
    "Item Type": "SFG",
    Quantity: 1,
    "Item Cost": 10,
    "Item unit cost": 10,
  },
  {
    FG: "3-Wheeler Rickshaw",
    "Item Description": "Body Shell",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 1510,
    "Item unit cost": 1510,
  },
  {
    FG: "3-Wheeler Rickshaw",
    "Item Description": "Doors",
    "Item Type": "RM",
    Quantity: 2,
    "Item Cost": 2730,
    "Item unit cost": 1365,
  },
  {
    FG: "3-Wheeler Rickshaw",
    "Item Description": "Windows",
    "Item Type": "RM",
    Quantity: 4,
    "Item Cost": 7356,
    "Item unit cost": 1839,
  },
  {
    FG: "3-Wheeler Rickshaw",
    "Item Description": "Powertrain",
    "Item Type": "SFG",
    Quantity: 1,
    "Item Cost": 10,
    "Item unit cost": 10,
  },
  {
    FG: "3-Wheeler Rickshaw",
    "Item Description": "Engine",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 1949,
    "Item unit cost": 1949,
  },
  {
    FG: "3-Wheeler Rickshaw",
    "Item Description": "Fuel Tank",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 1278,
    "Item unit cost": 1278,
  },
  {
    FG: "3-Wheeler Rickshaw",
    "Item Description": "Transmission",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 380,
    "Item unit cost": 380,
  },
  {
    FG: "3-Wheeler Rickshaw",
    "Item Description": "Drive Shaft",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 491,
    "Item unit cost": 491,
  },
  {
    FG: "3-Wheeler Rickshaw",
    "Item Description": "Electrical System",
    "Item Type": "SFG",
    Quantity: 1,
    "Item Cost": 2,
    "Item unit cost": 2,
  },
  {
    FG: "3-Wheeler Rickshaw",
    "Item Description": "Wiring Harness",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 1247,
    "Item unit cost": 1247,
  },
  {
    FG: "3-Wheeler Rickshaw",
    "Item Description": "Control Unit",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 2216,
    "Item unit cost": 2216,
  },
  {
    FG: "3-Wheeler Rickshaw",
    "Item Description": "Headlights",
    "Item Type": "RM",
    Quantity: 2,
    "Item Cost": 4216,
    "Item unit cost": 2108,
  },
  {
    FG: "3-Wheeler Rickshaw",
    "Item Description": "Taillights",
    "Item Type": "RM",
    Quantity: 2,
    "Item Cost": 3092,
    "Item unit cost": 1546,
  },
  {
    FG: "3-Wheeler Rickshaw",
    "Item Description": "Battery",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 1674,
    "Item unit cost": 1674,
  },
  {
    FG: "3-Wheeler Rickshaw",
    "Item Description": "Interior",
    "Item Type": "SFG",
    Quantity: 1,
    "Item Cost": 7,
    "Item unit cost": 7,
  },
  {
    FG: "3-Wheeler Rickshaw",
    "Item Description": "Seats",
    "Item Type": "RM",
    Quantity: 3,
    "Item Cost": 4311,
    "Item unit cost": 1437,
  },
  {
    FG: "3-Wheeler Rickshaw",
    "Item Description": "Dashboard",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 352,
    "Item unit cost": 352,
  },
  {
    FG: "3-Wheeler Rickshaw",
    "Item Description": "Console",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 1487,
    "Item unit cost": 1487,
  },
  {
    FG: "3-Wheeler Rickshaw",
    "Item Description": "Climate Control System",
    "Item Type": "SFG",
    Quantity: 1,
    "Item Cost": 4,
    "Item unit cost": 4,
  },
  {
    FG: "3-Wheeler Rickshaw",
    "Item Description": "Air Conditioning Unit",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 1142,
    "Item unit cost": 1142,
  },
  {
    FG: "3-Wheeler Rickshaw",
    "Item Description": "Heater",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 1402,
    "Item unit cost": 1402,
  },
  {
    FG: "3-Wheeler Rickshaw",
    "Item Description": "Braking System",
    "Item Type": "SFG",
    Quantity: 1,
    "Item Cost": 3,
    "Item unit cost": 3,
  },
  {
    FG: "3-Wheeler Rickshaw",
    "Item Description": "Front Brakes",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 1796,
    "Item unit cost": 1796,
  },
  {
    FG: "3-Wheeler Rickshaw",
    "Item Description": "Rear Brakes",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 1156,
    "Item unit cost": 1156,
  },
  {
    FG: "3-Wheeler Rickshaw",
    "Item Description": "Wheels",
    "Item Type": "SFG",
    Quantity: 3,
    "Item Cost": 21,
    "Item unit cost": 7,
  },
  {
    FG: "3-Wheeler Rickshaw",
    "Item Description": "Front Wheel",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 2298,
    "Item unit cost": 2298,
  },
  {
    FG: "3-Wheeler Rickshaw",
    "Item Description": "Rear Wheel",
    "Item Type": "RM",
    Quantity: 2,
    "Item Cost": 2142,
    "Item unit cost": 1071,
  },
  {
    FG: "3-Wheeler Rickshaw",
    "Item Description": "Accessories",
    "Item Type": "SFG",
    Quantity: 1,
    "Item Cost": 10,
    "Item unit cost": 10,
  },
  {
    FG: "3-Wheeler Rickshaw",
    "Item Description": "Mirrors",
    "Item Type": "RM",
    Quantity: 2,
    "Item Cost": 2060,
    "Item unit cost": 1030,
  },
  {
    FG: "3-Wheeler Rickshaw",
    "Item Description": "Horn",
    "Item Type": "RM",
    Quantity: 1,
    "Item Cost": 738,
    "Item unit cost": 738,
  },
  {
    FG: "3-Wheeler Rickshaw",
    "Item Description": "Windshield Wipers",
    "Item Type": "RM",
    Quantity: 2,
    "Item Cost": 3758,
    "Item unit cost": 1879,
  },
];
