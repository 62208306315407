import { set } from "lodash/fp";
import React, { useState } from "react";
import Select, { components } from "react-select";
import { useSelector } from "react-redux";

const customStyles = {
  menu: (provided) => ({
    ...provided,
    zIndex: 11,
    // Add your custom styles here
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "unset" : "white", // Background color when selected
    color: state.isSelected ? "unset" : "inherit", // Text color when selected
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "unset" : "inherit", // Text color when selected
  }),
};

function ModifiedSelect({
  options,
  label,
  isMulti,
  setAllAppliedFilters,
  key,
}) {
  const [selectedItems, setSelectedItems] = useState([]);
  const appliedFilters = useSelector(
    (state) => state.filters.allAppliedFilters
  );
  const TickIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 512 512"
        id="tick"
      >
        <path d="M223.9 329.7c-2.4 2.4-5.8 4.4-8.8 4.4s-6.4-2.1-8.9-4.5l-56-56 17.8-17.8 47.2 47.2L340 177.3l17.5 18.1-133.6 134.3z" />
      </svg>
    );
  };
  const CustomOption = ({ children, ...props }) => {
    console.log({ children, props11: props });
    return (
      <components.Option {...props}>
        {/* <img src={...} /> */}

        {props.isSelected ? (
          <div style={{ display: "flex" }}>
            <span style={{ marginRight: "20px" }}>{children}</span> <TickIcon />
          </div>
        ) : (
          children
        )}
      </components.Option>
    );
  };
  const handleChange = (selectedOptions) => {
    setAllAppliedFilters({ ...appliedFilters, [key]: selectedItems });
    setSelectedItems(selectedOptions);
  };
  const valueLabel =
    selectedItems.length === 0 ? "" : `${selectedItems.length} items selected`;
  return (
    <div className="select-container">
      <label
        style={{
          backgroundColor: "white",
          position: "relative",
          zIndex: 10,
          top: "8px",
          padding: "5px",
        }}
        className="filter-legend"
      >
        {label}
      </label>

      <Select
        options={options}
        hideSelectedOptions={false}
        isSearchable={false}
        styles={customStyles}
        isMulti={isMulti}
        onChange={handleChange}
        components={{
          // Create a custom value container that displays the count
          ValueContainer: ({ children }) => {
            // console.log({ children });
            return isMulti ? (
              <div style={{ fontSize: "14px", marginLeft: "14px" }}>
                {valueLabel}
              </div>
            ) : (
              children
            );
          },
          Option: CustomOption,
        }}
      />
    </div>
  );
}

export default ModifiedSelect;
