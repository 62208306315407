import {
  BreadcrumbMaker,
  ApprovedMarker,
  ViewPageButton,
  ActionButton,
  DynamicRefsExample,
} from "./tableUtils";

const TestPage = () => {
  return (
    <>
      {BreadcrumbMaker("/snp/inventory", "h1", "h2", "h3")}
      <hr />
      {ViewPageButton(23, "/snp/inventory")}
      <hr />
      {ApprovedMarker()}
      <hr />
    </>
  );
};

export default TestPage;
