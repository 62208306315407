export const ExpandSvg = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9375 0H12.2486C11.8469 0 11.4322 0.257856 11.2671 0.656426C11.1022 1.05486 11.1924 1.50941 11.4975 1.81389L12.9525 3.29707L9.71889 6.53072C9.51143 6.73824 9.51143 7.07459 9.71889 7.28178C9.92634 7.4893 10.2628 7.4893 10.4699 7.28178L13.7033 4.04846L15.1868 5.53197C15.3912 5.73534 15.6609 5.84325 15.9368 5.84325C16.0738 5.84325 16.2128 5.81627 16.3446 5.7618C16.7443 5.59473 17 5.18301 17 4.75137V1.0625C17 0.475801 16.5252 0 15.9375 0ZM15.9375 4.75137L12.2188 1.0625C12.2156 1.0625 12.2188 1.0625 12.2188 1.0625H15.9375V4.75137ZM1.03262 17H4.72148C5.12324 17 5.53795 16.7421 5.70297 16.3436C5.86795 15.9451 5.77768 15.4906 5.47264 15.1861L3.98945 13.7029L7.22311 10.4693C7.43056 10.2618 7.43056 9.92541 7.22311 9.71822C7.01565 9.5107 6.67924 9.5107 6.47205 9.71822L3.23873 12.9515L1.81355 11.4684C1.60916 11.265 1.33941 11.1571 1.0635 11.1571C0.926533 11.1571 0.787512 11.1841 0.655762 11.2385C0.257324 11.4053 0 11.7871 0 12.2188V15.9375C0 16.5252 0.475801 17 1.03262 17ZM1.03262 12.2188L4.75137 15.9076C4.75137 15.9076 4.75448 15.9076 4.75137 15.9076L1.03262 15.9375V12.2188Z"
        fill="#929292"
      />
    </svg>
  );
};
