import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  rmpGroupPlanViewDetails: {},
};

const rmpGroupPlanViewSlice = createSlice({
  name: "rmpGroupPlanViewSlice",
  initialState,
  reducers: {
    setRmpGroupPlanViewDetails: (state, action) => {
      state.rmpGroupPlanViewDetails = action.payload;
    },
  },
});

export const { setRmpGroupPlanViewDetails } = rmpGroupPlanViewSlice.actions;
export default rmpGroupPlanViewSlice.reducer;
