import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import UploadModal from "../../../components/modal/UploadModal";
import { usePreprocessesQuery } from "../../../features/api/preprocessesSlice";
import { formatISODateToHumanReadable } from "../../../utils/utils";
import "../../Inventory/Preprocess/styles.css";
import { handleProcessType } from "./utils";
import { ppPreprocessData, ppPreprocessMetadata } from "./data";
import { DataTable } from "@/components/tanstack-table/data-table";
import { loadingColumns } from "@/pages/Inventory/Dashboard/Tables/columns";
import { PpPreprocessColumns } from "./columns";
import { DataTableToolbar } from "@/components/tanstack-table/data-table-toolbar";

const transformData = (arr, level) => {
  return arr.map((item) => ({
    process: item.process,
    processTypes: item.process_type.name,
    lastUpdatedBy: item.last_updated_by,
    lastUpdatedAt: formatISODateToHumanReadable(item.last_updated_at),
    preprocessAction: level === "meta-data" ? [...item.preprocess_action, "download"] : item.preprocess_action,
  }));
};

function PreprocessLanding() {
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [tableData, setTableData] = useState();
  const [selectedRow, setSelectedRow] = useState();
  const [isDownloading, setIsDownloading] = useState({});
  const [isUploading, setIsUploading] = useState({});
  const [level, setLevel] = useState("preprocesses");
  //uncomment this after api is ready
  //   const {
  //     data: preprocesses,
  //     isLoading: isLoadingPreprocesses,
  //     isSuccess: isSuccessPreprocesses,
  //   } = usePreprocessesQuery(undefined, {
  //     refetchOnMountOrArgChange: true,
  //     force: true, // This will bypass the cache
  //   });

  const location = useLocation();
  const path = location.pathname;
  let idArr = path.split("/");
  useEffect(() => {
    //uncomment this after api is ready
    // setTableData(
    //   preprocesses && preprocesses.results
    //     ? transformData(preprocesses.results)
    //     : []
    // );
    if (level === "preprocesses") {
      setTableData(transformData(ppPreprocessData, level));
    } else if (level === "meta-data") {
      setTableData(transformData(ppPreprocessMetadata, level));
    }
    let isDownloadingTemp = {};
    let isUploadingTemp = {};
    //uncomment this after api is ready
    // if (preprocesses && preprocesses.results) {
    //   preprocesses.results.forEach((process) => {
    //     isDownloadingTemp[process.process] = false;
    //     isUploadingTemp[process.process] = false;
    //   });
    // }

    if (ppPreprocessData) {
      ppPreprocessData.forEach((process) => {
        isDownloadingTemp[process.process] = false;
        isUploadingTemp[process.process] = false;
      });
    }
    setIsDownloading(isDownloadingTemp);
    setIsUploading(isUploadingTemp);
  }, [level, ppPreprocessData, ppPreprocessMetadata]);

  const renderPreprocessHeader = ({ table }) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {renderLevelButtons()}
        <DataTableToolbar table={table} />
      </div>
    );
  };

  const renderLevelButtons = () => {
    return (
      <div className="header-btns">
        <button
          onClick={() => setLevel("preprocesses")}
          className={
            level === "preprocesses"
              ? "header-level-button header-active-btn"
              : "header-level-button"
          }
        >
          Preprocesses
        </button>
        <button
          onClick={() => {
            setLevel("meta-data");
          }}
          className={
            level === "meta-data"
              ? "header-level-button header-active-btn"
              : "header-level-button"
          }
        >
          Meta Data
        </button>
      </div>
    );
  };

  return (
    tableData && (
      <>
        <div
          className="snp-table-container"
          style={{
            width: `calc( 100% - 20px)`,
            marginLeft: "10px",
            marginTop: "10px",
          }}
        >
          <UploadModal
            openModal={openUploadModal}
            setOpenModal={setOpenUploadModal}
            classType={handleProcessType(selectedRow)}
            uploadType="preprocess"
            selectedProcess={selectedRow}
            setIsUploading={setIsUploading}
            moduleType="pp"
          />
          <DataTable
            data={tableData}
            columns={PpPreprocessColumns(
              selectedRow,
              setSelectedRow,
              openUploadModal,
              setOpenUploadModal,
              isUploading,
              setIsUploading,
              isDownloading,
              setIsDownloading,
              idArr,
              level,
              "pp"
            )}
            canResizeColumns={true}
            CustomToolbar={renderPreprocessHeader}
            showSelectedColumnsCount={false}
          />
        </div>
      </>
    )
  );
}

export default PreprocessLanding;
