const ShowHideFiltersIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 128 128"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.57143 22.8577H123.429C125.955 22.8577 128 20.8131 128 18.5434C128 16.2737 125.943 13.7148 123.429 13.7148H4.57143C2.04457 13.7148 0 15.7606 0 18.0291C0 20.2977 2.04457 22.8577 4.57143 22.8577ZM105.143 59.4291H22.8571C20.3314 59.4291 18.2857 61.4863 18.2857 63.7434C18.2857 66.5148 20.3314 68.572 22.8571 68.572H105.143C107.67 68.572 109.714 66.5274 109.714 64.2577C109.714 61.4863 107.657 59.4291 105.143 59.4291ZM77.7143 105.143H50.2857C47.7589 105.143 45.7143 107.188 45.7143 109.458C45.7143 111.727 47.7714 114.286 50.2857 114.286H77.7143C80.2411 114.286 82.2857 112.242 82.2857 109.972C82.2857 107.702 80.2286 105.143 77.7143 105.143Z"
      fill="#3D445C"
    />
  </svg>
);

export const ShowHideFiltersIconClose = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 128 132"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9771 20.0918H4.57143C2.04457 20.0918 0 22.1375 0 24.4061C0 26.6747 2.04457 29.2347 4.57143 29.2347H18.815L11.9771 20.0918ZM46.1668 65.8061H22.8571C20.3314 65.8061 18.2857 67.8632 18.2857 70.1204C18.2857 72.8918 20.3314 74.9489 22.8571 74.9489H53.0047L46.1668 65.8061ZM76.6501 74.9489L69.8121 65.8061H105.143C107.657 65.8061 109.714 67.8632 109.714 70.6346C109.714 72.9044 107.67 74.9489 105.143 74.9489H76.6501ZM42.4604 29.2347L35.6225 20.0918H123.429C125.943 20.0918 128 22.6507 128 24.9204C128 27.1901 125.955 29.2347 123.429 29.2347H42.4604ZM50.2857 111.52H77.7143C80.2286 111.52 82.2857 114.079 82.2857 116.349C82.2857 118.619 80.2411 120.663 77.7143 120.663H50.2857C47.7714 120.663 45.7143 118.104 45.7143 115.835C45.7143 113.565 47.7589 111.52 50.2857 111.52Z"
      fill="#3D445C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.6034 2.69515C11.5936 1.20666 14.4137 1.6134 15.9022 3.60363L104.902 122.604C106.391 124.594 105.984 127.414 103.994 128.902C102.003 130.391 99.1834 129.984 97.6949 127.994L8.69492 8.99394C7.20643 7.0037 7.61317 4.18364 9.6034 2.69515Z"
      fill="#3D445C"
    />
  </svg>
);

export default ShowHideFiltersIcon;
