import {
  ArrowDownIcon,
  ArrowUpIcon,
  CaretSortIcon,
  EyeNoneIcon,
} from "@radix-ui/react-icons";
import { useState } from "react";
import { Filter } from "lucide-react";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { ServerDataTableFacetedFilter } from "./server-data-table-faceted-filter";
import { forwardRef, useEffect, useRef } from "react";

export function ServerDataTableColumnHeader({
  column,
  title,
  className,
  options,
  header,
  orderingColumns,
  filteringColumns,
  filterOptionsData,
  handleSortingFiltering,
}) {
  console.log("filtering", filteringColumns);
  const [columnFilterData, setColumnFilterData] = useState([]);
  if (!column.getCanSort()) {
    return <div className={cn(className)}>{title}</div>;
  }

  const [order, setOrder] = useState("");
  const [filterValues, setFilterValues] = useState(new Set([]));
  const selectedOrder = useRef();

  const updateFilteringData = (filterValues) => {
    setFilterValues(filterValues);
    handleSortingFiltering(column.id, filterValues, order);
  };

  useEffect(() => {
    if (filterOptionsData) {
      if (filterOptionsData.length > 0 && filteringColumns) {
        let arr1 = {};
        filterOptionsData.forEach((item) => {
          let arr = Object.keys(item);
          if (arr[0] === column?.id) {
            arr1 = item;
          }
        });

        setColumnFilterData(arr1[`${column.id}`]);
      }
    }
  }, [filterOptionsData]);

  return (
    <div
      className={cn("flex items-center justify-center", className)}
      // style={{ width: `${header.getSize()}` }}
    >
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            size="sm"
            className="ml-3 h-full data-[state=open]:bg-[#b0b0b0] px-1 lg:px-1 hover:bg-[#b0b0b0]"
          >
            <span className="font-semibold font-sans lg:text-[1.4rem] antialiased text-[15px]">
              {title}
            </span>
            {title === "" ? null : selectedOrder.current === "desc" ? (
              <ArrowDownIcon className="ml-2 h-4 w-4 lg:h-6 lg:w-6 lg:ml-3" />
            ) : selectedOrder.current === "asc" ? (
              <ArrowUpIcon className="ml-2 h-4 w-4 lg:h-6 lg:w-6 lg:ml-3" />
            ) : orderingColumns ? (
              <CaretSortIcon className="ml-2 h-4 w-4 lg:h-6 lg:w-6 lg:ml-3" />
            ) : null}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="start" className="lg:h-auto lg:w-[120px]">
          <DropdownMenuItem
            onClick={() => {
              selectedOrder.current = "asc";
              handleSortingFiltering(column.id, filterValues, "asc");
            }}
            className="lg:text-xl justify-start lg:ml-3"
          >
            <ArrowUpIcon className="mr-2 h-3.5 w-3.5  lg:mr-3 lg:h-5 lg:w-5" />
            Asc
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => {
              selectedOrder.current = "desc";
              handleSortingFiltering(column.id, filterValues, "desc");
            }}
            className="lg:text-xl justify-start lg:ml-3"
          >
            <ArrowDownIcon className="mr-2 h-3.5 w-3.5  lg:mr-3 lg:h-5 lg:w-5" />
            Desc
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            onClick={() => column.toggleVisibility(false)}
            className="lg:text-xl justify-start lg:ml-3"
          >
            <EyeNoneIcon className="mr-2 h-3.5 w-3.5  lg:mr-3 lg:h-5 lg:w-5" />
            Hide
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      {title !== "" && filteringColumns && (
        <ServerDataTableFacetedFilter
          column={column}
          title={title}
          options={options}
          // filterOptionsData = {filterOptionsData.filter(item => {
          //   let arr = Object.keys(item);
          //   return arr[0] === column;
          // })}
          filterOptionsData={columnFilterData}
          updateFilteringData={updateFilteringData}
        />
      )}
    </div>
  );
}
