import { BrowserRouter } from "react-router-dom";
import Routing from "./routes/Routing";
import { createBrowserHistory } from "history";
import { Bounce, ToastContainer } from "react-toastify";

const defaultHistory = createBrowserHistory();

function App() {
  return (
    <div className="App">
      <BrowserRouter history={defaultHistory}>
        <Routing />
        <ToastContainer
          className="toast-position"
          position="bottom-right"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          transition={Bounce}
          role="alert"
        />
      </BrowserRouter>
    </div>
  );
}

export default App;
