import {
  Box,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  stepConnectorClasses,
  styled,
} from "@mui/material";

const CustomConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
  },
  [`&.${stepConnectorClasses.active}`]: {
    "& .MuiStepConnector-line": {
      borderColor: "#51BD61",
      borderTopWidth: "3px",
      color: "rgba(28, 29, 31, 0.1)",
      animationName: "rotate",
      animationDuration: "0.7s",
      animationDirection: "reverse",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    "& .MuiStepConnector-line": {
      borderColor: "#51BD61",
      borderTopWidth: "3px",
    },
  },
  [`&.${stepConnectorClasses.line}`]: {
    borderWidth: 10,
    borderRadius: 1,
  },
}));

const CustomStepper = ({ step }) => {
  const steps = ["Select Orders", "Entity Mapping", "Confirmation"];
  return (
    <Box sx={{ width: "100%" }}>
      <Stepper
        activeStep={step}
        alternativeLabel
        connector={<CustomConnector />}
        sx={{
          backgroundColor: "transparent",
          padding: "10px",
          "& .MuiStepLabel-label": {
            color: "#1C1D1F",
            fontWeight: "550",
            fontSize: "11px",
            fontFamily: "Inter",
          },
          "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
            marginTop: "4px",
          },
          "& .MuiStepIcon-root": {
            height: "25px",
            width: "25px",
            fontSize: "32px",
            color: "rgba(28, 29, 31, 0.1)",

            "&.Mui-completed": {
              color: "#51BD61",
            },
            "& .MuiStepIcon-text": {
              fill: "#a3a7a8",
              fontSize: "12px",
            },
            "&.Mui-active": {
              color: "rgba(28, 29, 31, 0.1)",
            },
          },
        }}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default CustomStepper;
