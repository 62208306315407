import React, { useEffect, useRef } from "react";
import G6 from "@antv/g6";
import {
  Rect,
  Text,
  Circle,
  Image,
  Group,
  createNodeFromReact,
} from "@antv/g6-react-node";

const Card = ({ cfg }) => {
  const { collapsed = false } = cfg;
  const id = cfg?.id;
  const label = cfg?.label ?? "No label";

  console.log({ id });

  return (
    <Group draggable>
      <Rect
        style={{
          // width: 400,
          height: "auto",
          ...(id === "root"
            ? { stroke: "#00C01E", fill: "#F1FFF3" }
            : { stroke: "#C9C9C9", fill: "#FFFFFF" }),
          // shadowColor: "#eee",
          // shadowBlur: 30,F1FFF3
          radius: [4],
          justifyContent: "center",
          padding: [6, 5],
          flexDirection: "row",
          margin: [0, 5],
          alignItems: "center",
        }}
        draggable
      >
        <Text
          style={{
            fill: "#000",
            fontSize: 12,
            margin: [0, 5, 0, 0],
          }}
        >
          {label}
        </Text>
        <Group>
          <Rect
            style={{
              flexDirection: "row",
              stroke: "#929292",
              radius: [2],
              fill: "#EFEFEF",
              // alignItems: "center",
            }}
          >
            <Rect style={{ padding: [5, 7] }}>
              <Text style={{ fill: "#000", fontSize: 10 }}>SFG</Text>
            </Rect>
            <Rect style={{ width: 1, fill: "#929292" }} />
            <Rect style={{ padding: [5, 7] }}>
              <Text style={{ fill: "#000", fontSize: 10 }}>2</Text>
            </Rect>
          </Rect>
        </Group>
      </Rect>
    </Group>
  );
};

const TreeChart = ({ data, levels }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const COLLAPSE_ICON = (x, y, r) => [
      ["M", x - r, y - r],
      ["a", r, r, 0, 1, 0, r * 2, 0],
      ["a", r, r, 0, 1, 0, -r * 2, 0],
      ["M", x + 2 - r, y - r],
      ["L", x + r - 2, y - r],
    ];

    const EXPAND_ICON = (x, y, r) => [
      ["M", x - r, y - r],
      ["a", r, r, 0, 1, 0, r * 2, 0],
      ["a", r, r, 0, 1, 0, -r * 2, 0],
      ["M", x + 2 - r, y - r],
      ["L", x + r - 2, y - r],
      ["M", x, y - 2 * r + 2],
      ["L", x, y - 2],
    ];

    // const data = {
    //   id: "root",
    //   label: "Electric Bike - Model E1",
    //   children: [
    //     {
    //       id: "c1",
    //       label: "Frame Assembly",
    //       children: [
    //         {
    //           id: "c1-1",
    //           label: "Frame",
    //           children: [
    //             {
    //               id: "c1-1-1",
    //               label: "Main Frame",
    //               children: [
    //                 {
    //                   id: "c1-1-1-1",
    //                   label: "Top Tube",
    //                   children: [
    //                     {
    //                       id: "c1-1-1-1-1",
    //                       label: "Tube Material",
    //                       children: [
    //                         {
    //                           id: "c1-1-1-1-1-1",
    //                           label: "Aluminum Alloy",
    //                           children: [
    //                             {
    //                               id: "c1-1-1-1-1-1-1",
    //                               label: "Alloy Grade",
    //                               quantity: 1,
    //                             },
    //                           ],
    //                         },
    //                       ],
    //                     },
    //                   ],
    //                 },
    //                 {
    //                   id: "c1-1-1-2",
    //                   label: "Down Tube",
    //                   quantity: 1,
    //                 },
    //                 {
    //                   id: "c1-1-1-3",
    //                   label: "Seat Tube",
    //                   quantity: 1,
    //                 },
    //                 {
    //                   id: "c1-1-1-4",
    //                   label: "Head Tube",
    //                   quantity: 1,
    //                 },
    //               ],
    //             },
    //             {
    //               id: "c1-1-2",
    //               label: "Battery Mount",
    //               children: [
    //                 {
    //                   id: "c1-1-2-1",
    //                   label: "Mount Bracket",
    //                   quantity: 1,
    //                 },
    //                 {
    //                   id: "c1-1-2-2",
    //                   label: "Locking Mechanism",
    //                   quantity: 1,
    //                 },
    //               ],
    //             },
    //             {
    //               id: "c1-1-3",
    //               label: "Motor Mount",
    //               children: [
    //                 {
    //                   id: "c1-1-3-1",
    //                   label: "Mounting Plate",
    //                   quantity: 1,
    //                 },
    //                 {
    //                   id: "c1-1-3-2",
    //                   label: "Bolts",
    //                   quantity: 4,
    //                 },
    //               ],
    //             },
    //           ],
    //         },
    //         {
    //           id: "c1-2",
    //           label: "Fork",
    //           children: [
    //             {
    //               id: "c1-2-1",
    //               label: "Fork Stanchions",
    //               children: [
    //                 {
    //                   id: "c1-2-1-1",
    //                   label: "Stanchion Tube",
    //                   children: [
    //                     {
    //                       id: "c1-2-1-1-1",
    //                       label: "Tube Material",
    //                       children: [
    //                         {
    //                           id: "c1-2-1-1-1-1",
    //                           label: "Aluminum Alloy",
    //                           children: [
    //                             {
    //                               id: "c1-2-1-1-1-1-1",
    //                               label: "Alloy Grade",
    //                               quantity: 2,
    //                             },
    //                           ],
    //                         },
    //                       ],
    //                     },
    //                   ],
    //                 },
    //                 {
    //                   id: "c1-2-1-2",
    //                   label: "Stanchion Bushings",
    //                   quantity: 4,
    //                 },
    //               ],
    //             },
    //             {
    //               id: "c1-2-2",
    //               label: "Fork Crown",
    //               quantity: 1,
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //     {
    //       id: "c2",
    //       label: "Electrical Components",
    //       children: [
    //         {
    //           id: "c2-1",
    //           label: "Electric Motor",
    //           children: [
    //             {
    //               id: "c2-1-1",
    //               label: "Motor Body",
    //               quantity: 1,
    //             },
    //             {
    //               id: "c2-1-2",
    //               label: "Rotor",
    //               quantity: 1,
    //             },
    //             {
    //               id: "c2-1-3",
    //               label: "Stator",
    //               quantity: 1,
    //             },
    //             {
    //               id: "c2-1-4",
    //               label: "Bearings",
    //               children: [
    //                 {
    //                   id: "c2-1-4-1",
    //                   label: "Front Bearing",
    //                   children: [
    //                     {
    //                       id: "c2-1-4-1-1",
    //                       label: "Bearing Type",
    //                       children: [
    //                         {
    //                           id: "c2-1-4-1-1-1",
    //                           label: "Sealed Cartridge",
    //                           children: [
    //                             {
    //                               id: "c2-1-4-1-1-1-1",
    //                               label: "Bearing Size",
    //                               quantity: 1,
    //                             },
    //                           ],
    //                         },
    //                       ],
    //                     },
    //                   ],
    //                 },
    //                 {
    //                   id: "c2-1-4-2",
    //                   label: "Rear Bearing",
    //                   quantity: 1,
    //                 },
    //               ],
    //             },
    //           ],
    //         },
    //         {
    //           id: "c2-2",
    //           label: "Battery Pack",
    //           children: [
    //             {
    //               id: "c2-2-1",
    //               label: "Battery Cells",
    //               children: [
    //                 {
    //                   id: "c2-2-1-1",
    //                   label: "Cell Type",
    //                   children: [
    //                     {
    //                       id: "c2-2-1-1-1",
    //                       label: "Lithium-ion",
    //                       children: [
    //                         {
    //                           id: "c2-2-1-1-1-1",
    //                           label: "Cell Chemistry",
    //                           children: [
    //                             {
    //                               id: "c2-2-1-1-1-1-1",
    //                               label: "NMC",
    //                               quantity: 40,
    //                             },
    //                           ],
    //                         },
    //                       ],
    //                     },
    //                   ],
    //                 },
    //               ],
    //             },
    //             {
    //               id: "c2-2-2",
    //               label: "Battery Management System",
    //               quantity: 1,
    //             },
    //           ],
    //         },
    //         {
    //           id: "c2-3",
    //           label: "Controller",
    //           quantity: 1,
    //         },
    //         {
    //           id: "c2-4",
    //           label: "Display",
    //           quantity: 1,
    //         },
    //         {
    //           id: "c2-5",
    //           label: "Wiring Harness",
    //           quantity: 1,
    //         },
    //       ],
    //     },
    //   ],
    // };

    // Root node specific styles
    const rootNodeStyle = {
      fill: "#F1FFF3",
      stroke: "#00C01E",
    };

    // Non-root node specific styles
    const nonRootNodeStyle = {
      fill: "#FFFFFF",
      stroke: "#C9C9C9",
    };

    G6.Util.traverseTree(data, (d) => {
      if (d.id === "root") {
        d.style = rootNodeStyle;
      } else {
        d.style = nonRootNodeStyle;
      }
      return true;
    });

    G6.registerNode("icon-node", createNodeFromReact(Card));

    G6.registerEdge("flow-line", {
      draw(cfg, group) {
        const startPoint = cfg.startPoint;
        const endPoint = cfg.endPoint;

        const { style } = cfg;
        const shape = group.addShape("path", {
          attrs: {
            stroke: "#53B430",
            endArrow: style.endArrow,
            startArrow: style.startArrow,
            path: [
              ["M", startPoint.x, startPoint.y],
              ["L", startPoint.x, (startPoint.y + endPoint.y) / 2],
              ["L", endPoint.x, (startPoint.y + endPoint.y) / 2],
              ["L", endPoint.x, endPoint.y],
            ],
          },
        });

        return shape;
      },
    });

    const defaultStateStyles = {
      hover: {
        stroke: "#1890ff",
        lineWidth: 2,
      },
    };

    const defaultNodeStyle = {
      fill: "#91d5ff",
      stroke: "#40a9ff",
      radius: 5,
    };

    const defaultEdgeStyle = {
      stroke: "#53B430",
      endArrow: {
        path: G6.Arrow.vee(5, 5, 20),
        d: 20,
      },
      // startArrow: true,
    };

    const defaultLayout = {
      type: "compactBox",
      direction: "TB",
      getId: function getId(d) {
        return d.id;
      },
      // getHeight: function getHeight() {
      //   return 16;
      // },
      getWidth: function getWidth() {
        return 16;
      },
      // getVGap: function getVGap() {
      //   return 40;
      // },
      getHGap: function getHGap() {
        return 70;
      },
    };

    const defaultLabelCfg = {
      style: {
        fill: "#000",
        fontSize: 12,
      },
    };

    const container = containerRef.current;
    const width = container.scrollWidth;
    const height = container.scrollHeight || 500;

    const minimap = new G6.Minimap({
      size: [150, 100],
    });
    const graph = new G6.TreeGraph({
      container: container,
      width,
      height,
      linkCenter: true,
      // plugins: [minimap],
      modes: {
        // default: ["drag-canvas", "zoom-canvas"],
        default: ["drag-canvas"],
      },
      defaultNode: {
        type: "icon-node",
        // size: [120, 40],
        style: defaultNodeStyle,
        labelCfg: defaultLabelCfg,
      },
      defaultEdge: {
        type: "flow-line",
        style: defaultEdgeStyle,
      },
      nodeStateStyles: defaultStateStyles,
      edgeStateStyles: defaultStateStyles,
      layout: defaultLayout,
      fitCenter: true,
      // fitView: true,
      background: {
        fill: "#000", // Change this to your desired background color
      },
      fitViewPadding: [10, 0, 0, 0],
    });

    graph.data(data);
    graph.render();
    const zoomLevel = 1.15;
    // Set initial zoom level
    graph.zoom(zoomLevel);

    // Get the graph's container size
    const containerWidth = graph.get("width");
    const containerHeight = graph.get("height");

    // Get the bounding box of the graph
    const rootNode = graph.findById("root");
    const bbox = rootNode.getBBox();

    // Calculate the required translation to center the graph horizontally
    const dx =
      (containerWidth - bbox.width * zoomLevel) / 2 - bbox.minX * zoomLevel;
    const dy = -bbox.minY * zoomLevel + 10; // To touch the top

    // Apply the translation to center the graph horizontally and move it to the top
    graph.translate(dx, dy);

    graph.on("node:mouseenter", (evt) => {
      const { item } = evt;
      graph.setItemState(item, "hover", true);
    });

    graph.on("node:mouseleave", (evt) => {
      const { item } = evt;
      graph.setItemState(item, "hover", false);
    });

    graph.on("node:click", (evt) => {
      const { item, target } = evt;
      const targetType = target.get("type");
      const name = target.get("name");

      if (targetType === "marker") {
        const model = item.getModel();
        if (name === "add-item") {
          if (!model.children) {
            model.children = [];
          }
          const id = `n-${Math.random()}`;
          model.children.push({
            id,
            label: id.substr(0, 8),
            // leftIcon: {
            //   style: {
            //     fill: "#e6fffb",
            //     stroke: "#e6fffb",
            //   },
            //   img: "https://gw.alipayobjects.com/mdn/rms_f8c6a0/afts/img/A*Q_FQT6nwEC8AAAAAAAAAAABkARQnAQ",
            // },
          });
          graph.updateChild(model, model.id);
        } else if (name === "remove-item") {
          graph.removeChild(model.id);
        }
      }
    });

    const handleResize = () => {
      if (!graph || graph.get("destroyed")) return;
      if (!container || !container.scrollWidth || !container.scrollHeight)
        return;
      graph.changeSize(container.scrollWidth, container.scrollHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      if (graph && !graph.get("destroyed")) {
        graph.destroy();
      }
    };
  }, []);

  return (
    <>
      <div className="flex border-solid border-2 border-[#DDDDDD] rounded-[5px] w-full h-[638px]">
        <div id="levels-container" className="w-[50px] rounded-l-[5px]">
          {Array(levels)
            .fill(1)
            .map((item, index) => {
              const isEven = index % 2 == 0;
              return (
                <div className="flex" key={index}>
                  <div
                    key={index}
                    className="w-[5px] h-[79.68px] mr-[5px]"
                    style={{
                      ...(isEven
                        ? { backgroundColor: "#1C1D1F" }
                        : { backgroundColor: "#E94F1C" }),
                      ...(index == 0 ? { borderTopLeftRadius: "5px" } : {}),
                      ...(index == 7 ? { borderBottomLeftRadius: "5px" } : {}),
                    }}
                  ></div>
                  <div className="flex font-semibold justify-center items-center">
                    <span>L{index}</span>
                  </div>
                </div>
              );
            })}
        </div>
        <div
          id="container"
          ref={containerRef}
          style={{
            width: "100%",
            height: "100%",
            fontFamily: "Inter",
            // border: "1px solid red",
          }}
        ></div>
      </div>
    </>
  );
};

export default TreeChart;
