import { useEffect, useState } from "react";
import Select from "react-select";
import "./jsConfigScreen.scss";
import {
  useConfigurationRulesQuery,
  useGroupRulesQuery,
  useJobCategoryQuery,
  useJobTypeQuery,
  useRunAdhocJobMutation,
} from "../../features/api/schedularSlice";
import { handleRunAdhocJob } from "./utils";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoaderSpinner } from "../tableUtils/tableUtils";
import { CircularIndefiniteSpinner } from "../loading/LoadingIndex";
export const BreadcrumbTemp = () => (
  <svg
    width="5"
    height="9"
    viewBox="0 0 5 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Vector 128"
      d="M0.5 1L4 4.5L0.5 8"
      stroke="#929292"
      strokeLinecap="round"
    />
  </svg>
);
import PercentageSplit from "./percentageSplit";
import { handleApiArrayResponse } from "../../utils/apiUtils";

const JobSchedularConfigScreen = () => {
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const location = useLocation();
  const baseURL = process.env.REACT_APP_SCAI_API_ENDPOINT;

  const configEditProps = useSelector(
    (state) => state.configuration.configurationProps
  );
  const configTableData2 = useSelector(
    (state) => state.configuration.configTabledata
  );
  const path = location.pathname;
  const isJobSchedulerConfig = location.state?.isJobSchedulerConfig;
  const urlState = location.state;
  const mode = useSelector((state) => state.configuration.configurationMode);
  const [rules, setRules] = useState([]);
  const allRules = mode === "create " ? rules : configEditProps.allRules;
  let idArr = path.split("/");

  let editId = Number(idArr[idArr.length - 1]);
  const [configName, setConfigName] = useState("");
  const [jobType, setJobType] = useState(undefined);
  const [jobCategory, setJobCategory] = useState(undefined);
  const [jobTypeOptions, setJobTypeOptions] = useState();
  const [jobCategoryOptions, setJobCategoryOptions] = useState();
  const [selectedRules, setSelectedRules] = useState([]);
  const [ruleLabels, setRuleLabels] = useState({});
  const [loading, setLoading] = useState(false);
  const [refetchCount, setRefetchCount] = useState(0);
  const [isSalesOrderChecked, setIsSalesOrderChecked] = useState(false);
  const rulesKeys = rules && Object.keys(rules);
  const distributionChangeHandler = (index, ruleIndex, valueArr) => {
    return selectedRules;
  };

  const {
    data: configurationTypeData,
    isSuccess: isSuccessConfigurationType,
    isLoading: configurationTypeIsLoading,
  } = useJobTypeQuery(jobType?.value);
  const {
    data: configurationCategory,
    error: configurationCategoryError,
    isLoading: configurationCategoryIsLoading,
  } = useJobCategoryQuery(jobType?.value);

  const {
    data: rulesData,
    isFetching: isLoadingRules,
    isSuccess: isSuccessRules,
  } = useConfigurationRulesQuery();

  const {
    data: groupRulesData,
    isFetching: isLoadingGroupRules,
    isSuccess: isSuccessGroupRules,
    refetch,
  } = useGroupRulesQuery(editId, {
    skip: mode === "create",
    refetchOnMountOrArgChange: true,
    force: true,
  });

  const [runAdhocJob, { isError: isRunAdhocError }] = useRunAdhocJobMutation();

  useEffect(() => {
    if (mode === "edit" && selectedRules.length > 0) {
      let selectedOption = selectedRules.find(
        (rule) => rule.name === "include_sales_order"
      ).options;
      if (selectedOption.value === true) {
        setIsSalesOrderChecked(true);
      }
      if (selectedOption.value === false) {
        setIsSalesOrderChecked(false);
      }
    }
  }, [selectedRules]);

  useEffect(() => {
    if (refetchCount === 0 && mode === "edit") {
      refetch();
      setRefetchCount(1);
    }
    if (refetchCount === 1 && isSuccessGroupRules) {
      setConfigName(groupRulesData.data.rule_set_name);
      setJobType({
        value: groupRulesData.data.group.module.id,
        label: groupRulesData.data.group.module.name,
      });
      setJobCategory({
        value: groupRulesData.data.group.id,
        label: groupRulesData.data.group.name,
      });
      setSelectedRules(groupRulesData.data.rules);
    }
  }, [isLoadingGroupRules]);

  useEffect(() => {
    if (isSuccessRules) {
      let moduleRules = rulesData.data.filter(
        (rule) => rule.module_name === jobType?.label
      );
      setRules(moduleRules);
      console.log({ moduleRules });
    }
  }, [isLoadingRules, jobType]);

  useEffect(() => {
    if (isSuccessConfigurationType) {
      const { results } = configurationTypeData;
      const tr = results.map((item) => ({
        ...item,
        label: item.name,
        value: item.id,
      }));
      setJobTypeOptions(tr);
    }
  }, [configurationTypeIsLoading]);

  useEffect(() => {
    if (jobType?.value && configurationCategory) {
      const { results } = configurationCategory;
      const tr = results.map((item) => ({
        ...item,
        label: item.name,
        value: item.id,
      }));

      setJobCategoryOptions(tr);
    }
  }, [jobType?.value, configurationCategory]);

  const handleCreateConfig = () => {
    if (rules) {
      let createConfigPayload = {
        group: jobCategory?.value,
        rule_set_name: configName,
        rule_set_value: {},
        rules: selectedRules,
      };

      if (mode === "edit") {
        createConfigPayload.rule_set_value =
          groupRulesData?.data?.rule_set_value ?? {};
      }

      const fetchRules = async (requestType) => {
        const myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          `Bearer ${JSON.parse(localStorage.getItem("token"))}`
        );
        myHeaders.append("Content-Type", "application/json");

        const requestOptions = {
          method: requestType,
          headers: myHeaders,
          redirect: "follow",
          body: JSON.stringify(createConfigPayload),
        };

        try {
          setShowLoader(true);
          const response = await fetch(
            mode === "create"
              ? `${baseURL}/rnc/v1/group_rules/`
              : `${baseURL}/rnc/v1/group_rules/${editId}/`,
            requestOptions
          );
          setShowLoader(false);
          const result = await handleApiArrayResponse(response);
          mode === "create"
            ? toast.success("Configuration creation was successful")
            : toast.success("Configuration edit operation was successful");
          setTimeout(() =>
            navigate(`/${idArr[1]}/${idArr[2]}/${idArr[3]}/config`)
          );
          return rules;
          // if (response.ok) {
          //   mode === "create"
          //     ? toast.success("Configuration creation was successful")
          //     : toast.success("Configuration edit operation was successful");
          //   setTimeout(() =>
          //     navigate(`/${idArr[2]}/${idArr[3]}/${idArr[4]}/config`)
          //   );
          //   return rules;
          // } else {
          //   toast.error("Error encountered");
          //   return [];
          // }
        } catch (error) {
          setShowLoader(false);
          if (error.message) {
            toast.error(error.message, { autoClose: 3000 });
          } else {
            toast.error("Error encountered");
          }
          return [];
        }
      };

      if (
        createConfigPayload.rule_set_name.length > 0 &&
        createConfigPayload.group &&
        createConfigPayload.rule_set_name.length < 20 &&
        ((!configTableData2.some((item) => item.name === configName) &&
          mode === "create") ||
          mode !== "create")
      ) {
        if (mode === "create") fetchRules("POST");
        else fetchRules("PATCH");
      } else setShowError(true);
    }
  };

  const centeredTextStyle = {
    textAlign: "center",
    fontSize: "13px",
    fontFamily: "Inter",
    fontWeight: 400,
    wordWrap: "break-word",
    cursor: "pointer",
  };

  const offWhiteColor = {
    color: "#777777",
    ...centeredTextStyle,
  };

  const horizontalLineStyle = {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "70vh",
          }}
        >
          <CircularIndefiniteSpinner />
        </div>
      ) : (
        <div className="common-props">
          <LoaderSpinner showLoader={showLoader} />{" "}
          <div style={{ border: "none" }}>
            <div
              style={{
                width: "100%",
                height: "100%",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "7px",
                display: "inline-flex",
                marginBottom: "10px",
              }}
            >
              {isJobSchedulerConfig ? (
                <div
                  style={centeredTextStyle}
                  onClick={() =>
                    navigate("/snp/inventory/management/job-scheduler")
                  }
                  className="config-link"
                >
                  Job Scheduler
                </div>
              ) : (
                <div
                  style={centeredTextStyle}
                  onClick={() =>
                    navigate(`/${idArr[1]}/${idArr[2]}/${idArr[3]}/config`)
                  }
                  className="config-link"
                >
                  Configuration
                </div>
              )}
              <div style={horizontalLineStyle}>
                <BreadcrumbTemp />
                <BreadcrumbTemp />
              </div>
              <div style={offWhiteColor}>
                {mode === "create"
                  ? "Create New Configuration"
                  : "Edit Configuration"}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              <div className="single-select-dd">
                <label className="dd-lable">Configuration Name</label>
                <input
                  placeholder="Enter Configuration Name"
                  className="job-name-input"
                  value={configName}
                  onChange={(e) => setConfigName(e.target.value)}
                />
                <div style={{ fontSize: 10, color: "red", height: 15 }}>
                  {showError && !configName ? "*Name is required" : ""}
                  {showError && configName.length >= 20
                    ? "*Name must be less than 20 characters"
                    : ""}
                  {showError &&
                  configTableData2 &&
                  configTableData2.some((item) => item.name === configName) &&
                  mode === "create"
                    ? "*Name must not be duplicate"
                    : ""}
                </div>
              </div>

              <div className="single-select-dd">
                <label className="dd-lable">Job Type</label>
                <Select
                  placeholder="Select Job Type"
                  value={jobType}
                  onChange={setJobType}
                  options={jobTypeOptions}
                  className="custom-select"
                  isLoading={mode === "edit" && isLoadingGroupRules}
                />
                {showError &&
                  !Object.keys(
                    jobType === undefined || jobType === null ? {} : jobType
                  ).length && (
                    <div style={{ fontSize: 10, color: "red" }}>
                      *Job type is required
                    </div>
                  )}
              </div>

              <div className="single-select-dd">
                <label className="dd-lable">Group</label>
                <Select
                  placeholder="Select Group"
                  value={jobCategory}
                  onChange={setJobCategory}
                  options={jobCategoryOptions}
                  className="custom-select"
                  isLoading={mode === "edit" && isLoadingGroupRules}
                />
                <div style={{ fontSize: 10, color: "red", height: 15 }}>
                  {showError &&
                  !Object.keys(
                    jobCategory === undefined || jobCategory === null
                      ? {}
                      : jobCategory
                  ).length
                    ? "*Job category is required"
                    : ""}
                </div>
              </div>
            </div>
            <hr className="config-line" />
            {(mode === "edit" &&
              isSuccessGroupRules &&
              Object.keys(selectedRules).length > 0) ||
            (mode === "create" && isSuccessRules) ? (
              <>
                {rules.map((item, index) => {
                  return item.data_type === "boolean" ? (
                    <div
                      style={{
                        display: "flex",
                        marginTop: "6px",
                        marginRight: "30px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          width: 180,
                          borderColor: "#E4E4E4",
                          marginLeft: -8,
                          marginTop: "6px",
                          marginBottom: "15px",
                        }}
                      >
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={
                              mode === "edit" ? isSalesOrderChecked : null
                            }
                            onClick={(e) => {
                              setSelectedRules((currRules) => {
                                if (
                                  currRules.find(
                                    (rule) => rule.id === item.id
                                  ) == null
                                ) {
                                  return [
                                    ...currRules,
                                    {
                                      id: item.id,
                                      data_type: item.data_type,
                                      options: {
                                        id: item.options[0].id,
                                        value: e.target.checked,
                                      },
                                    },
                                  ];
                                } else {
                                  return currRules.map((rule) => {
                                    if (rule.id === item.id) {
                                      return {
                                        ...rule,
                                        options: {
                                          id: item.options[0].id,
                                          value: e.target.checked,
                                        },
                                      };
                                    } else {
                                      return rule;
                                    }
                                  });
                                }
                              });
                            }}
                          />
                          <span className="slider round" />
                        </label>
                        <div className="schedular-subheader">{item.name}</div>
                      </div>
                    </div>
                  ) : item.data_type === "single_select" ? (
                    <div className="single-select-dd">
                      <label className="dd-lable"> {item.name}</label>
                      <Select
                        placeholder="Select Input Data"
                        defaultValue={() => {
                          if (mode === "edit") {
                            let selectedOption = selectedRules.find(
                              (rule) => rule.id === item.id
                            ).options;
                            return {
                              value: {
                                id: item.id,
                                value: selectedOption.value,
                              },
                              label: selectedOption.value,
                            };
                          } else {
                            return null;
                          }
                        }}
                        onChange={(e) => {
                          setSelectedRules((currRules) => {
                            if (
                              currRules.find((rule) => rule.id === item.id) ==
                              null
                            ) {
                              return [
                                ...currRules,
                                {
                                  id: item.id,
                                  data_type: item.data_type,
                                  options: e.value,
                                },
                              ];
                            } else {
                              return currRules.map((rule) => {
                                if (rule.id === item.id) {
                                  return { ...rule, options: e.value };
                                } else {
                                  return rule;
                                }
                              });
                            }
                          });
                        }}
                        options={item.options.map(({ id, name }) => ({
                          value: { id, value: name },
                          label: name,
                        }))}
                        className="custom-select"
                      />
                      {/* <div style={{ fontSize: 10, color: "red", height: 15 }}>
                        {selectedRules && selectedRules[item] === undefined
                          ? "*Rule required"
                          : ""}
                      </div> */}
                    </div>
                  ) : (
                    <>
                      <PercentageSplit
                        values={selectedRules[index] || [25, 25, 25, 25]}
                        dynamicComponentIndex={index}
                        distributionChangeHandler={distributionChangeHandler}
                        setSelectedRules={setSelectedRules}
                      />
                    </>
                  );
                })}
              </>
            ) : !isLoadingGroupRules && selectedRules.length === 0 ? (
              <div
                style={{
                  display: "flex",
                  margin: "auto",
                  marginTop: "20px",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h3 className="rules-loading">There are no rules !</h3>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  margin: "auto",
                  marginTop: "20px",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h3 className="rules-loading">Rules loading</h3>
                <CircularIndefiniteSpinner size={"20px"} />
              </div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <div
                style={{
                  // width: "258px",
                  columnGap: "20px",
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                  marginRight: 0,
                  marginTop: 40,
                }}
              >
                <button
                  className="action-button cancel"
                  onClick={() =>
                    navigate(`/${idArr[1]}/${idArr[2]}/${idArr[3]}/config`)
                  }
                >
                  Cancel
                </button>
                <button
                  onClick={handleCreateConfig}
                  className="action-button save"
                >
                  Save
                </button>
                {isJobSchedulerConfig && (
                  <button
                    onClick={() =>
                      handleRunAdhocJob({
                        runAdhocJob,
                        urlState,
                        setShowLoader,
                        navigate,
                        jobCategory,
                      })
                    }
                    className={"action-button run-adhoc-job"}
                  >
                    {"Run Adhoc Job"}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default JobSchedularConfigScreen;
