import { useState, useEffect, useRef, createRef, Fragment } from "react";
import MuiTable from "../../components/muiTable/muiTable";
// import { transformData } from "../../../utils/utils.js";
import { useNavigate, useLocation } from "react-router-dom";
import KPICard from "../../components/kpi_cards/KPICard";
import UploadModal from "../../components/modal/UploadModal";
import {
  NormalCell,
  ApprovalStatus,
  ApprovedMarker,
  SendRevokeAction,
  ViewPageButton,
  RenderMultipleButtons,
  TableLoaderSpinner,
} from "../../components/tableUtils/tableUtils";
import "./ProductionPlan.scss";
import { BreadcrumbMaker2 } from "../../components/tableUtils/tableUtils";
import { renderSTOTableHeader } from "../../components/tableUtils/tableUtils";
import { planGroupDetailCols } from "./Tables/columns";
import { planOrgGroupDetailData } from "./Tables/data";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../utils/components";
import { Typography } from "@mui/material";

function PlanViewMPSGroup() {
  const location = useLocation();
  const path = location.pathname;
  let idArr = path.split("/");
  let editId = idArr[idArr.length - 1];
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [level, setLevel] = useState("org");
  const [openSendModal, setOpenSendModal] = useState(false);
  const [selectedButton, setSelectedButton] = useState("APP");
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const actionButtonsArray = [ViewPageButton];
  const renderSTOTableHeader = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "14px",
          marginBottom: "6px",
          marginTop: "auto",
        }}
      >
        {
          <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
            {" "}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "15px",
                border: "1px solid #E6E6E6",
                borderRadius: "50px",
                padding: "5px 10px",
              }}
            >
              <p
                style={{
                  fontFamily: "Inter",
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "#3D445C",
                  marginBottom: "unset",
                }}
              >
                Approval Status:
              </p>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "center",
                }}
              >
                <div className="status-indicator-app"></div>
                <div className="status-indicator-app"></div>
                <div className="status-indicator-app"></div>
                <div className="status-indicator-app"></div>
                <div className="status-indicator-app"></div>
              </div>
            </div>{" "}
            <div
              style={{
                border: "1px solid #d9d9d9",
                borderRadius: "19px",
                padding: "4px 4px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxSizing: "border-box",
              }}
            >
              <p
                style={{
                  fontSize: "11px",
                  marginRight: "0px",
                  marginBottom: "unset",
                  lineHeight: "unset",
                  minWidth: "50px",
                  textAlign: "center",
                }}
              >
                Revoke
              </p>
              <label
                className="send-revoke-box small-switch"
                onClick={() => {}}
              >
                <input
                  type="checkbox"
                  className="send-revoke-input"
                  // defaultChecked
                  checked={true}
                />
                <span className="send-revoke-slider send-revoke-round"></span>
              </label>
            </div>
            <button className="grp-action-live-btn">Live</button>
          </div>
        }
        <div style={{ display: "flex", gap: "15px" }}>
          <div className="sto-card sto-card-all">
            <span className="sto-card-heading">All</span>
            <span>{12000}</span>
          </div>
          <div className="sto-card">
            <span>Accepted</span>
            <span>{12000}</span>
          </div>
          <div className="sto-card">
            <span style={{ background: "transparent" }}>Pending</span>
            <span>{11}</span>
          </div>
          <div className="sto-card">
            <span style={{ background: "unset" }}>Declined</span>
          </div>
        </div>
      </div>
    );
  };
  const renderLeftTableFooterGroupLevel = () => {
    return (
      <div className="table-left-bottom">
        <div className="table-left-bottom-footer">
          <p>Ongoing Cycle : 16-06-23 12:00 AM -11:59 PM</p>
          <p>Upcoming Cycle : 16-06-23 12:00 AM -11:59 PM</p>
        </div>
      </div>
    );
  };

  const AddIconTemp = () => (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 6.99966C14 7.29582 13.7577 7.53813 13.4615 7.53813H7.53846V13.4612C7.53846 13.7588 7.29764 14 7 14C6.70237 14 6.46154 13.7574 6.46154 13.4612V7.53813H0.538462C0.240827 7.53813 0 7.2975 0 7C0 6.70351 0.240962 6.4612 0.538462 6.4612H6.46154V0.538125C6.46154 0.24049 6.70237 0 7 0C7.29764 0 7.53846 0.240625 7.53846 0.538125V6.4612H13.4615C13.7577 6.4612 14 6.70351 14 6.99966Z"
        fill="white"
      />
    </svg>
  );

  const renderSTORightHeader = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "30px",
        gap: "30px",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: "10px",
          height: "100%",
          alignItems: "center",
        }}
      >
        {" "}
        <button className="download-button">
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.87338 3.63531L5.6249 1.21028V8.62506C5.6249 8.83234 5.79261 9.00006 5.99989 9.00006C6.20717 9.00006 6.37488 8.83234 6.37488 8.62506V1.21028L9.12639 3.65476C9.1967 3.71968 9.28576 3.75015 9.37483 3.75015C9.47809 3.75015 9.58135 3.70768 9.65537 3.62418C9.79306 3.46963 9.77841 3.23231 9.62387 3.09497L6.24893 0.0950256C6.10686 -0.0316752 5.89292 -0.0316752 5.75089 0.0950256L2.37596 3.09497C2.22136 3.23219 2.20683 3.46891 2.34371 3.62359C2.48058 3.77828 2.72104 3.79234 2.87338 3.63531ZM10.4998 7.50008H7.87485C7.66776 7.50008 7.49986 7.66794 7.49986 7.87508C7.49986 8.08217 7.66776 8.25007 7.87485 8.25007H10.4998C10.9139 8.25007 11.2498 8.58592 11.2498 9.00006V10.5C11.2498 10.9142 10.9139 11.25 10.4998 11.25H1.49997C1.08584 11.25 0.749986 10.9142 0.749986 10.5V9.00006C0.749986 8.58592 1.08584 8.25007 1.49997 8.25007H4.12492C4.33117 8.25007 4.49992 8.08132 4.49992 7.87508C4.49992 7.66883 4.33117 7.50008 4.12492 7.50008H1.49997C0.671472 7.50008 0 8.17155 0 9.00006V10.5C0 11.3285 0.671472 12 1.49997 12H10.4998C11.3283 12 11.9998 11.3285 11.9998 10.5V9.00006C11.9998 8.17273 11.3271 7.50008 10.4998 7.50008ZM10.3123 9.75004C10.3123 9.4395 10.0604 9.18755 9.74982 9.18755C9.43928 9.18755 9.18733 9.4395 9.18733 9.75004C9.18733 10.0606 9.43928 10.3125 9.74982 10.3125C10.0604 10.3125 10.3123 10.0618 10.3123 9.75004Z"
              fill="black"
            />
          </svg>
        </button>
        <button className="download-button">
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="red"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.75156 8.88516C5.82187 8.96953 5.91094 9 6 9C6.08906 9 6.17798 8.9685 6.24891 8.90515L9.62391 5.90515C9.77845 5.76783 9.7931 5.53062 9.65541 5.37593C9.51771 5.22066 9.279 5.20638 9.12595 5.3448L6.375 7.79062V0.375C6.375 0.167719 6.20625 0 6 0C5.79375 0 5.625 0.167719 5.625 0.375V7.79062L2.87344 5.34375C2.72109 5.20547 2.48203 5.22187 2.34375 5.37656C2.20688 5.51016 2.22141 5.76797 2.37656 5.88516L5.75156 8.88516ZM10.5 7.5H9.375C9.16791 7.5 9 7.66786 9 7.875C9 8.08209 9.16791 8.25 9.375 8.25H10.5C10.9141 8.25 11.25 8.58586 11.25 9V10.5C11.25 10.9141 10.9141 11.25 10.5 11.25H1.5C1.08586 11.25 0.75 10.9141 0.75 10.5V9C0.75 8.58586 1.08586 8.25 1.5 8.25H2.625C2.83125 8.25 3 8.08125 3 7.875C3 7.66875 2.83125 7.5 2.625 7.5H1.5C0.671484 7.5 0 8.17148 0 9V10.5C0 11.3285 0.671484 12 1.5 12H10.5C11.3285 12 12 11.3285 12 10.5V9C12 8.17266 11.3273 7.5 10.5 7.5ZM10.3125 9.75C10.3125 9.43945 10.0605 9.1875 9.75 9.1875C9.43945 9.1875 9.1875 9.43945 9.1875 9.75C9.1875 10.0605 9.43945 10.3125 9.75 10.3125C10.0605 10.3125 10.3125 10.0617 10.3125 9.75Z"
              fill="black"
            />
          </svg>
        </button>
        <button className="download-button">
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="red"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.75156 8.88516C5.82187 8.96953 5.91094 9 6 9C6.08906 9 6.17798 8.9685 6.24891 8.90515L9.62391 5.90515C9.77845 5.76783 9.7931 5.53062 9.65541 5.37593C9.51771 5.22066 9.279 5.20638 9.12595 5.3448L6.375 7.79062V0.375C6.375 0.167719 6.20625 0 6 0C5.79375 0 5.625 0.167719 5.625 0.375V7.79062L2.87344 5.34375C2.72109 5.20547 2.48203 5.22187 2.34375 5.37656C2.20688 5.51016 2.22141 5.76797 2.37656 5.88516L5.75156 8.88516ZM10.5 7.5H9.375C9.16791 7.5 9 7.66786 9 7.875C9 8.08209 9.16791 8.25 9.375 8.25H10.5C10.9141 8.25 11.25 8.58586 11.25 9V10.5C11.25 10.9141 10.9141 11.25 10.5 11.25H1.5C1.08586 11.25 0.75 10.9141 0.75 10.5V9C0.75 8.58586 1.08586 8.25 1.5 8.25H2.625C2.83125 8.25 3 8.08125 3 7.875C3 7.66875 2.83125 7.5 2.625 7.5H1.5C0.671484 7.5 0 8.17148 0 9V10.5C0 11.3285 0.671484 12 1.5 12H10.5C11.3285 12 12 11.3285 12 10.5V9C12 8.17266 11.3273 7.5 10.5 7.5ZM10.3125 9.75C10.3125 9.43945 10.0605 9.1875 9.75 9.1875C9.43945 9.1875 9.1875 9.43945 9.1875 9.75C9.1875 10.0605 9.43945 10.3125 9.75 10.3125C10.0605 10.3125 10.3125 10.0617 10.3125 9.75Z"
              fill="black"
            />
          </svg>
          <div style={{ paddingLeft: "5px" }}>BOM</div>
        </button>
      </div>
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          height: "100%",
        }}
      >
        {" "}
        <button
          className="download-button sto-multi-reject"
          // onClick={() => handleSTOApprovalStatus(false, true)}
        >
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.8228 11.8228C11.5884 12.0573 11.209 12.0573 10.9743 11.8228L5.9999 6.84728L1.02435 11.8228C0.789893 12.0573 0.410558 12.0573 0.175841 11.8228C-0.0586137 11.5884 -0.0586137 11.209 0.175841 10.9743L5.15252 5.9999L0.176591 1.02435C-0.0578638 0.789893 -0.0578638 0.410558 0.176591 0.175841C0.411046 -0.0586137 0.79038 -0.0586137 1.0251 0.175841L5.9999 5.15252L10.9755 0.176966C11.2099 -0.0574889 11.5892 -0.0574889 11.824 0.176966C12.0584 0.411421 12.0584 0.790755 11.824 1.02547L6.84728 5.9999L11.8228 10.9755C12.0591 11.2079 12.0591 11.5904 11.8228 11.8228Z"
              fill="#3D445C"
            />
          </svg>
        </button>
        <button
          className="download-button sto-multi-accept"
          // onClick={() => handleSTOApprovalStatus(true, true)}
        >
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.839 2.17228C12.0682 2.42715 12.0506 2.82248 11.7996 3.05525L4.55608 9.774C4.3066 10.0054 3.92008 9.98925 3.69016 9.73781L0.16447 5.88218C-0.0668127 5.62926 -0.0524215 5.2338 0.196613 4.9989C0.445648 4.76401 0.835022 4.77862 1.0663 5.03155L4.17671 8.43303L10.9696 2.13226C11.2206 1.89948 11.6098 1.9174 11.839 2.17228Z"
              fill="#3D445C"
            />
          </svg>
        </button>
        <button className="download-button">
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.5591 1.31856L10.68 0.439443C10.3872 0.146528 10.0029 0 9.62086 0C9.23696 0 8.85306 0.146481 8.5601 0.439443L1.05722 7.94279C0.856408 8.14355 0.716677 8.39724 0.654569 8.67426L0.00747342 11.5523C-0.0398693 11.7677 0.144299 11.9997 0.373793 11.9997C0.398332 11.9997 0.423386 11.9973 0.448745 11.9922C0.448745 11.9922 2.43175 11.5715 3.32681 11.3611C3.59727 11.2974 3.83937 11.1613 4.03578 10.965L11.5637 3.43703C12.1474 2.85228 12.145 1.90449 11.5591 1.31856ZM3.5038 10.4341C3.40492 10.533 3.28696 10.5991 3.15319 10.6306C2.57476 10.7668 1.52103 10.9951 0.869017 11.135L1.38557 8.83792C1.41679 8.69922 1.48642 8.57285 1.58699 8.47231L7.38998 2.66931L9.32963 4.60895L3.5038 10.4341ZM11.0318 2.90619L9.85921 4.0785L7.91956 2.13886L9.08977 0.968651C9.2318 0.828029 9.4193 0.749983 9.62086 0.749983C9.82241 0.749983 10.0094 0.828029 10.1512 0.969705L11.0304 1.84883C11.3224 2.14144 11.3224 2.61557 11.0318 2.90619Z"
              fill="#3D445C"
            />
          </svg>
        </button>{" "}
        <button className="create-new-button" onClick={() => navigate("")}>
          {/* <img src={AddIconTemp} /> */}
          <AddIconTemp />
          {"  "} <span>Add</span>
        </button>
      </div>
    </div>
  );
  const dataGroup = planOrgGroupDetailData;
  useEffect(() => {
    setTimeout(() => {
      setTableData(dataGroup);
      setIsLoading(false);
    }, 1000);
  }, []);
  return (
    <div style={{ width: `calc( 100% - 20px)`, margin: 10 }}>
      <div style={{ marginBottom: "10px" }}>
        {BreadcrumbMaker2([
          {
            text: "MPS",
            link: "/snp/production/production-plan",
            level: "group",
            tab: "MPS",
          },
          {
            text: "Group Level",
            link: "/snp/production/production-plan",
            level: "group",
            tab: "MPS",
          },
          { text: `Plan View: ${editId.replaceAll("%20", " ")}`, link: null },
        ])}
      </div>
      <Fragment>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            expanded={expanded === "panel1"}
          >
            <Typography
              sx={{
                width: "33%",
                flexShrink: 0,
                fontSize: "14px",
                color: "black",
              }}
            >
              KPI&apos;s
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div
              style={{
                margin: "auto",
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              <KPICard
                value="$10536"
                volume="25039"
                heading="Total Production Requirements"
              />
              <KPICard
                value="$10536"
                volume="22039"
                heading="Total Production Allocation"
              />{" "}
              <KPICard
                value="$320976"
                volume="12356"
                heading="Production Work Order"
              />
              <KPICard
                value="$231456"
                volume="178000"
                heading="Capacity Utilization"
              />
              <KPICard value="$231456" volume="178000" heading="Deviations" />
            </div>
          </AccordionDetails>
        </Accordion>

        <div className="snp-table-container">
          <MuiTable
            columns={planGroupDetailCols}
            enableRowSelection={true}
            td={dataGroup}
            createPath="/js-create"
            level={level}
            setLevel={setLevel}
            renderBottomToolbarCustomActions={renderLeftTableFooterGroupLevel}
            shouldShowAddBtn={false}
            renderTopToolbarCustomActions={renderSTOTableHeader}
            renderSTORightHeader={renderSTORightHeader}
            repPlanSTOView={true}

            //   viewPath="/process-view"
          />
        </div>
      </Fragment>
    </div>
  );
}

export default PlanViewMPSGroup;
