const ConfigIcon = ({ color, height }) => (
  <svg
    width={height}
    height={height}
    viewBox="0 0 128 128"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="action-icon-hover"
  >
    <path
      d="M64 0C41.2857 0 20.6857 11.9629 9.14286 31.3143V4.57143C9.14286 2.04571 7.09714 0 4.57143 0C2.04571 0 0 2.04571 0 4.57143V41.1429C0 43.6571 2.04571 45.7143 4.57143 45.7143H41.1429C43.6571 45.7143 45.7143 43.6571 45.7143 41.1429C45.7143 38.6286 43.6571 36.5714 41.1429 36.5714H16.6143C26.4286 19.6286 44.3143 9.14286 64 9.14286C94.2571 9.14286 118.857 33.7514 118.857 64C118.857 94.2486 94.2486 118.857 64 118.857C47.0057 118.857 31.2286 111.169 20.7714 97.76C19.2177 95.76 16.3486 95.394 14.3514 96.9743C12.3649 98.528 12.012 101.403 13.5657 103.386C25.7714 119.029 44.1714 128 64 128C99.2857 128 128 99.2857 128 64C128 28.7143 99.2857 0 64 0ZM64 27.4286C61.4857 27.4286 59.4286 29.4857 59.4286 32V68.5714C59.4286 70.0089 60.1071 71.366 61.2589 72.2314L79.5446 85.9457C80.3714 86.3143 81.3143 86.8571 82.0286 86.8571C83.4214 86.8571 84.792 86.2231 85.6886 85.0269C87.2063 83.0091 86.7957 80.144 84.7734 78.624L68.5714 66.2857V32C68.5714 29.4857 66.5143 27.4286 64 27.4286Z"
      fill={color}
    />
  </svg>
);
export default ConfigIcon;
