import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

export default function AreaChart_poc() {
  let testOptions = {
    chart: {
      type: "area",
    },
    title: {
      text: "",
      align: "left",
    },
    xAxis: {
      lineWidth: 1,
      lineColor: "#c2c2c2",
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yAxis: {
      title: {
        text: "",
      },
      lineWidth: 1,
      lineColor: "#c2c2c2",
    },
    plotOptions: {
      area: {
        fillOpacity: 0.1,
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        marker: {
          symbol: "circle",
        },
        name: "Past Sales",
        data: [
          10000,
          9789,
          6000,
          4000,
          8000,
          4000,
          null,
          null,
          null,
          null,
          null,
          null,
        ],
        color: "#70D880",
      },
      {
        marker: {
          symbol: "circle",
        },
        name: "Sales Forecast",
        data: [
          null,
          null,
          null,
          null,
          null,
          18000,
          15000,
          13000,
          17000,
          13000,
          12000,
          10000,
        ],
        color: "#EF3A3A",
        // fillColor: {
        //   linearGradient: [0, 0, 0, 150],
        //   stops: [
        //     [0, "#EF3A3A"],
        //     [1, "#fff"],
        //   ],
        // },
      },
      {
        marker: {
          symbol: "circle",
        },
        name: "Inventory Forecast",
        data: [
          null,
          null,
          null,
          null,
          null,
          5000,
          6000,
          11000,
          11000,
          7000,
          12000,
          4000,
        ],
        color: "#3B40CA",
        // fillColor: {
        //   linearGradient: [0, 0, 0, 200],
        //   stops: [
        //     [0, "#3B40CA"],
        //     [1, "#fff"],
        //   ],
        // },
      },
      {
        marker: {
          symbol: "circle",
        },
        name: "Safety stock",
        data: [
          null,
          null,
          null,
          null,
          null,
          3000,
          4000,
          5000,
          5500,
          4500,
          4000,
          3000,
        ],
        color: "#9596BA",
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={testOptions} />;
}
