import * as React from "react";
import { styled } from "@mui/material/styles";
import { createTheme, ThemeProvider } from "@mui/material";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import InventoryStatistics from "./InventoryStatistics";
import KpiCards from "../../components/kpi_cards/kpiCardsMain";

const StyledCard2 = styled("div")(({ theme }) => ({
  ...theme.typography.body1,
  borderRadius: "7px",
  border: "1px solid #E6E6E6",
  // padding: theme.spacing(1),
  display: "flex",
  flexDirection: "column",
  //   flexWrap: "wrap",
}));

const StyledButton = styled("button")(() => ({
  background: "#fff",
  paddingTop: "10px",
  fontSize: "12px",
  fontWeight: "500",
}));

const HeadingDiv = styled("div")(({ theme }) => ({
  ...theme.typography.customHeading,
  paddingLeft: "20px",
  paddingTop: "10px",
}));

const Layout = () => {
  const navigate = useNavigate();

  return (
    <ThemeProvider theme={customTheme}>
      <Box
        sx={{
          boxSizing: "border-box",
          display: "flex",
          height: "100vh",
          maxHeight: "100vh",
          width: "100%",
          flexDirection: "column",
          padding: "10px",
        }}
      >
        <KpiCards />
        <Box
          sx={{
            display: "grid",
            flexGrow: "1",
            gridTemplateRows: "repeat(2, 50%)",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "10px",
            marginBottom: "10px",
          }}
        >
          <StyledCard2>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <HeadingDiv>Demand Overview</HeadingDiv>
              <StyledButton onClick={() => navigate("/pbi-poc/screen2")}>
                View more data
              </StyledButton>
            </Box>
            <Box
              sx={{
                display: "grid",
                height: "85%",
                width: "100%",
              }}
            >
              <InventoryStatistics />
            </Box>
          </StyledCard2>
          <StyledCard2>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <HeadingDiv>Inventory Statistics</HeadingDiv>
              <StyledButton onClick={() => navigate("/pbi-poc/screen2")}>
                View more data
              </StyledButton>
            </Box>
          </StyledCard2>
          <StyledCard2>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <HeadingDiv>Current Plan</HeadingDiv>
              <StyledButton onClick={() => navigate("/pbi-poc/screen2")}>
                View more data
              </StyledButton>
            </Box>
          </StyledCard2>
          <StyledCard2>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <HeadingDiv>Performance</HeadingDiv>
              <StyledButton onClick={() => navigate("/pbi-poc/screen2")}>
                View more data
              </StyledButton>
            </Box>
          </StyledCard2>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

const customTheme = createTheme({
  palette: {
    primary: {
      main: "#f5f7fa",
    },
  },
  typography: {
    fontFamily: "Inter, sans-serif",
    fontSize: 12,
    fontWeightBold: 700,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightLight: 300,
    customBoldFont: {
      fontWeight: 600,
      fontSize: 13,
    },
    customLightFont: {
      fontSize: 11,
      fontWeight: 400,
    },
    customHeading: {
      fontWeight: 300,
      fontSize: 15,
    },
  },
  components: {
    MuiDrawer: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});

export default Layout;
