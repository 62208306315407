import { toast } from "react-toastify";
import { handleDownloadApiResponse } from "../../../utils/apiUtils";

export const handleProcessType = (selectedRow) => {
  if (selectedRow) {
    let selectedProcess = selectedRow.process;
    let selectedProcessArray = selectedProcess.split("_");
    let selectedProcessLength = selectedProcessArray.length;
    return {
      type: selectedProcessArray[selectedProcessLength - 1].toLowerCase(),
      processType:
        selectedRow.processTypes === "CSA" ? "classification" : "norms",
    };
  }
  return "";
};

export const handleDownload = (
  row,
  type,
  processType,
  isDownloading,
  setIsDownloading,
  downloadType,
  moduleType = "rp"
) => {
  let processSpec;
  if (moduleType === "rmp") {
    const process = row.process.toLowerCase().split(":");
    processSpec = process[1].trim() === "rm" ? "rm" : "sku";
  }
  if (downloadType === "preprocesses" || downloadType === "meta-data") {
    setIsDownloading((prev) => ({ ...prev, [row.process]: true }));
  } else {
    setIsDownloading(true);
  }

  if (downloadType === "meta-data") {
    const url = `${process.env.PUBLIC_URL}/dummy-csv/${row.process}_output.xlsx`;
    var a = document.createElement("a");
    a.href = url;
    a.download = `${row.process}_output.csv`;
    a.addEventListener("error", () => {
      // File not found, create and download an empty CSV
      const emptyCsvContent = "data:text/csv;charset=utf-8,";
      var emptyCsvLink = document.createElement("a");
      emptyCsvLink.href = emptyCsvContent;
      emptyCsvLink.download = `${row.process}_output.csv`;
      emptyCsvLink.click();
    });
    a.click();

    setIsDownloading((prev) => ({ ...prev, [row.process]: false }));
  } else {
    let url = "";
    if (row.processTypes === "INVENTORY_PROFILING" || type === "profiling") {
      url = `${process.env.REACT_APP_SCAI_API_ENDPOINT}/preprocess/download_profiling/`;
    } else {
      url =
        processType === "classification"
          ? `${process.env.REACT_APP_SCAI_API_ENDPOINT}/preprocess/download_${processType}/?type=${type}`
          : `${
              process.env.REACT_APP_SCAI_API_ENDPOINT
            }/preprocess/v2/download_${processType}/${
              moduleType === "rmp" ? processSpec : "sku"
            }/?norms_type=${type}&module_type=${moduleType}`;
    }
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
      },
    })
      .then(handleDownloadApiResponse)
      .then((data) => {
        if (downloadType === "preprocesses") {
          setIsDownloading((prev) => ({ ...prev, [row.process]: false }));
        } else {
          setIsDownloading(false);
        }
        var a = document.createElement("a");
        a.href = window.URL.createObjectURL(data);
        a.download =
          row.processTypes === "INVENTORY_PROFILING"
            ? "preprocesses_profiling.csv"
            : `preprocesses_${type.toLowerCase()}.csv`;
        a.click();
      })
      .catch((error) => {
        if (downloadType === "preprocesses") {
          setIsDownloading((prev) => ({ ...prev, [row.process]: false }));
        } else {
          setIsDownloading(false);
        }
        toast.error(error.message, { autoClose: 2000 });
      });
  }
};
