import { Checkbox } from "@mui/material";
import { createRef, useRef } from "react";
import { DataTableColumnHeader } from "@/components/tanstack-table/data-table-column-header";
import { Skeleton } from "@/components/ui/skeleton";
import { DataTableRowActions } from "@/components/tanstack-table/data-table-row-actions";

export const stockLevelCols = [
  {
    accessorKey: "sku_id",
    header: "SKU ID",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    size: 200,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "sku_description",
    header: "SKU Description",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    size: 200,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "node_id",
    header: "Node ID",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    size: 200,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "node_name",
    header: "Node Name",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    size: 200,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "channel_id",
    header: "Channel ID",
    enableColumnActions: true,
    muiTableBodyCellEditTextFieldProps: {
      select: true, //change to select for a dropdown
    },
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "classification_abc",
    header: "Classification-ABC",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    size: 200,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "classification_xyz",
    header: "Classification-XYZ",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    size: 200,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "stock_in_hand",
    header: "Stock In-hand",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    size: 200,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "stock_in_transit",
    header: "Stock In-Transit",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    size: 200,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "stock_in_pbns",
    header: "Stock In-PBNS",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    size: 200,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "health",
    header: "Health",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    size: 200,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "open_sales_orders",
    header: "Open Sales Orders",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    size: 200,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "safety_stock",
    header: "Safety Stock",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    size: 200,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "msl",
    header: "MSL",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    size: 200,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "dos",
    header: "DOS",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    size: 200,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "rop",
    header: "ROP",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    size: 200,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "sku_hierarchy_1_category",
    header: "SKU hierarchy 1 - Category",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    size: 200,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "sku_hierarchy_2_division",
    header: "SKU hierarchy 2 - Division",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    size: 200,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "sku_hierarchy_3_brand",
    header: "SKU hierarchy 3 - Brand",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    size: 200,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "node_hierarchy_1",
    header: "Node hierarchy 1",
    enableColumnOrdering: false,
    enableColumnActions: true,
    enableEditing: false,
    enableSorting: true,
    size: 200,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "node_hierarchy_2",
    header: "Node hierarchy 2",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    size: 200,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "node_hierarchy_3",
    header: "Node hierarchy 3",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    size: 200,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
];

export const salesOrderDetailsCols = [
  {
    accessorKey: "sales_order",
    header: "Sales Order",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    size: 200,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "invoice_id",
    header: "Invoice ID",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    size: 200,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "order_date",
    header: "Order date",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    size: 200,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "sku_code",
    header: "Sku Code",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    size: 200,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "sku_description",
    header: "SKU Description",
    enableColumnActions: false,
    muiTableBodyCellEditTextFieldProps: {
      select: true, //change to select for a dropdown
    },
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "channel_id",
    header: "Channel ID",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    size: 200,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "node_id",
    header: "Node ID",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    size: 200,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "node_name",
    header: "Node Name",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    size: 200,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "customer_id",
    header: "Customer ID",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    size: 200,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "Customer Name",
    header: "Customer Name",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    size: 200,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "total_order_quantity",
    header: "Total Order Quantity",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    size: 200,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "delivered_order_quantity",
    header: "Delivered Order Quantity",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    size: 200,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "in_transit_order_quantity",
    header: "In-Transit Order Quantity",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    size: 200,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "pending_order_quantity",
    header: "Pending Order Quantity",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    size: 200,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "net_sales_value",
    header: "Net Sales Value",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    size: 200,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "lead_time_days",
    header: "Lead time (days)",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    size: 200,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "req_delivery_date",
    header: "Req. Delivery date",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    size: 200,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "plan_delivery_date",
    header: "Plan Delivery date",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    size: 200,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "so_type",
    header: "SO Type",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    size: 200,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "invoice_amt",
    header: "Invoice amt (₹)",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    size: 200,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "order_status",
    header: "Order Status",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    size: 200,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "promo_id",
    header: "Promo Id",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    size: 200,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "sku_hierarchy_1_category",
    header: "SKU hierarchy 1 - Category",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    size: 200,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "sku_hierarchy_2_division",
    header: "SKU hierarchy 2 - Division",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    size: 200,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "sku_hierarchy_3_brand",
    header: "SKU hierarchy 3 - Brand",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    size: 200,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "node_hierarchy_1",
    header: "Node hierarchy 1",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    size: 200,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "node_hierarchy_2",
    header: "Node hierarchy 2",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    size: 200,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "node_hierarchy_3",
    header: "Node hierarchy 3",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    size: 200,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
];

export function DynamicRefsExample(
  numberOfRefs,
  componentArray,
  cell,
  row,
  centerAlign,
  multipleElements = false
) {
  const refsArray = useRef(
    Array.from({ length: numberOfRefs }, () => createRef())
  );
  return (
    <div className={!centerAlign ? "action-buttons" : "action-buttons-align"}>
      {Array.from({ length: numberOfRefs }, (_, index) => {
        const Comp = componentArray[index];

        return (
          <div
            id={index}
            key={index}
            ref={refsArray.current[index]}
            placeholder={`Ref ${index + 1}`}
            //   onClick={() => actionsArray[index](row)}
            style={{
              pointerEvents: row.original.isDisabled ? "none" : "auto",
              display: "flex",
              width: multipleElements ? "" : "100%",
            }}
          >
            <Comp row={row.original} />
          </div>
        );
      })}
    </div>
  );
}

export function newStockLevelCols() {
  const columns = stockLevelCols.map((item) => {
    return {
      accessorKey: item.accessorKey,
      header: ({ column, header }) => (
        <DataTableColumnHeader
          column={column}
          title={`${item.header}`}
          header={header}
        />
      ),
      cell: ({ row }) => (
        <div className="flex items-center justify-center">
          {row.getValue(`${item.accessorKey}`)}
        </div>
      ),
      // size: 220,
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
      enableSorting: true,
      enableHiding: false,
    };
  });
  return columns;
}

export function loadingColumns() {
  const columns = Array(10)
    .fill({})
    .map((item, index) => {
      return {
        accessorKey: `column_${index}`,
        header: ({ column, header }) => (
          <div className="py-[20px] lg:py-[15px]">
            <DataTableColumnHeader column={column} title={""} header={header} />
          </div>
        ),
        cell: ({ row }) => (
          <div className="flex items-center justify-center h-full py-[8px] lg:py-[6px]">
            <Skeleton className="h-5 w-1/2 lg:!py-4 py-2" />
          </div>
        ),
      };
    });
  return columns;
}

export function loadingDetailedColumns() {
  let columns = [];
  columns.push({
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={
          table.getIsAllPageRowsSelected() ||
          (table.getIsSomePageRowsSelected() && "indeterminate")
        }
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
        className="translate-y-[2px]"
      />
    ),
    cell: ({ row }) => (
      <div className="flex justify-center">
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Select row"
          className="translate-y-[2px]"
        />
      </div>
    ),
    enableSorting: false,
    enableHiding: false,
    size: 150,
  });
  const dummyColumns = Array(10)
    .fill({})
    .map((item, index) => {
      return {
        accessorKey: `column_${index}`,
        header: ({ column, header }) => (
          <DataTableColumnHeader
            column={column}
            title={`Column header ${index + 1}`}
            header={header}
          />
        ),
        cell: ({ row }) => (
          <div className="flex items-center justify-center">
            <Skeleton className="h-3 w-1/2" />
          </div>
        ),
      };
    });
  columns.push(...dummyColumns);
  columns.push({
    id: "actions",
    cell: ({ row }) => (
      <div className="flex items-center justify-center">
        <DataTableRowActions row={row} />
      </div>
    ),
    size: 150,
  });
  return columns;
}
