import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  formatDateToDateMonYear,
  formatISODateToHumanReadable,
  twoDecimalNumberFormat,
} from "@/utils/utils";
import "../Inventory/Dashboard/styles.css";
import {
  usePpAppGroupPlanUpdatePwoMutation,
  usePpAppGroupPlanUpdateStatusMutation,
  useMakePpPlanLiveMutation,
} from "@/features/api/prodPlanSlice";

import UploadModal from "../../components/modal/UploadModal.jsx";
import AllocationQuantityItem from "./AllocationQuantityItem";
import {
  TableLoaderSpinner,
  transformStatus,
} from "../../components/tableUtils/tableUtils";
import { PlanViewKpiCards } from "@/components/kpi_cards_copy/planViewKpiCards";
import { ThemeProvider, Typography, createTheme } from "@mui/material";

import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setPpGroupPlanViewDetails } from "@/features/ppGroupPlanViewSlice";
import {
  handleApiResponse,
  handleApiArrayResponse,
  handleDownloadApiResponse,
} from "@/utils/apiUtils";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../utils/components";
import {
  loadingColumns,
  loadingPlantToWareHouseColumns,
} from "./Tables/columns";
import {
  setEditedSTOList,
  setIsGroupLevelEditModeOn,
  setOriginalSTOValues,
} from "@/features/productionPlanSlice";
import { Checkbox } from "@/components/ui/checkbox";
import { DataTableColumnHeader } from "@/components/tanstack-table/data-table-column-header";
import { DataTableRowActions } from "@/components/tanstack-table/data-table-row-actions";
import { DataTable } from "@/components/tanstack-table/data-table";
import { DataTableToolbar } from "@/components/tanstack-table/data-table-toolbar";
import Tooltip from "@/components/tooltip/Tooltip";
import {
  usePpAppGroupPlanViewQuery,
  usePpAppGroupPlanPlantAllocationQuery,
} from "@/features/api/prodPlanSlice";
import PlantAllocation from "./PlantAllocation";
import { removeDuplicateKeys } from "@/components/tanstack-table/table-utils";

const transformTableData = (arr) => {
  return arr.map((item) => ({
    ...item,
    wpo_id: item.wpo_id,
    plant_id: item.plant_id,
    product_id: item.product_id,
    date: item.date,
    opening_stock: item.opening_stock,
    safety_stock: item.safety_stock,
    max_stock: item.max_stock,
    moq: item.moq,
    production_quantity: twoDecimalNumberFormat(item.production_quantity),
    production_cost: item.production_cost,
    budget_available: item.budget_available,
    share_of_business_amount_quantity: item.share_of_business_amount_quantity,
    total_allocation: item.total_allocation,
    closing_stock: item.closing_stock,
    // created_date: formatDateToDateMonYear(item.created_date),
    storage_space_occupied: item.storage_space_occupied,
    inventory_shortage: item.inventory_shortage,
    inventory_surplus: item.inventory_surplus,
    item_cost_per_unit: item.item_cost_per_unit,
    case_lot: item.case_lot,
    reason_code: item.reason_code,
    created_by: item.created_by,
    created_at: item.created_at,
    updated_at: item.updated_at,
    status: item.status,
    comments: item.comments,
    version: item.version,
  }));
};

const transformKpiData = (item) => {
  let arr = {};
  arr["totalOrderQuantitySystem"] = twoDecimalNumberFormat(
    item.total_order_quantity_system
  );
  arr["totalOrderQuantityUser"] = twoDecimalNumberFormat(
    item.total_order_quantity_user
  );
  arr["totalOrderValueSystem"] = twoDecimalNumberFormat(
    item.total_order_value_system
  );
  arr["totalOrderValueUser"] = twoDecimalNumberFormat(
    item.total_order_value_user
  );
  arr["orderCountTotal"] = item.order_count_total;
  arr["orderCountSystem"] = item.order_count_system;
  arr["orderCountManual"] = item.order_count_manual;
  arr["orderCountEdited"] = item.order_count_edited;
  arr["orderCountAccepted"] = item.order_count_accepted;
  arr["orderCountDeclined"] = item.order_count_declined;
  arr["orderCountPending"] = item.order_count_pending;
  arr["skuCount"] = item.sku_count;
  arr["sourceCount"] = item.source_count;
  arr["destinationCount"] = item.destination_count;
  return arr;
};

function getInitialVisibleColumns() {
  let localVisibleColumns = localStorage.getItem("snp-pp-group-cols");
  if (localVisibleColumns) {
    localVisibleColumns = JSON.parse(localVisibleColumns);
  } else {
    return {};
  }

  if (localVisibleColumns && Object.keys(localVisibleColumns).length !== 0) {
    return localVisibleColumns;
  } else {
    return {};
  }
}

function PlanViewAPPGroup() {
  const firstEditField = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  let pathSplit = path.split("/");
  let len = pathSplit.length;
  const planId = pathSplit[len - 1];
  const [tableData, setTableData] = useState(Array(10).fill({}));
  const [stoStatusValues, setStoStatusvalues] = useState({
    all: 0,
    approved: 0,
    declined: 0,
    pending: 0,
  });
  const [openApproveRejectModal, setOpenApproveRejectModal] = useState(false);
  const [approveRejectValue, setApproveRejectValue] = useState(null);
  const [openSendModal, setOpenSendModal] = useState(false);
  const [selectedGroupPlan, setSelectedGroupPlan] = useState({});
  const groupLevelPlanDetails = useSelector(
    (state) => state.ppGroupPlan.ppGroupPlanViewDetails
  );
  const [groupPlanDetails, setgroupPlanDetails] = useState(
    groupLevelPlanDetails
  );
  const [selectedSTO, setSelectedSTO] = useState({});
  const [loadingData, setLoadingData] = useState(false);
  const [selectedTab, setSelectedTab] = useState("all");
  const [openStoUploadModal, setopenStoUploadModal] = useState(false);
  const [isUploading, setisUploading] = useState(false);
  const [isDownloadingSto, setisDownloading] = useState(false);
  const [clearRowSelection, setClearRowSelection] = useState(false);
  const [approveLoading, setapproveLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [kpiData, setKpiData] = useState({});
  const [columnVisibility, setColumnVisibility] = useState(
    getInitialVisibleColumns()
  );

  let userDetails = JSON.parse(localStorage.getItem("user"));
  const { permissions } = userDetails;
  const isApprover = permissions.includes("PP_APPROVE_REJECT");
  const isEditPermission = permissions.includes("PWO_EDIT");
  const acceptPermission = permissions.includes("PWO_ACCEPT");
  const rejectPermission = permissions.includes("PWO_REJECT");
  const uploadPermission = permissions.includes("PP_UPLOAD");
  const downloadPermission = permissions.includes("PWO_VIEW");
  const addStoPermission = permissions.includes("PP_CREATE");
  const stoActionEnable =
    groupPlanDetails.approvalId === null ||
    (groupPlanDetails.approvalId !== null &&
      groupPlanDetails.approvalDetails &&
      groupPlanDetails.approvalDetails.permissions.canSubmit === true &&
      groupPlanDetails.approvalDetails.permissions.canRevoke !== true);
  const editEnable = isEditPermission && stoActionEnable;
  const acceptEnable = acceptPermission && stoActionEnable;
  const rejectEnable = rejectPermission && stoActionEnable;
  const uploadEnable = uploadPermission && stoActionEnable;
  const downloadEnable = downloadPermission;
  const addStoEnable = addStoPermission && stoActionEnable;
  const [openMakeLiveModal, setOpenMakeLiveModal] = useState(false);
  const [columns, setColumns] = useState(loadingColumns());
  const [subColumns, setSubColumns] = useState(
    loadingPlantToWareHouseColumns()
  );
  const [clickedWPO, setClickedWPO] = useState({});
  const [resetExpanded, setResetExpanded] = useState(false);

  const plantToWareHouseColumns = [
    {
      accessorKey: "plant_code",
      header: ({ column, header }) => (
        <DataTableColumnHeader
          column={column}
          title={"Plant ID"}
          header={header}
        />
      ),
      cell: ({ row, cell }) => {
        const widthValue = cell.column.getSize();
        return (
          <div className="flex items-center justify-center">
            <div
              className="antialiased truncate text-center"
              style={{ width: widthValue, minWidth: "150px" }}
            >
              {transformRenderedCellvalue(row.getValue("plant_code"))}
            </div>
          </div>
        );
      },
      size: 330,
      enableSorting: false,
    },
    {
      accessorKey: "warehouse_code",
      header: ({ column, header }) => (
        <div className="py-[8px] lg:py-[6px]">
          <DataTableColumnHeader
            column={column}
            title={"Warehouse ID"}
            header={header}
          />
        </div>
      ),
      cell: ({ row, cell }) => {
        const widthValue = cell.column.getSize();
        return (
          <div className="flex items-center justify-center">
            <div
              className="antialiased truncate text-center py-[8px] lg:py-[6px]"
              style={{ width: widthValue, minWidth: "150px" }}
            >
              {transformRenderedCellvalue(row.getValue("warehouse_code"))}
            </div>
          </div>
        );
      },
      size: 250,
      enableSorting: false,
    },
    {
      accessorKey: "sku_code",
      header: ({ column, header }) => (
        <DataTableColumnHeader
          column={column}
          title={"SKU ID"}
          header={header}
        />
      ),
      cell: ({ row, cell }) => {
        const widthValue = cell.column.getSize();
        return (
          <div className="flex items-center justify-center">
            <div
              className="antialiased truncate text-center"
              style={{ width: widthValue, minWidth: "150px" }}
            >
              {transformRenderedCellvalue(row.getValue("sku_code"))}
            </div>
          </div>
        );
      },
      size: 250,
      enableSorting: false,
    },
    {
      accessorKey: "date",
      header: ({ column, header }) => (
        <DataTableColumnHeader column={column} title={"Date"} header={header} />
      ),
      cell: ({ row, cell }) => {
        const widthValue = cell.column.getSize();
        return (
          <div className="flex items-center justify-center">
            <div
              className="antialiased truncate text-center"
              style={{ width: widthValue, minWidth: "150px" }}
            >
              {transformRenderedCellvalue(row.getValue("date"))}
            </div>
          </div>
        );
      },
      size: 250,
      enableSorting: false,
    },
    {
      accessorKey: "allocation",
      header: ({ column, header }) => (
        <DataTableColumnHeader
          column={column}
          title={"Allocation"}
          header={header}
        />
      ),
      cell: ({ row, cell }) => {
        const widthValue = cell.column.getSize();
        return (
          <AllocationQuantityItem
            editableField={editableFieldRef.current}
            stoValues={row.original}
            getEditSTOApiBody={getEditSTOApiBody}
            planId={planId}
          />
        );
      },
      size: 250,
      enableSorting: false,
    },
    {
      accessorKey: "number_of_cases",
      header: ({ column, header }) => (
        <DataTableColumnHeader
          column={column}
          title={"Number of Cases"}
          header={header}
        />
      ),
      cell: ({ row, cell }) => {
        const widthValue = cell.column.getSize();
        return (
          <div className="flex items-center justify-center">
            <div
              className="antialiased truncate text-center"
              style={{ width: widthValue, minWidth: "150px" }}
            >
              {transformRenderedCellvalue(row.getValue("number_of_cases"))}
            </div>
          </div>
        );
      },
      size: 250,
      enableSorting: false,
    },
  ];

  const savedColumns = useRef([]);
  const dispatch = useDispatch();
  const isGroupLevelEditModeOn = useSelector(
    (state) => state.productionPlan.isGroupLevelEditModeOn
  );
  const editedSTOList = useSelector(
    (state) => state.productionPlan.editedSTOList
  );
  let editableFieldRef = useRef(null);

  const {
    data: ppAppGroupPlanViewData,
    refetch,
    isFetching: isLoadingPpAppGroupPlanViewData,
    isSuccess: isSuccessPpAppGroupPlanViewData,
  } = usePpAppGroupPlanViewQuery(planId, {
    refetchOnMountOrArgChange: true,
    force: true, // This will bypass the cache
  });

  const {
    data: ppPlantAllocationData,
    isFetching: isLoadingPpAllocationData,
    isSuccess: isSuccessPpPlantAllocationData,
  } = usePpAppGroupPlanPlantAllocationQuery(
    {
      planId: planId,
      wpoId: clickedWPO.wpo_id,
      plantId: clickedWPO.plant_id,
      productId: clickedWPO.product_id,
      date: clickedWPO.date,
    },
    {
      skip: Object.keys(clickedWPO).length === 0,
      refetchOnMountOrArgChange: true,
      force: true, // This will bypass the cache
    }
  );

  const [updateWPO, { isLoading: isLoadingUpdateSTO, isError }] =
    usePpAppGroupPlanUpdatePwoMutation();

  const [updateWPOStatus, { isLoading: isLoadingUpdateWPOStatus }] =
    usePpAppGroupPlanUpdateStatusMutation();

  const [planLive, { isLoading: loadingMakePlanLive }] =
    useMakePpPlanLiveMutation();

  const actionColumn = (row) => {
    return (
      <div className="accept-reject-buttons">
        <div
          className={
            row.version !== "V1"
              ? "sto-edited"
              : row.is_manually_created === true
              ? "sto-manually-created"
              : "sto-system"
          }
        >
          <Tooltip content="Reject">
            <button
              className="group-plan-action-button remove"
              disabled={!rejectEnable || row.status === "rejected"}
              onClick={() => handleSingleSTOApproval(row.wpo_id, false)}
            >
              <svg
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.3228 11.8228C12.0884 12.0573 11.709 12.0573 11.4743 11.8228L6.4999 6.84728L1.52435 11.8228C1.28989 12.0573 0.910558 12.0573 0.675841 11.8228C0.441386 11.5884 0.441386 11.209 0.675841 10.9743L5.65252 5.9999L0.676591 1.02435C0.442136 0.789893 0.442136 0.410558 0.676591 0.175841C0.911046 -0.0586137 1.29038 -0.0586137 1.5251 0.175841L6.4999 5.15252L11.4755 0.176966C11.7099 -0.0574889 12.0892 -0.0574889 12.324 0.176966C12.5584 0.411421 12.5584 0.790755 12.324 1.02547L7.34728 5.9999L12.3228 10.9755C12.5591 11.2079 12.5591 11.5904 12.3228 11.8228Z"
                  fill="#3D445C"
                />
              </svg>
            </button>
          </Tooltip>
        </div>
        <div>
          <Tooltip content="Accept">
            <button
              className="group-plan-action-button save"
              disabled={!acceptEnable || row.status === "accepted"}
              onClick={() => handleSingleSTOApproval(row.wpo_id, true)}
            >
              <svg
                width="13"
                height="9"
                viewBox="0 0 13 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.339 1.17228C12.5682 1.42715 12.5506 1.82248 12.2996 2.05525L5.05608 8.774C4.8066 9.0054 4.42008 8.98925 4.19016 8.73781L0.66447 4.88218C0.433187 4.62926 0.447578 4.2338 0.696613 3.9989C0.945648 3.76401 1.33502 3.77862 1.5663 4.03155L4.67671 7.43303L11.4696 1.13226C11.7206 0.899479 12.1098 0.917397 12.339 1.17228Z"
                  fill="#3D445C"
                />
              </svg>
            </button>
          </Tooltip>
        </div>{" "}
      </div>
    );
  };

  function transformRenderedCellvalue(input) {
    const options = {
      day: "numeric",
      month: "short",
      year: "numeric",
    };
    if (input instanceof Date) {
      return input.toLocaleDateString("en-GB", options);
    }
    return input;
  }

  useEffect(() => {
    function prepareColumns() {
      const allowedColumns = Object.keys(ppAppGroupPlanViewData?.results?.[0]);
      let subRowIndex = allowedColumns.indexOf("subRows");
      if (subRowIndex !== -1) {
        allowedColumns.splice(subRowIndex, 1);
      }
      const columnHeaderMap = ppAppGroupPlanViewData.column_mapping;
      let cols = [];
      let visibleColumns = {};
      cols.push({
        id: "expander",
        header: ({ table, cell }) => {
          const widthValue = cell?.column?.getSize();
          return (
            <div
              className="flex justify-center lg:!py-[7px] lg:!px-0"
              style={{ width: widthValue }}
            >
              {/* <button
                {...{
                  onClick: table.getToggleAllRowsExpandedHandler(),
                }}
              >
                {table.getIsAllRowsExpanded() ? (
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.181092 3.95111L5.60477 8.86003C5.81357 9.04647 6.12669 9.04647 6.33566 8.86003L11.8197 3.95111C12.0433 3.7193 12.0614 3.40567 11.8602 3.18068C11.6462 2.94546 11.3012 2.94887 11.0888 3.13977L6.00021 7.72143L0.910614 3.13977C0.685826 2.93943 0.341655 2.95702 0.139503 3.18027C-0.0609446 3.40567 -0.0438996 3.7193 0.181092 3.95111Z"
                      fill="#3D445C"
                    />
                  </svg>
                ) : (
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.181092 3.95111L5.60477 8.86003C5.81357 9.04647 6.12669 9.04647 6.33566 8.86003L11.8197 3.95111C12.0433 3.7193 12.0614 3.40567 11.8602 3.18068C11.6462 2.94546 11.3012 2.94887 11.0888 3.13977L6.00021 7.72143L0.910614 3.13977C0.685826 2.93943 0.341655 2.95702 0.139503 3.18027C-0.0609446 3.40567 -0.0438996 3.7193 0.181092 3.95111Z"
                      fill="#3D445C"
                    />
                  </svg>
                )}
              </button>{" "} */}
            </div>
          );
        },
        cell: ({ table, row, cell }) => {
          const widthValue = cell?.column?.getSize();
          return (
            <div
              className="flex justify-center items-center h-full py-[8px] lg:py-[6px]"
              style={{
                width: widthValue,
              }}
            >
              {row.getCanExpand() ? (
                <button
                  {...{
                    onClick: () => {
                      if (row.getIsExpanded() === true) {
                        setClickedWPO({});
                      } else {
                        setClickedWPO(row.original);
                      }
                      // if (isSuccessPpPlantAllocationData) {
                      //   handlePlantAllocationData(
                      //     row.original.wpo_id,
                      //     row.original.plant_id,
                      //     row.original.product_id,
                      //     row.original.date
                      //   );
                      // }

                      row.toggleExpanded();
                    },
                  }}
                >
                  {row.getIsExpanded() ? (
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.8654 8.81515C11.6516 9.05063 11.3064 9.04604 11.094 8.85559L6.00268 4.28116L0.910715 8.856C0.685823 9.05604 0.341492 9.03848 0.139246 8.81556C-0.0612946 8.59049 -0.0432186 8.2467 0.180514 8.04587L5.60671 3.14426C5.81561 2.9581 6.12887 2.9581 6.33794 3.14426L11.7641 8.04587C12.0496 8.2467 12.0667 8.59049 11.8654 8.81515Z"
                        fill="#3D445C"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.181092 3.95111L5.60477 8.86003C5.81357 9.04647 6.12669 9.04647 6.33566 8.86003L11.8197 3.95111C12.0433 3.7193 12.0614 3.40567 11.8602 3.18068C11.6462 2.94546 11.3012 2.94887 11.0888 3.13977L6.00021 7.72143L0.910614 3.13977C0.685826 2.93943 0.341655 2.95702 0.139503 3.18027C-0.0609446 3.40567 -0.0438996 3.7193 0.181092 3.95111Z"
                        fill="#3D445C"
                      />
                    </svg>
                  )}
                </button>
              ) : (
                ""
              )}{" "}
            </div>
          );
        },
        enableSorting: false,
        enableHiding: false,
        enableResizing: false,
        size: 40,
      });
      if (!isApprover) {
        cols.push({
          id: "select",
          header: ({ table, cell }) => {
            const widthValue = cell?.column?.getSize();
            return (
              <div
                className="flex justify-center items-center py-[10px] lg:py-[8px]"
                style={{ width: widthValue }}
              >
                <Checkbox
                  checked={
                    table.getIsAllRowsSelected() ||
                    (table.getIsSomeRowsSelected() && "indeterminate")
                  }
                  onCheckedChange={(value) =>
                    table.toggleAllRowsSelected(!!value)
                  }
                  aria-label="Select all"
                  // className="translate-y-[2px]"
                />
              </div>
            );
          },
          cell: ({ row, cell }) => {
            const widthValue = cell?.column?.getSize();
            return (
              <div
                className="flex justify-center items-center h-full py-[8px] lg:py-[6px]"
                style={{
                  width: widthValue,
                }}
              >
                <Checkbox
                  checked={row.getIsSelected()}
                  onCheckedChange={(value) => row.toggleSelected(!!value)}
                  aria-label="Select row"
                  // className="translate-y-[2px]"
                />
              </div>
            );
          },
          enableSorting: false,
          enableHiding: false,
          enableResizing: false,
          size: 40,
        });
      }
      allowedColumns.forEach((columnKey) => {
        visibleColumns[columnKey] = true;
        let columnMetaData = null;
        if (columnKey === "total_allocation") {
          columnMetaData = {
            accessorKey: columnKey,
            header: ({ column, header }) => (
              <DataTableColumnHeader
                column={column}
                title={columnHeaderMap[columnKey] ?? columnKey}
                header={header}
              />
            ),
            cell: ({ row, cell }) => {
              const widthValue = cell.column.getSize();
              return (
                <div className="flex items-center justify-center">
                  <PlantAllocation
                    row={row}
                    columnKey={columnKey}
                    widthValue={widthValue}
                    transformRenderedCellvalue={transformRenderedCellvalue}
                  />
                </div>
              );
            },
            size: 250,
            filterFn: (row, id, value) => {
              return value.includes(row.getValue(id));
            },
            enableSorting: true,
            enableHiding: true,
          };
        } else {
          columnMetaData = {
            accessorKey: columnKey,
            header: ({ column, header }) => (
              <DataTableColumnHeader
                column={column}
                title={columnHeaderMap[columnKey] ?? columnKey}
                header={header}
              />
            ),
            cell: ({ row, cell }) => {
              const widthValue = cell.column.getSize();
              return (
                <div className="flex items-center justify-center">
                  <div
                    className="antialiased truncate text-center"
                    style={{ width: widthValue, minWidth: "150px" }}
                  >
                    <span>
                      {transformRenderedCellvalue(row.getValue(columnKey))}
                    </span>
                  </div>
                </div>
              );
            },
            size: 250,
            filterFn: (row, id, value) => {
              return value.includes(row.getValue(id));
            },
            enableSorting: true,
            enableHiding: true,
          };
        }

        cols.push(columnMetaData);
      });
      cols.push({
        id: "actions",
        accessorKey: "Action",
        header: ({ column, header }) => (
          <div className="flex justify-center">
            <span className="font-semibold font-sans lg:text-[1.4rem] antialiased !px-[6px] lg:!px-[4px]">
              Action
            </span>
          </div>
        ),
        cell: ({ cell, row }) => {
          return <>{actionColumn(row.original)}</>;
        },
        enableHiding: false,
        size: 200,
      });
      if (cols.length > 0) {
        setColumns(cols);
        savedColumns.current = cols;
      }
      if (columnVisibility && Object.keys(columnVisibility).length === 0) {
        setColumnVisibility(visibleColumns);
      }
    }
    if (
      ppAppGroupPlanViewData &&
      ppAppGroupPlanViewData.column_mapping &&
      ppAppGroupPlanViewData.results.length > 0
    ) {
      prepareColumns();
      let ppPlanData = ppAppGroupPlanViewData.results.map((item) => ({
        ...item,
        subRows: [Array(10).fill({})],
      }));
      setTableData(ppPlanData);
    } else if (isSuccessPpAppGroupPlanViewData) {
      setTableData([]);
    }
    setResetExpanded(false);
  }, [ppAppGroupPlanViewData]);

  useEffect(() => {
    if (ppAppGroupPlanViewData && ppAppGroupPlanViewData.aggregated_data) {
      setKpiData(
        ppAppGroupPlanViewData && ppAppGroupPlanViewData.aggregated_data
          ? transformKpiData(ppAppGroupPlanViewData.aggregated_data)
          : {}
      );
    }
    if (ppAppGroupPlanViewData && ppAppGroupPlanViewData.results) {
      getSTOStatusValues(ppAppGroupPlanViewData.results);
      setSTOValues(ppAppGroupPlanViewData.results);
    }
  }, [ppAppGroupPlanViewData]);

  useEffect(() => {
    if (clickedWPO && isSuccessPpPlantAllocationData) {
      setTimeout(() => {
        handlePlantAllocationData(
          clickedWPO.wpo_id,
          clickedWPO.plant_id,
          clickedWPO.product_id,
          clickedWPO.date
        );
      }, 3000);
    }
  }, [clickedWPO, isSuccessPpPlantAllocationData, ppPlantAllocationData]);

  useEffect(() => {
    if (loadingData === true) {
      setColumns(loadingColumns());
    } else {
      if (savedColumns.current.length !== 0) {
        const cols = savedColumns.current;
        setColumns([...cols]);
      }
    }
  }, [loadingData]);

  const refetchData = () => {
    fetch(
      `${process.env.REACT_APP_SCAI_API_ENDPOINT}/v1/pp_detail_view/${planId}/`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
        },
      }
    )
      .then(handleApiResponse)
      .then((updatedData) => {
        setLoadingData(false);
        if (updatedData && updatedData.results) {
          let ppPlanData = updatedData.results.map((item) => ({
            ...item,
            subRows: [Array(10).fill({})],
          }));
          setTableData(ppPlanData);
        } else {
          setTableData([]);
        }

        if (updatedData && updatedData.results) {
          getSTOStatusValues(updatedData.results);
        }
      })
      .catch((error) => {
        setLoadingData(false);
        if (error.message) {
          toast.error(error.message);
        }
      });
  };

  const handlePlantAllocationData = (wpo_id, plant_id, product_id, date) => {
    try {
      setSubColumns(plantToWareHouseColumns);
      setTableData((prev) =>
        prev.map((plan) =>
          plan.wpo_id === wpo_id
            ? { ...plan, subRows: ppPlantAllocationData }
            : plan
        )
      );
    } catch (error) {
      console.log("error");
    }
  };

  const handleSTOUpload = () => {
    setopenStoUploadModal(true);
  };

  const setSTOValues = (data) => {
    let editValuesTemp = {};
    data.forEach((item) => {
      editValuesTemp[item.wpo_id] = item;
    });
    dispatch(setOriginalSTOValues(editValuesTemp));
  };

  const getSTOStatusValues = (data) => {
    let approvedCount = 0;
    let pendingCount = 0;
    let declinedCount = 0;
    data.forEach((plan) => {
      if (plan.status === "accepted") {
        approvedCount += 1;
      } else if (plan.status === "pending") {
        pendingCount += 1;
      } else {
        declinedCount += 1;
      }
    });

    let stoStatusValuesCalculated = {
      all: approvedCount + pendingCount + declinedCount,
      approved: approvedCount,
      declined: declinedCount,
      pending: pendingCount,
    };
    setStoStatusvalues({ ...stoStatusValues, ...stoStatusValuesCalculated });
  };

  const filterTableData = (tableData) => {
    let filteredData = tableData;
    if (selectedTab === "accepted") {
      filteredData = tableData.filter((row) => row.status === "accepted");
    } else if (selectedTab === "rejected") {
      filteredData = tableData.filter((row) => row.status === "rejected");
    } else if (selectedTab === "pending") {
      filteredData = tableData.filter((row) => row.status === "pending");
    } else {
      filteredData = tableData;
    }
    return filteredData;
  };

  const handleSingleSTOApproval = (stoId, approvalStatus) => {
    let stoSingleApprovalStatus = { [stoId]: approvalStatus };
    handleSTOApprovalStatus(null, false, stoSingleApprovalStatus);
  };

  const handlePlanApproval = async (comment, planId, approveRejectValue) => {
    if (approveRejectValue === "APPROVED") {
      setapproveLoading(true);
    } else if (approveRejectValue === "REJECTED") {
      setRejectLoading(true);
    } else {
      setSubmitLoading(true);
    }
    const formData = new FormData();
    const approvalDetails = {
      action: approveRejectValue,
      approvable_type: "PP",
      approvables: [
        {
          id: planId,
          comment: comment,
          attachment_ids: [],
        },
      ],
    };
    formData.append("approval_details", JSON.stringify(approvalDetails));

    fetch(`${process.env.REACT_APP_SCAI_API_ENDPOINT}/v1/approval/`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
      },
      body: formData,
    })
      .then(handleApiArrayResponse)
      .then((data) => {
        const { message, approvals_info, approvable_details } = data;
        const approvableId = approvals_info[0].approvable_id;
        // const indexOfObjectToUpdate = tableData.findIndex((item) => {
        //   if (item.planId === approvableId) {
        //     return true;
        //   }
        // });
        // const planToBeUpdated = tableData[indexOfObjectToUpdate];
        const updatedPlan = {
          ...groupPlanDetails,
          isPlanLive: approvable_details[0].plan_live,
          planStatus:
            approvable_details[0].status === "APPROVAL_IN_PROGRESS"
              ? "Approval In Progress"
              : transformStatus(approvable_details[0].status),
          approvalId: approvals_info[0].approval_id,
          approvalDetails: {
            approvalId: approvals_info[0].approval_id,
            permissions: {
              canSubmit: approvals_info[0].permissions.canSubmit,
              canApprove: approvals_info[0].permissions.canApprove,
              canRevoke: approvals_info[0].permissions.canRevoke,
              canReject: approvals_info[0].permissions.canReject,
            },
            approvalStatus: approvals_info[0].approval_status,
            levelwiseApprovalStatus:
              approvals_info[0].levelwise_approval_status.map((element) => ({
                level: element.level,
                status: element.approval_status,
              })),
          },
        };
        // const newTableData = [...tableData];
        // newTableData[indexOfObjectToUpdate] = updatedPlan;
        // setTableData([...newTableData]);
        setgroupPlanDetails({ ...updatedPlan });
        dispatch(setPpGroupPlanViewDetails({ ...updatedPlan }));
        toast.success(`${planId} is ${message}`, { autoClose: 3000 });
        if (approveRejectValue === "APPROVED") {
          setapproveLoading(false);
        } else if (approveRejectValue === "REJECTED") {
          setRejectLoading(false);
        } else {
          setSubmitLoading(false);
        }
      })
      .catch((error) => {
        if (approveRejectValue === "APPROVED") {
          setapproveLoading(false);
        } else if (approveRejectValue === "REJECTED") {
          setRejectLoading(false);
        } else {
          setSubmitLoading(false);
        }
        toast.error(error.message, { autoClose: 4000 });
      });
  };

  // const renderSTOTableHeader = () => {
  //   const { approvalDetails, approvalId, planStatus, isPlanLive } =
  //     groupPlanDetails;
  //   const approveRejectDisable = () => {
  //     if (approvalId !== null && approvalDetails) {
  //       if (
  //         approvalDetails.permissions.canApprove === true &&
  //         approvalDetails.permissions.canReject === true
  //       ) {
  //         return false;
  //       } else {
  //         return true;
  //       }
  //     }
  //   };
  //   let userDetails = JSON.parse(localStorage.getItem("user"));
  //   const { permissions } = userDetails;
  //   const isPlanner = permissions.includes("STO_SUBMIT_APPROVAL");
  //   const isApprover = permissions.includes("STO_APPROVE_REJECT");
  //   return (
  //     <div
  //       style={{
  //         display: "flex",
  //         flexDirection: "column",
  //         gap: "10px",
  //         marginBottom: "6px",
  //         marginTop: "auto",
  //       }}
  //     >
  //       {!isGroupLevelEditModeOn && (
  //         <div
  //           style={{
  //             display: "flex",
  //             alignItems: "center",
  //             gap: "15px",
  //           }}
  //         >
  //           {" "}
  //           {approvalId !== null &&
  //             approvalDetails &&
  //             approvalDetails.levelwiseApprovalStatus.length !== 0 && (
  //               <div
  //                 style={{
  //                   display: "flex",
  //                   alignItems: "center",
  //                   gap: "15px",
  //                   border: "1px solid #E6E6E6",
  //                   borderRadius: "50px",
  //                   padding: "5px 10px",
  //                 }}
  //               >
  //                 <span
  //                   style={{
  //                     fontFamily: "Inter",
  //                     fontSize: "12px",
  //                     fontWeight: "400",
  //                     color: "#3D445C",
  //                     marginBottom: "unset",
  //                   }}
  //                 >
  //                   Approval Status:
  //                 </span>
  //                 <div
  //                   style={{
  //                     display: "flex",
  //                     gap: "10px",
  //                     justifyContent: "center",
  //                   }}
  //                 >
  //                   {" "}
  //                   {approvalId === null && (
  //                     <>
  //                       {/* <div className={`status-indicator-app awaiting-status}`}></div>
  //             <div className={`status-indicator-app awaiting-status}`}></div> */}
  //                     </>
  //                   )}
  //                   {approvalId !== null &&
  //                     approvalDetails &&
  //                     approvalDetails.levelwiseApprovalStatus.length !== 0 &&
  //                     approvalDetails.levelwiseApprovalStatus.map(
  //                       (levelItem, index) => (
  //                         <div
  //                           className={`status-indicator-app ${
  //                             levelItem.status !== "APPROVED"
  //                               ? levelItem.status !== "REJECTED"
  //                                 ? levelItem.status !== "PENDING"
  //                                   ? "awaiting-status"
  //                                   : "pending-status"
  //                                 : "rejected-status"
  //                               : "approved-status"
  //                           }`}
  //                           key={index}
  //                         ></div>
  //                       )
  //                     )}
  //                 </div>
  //               </div>
  //             )}
  //           {isPlanner && approvalId === null && (
  //             <div style={{ display: "flex" }}>
  //               <div
  //                 style={{
  //                   border: "1px solid #d9d9d9",
  //                   borderRadius: "19px",
  //                   padding: "2px 4px",
  //                   display: "flex",
  //                   justifyContent: "center",
  //                   alignItems: "center",
  //                   boxSizing: "border-box",
  //                   margin: "auto",
  //                   minWidth: "93px",
  //                   // opacity:
  //                   //   approvalDetails.permissions.canSubmit === true ||
  //                   //   approvalDetails.permissions.canRevoke ||
  //                   //   planStatus === "Planned"
  //                   //     ? 1
  //                   //     : 0.4,
  //                 }}
  //                 className="box-shadow-small"
  //                 // disabled={true}
  //               >
  //                 {submitLoading ? (
  //                   <div
  //                     style={{
  //                       paddingTop: "4px",
  //                       paddingBottom: "4px",
  //                     }}
  //                   >
  //                     <TableLoaderSpinner size={12} height="2vh" />
  //                   </div>
  //                 ) : (
  //                   <>
  //                     <span
  //                       style={{
  //                         fontSize: "11px",
  //                         marginRight: "0px",
  //                         marginBottom: "unset",
  //                         marginTop: "unset",
  //                         lineHeight: "unset",
  //                         minWidth: "50px",
  //                         textAlign: "center",
  //                       }}
  //                     >
  //                       {/* {approvalStatusValues[row.planId] ? "Send" : "Revoke"} */}
  //                       {/* {approvalId !== null && approvalDetails
  //                 ? approvalDetails.permissions.canSubmit === true
  //                   ? "Send"
  //                   : approvalDetails.permissions.canRevoke === true
  //                   ? "Revoke"
  //                   : "Send"
  //                 : null} */}
  //                       Send
  //                     </span>
  //                     <label
  //                       className="send-revoke-box small-switch"
  //                       onClick={() => {
  //                         setSelectedGroupPlan({ ...groupPlanDetails });
  //                         setOpenSendModal(true);
  //                         setApproveRejectValue("SUBMITTED");
  //                         // setSelectedGroupPlan({ ...groupPlanDetails });
  //                         // setOpenSendModal(true);
  //                         // setApproveRejectValue("SUBMITTED");
  //                         // if (
  //                         //   approvalDetails.permissions.canSubmit === true ||
  //                         //   approvalDetails.permissions.canRevoke === true
  //                         // ) {
  //                         //   setSelectedGroupPlan(row);
  //                         //   setOpenSendModal(true);
  //                         //   const actionValue =
  //                         //     approvalDetails.permissions.canSubmit === true
  //                         //       ? "SUBMITTED"
  //                         //       : approvalDetails.permissions.canRevoke === true
  //                         //       ? "REVOKED"
  //                         //       : "";
  //                         //   setApproveRejectValue(actionValue);
  //                         // }
  //                       }}
  //                       disabled={true}
  //                     >
  //                       <input
  //                         type="checkbox"
  //                         className="send-revoke-input"
  //                         // defaultChecked
  //                         disabled
  //                         // checked={!approvalStatusValues[row.planId]}
  //                         // checked={approvalDetails.permissions.canRevoke}
  //                       />
  //                       <span className="send-revoke-slider send-revoke-round"></span>
  //                     </label>
  //                   </>
  //                 )}
  //               </div>
  //             </div>
  //           )}
  //           {isPlanner && approvalId !== null && approvalDetails && (
  //             <div style={{ display: "flex" }}>
  //               <div
  //                 style={{
  //                   border: "1px solid #d9d9d9",
  //                   borderRadius: "19px",
  //                   padding: "2px 4px",
  //                   display: "flex",
  //                   justifyContent: "center",
  //                   alignItems: "center",
  //                   boxSizing: "border-box",
  //                   margin: "auto",
  //                   minWidth: "93px",
  //                   opacity:
  //                     approvalDetails.permissions.canSubmit === true ||
  //                     approvalDetails.permissions.canRevoke
  //                       ? 1
  //                       : 0.4,
  //                 }}
  //                 // disabled={true}
  //               >
  //                 {submitLoading ? (
  //                   <div
  //                     style={{
  //                       paddingTop: "4px",
  //                       paddingBottom: "4px",
  //                     }}
  //                   >
  //                     <TableLoaderSpinner size={12} height="2vh" />
  //                   </div>
  //                 ) : (
  //                   <>
  //                     {" "}
  //                     <span
  //                       style={{
  //                         fontSize: "11px",
  //                         marginRight: "0px",
  //                         marginBottom: "unset",
  //                         marginTop: "unset",
  //                         lineHeight: "unset",
  //                         minWidth: "50px",
  //                         textAlign: "center",
  //                       }}
  //                     >
  //                       {/* {approvalStatusValues[row.planId] ? "Send" : "Revoke"} */}
  //                       {approvalId !== null && approvalDetails
  //                         ? approvalDetails.permissions.canSubmit === true
  //                           ? "Send"
  //                           : approvalDetails.permissions.canRevoke === true
  //                           ? "Revoke"
  //                           : "Revoke"
  //                         : null}
  //                     </span>
  //                     <label
  //                       className="send-revoke-box small-switch"
  //                       onClick={() => {
  //                         if (
  //                           approvalDetails.permissions.canSubmit === true ||
  //                           approvalDetails.permissions.canRevoke === true
  //                         ) {
  //                           setSelectedGroupPlan({ ...groupPlanDetails });
  //                           setOpenSendModal(true);
  //                           const actionValue =
  //                             approvalDetails.permissions.canSubmit === true
  //                               ? "SUBMITTED"
  //                               : approvalDetails.permissions.canRevoke === true
  //                               ? "REVOKED"
  //                               : "";
  //                           setApproveRejectValue(actionValue);
  //                         }
  //                       }}
  //                       disabled={true}
  //                     >
  //                       <input
  //                         type="checkbox"
  //                         className="send-revoke-input"
  //                         // defaultChecked
  //                         disabled
  //                         // checked={!approvalStatusValues[row.planId]}
  //                         checked={approvalDetails.permissions.canRevoke}
  //                       />
  //                       <span className="send-revoke-slider send-revoke-round"></span>
  //                     </label>
  //                   </>
  //                 )}
  //               </div>
  //             </div>
  //           )}
  //           {isApprover && approvalId !== null && approvalDetails && (
  //             <div style={{ display: "flex" }}>
  //               <div className="approval-btns">
  //                 <button
  //                   className="approval-btn approve"
  //                   onClick={() => {
  //                     setOpenApproveRejectModal(true);
  //                     setSelectedGroupPlan({ ...groupPlanDetails });
  //                     setApproveRejectValue("APPROVED");
  //                   }}
  //                   disabled={
  //                     approveRejectDisable() || approveLoading || rejectLoading
  //                   }
  //                 >
  //                   {approveLoading ? (
  //                     <TableLoaderSpinner size={12} height="2vh" />
  //                   ) : (
  //                     "Approve"
  //                   )}
  //                   {/* <TableLoaderSpinner size={12} height="2vh" /> */}
  //                 </button>
  //                 <button
  //                   className="approval-btn reject"
  //                   onClick={() => {
  //                     setOpenApproveRejectModal(true);
  //                     setApproveRejectValue("REJECTED");
  //                     setSelectedGroupPlan({ ...groupPlanDetails });
  //                   }}
  //                   disabled={
  //                     approveRejectDisable() || approveLoading || rejectLoading
  //                   }
  //                 >
  //                   {rejectLoading ? (
  //                     <TableLoaderSpinner size={12} height="2vh" />
  //                   ) : (
  //                     "Reject"
  //                   )}
  //                 </button>
  //               </div>
  //             </div>
  //           )}
  //           <button
  //             className={
  //               // planStatus !== "Approved" ||
  //               // (isApprover && planStatus !== "Live")
  //               isPlanLive !== true || (!isPlanner && planStatus !== "Live")
  //                 ? planStatus !== "Live"
  //                   ? "grp-action-live-btn grp-action-live-btn-view box-shadow-small"
  //                   : "grp-action-live-btn live-btn-enable grp-action-live-btn-view box-shadow-small"
  //                 : "grp-action-live-btn grp-action-live-enable grp-action-live-btn-view box-shadow-small"
  //             }
  //             style={{ margin: "auto", marginTop: "12px" }}
  //             // disabled={
  //             //   planStatus !== "Approved" ||
  //             //   (isApprover && planStatus !== "Live")
  //             // }
  //             disabled={!isPlanLive || !isPlanner}
  //             onClick={() => {
  //               handleMakeLive({ ...groupPlanDetails });
  //             }}
  //           >
  //             {loadingMakePlanLive ? (
  //               <div
  //                 style={{
  //                   padding: "3px 5.5px",
  //                 }}
  //               >
  //                 <TableLoaderSpinner size={12} height="2vh" />
  //               </div>
  //             ) : (
  //               "Live"
  //             )}
  //           </button>
  //         </div>
  //       )}
  //       <div style={{ display: "flex", gap: "15px" }}>
  //         <div
  //           className={`box-shadow-small sto-card ${
  //             selectedTab === "all" ? "sto-card-active" : ""
  //           }`}
  //           onClick={() => {
  //             setClearRowSelection(true);
  //             setSelectedTab("all");
  //           }}
  //         >
  //           <span className="sto-card-heading">All</span>
  //           <span>{stoStatusValues.all}</span>
  //         </div>
  //         <div
  //           className={`box-shadow-small sto-card ${
  //             selectedTab === "accepted" ? "sto-card-active" : ""
  //           }`}
  //           onClick={() => {
  //             setClearRowSelection(true);
  //             setSelectedTab("accepted");
  //           }}
  //         >
  //           <span>Accepted</span>
  //           <span>{stoStatusValues.approved}</span>
  //         </div>
  //         <div
  //           className={`box-shadow-small sto-card ${
  //             selectedTab === "pending" ? "sto-card-active" : ""
  //           }`}
  //           onClick={() => {
  //             setClearRowSelection(true);
  //             setSelectedTab("pending");
  //           }}
  //         >
  //           <span style={{ background: "transparent" }}>Pending</span>
  //           <span>{stoStatusValues.pending}</span>
  //         </div>
  //         <div
  //           className={`box-shadow-small sto-card ${
  //             selectedTab === "rejected" ? "sto-card-active" : ""
  //           }`}
  //           onClick={() => {
  //             setClearRowSelection(true);
  //             setSelectedTab("rejected");
  //           }}
  //         >
  //           <span style={{ background: "unset" }}>Declined</span>
  //           <span>{stoStatusValues.declined}</span>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  const renderSTOTableHeader = () => {
    const { approvalDetails, approvalId, planStatus, isPlanLive } =
      groupPlanDetails;
    const approveRejectDisable = () => {
      if (approvalId !== null && approvalDetails) {
        if (
          approvalDetails.permissions.canApprove === true &&
          approvalDetails.permissions.canReject === true
        ) {
          return false;
        } else {
          return true;
        }
      }
    };
    let userDetails = JSON.parse(localStorage.getItem("user"));
    const { permissions } = userDetails;
    const isPlanner = permissions.includes("PP_SUBMIT_APPROVAL");
    const isApprover = permissions.includes("PP_APPROVE_REJECT");
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          marginTop: "auto",
        }}
      >
        {!isGroupLevelEditModeOn && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "15px",
            }}
          >
            {" "}
            {approvalId !== null &&
              approvalDetails &&
              approvalDetails.levelwiseApprovalStatus.length !== 0 && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "15px",
                    border: "1px solid #E6E6E6",
                    borderRadius: "50px",
                    padding: "5px 10px",
                  }}
                >
                  <span
                    style={{
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#3D445C",
                      marginBottom: "unset",
                    }}
                  >
                    Approval Status:
                  </span>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    {approvalId === null && (
                      <>
                        {/* <div className={`status-indicator-app awaiting-status}`}></div>
              <div className={`status-indicator-app awaiting-status}`}></div> */}
                      </>
                    )}
                    {approvalId !== null &&
                      approvalDetails &&
                      approvalDetails.levelwiseApprovalStatus.length !== 0 &&
                      approvalDetails.levelwiseApprovalStatus.map(
                        (levelItem, index) => (
                          <div
                            className={`status-indicator-app ${
                              levelItem.status !== "APPROVED"
                                ? levelItem.status !== "REJECTED"
                                  ? levelItem.status !== "PENDING"
                                    ? "awaiting-status"
                                    : "pending-status"
                                  : "rejected-status"
                                : "approved-status"
                            }`}
                            key={index}
                          ></div>
                        )
                      )}
                  </div>
                </div>
              )}
            {isPlanner && approvalId === null && (
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    border: "1px solid #d9d9d9",
                    borderRadius: "19px",
                    padding: "2px 4px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    boxSizing: "border-box",
                    margin: "auto",
                    minWidth: "93px",
                    // opacity:
                    //   approvalDetails.permissions.canSubmit === true ||
                    //   approvalDetails.permissions.canRevoke ||
                    //   planStatus === "Planned"
                    //     ? 1
                    //     : 0.4,
                  }}
                  className=""
                  // disabled={true}
                >
                  {submitLoading ? (
                    <div
                      style={{
                        paddingTop: "4px",
                        paddingBottom: "4px",
                      }}
                    >
                      <TableLoaderSpinner size={12} height="2vh" />
                    </div>
                  ) : (
                    <>
                      <span
                        style={{
                          fontSize: "11px",
                          marginRight: "0px",
                          marginBottom: "unset",
                          marginTop: "unset",
                          lineHeight: "unset",
                          minWidth: "50px",
                          textAlign: "center",
                        }}
                      >
                        {/* {approvalStatusValues[row.planId] ? "Send" : "Revoke"} */}
                        {/* {approvalId !== null && approvalDetails
                  ? approvalDetails.permissions.canSubmit === true
                    ? "Send"
                    : approvalDetails.permissions.canRevoke === true
                    ? "Revoke"
                    : "Send"
                  : null} */}
                        Send
                      </span>
                      <label
                        className="send-revoke-box small-switch"
                        onClick={() => {
                          setSelectedGroupPlan({ ...groupPlanDetails });
                          setOpenSendModal(true);
                          setApproveRejectValue("SUBMITTED");
                          // setSelectedGroupPlan({ ...groupPlanDetails });
                          // setOpenSendModal(true);
                          // setApproveRejectValue("SUBMITTED");
                          // if (
                          //   approvalDetails.permissions.canSubmit === true ||
                          //   approvalDetails.permissions.canRevoke === true
                          // ) {
                          //   setSelectedGroupPlan(row);
                          //   setOpenSendModal(true);
                          //   const actionValue =
                          //     approvalDetails.permissions.canSubmit === true
                          //       ? "SUBMITTED"
                          //       : approvalDetails.permissions.canRevoke === true
                          //       ? "REVOKED"
                          //       : "";
                          //   setApproveRejectValue(actionValue);
                          // }
                        }}
                        disabled={true}
                      >
                        <input
                          type="checkbox"
                          className="send-revoke-input"
                          // defaultChecked
                          disabled
                          // checked={!approvalStatusValues[row.planId]}
                          // checked={approvalDetails.permissions.canRevoke}
                        />
                        <span className="send-revoke-slider send-revoke-round"></span>
                      </label>
                    </>
                  )}
                </div>
              </div>
            )}
            {isPlanner && approvalId !== null && approvalDetails && (
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    border: "1px solid #d9d9d9",
                    borderRadius: "19px",
                    padding: "2px 4px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    boxSizing: "border-box",
                    margin: "auto",
                    minWidth: "93px",
                    opacity:
                      approvalDetails.permissions.canSubmit === true ||
                      approvalDetails.permissions.canRevoke
                        ? 1
                        : 0.4,
                  }}
                  // disabled={true}
                >
                  {submitLoading ? (
                    <div
                      style={{
                        paddingTop: "4px",
                        paddingBottom: "4px",
                      }}
                    >
                      <TableLoaderSpinner size={12} height="2vh" />
                    </div>
                  ) : (
                    <>
                      {" "}
                      <span
                        style={{
                          fontSize: "11px",
                          marginRight: "0px",
                          marginBottom: "unset",
                          marginTop: "unset",
                          lineHeight: "unset",
                          minWidth: "50px",
                          textAlign: "center",
                        }}
                      >
                        {/* {approvalStatusValues[row.planId] ? "Send" : "Revoke"} */}
                        {approvalId !== null && approvalDetails
                          ? approvalDetails.permissions.canSubmit === true
                            ? "Send"
                            : approvalDetails.permissions.canRevoke === true
                            ? "Revoke"
                            : "Revoke"
                          : null}
                      </span>
                      <label
                        className="send-revoke-box small-switch"
                        onClick={() => {
                          if (
                            approvalDetails.permissions.canSubmit === true ||
                            approvalDetails.permissions.canRevoke === true
                          ) {
                            setSelectedGroupPlan({ ...groupPlanDetails });
                            setOpenSendModal(true);
                            const actionValue =
                              approvalDetails.permissions.canSubmit === true
                                ? "SUBMITTED"
                                : approvalDetails.permissions.canRevoke === true
                                ? "REVOKED"
                                : "";
                            setApproveRejectValue(actionValue);
                          }
                        }}
                        disabled={true}
                      >
                        <input
                          type="checkbox"
                          className="send-revoke-input"
                          // defaultChecked
                          disabled
                          // checked={!approvalStatusValues[row.planId]}
                          checked={approvalDetails.permissions.canRevoke}
                        />
                        <span className="send-revoke-slider send-revoke-round"></span>
                      </label>
                    </>
                  )}
                </div>
              </div>
            )}
            {isApprover && approvalId !== null && approvalDetails && (
              <div style={{ display: "flex" }}>
                <div className="approval-btns">
                  <button
                    className="approval-btn approve"
                    onClick={() => {
                      setOpenApproveRejectModal(true);
                      setSelectedGroupPlan({ ...groupPlanDetails });
                      setApproveRejectValue("APPROVED");
                    }}
                    disabled={
                      approveRejectDisable() || approveLoading || rejectLoading
                    }
                  >
                    {approveLoading ? (
                      <TableLoaderSpinner size={12} height="2vh" />
                    ) : (
                      "Approve"
                    )}
                    {/* <TableLoaderSpinner size={12} height="2vh" /> */}
                  </button>
                  <button
                    className="approval-btn reject"
                    onClick={() => {
                      setOpenApproveRejectModal(true);
                      setApproveRejectValue("REJECTED");
                      setSelectedGroupPlan({ ...groupPlanDetails });
                    }}
                    disabled={
                      approveRejectDisable() || approveLoading || rejectLoading
                    }
                  >
                    {rejectLoading ? (
                      <TableLoaderSpinner size={12} height="2vh" />
                    ) : (
                      "Reject"
                    )}
                  </button>
                </div>
              </div>
            )}
            <button
              className={`flex items-center h-[30px] px-[10px] rounded-[5px] mb-[10px]  ${
                isPlanLive !== true || (!isPlanner && planStatus !== "Live")
                  ? planStatus !== "Live"
                    ? "grp-action-live-btn grp-action-live-btn-view "
                    : "grp-action-live-btn live-btn-enable grp-action-live-btn-view "
                  : "grp-action-live-btn grp-action-live-enable grp-action-live-btn-view "
              }`}
              style={{ margin: "auto", height: "23px", fontSize: "12px" }}
              disabled={!isPlanLive || !isPlanner}
              onClick={() => {
                handleMakeLive({ ...groupPlanDetails });
              }}
            >
              {loadingMakePlanLive ? (
                <div
                  style={{
                    padding: "3px 5.5px",
                  }}
                >
                  <TableLoaderSpinner size={12} height="2vh" />
                </div>
              ) : (
                <span className="font-[Inter] text-[13px] m-0 px-[5px] ">
                  Live
                </span>
              )}
            </button>
          </div>
        )}

        <div
          style={{ display: "flex", gap: "10px" }}
          className="font-[Inter] text-[13px] antialiased"
        >
          <div
            className={` sto-card ${
              selectedTab === "all" ? "sto-card-active" : ""
            }`}
            onClick={() => {
              setClearRowSelection(true);
              setSelectedTab("all");
            }}
          >
            <span className="sto-card-heading text-[13px]">All</span>
            <span>{stoStatusValues.all}</span>
          </div>
          <div
            className={` sto-card ${
              selectedTab === "accepted" ? "sto-card-active" : ""
            }`}
            onClick={() => {
              setClearRowSelection(true);
              setSelectedTab("accepted");
            }}
          >
            <span className="text-[13px]">Accepted</span>
            <span>{stoStatusValues.approved}</span>
          </div>
          <div
            className={` sto-card ${
              selectedTab === "pending" ? "sto-card-active" : ""
            }`}
            onClick={() => {
              setClearRowSelection(true);
              setSelectedTab("pending");
            }}
          >
            <span style={{ background: "transparent" }} className="text-[13px]">
              Pending
            </span>
            <span>{stoStatusValues.pending}</span>
          </div>
          <div
            className={` sto-card ${
              selectedTab === "rejected" ? "sto-card-active" : ""
            }`}
            onClick={() => {
              setClearRowSelection(true);
              setSelectedTab("rejected");
            }}
          >
            <span style={{ background: "unset" }} className="text-[13px]">
              Declined
            </span>
            <span>{stoStatusValues.declined}</span>
          </div>
        </div>
      </div>
    );
  };

  const AddIconTemp = () => (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 6.99966C14 7.29582 13.7577 7.53813 13.4615 7.53813H7.53846V13.4612C7.53846 13.7588 7.29764 14 7 14C6.70237 14 6.46154 13.7574 6.46154 13.4612V7.53813H0.538462C0.240827 7.53813 0 7.2975 0 7C0 6.70351 0.240962 6.4612 0.538462 6.4612H6.46154V0.538125C6.46154 0.24049 6.70237 0 7 0C7.29764 0 7.53846 0.240625 7.53846 0.538125V6.4612H13.4615C13.7577 6.4612 14 6.70351 14 6.99966Z"
        fill="white"
      />
    </svg>
  );

  const handlePwoDownload = () => {
    setisDownloading(true);
    fetch(
      `${process.env.REACT_APP_SCAI_API_ENDPOINT}/v1/pp_detail_view/${planId}/?action=download`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
        },
      }
    )
      .then(handleDownloadApiResponse)
      .then((data) => {
        setisDownloading(false);
        var a = document.createElement("a");
        a.href = window.URL.createObjectURL(data);
        a.download = `${planId}.csv`;
        a.click();
      })
      .catch((error) => {
        setisDownloading(false);
        toast.error(error.message, { autoClose: 2000 });
      });
  };

  const handleMakeLive = (row) => {
    setSelectedGroupPlan({ ...row });
    setOpenMakeLiveModal(true);
  };

  const makePlanLive = async (planId) => {
    const requestBody = {
      plan_id: planId,
    };
    try {
      const response = await planLive({
        planId,
        requestBody: requestBody,
      });
      if (response.error) {
        if (
          response.error.originalStatus &&
          response.error.originalStatus === 500
        ) {
          throw new Error("Something went wrong.!");
        } else {
          throw new Error(response.error.data.error);
        }
      } else {
        const updatedGroupPlanDetails = { ...groupPlanDetails };
        updatedGroupPlanDetails["planStatus"] = "Live";
        setgroupPlanDetails({ ...updatedGroupPlanDetails });
        dispatch(setPpGroupPlanViewDetails({ ...updatedGroupPlanDetails }));
        toast.success(response.data.success, { autoClose: 3000 });
      }
    } catch (error) {
      toast.error(error.message, { autoClose: 3000 });
    }
  };

  const handleAllEditSTOSave = async () => {
    setLoadingData(true);
    setSubColumns(loadingPlantToWareHouseColumns());
    try {
      const response = await updateWPO({
        planId,
        requestBody: editedSTOList,
      });
      if (response.error) {
        if (
          response.error.originalStatus &&
          response.error.originalStatus === 500
        ) {
          throw new Error("Something went wrong.!");
        } else {
          throw new Error(response.error.data.error);
        }
      } else {
        toast.success(response.data.message, { autoClose: 3000 });
      }
      setResetExpanded(true);
      refetchData();
    } catch (error) {
      setSubColumns(plantToWareHouseColumns);
      toast.error(error.message, { autoClose: 3000 });
      setLoadingData(false);
    } finally {
      // setLoadingData(false);
      dispatch(setIsGroupLevelEditModeOn(false));
      dispatch(setEditedSTOList({ type: "RESET" }));
    }
  };

  const transformStatusValues = (selectedSTO) => {
    const selectedSTORequestBody = {};
    Object.keys(selectedSTO).forEach((key) => {
      selectedSTORequestBody[key] = selectedSTO[key] ? "accepted" : "rejected";
    });
    return selectedSTORequestBody;
  };

  // const acceptSelectedSTO = (selectedSTO) => {
  //   const selectedSTORequestBody = {};
  //   Object.keys(selectedSTO).forEach((key) => {
  //     selectedSTORequestBody[key] = "accepted";
  //   });
  //   return selectedSTORequestBody;
  // };

  // const rejectSelectedSTO = (selectedSTO) => {
  //   const selectedSTORequestBody = {};
  //   Object.keys(selectedSTO).forEach((key) => {
  //     selectedSTORequestBody[key] = "rejected";
  //   });
  //   return selectedSTORequestBody;
  // };

  const handleSTOApprovalStatus = async (
    updateStatus,
    multiEnable,
    stoSingleSelected
  ) => {
    let requestBody;
    if (multiEnable) {
      requestBody = {
        action: updateStatus ? "accepted" : "rejected",
        ids: Object.keys(removeDuplicateKeys(selectedSTO)),
      };
    } else {
      let key = Object.keys(stoSingleSelected);
      requestBody = {
        action: stoSingleSelected[key] === true ? "accepted" : "rejected",
        ids: Object.keys(stoSingleSelected),
      };
    }
    setLoadingData(true);
    try {
      let response = await updateWPOStatus({ planId, requestBody });
      if (response.error) {
        if (
          response.error.originalStatus &&
          response.error.originalStatus === 500
        ) {
          throw new Error("Something went wrong.!");
        } else {
          throw new Error(response.error.data.error);
        }
      } else {
        toast.success(response.data.message, { autoClose: 3000 });
        setClearRowSelection(true);
        refetchData();
      }
    } catch (error) {
      toast.error(error.message, { autoClose: 3000 });
      setLoadingData(false);
    }
  };

  const handleEditField = () => {
    // firstEditField.current.scrollIntoView({
    //   behavior: "smooth",
    //   block: "start",
    //   inline: "center",
    // });
    dispatch(setIsGroupLevelEditModeOn(true));
  };

  const renderSTORightHeader = () => {
    return (
      <div
        style={{
          marginLeft: "auto",
          height: "30px",
          display: "flex",
          alignItems: "center",
          marginBottom: "10px",
          justifyContent: "flex-end",
        }}
      >
        {isGroupLevelEditModeOn ? (
          <>
            <button
              className="sto-view-cancel-button"
              onClick={() => {
                console.log("debug");
                dispatch(setEditedSTOList({ type: "RESET" }));
                dispatch(setIsGroupLevelEditModeOn(false));
              }}
            >
              Cancel
            </button>
            <button
              className="sto-view-save-button"
              disabled={
                isLoadingUpdateSTO ||
                (editedSTOList != null ? editedSTOList.length === 0 : true)
              }
              onClick={handleAllEditSTOSave}
            >
              Save
            </button>
          </>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                gap: "10px",
                height: "100%",
                alignItems: "center",
                marginRight: "10px",
              }}
            >
              {" "}
              <Tooltip content="Upload" placement="top">
                <button
                  className="sto-upload h-[30px] border rounded-[5px] border-[#E0E3EB] px-[10px]"
                  onClick={handleSTOUpload}
                  // disabled={isUploading || !uploadEnable}
                  disabled={true}
                >
                  {isUploading ? (
                    <TableLoaderSpinner size={12} />
                  ) : (
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.87338 3.63531L5.6249 1.21028V8.62506C5.6249 8.83234 5.79261 9.00006 5.99989 9.00006C6.20717 9.00006 6.37488 8.83234 6.37488 8.62506V1.21028L9.12639 3.65476C9.1967 3.71968 9.28576 3.75015 9.37483 3.75015C9.47809 3.75015 9.58135 3.70768 9.65537 3.62418C9.79306 3.46963 9.77841 3.23231 9.62387 3.09497L6.24893 0.0950256C6.10686 -0.0316752 5.89292 -0.0316752 5.75089 0.0950256L2.37596 3.09497C2.22136 3.23219 2.20683 3.46891 2.34371 3.62359C2.48058 3.77828 2.72104 3.79234 2.87338 3.63531ZM10.4998 7.50008H7.87485C7.66776 7.50008 7.49986 7.66794 7.49986 7.87508C7.49986 8.08217 7.66776 8.25007 7.87485 8.25007H10.4998C10.9139 8.25007 11.2498 8.58592 11.2498 9.00006V10.5C11.2498 10.9142 10.9139 11.25 10.4998 11.25H1.49997C1.08584 11.25 0.749986 10.9142 0.749986 10.5V9.00006C0.749986 8.58592 1.08584 8.25007 1.49997 8.25007H4.12492C4.33117 8.25007 4.49992 8.08132 4.49992 7.87508C4.49992 7.66883 4.33117 7.50008 4.12492 7.50008H1.49997C0.671472 7.50008 0 8.17155 0 9.00006V10.5C0 11.3285 0.671472 12 1.49997 12H10.4998C11.3283 12 11.9998 11.3285 11.9998 10.5V9.00006C11.9998 8.17273 11.3271 7.50008 10.4998 7.50008ZM10.3123 9.75004C10.3123 9.4395 10.0604 9.18755 9.74982 9.18755C9.43928 9.18755 9.18733 9.4395 9.18733 9.75004C9.18733 10.0606 9.43928 10.3125 9.74982 10.3125C10.0604 10.3125 10.3123 10.0618 10.3123 9.75004Z"
                        fill="black"
                      />
                    </svg>
                  )}
                </button>
              </Tooltip>
              <Tooltip content="Download">
                <button
                  className="sto-upload h-[30px] border rounded-[5px] border-[#E0E3EB] px-[10px]"
                  onClick={handlePwoDownload}
                  disabled={isDownloadingSto || !downloadEnable}
                >
                  {isDownloadingSto ? (
                    <TableLoaderSpinner size={12} height="0vh" />
                  ) : (
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="red"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.75156 8.88516C5.82187 8.96953 5.91094 9 6 9C6.08906 9 6.17798 8.9685 6.24891 8.90515L9.62391 5.90515C9.77845 5.76783 9.7931 5.53062 9.65541 5.37593C9.51771 5.22066 9.279 5.20638 9.12595 5.3448L6.375 7.79062V0.375C6.375 0.167719 6.20625 0 6 0C5.79375 0 5.625 0.167719 5.625 0.375V7.79062L2.87344 5.34375C2.72109 5.20547 2.48203 5.22187 2.34375 5.37656C2.20688 5.51016 2.22141 5.76797 2.37656 5.88516L5.75156 8.88516ZM10.5 7.5H9.375C9.16791 7.5 9 7.66786 9 7.875C9 8.08209 9.16791 8.25 9.375 8.25H10.5C10.9141 8.25 11.25 8.58586 11.25 9V10.5C11.25 10.9141 10.9141 11.25 10.5 11.25H1.5C1.08586 11.25 0.75 10.9141 0.75 10.5V9C0.75 8.58586 1.08586 8.25 1.5 8.25H2.625C2.83125 8.25 3 8.08125 3 7.875C3 7.66875 2.83125 7.5 2.625 7.5H1.5C0.671484 7.5 0 8.17148 0 9V10.5C0 11.3285 0.671484 12 1.5 12H10.5C11.3285 12 12 11.3285 12 10.5V9C12 8.17266 11.3273 7.5 10.5 7.5ZM10.3125 9.75C10.3125 9.43945 10.0605 9.1875 9.75 9.1875C9.43945 9.1875 9.1875 9.43945 9.1875 9.75C9.1875 10.0605 9.43945 10.3125 9.75 10.3125C10.0605 10.3125 10.3125 10.0617 10.3125 9.75Z"
                        fill="black"
                      />
                    </svg>
                  )}
                </button>
              </Tooltip>
            </div>
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                height: "100%",
              }}
            >
              {" "}
              <Tooltip content="Reject" placement="top">
                <button
                  className="sto-multi-reject h-[30px] border rounded-[5px] border-[#E0E3EB] px-[10px]"
                  onClick={() => handleSTOApprovalStatus(false, true)}
                  disabled={
                    Object.keys(selectedSTO).length === 0 || !rejectEnable
                  }
                >
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.8228 11.8228C11.5884 12.0573 11.209 12.0573 10.9743 11.8228L5.9999 6.84728L1.02435 11.8228C0.789893 12.0573 0.410558 12.0573 0.175841 11.8228C-0.0586137 11.5884 -0.0586137 11.209 0.175841 10.9743L5.15252 5.9999L0.176591 1.02435C-0.0578638 0.789893 -0.0578638 0.410558 0.176591 0.175841C0.411046 -0.0586137 0.79038 -0.0586137 1.0251 0.175841L5.9999 5.15252L10.9755 0.176966C11.2099 -0.0574889 11.5892 -0.0574889 11.824 0.176966C12.0584 0.411421 12.0584 0.790755 11.824 1.02547L6.84728 5.9999L11.8228 10.9755C12.0591 11.2079 12.0591 11.5904 11.8228 11.8228Z"
                      fill="#3D445C"
                    />
                  </svg>
                </button>
              </Tooltip>
              <Tooltip content="Accept" placement="top">
                <button
                  className="sto-multi-accept h-[30px] border rounded-[5px] border-[#E0E3EB] px-[10px]"
                  onClick={() => handleSTOApprovalStatus(true, true)}
                  disabled={
                    Object.keys(selectedSTO).length === 0 || !acceptEnable
                  }
                >
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11.839 2.17228C12.0682 2.42715 12.0506 2.82248 11.7996 3.05525L4.55608 9.774C4.3066 10.0054 3.92008 9.98925 3.69016 9.73781L0.16447 5.88218C-0.0668127 5.62926 -0.0524215 5.2338 0.196613 4.9989C0.445648 4.76401 0.835022 4.77862 1.0663 5.03155L4.17671 8.43303L10.9696 2.13226C11.2206 1.89948 11.6098 1.9174 11.839 2.17228Z"
                      fill="#3D445C"
                    />
                  </svg>
                </button>
              </Tooltip>
              <Tooltip content="Edit">
                <button
                  className="sto-edit h-[30px] border rounded-[5px] border-[#E0E3EB] px-[10px]"
                  onClick={handleEditField}
                  disabled={!editEnable}
                >
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.5591 1.31856L10.68 0.439443C10.3872 0.146528 10.0029 0 9.62086 0C9.23696 0 8.85306 0.146481 8.5601 0.439443L1.05722 7.94279C0.856408 8.14355 0.716677 8.39724 0.654569 8.67426L0.00747342 11.5523C-0.0398693 11.7677 0.144299 11.9997 0.373793 11.9997C0.398332 11.9997 0.423386 11.9973 0.448745 11.9922C0.448745 11.9922 2.43175 11.5715 3.32681 11.3611C3.59727 11.2974 3.83937 11.1613 4.03578 10.965L11.5637 3.43703C12.1474 2.85228 12.145 1.90449 11.5591 1.31856ZM3.5038 10.4341C3.40492 10.533 3.28696 10.5991 3.15319 10.6306C2.57476 10.7668 1.52103 10.9951 0.869017 11.135L1.38557 8.83792C1.41679 8.69922 1.48642 8.57285 1.58699 8.47231L7.38998 2.66931L9.32963 4.60895L3.5038 10.4341ZM11.0318 2.90619L9.85921 4.0785L7.91956 2.13886L9.08977 0.968651C9.2318 0.828029 9.4193 0.749983 9.62086 0.749983C9.82241 0.749983 10.0094 0.828029 10.1512 0.969705L11.0304 1.84883C11.3224 2.14144 11.3224 2.61557 11.0318 2.90619Z"
                      fill="#3D445C"
                    />
                  </svg>
                </button>{" "}
              </Tooltip>
              <button
                className="flex items-center bg-[#686867] h-[30px] rounded-[5px] px-[10px] add-btn-rm"
                onClick={() =>
                  navigate(
                    `/snp/inventory/replenishment-plan/group-plan/${planId}/add-sto`
                  )
                }
                // disabled={!addStoEnable}
                disabled={true}
              >
                {/* <img src={AddIconTemp} /> */}
                <AddIconTemp />
                {"  "}{" "}
                <span className="font-[Inter] text-[13px] text-white ml-[5px] disabled:text-[#d2d2d1]">
                  Add
                </span>
              </button>
            </div>
          </>
        )}
      </div>
    );
  };

  const getEditSTOApiBody = (sto, editedValue, reason, comments, wpoId) => {
    const editableField = editableFieldRef.current;
    dispatch(
      setEditedSTOList({
        type: "API_BODY",
        payload: { sto, editedValue, reason, comments, editableField, wpoId },
      })
    );
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const renderLeftTableFooterGroupLevel = () => {
    return (
      <div className="stos-legend-container ">
        <div className="stos-legend-item">
          <span className="stos-legend-item-color stos-legend-item-system"></span>
          <span>System </span>
        </div>
        <div className="stos-legend-item">
          <span className="stos-legend-item-color stos-legend-item-manual"></span>
          <span>Manual </span>
        </div>
        <div className="stos-legend-item">
          <span className="stos-legend-item-color stos-legend-item-edited"></span>
          <span>Edited </span>
        </div>
      </div>
    );
  };

  const GroupLevelToolbar = ({ table }) => {
    return (
      <div className="flex justify-between items-center">
        {renderSTOTableHeader()}
        <div>
          {renderSTORightHeader()}
          <DataTableToolbar table={table} />
        </div>
      </div>
    );
  };

  return tableData ? (
    <ThemeProvider theme={customTheme}>
      <div style={{ width: `calc(100% - 20px)`, margin: 10 }}>
        <UploadModal
          openModal={openStoUploadModal}
          setOpenModal={setopenStoUploadModal}
          uploadType="sto"
          isUploading={isUploading}
          setisUploading={setisUploading}
          // refetchSTO={refetchSTO}
          setLoadingData={setLoadingData}
        />
        <UploadModal
          openModal={openSendModal}
          setOpenModal={setOpenSendModal}
          modalType="send"
          // approvalStatusValues={approvalStatusValues}
          // setApprovalStatusValues={setApprovalStatusValues}
          selectedGroupPlan={selectedGroupPlan}
          approveRejectValue={approveRejectValue}
          handlePlanApproval={handlePlanApproval}
        />
        <UploadModal
          openModal={openApproveRejectModal}
          setOpenModal={setOpenApproveRejectModal}
          modalType="approve-reject"
          approveRejectValue={approveRejectValue}
          handlePlanApproval={handlePlanApproval}
          selectedGroupPlan={selectedGroupPlan}
          // approvalStatusValues={approvalStatusValues}
          // setApprovalStatusValues={setApprovalStatusValues}
          // selectedGroupPlan={selectedGroupPlan}
        />
        <UploadModal
          openModal={openMakeLiveModal}
          setOpenModal={setOpenMakeLiveModal}
          modalType="make-live"
          selectedGroupPlan={selectedGroupPlan}
          makePlanLive={makePlanLive}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            alignSelf: "center",
            marginTop: "12px",
            marginBottom: "12px",
          }}
          className="process-header"
        >
          <div
            className="process-header-1"
            onClick={() =>
              navigate("/snp/production/production-plan", {
                state: { planLevel: "group" },
              })
            }
          >
            Group Level
          </div>
          <div>
            <svg
              width="9"
              height="9"
              viewBox="0 0 9 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L4.5 4.5L1 8"
                stroke="#858585"
                strokeLinecap="round"
              />
              <path
                d="M4.5 1L8 4.5L4.5 8"
                stroke="#929292"
                strokeLinecap="round"
              />
            </svg>
          </div>
          <div className="process-header-2">{`Plan View : ${planId}`}</div>
        </div>

        {/* <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            expanded={expanded === "panel1"}
            className="box-shadow-small"
            fill="white"
            hoverColor="#e94f1c"
            backgroundColor="#494949"
            expandedBgColor="#e94f1b"
          >
            <Typography
              sx={{
                width: "33%",
                flexShrink: 0,
                fontSize: "12px",
                color: "white",
              }}
            >
              KPI&apos;s
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <PlanViewKpiCards
              kpiData={kpiData}
              //uncomment after api is ready
              // loading={isLoadingReplPlanViewData}
              loading={false}
            />
          </AccordionDetails>
        </Accordion> */}
        <div>
          <DataTable
            CustomToolbar={GroupLevelToolbar}
            data={filterTableData(tableData)}
            columns={columns}
            subRowColumns={subColumns}
            canResizeColumns={true}
            setSelectedSTO={setSelectedSTO}
            clearRowSelection={clearRowSelection}
            setClearRowSelection={setClearRowSelection}
            columnVisibility={columnVisibility}
            setColumnVisibility={setColumnVisibility}
            module="pp"
            clickedWPO={clickedWPO}
            resetExpanded={resetExpanded}
            showColorCodingLegend={true}
          />
        </div>
      </div>
    </ThemeProvider>
  ) : (
    <TableLoaderSpinner />
  );
}

const customTheme = createTheme({
  palette: {
    // primary: {
    //   main: "#f5f7fa",
    // },
  },
  typography: {
    fontFamily: "Inter, sans-serif",
    fontSize: 12,
    fontWeightBold: 700,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightLight: 300,
    customBoldFont: {
      fontWeight: 600,
      fontSize: 13,
    },
    customLightFont: {
      fontSize: 11,
      fontWeight: 400,
    },
    customHeading: {
      fontWeight: 300,
      fontSize: 15,
    },
  },
  components: {
    MuiDrawer: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});
export default PlanViewAPPGroup;
