import { useState } from "react";
import Autosuggest from "react-autosuggest";
import AutoSuggestStyles from "./InputStyles.css";
import { useEffect } from "react";

const SelectInput = ({
  label,
  placeholderValue,
  options,
  inputValue,
  handleInputValue,
  required = false,
  errorMsg = null,
  setErrors = null,
  name = null,
}) => {
  const suggestData = options;
  let listSuggestions = suggestData ? suggestData.map((item) => item) : [];
  const [value, setValue] = useState(inputValue);
  const [suggestions, setSuggestions] = useState([]);

  //If only one option is present, auto populate it by default
  useEffect(() => {
    if (required && options?.length === 1) {
      setValue(options[0]);
      handleInputValue(options[0]);
    }
  }, [required, options, handleInputValue, inputValue]);

  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return listSuggestions.filter((item) =>
      item.toLowerCase().includes(inputValue)
    );
  };

  const onChange = (event, { newValue }) => {
    setValue(newValue);
    if (required === true) {
      if (newValue === "") {
        setErrors((prev) => ({
          ...prev,
          [name]: `${label} cannot be empty`,
        }));
      } else if (options.indexOf(newValue) === -1) {
        setErrors((prev) => ({
          ...prev,
          [name]: `Select Valid ${label}`,
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          [name]: "",
        }));
      }
    } else {
      if (options.indexOf(newValue) === -1 && newValue !== "") {
        setErrors((prev) => ({
          ...prev,
          [name]: `Select Valid ${label}`,
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          [name]: ``,
        }));
      }
    }
    handleInputValue(newValue);
  };

  const onSuggestionsFetchRequested = ({ value, reason }) => {
    if (!value.trim()) {
      // If the input is empty, show all options
      setSuggestions([...listSuggestions]);
    } else {
      // Otherwise, filter suggestions based on the input value
      let suggestionsList = getSuggestions(value);
      setSuggestions([...suggestionsList]);
    }
  };

  const getSuggestionValue = (suggestion) => suggestion;

  const renderSuggestion = (suggestion) => <div>{suggestion}</div>;

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onKeyPress = (event) => {
    if (event.key === "Enter") {
      setValue("");
    }
  };

  const inputProps = {
    placeholder: placeholderValue,
    value,
    onChange,
    onKeyPress: onKeyPress,
    spellCheck: false,
    onClick: () => {
      // Trigger opening of the suggestions dropdown when input is clicked
      onSuggestionsFetchRequested({ value });
    },
  };

  const onSuggestionSelected = (e, { suggestion }) => {
    // searchToFocus(suggestion);
  };

  function shouldRenderSuggestions(value, reason) {
    return value.length >= 0;
  }

  useEffect(() => {
    setValue(inputValue);
  }, [inputValue]);

  return (
    <div style={{ width: "24%" }}>
      <label htmlFor="select-input" className="add-sto-input-label">
        {label} {required && <span className="required-asterisk">*</span>}
      </label>
      <Autosuggest
        id="select-input"
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        AutoSuggestStyles={AutoSuggestStyles}
        onSuggestionSelected={onSuggestionSelected}
        shouldRenderSuggestions={shouldRenderSuggestions}
      />
      <div className="error">{errorMsg}</div>
    </div>
  );
};

export default SelectInput;
