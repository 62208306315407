import { Breadcrumb } from "./common";
import "./styles.css";

const pbiUrl = process.env.REACT_APP_PBI_ENBED_URL;

export const InventoryProfile = () => {
  return (
    <div>
      <Breadcrumb isInvProfile={true} />
      <iframe
        className="iframe-pbi"
        title="Report Section"
        src={`${pbiUrl}/view?r=eyJrIjoiNDU2ODVkZTktYTllYS00YTVhLTk1OGEtZWZhODM2ZmVkZjI3IiwidCI6ImQxZDkzNmVjLThiZmYtNDU2MC1hOGE5LTYwMDEwZWMwMzRkZSJ9`}
        frameBorder="0"
        allowFullScreen="true"
      ></iframe>
    </div>
  );
};
