import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import InventoryStatistics from "./InventoryStatistics";
import BarAreaLineChart_poc from "./BarAreaLineChart";
import { ExpandSvg } from "../../assets/expand";
import AreaChart_poc from "./AreaChart";
import HeatMap from "./heatMap";

const StyledCard2 = styled("div")(({ theme }) => ({
  ...theme.typography.body1,
  borderRadius: "7px",
  border: "1px solid #E6E6E6",
  // padding: theme.spacing(1),
  display: "flex",
  flexDirection: "column",
  //   flexWrap: "wrap",
}));

export const Screen1 = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "grid",
        flexGrow: "1",
        gridTemplateRows: "repeat(2, 50%)",
        gridTemplateColumns: "repeat(2, 1fr)",
        gap: "10px",
        marginBottom: "10px",
      }}
    >
      <StyledCard2>
        <Box
          sx={{
            display: "grid",
            height: "90%",
            width: "100%",
          }}
        >
          <InventoryStatistics />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <button
            style={{ background: "#fff" }}
            onClick={() => navigate("/pbi-poc/screen2")}
          >
            View more data
          </button>
        </Box>
      </StyledCard2>
      <StyledCard2>2</StyledCard2>
      <StyledCard2>3</StyledCard2>
      <StyledCard2>4</StyledCard2>
    </Box>
  );
};

export const Screen2 = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "grid",
        flexGrow: "1",
        gridTemplateRows: "repeat(2, 50%)",
        gridTemplateColumns: "repeat(2, 1fr)",
        gap: "10px",
        marginBottom: "10px",
      }}
    >
      <StyledCard2>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            height: "15%",
            padding: "10px",
          }}
        >
          <div>Inventory Health & Classification</div>
          <div onClick={() => navigate("/pbi")}>
            <ExpandSvg />
          </div>
        </div>
        <Box
          sx={{
            display: "grid",
            height: "85%",
            width: "100%",
          }}
        >
          <HeatMap />
        </Box>
      </StyledCard2>
      <StyledCard2>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            height: "15%",
            padding: "10px",
          }}
        >
          <div>Inventory Profile</div>
          <div onClick={() => navigate("/pbi")}>
            <ExpandSvg />
          </div>
        </div>
        <Box
          sx={{
            display: "grid",
            height: "85%",
            width: "100%",
          }}
        >
          <AreaChart_poc />
        </Box>
      </StyledCard2>
      <StyledCard2>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px",
          }}
        >
          <div>Inventory Level Trend</div>
          <div onClick={() => navigate("/pbi")}>
            <ExpandSvg />
          </div>
        </div>
        <Box
          sx={{
            display: "grid",
            height: "90%",
            width: "100%",
          }}
        >
          <BarAreaLineChart_poc />
        </Box>
      </StyledCard2>
      <StyledCard2>4</StyledCard2>
    </Box>
  );
};
const pbiUrl = process.env.REACT_APP_PBI_ENBED_URL;
export const PowerBi = () => {
  return (
    <div style={{ height: "100vh" }}>
      <iframe
        className="iframe-pbi"
        title="Report Section"
        // width="1024"
        // height="804"
        src={`${pbiUrl}/view?r=eyJrIjoiYjE3OGM1NzctOWRhYi00MDVhLTg5ODAtMzk2ZWMwOGMwOGI4IiwidCI6ImQxZDkzNmVjLThiZmYtNDU2MC1hOGE5LTYwMDEwZWMwMzRkZSJ9`}
        frameBorder="0"
        allowFullScreen="true"
      ></iframe>
    </div>
  );
};
