import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query";
import { toast } from "react-toastify";

export const handleApiResponse = async (response) => {
  if (response.ok) {
    const result = await response.json();
    return result;
  } else if (response.status === 403) {
    const errorData = await response.json();
    throw new Error(errorData.detail);
  } else if (response.status >= 400 && response.status < 500) {
    const errorData = await response.json();
    if (errorData && errorData.error) {
      throw new Error(errorData.error);
    }
  } else if (response.status >= 500) {
    throw new Error("Something went wrong.!");
  }
};

export const customBaseQuery =
  (baseQueryOptions) => async (args, api, extraOptions) => {
    const result = await fetchBaseQuery(baseQueryOptions)(
      args,
      api,
      extraOptions
    );

    if (result.error) {
      if (result.error.status === 403) {
        toast.error(result.error.data.detail, { autoClose: 4000 });
      }
    }
    return result;
  };

export const handleApiArrayResponse = async (response) => {
  if (response.ok) {
    const result = await response.json();
    return result;
  } else if (response.status === 403) {
    const errorData = await response.json();
    throw new Error(errorData.detail);
  } else if (response.status >= 400 && response.status < 500) {
    const errorData = await response.json();
    if (errorData.length !== 0) {
      throw new Error(errorData[0]);
    }
  } else if (response.status >= 500) {
    throw new Error("Something went wrong.!");
  }
};

export const handleDownloadApiResponse = async (response) => {
  if (response.ok) {
    const result = await response.blob();
    return result;
  } else if (response.status === 403) {
    const errorData = await response.json();
    throw new Error(errorData.detail);
  } else if (response.status >= 400 && response.status < 500) {
    const errorData = await response.json();
    throw new Error("Error in downloading");
  } else if (response.status >= 500) {
    throw new Error("Something went wrong.!");
  }
};
