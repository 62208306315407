import { useCallback, useEffect, useState } from "react";
import "./styles.css";
import { TableLoaderSpinner } from "../../../components/tableUtils/tableUtils";
import { toast } from "react-toastify";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentEditSto,
  setEditedSTOList,
} from "@/features/rawMaterialPlanSlice";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { useRmPlanBreachesMutation } from "@/features/api/rawMaterialSlice";

const AllocationQuantityItem = ({
  stoValues,
  getEditSTOApiBody,
  planId,
  editableField,
}) => {
  const dispatch = useDispatch();
  const currentEditSto = useSelector(
    (state) => state.rawMaterialPlan.currentEditSto
  );
  const isGroupLevelEditModeOn = useSelector(
    (state) => state.rawMaterialPlan.isGroupLevelEditModeOn
  );
  const editedSTOList = useSelector(
    (state) => state.rawMaterialPlan.editedSTOList
  );

  const [
    rmPlanBreaches,
    { isLoading: isLoadingRmPlanBreaches, isErrorRmPlanBreaches },
  ] = useRmPlanBreachesMutation();

  const checkEditableField = () => {
    if (editableField === "user_pr_quantity") {
      return stoValues.user_pr_quantity ?? 0;
    }
  };

  const [userPRQty, setUserPRQty] = useState(checkEditableField());
  const [openEditReasonModal, setOpenEditReasonModal] = useState(false);
  const [fieldsRequired, setFieldsRequired] = useState({
    reasonRequired: false,
    commentsRequired: false,
    percentageBreached: null,
    breachLevel: null,
  });

  const handleReasonBreaches = async (requiredFields) => {
    setFieldsRequired((prev) => ({
      ...prev,
      reasonRequired: requiredFields.reason_required,
      commentsRequired: requiredFields.comments_required,
      breachLevel: requiredFields.breach_level,
      percentageBreached: requiredFields.percentage_breached,
      reasonOptions: requiredFields.reason_options,
    }));
  };

  useEffect(() => {
    if (fieldsRequired.breachLevel != null) {
      setOpenEditReasonModal(true);
    }
  }, [fieldsRequired]);

  const debounce = (callback, debouncedTime = 2000) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        callback.apply(this, args);
      }, debouncedTime);
    };
  };

  const debouncedWarningonDecimal = useCallback(
    debounce((inputNumber) => showWarningOnDecimal(inputNumber), 1000),
    []
  );

  function showWarningOnDecimal(inputNumber) {
    if (inputNumber % 1 != 0) {
      toast.warning("Input value should not be in decimal", { autoClose: 999 });
    }
  }

  const handleEditInput = (e) => {
    const inputNumber = e.target.value;
    setUserPRQty(inputNumber);
    debouncedWarningonDecimal(inputNumber);
  };

  const handleEditSave = async () => {
    const userPRQtyInt = Math.round(userPRQty);
    try {
      let requestBody = {
        user_pr_quantity: userPRQtyInt,
        system_pr_quantity: stoValues.system_pr_quantity,
      };

      const response = await rmPlanBreaches({ requestBody });
      console.log({ response });
      if (response?.data?.validation_status === true) {
        toast.success("Breach validation successful", { autoClose: 5000 });
        getEditSTOApiBody(stoValues, userPRQtyInt);
      } else {
        console.log("breached");
        handleReasonBreaches(response?.data?.response);
      }
    } catch (error) {
      toast.error("Something went wrong!", { autoClose: 5000 });
    }
    dispatch(setCurrentEditSto(null));
  };

  function resetInput() {
    dispatch(setCurrentEditSto(null));
    setUserPRQty(checkEditableField());
    dispatch(
      setEditedSTOList({
        type: "FILTER_OUT/STO_ID",
        payload: { pro_id: stoValues.pro_id },
      })
    );
  }

  function isSTOEdited() {
    const isSTOPresentInEditedList = editedSTOList.find(
      (item) => item.pro_id === stoValues.pro_id
    );
    if (isSTOPresentInEditedList) {
      return true;
    }
    return false;
  }

  function getValueFromEditedList() {
    const stoFromEditedList = editedSTOList.find(
      (item) => item.pro_id === stoValues.pro_id
    );
    if (editableField === "user_pr_quantity") {
      return stoFromEditedList.user_pr_quantity;
    }
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <BreachesReasonModal
        openModal={openEditReasonModal}
        setOpenModal={setOpenEditReasonModal}
        activeSTO={stoValues}
        resetInput={resetInput}
        userPRQty={userPRQty}
        getEditSTOApiBody={getEditSTOApiBody}
        fieldsRequired={fieldsRequired}
        editableField={editableField}
      />
      {!isGroupLevelEditModeOn && (
        <span className=".allocation-quantity">{checkEditableField()}</span>
      )}
      {isGroupLevelEditModeOn && (
        <>
          <input
            disabled={
              currentEditSto &&
              currentEditSto !== stoValues.pro_id &&
              editableField
            }
            type="number"
            className="editable-input p-1 rounded-md mr-2 lg:rounded-lg lg:mr-4"
            onChange={handleEditInput}
            value={isSTOEdited() ? getValueFromEditedList() : userPRQty}
            style={isSTOEdited() ? { border: "2px solid #48506d" } : {}}
            onFocus={() => {
              dispatch(setCurrentEditSto(stoValues.pro_id));
            }}
            onBlur={(e) => {
              if (
                e.relatedTarget?.name !== "reset-input-btn" &&
                userPRQty !== checkEditableField()
              ) {
                handleEditSave();
              }
            }}
          />
          <div className="edit-mode-buttons">
            {isLoadingRmPlanBreaches ? (
              <button>
                <TableLoaderSpinner size={10} height="1vh" />
              </button>
            ) : (
              <button
                onClick={() => {
                  resetInput();
                }}
                name="reset-input-btn"
                className="reset-input-btn !rounded-sm lg:!rounded-md"
              >
                <svg
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.85236 9.85236C9.65699 10.0477 9.34087 10.0477 9.14528 9.85236L4.99992 5.70607L0.853623 9.85236C0.658244 10.0477 0.342132 10.0477 0.146534 9.85236C-0.0488447 9.65698 -0.0488447 9.34087 0.146534 9.14528L4.29377 4.99992L0.147159 0.853623C-0.0482198 0.658244 -0.0482198 0.342132 0.147159 0.146534C0.342538 -0.0488447 0.65865 -0.0488447 0.854248 0.146534L4.99992 4.29377L9.14621 0.147472C9.34159 -0.0479074 9.6577 -0.0479074 9.8533 0.147472C10.0487 0.34285 10.0487 0.658963 9.8533 0.85456L5.70607 4.99992L9.85236 9.14621C10.0492 9.33994 10.0492 9.65864 9.85236 9.85236Z"
                    fill="#3D445C"
                  />
                </svg>
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );
};

function BreachesReasonModal({
  openModal,
  setOpenModal,
  activeSTO,
  resetInput,
  userPRQty,
  getEditSTOApiBody,
  fieldsRequired,
  editableField,
}) {
  const [reason, setReason] = useState("");
  const [comments, setComments] = useState("");
  const [reasonError, setReasonError] = useState(false);
  const [commentsError, setCommentsError] = useState(false);

  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };

  const handleEditSTOSubmit = () => {
    setReasonError(false);
    setCommentsError(false);
    if (
      (reason === "" || reason === "Select Reason Code") &&
      fieldsRequired.reasonRequired
    ) {
      setReasonError(true);
    }
    if (comments === "" && fieldsRequired.commentsRequired) {
      setCommentsError(true);
    }
    if (
      !(
        (reason === "" || reason === "Select Reason Code") &&
        fieldsRequired.reasonRequired
      ) &&
      !(comments === "" && fieldsRequired.commentsRequired)
    ) {
      getEditSTOApiBody(activeSTO, userPRQty, reason, comments);
      setReason("");
      setComments("");
      setOpenModal(false);
    }
  };

  const getOrderQty = () => {
    if (editableField === "user_pr_quantity") {
      return activeSTO.system_pr_quantity;
    }
  };

  const breachLevelCalculated =
    fieldsRequired && fieldsRequired.breachLevel
      ? fieldsRequired.breachLevel.split("")
      : null;

  return (
    <Dialog open={openModal} onOpenChange={setOpenModal}>
      <DialogContent className="rounded-md w-[500px] lg:w-[500px] lg:max-w-[500px] lg:rounded-lg">
        <DialogHeader className="flex-row space-y-0 items-center">
          <span className="mr-2">
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.0007 15.4689C10.4314 15.4689 10.0082 15.9304 10.0082 16.5001C10.0082 17.0693 10.4701 17.5312 11.0007 17.5312C11.57 17.5312 11.9931 17.0693 11.9931 16.5001C12.0318 15.9286 11.5721 15.4689 11.0007 15.4689ZM11.0007 13.7504C11.3806 13.7504 11.6494 13.443 11.6494 13.063V6.87627C11.6494 6.4963 11.342 6.18885 11.0007 6.18885C10.6593 6.18885 10.3133 6.49819 10.3133 6.87627V13.063C10.3133 13.4411 10.6226 13.7504 11.0007 13.7504ZM21.6685 17.0715L13.0844 2.55329C12.6505 1.81819 11.8685 1.37824 11.0007 1.37695C10.1328 1.37695 9.35519 1.81604 8.91696 2.55243L0.328585 17.0741C-0.103197 17.8031 -0.109642 18.6783 0.310463 19.4156C0.7449 20.1734 1.52941 20.6245 2.41231 20.6245H19.5934C20.4745 20.6245 21.2582 20.1721 21.6904 19.4143C22.111 18.6783 22.1024 17.8019 21.6685 17.0715ZM20.4569 18.6955C20.3108 19.0564 19.9714 19.2497 19.5547 19.2497H2.41231C2.02968 19.2497 1.69138 19.0571 1.50664 18.7342C1.33144 18.4267 1.33414 18.0768 1.51335 17.7735L10.1028 3.25273C10.2875 2.9391 10.6226 2.75178 11.0007 2.75178C11 2.75178 11.0007 2.75178 11.0007 2.75178C11.3773 2.75245 11.7122 2.93906 11.8969 3.25187L20.4853 17.7735C20.6288 18.0768 20.6717 18.4248 20.4569 18.6955Z"
                fill="#B45309"
              />
            </svg>
          </span>
          <DialogTitle>Alert</DialogTitle>
        </DialogHeader>
        <div>
          <p>
            This Edited Quantity is Breaching the tolerance level: “Level{" "}
            {breachLevelCalculated != null &&
              `${
                breachLevelCalculated[1]
              } - ${fieldsRequired.percentageBreached.toFixed(2)}%"`}
            <br /> Please add a Reason Code/ Comment to go ahead with this
            change.
          </p>
          <p className="edit-reason-order-quantity">
            {`Order Quantity: ${getOrderQty()}`}
          </p>
          <p className="edit-reason-edited-quantity">
            {`Edited Quantity: ${userPRQty}`}
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "10px",
              width: "100%",
            }}
          >
            <label htmlFor="edit-reason" className="edit-reason-label">
              Reason code
              {fieldsRequired.reasonRequired && (
                <span className="required-asterisk">*</span>
              )}
            </label>
            <select
              id="edit-reason"
              name="edit-reason"
              className="edit-select-container"
              onChange={handleReasonChange}
              value={reason}
            >
              <option value="" selected>
                Select Reason Code
              </option>
              {fieldsRequired?.reasonOptions?.map((reason, index) => (
                <option key={index} value={reason}>
                  {reason}
                </option>
              ))}
            </select>
            {reasonError && (
              <span className="required-error-msg">Reason required</span>
            )}
          </div>

          <div
            style={{
              marginTop: "8px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {" "}
            <label htmlFor="comments-textarea" className="edit-reason-label">
              Comments{" "}
              {fieldsRequired.commentsRequired && (
                <span className="required-asterisk">*</span>
              )}
            </label>
            <textarea
              id="comments-textarea"
              style={{ width: "100%", marginTop: "10px" }}
              className="edit-reason-textarea"
              placeholder="Add comment"
              rows={3}
              onChange={(e) => setComments(e.target.value)}
            ></textarea>
            {commentsError && (
              <span className="required-error-msg">Comments required</span>
            )}
          </div>
        </div>
        <DialogFooter className="flex justify-end flex-row">
          <button
            className="send-cancel-button px-4 py-1 lg:!px-7 lg:!py-3 lg:text-xl"
            onClick={() => {
              resetInput();
              setReason("");
              setComments("");
              setOpenModal(false);
            }}
          >
            Cancel
          </button>
          <button
            className="send-yes-button px-4 py-1 lg:!px-7 lg:!py-3 lg:text-xl"
            onClick={handleEditSTOSubmit}
          >
            Yes
          </button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export default AllocationQuantityItem;
