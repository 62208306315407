import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Cron } from "react-js-cron";
import Select from "react-select";
import {
  useCreateJobScheduleMutation,
  useJobCategoryQuery,
  useJobTypeQuery,
  useEditJobScheduleMutation,
  useAllGroupSchedulesQuery,
  useRunAdhocJobMutation,
} from "../../features/api/schedularSlice";
import "./jobSchedular.scss";
import { useIsMount } from "./utils";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  setJobSchedulerMode,
  setJobSchedulerProps,
  // setJobSchedulerEditId,
} from "../../features/JobSchedulerSlice";
import cron from "cron-validate";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../modal/UploadModal.scss";
import { BreadcrumbMaker } from "../tableUtils/tableUtils";
import { LoaderSpinner } from "../tableUtils/tableUtils";
import {
  Box,
  Modal,
  Tooltip,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import DateInput from "../add-sto/DateInput";
import { handleApiArrayResponse } from "../../utils/apiUtils";

const UploadModal = ({
  openUploadModal,
  setOpenUploadModal,
  setCheckboxValue,
}) => {
  return (
    openUploadModal && (
      <div className="upload-modal-container">
        <div
          className="prompt-modal"
          style={{ display: "flex", flexDirection: "column", padding: "0px" }}
        >
          <h1
            style={{
              backgroundColor: "#f9f9fb",
              fontSize: "18px",
              padding: "16px 26px",
              borderTopLeftRadius: "6px",
              borderTopRightRadius: "6px",
              color: "#51545e",
            }}
          >
            Confirmation
          </h1>
          <div style={{ padding: "16px 26px", height: "100%" }}>
            <p
              style={{
                color: "#69788e",
                fontSize: "12px",
                marginBottom: "10px",
              }}
            >
              Are you sure you want to disable the Scheduler?
            </p>
            <p
              style={{
                color: "#69788e",
                fontSize: "12px",
                marginBottom: "10px",
              }}
            >
              Please note: If you go ahead, the Job will not run until it is
              Enabled again
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              gap: "6px",
              marginTop: "18px",
              padding: " 16px 26px",
            }}
          >
            <button
              className="scheduler-cancel-button"
              onClick={() => setOpenUploadModal(false)}
            >
              Cancel
            </button>
            <button
              className="scheduler-yes-button"
              onClick={() => {
                setOpenUploadModal(false);
                setCheckboxValue(false);
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    )
  );
};
const JobSchedular = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const jsEditProps = useSelector(
    (state) => state.jobScheduler.jobSchedulerProps
  );
  const {
    data: allGroupSchedules,
    isFetching: isLoadingAllGroupSchedules,
    isSuccess: isSuccessAllGroupSchedules,
  } = useAllGroupSchedulesQuery(undefined, {
    refetchOnMountOrArgChange: true,
    force: true, // This will bypass the cache
  });
  const path = location.pathname;
  const baseURL = process.env.REACT_APP_SCAI_API_ENDPOINT;
  const [showLoader, setShowLoader] = useState(false);
  const [jobTypeOptions, setJobTypeOptions] = useState([]);
  const [jobCategoryOptions, setJobCategoryOptions] = useState([]);
  const [jobConfigOptions, setJobConfigOptions] = useState([]);
  const [isJobConfigLoading, setIsJobConfigLoading] = useState(false);
  // const mode = path === "/job-scheduler-create" ? "create" : "edit";
  const mode = useSelector((state) => state.jobScheduler.jobSchedulerMode);
  const [openModal, setOpenModal] = useState(false);
  const [openErrModal, setOpenErrModal] = useState(false);
  const [errModalMsg, setErrModalMsg] = useState("");
  const [isAdhoc, setIsAdhoc] = useState(false);
  let idArr = path.split("/");
  let editId = Number(idArr[idArr.length - 1]);
  const [jobName, setJobName] = useState(
    mode === "create" ? "" : jsEditProps?.name
  );
  const [jobType, setJobType] = useState(
    mode === "create" ? null : jsEditProps?.jobTypeSelected
  );
  const [jobCategory, setJobCategory] = useState(
    mode === "create" ? null : jsEditProps?.jobCategorySelected
  );
  const [jobConfig, setJobConfig] = useState(
    mode === "create" ? null : jsEditProps?.jobConfigurationSelected
  );
  const [startDate, setStartdate] = useState(
    mode === "create" ? new Date() : jsEditProps?.startDate
  );
  const [endDate, setEndDate] = useState(
    mode === "create" ? null : jsEditProps?.endDate
  );
  const [showError, setShowError] = useState(false);
  const [value, setValue] = useState(
    mode === "create" ? "30 5 * * 1,6" : jsEditProps?.cron
  );
  const [checkboxValue, setCheckboxValue] = useState(
    mode === "create" ? true : jsEditProps?.active
  );
  const [savedName, setSavedName] = useState(
    mode === "create" ? "" : jsEditProps?.name
  );
  const handleErrModalOpen = (errMsg) => {
    setErrModalMsg(errMsg);
    setOpenErrModal(true);
  };
  const handleErrModalClose = () => {
    setOpenErrModal(false);
    setErrModalMsg("");
  };

  const isFirstMount = useIsMount();
  function isFutureDate(date) {
    if (mode === "edit") {
      return true;
    }
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const inputDate = new Date(date);
    inputDate.setHours(0, 0, 0, 0);
    return +inputDate >= +currentDate;
  }

  useEffect(() => {
    if (editId) {
      setJobName(jsEditProps.name);
      setCheckboxValue(jsEditProps.active);
      setEndDate(jsEditProps?.endDate ? new Date(jsEditProps.endDate) : null);
      setStartdate(
        jsEditProps?.startDate ? new Date(jsEditProps.startDate) : null
      );
      setJobCategory(jsEditProps.jobCategorySelected);
      setJobType(jsEditProps.jobTypeSelected);
      setJobConfig(jsEditProps.jobConfigurationSelected);
      setValue(jsEditProps.cron);
    }
  }, [jsEditProps]);

  useEffect(() => {
    console.log({ mode, editId });
    const fetchRules = async () => {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${JSON.parse(localStorage.getItem("token"))}`
      );

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      try {
        const response = await fetch(
          `${baseURL}/rnc/group_schedules/${editId}/`,
          requestOptions
        );

        const rules = await handleApiArrayResponse(response);
        return rules;
        // if (response.ok) {
        //   const rules = await response.json();
        //   console.log({ rules });
        //   return rules;
        // } else {
        //   // toast.error("Error fetching rules");
        //   console.log("Error fetching rules");
        //   return [];
        // }
      } catch (error) {
        if (error.message) {
          toast.error(error.message, { autoClose: 3000 });
        } else {
          toast.error("Error fetching rules");
        }
        return [];
      }
    };

    if (editId) {
      fetchRules().then((rulesData) => {
        dispatchHandler(path, rulesData);
      });
    }
  }, [mode, editId]);
  function compareDates(dateStr1, dateStr2) {
    // Parse the date strings as yyyy-mm-dd
    if (dateStr1 !== null && dateStr2 !== null) {
      const dateParts1 = dateStr1.split("-").map(Number);
      const dateParts2 = dateStr2.split("-").map(Number);

      // Create Date objects from the parsed date strings
      const date1 = new Date(dateParts1[0], dateParts1[1] - 1, dateParts1[2]);
      const date2 = new Date(dateParts2[0], dateParts2[1] - 1, dateParts2[2]);
      console.log({ date1, date2 });
      // Compare the dates
      if (date1 < date2) {
        return true; // date1 is less than date2
      } else if (date1 > date2) {
        return false; // date1 is greater than date2
      } else {
        return true; // dates are equal
      }
    }
  }

  const dispatchHandler = (path, groupSchedule) => {
    if (mode !== "create") {
      const idArr = path.split("/");
      const editId = idArr[idArr.length - 1];

      const { name, cron, start_date, end_date, active } = groupSchedule;
      console.log({ groupSchedule });
      dispatch(
        setJobSchedulerProps({
          name,
          jobTypeSelected: {
            value: groupSchedule.group_rule.group.module.id,
            label: groupSchedule.group_rule.group.module.name,
          },
          jobCategorySelected: {
            value: groupSchedule.group_rule.group.id,
            label: groupSchedule.group_rule.group.name,
          },
          jobConfigurationSelected: {
            value: groupSchedule.group_rule.id,
            label: groupSchedule.group_rule.rule_set_name,
          },
          startDate: start_date,
          endDate: end_date,
          cron,
          active,
          id: editId,
        })
      );
    }
  };
  console.log("cron", cron(value).isValid());
  console.log("cron", value);
  const {
    data: schedularJobDetails,
    isFetching: isLoadingSchedularJobDetails,
    isSuccess: isSuccessSchedularJobDetails,
  } = useJobTypeQuery(undefined, {
    refetchOnMountOrArgChange: true,
    force: true, // This will bypass the cache
  });

  const [skip, setSkip] = useState(true);

  const {
    data: jobCategoryData,
    error: jobCategoryError,
    isFetching: jobCategoryIsLoading,
  } = useJobCategoryQuery(jobType?.value, {
    skip: skip,
    refetchOnMountOrArgChange: true,
    force: true,
  });

  useEffect(() => {
    if (isSuccessSchedularJobDetails) {
      const { results } = schedularJobDetails;
      const tr = results.map((item) => ({
        ...item,
        label: item.name,
        value: item.id,
      }));
      console.log({ tr });

      setJobTypeOptions(tr);
    }
  }, [isLoadingSchedularJobDetails]);

  useEffect(() => {
    if ((mode === "create" && jobType !== null) || mode === "edit") {
      setSkip(false);
    }

    if (!isFirstMount && jobCategoryData) {
      console.log({ jobCategoryData });
      const jobCategoryDataTemp = jobCategoryData.results.map((item) => ({
        ...item,
        label: item.name,
        value: item.id,
      }));
      setJobCategoryOptions(jobCategoryDataTemp);
    }
  }, [jobType, jobCategoryData]);

  const [
    editJobSchedule,
    // {
    //   isSuccess: editJobScheduleIsSuccess,
    //   isLoading: editJobScheduleIsLoading,
    //   isError: editJobScheduleIsError,
    //   data: editJobScheduleData,
    //   error: editJobScheduleError,
    // },
  ] = useEditJobScheduleMutation();

  useEffect(() => {
    if (
      (mode === "create" && jobCategory !== null) ||
      (mode === "edit" && jobCategory !== jsEditProps?.jobCategorySelected)
    ) {
      setIsJobConfigLoading(true);
    }
    if (!isFirstMount) {
      const fetchRules = async () => {
        const myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          `Bearer ${JSON.parse(localStorage.getItem("token"))}`
        );

        const requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        try {
          const response = await fetch(
            `${baseURL}/rnc/group_rules/?group__id=${jobCategory.value}`,
            requestOptions
          );
          const rules = await handleApiArrayResponse(response);
          return rules.data;
          // if (response.ok) {
          //   const rules = await response.json();
          //   console.log({ rules });
          //   return rules.data;
          // } else {
          //   // toast.error("Error fetching rules");
          //   console.log("Error fetching rules");
          //   return [];
          // }
        } catch (error) {
          if (error.message) {
            toast.error(error.message, { autoClose: 3000 });
          } else {
            toast.error("Error fetching rules");
          }
          return [];
        }
      };

      fetchRules().then((rulesData) => {
        const selectOptions = rulesData.map((item) => ({
          value: item.id,
          label: item.rule_set_name,
        }));
        console.log({ selectOptions });
        setIsJobConfigLoading(false);
        setJobConfigOptions(selectOptions);
        if (mode === "create")
          setJobConfig(selectOptions.length > 0 && selectOptions[0]);
      });
    }
  }, [jobCategory]);

  const [createJobSchedule, { isSuccess, isLoading, isError, data, error }] =
    useCreateJobScheduleMutation();

  const [runAdhocJob, { isError: isRunAdhocError }] = useRunAdhocJobMutation();

  const handleSelect2Change = (selectedOption) => {
    setJobCategoryOptions([]);
    setJobCategory(null);
    setJobConfigOptions([]);
    setJobConfig(null);
    setJobType(selectedOption);
  };

  const handleSelect3Change = (selectedOption) => {
    setJobConfigOptions([]);
    setJobConfig(null);
    setIsJobConfigLoading(false);
    setJobCategory(selectedOption);
  };

  const handleSelect4Change = (selectedOption) => {
    setJobConfig(selectedOption);
  };

  const cancelAction = () => {
    dispatch(setJobSchedulerMode("create"));
    navigate(`/${idArr[1]}/${idArr[2]}/${idArr[3]}/job-scheduler`);
  };

  const handleDuplicateJobName = (name) => {
    console.log({ allGroupSchedules });
    return (
      allGroupSchedules &&
      allGroupSchedules.data.some((item) => item.name === name)
    );
  };

  const handleRunAdhocJob = async () => {
    setIsAdhoc(true);
    requestBody.is_adhoc = true;
    requestBody.active = true;
    delete requestBody.start_date;
    delete requestBody.end_date;
    delete requestBody.cron;
    if (mode !== "create") {
      requestBody.group_schedule = editId;
      requestBody.group_config = jobCategory.value;
      delete requestBody.active;
      delete requestBody.id;
    }
    try {
      if (
        requestBody.name.length > 0 &&
        requestBody.name.length < 20 &&
        requestBody.group_rule &&
        (mode === "create"
          ? !handleDuplicateJobName(jobName) && jobName !== savedName
          : true)
      ) {
        setShowLoader(true);
        if (mode != "create") {
          delete requestBody.name;
        }
        const response =
          mode === "create"
            ? await createJobSchedule(requestBody).unwrap()
            : await runAdhocJob(requestBody).unwrap();
        if (response.err) {
          toast.error(`${response?.err?.data}`);
        } else {
          mode === "create"
            ? toast.success("Adhoc job created successfully")
            : toast.success("Adhoc job triggered successfully");
          setTimeout(() =>
            navigate(`/${idArr[1]}/${idArr[2]}/${idArr[3]}/job-scheduler`)
          );
        }
        setShowLoader(false);
      } else setShowError(true);
    } catch (err) {
      console.log({ err });
      handleErrModalOpen(err.data.detail);
      setShowLoader(false);
      setShowError(true);
    }
  };

  const createScheduleHandler = async () => {
    setIsAdhoc(false);
    requestBody.is_adhoc = false;
    try {
      if (
        requestBody.name.length > 0 &&
        requestBody.name.length < 20 &&
        requestBody.cron &&
        requestBody.group_rule &&
        requestBody.end_date &&
        requestBody.start_date &&
        requestBody.cron !== "* * * * *" &&
        requestBody.start_date !== requestBody.end_date &&
        isFutureDate(requestBody.start_date) &&
        isFutureDate(requestBody.end_date) &&
        compareDates(requestBody.start_date, requestBody.end_date) &&
        (mode === "create"
          ? !handleDuplicateJobName(jobName) && jobName !== savedName
          : true)
      ) {
        setShowLoader(true);
        const response =
          mode === "create"
            ? await createJobSchedule(requestBody).unwrap()
            : await editJobSchedule(requestBody).unwrap();
        if (response.err) {
          toast.error(`${response?.err?.data}`);
        } else {
          // toast.success("Job Schedule operation was successful");
          mode === "create"
            ? toast.success("Job Schedule creation was successful")
            : toast.success("Job Schedule edit operation was successful");
          setTimeout(() =>
            navigate(`/${idArr[1]}/${idArr[2]}/${idArr[3]}/job-scheduler`)
          );
        }
        setShowLoader(false);
      } else setShowError(true);
    } catch (err) {
      console.log({ err });
      handleErrModalOpen(err.data.detail);
      setShowLoader(false);
      setShowError(true);
    }
  };
  const getFormattedDate = (currDate) => {
    if (currDate == "null") {
      return null;
    }
    if (!currDate) {
      return null;
    }
    if (typeof currDate === "object") {
      return currDate.toISOString().split("T")[0];
    }
    return currDate.split("T")[0];
  };
  const requestBody = {
    group_rule: jobConfig?.value,
    name: jobName,
    cron: value,
    start_date: startDate ? getFormattedDate(startDate) : null,
    end_date: endDate ? getFormattedDate(endDate) : null,
    id: mode === "create" ? undefined : editId,
    active: checkboxValue,
    is_adhoc: false,
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#3d445c",
      fontSize: "12px",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#3d445c",
      fontSize: "12px",
    },
  }));

  return (
    <div className="common-props">
      <Modal
        open={openErrModal}
        onClose={handleErrModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            display="flex"
            sx={{ alignItems: "center", justifyContent: "space-between" }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <ErrorIcon
                sx={{ color: "red", marginRight: "10px", fontSize: "25px" }}
              />
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                fontSize={"18px"}
              >
                Error
              </Typography>
            </div>
            <div onClick={handleErrModalClose}>
              <CloseIcon sx={{ fontSize: "20px" }} />
            </div>
          </Box>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, fontSize: "15px" }}
          >
            {errModalMsg}
          </Typography>
        </Box>
      </Modal>
      <UploadModal
        openUploadModal={openModal}
        setOpenUploadModal={setOpenModal}
        setCheckboxValue={setCheckboxValue}
        checkboxValue={checkboxValue}
      />
      <LoaderSpinner showLoader={showLoader} />
      <div>
        <div
          style={{
            display: "flex",
            marginTop: "6px",
            justifyContent: "space-between",
            marginBottom: "5px",
          }}
        >
          <div>
            {BreadcrumbMaker(
              `/${idArr[1]}/${idArr[2]}/${idArr[3]}/job-scheduler`,
              "Job Scheduler",
              mode === "create" ? "Create New Job" : " Edit Job"
            )}
          </div>
          <div
            style={{
              display: "flex",
              border: "1px solid grey",
              width: 120,
              padding: "5px 12px",
              borderColor: "#E4E4E4",
              boxSizing: "unset",
            }}
          >
            <div className="schedular-subheader">Scheduler</div>
            <label className="switch">
              <input
                type="checkbox"
                checked={checkboxValue}
                value={checkboxValue}
                onClick={() => {
                  if (checkboxValue === true) {
                    setOpenModal(true);
                  } else {
                    setCheckboxValue(true);
                  }
                  // e.stopPropagation();
                }}
              />
              <span className="slider round" />
            </label>
          </div>
        </div>
        <div>
          <div className="single-select-container">
            <div className="single-select-dd">
              <label className="dd-lable">Job Name</label>
              <input
                placeholder="Enter Job name"
                className="job-name-input"
                value={jobName}
                onChange={(e) => setJobName(e.target.value)}
              />
              {(showError && !jobName && (
                <div style={{ fontSize: 10, color: "red" }}>
                  *Name is required
                </div>
              )) ||
                (showError && jobName.length >= 20 && (
                  <div style={{ fontSize: 10, color: "red" }}>
                    *Name must be less than 20 characters
                  </div>
                )) ||
                (showError && /^\s*$/.test(jobName) && (
                  <div style={{ fontSize: 10, color: "red" }}>
                    *Name must not have only empty spaces
                  </div>
                )) ||
                (handleDuplicateJobName(jobName) && jobName !== savedName && (
                  <div style={{ fontSize: 10, color: "red" }}>
                    *Job Name already exists
                  </div>
                ))}
            </div>

            <div className="single-select-dd">
              <label className="dd-lable">Job Type</label>
              <Select
                placeholder="Select Job Type"
                value={jobType}
                onChange={handleSelect2Change}
                options={jobTypeOptions}
                className="custom-select"
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 2,
                  colors: {
                    ...theme.colors,
                    primary: "#5b80f7",
                  },
                })}
              />
              {showError &&
                !Object.keys(jobType === null ? {} : jobType).length && (
                  <div style={{ fontSize: 10, color: "red" }}>
                    *Job type is required
                  </div>
                )}
            </div>
          </div>
        </div>
        <div className="single-select-container">
          <div className="single-select-dd">
            <label className="dd-lable">Group</label>
            <Select
              placeholder="Select Group"
              value={jobCategory}
              onChange={handleSelect3Change}
              options={jobCategoryOptions}
              className="custom-select"
              isLoading={jobCategoryIsLoading}
              theme={(theme) => ({
                ...theme,
                borderRadius: 2,
                colors: {
                  ...theme.colors,
                  primary: "#5b80f7",
                },
              })}
            />
            {showError &&
              !Object.keys(jobCategory === null ? {} : jobCategory).length && (
                <div style={{ fontSize: 10, color: "red" }}>
                  *Job category is required
                </div>
              )}
          </div>
          <div className="single-select-dd">
            <label className="dd-lable">Configuration</label>
            <Select
              placeholder="Select Configuration"
              value={jobConfig}
              onChange={handleSelect4Change}
              options={jobConfigOptions}
              className="custom-select"
              isLoading={isJobConfigLoading}
              theme={(theme) => ({
                ...theme,
                borderRadius: 2,
                colors: {
                  ...theme.colors,
                  primary: "#5b80f7",
                },
              })}
            />
            {showError &&
              !Object.keys(jobConfig === null ? {} : jobConfig).length && (
                <div style={{ fontSize: 10, color: "red" }}>
                  *Job configuration is required
                </div>
              )}
          </div>
        </div>
        <>
          <div
            style={{ display: "flex", alignItems: "center", columnGap: "5px" }}
          >
            <div className="schedular-header">Scheduler</div>
            <StyledTooltip
              title="Scheduler input is not required for triggering adhoc jobs"
              placement="right-end"
            >
              <InfoIcon sx={{ color: "#3d445c", fontSize: "20px" }} />
            </StyledTooltip>
          </div>

          <div
            style={{
              margin: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="cron-container">
              <div
                style={{
                  borderRadius: 5,
                  border: "0.50px  #E0E3EB solid",
                  padding: "5px 20px 10px 20px",
                }}
              >
                <div
                  style={{
                    width: "70%",
                    display: "flex",
                    gap: "15px",
                    marginTop: "12px",
                  }}
                >
                  <DateInput
                    label="Start Date"
                    inputValue={startDate}
                    handleInputValue={setStartdate}
                    name="scheduler-start-date"
                  />
                  <span
                    style={{
                      alignSelf: "center",
                      color: "rgba(0, 0, 0, 0.87)",
                      fontSize: "13px",
                      fontFamily: "Inter",
                      position: "relative",
                      top: "10px",
                    }}
                  >
                    to
                  </span>
                  <DateInput
                    label="End Date"
                    inputValue={endDate}
                    handleInputValue={setEndDate}
                    name="scheduler-end-date"
                  />
                </div>
                <div className="js-error">
                  {" "}
                  {(mode === "create" &&
                    startDate &&
                    endDate &&
                    (!isFutureDate(startDate) || !isFutureDate(endDate)) &&
                    isAdhoc !== true &&
                    "*Start and end date must be at least current date") ||
                    (showError &&
                      requestBody.end_date === null &&
                      isAdhoc !== true &&
                      "*End Date cannot be empty") ||
                    (showError &&
                      requestBody.start_date === requestBody.end_date &&
                      isAdhoc !== true &&
                      "*Start Date and End Date cannot be same.") ||
                    (showError &&
                      !compareDates(
                        requestBody.start_date,
                        requestBody.end_date
                      ) &&
                      isAdhoc !== true &&
                      "*End date cannot be less than start date")}
                </div>

                <div>
                  <Cron value={value} setValue={setValue} />
                  <div className="js-error">
                    {" "}
                    {showError &&
                      (!cron(value).isValid() || value === "* * * * *") &&
                      isAdhoc !== true &&
                      " *Invalid cron"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
              gap: "14px",
              marginTop: "18px",
            }}
          >
            <button onClick={cancelAction} className="action-button cancel">
              Cancel
            </button>
            <button
              onClick={createScheduleHandler}
              className="action-button save"
            >
              Save
            </button>
            <button
              onClick={handleRunAdhocJob}
              className={
                mode === "create"
                  ? "action-button run-adhoc-job"
                  : "action-button run-adhoc-job"
              }
            >
              {mode === "create" ? "Save & Run Adhoc Job" : "Run Adhoc Job"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobSchedular;
