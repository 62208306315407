import React from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

export function KpiCardsCopy2() {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.fontWeightLight,
    marginBottom: "10px",
    boxShadow: "none",
    color: theme.palette.text.secondary,
    fontSize: "11px",
    fontWeight: "400",
    background: "transparent",
  }));

  const StyledCard1 = styled(Grid)(({ theme }) => ({
    ...theme.typography.body1,
    // border: "1px solid #E5E7EB",
    boxShadow: "0px 4px 4px 0px rgba(174, 174, 174, 0.25)",
    borderRadius: "6px",
    padding: theme.spacing(1),
    background: " #F6F6F6",
    border: "1px solid #FFAB8A",
  }));

  const BoldDiv = styled("div")(({ theme }) => ({
    ...theme.typography.customBoldFont,
  }));

  const LightDiv = styled("div")(({ theme }) => ({
    ...theme.typography.customLightFont,
  }));

  return (
    <Grid container sx={{ columnGap: "15px" }}>
      <StyledCard1 item xs>
        <Item>Total Replenishment Requirements</Item>
        <Box display={"flex"} sx={{ justifyContent: "space-between" }}>
          <div>
            <BoldDiv>25039</BoldDiv>
            <LightDiv>Volume</LightDiv>
          </div>
          <div>
            <BoldDiv>₹12537</BoldDiv>
            <LightDiv>Value</LightDiv>
          </div>
        </Box>
      </StyledCard1>
      <StyledCard1 item xs>
        <Item>Total Replenishment Allocation</Item>
        <Box display={"flex"} sx={{ justifyContent: "space-between" }}>
          <div>
            <BoldDiv>22039</BoldDiv>
            <LightDiv>Volume</LightDiv>
          </div>
          <div>
            <BoldDiv>₹10306</BoldDiv>
            <LightDiv>Value</LightDiv>
          </div>
        </Box>
      </StyledCard1>
      <StyledCard1 item xs>
        <Box display={"flex"} sx={{ justifyContent: "space-between" }}>
          <Item>STO</Item>
          <Item>Count: 535</Item>
        </Box>
        <Box display={"flex"} sx={{ justifyContent: "space-between" }}>
          <div>
            <BoldDiv>12356</BoldDiv>
            <LightDiv>Volume</LightDiv>
          </div>
          <div>
            <BoldDiv>₹320976</BoldDiv>
            <LightDiv>Value</LightDiv>
          </div>
        </Box>
      </StyledCard1>
      <StyledCard1 item xs>
        <Box display={"flex"} sx={{ justifyContent: "space-between" }}>
          <Item>Lost Sales</Item>
          <Item>Count: 535</Item>
        </Box>
        <Box display={"flex"} sx={{ justifyContent: "space-between" }}>
          <div>
            <BoldDiv>17800</BoldDiv>
            <LightDiv>Volume</LightDiv>
          </div>
          <div>
            <BoldDiv>₹231456</BoldDiv>
            <LightDiv>Value</LightDiv>
          </div>
        </Box>
      </StyledCard1>
      <StyledCard1 item xs>
        <Item>Deviations</Item>
        <Box display={"flex"} sx={{ justifyContent: "space-between" }}>
          <div>
            <BoldDiv>--</BoldDiv>
            <LightDiv>Metric</LightDiv>
          </div>
          <div>
            <BoldDiv>--</BoldDiv>
            <LightDiv>Metric</LightDiv>
          </div>
        </Box>
      </StyledCard1>
    </Grid>
  );
}
