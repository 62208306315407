import { toast } from "react-toastify";
import BasicUploader from "../uploader/BasicUploader.js";
import { CircularIndefiniteSpinner } from "../loading/LoadingIndex.jsx";

const AddOrder = ({
  openModal,
  setOpenModal,
  uploaderRef,
  classType,
  setisUploadingSto,
  refetchSTO,
  setLoadingData,
  selectedProcess,
  setIsUploading,
  refetch,
  moduleType,
  handleOrdersMgmtDownload,
  isDownloadingTemplate,
  setIsDownloadingTemplate,
  isLoadingGroups,
  groupOptions,
  selectedGroupOption,
  setSelectedGroupOption,
}) => {
  const handleUpload = () => {
    uploaderRef.current.handleUploadClick();
  };
  return (
    <>
      {" "}
      <div className="upload-modal upload-send-modal orders-mgmt-add-order-modal">
        <div className="add-order-modal-header">
          <h1
            className="orders-mgmt-modal-heading add-order-heading"
            style={{ flexGrow: "1" }}
          >
            Add Orders
          </h1>
          <button
            onClick={() => setOpenModal(false)}
            className="add-order-close-btn"
          >
            <svg
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.85236 9.85285C9.65699 10.0482 9.34087 10.0482 9.14528 9.85285L4.99992 5.70656L0.853623 9.85285C0.658244 10.0482 0.342132 10.0482 0.146534 9.85285C-0.0488447 9.65747 -0.0488447 9.34136 0.146534 9.14576L4.29377 5.00041L0.147159 0.854111C-0.0482198 0.658732 -0.0482198 0.34262 0.147159 0.147022C0.342538 -0.0483565 0.65865 -0.0483565 0.854248 0.147022L4.99992 4.29425L9.14621 0.14796C9.34159 -0.0474191 9.6577 -0.0474191 9.8533 0.14796C10.0487 0.343339 10.0487 0.659451 9.8533 0.855049L5.70607 5.00041L9.85236 9.1467C10.0492 9.34042 10.0492 9.65913 9.85236 9.85285Z"
                fill="#3D445C"
              />
            </svg>
          </button>
        </div>
        <div className="add-order-body">
          <p className="add-order-upload-heading">Upload CSV File</p>
          <BasicUploader
            ref={uploaderRef}
            preprocessValues={classType}
            uploadType="add-new-order"
            setIsUploadingSto={setisUploadingSto}
            refetchSTO={refetchSTO}
            setLoadingData={setLoadingData}
            selectedProcess={selectedProcess}
            setIsUploading={setIsUploading}
            setOpenModal={setOpenModal}
            refetch={refetch}
            moduleType={moduleType}
            isLoadingGroups={isLoadingGroups}
            groupOptions={groupOptions}
            selectedGroupOption={selectedGroupOption}
            setSelectedGroupOption={setSelectedGroupOption}
          />
          <button
            className="add-order-dwnld-template-btn"
            onClick={() => {
              const groupId = selectedGroupOption?.id;
              if (groupId != null) {
                handleOrdersMgmtDownload(
                  `${process.env.REACT_APP_SCAI_API_ENDPOINT}/oms/download-csv-template/?group=${groupId}`,
                  setIsDownloadingTemplate
                );
              } else {
                toast.error("Please select a group to download template", {
                  autoClose: 3000,
                });
              }
            }}
          >
            <span>Download Template</span>
            <svg
              width="12"
              height="14"
              viewBox="0 0 12 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.7387 3.42617L7.82656 0.514065C7.4957 0.184354 7.05273 2.73437e-06 6.58789 2.73437e-06L2.5 0C1.5334 0 0.75 0.783399 0.75 1.75L0.750178 12.25C0.750178 13.2166 1.53358 14 2.50018 14H9.5C10.4666 14 11.25 13.2166 11.25 12.25V4.66211C11.25 4.19727 11.0668 3.7543 10.7387 3.42617ZM6.875 0.931878C6.99742 0.974698 7.11196 1.03704 7.20641 1.13146L10.1185 4.04357C10.2137 4.13711 10.2766 4.25196 10.3176 4.375H7.3125C7.07188 4.375 6.875 4.17813 6.875 3.9375V0.931878ZM10.375 12.25C10.375 12.7323 9.98234 13.125 9.5 13.125H2.5C2.01766 13.125 1.625 12.7323 1.625 12.25V1.75C1.625 1.26766 2.01766 0.875003 2.5 0.875003H6V3.9375C6 4.66211 6.58789 5.25 7.3125 5.25H10.375V12.25ZM3.375 7.4375C3.375 7.67813 3.57188 7.875 3.8125 7.875H8.1875C8.42812 7.875 8.625 7.67813 8.625 7.4375C8.625 7.19688 8.42812 7 8.1875 7H3.8125C3.57188 7 3.375 7.19688 3.375 7.4375ZM8.1875 8.75H3.8125C3.57188 8.75 3.375 8.94688 3.375 9.1875C3.375 9.42813 3.57188 9.625 3.8125 9.625H8.1875C8.42916 9.625 8.625 9.42911 8.625 9.1875C8.625 8.94589 8.42812 8.75 8.1875 8.75ZM8.1875 10.5H3.8125C3.57188 10.5 3.375 10.6969 3.375 10.9375C3.375 11.1781 3.57188 11.375 3.8125 11.375H8.1875C8.42916 11.375 8.625 11.1791 8.625 10.9375C8.625 10.6959 8.42812 10.5 8.1875 10.5Z"
                fill="#3D445C"
              />
            </svg>
            {isDownloadingTemplate && (
              <CircularIndefiniteSpinner size={"16px"} />
            )}
          </button>
        </div>
        <div className="add-order-footer">
          <button
            className="add-order-cancel-btn"
            onClick={() => setOpenModal(false)}
          >
            Cancel
          </button>
          <button
            className="add-order-upload-btn"
            onClick={() => handleUpload()}
          >
            Upload
          </button>
        </div>
      </div>
    </>
  );
};

export default AddOrder;
