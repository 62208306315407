import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import _ from "lodash";
import ErrorPage from "../globalComponent/ErrorPage/Error";
import LoginComponent from "../pages/Login";
import Reports from "../pages/Reports/ReportsIndex";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import JobSchedular from "../components/job-schedular/jobSchedular";
import { PowerBi, Screen1, Screen2 } from "../pages/PbiPoc";
import Layout from "../pages/PbiPoc/Layout";
import { useSelector } from "react-redux";
import JobSchedulerLanding from "../pages/JobSchedulerLanding";
import ConfigLanding from "../pages/ConfigLanding";
import JobSchedularConfigScreen from "../components/job-schedular/jsConfigScreen";
import NodalGraph2 from "../components/nodeGraph/nodeGraph";
import TabLayout from "../components/Navbar/navbar";
import PreprocessLanding from "../pages/Inventory/Preprocess/PreprocessesLanding";
import PreprocessLandingPP from "@/pages/Production/Preprocess/PreprocessesLanding";
import ProcessView from "../pages/Inventory/Preprocess/ProcessView";
import ProcessViewPP from "@/pages/Production/Preprocess/ProcessView";
import ProcessViewRMP from "@/pages/RawMaterial/Preprocess/ProcessView";
import InventoryDashBoard from "../pages/Inventory/Dashboard/InventoryDashboard";
import InventoryStatsDashBoard from "../pages/Inventory/Dashboard/InventoryStatisticsDashboard";
import JobSchedulerHistory from "../pages/JobSchedulerHistory";
import ReplPlanDashboard from "../pages/Inventory/Dashboard/ReplPlanDasboard";
import OrgLevelPlanView from "../pages/Inventory/Dashboard/OrgLevelPlanView";
import PlanViewAPPGroup from "../pages/Production/PlanViewAPPGroup";
import PlanViewAPPOrg from "../pages/Production/PlanViewAPPOrg";
import ProductionPlanning from "../pages/Production/ProductionPlan";
import GroupLevelPlanView from "../pages/Inventory/Dashboard/GroupLevelPlanView";
import { InventoryProfile } from "../pages/Inventory/Dashboard/Powerbi/InventoryProfile";
import PlanViewMPSGroup from "../pages/Production/PlanViewMPSGroup";
import PlanViewMPSOrg from "../pages/Production/PlanViewMPSOrg";
import { InventoryHealthClassification } from "../pages/Inventory/Dashboard/Powerbi/InventoryHealthClassification";
import TestPage from "../components/tableUtils/testPage";
import { StockLevelTable } from "../pages/Inventory/Dashboard/Powerbi/StockLevelTable";
import CreateSTO from "../pages/Inventory/Dashboard/CreateSTO";
import DemandOverviewDashboard from "../pages/Inventory/Dashboard/DemandOverviewDashboard";
import RMPlanDashboard from "../pages/RawMaterial/Dashboard/RMPlanDashboard";
import RMOrgLevelPlanView from "../pages/RawMaterial/Dashboard/OrgLevelPlanView";
import RMGroupLevelPlanView from "../pages/RawMaterial/Dashboard/GroupLevelPlanView";
import RMCreateSTO from "../pages/RawMaterial/Dashboard/CreateSTO";
import RequirementDashboard from "../pages/Requirements/Dashboards";
import IP1 from "../pages/Requirements/InnerPage1";
import IP2 from "../pages/Requirements/InnerPage2";
import CreatePWO from "../pages/Production/CreatePWO";
import RMRequirement from "../pages/RawMaterial/Dashboard/RMRequirement";
import PreprocessLandingRMP from "../pages/RawMaterial/Preprocess/PreprocessLanding";
import OrdersManagement from "@/pages/OrdersManagement/OrdersManagement";
import { BOMExplosion } from "@/pages/RawMaterial/Dashboard/BOMExplosion";

export function PBIReport() {
  const { id } = useParams();
  let cardsArr = [
    [
      "Inventory Coverage",
      "https://app.powerbi.com/view?r=eyJrIjoiYmJjMTI2NmEtM2U4My00YTgwLThhY2QtNWI4ZWVhM2U4MzY5IiwidCI6ImQxZDkzNmVjLThiZmYtNDU2MC1hOGE5LTYwMDEwZWMwMzRkZSJ9",
    ],
    [
      "YoY & MoM Sales trend",
      "https://app.powerbi.com/view?r=eyJrIjoiYmJjMTI2NmEtM2U4My00YTgwLThhY2QtNWI4ZWVhM2U4MzY5IiwidCI6ImQxZDkzNmVjLThiZmYtNDU2MC1hOGE5LTYwMDEwZWMwMzRkZSJ9",
    ],
    [
      "Sales Order & Backorder Tracking",
      "https://app.powerbi.com/view?r=eyJrIjoiYmJjMTI2NmEtM2U4My00YTgwLThhY2QtNWI4ZWVhM2U4MzY5IiwidCI6ImQxZDkzNmVjLThiZmYtNDU2MC1hOGE5LTYwMDEwZWMwMzRkZSJ9",
    ],
    [
      "Inventory Visibility (GIT, GIH, PBNS)",
      "https://app.powerbi.com/view?r=eyJrIjoiOGRhMTQ1ZDMtNGYwOC00MGNiLTkzNDEtY2RiM2NkZTI0Nzc1IiwidCI6ImQxZDkzNmVjLThiZmYtNDU2MC1hOGE5LTYwMDEwZWMwMzRkZSJ9",
    ],
    [
      "SLOB report",
      "https://app.powerbi.com/view?r=eyJrIjoiZjAxNjViYjgtNzk4Mi00OWIwLTk2M2UtZjY3OTQwMzgwOWI1IiwidCI6ImQxZDkzNmVjLThiZmYtNDU2MC1hOGE5LTYwMDEwZWMwMzRkZSJ9",
    ],
    [
      "Production Vs Inventory Vs Sales",
      "https://app.powerbi.com/view?r=eyJrIjoiMzk2ODk4ZTMtMzY0NC00MTA2LTk5YWItN2ZiNjM3M2IzMjVhIiwidCI6ImQxZDkzNmVjLThiZmYtNDU2MC1hOGE5LTYwMDEwZWMwMzRkZSJ9",
    ],
    [
      "MTD Production",
      "https://app.powerbi.com/view?r=eyJrIjoiMjY5M2ZmYjQtMzY5OC00MzI2LTgyNGMtMGE3YzJjNzRjZmU0IiwidCI6ImQxZDkzNmVjLThiZmYtNDU2MC1hOGE5LTYwMDEwZWMwMzRkZSJ9",
    ],
    [
      "Capacity Utilisation",
      "https://app.powerbi.com/view?r=eyJrIjoiYmRjZWU5NjAtNDNiMC00NWEzLTliNGQtYmU4YTcyYWUzODZmIiwidCI6ImQxZDkzNmVjLThiZmYtNDU2MC1hOGE5LTYwMDEwZWMwMzRkZSJ9",
    ],
    [
      "Weekly RP report",
      "https://app.powerbi.com/view?r=eyJrIjoiNGMxMjFiZjItYzgyZC00YTc1LTk0NmEtOGMxMTA5MzY3YTVjIiwidCI6ImQxZDkzNmVjLThiZmYtNDU2MC1hOGE5LTYwMDEwZWMwMzRkZSJ9",
    ],
    [
      "Weekly PP report",
      "https://app.powerbi.com/view?r=eyJrIjoiZjcxYzJhODQtZWE2MS00OGVhLTlmYzktZWYzMzhiMTRiYzgzIiwidCI6ImQxZDkzNmVjLThiZmYtNDU2MC1hOGE5LTYwMDEwZWMwMzRkZSJ9",
    ],
  ];
  let url = cardsArr[id - 1][1];
  let title = cardsArr[id - 1][0];
  return (
    <div>
      {" "}
      <>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <iframe
            title={title}
            width="100%"
            height="900"
            src={url}
            frameBorder="0"
            allowFullScreen={true}
          ></iframe>
        </Box>{" "}
      </>
    </div>
  );
}

const Routing = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<LoginComponent />} />
        <Route path="/test" element={<TestPage />} />
        <Route path="/snp/inventory">
          <Route path="management" element={<TabLayout />}>
            <Route path="" element={<InventoryDashBoard />} />
            <Route
              path="inventory-stats"
              element={<InventoryStatsDashBoard />}
            />
            <Route
              path="inventory-stats/inventory-profile"
              element={<InventoryProfile />}
            />
            <Route
              path="inventory-stats/inventory-health-classification"
              element={<InventoryHealthClassification />}
            />
            <Route
              path="inventory-stats/stock-level-table"
              element={<StockLevelTable />}
            />
            <Route
              path="demand-overview"
              element={<DemandOverviewDashboard />}
            />
            <Route path="job-scheduler" element={<JobSchedulerLanding />} />
            <Route path="job-scheduler-create" element={<JobSchedular />} />
            <Route
              path="job-scheduler-history/:id"
              element={<JobSchedulerHistory />}
            />
            <Route path="job-scheduler-edit/:id" element={<JobSchedular />} />
            <Route path="config" element={<ConfigLanding />} />
            <Route
              path="config-create"
              element={<JobSchedularConfigScreen />}
            />
            <Route
              path="config-edit/:id"
              element={<JobSchedularConfigScreen />}
            />
            <Route path="preprocesses" element={<PreprocessLanding />} />
            <Route
              path="preprocesses/process/:type"
              element={<ProcessView />}
            />
            <Route path="network" element={<NodalGraph2 />} />
            <Route
              path="inventory-stats"
              element={<InventoryStatsDashBoard />}
            />
          </Route>
          <Route path="replenishment-plan" element={<TabLayout />}>
            <Route path="" element={<ReplPlanDashboard />} />
            <Route path="org-plan/:planId" element={<OrgLevelPlanView />} />
            <Route path="group-plan/:planId" element={<GroupLevelPlanView />} />
            <Route path="group-plan/:planId/add-sto" element={<CreateSTO />} />
            <Route path="job-scheduler" element={<JobSchedulerLanding />} />
            <Route path="job-scheduler-create" element={<JobSchedular />} />
            <Route
              path="job-scheduler-history/:id"
              element={<JobSchedulerHistory />}
            />

            <Route path="job-scheduler-edit/:id" element={<JobSchedular />} />
            <Route
              path="job-scheduler-history/:id"
              element={<JobSchedulerHistory />}
            />
            <Route path="config" element={<ConfigLanding />} />
            <Route
              path="config-create"
              element={<JobSchedularConfigScreen />}
            />
            <Route
              path="config-edit/:id"
              element={<JobSchedularConfigScreen />}
            />
            <Route path="preprocesses" element={<PreprocessLanding />} />
            <Route
              path="preprocesses/process/:type"
              element={<ProcessView />}
            />
            <Route path="network" element={<NodalGraph2 />} />
          </Route>
        </Route>

        <Route path="/snp/raw-material">
          <Route path="requirement" element={<TabLayout />}>
            <Route path="" element={<RMRequirement />} />
            <Route path="bom-explosion" element={<BOMExplosion />} />
          </Route>
          <Route path="plan" element={<TabLayout />}>
            <Route path="" element={<RMPlanDashboard />} />
            <Route path="org-plan/:planId" element={<RMOrgLevelPlanView />} />
            <Route
              path="group-plan/:planId"
              element={<RMGroupLevelPlanView />}
            />
            <Route
              path="group-plan/:planId/add-sto"
              element={<RMCreateSTO />}
            />
            <Route path="job-scheduler" element={<JobSchedulerLanding />} />
            <Route path="job-scheduler-create" element={<JobSchedular />} />
            <Route
              path="job-scheduler-history/:id"
              element={<JobSchedulerHistory />}
            />

            <Route path="job-scheduler-edit/:id" element={<JobSchedular />} />
            <Route
              path="job-scheduler-history/:id"
              element={<JobSchedulerHistory />}
            />
            <Route path="config" element={<ConfigLanding />} />
            <Route
              path="config-create"
              element={<JobSchedularConfigScreen />}
            />
            <Route
              path="config-edit/:id"
              element={<JobSchedularConfigScreen />}
            />
            <Route path="preprocesses" element={<PreprocessLandingRMP />} />
            <Route
              path="preprocesses/process/:type"
              element={<ProcessViewRMP />}
            />
            <Route path="network" element={<NodalGraph2 />} />
          </Route>
        </Route>

        <Route path="/snp/production">
          <Route path="production-plan" element={<TabLayout />}>
            <Route path="" element={<ProductionPlanning />} />
            <Route
              path="app-group-level-view/:id"
              element={<PlanViewAPPGroup />}
            />
            <Route
              path="app-group-level-view/:id/add-pwo"
              element={<CreatePWO />}
            />
            <Route path="app-org-level-view/:id" element={<PlanViewAPPOrg />} />
            <Route path="mps-org-level-view/:id" element={<PlanViewMPSOrg />} />
            <Route
              path="mps-group-level-view/:id"
              element={<PlanViewMPSGroup />}
            />
            <Route path="job-scheduler" element={<JobSchedulerLanding />} />
            <Route path="job-scheduler-create" element={<JobSchedular />} />
            <Route
              path="job-scheduler-history/:id"
              element={<JobSchedulerHistory />}
            />
            <Route path="job-scheduler-edit/:id" element={<JobSchedular />} />
            <Route
              path="job-scheduler-history/:id"
              element={<JobSchedulerHistory />}
            />
            <Route path="config" element={<ConfigLanding />} />
            <Route
              path="config-create"
              element={<JobSchedularConfigScreen />}
            />
            <Route
              path="config-edit/:id"
              element={<JobSchedularConfigScreen />}
            />
            <Route path="preprocesses" element={<PreprocessLandingPP />} />
            <Route
              path="preprocesses/process/:type"
              element={<ProcessViewPP />}
            />
            <Route path="network" element={<NodalGraph2 />} />
          </Route>
        </Route>

        <Route path="/snp/production">
          <Route path="requirement" element={<TabLayout />}>
            <Route path="" element={<RequirementDashboard />} />
            <Route path="demand-overview" element={<IP1 />} />
            <Route path="inventory-stats" element={<IP2 />} />
            <Route path="mps-org-level-view/:id" element={<PlanViewMPSOrg />} />
            <Route
              path="mps-group-level-view/:id"
              element={<PlanViewMPSGroup />}
            />
            <Route path="job-scheduler" element={<JobSchedulerLanding />} />
            <Route path="job-scheduler-create" element={<JobSchedular />} />
            <Route
              path="job-scheduler-history/:id"
              element={<JobSchedulerHistory />}
            />

            <Route path="job-scheduler-edit/:id" element={<JobSchedular />} />
            <Route
              path="job-scheduler-history/:id"
              element={<JobSchedulerHistory />}
            />
            <Route path="config" element={<ConfigLanding />} />
            <Route
              path="config-create"
              element={<JobSchedularConfigScreen />}
            />
            <Route
              path="config-edit/:id"
              element={<JobSchedularConfigScreen />}
            />
            <Route path="preprocesses" element={<PreprocessLandingPP />} />
            <Route
              path="preprocesses/process/:type"
              element={<ProcessViewPP />}
            />
            <Route path="network" element={<NodalGraph2 />} />
          </Route>
        </Route>

        <Route path="/production/network" element={<NodalGraph2 />} />
        <Route path="/config-edit/:id" element={<JobSchedularConfigScreen />} />
        <Route path="/config-create" element={<JobSchedularConfigScreen />} />
        <Route path="*" element={<ErrorPage />} />
        <Route path="/pbi-poc" element={<Layout />}>
          <Route path="screen1" element={<Screen1 />} />
          <Route path="screen2" element={<Screen2 />} />
        </Route>
        <Route path="/pbi" element={<PowerBi />} />
        <Route path="/snp/reports" element={<Reports />} />
        <Route path="/snp/reports/:id" element={<PBIReport />} />
        <Route path="/snp/orders-management" element={<OrdersManagement />} />
      </Routes>
    </>
  );
};

export default Routing;
