import { useState, useEffect, useRef, createRef } from "react";
import MuiTable from "../../../components/muiTable/muiTable";
import { useLocation, useNavigate } from "react-router-dom";
import {
  formatDateToDateMonYear,
  formatISODateToHumanReadable,
  transformData,
  twoDecimalNumberFormat,
} from "../../../utils/utils";
import KPICard from "../../../components/kpi_cards/KPICard";
import BreadCrumbIcon from "../../../assets/breadCrumbIcon.svg";
import {
  TextField,
  Autocomplete,
  Checkbox,
  ThemeProvider,
  createTheme,
  Typography,
} from "@mui/material";
import "./styles.css";
import { useReplOrgPlanQuery } from "../../../features/api/replPlanSlice";
import { KpiCardsCopy2 } from "../../../components/kpi_cards_copy/kpicardsCopy2";
import { CheckBoxBlankIcon, checkSharpIcon } from "../../../assets/svg-icons";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../../utils/components";

const transformTableData = (arr) => {
  return arr.map((item) => ({
    stoId: item.sto_id,
    unitCost: item.unit_cost,
    totalCost: item.total_cost,
    skuName: item.product,
    stockType: item.stock_type,
    channelId: item.channel_name,
    sourceId: item.location_start,
    sourceName: item.source_name,
    destinationId: item.location_end,
    destinationName: item.destination_name,
    totalAllocationSystem: twoDecimalNumberFormat(
      item.planned_replenishment_qty
    ),
    totalAllocationUser: twoDecimalNumberFormat(item.edited_replenishment_qty),
    shortSupply: item.short_supply,
    status: item.status,
    approvedBy: item.approved_by,
    approvedDate: item.approved_date,
    createdDate: formatDateToDateMonYear(item.created_date),
    createdBy: item.created_by,
    version: item.version,
    reason: item.reason_code,
    stoDate: formatDateToDateMonYear(item.date),
    skuCode: item.sku_code ?? "",
    demand_forecast: twoDecimalNumberFormat(item.demand_forecast),
    safety_stock: twoDecimalNumberFormat(item.safety_stock),
    max_stock: twoDecimalNumberFormat(item.max_stock),
    dos: twoDecimalNumberFormat(item.dos),
    projected_sales: twoDecimalNumberFormat(item.projected_sales),
    lost_sales: twoDecimalNumberFormat(item.lost_sales),
    current_inv_dos: item.current_inv_dos,
    lead_time: twoDecimalNumberFormat(item.lead_time),
    planned_dispatch_date: formatISODateToHumanReadable(
      item.planned_dispatch_date
    ),
    required_delivery_date: formatDateToDateMonYear(
      item.required_delivery_date
    ),
  }));
};

function OrgLevelPlanView() {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  let pathSplit = path.split("/");
  let len = pathSplit.length;
  const cycleIdParam = pathSplit[len - 1];

  const [tableData, setTableData] = useState([]);
  const [stateColumnValues, setStateColumnValues] = useState([]);
  const [expanded, setExpanded] = useState(false);

  const reasonArray = [
    (row1) => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            // border: "1px solid red",
            width: "100%",
            flexGrow: "1",
          }}
          // className="active-cell"
        >
          <span className="active-cell">Ider used to...</span>
          {/* <button
            style={{
              background: "transparent",
              padding: "0",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              border: "none",
            }}
            className="info-button"
          >
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              r="5"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13 6.5C13 10.0899 10.0899 13 6.5 13C2.91015 13 0 10.0899 0 6.5C0 2.91015 2.91015 0 6.5 0C10.0899 0 13 2.91015 13 6.5ZM7.3125 3.25C7.3125 3.69873 6.94873 4.0625 6.5 4.0625C6.05127 4.0625 5.6875 3.69873 5.6875 3.25C5.6875 2.80127 6.05127 2.4375 6.5 2.4375C6.94873 2.4375 7.3125 2.80127 7.3125 3.25ZM5.6875 5.6875C5.23877 5.6875 4.875 6.05127 4.875 6.5C4.875 6.94873 5.23877 7.3125 5.6875 7.3125V9.75C5.6875 10.1987 6.05127 10.5625 6.5 10.5625H7.3125C7.76123 10.5625 8.125 10.1987 8.125 9.75C8.125 9.30127 7.76123 8.9375 7.3125 8.9375V6.5C7.3125 6.05127 6.94873 5.6875 6.5 5.6875H5.6875Z"
                fill="#A3AAC2"
              />
            </svg>
          </button> */}
        </div>
      );
    },
  ];

  function DynamicRefsExample(
    numberOfRefs,
    componentArray,
    cell,
    row,
    centerAlign
  ) {
    const refsArray = useRef(
      Array.from({ length: numberOfRefs }, () => createRef())
    );
    return (
      <div className={!centerAlign ? "action-buttons" : "action-buttons-align"}>
        {Array.from({ length: numberOfRefs }, (_, index) => {
          const Comp = componentArray[index];

          return (
            <div
              id={index}
              key={index}
              ref={refsArray.current[index]}
              placeholder={`Ref ${index + 1}`}
              //   onClick={() => actionsArray[index](row)}
              style={{
                pointerEvents: row.original.isDisabled ? "none" : "auto",
                width: "100%",
              }}
            >
              <Comp row={row.original} />
            </div>
          );
        })}
      </div>
    );
  }

  const renderLeftTableFooterGroupLevel = () => {
    return (
      <div className="table-left-bottom">
        <div className="table-left-bottom-footer">
          <p>Ongoing Cycle : 16 Jun 2023 12:00 AM -11:59 PM</p>
          <p>Upcoming Cycle : 16 Jun 2023 12:00 AM -11:59 PM</p>
        </div>
      </div>
    );
  };

  const {
    data: orgPlanViewData,
    isLoading: isLoadingOrgPlanViewData,
    isSuccess: isSuccessOrgPlanViewData,
  } = useReplOrgPlanQuery(cycleIdParam, {
    refetchOnMountOrArgChange: true,
    force: true, // This will bypass the cache
  });

  useEffect(() => {
    setTableData(
      orgPlanViewData ? transformTableData(orgPlanViewData?.results) : []
    );
  }, [orgPlanViewData, orgPlanViewData?.results]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return tableData ? (
    <ThemeProvider theme={customTheme}>
      <div style={{ width: `calc(100% - 20px)`, margin: 10 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            alignSelf: "center",
            marginTop: "12px",
            marginBottom: "12px",
          }}
          className="process-header"
        >
          <div
            className="process-header-1"
            onClick={() => navigate("/snp/inventory/replenishment-plan")}
          >
            ORG Level
          </div>
          <div>
            <svg
              width="9"
              height="9"
              viewBox="0 0 9 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L4.5 4.5L1 8"
                stroke="#858585"
                strokeLinecap="round"
              />
              <path
                d="M4.5 1L8 4.5L4.5 8"
                stroke="#929292"
                strokeLinecap="round"
              />
            </svg>
          </div>
          <div className="process-header-2">{`Plan View : ${cycleIdParam}`}</div>
        </div>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            expanded={expanded === "panel1"}
            fill="white"
            hoverColor="#e94f1c"
            backgroundColor="#494949"
            expandedBgColor="#e94f1b"
          >
            <Typography
              sx={{
                width: "33%",
                flexShrink: 0,
                fontSize: "12px",
                color: "white",
              }}
            >
              KPI&apos;s
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <KpiCardsCopy2 />
          </AccordionDetails>
        </Accordion>
        <div
          className="snp-table-container"
          style={{ marginTop: "-5px" }}
          // style={{ width: `calc( 100% - 20px)`, margin: 10 }}
        >
          <MuiTable
            columns={[
              {
                accessorKey: "stoId",
                header: "STO NO",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                size: 180,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "stoDate",
                header: "STO Date",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                size: 180,
                Cell: ({ cell, row }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "skuCode",
                header: "SKU Code",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                size: 180,
                Cell: ({ cell, row }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "skuName",
                header: "SKU Name",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                size: 180,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "sourceId",
                header: "Source ID",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                size: 180,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "sourceName",
                header: "Source Name",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                size: 180,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "destinationId",
                header: "Destination ID",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                size: 180,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "destinationName",
                header: "Destination Name",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                size: 180,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "totalAllocationUser",
                header: "Total Allocation User",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                size: 240,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "totalAllocationSystem",
                header: "Total Allocation System",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                size: 240,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "planned_dispatch_date",
                header: "Planned Dispatch Date",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                size: 180,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "required_delivery_date",
                header: "Required Delivery Date",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                size: 180,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "stokeType",
                header: "Stock Type",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                size: 180,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "unitCost",
                header: "Unit Cost",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                size: 180,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "totalCost",
                header: "Total Cost",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                size: 180,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "lead_time",
                header: "Lead Time",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                size: 180,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "shortSupply",
                header: "Short Supply",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                size: 180,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "projected_sales",
                header: "Projected Sales",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                size: 180,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "lost_sales",
                header: "Lost Sales",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                size: 180,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "demand_forecast",
                header: "Demand",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                size: 180,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "safety_stock",
                header: "Safety Stock",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                size: 180,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "max_stock",
                header: "MSL",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                size: 180,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "dos",
                header: "DOS",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                size: 180,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "current_inv_dos",
                header: "Current Inventory DOS",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                size: 180,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "status",
                header: "Status",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                size: 180,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
                Filter: ({ header }) => (
                  <Autocomplete
                    onChange={(e, newValue) => {
                      setStateColumnValues(newValue);
                      header.column.setFilterValue(newValue);
                    }}
                    sx={{
                      "& .MuiAutocomplete-clearIndicator": {
                        display: "none",
                      },
                    }}
                    multiple
                    size="small"
                    id="checkboxes-tags-demo"
                    options={["Rejected", "Completed", "In-Progress"].sort(
                      (a, b) => {
                        let ai = stateColumnValues.indexOf(a);
                        ai =
                          ai === -1
                            ? stateColumnValues.length +
                              ["Rejected", "Completed", "In-Progress"].indexOf(
                                a
                              )
                            : ai;
                        let bi = stateColumnValues.indexOf(b);
                        bi =
                          bi === -1
                            ? stateColumnValues.length +
                              ["Rejected", "Completed", "In-Progress"].indexOf(
                                b
                              )
                            : bi;
                        return ai - bi;
                      }
                    )}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option}
                    renderTags={() => null}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={CheckBoxBlankIcon}
                          checkedIcon={checkSharpIcon}
                          style={{ width: "20%", margin: "0", padding: "0" }}
                          checked={selected}
                        />
                        {option}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} label="" placeholder="" />
                    )}
                  />
                ),
                filterFn: "arrIncludesSome",
              },
              {
                accessorKey: "approvedBy",
                header: "Approved by",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                size: 180,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "approvedDate",
                header: "Approved Date",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                size: 180,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "version",
                header: "Version",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                size: 180,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "reason",
                header: "Reason",
                enableColumnActions: true,
                enableSorting: true,
                muiTableBodyCellEditTextFieldProps: {
                  select: true, //change to select for a dropdown
                },
                size: 210,
                Cell: ({ cell, row }) => {
                  const centerAlign = false;
                  return DynamicRefsExample(
                    1,
                    reasonArray,
                    cell,
                    row,
                    centerAlign
                  );
                },
              },
              {
                accessorKey: "createdDate",
                header: "Created Date",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                size: 180,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
              {
                accessorKey: "createdBy",
                header: "Created By",
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                size: 180,
                Cell: ({ cell, row, column }) => {
                  return (
                    <div
                      className={
                        row.original.isDisabled
                          ? "disabled-cell"
                          : "active-cell"
                      }
                    >
                      {cell.getValue()}
                    </div>
                  );
                },
              },
            ]}
            td={tableData}
            createPath="/js-create"
            renderBottomToolbarCustomActions={renderLeftTableFooterGroupLevel}
            shouldShowAddBtn={false}
            showDownloadIcon={true}
            isLoading={isLoadingOrgPlanViewData}
          />
        </div>
      </div>
    </ThemeProvider>
  ) : (
    "loading"
  );
}

const customTheme = createTheme({
  palette: {
    primary: {
      main: "#f5f7fa",
    },
  },
  typography: {
    fontFamily: "Inter, sans-serif",
    fontSize: 12,
    fontWeightBold: 700,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightLight: 300,
    customBoldFont: {
      fontWeight: 600,
      fontSize: 13,
    },
    customLightFont: {
      fontSize: 11,
      fontWeight: 400,
    },
    customHeading: {
      fontWeight: 300,
      fontSize: 15,
    },
  },
  components: {
    MuiDrawer: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});

export default OrgLevelPlanView;
