import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useState, useEffect, useRef } from "react";

function DemandOverview({ componentWidth, snpWidth, data }) {
  const [options, setOptions] = useState({});
  const {
    data: { actual_sales, demand_forecast },
    duration,
  } = data;
  const chartRef = useRef(null);

  Highcharts.setOptions({
    lang: {
      decimalPoint: ".",
      thousandsSep: ",",
    },
  });

  useEffect(() => {
    const chartOptions = {
      chart: {
        type: "spline",
        height: 260,
      },
      title: {
        text: "",
      },
      legend: {
        itemStyle: {
          fontFamily: "Inter",
        },
        padding: 0.5,
      },
      xAxis: {
        lineWidth: 1,
        lineColor: "#c2c2c2",
        categories: duration,
        labels: {
          style: {
            fontSize: "10px",
            fontFamily: "Inter",
          },
        },
        accessibility: {
          description: "Months of the year",
        },
        plotLines: [
          {
            color: "#FFDFC1", // Color value
            dashStyle: "Solid", // Style of the plot line. Default to solid
            value: 8, // Value of where the line will appear
            width: 2, // Width of the line
          },
        ],
        plotBands: [
          {
            color: "#f9f9f9", // Color value
            from: 0, // Start of the plot band
            to: 8, // End of the plot band
          },
        ],
      },
      yAxis: {
        lineWidth: 1,
        lineColor: "#c2c2c2",
        labels: {
          x: -8,
          style: {
            fontSize: "10px",
            fontFamily: "Inter",
          },
        },
        title: {
          text: "",
        },
      },
      tooltip: {
        crosshairs: true,
        shared: true,
      },
      plotOptions: {
        area: {
          fillOpacity: 0.1,
        },
      },
      credits: {
        enabled: false,
      },
      colors: ["#04BFDA", "#668DF2"],
      series: [
        {
          marker: {
            symbol: "circle",
          },
          name: "Actual Sales",
          data: actual_sales,
          tooltip: {
            valuePrefix: "₹",
          },
        },
        {
          marker: {
            symbol: "circle",
          },
          name: "Demand",
          data: demand_forecast,
          tooltip: {
            valuePrefix: "₹",
          },
        },
      ],
    };
    setOptions(chartOptions);
  }, []);

  useEffect(() => {
    const chart = chartRef.current.chart;
    // Function to handle resizing
    const handleResize = () => {
      // Calculate the new width based on the container's width
      // const newWidth = chartRef.current.container.offsetWidth;
      chart.setSize(componentWidth);
    };
    handleResize();
  }, [snpWidth]);

  return (
    <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
  );
}

export default DemandOverview;
