export const planOrgGroupDetailData = [
  {
    mps_wo_no: "MPS-WO329001",
    wo_time_period: "5-Oct-23",
    sku_code: "92853BU",
    sku_name: "LED NIGHT LAMP SPHERICAL 0.5W - BLUE",
    production_plant_code: "OEM-PLNT22",
    production_plant_name: "SAKAR ELE",
    shift: "S1",
    workcenter: "Workcenter-1",
    workforce: "Workforce L1",
    batch_size: 250,
    moq: 500,
    capacity: 2500,
    production_requirement: 9224,
    production_allocation_system: 9250,
    production_allocation_user: 9600,
    pre_build_allocation: 650,
    short_supply: 0,
    reason_code: "-",
    comments: "-",
    version: "V2",
    cycle: "Oct-23",
    status: "PENDING",
    net_sales_rate: 318816,
    item_cost: 259200,
    created_by: "snpuser3@3sc.com",
    created_date: "28-Sep-23",
    updated_date: "snpuser3@3sc.com",
    updated_by: "14-Sep-23",
  },
  {
    mps_wo_no: "MPS-WO329002",
    wo_time_period: "6-Oct-23",
    sku_code: "92853GN",
    sku_name: "LED NIGHT LAMP SPHERICAL 0.5W - GREEN",
    production_plant_code: "OEM-PLNT22",
    production_plant_name: "SAKAR ELE",
    shift: "S1",
    workcenter: "Workcenter-2",
    workforce: "Workforce L2",
    batch_size: 250,
    moq: 500,
    capacity: 3000,
    production_requirement: 8613,
    production_allocation_system: 8750,
    production_allocation_user: 8750,
    pre_build_allocation: 0,
    short_supply: 300,
    reason_code: "-",
    comments: "-",
    version: "V1",
    cycle: "Oct-23",
    status: "PENDING",
    net_sales_rate: 408975,
    item_cost: 332500,
    created_by: "snpuser3@3sc.com",
    created_date: "28-Sep-23",
    updated_date: "snpuser3@3sc.com",
    updated_by: "14-Sep-23",
  },
  {
    mps_wo_no: "MPS-WO329003",
    wo_time_period: "7-Oct-23",
    sku_code: "92853OR",
    sku_name: "LED NIGHT LAMP SPHERICAL 0.5W - ORANGE",
    production_plant_code: "OEM-PLNT23",
    production_plant_name: "TECHNO E",
    shift: "S2",
    workcenter: "Assembly line-4A",
    workforce: "Workforce L3",
    batch_size: 250,
    moq: 500,
    capacity: 3500,
    production_requirement: 10629,
    production_allocation_system: 10750,
    production_allocation_user: 10750,
    pre_build_allocation: 0,
    short_supply: 0,
    reason_code: "-",
    comments: "-",
    version: "V1",
    cycle: "Oct-23",
    status: "PENDING",
    net_sales_rate: 251228,
    item_cost: 204250,
    created_by: "snpuser3@3sc.com",
    created_date: "28-Sep-23",
    updated_date: "snpuser3@3sc.com",
    updated_by: "14-Sep-23",
  },
  {
    mps_wo_no: "MPS-WO329004",
    wo_time_period: "8-Oct-23",
    sku_code: "92853PN",
    sku_name: "LED NIGHT LAMP SPHERICAL 0.5W - PINK",
    production_plant_code: "OEM-PLNT23",
    production_plant_name: "TECHNO E",
    shift: "S2",
    workcenter: "Workcenter-1",
    workforce: "Workforce L1",
    batch_size: 250,
    moq: 500,
    capacity: 4000,
    production_requirement: 9033,
    production_allocation_system: 9250,
    production_allocation_user: 9250,
    pre_build_allocation: 0,
    short_supply: 900,
    reason_code: "-",
    comments: "-",
    version: "V1",
    cycle: "Oct-23",
    status: "PENDING",
    net_sales_rate: 341325,
    item_cost: 277500,
    created_by: "snpuser3@3sc.com",
    created_date: "28-Sep-23",
    updated_date: "snpuser3@3sc.com",
    updated_by: "14-Sep-23",
  },
  {
    mps_wo_no: "MPS-WO329005",
    wo_time_period: "9-Oct-23",
    sku_code: "92853RD",
    sku_name: "LED NIGHT LAMP SPHERICAL 0.5W - RED",
    production_plant_code: "OEM-PLNT24",
    production_plant_name: "Lumisense",
    shift: "S2",
    workcenter: "Workcenter-2",
    workforce: "Workforce L2",
    batch_size: 250,
    moq: 500,
    capacity: 2500,
    production_requirement: 8974,
    production_allocation_system: 9000,
    production_allocation_user: 9000,
    pre_build_allocation: 750,
    short_supply: 0,
    reason_code: "-",
    comments: "-",
    version: "V1",
    cycle: "Oct-23",
    status: "PENDING",
    net_sales_rate: 420660,
    item_cost: 342000,
    created_by: "snpuser3@3sc.com",
    created_date: "28-Sep-23",
    updated_date: "snpuser3@3sc.com",
    updated_by: "14-Sep-23",
  },
  {
    mps_wo_no: "MPS-WO329006",
    wo_time_period: "10-Oct-23",
    sku_code: "92853WH",
    sku_name: "LED NIGHT LAMP SPHERICAL 0.5W - WHITE",
    production_plant_code: "OEM-PLNT24",
    production_plant_name: "Lumisense",
    shift: "S2",
    workcenter: "Workcenter-3",
    workforce: "Workforce L3",
    batch_size: 250,
    moq: 500,
    capacity: 3000,
    production_requirement: 10119,
    production_allocation_system: 10250,
    production_allocation_user: 10250,
    pre_build_allocation: 0,
    short_supply: 150,
    reason_code: "-",
    comments: "-",
    version: "V1",
    cycle: "Oct-23",
    status: "PENDING",
    net_sales_rate: 453870,
    item_cost: 369000,
    created_by: "snpuser3@3sc.com",
    created_date: "28-Sep-23",
    updated_date: "snpuser3@3sc.com",
    updated_by: "14-Sep-23",
  },
  {
    mps_wo_no: "MPS-WO329007",
    wo_time_period: "11-Oct-23",
    sku_code: "92853YW",
    sku_name: "LED NIGHT LAMP SPHERICAL 0.5W - YELLOW",
    production_plant_code: "OEM-PLNT22",
    production_plant_name: "SAKAR ELE",
    shift: "S3",
    workcenter: "Assembly line-4A",
    workforce: "Workforce L3",
    batch_size: 250,
    moq: 500,
    capacity: 3500,
    production_requirement: 11548,
    production_allocation_system: 11750,
    production_allocation_user: 11750,
    pre_build_allocation: 800,
    short_supply: 0,
    reason_code: "-",
    comments: "-",
    version: "V1",
    cycle: "Oct-23",
    status: "PENDING",
    net_sales_rate: 419123,
    item_cost: 340750,
    created_by: "snpuser3@3sc.com",
    created_date: "28-Sep-23",
    updated_date: "snpuser3@3sc.com",
    updated_by: "14-Sep-23",
  },
  {
    mps_wo_no: "MPS-WO329008",
    wo_time_period: "12-Oct-23",
    sku_code: "ABTM01103",
    sku_name: "LED BATTEN ROUND 10W 3000K",
    production_plant_code: "OEM-PLNT22",
    production_plant_name: "SAKAR ELE",
    shift: "S3",
    workcenter: "Workcenter-1",
    workforce: "Workforce L1",
    batch_size: 250,
    moq: 500,
    capacity: 4000,
    production_requirement: 10105,
    production_allocation_system: 10250,
    production_allocation_user: 10250,
    pre_build_allocation: 0,
    short_supply: 0,
    reason_code: "-",
    comments: "-",
    version: "V1",
    cycle: "Oct-23",
    status: "PENDING",
    net_sales_rate: 416048,
    item_cost: 338250,
    created_by: "snpuser3@3sc.com",
    created_date: "28-Sep-23",
    updated_date: "snpuser3@3sc.com",
    updated_by: "14-Sep-23",
  },
  {
    mps_wo_no: "MPS-WO329009",
    wo_time_period: "13-Oct-23",
    sku_code: "ABTM01107",
    sku_name: "LED BATTEN ROUND 10W 6500K",
    production_plant_code: "OEM-PLNT23",
    production_plant_name: "TECHNO E",
    shift: "S1",
    workcenter: "Workcenter-2",
    workforce: "Workforce L2",
    batch_size: 250,
    moq: 500,
    capacity: 2500,
    production_requirement: 9968,
    production_allocation_system: 10000,
    production_allocation_user: 10350,
    pre_build_allocation: 0,
    short_supply: 100,
    reason_code: "-",
    comments: "-",
    version: "V3",
    cycle: "Oct-23",
    status: "PENDING",
    net_sales_rate: 356454,
    item_cost: 289800,
    created_by: "snpuser3@3sc.com",
    created_date: "28-Sep-23",
    updated_date: "snpuser3@3sc.com",
    updated_by: "14-Sep-23",
  },
  {
    mps_wo_no: "MPS-WO329010",
    wo_time_period: "14-Oct-23",
    sku_code: "ABTM01203",
    sku_name: "LED BATTEN ROUND 20W 3000K",
    production_plant_code: "OEM-PLNT23",
    production_plant_name: "TECHNO E",
    shift: "S1",
    workcenter: "Workcenter-2",
    workforce: "Workforce L2",
    batch_size: 250,
    moq: 500,
    capacity: 3000,
    production_requirement: 10930,
    production_allocation_system: 11000,
    production_allocation_user: 11000,
    pre_build_allocation: 0,
    short_supply: 0,
    reason_code: "-",
    comments: "-",
    version: "V1",
    cycle: "Oct-23",
    status: "PENDING",
    net_sales_rate: 284130,
    item_cost: 231000,
    created_by: "snpuser3@3sc.com",
    created_date: "28-Sep-23",
    updated_date: "snpuser3@3sc.com",
    updated_by: "14-Sep-23",
  },
  {
    mps_wo_no: "MPS-WO329011",
    wo_time_period: "15-Oct-23",
    sku_code: "ABTM01207",
    sku_name: "LED BATTEN ROUND 20W 6500K",
    production_plant_code: "OEM-PLNT24",
    production_plant_name: "Lumisense",
    shift: "S2",
    workcenter: "Workcenter-3",
    workforce: "Workforce L3",
    batch_size: 250,
    moq: 500,
    capacity: 3500,
    production_requirement: 10224,
    production_allocation_system: 10250,
    production_allocation_user: 10600,
    pre_build_allocation: 0,
    short_supply: 0,
    reason_code: "-",
    comments: "-",
    version: "V2",
    cycle: "Oct-23",
    status: "PENDING",
    net_sales_rate: 312912,
    item_cost: 254400,
    created_by: "snpuser3@3sc.com",
    created_date: "28-Sep-23",
    updated_date: "snpuser3@3sc.com",
    updated_by: "14-Sep-23",
  },
  {
    mps_wo_no: "MPS-WO329012",
    wo_time_period: "16-Oct-23",
    sku_code: "ABTM02207",
    sku_name: "LED BATTEN SQUARE 20W 6500K",
    production_plant_code: "OEM-PLNT24",
    production_plant_name: "Lumisense",
    shift: "S2",
    workcenter: "Workcenter-3",
    workforce: "Workforce L3",
    batch_size: 250,
    moq: 500,
    capacity: 4000,
    production_requirement: 9010,
    production_allocation_system: 9250,
    production_allocation_user: 9600,
    pre_build_allocation: 150,
    short_supply: 0,
    reason_code: "-",
    comments: "-",
    version: "V3",
    cycle: "Oct-23",
    status: "PENDING",
    net_sales_rate: 389664,
    item_cost: 316800,
    created_by: "snpuser3@3sc.com",
    created_date: "28-Sep-23",
    updated_date: "snpuser3@3sc.com",
    updated_by: "14-Sep-23",
  },
  {
    mps_wo_no: "MPS-WO329013",
    wo_time_period: "5-Oct-23",
    sku_code: "ABTM03243",
    sku_name: "LED BATTEN OVAL 24W 3000K",
    production_plant_code: "OEM-PLNT22",
    production_plant_name: "SAKAR ELE",
    shift: "S2",
    workcenter: "Assembly line-3B",
    workforce: "Workforce L3",
    batch_size: 250,
    moq: 500,
    capacity: 2500,
    production_requirement: 9298,
    production_allocation_system: 9500,
    production_allocation_user: 9850,
    pre_build_allocation: 0,
    short_supply: 0,
    reason_code: "-",
    comments: "-",
    version: "V3",
    cycle: "Oct-23",
    status: "PENDING",
    net_sales_rate: 290772,
    item_cost: 236400,
    created_by: "snpuser3@3sc.com",
    created_date: "28-Sep-23",
    updated_date: "snpuser3@3sc.com",
    updated_by: "14-Sep-23",
  },
  {
    mps_wo_no: "MPS-WO329014",
    wo_time_period: "6-Oct-23",
    sku_code: "ABTM03247",
    sku_name: "LED BATTEN OVAL 24W 6500K",
    production_plant_code: "OEM-PLNT22",
    production_plant_name: "SAKAR ELE",
    shift: "S2",
    workcenter: "Workcenter-2",
    workforce: "Workforce L2",
    batch_size: 250,
    moq: 500,
    capacity: 3000,
    production_requirement: 10697,
    production_allocation_system: 10750,
    production_allocation_user: 11100,
    pre_build_allocation: 0,
    short_supply: 550,
    reason_code: "-",
    comments: "-",
    version: "V2",
    cycle: "Oct-23",
    status: "PENDING",
    net_sales_rate: 464202,
    item_cost: 377400,
    created_by: "snpuser3@3sc.com",
    created_date: "28-Sep-23",
    updated_date: "snpuser3@3sc.com",
    updated_by: "14-Sep-23",
  },
  {
    mps_wo_no: "MPS-WO329015",
    wo_time_period: "7-Oct-23",
    sku_code: "ABTM04203",
    sku_name: "LED BATTEN ANMOL 20W 3000K",
    production_plant_code: "OEM-PLNT23",
    production_plant_name: "TECHNO E",
    shift: "S3",
    workcenter: "Workcenter-2",
    workforce: "Workforce L2",
    batch_size: 250,
    moq: 500,
    capacity: 3500,
    production_requirement: 9951,
    production_allocation_system: 10000,
    production_allocation_user: 10350,
    pre_build_allocation: 0,
    short_supply: 0,
    reason_code: "-",
    comments: "-",
    version: "V2",
    cycle: "Oct-23",
    status: "PENDING",
    net_sales_rate: 229149,
    item_cost: 186300,
    created_by: "snpuser3@3sc.com",
    created_date: "28-Sep-23",
    updated_date: "snpuser3@3sc.com",
    updated_by: "14-Sep-23",
  },
  {
    mps_wo_no: "MPS-WO329016",
    wo_time_period: "8-Oct-23",
    sku_code: "92853PN",
    sku_name: "LED NIGHT LAMP SPHERICAL 0.5W - PINK",
    production_plant_code: "OEM-PLNT23",
    production_plant_name: "TECHNO E",
    shift: "S3",
    workcenter: "Workcenter-3",
    workforce: "Workforce L3",
    batch_size: 250,
    moq: 500,
    capacity: 4000,
    production_requirement: 8796,
    production_allocation_system: 9000,
    production_allocation_user: 9000,
    pre_build_allocation: 0,
    short_supply: 200,
    reason_code: "-",
    comments: "-",
    version: "V1",
    cycle: "Oct-23",
    status: "PENDING",
    net_sales_rate: 309960,
    item_cost: 252000,
    created_by: "snpuser3@3sc.com",
    created_date: "28-Sep-23",
    updated_date: "snpuser3@3sc.com",
    updated_by: "14-Sep-23",
  },
  {
    mps_wo_no: "MPS-WO329017",
    wo_time_period: "9-Oct-23",
    sku_code: "92853RD",
    sku_name: "LED NIGHT LAMP SPHERICAL 0.5W - RED",
    production_plant_code: "OEM-PLNT24",
    production_plant_name: "Lumisense",
    shift: "S1",
    workcenter: "Workcenter-1",
    workforce: "Workforce L1",
    batch_size: 250,
    moq: 500,
    capacity: 2500,
    production_requirement: 8545,
    production_allocation_system: 8750,
    production_allocation_user: 8750,
    pre_build_allocation: 0,
    short_supply: 0,
    reason_code: "-",
    comments: "-",
    version: "V1",
    cycle: "Oct-23",
    status: "PENDING",
    net_sales_rate: 355163,
    item_cost: 288750,
    created_by: "snpuser3@3sc.com",
    created_date: "28-Sep-23",
    updated_date: "snpuser3@3sc.com",
    updated_by: "14-Sep-23",
  },
  {
    mps_wo_no: "MPS-WO329018",
    wo_time_period: "10-Oct-23",
    sku_code: "92853WH",
    sku_name: "LED NIGHT LAMP SPHERICAL 0.5W - WHITE",
    production_plant_code: "OEM-PLNT24",
    production_plant_name: "Lumisense",
    shift: "S2",
    workcenter: "Workcenter-2",
    workforce: "Workforce L2",
    batch_size: 250,
    moq: 500,
    capacity: 3000,
    production_requirement: 8614,
    production_allocation_system: 8750,
    production_allocation_user: 9100,
    pre_build_allocation: 0,
    short_supply: 0,
    reason_code: "-",
    comments: "-",
    version: "V2",
    cycle: "Oct-23",
    status: "PENDING",
    net_sales_rate: 291018,
    item_cost: 236600,
    created_by: "snpuser3@3sc.com",
    created_date: "28-Sep-23",
    updated_date: "snpuser3@3sc.com",
    updated_by: "14-Sep-23",
  },
  {
    mps_wo_no: "MPS-WO329019",
    wo_time_period: "11-Oct-23",
    sku_code: "92853YW",
    sku_name: "LED NIGHT LAMP SPHERICAL 0.5W - YELLOW",
    production_plant_code: "OEM-PLNT22",
    production_plant_name: "SAKAR ELE",
    shift: "S2",
    workcenter: "Workcenter-3",
    workforce: "Workforce L3",
    batch_size: 250,
    moq: 500,
    capacity: 3500,
    production_requirement: 11989,
    production_allocation_system: 12000,
    production_allocation_user: 12350,
    pre_build_allocation: 350,
    short_supply: 0,
    reason_code: "-",
    comments: "-",
    version: "V2",
    cycle: "Oct-23",
    status: "PENDING",
    net_sales_rate: 273429,
    item_cost: 222300,
    created_by: "snpuser3@3sc.com",
    created_date: "28-Sep-23",
    updated_date: "snpuser3@3sc.com",
    updated_by: "14-Sep-23",
  },
  {
    mps_wo_no: "MPS-WO329020",
    wo_time_period: "12-Oct-23",
    sku_code: "ABTM01103",
    sku_name: "LED BATTEN ROUND 10W 3000K",
    production_plant_code: "OEM-PLNT22",
    production_plant_name: "SAKAR ELE",
    shift: "S2",
    workcenter: "Assembly line-3B",
    workforce: "Workforce L3",
    batch_size: 250,
    moq: 500,
    capacity: 4000,
    production_requirement: 8168,
    production_allocation_system: 8250,
    production_allocation_user: 8600,
    pre_build_allocation: 0,
    short_supply: 450,
    reason_code: "-",
    comments: "-",
    version: "V3",
    cycle: "Oct-23",
    status: "PENDING",
    net_sales_rate: 232716,
    item_cost: 189200,
    created_by: "snpuser3@3sc.com",
    created_date: "28-Sep-23",
    updated_date: "snpuser3@3sc.com",
    updated_by: "14-Sep-23",
  },
  {
    mps_wo_no: "MPS-WO329021",
    wo_time_period: "13-Oct-23",
    sku_code: "ABTM01107",
    sku_name: "LED BATTEN ROUND 10W 6500K",
    production_plant_code: "OEM-PLNT23",
    production_plant_name: "TECHNO E",
    shift: "S2",
    workcenter: "Assembly line-4A",
    workforce: "Workforce L1",
    batch_size: 250,
    moq: 500,
    capacity: 2500,
    production_requirement: 10558,
    production_allocation_system: 10750,
    production_allocation_user: 11100,
    pre_build_allocation: 0,
    short_supply: 0,
    reason_code: "-",
    comments: "-",
    version: "V2",
    cycle: "Oct-23",
    status: "PENDING",
    net_sales_rate: 368631,
    item_cost: 299700,
    created_by: "snpuser3@3sc.com",
    created_date: "28-Sep-23",
    updated_date: "snpuser3@3sc.com",
    updated_by: "14-Sep-23",
  },
  {
    mps_wo_no: "MPS-WO329022",
    wo_time_period: "14-Oct-23",
    sku_code: "ABTM01203",
    sku_name: "LED BATTEN ROUND 20W 3000K",
    production_plant_code: "OEM-PLNT23",
    production_plant_name: "TECHNO E",
    shift: "S2",
    workcenter: "Workcenter-2",
    workforce: "Workforce L2",
    batch_size: 250,
    moq: 500,
    capacity: 3000,
    production_requirement: 8630,
    production_allocation_system: 8750,
    production_allocation_user: 9100,
    pre_build_allocation: 0,
    short_supply: 250,
    reason_code: "-",
    comments: "-",
    version: "V3",
    cycle: "Oct-23",
    status: "PENDING",
    net_sales_rate: 391755,
    item_cost: 318500,
    created_by: "snpuser3@3sc.com",
    created_date: "28-Sep-23",
    updated_date: "snpuser3@3sc.com",
    updated_by: "14-Sep-23",
  },
  {
    mps_wo_no: "MPS-WO329023",
    wo_time_period: "15-Oct-23",
    sku_code: "ABTM01207",
    sku_name: "LED BATTEN ROUND 20W 6500K",
    production_plant_code: "OEM-PLNT24",
    production_plant_name: "Lumisense",
    shift: "S3",
    workcenter: "Workcenter-3",
    workforce: "Workforce L3",
    batch_size: 250,
    moq: 500,
    capacity: 3500,
    production_requirement: 11295,
    production_allocation_system: 11500,
    production_allocation_user: 11500,
    pre_build_allocation: 200,
    short_supply: 0,
    reason_code: "-",
    comments: "-",
    version: "V1",
    cycle: "Oct-23",
    status: "PENDING",
    net_sales_rate: 452640,
    item_cost: 368000,
    created_by: "snpuser3@3sc.com",
    created_date: "28-Sep-23",
    updated_date: "snpuser3@3sc.com",
    updated_by: "14-Sep-23",
  },
  {
    mps_wo_no: "MPS-WO329024",
    wo_time_period: "16-Oct-23",
    sku_code: "ABTM02207",
    sku_name: "LED BATTEN SQUARE 20W 6500K",
    production_plant_code: "OEM-PLNT24",
    production_plant_name: "Lumisense",
    shift: "S3",
    workcenter: "Workcenter-2",
    workforce: "Workforce L2",
    batch_size: 250,
    moq: 500,
    capacity: 4000,
    production_requirement: 9593,
    production_allocation_system: 9750,
    production_allocation_user: 9750,
    pre_build_allocation: 780,
    short_supply: 0,
    reason_code: "-",
    comments: "-",
    version: "V1",
    cycle: "Oct-23",
    status: "PENDING",
    net_sales_rate: 383760,
    item_cost: 312000,
    created_by: "snpuser3@3sc.com",
    created_date: "28-Sep-23",
    updated_date: "snpuser3@3sc.com",
    updated_by: "14-Sep-23",
  },
];
