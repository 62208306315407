import { useState } from "react";

export default function Toggle() {
  const [radio, setRadio] = useState();
  const handleChange = (e) => {
    const { name, value } = e.target;

    setRadio({
      [name]: value,
    });

    console.log(radio);
  };
  return (
    <div className="radio-buttons">
      <h3 className="filter-heading">Unit</h3>
      <span style={{ marginRight: 20 }}>
        <span
          style={{
            fontSize: 12,
            fontWeight: 500,
            fontFamily: "IBM Plex Sans",
            color: "#7a7a7a",
            position: "relative",
            bottom: "2px",
          }}
        >
          Value
        </span>
        <input
          id="mac"
          value="mac"
          name="platform"
          type="radio"
          onChange={handleChange}
          style={{ margin: "3px" }}
        />
      </span>
      <span style={{ marginRight: 20 }}>
        <span
          style={{
            fontSize: 12,
            fontWeight: 500,
            fontFamily: "IBM Plex Sans",
            color: "#7a7a7a",
            position: "relative",
            bottom: "2px",
          }}
        >
          Volume
        </span>
        <input
          id="linux"
          value="linux"
          name="platform"
          type="radio"
          onChange={handleChange}
        />
      </span>
    </div>
  );
}
