import Highcharts from "highcharts";
import HighchartsReact, { color } from "highcharts-react-official";
import { useState, useEffect, useRef } from "react";
// import { rawNumberFormatter } from "./utils";

function InventoryStatistics({ componentWidth, snpWidth }) {
  const [options, setOptions] = useState({});
  const chartRef = useRef(null);

  //   const formattedProjectedInvData = rawNumberFormatter(projected_inventory);
  //   const formattedActualInvData = rawNumberFormatter(actual_inventory);
  //   const formattedMslData = rawNumberFormatter(msl);
  //   const formattedSafetyStockData = rawNumberFormatter(safety_stock);

  Highcharts.setOptions({
    lang: {
      decimalPoint: ".",
      thousandsSep: ",",
    },
  });

  useEffect(() => {
    const chartOptions = {
      chart: {
        type: "spline",
        height: 260,
      },
      title: {
        text: "",
      },
      legend: {
        itemStyle: {
          fontFamily: "Inter",
        },
        padding: 0.5,
      },
      xAxis: {
        lineWidth: 1,
        lineColor: "#c2c2c2",
        categories: [
          "Jan 24",
          "Feb 24",
          "Mar 24",
          "Apr 24",
          "May 24",
          "Jun 24",
          "Jul 24",
          "Aug 24",
          "Sep 24",
          "Oct 24",
          "Nov 24",
          "Dec 24",
        ],
        labels: {
          style: {
            fontSize: "10px",
            fontFamily: "Inter",
          },
        },
        accessibility: {
          description: "Months of the year",
        },
        // plotLines: [
        //   {
        //     color: "#FFDFC1", // Color value
        //     dashStyle: "Solid", // Style of the plot line. Default to solid
        //     value: 8, // Value of where the line will appear
        //     width: 2, // Width of the line
        //   },
        // ],
        // plotBands: [
        //   {
        //     color: "#f9f9f9", // Color value
        //     from: 0, // Start of the plot band
        //     to: 8, // End of the plot band
        //   },
        // ],
      },
      yAxis: {
        lineWidth: 1,
        lineColor: "#c2c2c2",
        gridLineWidth: 0,
        labels: {
          x: -8,
          style: {
            fontSize: "10px",
            fontFamily: "Inter",
          },
        },
        title: {
          text: "",
        },
      },
      tooltip: {
        crosshairs: true,
        shared: true,
      },
      plotOptions: {
        area: {
          fillOpacity: 0.1,
        },
      },
      credits: {
        enabled: false,
      },
      colors: ["#72C37F", "#83ADFF", "#FF8686", "#C79BFF", "#FFC56D"],
      series: [
        {
          type: "line",
          color: '#969696',
          pointPlacement: "on",
          lineWidth: 1,
          marker: {
            symbol: "circle",
            fillColor: "#FFFFFF",
            lineWidth: 1,
            lineColor: "#969696",
          },
          name: "Projected RM Inventory",
          tooltip: {
            valuePrefix: "₹",
            valueSuffix: "",
          },
          data: [
            1300, 2500, 3500, 2000, 3500, 3900, 1300, 500, 1000, 1500, 1800,
            2500,
          ],
        },
        {
          type: "line",
          color: '#57BBE3',
          lineWidth: 1,
          marker: {
            symbol: "circle",
            fillColor: "#FFFFFF",
            lineWidth: 1,
            lineColor: "#57BBE3",
          },
          name: "Projected FG Inventory",
          tooltip: {
            valuePrefix: "₹",
            valueSuffix: "",
          },
          data: [
            1100, 2200, 3300, 2500, 4100, 3400, 1300, 500, 400, 900, 1600, 1800,
          ],
        },
        {
          type: 'column',
          color: "#494949",
          name: 'Actual RM',
          data: [
            1000, 2000, 3300, 2100, 2500, 3500, 1100, 300, 700, 1300, 1100,
            2200,
          ],
        tooltip: {
          valuePrefix: "₹",
          valueSuffix: "",
        },
        },
        {
          type: 'column',
          color: "#FF8251",
          name: 'Actual FG',
          data: [
            1200, 2300, 3300, 2100, 3200, 3500, 1100, 200, 500, 1200, 1400,
            2500,
          ],
          tooltip: {
            valuePrefix: "₹",
            valueSuffix: "",
        },
      }
      ],
    };
    setOptions(chartOptions);
  }, []);

  useEffect(() => {
    const chart = chartRef.current.chart;
    // Function to handle resizing
    const handleResize = () => {
      // Calculate the new width based on the container's width
      // const newWidth = chartRef.current.container.offsetWidth;
      chart.setSize(componentWidth);
    };
    handleResize();
  }, [snpWidth]);

  return (
    <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
  );
}

export default InventoryStatistics;
