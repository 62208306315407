import { DataTableColumnHeader } from "@/components/tanstack-table/data-table-column-header";
import { useRef, createRef } from "react";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import { Skeleton } from "@/components/ui/skeleton";
import { useDispatch } from "react-redux";

const getActionColumnName = (isPlanner, isApprover) => {
  if (isPlanner) {
    return "Action - Send / Revoke";
  } else if (isApprover) {
    return "Action - Approve / Reject";
  } else {
    return "Action";
  }
};

const viewColumn = (row, navigatePath, handlePlanDetails) => {
  const navigate = useNavigate();
  return (
    <div className="table-cell-align action-view-btn-box">
      <button
        className="action-view-btn"
        onClick={() => {
          handlePlanDetails({ ...row });
          navigate(navigatePath);
        }}
      >
        View
      </button>
    </div>
  );
};

const statusColumn = (row) => {
  return (
    <div className="table-cell-align status-box py-1 lg:py-2 flex justify-center">
      <span className="status-circle"></span>
      <span>{row.status}</span>
    </div>
  );
};

const mpsColumn = (row) => {
  return (
    <div className="table-cell-align status-box py-1 lg:py-2 flex justify-center">
      <span className="mps">2 Plans</span>
    </div>
  );
};

const planStatusColumn = (row, setopenApprovalCommentsModal, getComments) => {
  const { approvalId } = row;
  return (
    <div
      className="table-cell-align"
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        flexGrow: "1",
        height: 22,
        alignItems: "center",
      }}
    >
      <div style={{ color: "#111725" }}>{row.planStatus}</div>
      <button
        style={{
          background: "transparent",
          padding: "0px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "50%",
          border: "none",
        }}
        className={`info-button ${approvalId === null ? "info-disable" : null}`}
        onClick={() => {
          setopenApprovalCommentsModal(true);
          getComments(row.approvalId);
        }}
        disabled={approvalId === null}
      >
        <svg
          width="13"
          height="13"
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          r="5"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13 6.5C13 10.0899 10.0899 13 6.5 13C2.91015 13 0 10.0899 0 6.5C0 2.91015 2.91015 0 6.5 0C10.0899 0 13 2.91015 13 6.5ZM7.3125 3.25C7.3125 3.69873 6.94873 4.0625 6.5 4.0625C6.05127 4.0625 5.6875 3.69873 5.6875 3.25C5.6875 2.80127 6.05127 2.4375 6.5 2.4375C6.94873 2.4375 7.3125 2.80127 7.3125 3.25ZM5.6875 5.6875C5.23877 5.6875 4.875 6.05127 4.875 6.5C4.875 6.94873 5.23877 7.3125 5.6875 7.3125V9.75C5.6875 10.1987 6.05127 10.5625 6.5 10.5625H7.3125C7.76123 10.5625 8.125 10.1987 8.125 9.75C8.125 9.30127 7.76123 8.9375 7.3125 8.9375V6.5C7.3125 6.05127 6.94873 5.6875 6.5 5.6875H5.6875Z"
            fill="#A3AAC2"
          />
        </svg>
      </button>
    </div>
  );
};

const approvalStatusColumn = (row) => {
  const { approvalId, approvalDetails } = row;
  return (
    <div
      style={{
        display: "flex",
        gap: "10px",
        justifyContent: "center",
      }}
      className="table-cell-align"
    >
      {approvalId !== null &&
        approvalDetails &&
        approvalDetails.levelwiseApprovalStatus.length !== 0 &&
        approvalDetails.levelwiseApprovalStatus.map((levelItem, index) => (
          <div
            className={`status-indicator-app ${
              levelItem.status !== "APPROVED"
                ? levelItem.status !== "REJECTED"
                  ? levelItem.status !== "PENDING"
                    ? "awaiting-status"
                    : "pending-status"
                  : "rejected-status"
                : "approved-status"
            }`}
            key={index}
          ></div>
        ))}
    </div>
  );
};

const actionApprovalColumn = (
  row,
  setSelectedGroupPlan,
  setOpenSendModal,
  setApproveRejectValue,
  setOpenApproveRejectModal,
  isPlanner,
  isApprover
) => {
  const { approvalId, approvalDetails, planStatus } = row;
  const approveRejectDisable = () => {
    if (approvalId !== null && approvalDetails) {
      if (
        approvalDetails.permissions.canApprove === true &&
        approvalDetails.permissions.canReject === true
      ) {
        return false;
      } else {
        return true;
      }
    }
  };

  return (
    <div style={{ display: "flex" }}>
      {isPlanner && approvalId === null && (
        <div
          style={{
            border: "1px solid #d9d9d9",
            borderRadius: "19px",
            padding: "2px 4px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxSizing: "border-box",
            margin: "auto",
          }}
        >
          <span
            style={{
              fontSize: "11px",
              marginRight: "0px",
              marginBottom: "unset",
              marginTop: "unset",
              lineHeight: "unset",
              minWidth: "50px",
              textAlign: "center",
            }}
          >
            Send
          </span>
          <label
            className="send-revoke-box small-switch"
            onClick={() => {
              setSelectedGroupPlan(row);
              setOpenSendModal(true);
              setApproveRejectValue("SUBMITTED");
            }}
            disabled={true}
          >
            <input type="checkbox" className="send-revoke-input" disabled />
            <span className="send-revoke-slider send-revoke-round"></span>
          </label>
        </div>
      )}
      {isPlanner && approvalId !== null && approvalDetails && (
        <div
          style={{
            border: "1px solid #d9d9d9",
            borderRadius: "19px",
            padding: "2px 4px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxSizing: "border-box",
            margin: "auto",
            opacity:
              approvalDetails.permissions.canSubmit === true ||
              approvalDetails.permissions.canRevoke
                ? 1
                : 0.4,
          }}
        >
          <span
            style={{
              fontSize: "11px",
              marginRight: "0px",
              marginBottom: "unset",
              marginTop: "unset",
              lineHeight: "unset",
              minWidth: "50px",
              textAlign: "center",
            }}
          >
            {approvalId !== null && approvalDetails
              ? approvalDetails.permissions.canSubmit === true
                ? "Send"
                : approvalDetails.permissions.canRevoke === true
                ? "Revoke"
                : "Revoke"
              : null}
          </span>
          <label
            className="send-revoke-box small-switch"
            onClick={() => {
              if (
                approvalDetails.permissions.canSubmit === true ||
                approvalDetails.permissions.canRevoke === true
              ) {
                setSelectedGroupPlan(row);
                setOpenSendModal(true);
                const actionValue =
                  approvalDetails.permissions.canSubmit === true
                    ? "SUBMITTED"
                    : approvalDetails.permissions.canRevoke === true
                    ? "REVOKED"
                    : "";
                setApproveRejectValue(actionValue);
              }
            }}
            disabled={true}
          >
            <input
              type="checkbox"
              className="send-revoke-input"
              disabled
              checked={approvalDetails.permissions.canRevoke}
            />
            <span className="send-revoke-slider send-revoke-round"></span>
          </label>
        </div>
      )}
      {isApprover && approvalId !== null && approvalDetails && (
        <div className="approval-btns">
          <button
            className="approval-btn approve"
            onClick={() => {
              setOpenApproveRejectModal(true);
              setSelectedGroupPlan(row);
              setApproveRejectValue("APPROVED");
            }}
            style={{
              opacity: approveRejectDisable() ? 0.4 : 1,
            }}
            // disabled={approveRejectDisable}
            disabled={approveRejectDisable()}
          >
            Approve
          </button>
          <button
            className="approval-btn reject"
            onClick={() => {
              setOpenApproveRejectModal(true);
              setApproveRejectValue("REJECTED");
              setSelectedGroupPlan(row);
            }}
            style={{
              opacity: approveRejectDisable() ? 0.4 : 1,
            }}
            disabled={approveRejectDisable()}
          >
            Reject
          </button>
        </div>
      )}{" "}
    </div>
  );
};
const planLiveColumn = (row, handleMakeLive, isPlanner, isApprover) => {
  const { approvalId, approvalDetails, planStatus, isPlanLive } = row;
  return (
    <div
      style={{ width: "100%", display: "flex" }}
      className="table-cell-align"
    >
      <button
        className={
          isPlanLive !== true || (!isPlanner && planStatus !== "Live")
            ? planStatus !== "Live"
              ? "grp-action-live-btn"
              : "grp-action-live-btn live-btn-enable"
            : "grp-action-live-btn grp-action-live-enable"
        }
        style={{ margin: "auto", height: "22px", fontSize: "12px" }}
        disabled={!row.isPlanLive || !isPlanner}
        onClick={() => {
          handleMakeLive(row);
        }}
      >
        Live
      </button>
    </div>
  );
};

export const generateColumns = (arr) => {
  let cols = [];
  arr.map((item, index) => {
    cols.push({
      accessorKey: item[0],
      header: ({ column, header }) => (
        <div className="py-[10px] lg:py-[8px]">
          <DataTableColumnHeader
            column={column}
            title={item[1]}
            header={header}
          />{" "}
        </div>
      ),
      cell: ({ row, cell }) => {
        const widthValue = cell.column.getSize();
        return (
          <div className="flex items-center justify-center">
            <div
              className="antialiased truncate text-center py-[8px] lg:py-[6px]"
              style={{ width: widthValue, minWidth: "150px" }}
            >
              {row.getValue(item[0])}
            </div>
          </div>
        );
      },
      size: 270,
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
      enableSorting: true,
      enableHiding: true,
    });
  });
  return cols;
};

export const ppOrgColumns = (columnData) => {
  let columns = [...generateColumns(columnData)];
  columns.push({
    accessorKey: "status",
    header: ({ column, header }) => (
      <div className="py-[10px] lg:py-[8px]">
        <DataTableColumnHeader column={column} title="Status" header={header} />
      </div>
    ),
    cell: ({ cell, row }) => {
      return <>{statusColumn(row.original)}</>;
    },
    enableHiding: false,
    size: 250,
  });
  columns.push({
    accessorKey: "mps",
    header: ({ column, header }) => (
      <div className="py-[10px] lg:py-[8px]">
        <DataTableColumnHeader column={column} title="MPS" header={header} />
      </div>
    ),
    cell: ({ cell, row }) => {
      return <>{mpsColumn(row.original)}</>;
    },
    enableHiding: false,
    size: 250,
  });
  columns.push({
    id: "actions",
    accessorKey: "Action",
    header: ({ column, header }) => (
      <div className="font-semibold font-sans lg:text-[1.4rem] antialiased !px-[6px] lg:!px-[4px]">
        <DataTableColumnHeader
          column={column}
          title="View Plan"
          header={header}
        />
      </div>
    ),
    cell: ({ cell, row }) => {
      const navigatePath = `/snp/production/production-plan/app-org-level-view/${row.original.cycleId}`;
      return <>{viewColumn(row.original, navigatePath)}</>;
    },
    enableHiding: false,
    size: 250,
  });
  // columns.push({
  //   accessorKey: "planStatus",
  //   header: ({ column, header }) => (
  //     <div className="py-[10px] lg:py-[8px]">
  //       <DataTableColumnHeader
  //         column={column}
  //         title="Plan Status"
  //         header={header}
  //       />
  //     </div>
  //   ),
  //   cell: ({ cell, row }) => {
  //     return <>{planStatusColumn(row.original)}</>;
  //   },
  //   enableHiding: false,
  //   size: 250,
  // });

  return columns;
};

export const ppGroupColumns = (
  columnData,
  setopenApprovalCommentsModal,
  getComments,
  handleMakeLive,
  setSelectedGroupPlan,
  setOpenSendModal,
  setApproveRejectValue,
  setOpenApproveRejectModal,
  handlePlanDetails,
  isPlanner,
  isApprover
) => {
  let columns = [...generateColumns(columnData)];
  columns.push({
    accessorKey: "planStatus",
    header: ({ column, header }) => (
      <div>
        <DataTableColumnHeader
          column={column}
          title="Plan Status"
          header={header}
        />
      </div>
    ),
    cell: ({ cell, row }) => {
      return (
        <>
          {planStatusColumn(
            row.original,
            setopenApprovalCommentsModal,
            getComments
          )}
        </>
      );
    },
    enableHiding: false,
    size: 250,
  });
  columns.push({
    accessorKey: "approvalStatus",
    header: ({ column, header }) => (
      <div>
        <DataTableColumnHeader
          column={column}
          title="Approval Status"
          header={header}
        />
      </div>
    ),
    cell: ({ cell, row }) => {
      return <>{approvalStatusColumn(row.original)}</>;
    },
    enableHiding: false,
    size: 250,
  });
  columns.push({
    accessorKey: "action1",
    header: ({ column, header }) => (
      <div>
        <DataTableColumnHeader
          column={column}
          title={getActionColumnName(isPlanner, isApprover)}
          header={header}
        />
      </div>
    ),
    cell: ({ cell, row }) => {
      return (
        <>
          {actionApprovalColumn(
            row.original,
            setSelectedGroupPlan,
            setOpenSendModal,
            setApproveRejectValue,
            setOpenApproveRejectModal,
            isPlanner,
            isApprover
          )}
        </>
      );
    },
    enableHiding: false,
    size: 250,
  });
  columns.push({
    accessorKey: "planLive",
    header: ({ column, header }) => (
      <div>
        <DataTableColumnHeader
          column={column}
          title="Action - Make Live"
          header={header}
        />
      </div>
    ),
    cell: ({ cell, row }) => {
      return (
        <>
          {planLiveColumn(row.original, handleMakeLive, isPlanner, isApprover)}
        </>
      );
    },
    enableHiding: false,
    size: 250,
  });
  columns.push({
    id: "actions",
    accessorKey: "Actions",
    header: ({ column, header }) => (
      <div>
        <DataTableColumnHeader
          column={column}
          title="View Plan"
          header={header}
        />
      </div>
    ),
    cell: ({ cell, row }) => {
      const dispatch = useDispatch();
      const navigatePath = `/snp/production/production-plan/app-group-level-view/${row.original.planId}`;
      return <>{viewColumn(row.original, navigatePath, handlePlanDetails)}</>;
    },
    enableHiding: false,
    size: 250,
  });
  return columns;
};

export const planOrgDetailCols = [
  {
    accessorKey: "mps_wo_no",
    header: "MPS WO No",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "wo_time_period",
    header: "WO Time Period",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "sku_code",
    header: "SKU Code",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "sku_name",
    header: "SKU Name",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "production_plant_code",
    header: "Production Plant Code",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "production_plant_name",
    header: "Production Plant Name",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "shift",
    header: "Shift",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "workcenter",
    header: "Workcenter",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "workforce",
    header: "Workforce",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "batch_size",
    header: "Batch Size",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "moq",
    header: "MOQ",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "capacity",
    header: "Capacity",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "production_requirement",
    header: "Production Requirement",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "production_allocation_system",
    header: "Production Allocation System",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "production_allocation_user",
    header: "Production Allocation User",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "pre_build_allocation",
    header: "Pre-Build Allocation",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "short_supply",
    header: "Short Supply",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "reason_code",
    header: "Reason code",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "comments",
    header: "Comments",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "version",
    header: "Version",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "cycle",
    header: "Cycle",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "status",
    header: "Status",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "net_sales_rate",
    header: "Net Sales Rate",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "item_cost",
    header: "Item-Cost",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "created_by",
    header: "Created By",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "created_date",
    header: "Created Date",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "updated_date",
    header: "Updated Date",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "updated_by",
    header: "Updated By",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
];

export const planGroupDetailCols = [
  {
    accessorKey: "mps_wo_no",
    header: "MPS WO No",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "wo_time_period",
    header: "WO Time Period",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "sku_code",
    header: "SKU Code",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "sku_name",
    header: "SKU Name",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "production_plant_code",
    header: "Production Plant Code",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "production_plant_name",
    header: "Production Plant Name",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "shift",
    header: "Shift",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "workcenter",
    header: "Workcenter",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "workforce",
    header: "Workforce",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "batch_size",
    header: "Batch Size",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "moq",
    header: "MOQ",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "capacity",
    header: "Capacity",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "production_requirement",
    header: "Production Requirement",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "production_allocation_system",
    header: "Production Allocation System",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "production_allocation_user",
    header: "Production Allocation User",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "pre_build_allocation",
    header: "Pre-Build Allocation",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "short_supply",
    header: "Short Supply",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "reason_code",
    header: "Reason code",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "comments",
    header: "Comments",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "version",
    header: "Version",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "cycle",
    header: "Cycle",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "status",
    header: "Status",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "net_sales_rate",
    header: "Net Sales Rate",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "item_cost",
    header: "Item-Cost",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "created_by",
    header: "Created By",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "created_date",
    header: "Created Date",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "updated_date",
    header: "Updated Date",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "updated_by",
    header: "Updated By",
    enableColumnOrdering: false,
    enableEditing: false,
    enableSorting: true,
    Cell: ({ cell, row, column }) => {
      return (
        <div
          className={row.original.isDisabled ? "disabled-cell" : "active-cell"}
        >
          {cell.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "action",
    header: "Action",
    enableColumnActions: false,
    enableSorting: false,
    muiTableBodyCellEditTextFieldProps: {
      select: true, //change to select for a dropdown
    },
    size: 210,
    Cell: ({ cell, row }) => {
      const centerAlign = true;
      const multipleElements = true;
      return DynamicRefsExample(
        2,
        actionGroupPlanArray,
        cell,
        row,
        centerAlign,
        multipleElements
      );
    },
  },
];

function DynamicRefsExample(
  numberOfRefs,
  componentArray,
  cell,
  row,
  centerAlign,
  multipleElements = false
) {
  const refsArray = useRef(
    Array.from({ length: numberOfRefs }, () => createRef())
  );
  return (
    <div className={!centerAlign ? "action-buttons" : "action-buttons-align"}>
      {Array.from({ length: numberOfRefs }, (_, index) => {
        const Comp = componentArray[index];

        return (
          <div
            id={index}
            key={index}
            ref={refsArray.current[index]}
            placeholder={`Ref ${index + 1}`}
            //   onClick={() => actionsArray[index](row)}
            style={{
              pointerEvents: row.original.isDisabled ? "none" : "auto",
              display: "flex",
              width: multipleElements ? "" : "100%",
            }}
          >
            <Comp row={row.original} />
          </div>
        );
      })}
    </div>
  );
}

const actionGroupPlanArray = [
  (row1) => {
    const { row } = row1;
    return (
      <div>
        <button
          className="group-plan-action-button remove"
          style={{}}
          //   onClick={() => handleSingleSTOApproval(row.stoId, false)}
        >
          <svg
            width="13"
            height="12"
            viewBox="0 0 13 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.3228 11.8228C12.0884 12.0573 11.709 12.0573 11.4743 11.8228L6.4999 6.84728L1.52435 11.8228C1.28989 12.0573 0.910558 12.0573 0.675841 11.8228C0.441386 11.5884 0.441386 11.209 0.675841 10.9743L5.65252 5.9999L0.676591 1.02435C0.442136 0.789893 0.442136 0.410558 0.676591 0.175841C0.911046 -0.0586137 1.29038 -0.0586137 1.5251 0.175841L6.4999 5.15252L11.4755 0.176966C11.7099 -0.0574889 12.0892 -0.0574889 12.324 0.176966C12.5584 0.411421 12.5584 0.790755 12.324 1.02547L7.34728 5.9999L12.3228 10.9755C12.5591 11.2079 12.5591 11.5904 12.3228 11.8228Z"
              fill="#3D445C"
            />
          </svg>
        </button>
      </div>
    );
  },
  (row1) => {
    const { row } = row1;
    return (
      <div>
        <button
          className="group-plan-action-button save"
          //   onClick={() => handleSingleSTOApproval(row.stoId, true)}
        >
          <svg
            width="13"
            height="9"
            viewBox="0 0 13 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.339 1.17228C12.5682 1.42715 12.5506 1.82248 12.2996 2.05525L5.05608 8.774C4.8066 9.0054 4.42008 8.98925 4.19016 8.73781L0.66447 4.88218C0.433187 4.62926 0.447578 4.2338 0.696613 3.9989C0.945648 3.76401 1.33502 3.77862 1.5663 4.03155L4.67671 7.43303L11.4696 1.13226C11.7206 0.899479 12.1098 0.917397 12.339 1.17228Z"
              fill="#3D445C"
            />
          </svg>
        </button>
      </div>
    );
  },
];

export function loadingColumns() {
  const columns = Array(10)
    .fill({})
    .map((item, index) => {
      return {
        accessorKey: `column_${index}`,
        header: ({ column, header }) => (
          <div className="py-[20px] lg:py-[15px]">
            <DataTableColumnHeader column={column} title={""} header={header} />
          </div>
        ),
        cell: ({ row }) => (
          <div className="flex items-center justify-center h-full py-[8px] lg:py-[6px]">
            <Skeleton className="h-5 w-1/2" />
          </div>
        ),
      };
    });
  return columns;
}

export function loadingSubColumns() {
  const columns = Array(2)
    .fill({})
    .map((item, index) => {
      return {
        accessorKey: `column_${index}`,
        header: ({ column, header }) => (
          <div className="py-[20px] lg:py-[15px]">
            <DataTableColumnHeader column={column} title={""} header={header} />
          </div>
        ),
        cell: ({ row }) => (
          <div className="flex items-center justify-center h-full py-[8px] lg:py-[6px] color">
            <Skeleton className="h-5 w-1/2" />
          </div>
        ),
      };
    });
  return columns;
}

export function loadingPlantToWareHouseColumns() {
  const columns = [
    {
      accessorKey: "plant_code",
      header: ({ column, header }) => (
        <DataTableColumnHeader
          column={column}
          title={"Plant ID"}
          header={header}
        />
      ),
      cell: ({ row, cell }) => {
        const widthValue = cell.column.getSize();
        return (
          <div className="flex items-center justify-center">
            <div
              className="antialiased truncate text-center"
              style={{ width: widthValue, minWidth: "150px" }}
            >
              <Skeleton
                className="h-5 w-20 lg:!py-2 py-1 mx-auto"
                bgColor="bg-stone-200"
              />
            </div>
          </div>
        );
      },
      size: 330,
      enableSorting: false,
    },
    {
      accessorKey: "warehouse_code",
      header: ({ column, header }) => (
        <div className="py-[8px] lg:py-[6px]">
          <DataTableColumnHeader
            column={column}
            title={"Warehouse ID"}
            header={header}
          />
        </div>
      ),
      cell: ({ row, cell }) => {
        const widthValue = cell.column.getSize();
        return (
          <div className="flex items-center justify-center">
            <div
              className="antialiased truncate text-center py-[8px] lg:py-[6px]"
              style={{ width: widthValue, minWidth: "150px" }}
            >
              <Skeleton
                className="h-5 w-28 lg:!py-2 py-1 mx-auto"
                bgColor="bg-stone-200"
              />
            </div>
          </div>
        );
      },
      size: 250,
      enableSorting: false,
    },
    {
      accessorKey: "sku_code",
      header: ({ column, header }) => (
        <DataTableColumnHeader
          column={column}
          title={"SKU ID"}
          header={header}
        />
      ),
      cell: ({ row, cell }) => {
        const widthValue = cell.column.getSize();
        return (
          <div className="flex items-center justify-center">
            <div
              className="antialiased truncate text-center"
              style={{ width: widthValue, minWidth: "150px" }}
            >
              <Skeleton
                className="h-5 w-28 lg:!py-2 py-1 mx-auto"
                bgColor="bg-stone-200"
              />
            </div>
          </div>
        );
      },
      size: 250,
      enableSorting: false,
    },
    {
      accessorKey: "date",
      header: ({ column, header }) => (
        <DataTableColumnHeader column={column} title={"Date"} header={header} />
      ),
      cell: ({ row, cell }) => {
        const widthValue = cell.column.getSize();
        return (
          <div className="flex items-center justify-center">
            <div
              className="antialiased truncate text-center"
              style={{ width: widthValue, minWidth: "150px" }}
            >
              <Skeleton
                className="h-5 w-28 lg:!py-2 py-1 mx-auto"
                bgColor="bg-stone-200"
              />
            </div>
          </div>
        );
      },
      size: 250,
      enableSorting: false,
    },
    {
      accessorKey: "allocation",
      header: ({ column, header }) => (
        <DataTableColumnHeader
          column={column}
          title={"Allocation"}
          header={header}
        />
      ),
      cell: ({ row, cell }) => {
        const widthValue = cell.column.getSize();
        return (
          <div className="flex items-center justify-center">
            <div
              className="antialiased truncate text-center"
              style={{ width: widthValue, minWidth: "150px" }}
            >
              <Skeleton
                className="h-5 w-28 lg:!py-2 py-1 mx-auto"
                bgColor="bg-stone-200"
              />
            </div>
          </div>
        );
      },
      size: 250,
      enableSorting: false,
    },
    {
      accessorKey: "number_of_cases",
      header: ({ column, header }) => (
        <DataTableColumnHeader
          column={column}
          title={"Number of Cases"}
          header={header}
        />
      ),
      cell: ({ row, cell }) => {
        const widthValue = cell.column.getSize();
        return (
          <div className="flex items-center justify-center">
            <div
              className="antialiased truncate text-center"
              style={{ width: widthValue, minWidth: "150px" }}
            >
              <Skeleton
                className="h-5 w-28 lg:!py-2 py-1 mx-auto"
                bgColor="bg-stone-200"
              />
            </div>
          </div>
        );
      },
      size: 250,
      enableSorting: false,
    },
  ];
  return columns;
}
