import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentEditSto: null,
  originalSTOValues: {},
  isGroupLevelEditModeOn: false,
  editedSTOList: {},
};

const productionPlanSlice = createSlice({
  name: "productionPlanSlice",
  initialState,
  reducers: {
    setCurrentEditSto: (state, action) => {
      state.currentEditSto = action.payload;
    },
    setOriginalSTOValues: (state, action) => {
      state.originalSTOValues = action.payload;
    },
    setIsGroupLevelEditModeOn: (state, action) => {
      state.isGroupLevelEditModeOn = action.payload;
    },
    setEditedSTOList: (state, action) => {
      const { payload, type } = action.payload;
      switch (type) {
        case "API_BODY": {
          const sto = payload.sto;
          const editedValue = Number(payload.editedValue);
          const reason = payload.reason;
          const comments = payload.comments;
          let tempEditedSTOList = { ...state.editedSTOList };
          let editedWPO = tempEditedSTOList[payload.wpoId];
          if (editedWPO) {
            let plantToWarehouseId = `${payload.wpoId}-${sto.sku_code}-${sto.plant_code}-${sto.warehouse_code}-${sto.date}`;
            let objToUpdateIndex = editedWPO.findIndex((item) => {
              return (
                `${payload.wpoId}-${item.sku_code}-${item.plant_code}-${item.warehouse_code}-${item.date}` ===
                plantToWarehouseId
              );
            });
            if (objToUpdateIndex !== -1) {
              let plantToWareHouseToUpdate = editedWPO[objToUpdateIndex];
              plantToWareHouseToUpdate.edited_qty = editedValue;
              editedWPO[objToUpdateIndex] = plantToWareHouseToUpdate;
              tempEditedSTOList[payload.wpoId] = editedWPO;
              state.editedSTOList = { ...tempEditedSTOList };
            } else {
              editedWPO.push({
                sku_code: sto.sku_code,
                plant_code: sto.plant_code,
                warehouse_code: sto.warehouse_code,
                date: sto.date,
                planned_qty: sto.allocation,
                edited_qty: editedValue,
                reason_code: reason,
                comments: comments ? comments : null,
              });
              tempEditedSTOList[payload.wpoId] = editedWPO;
              state.editedSTOList = { ...tempEditedSTOList };
            }
          } else {
            tempEditedSTOList[payload.wpoId] = [
              {
                sku_code: sto.sku_code,
                plant_code: sto.plant_code,
                warehouse_code: sto.warehouse_code,
                date: sto.date,
                planned_qty: sto.allocation,
                edited_qty: editedValue,
                reason_code: reason,
                comments: comments ? comments : null,
              },
            ];
            state.editedSTOList = { ...tempEditedSTOList };
          }
          break;
        }
        case "FILTER_OUT/STO_ID": {
          const sto = payload.stoValues;
          let tempEditedSTOList = state.editedSTOList;
          let editedWPO = tempEditedSTOList[sto.wpo_id];
          if (editedWPO) {
            let plantToWarehouseId = `${sto.wpoId}-${sto.sku_code}-${sto.plant_code}-${sto.warehouse_code}-${sto.date}`;
            let objToUpdateIndex = editedWPO.findIndex(
              (item) =>
                `${payload.wpoId}-${item.sku_code}-${item.plant_code}-${item.warehouse_code}-${item.date}` ===
                plantToWarehouseId
            );
            editedWPO.splice(objToUpdateIndex, 1);
            tempEditedSTOList[sto.wpo_id] = editedWPO;
            state.editedSTOList = { ...tempEditedSTOList };
          }

          break;
        }
        case "RESET": {
          state.editedSTOList = {};
          break;
        }
      }
    },
  },
});

export const {
  setCurrentEditSto,
  setOriginalSTOValues,
  setIsGroupLevelEditModeOn,
  setEditedSTOList,
} = productionPlanSlice.actions;
export default productionPlanSlice.reducer;
