export function rawNumberFormatter(rawData) {
  return rawData.map((item) => {
    let formattedString = new Intl.NumberFormat("en-US", {
      style: "decimal",
      maximumFractionDigits: 2,
    }).format(item);
    const parsedNumber = parseFloat(formattedString.replace(/,/g, ""));
    return parsedNumber;
  });
}
