import "./CalendarIcon.scss";

const CalendarIcon = () => {
  const currentDate = new Date();
  const options = { month: "short" };
  const currentMonth = currentDate.toLocaleString("en-US", options);
  const day = currentDate.getDate();
  const currentDay = day.toString().padStart(2, "0");
  return (
    <div className="calendar-icon-layout">
      <div className="calendar-icon-month">{currentMonth}</div>
      <div className="calendar-icon-day">{currentDay}</div>
    </div>
  );
};

export default CalendarIcon;
