import "./heatMap.scss";

function HeatMap() {
  return (
    <div className="invent-hc-main-container">
      <div className="kpi-container">
        <div className="kpi-card kpi-card-1">
          <span>In Stock</span>
          <span>1000 SKUs</span>
        </div>
        <div className="kpi-card kpi-card-2">
          <span>Excess Stock</span>
          <span>2500 SKUs</span>
        </div>
        <div className="kpi-card kpi-card-3">
          <span>Under Stock</span>
          <span>500 SKUs</span>
        </div>
        <div className="kpi-card kpi-card-4">
          <span>Out of Stock</span>
          <span>0 SKUs</span>
        </div>
      </div>
      <div className="invent-hc">
        <div className="invent-hc-left-container">
          <div className="invent-hc-left-incon">
            <p>Volume</p>
            <svg
              width="75"
              height="9"
              viewBox="0 0 75 9"
              fill="red"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M73.177 4.85356C73.372 4.6583 73.372 4.34172 73.177 4.14646L69.995 0.964475C69.799 0.769213 69.483 0.769213 69.287 0.964475C69.092 1.15974 69.092 1.47632 69.287 1.67158L72.116 4.50001L69.287 7.32844C69.092 7.5237 69.092 7.84028 69.287 8.03554C69.483 8.23081 69.799 8.23081 69.995 8.03554L73.177 4.85356ZM0 5L73 5.00001L73 4.00001L0 4L0 5Z"
                fill="#E6E6E6"
              />
            </svg>
          </div>
        </div>
        <div className="invent-hc-container">
          <div className="invent-hc-top-container">
            <div className="invent-hc-top">X</div>
            <div className="invent-hc-top">Y</div>
            <div className="invent-hc-top">Z</div>
          </div>
          <div className="invent-hc-mid-container">
            <div className="invent-hc-left">
              <p>High</p>
            </div>
            <div className="invent-hc-card invent-hc-card-1">
              <h3>SKU12</h3>
              <p>Value:$2.3M</p>
              <p>Volume:23,000</p>
            </div>
            <div className="invent-hc-card invent-hc-card-2">
              <h3>SKU12</h3>
              <p>Value:$2.3M</p>
              <p>Volume:23,000</p>
            </div>
            <div className="invent-hc-card invent-hc-card-3">
              <h3>SKU12</h3>
              <p>Value:$2.3M</p>
              <p>Volume:23,000</p>
            </div>
            <div className="invent-hc-right">
              <p>A</p>
            </div>
          </div>
          <div className="invent-hc-mid-container">
            <div className="invent-hc-left">
              <p>Medium</p>
            </div>
            <div className="invent-hc-card invent-hc-card-4">
              <h3>SKU12</h3>
              <p>Value:$2.3M</p>
              <p>Volume:23,000</p>
            </div>
            <div className="invent-hc-card invent-hc-card-5">
              <h3>SKU12</h3>
              <p>Value:$2.3M</p>
              <p>Volume:23,000</p>
            </div>
            <div className="invent-hc-card invent-hc-card-6">
              <h3>SKU12</h3>
              <p>Value:$2.3M</p>
              <p>Volume:23,000</p>
            </div>
            <div className="invent-hc-right">
              <p>B</p>
            </div>
          </div>
          <div className="invent-hc-mid-container">
            <div className="invent-hc-left">
              <p>Low</p>
            </div>
            <div className="invent-hc-card invent-hc-card-7">
              <h3>SKU12</h3>
              <p>Value:$2.3M</p>
              <p>Volume:23,000</p>
            </div>
            <div className="invent-hc-card invent-hc-card-8">
              <h3>SKU12</h3>
              <p>Value:$2.3M</p>
              <p>Volume:23,000</p>
            </div>
            <div className="invent-hc-card invent-hc-card-9">
              <h3>SKU12</h3>
              <p>Value:$2.3M</p>
              <p>Volume:23,000</p>
            </div>
            <div className="invent-hc-right">
              <p>C</p>
            </div>
          </div>
          <div className="invent-hc-top-container invent-hc-bottom-container">
            <div className="invent-hc-bottom">Low</div>
            <div className="invent-hc-bottom">Medium</div>
            <div className="invent-hc-bottom">High</div>
          </div>
          <div className="invent-hc-bottom-label">
            <p>Variability</p>
            <svg
              width="75"
              height="9"
              viewBox="0 0 75 9"
              fill="red"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M73.177 4.85356C73.372 4.6583 73.372 4.34172 73.177 4.14646L69.995 0.964475C69.799 0.769213 69.483 0.769213 69.287 0.964475C69.092 1.15974 69.092 1.47632 69.287 1.67158L72.116 4.50001L69.287 7.32844C69.092 7.5237 69.092 7.84028 69.287 8.03554C69.483 8.23081 69.799 8.23081 69.995 8.03554L73.177 4.85356ZM0 5L73 5.00001L73 4.00001L0 4L0 5Z"
                fill="#E6E6E6"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeatMap;
