import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon,
} from "@radix-ui/react-icons";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

export const transformData = (arr) => {
  return arr.map((item) => ({
    ...item,
    stoId: item.sto_id,
    skuCode: item.sku_code,
    skuName: item.sku_name,
    startNodeCode: item.start_node_code,
    startNodeName: item.start_node_name,
    endNodeCode: item.end_node_code,
    endNodeName: item.end_node_name,
    channelName: item.channel_name,
    groupCode: item.group_code,
    orderStatus: item.order_status,
    planStatus: item.plan_status,
    isExpired: item.is_expired,
    expiryDate: item.expiry_date,
    asOnDate: item.as_on_date,
    orderType: item.order_type,
    planType: item.plan_type,
    orderQuantity: item.order_quantity,
    totalQuantity: item.total_quantity,
    promoCode: item.promo_code,
    promoDescription: item.promo_description,
    promoType: item.promo_type,
    promoFreeQuantity: item.promo_free_quantity,
    openQuantity: item.open_quantity,
    deliveredOrderQuantity: item.delivered_order_quantity,
    inTransitOrderQuantity: item.in_transit_order_quantity,
    pendingOrderQuantity: item.pending_order_quantity,
    orderPlanDate: item.order_plan_date,
    orderDispatchDate: item.order_dispatch_date,
    requestedDeliveryDate: item.requested_delivery_date,
    salesOrderNumber: item.sales_order_number,
    invoiceNumber: item.invoice_number,
    invoiceLineItemNumber: item.invoice_line_item_number,
    endNodeGroup: item.end_node_group,
    orderQuantitySum: item.order_quantity_sum,
    unitSellingPrice: item.unit_selling_price,
    netValue: item.net_value,
    transportModeType: item.transport_mode_type,
    variableCost: item.variable_cost,
    fixedCost: item.fixed_cost,
    unitCost: item.unit_cost,
    totalCost: item.total_cost,
    leadTime: item.lead_time,
    reasonCode: item.reason_code,
    comments: item.comments,
    createdBy: item.created_by,
    createdAt: item.created_at,
    updatedBy: item.updated_by,
    updatedAt: item.updated_at,
    isManuallyCreated: item.is_manually_created,
  }));
};

export const ordersMgmttColumnHeaders = [
  ["stoId", "STO ID"],
  ["skuCode", "SKU Code"],
  ["skuName", "SKU Name"],
  ["startNodeCode", "Start Node Code"],
  ["startNodeName", "Start Node Name"],
  ["endNodeCode", "End Node Code"],
  ["endNodeName", "End Node Name"],
  ["channelName", "Channel Name"],
  ["groupCode", "Group Code"],
  ["orderStatus", "Order Status"],
  ["planStatus", "Plan Status"],
  ["isExpired", "Is Expired"],
  ["expiryDate", "Expiry Date"],
  ["asOnDate", "As On Date"],
  ["orderType", "Order Type"],
  ["planType", "Plan Type"],
  ["orderQuantity", "Order Quantity"],
  ["totalQuantity", "Total Quantity"],
  ["promoCode", "Promo Code"],
  ["promoDescription", "Promo Description"],
  ["promoType", "Promo Type"],
  ["promoFreeQuantity", "Promo Free Quantity"],
  ["openQuantity", "Open Quantity"],
  ["deliveredOrderQuantity", "Delivered Order Quantity"],
  ["inTransitOrderQuantity", "In Transit Order Quantity"],
  ["pendingOrderQuantity", "Pending Order Quantity"],
  ["orderPlanDate", "Order Plan Date"],
  ["orderDispatchDate", "Order Dispatch Date"],
  ["requestedDeliveryDate", "Requested Delivery Date"],
  ["salesOrderNumber", "Sales Order Number"],
  ["invoiceNumber", "Invoice Number"],
  ["invoiceLineItemNumber", "Invoice Line Item Number"],
  ["endNodeGroup", "End Node Group"],
  ["orderQuantitySum", "Order Quantity Sum"],
  ["unitSellingPrice", "Unit Selling Price"],
  ["netValue", "Net Value"],
  ["transportModeType", "Transport Mode Type"],
  ["variableCost", "Variable Cost"],
  ["fixedCost", "Fixed Cost"],
  ["unitCost", "Unit Cost"],
  ["totalCost", "Total Cost"],
  ["leadTime", "Lead Time"],
  ["reasonCode", "Reason Code"],
  ["comments", "Comments"],
  ["createdBy", "Created By"],
  ["createdAt", "Created At"],
  ["updatedBy", "Updated By"],
  ["updatedAt", "Updated At"],
  ["isManuallyCreated", "Is Manually Created"],
];

export function OmsServerDataTablePagination({
  table,
  showColorCodingLegend,
  paginationData,
  setPaginationData,
}) {
  return (
    <div className="flex items-center justify-between px-2 align-center">
      <div className=" flex flex-1 text-sm text-muted-foreground lg:text-xl">
        <div>
          {table.getFilteredSelectedRowModel().rows.length} of{" "}
          {table.getFilteredRowModel().rows.length} row(s) selected
        </div>
        {showColorCodingLegend && (
          <div className="flex gap-2 items-center text-lg ml-3 font-[Inter] text-[#35445c] ">
            <div className="flex items-center gap-1">
              <span className="border border-[#d9d9d9] h-4 w-4 bg-white"></span>
              <span>System </span>
            </div>
            <div className="flex items-center gap-1">
              <span className="border border-[#d9d9d9] h-4 w-4 bg-[#E1F4F4]"></span>
              <span>Manual </span>
            </div>
            <div className="flex items-center gap-1">
              <span className="border border-[#d9d9d9] h-4 w-4 bg-[#EDEBFF]"></span>
              <span>Edited </span>
            </div>
          </div>
        )}
      </div>
      <div className="flex items-center space-x-6 lg:space-x-8">
        <div className="flex items-center space-x-2">
          <p className="text-sm font-medium lg:text-xl">Rows per page</p>
          <Select
            value={`${table.getState().pagination.pageSize}`}
            onValueChange={(value) => {
              table.setPageSize(Number(value));
              setPaginationData((prev) => ({ ...prev, page_size: value }));
            }}
          >
            <SelectTrigger className="h-8 w-[70px] lg:h-11">
              <SelectValue placeholder={table.getState().pagination.pageSize} />
            </SelectTrigger>
            <SelectContent side="top">
              {[10, 20, 50, 100, 250].map((pageSize) => (
                <SelectItem key={pageSize} value={`${pageSize}`}>
                  {pageSize}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="flex w-[100px] items-center justify-center text-sm font-medium lg:text-xl">
          Page{" "}
          {paginationData.next === null
            ? paginationData?.last?.split("=")[1]
            : Number(paginationData?.next?.split("=")[1]) - 1}{" "}
          of{" "}
          {paginationData.last && Number(paginationData?.last?.split("=")[1])}
        </div>
        <div className="flex items-center space-x-2 lg:space-x-6">
          <Button
            variant="outline"
            className="h-8 w-8 p-0 lg:h-11 lg:w-11"
            onClick={() => setPaginationData((prev) => ({ ...prev, page: 1 }))}
            disabled={paginationData.previous === null}
          >
            <span className="sr-only">Go to first page</span>
            <DoubleArrowLeftIcon className="h-4 w-4 lg:h-6 lg:w-6" />
          </Button>
          <Button
            variant="outline"
            className="h-8 w-8 p-0 lg:h-11 lg:w-11"
            onClick={() =>
              setPaginationData((prev) => ({ ...prev, page: prev.page - 1 }))
            }
            disabled={paginationData.previous === null}
          >
            <span className="sr-only">Go to previous page</span>
            <ChevronLeftIcon className="h-4 w-4 lg:h-6 lg:w-6" />
          </Button>
          <Button
            variant="outline"
            className="h-8 w-8 p-0 lg:h-11 lg:w-11"
            onClick={() =>
              setPaginationData((prev) => ({ ...prev, page: prev.page + 1 }))
            }
            disabled={paginationData.next === null}
          >
            <span className="sr-only">Go to next page</span>
            <ChevronRightIcon className="h-4 w-4 lg:h-6 lg:w-6" />
          </Button>
          <Button
            variant="outline"
            className="h-8 w-8 p-0 lg:h-11 lg:w-11"
            onClick={() =>
              setPaginationData((prev) => {
                const lastPage = Number(paginationData?.last?.split("=")[1]);
                return { ...prev, page: lastPage };
              })
            }
            disabled={paginationData.next === null}
          >
            <span className="sr-only">Go to last page</span>
            <DoubleArrowRightIcon className="h-4 w-4 lg:h-6 lg:w-6" />
          </Button>
        </div>
      </div>
    </div>
  );
}
