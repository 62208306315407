import Highcharts from "highcharts";
require("highcharts/modules/funnel")(Highcharts);

import HighchartsReact from "highcharts-react-official";
import { useEffect, useRef, useState } from "react";

export default function FunnelChart({ componentWidth, snpWidth }) {
  const [options, setOptions] = useState({});
  const chartRef = useRef(null);

  useEffect(() => {
    const chartOptions = {
      chart: {
        type: "funnel",
        height: "290px",
      },
      title: {
        text: "",
      },
      plotOptions: {
        funnel: {
          colors: [
            "#F4DAC2",
            "#B0C7F6",
            "#9CB7ED",
            "#8AA9E9",
            "#7B9DE2",
            "#6B91DF",
            "#6089DE",
            "#547FD7",
            "#4776D7",
            "#366AD6",
            "#C3F6BF",
          ],
        },
        series: {
          // dataLabels: {
          //   enabled: true,
          //   format: `<b style="color:#3D445C;fill:red;font-size:12px;font-weight:normal;text-decoration:none;text-shadow:none;drop-shadow:none">{point.name}</b>`,
          //   inside: true,
          //   verticalAlign: "middle",
          // },
          center: ["50%", "20%"],
          neckWidth: "30%",
          neckHeight: "0%",
          width: "80%",
          height: "180%",
        },
      },
      legend: {
        enabled: false,
      },
      series: [
        {
          name: "Stage / Process Name",
          data: [
            // {
            //   name: "Raw Materials2",
            //   y: 100,
            //   value: "213",
            //   volume: "2132 units",
            //   skuInWip: "123",
            //   rmInWip: "213",
            // },
            {
              name: "Raw Materials",
              y: 110,
              value: "213",
              volume: "2132 units",
              skuInWip: "123",
              rmInWip: "213",
            },
            {
              name: "Stage / Process-1",
              y: 10,
              value: "213",
              volume: "2132 units",
              skuInWip: "123",
              rmInWip: "213",
            },
            {
              name: "Stage / Process-2",
              y: 10,
              value: "213",
              volume: "2132 units",
              skuInWip: "123",
              rmInWip: "213",
            },
            {
              name: "Stage / Process-3",
              y: 10,
              value: "213",
              volume: "2132 units",
              skuInWip: "123",
              rmInWip: "213",
            },
            {
              name: "Stage / Process-4",
              y: 10,
              value: "213",
              volume: "2132 units",
              skuInWip: "123",
              rmInWip: "213",
            },
            {
              name: "Stage / Process-5",
              y: 10,
              value: "213",
              volume: "2132 units",
              skuInWip: "123",
              rmInWip: "213",
            },
            {
              name: "Stage / Process-6",
              y: 10,
              value: "213",
              volume: "2132 units",
              skuInWip: "123",
              rmInWip: "213",
            },
            {
              name: "Stage / Process-7",
              y: 10,
              value: "213",
              volume: "2132 units",
              skuInWip: "123",
              rmInWip: "213",
            },
            {
              name: "Stage / Process-8",
              y: 10,
              value: "213",
              volume: "2132 units",
              skuInWip: "123",
              rmInWip: "213",
            },
            {
              name: "Stage / Process-9",
              y: 10,
              value: "213",
              volume: "2132 units",
              skuInWip: "123",
              rmInWip: "213",
            },
            {
              name: "Finished Goods",
              y: 30,
              value: "213",
              volume: "2132 units",
              skuInWip: "123",
              rmInWip: "213",
            },
          ],
        },
      ],
      tooltip: {
        formatter: function () {
          const point = this.point;
          const tooltipContent = `<b>${point.name}</b><br>
                                   Value: ${point.value || "N/A"}<br>
                                   Volume: ${point.volume || "N/A"}<br>
                                   SKU in WIP: ${point.skuInWip || "N/A"}<br>
                                   RM in WIP: ${point.rmInWip || "N/A"}`;
          return tooltipContent;
        },
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              plotOptions: {
                series: {
                  dataLabels: {
                    inside: true,
                  },
                  center: ["40%", "40%"],
                  width: "80%",
                },
              },
            },
          },
        ],
      },
    };
    setOptions(chartOptions);
  }, []);

  useEffect(() => {
    const chart = chartRef.current.chart;
    const handleResize = () => {
      chart.setSize(componentWidth);
    };
    handleResize();
  }, [snpWidth]);

  return (
    <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
  );
}
