export const ppPreprocessData = [
  {
    id: 180,
    process_type: {
      id: 5,
      name: "INVENTORY_NORMS",
      process: "PREPROCESS",
      status: 1,
    },
    process: "SS: FG",
    tenant_id: "33a2289b-2b54-45cb-8294-8edc466b7d7f",
    bu_id: "328400bd-b76f-4a1d-8183-019b5719e47f",
    preprocess_action: ["view", "download", "upload"],
    last_updated_at: "2024-06-04T15:38:26.665748+05:30",
    last_updated_by: "colgate-rpadmin-dev@gmail.com",
  },
  {
    id: 179,
    process_type: {
      id: 5,
      name: "INVENTORY_NORMS",
      process: "PREPROCESS",
      status: 1,
    },
    process: "DOS: FG",
    tenant_id: "33a2289b-2b54-45cb-8294-8edc466b7d7f",
    bu_id: "328400bd-b76f-4a1d-8183-019b5719e47f",
    preprocess_action: ["view", "download", "upload"],
    last_updated_at: "2024-06-04T15:38:26.561064+05:30",
    last_updated_by: "colgate-rpadmin-dev@gmail.com",
  },
  {
    id: 178,
    process_type: {
      id: 5,
      name: "INVENTORY_NORMS",
      process: "PREPROCESS",
      status: 1,
    },
    process: "MiSL: FG",
    tenant_id: "33a2289b-2b54-45cb-8294-8edc466b7d7f",
    bu_id: "328400bd-b76f-4a1d-8183-019b5719e47f",
    preprocess_action: ["view", "download", "upload"],
    last_updated_at: "2024-06-04T15:38:26.457717+05:30",
    last_updated_by: "colgate-rpadmin-dev@gmail.com",
  },
  {
    id: 177,
    process_type: {
      id: 5,
      name: "INVENTORY_NORMS",
      process: "PREPROCESS",
      status: 1,
    },
    process: "MSL: FG",
    tenant_id: "33a2289b-2b54-45cb-8294-8edc466b7d7f",
    bu_id: "328400bd-b76f-4a1d-8183-019b5719e47f",
    preprocess_action: ["view", "download", "upload"],
    last_updated_at: "2024-06-04T15:38:26.354293+05:30",
    last_updated_by: "colgate-rpadmin-dev@gmail.com",
  },
  {
    id: 178,
    process_type: {
      id: 6,
      name: "Requirement",
      process: "PREPROCESS",
      status: 1,
    },
    process: "Requirement: FG",
    tenant_id: "33a2289b-2b54-45cb-8294-8edc466b7d7f",
    bu_id: "328400bd-b76f-4a1d-8183-019b5719e47f",
    preprocess_action: [],
    last_updated_at: "2024-02-01T14:43:48.987763+05:30",
    last_updated_by: "scheduler-user@gmail.com",
  },
  {
    id: 173,
    process_type: {
      id: 6,
      name: "CSA",
      process: "PREPROCESS",
      status: 1,
    },
    process: "CSE_FMR",
    tenant_id: "33a2289b-2b54-45cb-8294-8edc466b7d7f",
    bu_id: "328400bd-b76f-4a1d-8183-019b5719e47f",
    preprocess_action: [],
    last_updated_at: "2024-02-01T14:44:04.836156+05:30",
    last_updated_by: "scheduler-user@gmail.com",
  },
  {
    id: 172,
    process_type: {
      id: 6,
      name: "CSA",
      process: "PREPROCESS",
      status: 1,
    },
    process: "CSE_XYZ",
    tenant_id: "33a2289b-2b54-45cb-8294-8edc466b7d7f",
    bu_id: "328400bd-b76f-4a1d-8183-019b5719e47f",
    preprocess_action: [],
    last_updated_at: "2024-02-01T14:43:55.972647+05:30",
    last_updated_by: "scheduler-user@gmail.com",
  },
  {
    id: 171,
    process_type: {
      id: 6,
      name: "CSA",
      process: "PREPROCESS",
      status: 1,
    },
    process: "CSE_ABC",
    tenant_id: "33a2289b-2b54-45cb-8294-8edc466b7d7f",
    bu_id: "328400bd-b76f-4a1d-8183-019b5719e47f",
    preprocess_action: [],
    last_updated_at: "2024-02-01T14:43:48.987763+05:30",
    last_updated_by: "scheduler-user@gmail.com",
  },
];


export const ppPreprocessMetadata = [
  {
    id: 179,
    process_type: {
      id: 5,
      name: "Inventory_Profiling",
      process: "PREPROCESS",
      status: 1,
    },
    process: "Inventory Profiling",
    tenant_id: "33a2289b-2b54-45cb-8294-8edc466b7d7f",
    bu_id: "328400bd-b76f-4a1d-8183-019b5719e47f",
    preprocess_action: [],
    last_updated_at: "2024-06-04T15:38:26.561064+05:30",
    last_updated_by: "colgate-rpadmin-dev@gmail.com",
  },
  {
    id: 179,
    process_type: {
      id: 5,
      name: "Output",
      process: "PREPROCESS",
      status: 1,
    },
    process: "Demand Fulfillment",
    tenant_id: "33a2289b-2b54-45cb-8294-8edc466b7d7f",
    bu_id: "328400bd-b76f-4a1d-8183-019b5719e47f",
    preprocess_action: [],
    last_updated_at: "2024-06-04T15:38:26.561064+05:30",
    last_updated_by: "colgate-rpadmin-dev@gmail.com",
  },
  {
    id: 179,
    process_type: {
      id: 5,
      name: "Output",
      process: "PREPROCESS",
      status: 1,
    },
    process: "Plant to Warehouse Allocation",
    tenant_id: "33a2289b-2b54-45cb-8294-8edc466b7d7f",
    bu_id: "328400bd-b76f-4a1d-8183-019b5719e47f",
    preprocess_action: [],
    last_updated_at: "2024-06-04T15:38:26.561064+05:30",
    last_updated_by: "colgate-rpadmin-dev@gmail.com",
  },
  {
    id: 179,
    process_type: {
      id: 5,
      name: "Output",
      process: "PREPROCESS",
      status: 1,
    },
    process: "Plant Capacity Analysis",
    tenant_id: "33a2289b-2b54-45cb-8294-8edc466b7d7f",
    bu_id: "328400bd-b76f-4a1d-8183-019b5719e47f",
    preprocess_action: [],
    last_updated_at: "2024-06-04T15:38:26.561064+05:30",
    last_updated_by: "colgate-rpadmin-dev@gmail.com",
  },
  {
    id: 179,
    process_type: {
      id: 5,
      name: "Output",
      process: "PREPROCESS",
      status: 1,
    },
    process: "Workforce Analysis",
    tenant_id: "33a2289b-2b54-45cb-8294-8edc466b7d7f",
    bu_id: "328400bd-b76f-4a1d-8183-019b5719e47f",
    preprocess_action: [],
    last_updated_at: "2024-06-04T15:38:26.561064+05:30",
    last_updated_by: "colgate-rpadmin-dev@gmail.com",
  },
  {
    id: 179,
    process_type: {
      id: 5,
      name: "Output",
      process: "PREPROCESS",
      status: 1,
    },
    process: "Production Capacity Machine Analysis",
    tenant_id: "33a2289b-2b54-45cb-8294-8edc466b7d7f",
    bu_id: "328400bd-b76f-4a1d-8183-019b5719e47f",
    preprocess_action: [],
    last_updated_at: "2024-06-04T15:38:26.561064+05:30",
    last_updated_by: "colgate-rpadmin-dev@gmail.com",
  },
  {
    id: 179,
    process_type: {
      id: 5,
      name: "Output",
      process: "PREPROCESS",
      status: 1,
    },
    process: "Stock Analysis - Material",
    tenant_id: "33a2289b-2b54-45cb-8294-8edc466b7d7f",
    bu_id: "328400bd-b76f-4a1d-8183-019b5719e47f",
    preprocess_action: [],
    last_updated_at: "2024-06-04T15:38:26.561064+05:30",
    last_updated_by: "colgate-rpadmin-dev@gmail.com",
  },
  {
    id: 179,
    process_type: {
      id: 5,
      name: "Output",
      process: "PREPROCESS",
      status: 1,
    },
    process: "Stock Analysis - Plant",
    tenant_id: "33a2289b-2b54-45cb-8294-8edc466b7d7f",
    bu_id: "328400bd-b76f-4a1d-8183-019b5719e47f",
    preprocess_action: [],
    last_updated_at: "2024-06-04T15:38:26.561064+05:30",
    last_updated_by: "colgate-rpadmin-dev@gmail.com",
  },
  {
    id: 179,
    process_type: {
      id: 5,
      name: "Output",
      process: "PREPROCESS",
      status: 1,
    },
    process: "Stock Analysis - WH",
    tenant_id: "33a2289b-2b54-45cb-8294-8edc466b7d7f",
    bu_id: "328400bd-b76f-4a1d-8183-019b5719e47f",
    preprocess_action: [],
    last_updated_at: "2024-06-04T15:38:26.561064+05:30",
    last_updated_by: "colgate-rpadmin-dev@gmail.com",
  },
  {
    id: 179,
    process_type: {
      id: 5,
      name: "Output",
      process: "PREPROCESS",
      status: 1,
    },
    process: "CRP - Machine Analysis",
    tenant_id: "33a2289b-2b54-45cb-8294-8edc466b7d7f",
    bu_id: "328400bd-b76f-4a1d-8183-019b5719e47f",
    preprocess_action: [],
    last_updated_at: "2024-06-04T15:38:26.561064+05:30",
    last_updated_by: "colgate-rpadmin-dev@gmail.com",
  },
  {
    id: 179,
    process_type: {
      id: 5,
      name: "Output",
      process: "PREPROCESS",
      status: 1,
    },
    process: "CRP - Workforce analysis ",
    tenant_id: "33a2289b-2b54-45cb-8294-8edc466b7d7f",
    bu_id: "328400bd-b76f-4a1d-8183-019b5719e47f",
    preprocess_action: [],
    last_updated_at: "2024-06-04T15:38:26.561064+05:30",
    last_updated_by: "colgate-rpadmin-dev@gmail.com",
  },
];