import FilterModal from "./FilterModal";
import "./NodeDetails.scss";

const NodeDetails = ({ isOpen, setIsOpen, nodeType, details }) => {
  console.log("in details");
  const handleDisplay = isOpen ? "node-details toggle-bar" : "node-details";
  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <div
      id="network-sidebar"
      className={handleDisplay}
      style={{ padding: "12px" }}
    >
      {!(nodeType === "Filters") ? (
        <>
          {" "}
          <div className="node-details-header ">
            {nodeType === "Network" && <h1>Network Details</h1>}
            {nodeType === "Node" && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    background: "#2F6DF6",
                    width: "43px",
                    height: "38px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "3px",
                  }}
                >
                  <svg
                    width="23"
                    height="18"
                    viewBox="0 0 23 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.9174 3.85714C21.7243 3.85714 21.5263 3.90427 21.3424 4.00825L15.0938 7.71429V4.85759C15.0938 4.29308 14.574 3.8929 14.012 3.8929C13.8189 3.8929 13.621 3.94003 13.437 4.04397L7.1875 7.71429V1.28571C7.1875 0.575759 6.54377 0 5.75 0H1.4375C0.64373 0 0 0.575759 0 1.28571V16.7143C0 17.4242 0.64373 18 1.4375 18H21.5625C22.3563 18 23 17.4242 23 16.7143V4.85759C23 4.25893 22.4789 3.85714 21.9174 3.85714ZM21.5625 16.7504H1.4375V1.32188H5.75V10.1451L13.6562 5.45625V10.1451L21.5625 5.45625V16.7504ZM7.1875 11.6076H4.3125C3.91521 11.6076 3.59375 11.8951 3.59375 12.2504V14.8219C3.59375 15.1754 3.91539 15.4647 4.3125 15.4647H7.1875C7.58479 15.4647 7.90625 15.1772 7.90625 14.8219V12.2504C7.90625 11.8969 7.58281 11.6076 7.1875 11.6076ZM6.46875 14.179H5.03125V12.8933H6.46875V14.179ZM12.9375 11.6076H10.0625C9.66521 11.6076 9.34375 11.8951 9.34375 12.2504V14.8219C9.34375 15.1754 9.66719 15.4647 10.0625 15.4647H12.9375C13.3348 15.4647 13.6562 15.1772 13.6562 14.8219V12.2504C13.6562 11.8969 13.3328 11.6076 12.9375 11.6076ZM12.2188 14.179H10.7812V12.8933H12.2188V14.179ZM15.0938 12.2504V14.8219C15.0938 15.1772 15.4152 15.4647 15.8125 15.4647H18.6875C19.0848 15.4647 19.4062 15.1772 19.4062 14.8219V12.2504C19.4062 11.8951 19.0848 11.6076 18.6875 11.6076H15.8125C15.4172 11.6076 15.0938 11.8969 15.0938 12.2504ZM16.5312 12.8933H17.9688V14.179H16.5312V12.8933Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <h1>WH-N231</h1>
              </div>
            )}

            <button className="close-btn" onClick={handleClose}>
              <svg
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.85236 9.85334C9.65699 10.0487 9.34087 10.0487 9.14528 9.85334L4.99992 5.70705L0.853623 9.85334C0.658244 10.0487 0.342132 10.0487 0.146534 9.85334C-0.0488447 9.65796 -0.0488447 9.34185 0.146534 9.14625L4.29377 5.00089L0.147159 0.854599C-0.0482198 0.659221 -0.0482198 0.343108 0.147159 0.147511C0.342538 -0.0478682 0.65865 -0.0478682 0.854248 0.147511L4.99992 4.29474L9.14621 0.148448C9.34159 -0.0469308 9.6577 -0.0469308 9.8533 0.148448C10.0487 0.343827 10.0487 0.659939 9.8533 0.855537L5.70607 5.00089L9.85236 9.14719C10.0492 9.34091 10.0492 9.65962 9.85236 9.85334Z"
                  fill="#3D445C"
                />
              </svg>
            </button>
          </div>
          <hr className="node-details-line" />
          <table className="table">
            <tbody style={{ width: "100%" }}>
              {details.map((detail, ind) => (
                <tr
                  key={ind}
                  onMouseEnter={() => {
                    console.log("hovered");
                  }}
                >
                  <td>{detail.type}</td>
                  <td>{detail.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : (
        <FilterModal isOpen={isOpen} setIsOpen={setIsOpen} />
      )}
    </div>
  );
};

export default NodeDetails;
