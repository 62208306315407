import { DataTableColumnHeader } from "@/components/tanstack-table/data-table-column-header";
import { useNavigate } from "react-router-dom";
import UploadIcon from "@/components/customSvgIcons/upload";
import { TableLoaderSpinner } from "@/components/tableUtils/tableUtils";
import { handleDownload } from "@/pages/Inventory/Preprocess/preprocessUtils";
import DownloadIcon from "@/components/customSvgIcons/download";
import "../Tables/styles.css";

const viewColumn = (
  row,
  navigatePath,
  selectedRow,
  setSelectedRow,
  openUploadModal,
  setOpenUploadModal,
  isUploading,
  setIsUploading,
  isDownloading,
  setIsDownloading,
  idArr,
  level,
  moduleType
) => {
  const navigate = useNavigate();
  let type;
  let processType;
  if (row.processTypes === "CSA") {
    const rowArray = row.process.split("_");
    const rowArrayLength = rowArray.length;
    type = rowArray[rowArrayLength - 1].toLowerCase();
    processType = "classification";
  } else if (row.processTypes.toLowerCase().includes("norms")) {
    {
      const rowArray = row.process.split(":");
      type = rowArray[0].toLowerCase();
      processType = "norms";
    }
  }
  return (
    <div className="table-cell-align action-view-btn-box">
      {level === "preprocesses" && (
        <button
          className={`table-action-button upload-button process-btn ${
            row.processTypes === "RP_PREPROCESS_D" ? "disabled-btn" : ""
          }`}
          onClick={() => {
            setSelectedRow(row);
            setOpenUploadModal(true);
          }}
          disabled={
            isUploading[row.process] ||
            row.processTypes === "RP_PREPROCESS_D" ||
            !row.preprocessAction.includes("upload")
          }
        >
          {isUploading[row.process] ? (
            <TableLoaderSpinner size={12} height="2vh" />
          ) : (
            <UploadIcon />
          )}
        </button>
      )}
      <button
        className={`table-action-button upload-button process-btn ${
          row.processTypes === "RP_PREPROCESS_D" ? "disabled-btn" : ""
        }`}
        onClick={() =>
          handleDownload(
            row,
            type,
            processType,
            isDownloading,
            setIsDownloading,
            level, // this I have to pass
            moduleType
          )
        }
        disabled={
          isDownloading[row.process] ||
          row.processTypes === "RP_PREPROCESS_D" ||
          !row.preprocessAction.includes("download")
        }
      >
        {isDownloading[row.process] ? (
          <TableLoaderSpinner size={12} height="2vh" />
        ) : (
          <DownloadIcon />
        )}
      </button>
      <button
        className={`table-action-button view-button-preprocess process-view-btn ${
          row.processTypes === "RP_PREPROCESS_D"
            ? "view-button-preprocess-disabled"
            : ""
        }`}
        onClick={() => {
          if (row.processTypes === "INVENTORY_PROFILING") {
            return navigate(
              `/${idArr[1]}/${idArr[2]}/${idArr[3]}/${idArr[4]}/process/profiling`,
              { state: { processType: processType, preprocessRow: row } }
            );
          }
          return navigate(
            `/${idArr[1]}/${idArr[2]}/${idArr[3]}/${idArr[4]}/process/${type}`,
            { state: { processType: processType, preprocessRow: row } }
          );
        }}
        disabled={
          row.processTypes === "RP_PREPROCESS_D" ||
          !row.preprocessAction.includes("view")
        }
      >
        View
      </button>
    </div>
  );
};

export const PpPreprocessColumns = (
  selectedRow,
  setSelectedRow,
  openUploadModal,
  setOpenUploadModal,
  isUploading,
  setIsUploading,
  isDownloading,
  setIsDownloading,
  idArr,
  level,
  moduleType = "pp"
) => {
  const columns = [
    {
      accessorKey: "process",
      header: ({ column, header }) => (
        <div className="py-[10px] lg:py-[8px]">
          <DataTableColumnHeader
            column={column}
            title={"Process"}
            header={header}
          />
        </div>
      ),
      cell: ({ row, cell }) => {
        const widthValue = cell.column.getSize();
        return (
          <div className="flex items-center justify-center">
            <div
              className="antialiased truncate text-center py-[8px] lg:py-[6px]"
              style={{ width: widthValue, minWidth: "150px" }}
            >
              {row.getValue("process")}
            </div>
          </div>
        );
      },
      size: 270,
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
      enableSorting: true,
      enableHiding: true,
    },
    {
      accessorKey: "processTypes",
      header: ({ column, header }) => (
        <div className="py-[10px] lg:py-[8px]">
          <DataTableColumnHeader
            column={column}
            title={"Process Types"}
            header={header}
          />{" "}
        </div>
      ),
      cell: ({ row, cell }) => {
        const widthValue = cell.column.getSize();
        return (
          <div className="flex items-center justify-center">
            <div
              className="antialiased truncate text-center py-[8px] lg:py-[6px]"
              style={{ width: widthValue, minWidth: "150px" }}
            >
              {row.getValue("processTypes")}
            </div>
          </div>
        );
      },
      size: 270,
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
      enableSorting: true,
      enableHiding: true,
    },
    {
      accessorKey: "lastUpdatedBy",
      header: ({ column, header }) => (
        <div className="py-[10px] lg:py-[8px]">
          <DataTableColumnHeader
            column={column}
            title={"Last Updated By"}
            header={header}
          />{" "}
        </div>
      ),
      cell: ({ row, cell }) => {
        const widthValue = cell.column.getSize();
        return (
          <div className="flex items-center justify-center">
            <div
              className="antialiased truncate text-center py-[8px] lg:py-[6px]"
              style={{ width: widthValue, minWidth: "150px" }}
            >
              {row.getValue("lastUpdatedBy")}
            </div>
          </div>
        );
      },
      size: 270,
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
      enableSorting: true,
      enableHiding: true,
    },
    {
      accessorKey: "lastUpdatedAt",
      header: ({ column, header }) => (
        <div className="py-[10px] lg:py-[8px]">
          <DataTableColumnHeader
            column={column}
            title={"Last Updated At"}
            header={header}
          />{" "}
        </div>
      ),
      cell: ({ row, cell }) => {
        const widthValue = cell.column.getSize();
        return (
          <div className="flex items-center justify-center">
            <div
              className="antialiased truncate text-center py-[8px] lg:py-[6px]"
              style={{ width: widthValue, minWidth: "150px" }}
            >
              {row.getValue("lastUpdatedAt")}
            </div>
          </div>
        );
      },
      size: 270,
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
      enableSorting: true,
      enableHiding: true,
    },
    {
      id: "actions",
      accessorKey: "Action",
      header: ({ column, header }) => (
        <div className="font-semibold font-sans lg:text-[1.4rem] antialiased !px-[6px] lg:!px-[4px]">
          <DataTableColumnHeader
            column={column}
            title="Actions"
            header={header}
          />
        </div>
      ),
      cell: ({ cell, row }) => {
        const navigatePath = `/snp/production/production-plan`;
        return (
          <>
            {viewColumn(
              row.original,
              navigatePath,
              selectedRow,
              setSelectedRow,
              openUploadModal,
              setOpenUploadModal,
              isUploading,
              setIsUploading,
              isDownloading,
              setIsDownloading,
              idArr,
              level,
              moduleType
            )}
          </>
        );
      },
      enableHiding: false,
      size: 210,
    },
  ];

  return columns;
};

const getDisplayNameProcessType = (type, processType, userType) => {
  if (processType === "classification") {
    return `Classification - ${userType}`;
  } else if (processType === "norms") {
    let processPath = "";
    if (type === "msl") {
      processPath = `Max Stock Level - ${userType}`;
    } else if (type === "misl") {
      processPath = `Min Stock Level - ${userType}`;
    } else if (type === "dos") {
      processPath = `Days of Stock (DOS) - ${userType}`;
    } else if (type === "ss") {
      processPath = `Safety Stock - ${userType}`;
    }
    return processPath;
  }
  return "";
};

export function getColumns({ skipViewQuery, type, processType }) {
  let columns = [
    {
      accessorKey: "skuName",
      header: ({ column, header }) => (
        <div className="py-[10px] lg:py-[8px]">
          <DataTableColumnHeader
            column={column}
            title={"SKU Name"}
            header={header}
          />
        </div>
      ),
      cell: ({ row, cell }) => {
        const widthValue = cell.column.getSize();
        return (
          <div className="flex items-center justify-center">
            <div
              className="antialiased truncate text-center py-[8px] lg:py-[6px]"
              style={{ width: widthValue, minWidth: "150px" }}
            >
              {row.getValue("skuName")}
            </div>
          </div>
        );
      },
      size: 270,
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
      enableSorting: true,
      enableHiding: true,
    },
    {
      accessorKey: "skuCode",
      header: ({ column, header }) => (
        <div className="py-[10px] lg:py-[8px]">
          <DataTableColumnHeader
            column={column}
            title={"SKU Code"}
            header={header}
          />
        </div>
      ),
      cell: ({ row, cell }) => {
        const widthValue = cell.column.getSize();
        return (
          <div className="flex items-center justify-center">
            <div
              className="antialiased truncate text-center py-[8px] lg:py-[6px]"
              style={{ width: widthValue, minWidth: "150px" }}
            >
              {row.getValue("skuCode")}
            </div>
          </div>
        );
      },
      size: 270,
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
      enableSorting: true,
      enableHiding: true,
    },
    {
      accessorKey: "channelName",
      header: ({ column, header }) => (
        <div className="py-[10px] lg:py-[8px]">
          <DataTableColumnHeader
            column={column}
            title={"Channel Name"}
            header={header}
          />
        </div>
      ),
      cell: ({ row, cell }) => {
        const widthValue = cell.column.getSize();
        return (
          <div className="flex items-center justify-center">
            <div
              className="antialiased truncate text-center py-[8px] lg:py-[6px]"
              style={{ width: widthValue, minWidth: "150px" }}
            >
              {row.getValue("channelName")}
            </div>
          </div>
        );
      },
      size: 270,
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
      enableSorting: true,
      enableHiding: true,
    },
    {
      accessorKey: "nodeName",
      header: ({ column, header }) => (
        <div className="py-[10px] lg:py-[8px]">
          <DataTableColumnHeader
            column={column}
            title={"Node Name"}
            header={header}
          />
        </div>
      ),
      cell: ({ row, cell }) => {
        const widthValue = cell.column.getSize();
        return (
          <div className="flex items-center justify-center">
            <div
              className="antialiased truncate text-center py-[8px] lg:py-[6px]"
              style={{ width: widthValue, minWidth: "150px" }}
            >
              {row.getValue("nodeName")}
            </div>
          </div>
        );
      },
      size: 270,
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
      enableSorting: true,
      enableHiding: true,
    },
    {
      accessorKey: "nodeCode",
      header: ({ column, header }) => (
        <div className="py-[10px] lg:py-[8px]">
          <DataTableColumnHeader
            column={column}
            title={"Node Code"}
            header={header}
          />
        </div>
      ),
      cell: ({ row, cell }) => {
        const widthValue = cell.column.getSize();
        return (
          <div className="flex items-center justify-center">
            <div
              className="antialiased truncate text-center py-[8px] lg:py-[6px]"
              style={{ width: widthValue, minWidth: "150px" }}
            >
              {row.getValue("nodeCode")}
            </div>
          </div>
        );
      },
      size: 270,
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
      enableSorting: true,
      enableHiding: true,
    },
  ];
  let profilingCols = [
    ...columns,
    {
      accessorKey: "inventoryLevel",
      header: ({ column, header }) => (
        <div className="py-[10px] lg:py-[8px]">
          <DataTableColumnHeader
            column={column}
            title={"Inventory Level"}
            header={header}
          />
        </div>
      ),
      cell: ({ row, cell }) => {
        const widthValue = cell.column.getSize();
        return (
          <div className="flex items-center justify-center">
            <div
              className="antialiased truncate text-center py-[8px] lg:py-[6px]"
              style={{ width: widthValue, minWidth: "150px" }}
            >
              {row.getValue("inventoryLevel")}
            </div>
          </div>
        );
      },
      size: 270,
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
      enableSorting: true,
      enableHiding: true,
    },
    {
      accessorKey: "lostSales",
      header: ({ column, header }) => (
        <div className="py-[10px] lg:py-[8px]">
          <DataTableColumnHeader
            column={column}
            title={"Lost Sales"}
            header={header}
          />
        </div>
      ),
      cell: ({ row, cell }) => {
        const widthValue = cell.column.getSize();
        return (
          <div className="flex items-center justify-center">
            <div
              className="antialiased truncate text-center py-[8px] lg:py-[6px]"
              style={{ width: widthValue, minWidth: "150px" }}
            >
              {row.getValue("lostSales")}
            </div>
          </div>
        );
      },
      size: 270,
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
      enableSorting: true,
      enableHiding: true,
    },
    {
      accessorKey: "inventoryPosition",
      header: ({ column, header }) => (
        <div className="py-[10px] lg:py-[8px]">
          <DataTableColumnHeader
            column={column}
            title={"Inventory Position"}
            header={header}
          />
        </div>
      ),
      cell: ({ row, cell }) => {
        const widthValue = cell.column.getSize();
        return (
          <div className="flex items-center justify-center">
            <div
              className="antialiased truncate text-center py-[8px] lg:py-[6px]"
              style={{ width: widthValue, minWidth: "150px" }}
            >
              {row.getValue("inventoryPosition")}
            </div>
          </div>
        );
      },
      size: 270,
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
      enableSorting: true,
      enableHiding: true,
    },
    {
      accessorKey: "date",
      header: ({ column, header }) => (
        <div className="py-[10px] lg:py-[8px]">
          <DataTableColumnHeader
            column={column}
            title={"Date"}
            header={header}
          />
        </div>
      ),
      cell: ({ row, cell }) => {
        const widthValue = cell.column.getSize();
        return (
          <div className="flex items-center justify-center">
            <div
              className="antialiased truncate text-center py-[8px] lg:py-[6px]"
              style={{ width: widthValue, minWidth: "150px" }}
            >
              {row.getValue("date")}
            </div>
          </div>
        );
      },
      size: 270,
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
      enableSorting: true,
      enableHiding: true,
    },
  ];
  let viewCols = [
    ...columns,
    {
      accessorKey: "classification",
      header: ({ column, header }) => (
        <div className="py-[10px] lg:py-[8px]">
          <DataTableColumnHeader
            column={column}
            title={getDisplayNameProcessType(type, processType, "System")}
            header={header}
          />
        </div>
      ),
      cell: ({ row, cell }) => {
        const widthValue = cell.column.getSize();
        return (
          <div className="flex items-center justify-center">
            <div
              className="antialiased truncate text-center py-[8px] lg:py-[6px]"
              style={{ width: widthValue, minWidth: "150px" }}
            >
              {row.getValue("classification")}
            </div>
          </div>
        );
      },
      size: 270,
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
      enableSorting: true,
      enableHiding: true,
    },
    {
      accessorKey: "classification1",
      header: ({ column, header }) => (
        <div className="py-[10px] lg:py-[8px]">
          <DataTableColumnHeader
            column={column}
            title={getDisplayNameProcessType(type, processType, "User")}
            header={header}
          />
        </div>
      ),
      cell: ({ row, cell }) => {
        const widthValue = cell.column.getSize();
        return (
          <div className="flex items-center justify-center">
            <div
              className="antialiased truncate text-center py-[8px] lg:py-[6px]"
              style={{ width: widthValue, minWidth: "150px" }}
            >
              {row.getValue("classification1")}
            </div>
          </div>
        );
      },
      size: 270,
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
      enableSorting: true,
      enableHiding: true,
    },
  ];
  if (skipViewQuery) {
    return profilingCols;
  }
  return viewCols;
}
